import { useQuery } from 'hooks/sympl-query';
import { GET_VACANCY_COPY } from 'graphql/vacancies/queries';
import useNavigationContext from 'hooks/context/nav-context';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import {
  UpdateVacancyCopy,
  Vacancy,
  VacancyContextType,
  VacancyCopy,
} from 'types/vacancyTypes';
import { useMutation } from 'hooks/sympl-mutation';
import {
  UPDATE_VACANCY_COPY,
  UPDATE_VACANCY_CUSTOM_URL,
} from 'graphql/vacancies/mutations';
import { getRandomText } from 'utils/wordpressHelpers';
import { VacancyPromptPayload } from 'utils/vacancy-generator/vacancyHelpers';

export const VacancyContext = createContext<VacancyContextType>(undefined!);

export const VacancyProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { activeVacancy, setHasUnsavedChanges } = useNavigationContext();

  const [vacancyData, _setVacancyData] = useState<VacancyCopy>();
  const [payload, setPayload] = useState<VacancyPromptPayload>();
  const [generatingFields, setGeneratingFields] = useState<
    (keyof VacancyCopy)[]
  >([]);

  const {
    loading: vacancyLoading,
    data,
    refetch: refetchCopy,
  } = useQuery<
    {
      vacancy?: Vacancy;
    },
    { vacancyId: number }
  >(GET_VACANCY_COPY, {
    skip: !activeVacancy,
    variables: { vacancyId: activeVacancy ?? 0 },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const modifiedVacData = data.vacancy?.vacCopy
        ? {
            ...(data.vacancy.vacCopy as VacancyCopy),
            has_custom_url: data.vacancy.has_custom_url ?? false,
            published: data.vacancy.published,
            brand_id: data.vacancy.brand_id,
          }
        : undefined;
      _setVacancyData(modifiedVacData);
    },
  });

  const setVacancyData = (
    data: React.SetStateAction<VacancyCopy | undefined>
  ) => {
    _setVacancyData(data);
    setHasUnsavedChanges(true);
  };

  const [updateCopy] = useMutation<UpdateVacancyCopy>(UPDATE_VACANCY_COPY);
  const [updateCustomUrl] = useMutation<UpdateVacancyCopy>(
    UPDATE_VACANCY_CUSTOM_URL
  );

  const saveHandler = async (vacancyDataCopy?: VacancyCopy): Promise<void> => {
    const tempvacancyData = {
      ...vacancyDataCopy,
      vac_text_uniqueness: JSON.stringify(vacancyDataCopy?.vac_text_uniqueness),
      vac_text_uniqueness_beta: vacancyDataCopy?.vac_text_uniqueness,
      pictures: {
        logo: vacancyDataCopy?.pictures?.logo?.id,
        image_cb1: vacancyDataCopy?.pictures?.image_cb1?.id,
        image_cb3: vacancyDataCopy?.pictures?.image_cb3?.id,
      },
    };

    await updateCopy({
      variables: {
        vacancyId: activeVacancy,
        input: tempvacancyData,
      },
    });
  };

  const saveCustomUrlHandler = async (
    customUrl: string,
    cb?: () => void
  ): Promise<void> => {
    if (activeVacancy?.constructor !== Number) return;
    try {
      const variables = {
        vacancyId: activeVacancy,
        input: {
          vacancy_url: customUrl,
        },
      };

      await updateCustomUrl({
        variables,
      });

      cb?.();
    } catch (e) {}
  };

  useEffect(() => {
    // Set vacancy data if it hasn't been set or if the vacancy has changed
    if (
      !vacancyData ||
      (data &&
        Object.values(data).length &&
        vacancyData.id !== data.vacancy?.vacCopy?.id)
    ) {
      const modifiedVacData = data?.vacancy?.vacCopy
        ? {
            ...(data.vacancy.vacCopy as VacancyCopy),
            has_custom_url: data.vacancy.has_custom_url ?? false,
            brand_id: data.vacancy.brand_id,
          }
        : undefined;
      _setVacancyData(modifiedVacData);
      return;
    }

    // PROBLEM: return () => setHasUnsavedChanges(false);
  }, [data, setHasUnsavedChanges, vacancyData]);

  const dummyTxt = useMemo(() => getRandomText(35), []);

  return (
    <VacancyContext.Provider
      value={{
        vacancyData,
        setVacancyData,
        saveHandler,
        saveCustomUrlHandler,
        dummyTxt,
        vacancyLoading,
        refetchCopy,
        generatingFields,
        setGeneratingFields,
        payload,
        setPayload,
      }}
    >
      {children}
    </VacancyContext.Provider>
  );
};
