import { TargetingLocation } from './geolocationTypes';

export interface JobType {
  id: number;
  name: string;
  sub_job_types: SubJobType[];
}

export interface Targeting {
  job_type?: number;
  job_type_name?: string;
  sub_job_type?: number | null;
  sub_job_type_name?: string;
  experience?: string[];
  locations?: TargetingLocation[];
  language_id?: number;
  vac_name?: string;
  brand_id?: number | null;
}

interface SubJobType {
  id: number;
  name: string;
}

export const EXPERIENCE = [
  {
    code: 'exp_none',
    name: 'None',
  },
  {
    code: 'exp_2',
    name: '0-2 years',
  },
  {
    code: 'exp_5',
    name: '3-5 years',
  },
  {
    code: 'exp_10',
    name: '6-10 years',
  },
  {
    code: 'exp_10+',
    name: '10+ years',
  },
];
