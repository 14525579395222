import { SectionType } from '@symplbe/sympl-components';
import React, { FC } from 'react';

import {
  Slideshow,
  VideoCamera,
  EnvelopeSimple,
  Newspaper,
  ImageSquare,
  BagSimple,
  ListPlus,
  Users,
  Quotes,
  SquaresFour,
} from '@phosphor-icons/react';

interface SectionIconsProps {
  type: SectionType;
  size?: number;
}

const SectionIcons: FC<SectionIconsProps> = ({ type, size = 24 }) => {
  switch (type) {
    case SectionType.Hero:
      return <ImageSquare size={size} weight="bold" />;
    case SectionType.Feature:
      return <ListPlus size={size} weight="bold" />;
    case SectionType.Cards:
      return <SquaresFour size={size} weight="bold" />;
    case SectionType.Carousel:
      return <Slideshow size={size} weight="bold" />;
    case SectionType.Testimonials:
      return <Quotes size={size} weight="fill" />;
    case SectionType.Team:
      return <Users size={size} weight="bold" />;
    case SectionType.Vacancies:
      return <BagSimple size={size} weight="bold" />;
    case SectionType.Video:
      return <VideoCamera size={size} weight="bold" />;
    case SectionType.Contact:
      return <EnvelopeSimple size={size} weight="bold" />;
    case SectionType.Footer:
      return <Newspaper size={size} weight="bold" />;
    default:
      return null;
  }
};
export default SectionIcons;
