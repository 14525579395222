import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser {
    user @rest(type: "User", path: "/v4/users/me") {
      job_position
      signature
      language_id
    }
  }
`;
