import 'twin.macro';
import React from 'react';

export type BannerProps = {
  heading: string;
  closeText?: string;
  onClose?: () => void;
};

export const Banner: React.FC<BannerProps> = ({
  heading,
  children,
  closeText = "Don't show again",
  onClose,
}) => {
  const clickHideHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    onClose?.();
  };

  return (
    <div tw="inline-block w-full text-gray-700 rounded-md px-8 py-6">
      <div tw="flex flex-row justify-between items-center">
        <span tw="text-xl font-medium">{heading}</span>
        <button
          tw="text-xs cursor-pointer ml-8"
          onClick={(e) => clickHideHandler(e)}
        >
          {closeText}
        </button>
      </div>
      {children}
    </div>
  );
};

export default Banner;
