import { gql } from '@apollo/client';

export const GET_INVOICES = gql`
  query getInvoices($customerId: ID!) {
    invoices(
      customerId: $customerId
      pageSize: $pageSize
      currentPage: $currentPage
      status: $status
    )
      @rest(
        type: "Invoice"
        path: "/v4/invoices?sort=-sent_at,-number&filter%5Bstatus%5D={args.status}&page%5Bsize%5D={args.pageSize}&page%5Bnumber%5D={args.currentPage}"
      ) {
      id
      type
      number
      po_number
      date
      amount
      amount_excl
      is_paid
      url
      currency
      status
      created_at
      meta {
        pagination {
          count
          current_page
          links {
            previous
            next
          }
          per_page
          total
          total_pages
        }
      }
    }
  }
`;
