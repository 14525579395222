import {
  Gauge,
  PersonSimpleWalk,
  PersonSimpleBike,
  AirplaneTakeoff,
} from '@phosphor-icons/react';
import Dropdown, { DropdownItem } from 'components/dropdown/Dropdown';
import { SkeletonItem } from 'components/skeleton/skeleton-list/SkeletonList';
import React from 'react';
import tw from 'twin.macro';

export type Pace = 'Slow' | 'Default' | 'Fast';
export type CustomPace = 'Custom';

const paces: DropdownItem[] = [
  {
    label: 'Slow',
    key: 'Slow',
    subLabel: 'Conserve your budget by spending it slowly over time',
    labelIcon: <PersonSimpleWalk size={20} />,
  },
  {
    label: 'Default',
    subLabel: 'Spread your budget evenly for a consistent reach over time',
    key: 'Default',
    labelIcon: <PersonSimpleBike size={20} />,
  },
  {
    label: 'Fast',
    key: 'Fast',
    subLabel: 'Maximize reach by spending your budget as fast as possible',
    labelIcon: <AirplaneTakeoff size={20} />,
  },
];

interface IPaceControls {
  value?: Pace | CustomPace;
  onChange: (newValue: string | number) => void;
  loading?: boolean;
}

export const PaceControls = ({
  value = 'Default',
  onChange,
  loading = false,
}: IPaceControls) => {
  if (loading) {
    return <SkeletonItem height="46px" width="15ch" />;
  }
  return (
    <div tw="bg-gray-50 rounded-md min-w-[15ch] float-right mb-4 focus:ring-2 sm:mb-0">
      <Dropdown
        label={value}
        searchable={false}
        customStyle={tw`disabled:cursor-wait`} // Without this, the dropdown does not get its bg color
        items={paces}
        onChange={(key) => onChange(key)}
        icon={<Gauge size={22} />}
      />
    </div>
  );
};

export default PaceControls;
