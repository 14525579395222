import React, { useCallback, useEffect } from 'react';

import { initIntercom, shutdownIntercom } from 'utils/intercomHelper';
import useWindowDimensions from 'hooks/viewport';
import useIntakeCoreContext from 'hooks/context/intakes-context';
import { Routes as RouteTypes } from 'types/routeTypes';
import { IntakeDetails } from './IntakeDetails';
import IntakeForm from 'components/intake/IntakeForm';
import { useNavigate, useParams } from 'react-router-dom';

interface IRouteParams extends Record<string, string | undefined> {
  intake?: string;
}

const CustomerIntake: React.FC<{
  id: string;
}> = ({ id }) => {
  const navigate = useNavigate();
  const {
    fetchIntake,
    intakeConfig,
    currentStepId,
    currentSectionId,
    handleSetIntakeHash,
    handleSetStepId,
    handleSetIntakeId,
    handleSetSectionId,
    hasFinished: hasFinishedIntake,
  } = useIntakeCoreContext();

  const { intake } = useParams<IRouteParams>();

  const { width } = useWindowDimensions();

  const setIntakeSessionData = useCallback(() => {
    if (!intakeConfig) return;

    const result = intakeConfig;
    const intakeId = result.intakes[Object.keys(result.intakes)[0]].id;
    handleSetIntakeId(intakeId);
    handleSetIntakeHash(intake as string);

    // only set stepId when values are -1
    // only set sectionId when values are -1
    if (currentStepId > 0 || currentSectionId > 0) return;

    if (
      result.intakes[intakeId].sections &&
      result.intakes[intakeId].sections.length > 0
    ) {
      // TODO: change these hardcoded values from session values
      const firstSectionId = result.intakes[intakeId]
        .sections[0] as unknown as number;
      const firstStepId = result.sections[firstSectionId]
        .steps?.[0] as unknown as number;

      handleSetSectionId(firstSectionId);
      handleSetStepId(firstStepId);
    }
  }, [
    currentStepId,
    currentSectionId,
    intakeConfig,
    intakeConfig?.sections,
    intakeConfig?.steps,
    intake,
  ]);

  // intercom
  useEffect(() => {
    // Intercom is rendered by default
    if (width > 640) {
      initIntercom('left'); // Move intercom to the left
    } else {
      shutdownIntercom(); // Hide it on smaller screens
    }
    return () => {
      initIntercom('right'); // Undo intercom placement changes when navigating back to the app
    };
  }, [width]);

  useEffect(() => {
    setIntakeSessionData();
  }, [intakeConfig]);

  useEffect(() => {
    if (intake) fetchIntake(intake).catch(() => navigate(RouteTypes.ERROR));
  }, [navigate, intake]);

  return !hasFinishedIntake ? (
    <IntakeForm id={id} />
  ) : (
    <IntakeDetails hash={intake} />
  );
};

export default CustomerIntake;
