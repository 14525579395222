import { useQuery } from 'hooks/sympl-query';
import { ApiListItem, ApiListType } from 'types/apiTypes';
import { FETCH_API_LIST } from 'graphql/lists/queries';
import useNavigationContext from './context/nav-context';
import { useEffect } from 'react';

/**
 * Gets an API list from the listcontroller for a given type
 * @param type The API list type
 * @param source The API list source (normal|legacy)
 * @returns An array containing the loading state, the list and possible errors
 */
const useApiList = (type: ApiListType) => {
  const { activeCustomer } = useNavigationContext();

  useEffect(() => {
    if (activeCustomer) {
      refetch();
    }
  }, [activeCustomer]);

  const { loading, error, data, refetch } = useQuery<
    {
      apiList: ApiListItem[];
    },
    { type: string }
  >(FETCH_API_LIST, {
    variables: { type },
  });

  return {
    error,
    loading,
    data: data?.apiList ?? [],
  };
};

export default useApiList;
