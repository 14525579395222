import React, { useEffect, useState } from 'react';
import symplImage from 'assets/sympl-transparent.png';
import 'twin.macro';

interface spinningSymplLoaderProps {
  messages: string[];
  loading: boolean;
  delay?: number;
}
const DEFAULT_MESSAGES = [
  'We are looking for your vacancy...',
  'Found it!',
  "Let's gather the important details...",
  'Got it! Time to import...',
  'Importing the vacancy now...',
  'Almost there...',
  "We're finishing up...",
];

const SpinningSymplLoader: React.FC<spinningSymplLoaderProps> = ({
  messages = DEFAULT_MESSAGES,
  loading = false,
  delay = 2500,
}) => {
  const [index, setIndex] = useState<number>(0);

  // SymplLogo component (could be moved outside to avoid re-rendering)
  const SymplLogo = () => (
    <img src={symplImage} alt="Sympl logo" tw="object-contain" tabIndex={-1} />
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const changeMessage = () => {
      setIndex((prevIndex) =>
        prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex
      );
      timeoutId = setTimeout(changeMessage, delay);
    };

    if (loading) timeoutId = setTimeout(changeMessage, delay);

    return () => {
      clearTimeout(timeoutId);
      setIndex(0);
    };
  }, [loading, delay, messages.length]);

  return (
    <div tw="flex flex-col items-center overflow-hidden justify-center w-[25rem] h-[434px]">
      <div className="logo__conatainer" tw="animate-rotate bg-transparent">
        <SymplLogo />
      </div>
      <p tw="mt-8 text-xl text-sympl font-semibold text-center">
        {messages[index]}
      </p>
    </div>
  );
};

export default SpinningSymplLoader;
