import { gql } from '@apollo/client';

export const UPDATE_BRAND_DEFAULTS = gql`
  mutation updateBrandDefaults($brandId: ID!) {
    updateBrandDefaults(input: $input, id: $brandId)
      @rest(
        type: "Brand"
        path: "/v4/brands/{args.id}/defaults"
        method: "POST"
      ) {
      brandId
    }
  }
`;

export const ADD_CUSTOM_DOMAIN = gql`
  mutation addCustomDomain($brandId: ID!) {
    addCustomDomain(input: $input, brandId: $brandId)
      @rest(
        type: "Brand"
        path: "/v4/brands/{args.brandId}/custom-domain"
        method: "POST"
      ) {
      status
      validations {
        type
        name
        value
      }
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation createBrand($brandId: ID!) {
    createBrand(input: $input)
      @rest(type: "Customer", path: "/v4/brands", method: "POST") {
      id
    }
  }
`;

export const DELETE_BRAND = gql`
  mutation deleteBrand($brandId: ID!) {
    deleteBrand(id: $brandId, input: $input)
      @rest(type: "Brand", path: "/v4/brands/{args.id}", method: "DELETE")
  }
`;
