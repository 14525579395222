import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NotePencil, Plus, Trash, Star } from '@phosphor-icons/react';
import tw from 'twin.macro';

import { Coworker } from 'types/formTypes';
import { IntakeQuestion } from 'types/intakeTypes';
import Label from 'components/form/Label';
import ModalCoWorker from 'components/form/modal/ModalCoWorker';

const InviteCoworker = ({
  name,
  question,
  required = false,
}: {
  name?: string;
  question?: IntakeQuestion;
  required?: boolean;
}) => {
  const { setValue, getValues, register, trigger } = useFormContext();

  const getCoworkersFromStore = (): Coworker[] => {
    if (question && question.value)
      return question.value.constructor === Array
        ? (question.value as Coworker[])
        : (JSON.parse(question.value.toString()) as Coworker[]);
    return [];
  };

  const [coworkers, setCoworkers] = useState(getCoworkersFromStore());
  const [currentCoworker, setCurrentCoworker] = useState<Coworker>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [formIsShown, setFormIsShown] = useState(false);

  const questionId = question ? question.id.toString() : name ?? '';

  const addCoworkerHandler = async () => {
    if (!(await trigger(['firstname', 'lastname', 'email', 'function'])))
      return;

    const {
      firstname,
      lastname,
      email,
      function: func,
      isLeadRecruiter,
    } = getValues();
    let workers = [];

    if (isEditMode)
      workers = coworkers.filter(
        (worker) => worker.email !== currentCoworker?.email
      );
    else workers = coworkers.filter((worker) => worker.email !== email);

    if (isLeadRecruiter)
      workers = workers.map((worker) =>
        worker.isLeadRecruiter ? { ...worker, isLeadRecruiter: false } : worker
      );

    setCoworkers([
      ...workers,
      {
        firstname,
        lastname,
        email,
        function: func,
        isLeadRecruiter,
      },
    ]);
    setFormIsShown(false);
  };

  const editCoworkerHandler = async (coworker: Coworker) => {
    setFormIsShown(true);
    setIsEditMode(true);
    setCurrentCoworker(coworker);
  };

  const deleteCoworkerHandler = (worker: Coworker) =>
    setCoworkers(coworkers.filter((w) => w.email !== worker.email));

  const modalCloseHandler = () => {
    setFormIsShown(false);
    setIsEditMode(false);
    setCurrentCoworker(undefined);
  };

  useEffect(() => {
    setValue(
      questionId,
      coworkers && coworkers.length ? JSON.stringify(coworkers) : null,
      {
        shouldValidate: false,
      }
    );
  }, [questionId, coworkers, setValue]);

  return (
    <div css={tw`space-y-1`}>
      <Label htmlFor={questionId}>
        {question ? question.label : 'Who do you want to invite?'}
      </Label>

      <input
        tw="hidden"
        name={questionId}
        type="text"
        ref={register({
          required,
        })}
      />

      <div
        onClick={() => setFormIsShown(!formIsShown)}
        tw="flex cursor-pointer items-center justify-center rounded-md border border-gray-100 text-gray-400 bg-gray-50 p-4 hover:(border-gray-200 text-gray-500) active:bg-gray-200/80"
      >
        <Plus weight="bold" />
      </div>

      <ul tw="grid grid-cols-2 gap-x-3">
        {coworkers.map((worker) => (
          <li
            tw="flex mt-4 items-center rounded-md border-2 border-gray-300 p-2 transition-shadow hover:shadow-md"
            key={worker.email}
          >
            <div tw="flex w-full justify-between">
              <div tw="flex max-w-[85%] flex-1 flex-col">
                <div tw="mb-2">
                  <div tw="flex items-center">
                    <p tw="truncate text-gray-600">
                      {worker.firstname} {worker.lastname}
                    </p>
                    {worker.isLeadRecruiter && (
                      <Star
                        weight="fill"
                        tw="ml-1 min-w-[1em] text-indigo-300"
                      />
                    )}
                  </div>

                  <p tw="mt-2 inline-block max-w-full truncate rounded-md bg-indigo-100 px-2 py-0 text-xs font-medium leading-5 text-indigo-500">
                    {worker.function}
                  </p>
                </div>
                <p tw="truncate font-medium text-gray-500">{worker.email}</p>
              </div>
              <div tw="flex flex-col space-y-2">
                <NotePencil
                  weight="bold"
                  onClick={() => editCoworkerHandler(worker)}
                  tw="cursor-pointer text-2xl text-gray-300 hover:text-gray-500 focus:text-gray-500"
                />
                <Trash
                  weight="bold"
                  onClick={() => deleteCoworkerHandler(worker)}
                  tw="cursor-pointer text-2xl text-gray-300 hover:text-gray-500 focus:text-gray-500"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>

      <ModalCoWorker
        isShown={formIsShown}
        onModalClose={modalCloseHandler}
        isEditMode={isEditMode}
        onSave={addCoworkerHandler}
        currentCoWorker={currentCoworker}
      />
    </div>
  );
};

export default InviteCoworker;
