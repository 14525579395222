import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import tw, { css, styled } from 'twin.macro';

import { IBaseInputProps, FormData } from 'types/formTypes';
import { questionRules } from 'utils/formHelpers';
import { Body } from 'components/typography/Typography';

interface IProps extends IBaseInputProps {
  options: string[];
}

const ButtonGroup = (props: IProps) => {
  const { register, errors, setValue } = useFormContext();

  const questionId = props.question.id.toString();

  const getInitialSelectedOption = (): string | undefined =>
    props.question.value?.toString() ??
    (props.options[0] as string) ??
    undefined;

  const [selectedOptions, setSelectedOptions] = useState<FormData | undefined>(
    getInitialSelectedOption()
      ? {
          [props.question.id]: getInitialSelectedOption() as string,
        }
      : undefined
  );

  const optionClickedHandler = (option: string | null) => {
    if (!option) return;
    if (selectedOptions && selectedOptions[parseInt(questionId)] === option)
      setSelectedOptions(undefined);
    else setSelectedOptions({ [parseInt(questionId)]: option });
  };

  useEffect(() => {
    setValue(
      questionId,
      selectedOptions ? selectedOptions[props.question.id] : null
    );
  }, [props.question.id, questionId, selectedOptions, setValue]);

  return (
    <>
      {props.options && (
        <>
          <select
            hidden
            ref={register(questionRules(props.question))}
            name={questionId}
            id={questionId}
            aria-invalid={errors[questionId] !== undefined}
            onChange={() => {}}
          >
            {props.options.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>

          <GroupWrapper>
            {props.options.map((option) => (
              <ButtonGroupButton
                type="button"
                isActive={
                  selectedOptions !== undefined &&
                  selectedOptions[props.question.id] === option
                }
                key={option}
                amountOfButtons={props.options.length}
                onClick={() => optionClickedHandler(option)}
              >
                <Body>{option}</Body>
              </ButtonGroupButton>
            ))}
          </GroupWrapper>
        </>
      )}
    </>
  );
};

const GroupWrapper = styled.span(() => [
  tw`flex justify-center ring-1 ring-black/5 rounded-md min-w-full bg-white p-2`,
]);

interface IButtonGroupButtonProps {
  amountOfButtons: number;
  isActive: boolean;
}

const ButtonGroupButton = styled.button<IButtonGroupButtonProps>`
  ${tw`px-2 overflow-hidden rounded-md text-gray-500`}

  ${({ isActive }) =>
    isActive &&
    tw`px-2 bg-gray-200 text-gray-700 overflow-hidden ring-1 ring-black/5 font-medium`}

  ${({ amountOfButtons }) => css`
    width: ${100 / amountOfButtons}%;
    height: 50px;
  `}
`;

export default ButtonGroup;
