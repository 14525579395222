export const getDateString = (date: Date = new Date()): string => {
  return new Date(date.toString().replace(/-/g, '/')).toLocaleDateString(
    'nl-BE',
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
  );
};
