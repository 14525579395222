import tw, { styled } from 'twin.macro';
import React from 'react';
import SocialIcon from 'components/icons/SocialIcon';

interface WhatsAppButtonProps {
  phone: string;
  isIcon?: boolean;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phone, isIcon }) => {
  return (
    <>
      {phone.startsWith('+') && (
        <a
          href={`https://wa.me/${phone.replace(/[^+\d]+/g, '')}`}
          tw="block"
          target="_blank"
          rel="noreferrer"
        >
          {isIcon ? (
            <SocialIcon
              company="whatsapp"
              width="1rem"
              height="1rem"
              color="currentColor"
              tw="h-5 w-5 text-gray-400"
              aria-hidden="true"
              grayScale
            />
          ) : (
            <ContactLinkWhatsApp>Whatsapp</ContactLinkWhatsApp>
          )}
        </a>
      )}
    </>
  );
};

const ContactLinkWhatsApp = styled.div(() => [
  tw`
    rounded-md px-4 py-2 border border-gray-100 transition
  bg-white text-gray-700 text-sm font-medium
    hover:(bg-gray-50)
  `,
]);
export default WhatsAppButton;
