import {
  startOfDay,
  endOfDay,
  subDays,
  subMonths,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfMonth,
  endOfYear,
  subWeeks,
  endOfWeek,
} from 'date-fns';
import type { DateRange } from 'rsuite/esm/DateRangePicker';

type datePickerRangeType = {
  label: string;
  value: DateRange;
  placement: 'left' | 'bottom' | undefined;
};

export const datePickerRanges: datePickerRangeType[] = [
  {
    label: 'today',
    value: [startOfDay(new Date()), endOfDay(new Date())],
    placement: 'left',
  },
  {
    label: 'yesterday',
    value: [
      startOfDay(subDays(new Date(), 1)),
      endOfDay(subDays(new Date(), 1)),
    ],
    placement: 'left',
  },
  {
    label: 'this week',
    value: [startOfWeek(new Date()), endOfDay(new Date())],
    placement: 'left',
  },
  {
    label: 'last week',
    value: [
      startOfWeek(subWeeks(new Date(), 1)),
      endOfWeek(subWeeks(new Date(), 1)),
    ],
    placement: 'left',
  },
  {
    label: 'this month',
    value: [startOfMonth(new Date()), endOfDay(new Date())],
    placement: 'left',
  },
  {
    label: 'last month',
    value: [
      startOfMonth(subMonths(new Date(), 1)),
      endOfMonth(subMonths(new Date(), 1)),
    ],
    placement: 'left',
  },
  {
    label: 'this year',
    value: [startOfYear(new Date()), endOfDay(new Date())],
    placement: 'left',
  },
  {
    label: 'last year',
    value: [
      startOfYear(subMonths(new Date(), 12)),
      endOfYear(subMonths(new Date(), 12)),
    ],
    placement: 'left',
  },
];
