import 'twin.macro';
import React from 'react';

import { Routes } from 'types/routeTypes';
import Centered from 'components/centered/Centered';
import { getCurrentAuthToken } from 'utils/authHelpers';

export const ErrorPage: React.FC = () => {
  const isAuthenticated = getCurrentAuthToken();

  return (
    <Centered>
      <p tw="text-gray-800 text-lg" tabIndex={-1}>
        It seems that something went wrong, please try again.
      </p>
      <a
        tw="mt-5 text-sympl border border-sympl px-4 py-2"
        tabIndex={0}
        href={isAuthenticated ? Routes.ROOT : Routes.LOGIN}
      >
        {isAuthenticated ? 'Go to my dashboard' : 'Go back'}
      </a>
    </Centered>
  );
};
