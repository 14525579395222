import React, { useEffect, useMemo, useRef } from 'react';
import tw, { styled } from 'twin.macro';
import { ArrowUp } from '@phosphor-icons/react';
import { useMutation } from 'hooks/sympl-mutation';

import Notification from 'components/notifications/notifications-tab/Notification';
import { useNotifications } from 'hooks/notificationHooks';
import { AppNotification, NotificationStatus } from 'types/notificationTypes';
import useDefocusHandler from 'hooks/defocus';
import { MARK_ALL_AS_READ } from 'graphql/notifications/mutations';
import { GET_NOTIFICATIONS } from 'graphql/notifications/queries';
import { useQuery } from 'hooks/sympl-query';

export const NotificationTab: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { notifications, toggleNotificationTab, updateNotifications } =
    useNotifications();

  const { loading, data: notificationsData } = useQuery<{
    notifications: AppNotification[];
  }>(GET_NOTIFICATIONS);

  useEffect(() => {
    if (notificationsData) {
      updateNotifications(
        notificationsData.notifications.map((notification) => ({
          id: notification.id,
          title: notification.title,
          description: notification.description,
          date: notification?.date
            ? new Date(notification.date.toString().replace(/-/g, '/'))
            : new Date(),
          status: notification.status,
          type: notification.type,
          url: notification.url,
          message: '',
        }))
      );
    }
  }, [notificationsData, updateNotifications]);

  const filteredNotifications = useMemo(
    () =>
      notifications.sort(
        (notificationA, notificationB) =>
          notificationB.date.getTime() - notificationA.date.getTime()
      ),
    [notifications]
  );

  const [markAllAsRead] = useMutation(MARK_ALL_AS_READ);

  const markAllAsReadHandler = () => {
    markAllAsRead().then(() => {
      updateNotifications(
        notifications.map((notification) => {
          notification.status = NotificationStatus.READ;
          return notification;
        })
      );
    });
  };

  useDefocusHandler(wrapperRef, () => {
    toggleNotificationTab(false);
  });

  if (loading) {
    return (
      <Wrapper>
        <div tw="relative flex items-center border-b-2 bg-gray-200 h-full w-full animate-pulse rounded-md"></div>
      </Wrapper>
    );
  }

  return (
    <Wrapper ref={wrapperRef}>
      <div tw=" justify-between flex p-6 border-b-2 h-auto place-items-center">
        <TabLabel>Notifications</TabLabel>
        <div tw="flex gap-6 lg:(gap-0)">
          <ActionsContainer>
            <ReadAllButton onClick={markAllAsReadHandler}>
              <ReadAllWrapper>Mark all as read </ReadAllWrapper>
            </ReadAllButton>
          </ActionsContainer>
          <CloseWrapper>
            <CloseButton
              onClick={() => {
                toggleNotificationTab(false);
              }}
            >
              <span tw="sr-only">Close</span>
              <IconWrapper>
                <ArrowUp
                  weight="bold"
                  tw="block lg:(hidden h-5 w-5)"
                  aria-hidden="true"
                />
              </IconWrapper>
            </CloseButton>
          </CloseWrapper>
        </div>
      </div>
      {notifications.length > 0 ? (
        <NotificationsWrapper>
          <div>
            <div>
              {filteredNotifications.map((notification) => (
                <div>
                  <Notification
                    key={notification.id}
                    notification={notification}
                  />
                </div>
              ))}
            </div>
          </div>
        </NotificationsWrapper>
      ) : (
        <div tw="h-full w-full flex flex-auto justify-center items-center">
          <p tw="text-base font-medium text-gray-700 py-5">
            No notifications at this moment
          </p>
        </div>
      )}
    </Wrapper>
  );
};

const CloseButton = styled.button(
  tw`
    rounded-md inline-flex text-gray-400
    hover:text-gray-500 focus:(outline-none ring-2 ring-offset-2 ring-indigo-400)
  `
);

const CloseWrapper = styled.div(tw`shrink-0 flex self-center`);

const TabLabel = styled.p(tw`text-gray-700 text-xs self-center md:text-base`);

const NotificationsWrapper = styled.div(
  tw`overflow-y-scroll row-span-4 h-full lg:border-b-2`
);

const Wrapper = styled.div(
  tw`
    bg-white opacity-100 w-full fixed z-50 shadow-md overflow-hidden flex flex-col rounded-b-md top-16 left-0 min-h-[30%] max-h-[2/3] text-[#5145cd]
    lg:(top-0 left-64 max-h-[45rem] min-h-[40%] w-[26rem] rounded-md)
  `
);

const IconWrapper = styled.i(tw`visible lg:invisible`);

const ActionsContainer = styled.div(
  tw`flex items-center justify-between w-auto`
);

const ReadAllButton = styled.button(tw`text-gray-400 hover:text-gray-500`);

const ReadAllWrapper = styled.p(tw`underline text-xs flex items-center`);

export default NotificationTab;
