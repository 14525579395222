import 'twin.macro';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IntakeQuestion } from 'types/intakeTypes';

import VatInput from 'components/form/VatInput';

const IntakeVat: React.FC<{
  question: IntakeQuestion;
  defaultValue: string;
}> = ({ question, defaultValue }) => {
  const { control, errors } = useFormContext();

  const [vatValidator, setVatValidator] =
    useState<() => (vat?: string) => boolean>();

  const id = useMemo(() => question.id.toString(), [question.id]);

  const changeHandler = (
    onChange: (value: string) => void,
    e?: React.ChangeEvent<HTMLInputElement>,
    validator?: () => (vat?: string) => boolean
  ) => {
    e?.preventDefault();

    const val = e?.currentTarget?.value;

    validator && setVatValidator(() => validator);

    if (!val && val !== '') return;

    onChange?.(val);
  };

  const vatValidationHandler = (vat: string): boolean => {
    if (vat === '') return true;
    return vatValidator ? vatValidator()(vat) : true;
  };

  return (
    <>
      <Controller
        id={id}
        name={id}
        rules={{ validate: vatValidationHandler }}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange }) => (
          <VatInput
            value={defaultValue}
            onChange={(e) => changeHandler?.(onChange, e)}
            onBlur={(e, validator) => changeHandler?.(onChange, e, validator)}
          />
        )}
      />
      {errors[id] !== undefined && (
        <p tw="mt-1 text-xs leading-5 text-red-400">
          Please provide a valid VAT
        </p>
      )}
    </>
  );
};

export default IntakeVat;
