import { useQuery } from 'hooks/sympl-query';
import { useMutation } from 'hooks/sympl-mutation';
import 'twin.macro';

import Button from 'components/button/Button';
import { INVITE_COLLEAGUE } from 'graphql/customers/mutations';
import { GET_CUSTOMER_USERS } from 'graphql/customers/queries';
import useAppSession from 'hooks/session';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Plus } from '@phosphor-icons/react';
import { Customer } from 'types/customer/types';
import Container from 'components/container/Container';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import ColleaguesList from './ColleaguesList';
import { Title2 } from 'components/typography/Typography';
import { RESEND_INVITE_COLLEAGUE, UNLINK_USER } from 'graphql/users/mutations';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import { User } from 'types/userTypes';
import ModalCoWorker from 'components/form/modal/ModalCoWorker';

type InviteColleague = 'firstname' | 'lastname' | 'email' | 'function';

interface CustomerColleagues extends Customer {
  users: User[];
}

interface InviteColleagueData {
  email: string;
  first_name: string;
  last_name: string;
  job_position: string;
}

interface InviteColleaguePayload {
  input: InviteColleagueData;
}

const UsersConfig: React.FC = () => {
  const session = useAppSession();
  const activeCustomer = session?.activeCustomer;

  const formMethods = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      function: '',
    },
  });
  const { handleSubmit, reset: resetForm } = formMethods;
  const { addToast } = useToastNotifications();

  const [formIsShown, setFormIsShown] = useState(false);

  const { data: customerColleagues, refetch: refetchColleagues } = useQuery<
    { customer: CustomerColleagues },
    { customerId: number }
  >(GET_CUSTOMER_USERS, {
    skip: !activeCustomer,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { customerId: activeCustomer as number },
  });

  const [inviteColleague, { loading: inviteColleagueLoading }] = useMutation<
    undefined,
    InviteColleaguePayload
  >(INVITE_COLLEAGUE);

  const [resendInviteColleague, { loading: resendingInvite }] = useMutation<
    {},
    { userId: number }
  >(RESEND_INVITE_COLLEAGUE);

  const [unLinkUser] = useMutation<{}, { userId: number }>(UNLINK_USER);

  const submitHandler = async (data: {
    [K in InviteColleague]?: string;
  }) => {
    await inviteColleague({
      variables: {
        input: {
          email: data['email']?.toString() ?? '',
          first_name: data['firstname']?.toString() ?? '',
          last_name: data['lastname']?.toString() ?? '',
          job_position: data['function']?.toString() ?? '',
        },
      },
    });
    addToast({
      type: ToastTypes.SUCCESS,
      description: `You have successfully invited ${data['firstname']} ${data['lastname']}`,
    });
    resetForm();
    refetchColleagues();
    setFormIsShown(false);
  };

  const resendInvite = (colleague: User) => {
    resendInviteColleague({
      variables: {
        userId: colleague.id,
      },
    })
      .then(() => {
        addToast({
          type: ToastTypes.SUCCESS,
          description:
            'We have sent an invitation mail to' +
            ` ${colleague.firstname} ${colleague.lastname}`,
        });
      })
      .catch(() => {
        addToast({
          description:
            'We were unable to sent an invitation mail to' +
            ` ${colleague.firstname} ${colleague.lastname}` +
            ', please try again. Please contact us if this problem persists!',
          type: ToastTypes.ERROR,
        });
      });
  };

  const unLinkColleague = async (colleague: User) => {
    await unLinkUser({
      variables: {
        userId: colleague.id,
      },
    });

    refetchColleagues();
  };

  return (
    <Container>
      <ContainerHeader>
        <div tw="flex flex-row justify-between">
          <Title2 mb={0}>Users</Title2>
          <Button
            onClick={() => setFormIsShown(true)}
            icon={<Plus weight="bold" />}
          >
            Add user
          </Button>
        </div>
      </ContainerHeader>
      <div tw="flex w-full flex-row flex-wrap gap-5 mt-4">
        <div tw="flex w-full flex-col">
          <ColleaguesList
            onResend={resendInvite}
            onUnlink={unLinkColleague}
            colleagues={customerColleagues?.customer.users ?? []}
            loading={resendingInvite}
          />
        </div>
        <div tw="border"></div>
        <FormProvider {...formMethods}>
          <form autoComplete="on" tw="w-full">
            <ModalCoWorker
              isShown={formIsShown}
              onModalClose={() => setFormIsShown(false)}
              isEditMode={false}
              onSave={() => handleSubmit(submitHandler)()}
              onSaveLoading={inviteColleagueLoading}
              includeLeadRecruiter={false}
            />
          </form>
        </FormProvider>
      </div>
    </Container>
  );
};

export default UsersConfig;
