import React from 'react';
import tw, { styled } from 'twin.macro';

import { IntakeQuestion } from 'types/intakeTypes';
import Annotation from './Annotation';
import Tooltip from './Tooltip';

interface LabelProps {
  question?: IntakeQuestion;
  htmlFor: string;
  children: React.ReactNode;
  hasAnnotation?: boolean;
  showAnnotationText?: boolean;
  fullWidth?: boolean;
}

const Label: React.FC<LabelProps> = ({
  question,
  htmlFor,
  children,
  hasAnnotation,
  showAnnotationText,
  fullWidth = true,
}) => {
  return (
    <div tw="flex flex-col" css={[fullWidth && tw`w-full`]}>
      <LabelContentsWrapper>
        <Text htmlFor={htmlFor}>
          {question && (
            <>
              {!question?.required && <RequiredStatus>Optional</RequiredStatus>}
            </>
          )}
          {children}
        </Text>
        {question && question.helper && <Tooltip tip={question.helper} />}
        {question && hasAnnotation && (
          <Annotation withText={showAnnotationText} question={question} />
        )}
      </LabelContentsWrapper>
    </div>
  );
};

const LabelContentsWrapper = styled.div(() => [
  tw`
    flex flex-row flex-wrap justify-between items-center w-full mb-2
    sm:mt-px sm:pt-2
  `,
]);

const RequiredStatus = styled.span(() => [tw`text-gray-300 text-sm mb-1`]);

const Text = styled.label`
  ${tw`flex flex-col font-medium leading-5 w-full sm:w-auto text-gray-600`}
`;

export default Label;
