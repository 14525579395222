import { gql } from '@apollo/client';

export const GET_INTEGRATION_LINK = gql`
  query getIntegrationLink {
    integration @rest(type: "IntegrationLink", path: "/v4/integrations/init") {
      link
    }
  }
`;
export const GET_INTEGRATIONS = gql`
  query getIntegrations {
    integrations @rest(type: "[Integrations]", path: "/v4/integrations") {
      type
      external_id
      friendly_name
      logo_url
      icon_url
    }
  }
`;

export const GET_EXTERNAL_JOBS = gql`
  query getExternalJobs {
    externalJobs
      @rest(
        type: "[IntegrationExternalJob]"
        path: "/v4/integrations/external-jobs"
      ) {
      id
      name
      status
      integration_id
    }
  }
`;
