import 'twin.macro';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Coworker } from 'types/formTypes';
import Label from 'components/form/Label';
import InviteCoworker from 'components/form/InviteCoworker';
import Checkbox from 'components/form/checkbox/Checkbox';

import { CopyToClipboard } from 'components/copy-to-clipboard/CopyToClipboard';
import Dropdown from 'components/dropdown/Dropdown';
import { GET_INTAKES_SHORT } from 'graphql/intakes/queries';
import { Intake } from 'types/intakeTypes';
import { useLazyQuery, useQuery } from 'hooks/sympl-query';
import useNavigationContext from 'hooks/context/nav-context';
import Input from 'components/form/input/Input';
import SubLabel from 'components/form/SubLabel';
import IntakeInitInput from 'components/form/input/IntakeInitInput';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import InvoiceBuilder, {
  calculateTotal,
} from 'components/invoicing/InvoiceBuilder';
import { LineItem } from 'types/invoicing/types';
import { GET_CUSTOMERS_WITH_SUB } from 'graphql/customers/queries';
import { GET_PLANS_INVOICE_ITEMS } from 'graphql/subscriptions/queries';
import { PlanInvoiceItems } from 'types/plans/types';
import { Error, Footnote } from 'components/typography/Typography';
import { Interval } from 'types/subscriptions/types';
import { Routes } from 'types/routeTypes';
import { Link } from 'react-router-dom';
import Button from 'components/button/Button';
import useIntakeCoreContext from 'hooks/context/intakes-context';

interface Customer {
  id: number;
  name: string;
  plan_id: number | null;
  interval: string | null;
  payment_term: number | null;
}

const DEFAULT_PLAN_ID = 2;
const DEFAULT_INTERVAL = Interval.QUARTERLY;

const InitIntake = () => {
  const { createIntake, fetchTemplates, fetchCurrentIntakeTypes } =
    useIntakeCoreContext();
  const { addToast } = useToastNotifications();
  const {
    isAdmin,
    setActiveCustomer,
    customer: activeCustomer,
  } = useNavigationContext();

  const formMethods = useForm();
  const { control, errors, setValue, getValues, reset, handleSubmit, watch } =
    formMethods;

  const [intakeUrl, setIntakeUrl] = useState('');

  const [selectedPlan, setSelectedPlan] = useState<number>(DEFAULT_PLAN_ID);
  const [selectedInterval, setSelectedInterval] =
    useState<string>(DEFAULT_INTERVAL);

  const [createInvoice, setCreateInvoice] = useState(true);
  const [sendMailRequired, setSendMailRequired] = useState(true);
  const [isCreatingIntake, setIsCreatingIntake] = useState(false);

  const [, { refetch: refetchIntakes, data: intakesData }] = useLazyQuery<{
    intakes: Intake[];
  }>(GET_INTAKES_SHORT, {
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingCustomers,
    data: customers,
    refetch: refetchCustomers,
  } = useQuery<{
    customers: Customer[];
  }>(GET_CUSTOMERS_WITH_SUB);

  const { data: plansData } = useQuery<
    {
      plans: PlanInvoiceItems[];
    },
    {}
  >(GET_PLANS_INVOICE_ITEMS, {
    fetchPolicy: 'network-only',
    skip: !customers,
  });

  const filteredIntakes = useMemo(
    () =>
      intakesData?.intakes
        ?.filter(
          ({ customer_id }: Intake) =>
            customer_id === getValues('intake-customer')
        )
        .map(({ id, name }: Intake) => ({
          key: id,
          label: name,
        })) ?? [],
    [intakesData]
  );

  const getIntakeConfig = (data: any) => {
    return {
      templateId: 1,
      name: data['intake-name'],
      type: 'BOOSTER-PACK',
      locale: '141',
      customer: data['intake-customer']?.toString(),
      intakeId: data['intake-previous']?.toString(),
      invitees: data['invite-coworkers']
        ? (JSON.parse(data['invite-coworkers']) as Coworker[])
        : [],
      sendMails: data['intake-send-mails'],
      createInvoice: data['intake-create-invoice'],
      lineItems: createInvoice ? data['intake-line-items'] : [],
      planId: selectedPlan,
      interval: selectedInterval,
      paymentTerm: parseInt(data['intake-payment-term']),
    };
  };

  const resetForm = () => {
    reset();
    setCreateInvoice(true);
    setSendMailRequired(true);
    setValue('intake-create-invoice', true);
    setValue('intake-send-mails', true);
    setValue('invite-coworkers', '');

    planChangeHandler(DEFAULT_PLAN_ID, DEFAULT_INTERVAL);
  };

  const onSubmit = (data: any) => {
    const intakeConfig = getIntakeConfig(data);

    setIsCreatingIntake(true);

    createIntake(intakeConfig)
      .then(({ intakes }: { intakes: any }) => {
        addToast({
          title: `Successfully created intake ${intakeConfig.name}`,
          type: ToastTypes.SUCCESS,
          description: '',
        });
        setIntakeUrl(`/intake/${intakes[Object.keys(intakes)[0]].hash}`);
        resetForm();
        (async () => await refetchCustomers())();
      })
      .catch((error: any) => {
        console.error(error);
        addToast({
          title: `Failed to created intake ${intakeConfig.name}`,
          type: ToastTypes.ERROR,
          description: '',
        });
      })
      .finally(() => setIsCreatingIntake(false));
  };

  const customerChangeHandler = async (value: string | number) => {
    if (!Number.isNaN(parseInt(value as string))) {
      const customer = customers?.customers?.find(({ id }) => id === value);
      setActiveCustomer(customer?.id);
      setValue('intake-payment-term', customer?.payment_term);
      setValue('intake-previous', '');
      await refetchIntakes();
    }
  };

  useEffect(() => {
    if (watch('intake-customer') === undefined) return;
    (async () => customerChangeHandler(activeCustomer?.id as number))();
  }, [activeCustomer]);

  const planChangeHandler = (planId: number, interval: string) => {
    setSelectedPlan(planId);
    setSelectedInterval(interval);
  };

  const lineItemsValidator = (items: LineItem[]) => {
    const totalAmount = calculateTotal(items);

    if (totalAmount < 0) return false;

    const temp =
      !!(
        items.length &&
        !items.find(
          (item) =>
            item.type === undefined ||
            item.name === undefined ||
            item.quantity === undefined ||
            item.value === undefined ||
            !item.type.trim() ||
            !item.name.trim()
        )
      ) && totalAmount > 0;

    return temp;
  };

  useEffect(() => {
    fetchTemplates();
    fetchCurrentIntakeTypes();
  }, []);

  // Don't show content to non-admin users.
  if (!isAdmin) return <></>;

  return (
    <div tw="w-full h-full grid place-items-center overflow-auto">
      <Link to={Routes.ROOT} tw="mt-4">
        <Footnote>Back to app</Footnote>
      </Link>
      <FormProvider {...formMethods}>
        <form
          className="isAdmin"
          tw="space-y-4 my-8 p-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1 tw="uppercase text-xs tracking-wider text-gray-500 font-medium">
            Send a <b>booster</b> intake to a customer
          </h1>

          <div>
            <IntakeInitInput
              label={
                'New customer - type the customer name' +
                '; ' +
                'Existing customer - select from the list'
              }
              errorMessage={'This field is required'}
              hasError={errors['intake-customer']}
            >
              <Controller
                id="intake-customer"
                name="intake-customer"
                rules={{ required: true }}
                control={control}
                render={({ onChange }: { onChange: any }) => (
                  <Dropdown
                    loading={loadingCustomers}
                    items={
                      customers?.customers.map((customer: Customer) => ({
                        key: customer.id,
                        label: customer.name,
                      })) ?? []
                    }
                    customValueType={'customer'}
                    allowCustomValue={true}
                    onChange={(value) => {
                      if (!Number.isNaN(parseInt(value as string))) {
                        setActiveCustomer(value as number);
                      }
                      onChange(value);
                    }}
                  />
                )}
              />
            </IntakeInitInput>
          </div>
          {getValues('intake-customer') && filteredIntakes.length > 0 && (
            <div tw="space-y-1">
              <Label htmlFor="intake-previous">
                Duplicate a previous intake (optional)
              </Label>
              <Controller
                id="intake-previous"
                name="intake-previous"
                control={control}
                render={({ onChange }: { onChange: any }) => (
                  <Dropdown items={filteredIntakes} onChange={onChange} />
                )}
              />
            </div>
          )}
          <IntakeInitInput
            label={"What's the name of this intake?"}
            errorMessage={'This field is required'}
            hasError={errors['intake-name']}
          >
            <Controller
              id="intake-name"
              name="intake-name"
              control={control}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  id="intake-name"
                  name="intake-name"
                  value={value}
                  defaultValue={''}
                  aria-invalid={errors['intake-name']}
                  onChange={onChange}
                />
              )}
            />
          </IntakeInitInput>

          <IntakeInitInput
            label="Select a plan"
            errorMessage={'This field is required'}
            hasError={false}
          >
            <Dropdown
              label={
                plansData?.plans.find(
                  ({ id, interval }) =>
                    id === selectedPlan && interval === selectedInterval
                )?.name
              }
              value={selectedPlan}
              items={
                plansData?.plans.map(({ id, interval, name }) => ({
                  key: id + '/' + interval,
                  label: name,
                })) ?? []
              }
              onChange={(value) => {
                const [plan, interval] = (value as string).split('/');
                planChangeHandler(parseInt(plan, 10), interval);
              }}
              stretch={true}
            />
          </IntakeInitInput>

          <div tw="space-y-1">
            <Label htmlFor="intake-create-invoice">
              Do you want to create an invoice?
            </Label>

            <div tw="flex flex-row items-center">
              <Controller
                id="intake-create-invoice"
                name="intake-create-invoice"
                defaultValue={true}
                control={control}
                render={({ onChange, value }) => (
                  <Checkbox
                    id="intake-create-invoice"
                    name="intake-create-invoice"
                    checked={value}
                    onCheck={(checked) => {
                      onChange(checked);
                      setCreateInvoice(checked);
                    }}
                  />
                )}
              />

              <p tw="ml-2 text-gray-700 font-medium">Yes</p>
            </div>
          </div>

          {createInvoice && (
            <div>
              <Controller
                id="intake-line-items"
                name="intake-line-items"
                control={control}
                rules={{
                  required: true,
                  validate: lineItemsValidator,
                }}
                defaultValue={[]}
                render={({
                  value,
                  onChange,
                }: {
                  value: LineItem[];
                  onChange: (value: LineItem[]) => void;
                }) => <InvoiceBuilder lineItems={value} onChange={onChange} />}
              />

              {errors['intake-line-items'] && (
                <Error>
                  {`This field is required because you selected to create an invoice`}
                </Error>
              )}
            </div>
          )}

          <div>
            <Label htmlFor="intake-send-mails">
              Do we need to send out emails?
            </Label>
            <SubLabel htmlFor="intake-send-mails">
              Make sure to add invitees!
            </SubLabel>

            <div tw="flex flex-row items-center">
              <Controller
                id="intake-send-mails"
                name="intake-send-mails"
                defaultValue={true}
                control={control}
                render={({ onChange, value }) => (
                  <Checkbox
                    id="intake-send-mails"
                    name="intake-send-mails"
                    checked={value}
                    onCheck={(checked) => {
                      onChange(checked);
                      setSendMailRequired(checked);
                    }}
                  />
                )}
              />
              <p tw="ml-2 text-gray-700 font-medium">Yes</p>
            </div>
          </div>

          <IntakeInitInput
            label={''}
            errorMessage={
              'This field is required because you selected to send out emails'
            }
            hasError={errors['invite-coworkers']}
          >
            <div tw="w-full">
              <InviteCoworker
                name="invite-coworkers"
                required={sendMailRequired}
              />
            </div>
          </IntakeInitInput>

          <IntakeInitInput
            label="Payment term (in days)"
            errorMessage="This field is required"
            hasError={errors['intake-payment-term']}
          >
            <Controller
              id="intake-payment-term"
              name="intake-payment-term"
              control={control}
              defaultValue={14}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  type={'number'}
                  min={0}
                  id="intake-payment-term"
                  name="intake-payment-term"
                  value={value}
                  aria-invalid={errors['intake-payment-term']}
                  onChange={onChange}
                />
              )}
            />
          </IntakeInitInput>
          <div>
            <Button
              type="submit"
              stretch
              loading={isCreatingIntake}
              disabled={isCreatingIntake}
            >
              Start intake
            </Button>
          </div>

          {intakeUrl && (
            <div tw="mt-2">
              <CopyToClipboard url={`https://app.sympl.works${intakeUrl}`} />
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default InitIntake;
