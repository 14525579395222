import React from 'react';
import { CaretLeft } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { IntakeQuestion } from 'types/intakeTypes';
import { Routes } from 'types/routeTypes';
import QuestionAnswer from '../intake/QuestionAnswer';
import { Body } from 'components/typography/Typography';

export interface ModifiedIntakeQuestion extends IntakeQuestion {
  answer: IntakeQuestion['friendlyValue'] | IntakeQuestion['value'];
}

const QuestionAnswerList: React.FC<{
  items: ModifiedIntakeQuestion[];
  name?: string;
}> = ({ items }) => {
  return (
    <div tw="mt-4 pb-12">
      <Link
        tw="mb-6 flex items-center justify-between text-gray-600"
        to={Routes.INTAKES}
      >
        <div tw="flex flex-row items-center">
          <CaretLeft weight="bold" size={20} tw="mr-2" />{' '}
          <Body>My Intakes</Body>
        </div>
      </Link>
      {items && items.length ? (
        <Wrapper id="element-to-print">
          {items.map((item) => (
            <QuestionAnswer key={item.label} item={item} />
          ))}
        </Wrapper>
      ) : (
        <Body>No items found ...</Body>
      )}
    </div>
  );
};

const Wrapper = styled.div(tw`flex flex-col space-y-4`);

export default QuestionAnswerList;
