import { gql } from '@apollo/client';

export const GET_ACTIVE_VACANCY_METRICS = gql`
  query getActiveVacancyMetrics {
    campaignMetrics
      @rest(type: "[CampaignMetrics]", path: "/v4/dashboard/active-campaigns") {
      active_vacancies {
        vac_id
        name
        total_budget
        applications
        spend_budget
        impressions
        has_custom_url
      }
    }
  }
`;
