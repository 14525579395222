import React from 'react';
import 'twin.macro';
import Container from 'components/container/Container';
import KomboButton from 'components/integrations/KomboButton';
import { useQuery } from 'hooks/sympl-query';
import { GET_INTEGRATIONS } from 'graphql/integrations/queries';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import { Body, Title2 } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';
import { showIntercom } from 'utils/intercomHelper';
import { fireEvent } from 'utils/eventHelper';

export interface Integration {
  type: string;
  friendly_name: string;
  logo_url: string;
  icon_url: string;
}

const IntegrationSettings = () => {
  const { hasIntegrationsEnabled } = useNavigationContext();

  const { data, refetch: refetchIntegrations } = useQuery<{
    integrations: Integration[];
  }>(GET_INTEGRATIONS);

  return (
    <Container>
      {hasIntegrationsEnabled ? (
        <div>
          <ContainerHeader>
            <Title2>Current Integrations</Title2>
          </ContainerHeader>

          <ul>
            {data?.integrations?.map(({ icon_url, friendly_name }) => (
              <li tw="flex flex-row items-center py-3">
                <img tw="h-10 mr-2" alt="logo" src={icon_url} />
                <Body>{friendly_name}</Body>
              </li>
            ))}
          </ul>
          <div tw="mt-4">
            <KomboButton onIntegrationCreated={refetchIntegrations} />
          </div>
        </div>
      ) : (
        <div>
          <ContainerHeader>
            <Title2>ATS Integration</Title2>
          </ContainerHeader>
          <p tw="my-3">sympl now supports integrations with over 40 ATS.</p>
          <Button
            onClick={() => {
              fireEvent('integrations_interested');
              showIntercom();
            }}
          >
            I'm interested 🙋‍♂️
          </Button>
        </div>
      )}
    </Container>
  );
};

export default IntegrationSettings;
