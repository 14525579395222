import React from 'react';
import tw, { styled } from 'twin.macro';

interface PillProps {
  selected?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

const Pill: React.FC<PillProps> = ({ selected = false, children, onClick }) => {
  return (
    <Container onClick={onClick} isSelected={selected}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ isSelected: boolean }>`
  ${tw`px-3 py-1 rounded-full text-sm font-medium leading-5 cursor-pointer`}
  ${({ isSelected }) =>
    isSelected
      ? tw`bg-indigo-100 text-indigo-600 hover:bg-indigo-200`
      : tw`bg-gray-100 text-gray-600 hover:bg-gray-200`}
`;

export default Pill;
