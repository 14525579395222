import { IntakeFormResult } from 'types/formTypes';
import { IntakeQuestion } from 'types/intakeTypes';
import { isJsonString } from './baseHelpers';

export const updateQuestionAndAnnotationInStore = (
  stateEntities: any,
  question: IntakeFormResult
) => {
  if (question.value) {
    (stateEntities[question.id] as IntakeQuestion).value = isJsonString(
      question.value.toString()
    )
      ? JSON.parse(question.value.toString())
      : question.value.constructor === Array
      ? (question.value as string[])
      : question.value.toString();
  } else {
    (stateEntities[question.id] as IntakeQuestion).value = '';
  }

  if (question.annotation)
    (stateEntities[question.id] as IntakeQuestion).annotation =
      question.annotation;
};

/**
 * Checks if an intake question value has a valid value
 * @param  {IntakeQuestion['value']} value The intake question value
 * @returns A boolean indicating if the question value is valid
 */
export const intakeQuestionHasValidValue = (
  value: IntakeQuestion['value']
): boolean => {
  if (value?.constructor === Array) {
    if (value?.length <= 0) return false;
    else if (value?.[0]?.constructor === Object)
      return Object.keys(value?.[0]).length > 0;
    else return value !== undefined;
  } else if (value?.constructor === Object) {
    return Object.keys(value).length > 0;
  } else if (value?.constructor === Number) {
    if (value === 0 || value) return true;
  } else if ((value as string).includes('files')) {
    try {
      return JSON.parse(value as string)?.files.length > 0;
    } catch (err) {
      return false;
    }
  } else if (value?.constructor === String) {
    return value.length > 0;
  } else return value !== undefined;
  return true;
};

export const getMissingQuestionsFromQuestionList = (
  questionList: IntakeQuestion[]
) => {
  if (questionList.length <= 0) return [];
  return (questionList as IntakeQuestion[]).filter((question) => {
    if (!question) return false;
    return question.required && !intakeQuestionHasValidValue(question.value);
  });
};

export const checkIfStepOptional = (
  questionList: IntakeQuestion[]
): boolean => {
  if (questionList.length <= 0) return false;
  const totalOfOptional = questionList.filter((q) => !q.required);
  return totalOfOptional.length === questionList.length;
};

export const objectToArray = (obj: any) => {
  return Object.keys(obj).map((key) => obj[key]);
};
