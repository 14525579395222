import 'twin.macro';
import React from 'react';
import Button from 'components/button/Button';
import { showKomboConnect } from '@kombo-api/connect';
import { useMutation } from 'hooks/sympl-mutation';
import { CREATE_INTEGRATION } from 'graphql/integrations/mutations';
import { useLazyQuery } from 'hooks/sympl-query';
import { GET_INTEGRATION_LINK } from 'graphql/integrations/queries';
import { useNavigate } from 'react-router-dom';

const KomboButton: React.FC<{ onIntegrationCreated?: () => void }> = ({
  onIntegrationCreated,
}) => {
  const navigate = useNavigate();

  const [getIntegrationLink] = useLazyQuery<{
    integration: {
      link: string;
    };
  }>(GET_INTEGRATION_LINK, {
    fetchPolicy: 'network-only',
  });

  const [createIntegration] = useMutation(CREATE_INTEGRATION);

  async function getKomboConnectLink() {
    const response = await getIntegrationLink();
    return response.data?.integration.link ?? '';
  }

  async function connectIntegration() {
    const link = await getKomboConnectLink();

    const activationToken = await showKomboConnect(link);

    createIntegration({
      variables: {
        input: {
          activation_token: activationToken,
        },
      },
    }).then(() => {
      onIntegrationCreated?.();
      navigate('settings/integrations-succeeded');
    });
  }

  return (
    <div>
      <Button onClick={connectIntegration}>Connect an ATS</Button>
    </div>
  );
};

export default KomboButton;
