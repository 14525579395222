import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IntakeQuestion } from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';
import { CustomerAddress } from 'types/geolocationTypes';
import AddressInput from 'components/form/AddressInput';
import { addressValidationHandler } from 'utils/addressHelpers';

const IntakeCustomerAddress: React.FC<{
  question: IntakeQuestion;
  defaultValue?: CustomerAddress;
}> = ({ question, defaultValue }) => {
  const { control, setValue } = useFormContext();
  const id = question.id.toString();

  const rules = questionRules(question);

  const changeHandler = (newCustomerAddress: CustomerAddress) => {
    if (newCustomerAddress) {
      setValue(id, newCustomerAddress);
    }
  };

  return (
    <>
      <Controller
        id={id}
        name={id}
        rules={{
          ...rules,
          validate: (value) =>
            addressValidationHandler(!!question.required, value),
        }}
        control={control}
        defaultValue={
          defaultValue ?? {
            road: '',
            house_number: '',
            box_number: '',
            zip_code: '',
            city: '',
            country_code: '',
          }
        }
        render={({ value }) => (
          <AddressInput value={value} onChange={changeHandler} />
        )}
      />
    </>
  );
};

export default IntakeCustomerAddress;
