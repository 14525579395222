import React from 'react';
import tw, { styled } from 'twin.macro';

import { AdChannel, AD_CHANNELS } from 'types/adTypes';
import ChannelButton from '../channel-button/ChannelButton';

const ChannelButtonGroup: React.FC<{
  channels: AdChannel[];
  onChannelClicked: (channel: AdChannel) => void;
  disabled?: boolean;
}> = ({ channels, onChannelClicked, disabled = false }) => {
  const channelClickedHandler = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    channel: AdChannel
  ) => {
    e.preventDefault();
    if (!disabled) onChannelClicked(channel);
  };

  return (
    <Wrapper>
      {AD_CHANNELS.map((channel) => (
        <li
          key={channel}
          tw="mr-3 last-of-type:mr-0"
          onClick={(e) => channelClickedHandler(e, channel)}
        >
          <ChannelButton
            isActive={channels.includes(channel)}
            channelType={channel}
            disabled={disabled}
            grayScale
          />
        </li>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.ul(
  tw`flex overflow-x-auto lg:overflow-x-hidden justify-start`
);

export default ChannelButtonGroup;
