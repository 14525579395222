import { VacanciesKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';
import { faker } from '@faker-js/faker';

export const defaultVacanciesSettings: VacanciesKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig(1, {
    placeholderValue: {
      title: '',
      subTitle: 'Join our team',
      description: '',
      primaryButtonLabel: '',
      linkButtonLabel: '',
    },
  }),
  image: '',
  vacancies: [...Array(3)].map(() => ({
    functionTitle: faker.person.jobTitle(),
    description: faker.lorem.paragraph(),
    url: 'https://sympl.be/vacatures',
    location: faker.location.city(),
    isPlaceholder: true,
  })),
};
