import React, { useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from '@phosphor-icons/react';
import Button from 'components/button/Button';
import Label from '../Label';
import Input from '../input/Input';
import Checkbox from 'components/checkbox/Checkbox';

interface TextEditorLinkPopupProps {
  // Function that handles the change of the editor
  onChange: Function;
  /**
   * Config file that holds configured option
   *
   * Currently only defaultTargetOption is important, feel free to expand
   */
  config: { defaultTargetOption: string };
  // Is the popup expanded? (Or should it be)
  expanded: boolean;
  // Handle expansion click event (toggle expanded)
  onExpandEvent: () => void;
  // Force expansion
  doExpand: () => void;
  // Force collapse
  doCollapse: () => void;
  // Current state
  currentState: object;
  linkCallback: () => void;
}

const TextEditorLinkPopup: React.FC<TextEditorLinkPopupProps> = ({
  onChange,
  config,
  expanded,
  onExpandEvent,
  doCollapse,
}) => {
  // Use effect to reset the form each time it is closed
  useEffect(
    () => {
      if (!expanded) {
        reset();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expanded]
  );

  /**
   * Function to add link to editor
   */
  const saveLink = () => {
    const target = linkExternal.current ? '_blank' : '_self';

    // Indicate to editor that a change has happened
    onChange('link', linkTitle, linkRef, target);
  };

  const [linkTitle, setLinkTitle] = useState('');
  const [linkRef, setLinkRef] = useState('');
  const linkExternal = useRef(config['defaultTargetOption'] === '_blank');

  /**
   * Reset inputs to default value
   */
  const reset = () => {
    setLinkTitle('');
    setLinkRef('');
    linkExternal.current = config['defaultTargetOption'] === '_blank';
  };

  const isValid = !!linkTitle && !!linkRef;

  return (
    <div tw="relative grid place-items-center">
      <div
        role="button"
        className="rdw-list-wrapper"
        aria-label="rdw-list-control"
        title="link"
        aria-expanded="false"
        tw="mb-0"
      >
        <div
          onClick={onExpandEvent}
          className="rdw-option-wrapper"
          tw="text-black"
        >
          <Link weight="bold" size={16} />
        </div>
      </div>
      {/* Stop event propagation so the popup does not close when clicking inside (without it editor will regain focus) */}
      <Popup isOpen={expanded} onClick={(e) => e.stopPropagation()}>
        <div tw="p-4 pt-2 font-sans">
          <Label tw="mb-2" htmlFor="displayLink">
            <span tw="my-2">Link Title</span>
            <Input
              name="displayLink"
              value={linkTitle}
              tabIndex={0}
              onChange={(e) => setLinkTitle(e.target.value)}
            />
          </Label>
          <Label tw="my-2" htmlFor="refLink">
            <span tw="my-2">Link URL</span>
            <Input
              name="refLink"
              tabIndex={0}
              value={linkRef}
              onChange={(e) => setLinkRef(e.target.value)}
            />
          </Label>
          <Label tw="my-2" htmlFor="externalLink">
            <div tw="flex flex-row items-center flex-nowrap gap-1">
              <Checkbox
                onChange={(value: boolean) => (linkExternal.current = value)}
              />
              <span tw="my-2 ">Open link in new window?</span>
            </div>
          </Label>
          <div tw="flex mt-4 justify-between">
            <Button tabIndex={0} onClick={() => doCollapse()} variant="outline">
              Cancel
            </Button>
            <Button tabIndex={0} disabled={!isValid} onClick={() => saveLink()}>
              Save
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

const Popup = styled.div<{ isOpen: boolean }>`
  ${tw`absolute top-9 left-0 z-30 w-max bg-white rounded-sm drop-shadow-lg shadow-lg border border-gray-100`}
  ${({ isOpen }) => !isOpen && tw`hidden`}
`;

export default TextEditorLinkPopup;
