import {
  CardsSectionResponse,
  CarouselEditableFields,
  CarouselSectionResponse,
  ContactKeyFields,
  ContactSectionResponse,
  FeatureEditableFields,
  FeatureSectionResponse,
  FooterSectionResponse,
  HeroEditableFields,
  HeroSectionResponse,
  ImageModel,
  SectionType,
  TeamSectionResponse,
  TestimonialSectionResponse,
  VacanciesEditableFields,
  VacanciesProps,
  // VacanciesSectionResponse,
  VideoSectionResponse,
} from '@symplbe/sympl-components';
import {
  TFilterDeletedSectionsFn,
  TFilterDeletedOrderFn,
  TGetHiddenKeysFn,
  TGetDefaultSectionValuesFn,
} from './sectionHelper.types';
import { defaultHeroSettings } from 'components/careers/section-renderer/sections-with-config/hero/defaultConfig';
import { defaultCarouselSettings } from 'components/careers/section-renderer/sections-with-config/carousel/defaultConfig';
import { defaultVideoSettings } from 'components/careers/section-renderer/sections-with-config/video/defaultConfig';
import { defaultFeatureSettings } from 'components/careers/section-renderer/sections-with-config/feature/defaultConfig';
import { defaultTeamSettings } from 'components/careers/section-renderer/sections-with-config/team/defaultConfig';
import { defaultVacanciesSettings } from 'components/careers/section-renderer/sections-with-config/vacancies/defaultConfig';
import { defaultCardSettings } from 'components/careers/section-renderer/sections-with-config/cards/defaultConfig';
import { defaultTestimonialsSettings } from 'components/careers/section-renderer/sections-with-config/testimonial/defaultConfig';
import { defaultContactSettings } from 'components/careers/section-renderer/sections-with-config/contact/defaultConfig';
import { defaultFooterSettings } from 'components/careers/section-renderer/sections-with-config/footer/defaultConfig';
import { LocationModel } from '@symplbe/sympl-components/dist/types/location';
import { RecruiterModel } from '@symplbe/sympl-components/dist/types/recruiter';

/**
 * @sections - object of sections with sectionId as key
 * @deletedSectionIds - array of deleted section ids
 */
export const filterDeletedSections: TFilterDeletedSectionsFn = (
  sections,
  deletedSectionIds
) => {
  const sectionsCopy = { ...sections };

  deletedSectionIds.forEach((sectionId) => {
    delete sectionsCopy[sectionId];
  });

  return sectionsCopy;
};

/**
 * @order - array of orders
 * @deletedSectionIds - array of deleted section ids
 */
export const filterDeletedOrder: TFilterDeletedOrderFn = (
  order,
  deletedSectionIds
) => {
  // TODO: check?
  if (!order) return [];
  return [...order].filter(
    ({ sectionId }) => !deletedSectionIds.includes(sectionId)
  );
};

/**
 * @order - array of orders
 */
export const getHiddenKeys: TGetHiddenKeysFn = (order) => {
  const hiddenKeys: string[] = [];

  order.forEach(({ sectionId, isHidden }) => {
    if (isHidden) {
      hiddenKeys.push(sectionId);
    }
  });

  return hiddenKeys;
};

export const DEFAULT_PRIMARY_COLOR = '#009448';
export const DEFAULT_BG_OPACITY = '10';

function getRandomElement<T>(arr: T[]): T | null {
  if (arr.length === 0) return null; // Return null for empty array
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

/**
 * @section - section object with default values
 * @pageConfig - page config object from context
 * @customConfig - override section config
 * @jobsAndSubjobTypes - from jobTypesContext and is needed for vacancies section
 */
export const getDefaultSectionValues: TGetDefaultSectionValuesFn = (
  section,
  pageConfig,
  customConfig,
  jobsAndSubjobTypes
) => {
  const { type } = section;

  const VACANCY_PAGINATE_BY = 6;
  const VACANCY_WORD_LIMIT = 30;
  const primaryColor = pageConfig.branding.colors.primary_color;

  // for v2
  // v2 will be using branding
  // const primaryColor =
  //   pageConfig.branding?.colors?.find((c) => c.id === 'primary_color')?.value ||
  //   '#000000';

  // const secondaryColor =
  //   pageConfig.branding?.colors?.find((c) => c.id === 'secondary_color')
  //     ?.value || '#000000';

  // TODO:
  // Right now the customer colors are not being used due to the reason that
  // it is an array of strings without a preferred color
  // TEMP SOLUTION:
  // I will use the temporary fixed character and change it after

  const defaultTextColors = {
    textColors: {
      subtitle_color: primaryColor,
      title_color: '#000000',
      link_color: primaryColor,
      link_hover_color: primaryColor,
    },
  };

  const sectionKeysWithImages: {
    [skey: string]: {
      key: string;
      isMultiple?: boolean;
    };
  } = {
    [SectionType.Hero]: {
      key: HeroEditableFields.Images,
      isMultiple: true,
    },
    [SectionType.Feature]: {
      key: FeatureEditableFields.Image,
    },
    [SectionType.Vacancies]: {
      key: VacanciesEditableFields.Image,
    },
    [SectionType.Carousel]: {
      key: CarouselEditableFields.Slides,
      isMultiple: true,
    },
  };

  const keyWithImage = sectionKeysWithImages[section.type as string]?.key;

  // this is needed so that the footer is not included in the list
  // of alternating BG
  const filteredFooterOrder = pageConfig.order.filter(
    (o) => !o.sectionId.includes('footer-')
  );

  const lastSection =
    pageConfig.sections[
      pageConfig.order[filteredFooterOrder.length - 1]?.sectionId
    ];

  const hasBrandingImages =
    keyWithImage &&
    pageConfig?.branding?.images &&
    pageConfig?.branding?.images.length > 0;

  const isMultipleImages =
    sectionKeysWithImages[section.type as string]?.isMultiple;

  // this is used when the section is added one by one ( not initially generated )
  const isAlternateBgFromSingleClick =
    lastSection?.bgColor ||
    [SectionType.Hero, SectionType.Footer].includes(type as SectionType);

  const base = {
    ...section,
    mainColor: primaryColor,

    // colors from branding are needed
    colors: { ...pageConfig.branding.colors },

    // generate a random feature image from branding
    ...(hasBrandingImages && {
      [keyWithImage]: getRandomElement(
        pageConfig.branding.images as ImageModel[]
      ),
    }),

    // generate a random images from branding ( for multiple.. )
    ...(hasBrandingImages &&
      isMultipleImages && {
        [keyWithImage]:
          section.type === SectionType.Hero
            ? (pageConfig.branding.images as ImageModel[]).length < 5
              ? pageConfig.branding.images
              : pageConfig.branding.images?.slice(0, 5)
            : pageConfig.branding.images?.map((i) => ({
                image: (i as any).path,
              })),
      }),

    // Alternate BG allocation
    bgColor: isAlternateBgFromSingleClick
      ? undefined
      : `${pageConfig.branding.colors.primary_color}${DEFAULT_BG_OPACITY}`,
  };

  switch (type) {
    case SectionType.Hero:
      return {
        ...defaultHeroSettings,
        ...base,
        textColors: {
          link_color: primaryColor,
          link_hover_color: primaryColor,
        },
        ...customConfig,
      } as HeroSectionResponse;
    case SectionType.Carousel:
      return {
        ...defaultCarouselSettings,
        ...defaultTextColors,
        ...base,
        ...customConfig,
      } as CarouselSectionResponse;
    case SectionType.Team:
      return {
        ...defaultTeamSettings,
        ...defaultTextColors,
        $textAlign: 'left',
        ...base,
        ...customConfig,
      } as TeamSectionResponse;
    case SectionType.Cards:
      return {
        ...defaultCardSettings,
        ...defaultTextColors,
        $layout: 'vertical',
        $horizontalAlign: 'left',
        $textAlign: 'left',
        ...base,
        ...customConfig,
      } as unknown as CardsSectionResponse;
    case SectionType.Feature:
      return {
        ...defaultFeatureSettings,
        imagePlacement: getRandomElement(['left', 'right']),
        ...defaultTextColors,
        ...base,
        ...customConfig,
      } as FeatureSectionResponse;
    case SectionType.Video:
      return {
        ...defaultVideoSettings,
        ...defaultTextColors,
        ...base,
        ...customConfig,
      } as VideoSectionResponse;
    case SectionType.Vacancies:
      return {
        ...defaultVacanciesSettings,
        vacancyDescLimit: VACANCY_WORD_LIMIT,
        textColors: {
          ...defaultTextColors.textColors,
          link_hover_color: primaryColor,
          link_bg_color: primaryColor,
          link_text_color: '#FFFFFF',
        },
        ...base,
        ...customConfig,
        ...(jobsAndSubjobTypes && {
          paginateBy: VACANCY_PAGINATE_BY,
          vacancyFilterJobTypes: jobsAndSubjobTypes.jobTypes.map((j) => j.name),
          showFilters: true,
        }),
      } as unknown as VacanciesProps;
    case SectionType.Testimonials:
      return {
        ...defaultTestimonialsSettings,
        textColors: {
          ...defaultTextColors.textColors,
          card_accent_color: primaryColor,
        },
        ...base,
        ...customConfig,
      } as TestimonialSectionResponse;
    case SectionType.Contact:
      return {
        ...defaultContactSettings,
        ...defaultTextColors,
        customInfoClass: 'sm:w-1/3',
        customCardsClass: 'sm:w-2/3',
        $layout: 'horizontal',
        $verticalAlign: 'top',
        $textAlign: 'left',
        ...base,
        ...customConfig,
      } as unknown as ContactSectionResponse;
    case SectionType.Footer:
      return {
        sectionId: 'footer-section',
        ...defaultFooterSettings,
        companyName: pageConfig.branding.brand_name,
        ...defaultTextColors,
        ...base,
        ...customConfig,
      } as FooterSectionResponse;
    default:
      return section;
  }
};

export const setColorsBySectionPrimaryColor = (
  primaryColor: string,
  withBg?: boolean
) => {
  const DEFAULT_BG_OPACITY = '10';

  const textColors = {
    subtitle_color: primaryColor,
    link_color: primaryColor,
  };
  return withBg
    ? {
        mainColor: primaryColor,
        bgColor: `${primaryColor}${DEFAULT_BG_OPACITY}`,
        textColors,
      }
    : {
        mainColor: primaryColor,
        textColors,
      };
};

type TGetContactCards = (
  locationObj?: LocationModel,
  recruiterObj?: RecruiterModel
) => Partial<ContactSectionResponse>;

export const getContactCards: TGetContactCards = (
  locationObj,
  recruiterObj
) => {
  const cards: ContactKeyFields['cards'] = [];

  if (recruiterObj) {
    const {
      email = 'example@email.com',
      first_name = 'John',
      last_name = 'Doe',
    } = recruiterObj;

    const recruiterCard = {
      type: 'contact-card',
      label: `${first_name} ${last_name}`,
      email,
    };
    cards.push(recruiterCard as any);
  }

  if (locationObj) {
    const road = locationObj.road ?? '';
    const city = locationObj.city ?? '';
    const state = locationObj.state ?? '';
    const zip_code = locationObj.zip_code ?? '';
    const country_code = locationObj.country_code?.toUpperCase() ?? '';
    const box_number = locationObj.box_number ?? '';
    const house_number = locationObj.house_number ?? '';

    const address_line_1 = `${road} ${house_number} ${box_number}`.trim();
    const address_line_2 = `
      ${zip_code} ${city}
      ${state !== '' ? `, ${state}` : state}
      ${country_code !== '' ? `, ${country_code}` : country_code}
    `.trim();
    const hasAddress = address_line_1.length > 0 || address_line_2.length > 0;

    const addressCard = {
      type: 'contact-card',
      label: locationObj?.type === 'OFFICE' ? 'Office' : '',
      ...(hasAddress && {
        address: {
          address_line_1:
            address_line_1.length > 0 ? address_line_1 : undefined,
          address_line_2:
            address_line_2.length > 0 ? address_line_2 : undefined,
        },
      }),
    };
    cards.push(addressCard as any);
  }

  return {
    cards,
  };
};
