import 'twin.macro';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ConfigFormQuestionType } from 'views/targeting/ConfigForm';
import Input from 'components/form/input/Input';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import { Error } from 'components/typography/Typography';
import { TargetingLocation } from 'types/geolocationTypes';
import type { DropdownItem } from 'components/dropdown/Dropdown';
import MultiLocation from 'components/multi-location/MultiLocation';
import NestedDropdown from 'components/dropdown/nested-dropdown/NestedDropdown';
import { useLazyQuery } from 'hooks/sympl-query';
import useNavigationContext from 'hooks/context/nav-context';
import { GET_PREVIOUS_BRAND_LOCATIONS } from 'graphql/location/queries';
import { JobType } from 'types/targetingTypes';
import { TargetingFormData } from 'views/targeting/TargetingConfig';
import { getLanguageCode } from 'utils/i18nHelper';
import PillGroup from 'components/form/pill-group/PillGroup';
import useApiList from 'hooks/useApiList';
import useJobTypesContext from 'hooks/context/job-types-context';
import CreateBrandDropdown from 'components/dropdown/create-brand-dropdown/CreateBrandDropdown';

export interface ConfigInputProps {
  id: string;
  isDisabled?: boolean;
  value?: string | number | TargetingLocation[] | string[] | null;
  formData: TargetingFormData[];
  secondaryValue?: string | number | null;
  type?: ConfigFormQuestionType;
  onChange?: (
    value: string | number | TargetingLocation[] | string[] | null
  ) => void;
  onChangeSecondary?: (value: string | number | null) => void;
}

interface LocationData {
  locationData: {
    id: number;
    locations: TargetingLocation[];
    name: string;
  };
}
const ConfigInput: React.FC<ConfigInputProps> = ({
  id,
  isDisabled = false,
  value,
  formData,
  secondaryValue,
  type = 'text',
  onChange,
  onChangeSecondary,
}) => {
  const { brands } = useNavigationContext();
  const { jobsAndSubjobTypes } = useJobTypesContext();

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchBrand = Number(watch('brand'));

  const { data: experiences } = useApiList('years_of_experience');

  const [suggestLocations, setSuggestLocations] = useState<TargetingLocation[]>(
    []
  );

  const [fetchLocationData] = useLazyQuery<
    LocationData,
    {
      id: number;
    }
  >(GET_PREVIOUS_BRAND_LOCATIONS, {
    skip: type !== 'geolocation',
    fetchPolicy: 'network-only',
  });

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const changeHandler = (
    onInputChange: (value: string) => void,
    e?: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e) {
      e.preventDefault();
      const val = e.target.value;
      onInputChange(val);
      onChange?.(val);
    }
  };

  const dropdownChangeHandler = (
    onInputChange: (value: string) => void,
    val?: {
      key: string | number;
      label: string;
    }
  ) => {
    if (!val?.key) return;
    onInputChange(val.key.toString());
    onChange?.(val.key);
  };

  const geoSuggestChangeHandler = (
    onInputChange: (value: TargetingLocation[]) => void,
    result: TargetingLocation[]
  ) => {
    onInputChange(result);
    onChange?.(result);
  };

  const onBrandChange = useCallback(
    (
      value: string | number,
      onInputChange?: (value: string | number) => void
    ) => {
      onInputChange?.(value);
      onChange?.(value);
    },
    [onChange]
  );

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (isNaN(watchBrand) || !watchBrand) {
      setSuggestLocations([]);
      return;
    }
    fetchLocationData({ variables: { id: watchBrand } })
      .then((data) => {
        if (data.error) setSuggestLocations([]);
        else
          setSuggestLocations(
            data.data?.locationData.locations.filter((v, i) => {
              return (
                i ===
                data.data?.locationData.locations.findIndex(
                  (o) => o.lat === v.lat && o.lng === v.lng
                )
              );
            }) ?? []
          );
      })
      .catch(() => {
        setSuggestLocations([]);
      });
  }, [isMounted, watchBrand, fetchLocationData]);

  useEffect(() => {
    if (type !== 'brand' || !brands.length) return;

    const brand = brands.find(({ name }) => name === value);

    if (brand?.id) onBrandChange(brand.id);
  }, [brands, onBrandChange, type, value]);

  const language = useMemo(() => {
    const formLanguage =
      (formData
        .find(({ section }) => section === 'Who')
        ?.questions.find(({ key }) => key === 'language')?.value as number) ??
      141; // Defaults to English
    return getLanguageCode(formLanguage);
  }, [formData]);

  const selectedExperienceLabel = useMemo(() => {
    if (type !== 'experience') return undefined;
    if (!experiences || !value) return undefined;

    const selectedLabels = experiences
      ?.filter(({ key }) => (value as string[])?.includes(key.toString()))
      .map(({ label }) => label);

    return selectedLabels && selectedLabels.length > 0
      ? selectedLabels
      : undefined;
  }, [experiences, type, value]);

  return (
    <div tw="flex flex-col">
      <Controller
        id={id}
        name={id}
        control={control}
        defaultValue={value ?? ''}
        rules={{ required: type !== 'sub-job-type' }}
        render={({ onChange: onInputChange }: { onChange: any }) => (
          <>
            {(type === 'text' || type === 'vac-name') && (
              <Input
                id={id}
                name={id}
                type="text"
                value={value?.toString() ?? ''}
                ariaInvalid={errors[id] !== undefined}
                onChange={(e) => changeHandler?.(onInputChange, e)}
                onBlur={() => changeHandler?.(onInputChange)}
                disabled={isDisabled}
              />
            )}
            {type === 'geolocation' && (
              <MultiLocation
                defaultValue={value as TargetingLocation[]}
                language={language}
                suggestions={suggestLocations}
                onChange={(value) =>
                  geoSuggestChangeHandler(onInputChange, value)
                }
              />
            )}
            {type === 'job-type' && (
              <NestedDropdown
                items={
                  jobsAndSubjobTypes?.jobTypes?.map((job: JobType) => {
                    return {
                      key: job.id,
                      label: job.name,
                      items: job.sub_job_types.map((subjob) => {
                        return { key: subjob.id, label: subjob.name };
                      }),
                    };
                  }) ?? []
                }
                onChangeCategory={(val) => {
                  onChange?.(val);
                  onInputChange?.(val);
                }}
                categoryValue={value as number | string | undefined}
                onChangeItem={(val) => {
                  onChangeSecondary?.(val);
                }}
                itemValue={secondaryValue}
                disabled={isDisabled}
              />
            )}
            {type === 'brand' && (
              <CreateBrandDropdown
                label={
                  typeof value === 'string'
                    ? value
                    : brands.find((brand) => brand.id === value)?.name
                }
                value={value as number}
                defaultValue={value as number}
                onChange={(key) => onBrandChange(key, onInputChange)}
                disabled={isDisabled}
                noResultsLabel={'No results found'}
                enableCustom
              />
            )}
            {type === 'language' && (
              <ApiDropdown
                mode={'key'}
                disabled={isDisabled}
                value={value as number | string | undefined}
                type={'vacancy_languages'}
                onChange={(val) =>
                  dropdownChangeHandler?.(
                    onInputChange,
                    val as DropdownItem | undefined
                  )
                }
              />
            )}
            {type === 'experience' && (
              <PillGroup
                selected={selectedExperienceLabel ?? ['None']}
                options={experiences.map((experience) => experience.label)}
                onChange={(options) => {
                  const selectedOptions = options
                    .filter((option) => option.selected)
                    .map((option) => option.key);

                  const selections = experiences
                    .filter((experience) =>
                      selectedOptions.includes(experience.label)
                    )
                    .map((experience) => experience.key);

                  if (selections.length > 0) {
                    onInputChange(selections);
                    onChange?.(selections.map(String));
                  }
                }}
              />
            )}
          </>
        )}
      />
      {errors[id] !== undefined &&
        (id === 'sub_job_type' ? (
          !errors['job_type'] && <Error>{'Please provide a sub type'}</Error>
        ) : (
          <Error>{'This field is required'}</Error>
        ))}
    </div>
  );
};

export default ConfigInput;
