import React from 'react';
import Dropdown from 'components/dropdown/Dropdown';
import useCareerPageContext from 'hooks/context/career-page-context';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CareerPageStatusType,
  CareerPageStatusTypes,
} from 'types/careers-page/types';
import * as Styled from './styles';
import 'twin.macro';

const PageStatusDropDown = () => {
  const { control } = useFormContext();
  const { careerPageConfig, isCareerDataLoading, handleUpdatePageStatus } =
    useCareerPageContext();

  //only load when careerPageConfig is not null
  if (!careerPageConfig?.status || careerPageConfig?.status === undefined) {
    return null;
  }

  return (
    <div tw="w-auto mx-4">
      <Controller
        id="status"
        name="status"
        rules={{ required: true }}
        control={control}
        defaultValue={careerPageConfig.status}
        render={({ value, onChange }) => (
          <Dropdown
            loading={isCareerDataLoading && !careerPageConfig}
            icon={null}
            label={value}
            value={value}
            customLabelRenderer={(label) => {
              return (
                <>
                  {label && (
                    <Styled.StatusIndicator
                      status={label as CareerPageStatusTypes}
                    >
                      {label}
                    </Styled.StatusIndicator>
                  )}
                </>
              );
            }}
            searchable={false}
            onChange={(key) => {
              onChange(key);
              handleUpdatePageStatus(key as CareerPageStatusType);
            }}
            items={Object.values(CareerPageStatusType).map((status) => ({
              key: status,
              label: status,
            }))}
          />
        )}
      />
    </div>
  );
};

export default PageStatusDropDown;
