import { AcceptedSections, SectionTypeLabels } from '@symplbe/sympl-components';
import ToolTip from 'components/tooltip/ToolTip';
import React, { FC } from 'react';
import { DotsSixVertical, EyeSlash, Eye } from '@phosphor-icons/react';
import tw, { css, styled } from 'twin.macro';
import SectionIcons from './SectionIcons';
import { DraggableProvided } from 'react-beautiful-dnd';
// import { blockListConfig } from '../block-list-settings/blockListConfigs';
// import BlockListSettings from '../block-list-settings/BlockListSettings';
import { CareerPageType } from 'types/careers-page/context';
import { ITEM_HEIGHT } from './styles';

interface SectionItemProps {
  index: number;
  isHidden: boolean;
  activeSection: string | null;
  sectionId: string;
  provided?: DraggableProvided;
  sectionObj: AcceptedSections;
  handleOnHide?: (sectionId: string, isHidden: boolean) => void;
  handleSectionClick: (
    e: React.MouseEvent,
    sectionId: string,
    isHidden: boolean
  ) => void;
  handleUpdatePageSections: CareerPageType['handleUpdatePageSections'];
}

const SectionItem: FC<SectionItemProps> = ({
  index,
  isHidden,
  activeSection,
  sectionId,
  provided,
  sectionObj,
  handleOnHide,
  handleSectionClick,
  // handleUpdatePageSections,
}) => {
  const toolTipText = ` ${isHidden ? 'Show' : 'Hide'} ${
    SectionTypeLabels[sectionObj.type]
  } Section `;

  // const keyWithBlocks = blockListConfig[sectionObj.type];

  // const renderBlocks = () => {
  //   const blocksToRender = (sectionObj as any)[keyWithBlocks as string] ?? []; // TODO: is this the right fallback approach?
  //   const blocks = [...blocksToRender];

  //   if (!keyWithBlocks && blocks.length === 0) {
  //     return null;
  //   }

  //   return (
  //     <BlockListSettings
  //       blocks={blocks}
  //       sectionObj={sectionObj}
  //       keyWithBlocks={keyWithBlocks as string}
  //       handleBlocksUpdate={(blocks: any) => {
  //         handleUpdatePageSections(sectionId, {
  //           ...sectionObj,
  //           [keyWithBlocks as string]: blocks,
  //         });
  //       }}
  //     />
  //   );
  // };

  return (
    <Item
      isHidden={isHidden}
      isActive={activeSection === sectionId}
      onClick={(e) => handleSectionClick(e, sectionId, isHidden)}
      {...(provided && {
        ...provided.draggableProps,
        ref: provided.innerRef,
      })}
    >
      <ItemRow>
        <SectionIcons type={sectionObj.type} />
        <span tw="px-2 text-sm">
          {SectionTypeLabels[sectionObj.type]} Section
        </span>

        {handleOnHide && (
          <ToolTip
            key={`${sectionId} ${index}`}
            text={toolTipText}
            placement="top"
            arrow
          >
            <div
              tw="p-2 bg-gray-100 w-auto ml-auto rounded-md"
              className="fake-btn-hide"
              onClick={() => {
                handleOnHide(sectionId, !isHidden);
              }}
            >
              {isHidden ? <EyeSlash weight="bold" /> : <Eye weight="bold" />}
            </div>
          </ToolTip>
        )}
        {provided && (
          <div
            tw="p-2 bg-gray-100 w-auto ml-2"
            className="fake-btn-grabber"
            {...provided.dragHandleProps}
          >
            <DotsSixVertical weight="bold" />
          </div>
        )}
      </ItemRow>
      {/* 
        TODO:
        right now these blocks are not doing so well
        for this current version
        {keyWithBlocks && renderBlocks()} 
      */}
    </Item>
  );
};

const ItemRow = styled.div`
  ${tw`
    w-full 
    flex 
    items-center 
    justify-start
    flex-nowrap
  `}
`;

const Item = styled.div<{ isHidden?: boolean; isActive?: boolean }>`
  ${css`
    --min-item-height: ${ITEM_HEIGHT}px;
  `}
  ${tw`
    w-full
    flex
    flex-col
    p-4 
    border-2 
    border-gray-100 
    rounded 
    bg-white
    h-auto
    min-h-[var(min-item-height)]
  `}
  ${({ isHidden }) =>
    isHidden &&
    tw`
    bg-gray-100
    opacity-25
  `}
  ${({ isActive }) =>
    isActive &&
    tw`
    bg-gray-100
    bg-opacity-25
    border-l-4 
    border-r-2
    border-t-2
    border-b-2
    border-l-indigo-500 
  `}
`;

export default SectionItem;
