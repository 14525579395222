import 'twin.macro';
import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from 'types/routeTypes';
import SvgError from 'components/svg/SvgError';
import Centered from 'components/centered/Centered';

export const IntakeErrorPage: React.FC<{
  isBoundaryError?: boolean;
}> = ({ isBoundaryError = false }) => {
  const backHandler = () => {
    window.location.replace(Routes.INTAKE);
  };

  return (
    <Centered>
      <SvgError />
      {!isBoundaryError ? (
        <Link to={Routes.INTAKE} tw="mt-6 text-indigo-600">
          ← Back to intake
        </Link>
      ) : (
        <button tw="mt-6 text-indigo-600" onClick={() => backHandler()}>
          ← Back to intake
        </button>
      )}
    </Centered>
  );
};
