import { gql } from '@apollo/client';

export const UPDATE_PLAN = gql`
  mutation updatePlan($customerId: ID!) {
    data(input: $input, customerId: $customerId)
      @rest(type: "Plan", path: "/v4/subscription/plan", method: "POST") {
      session_id
    }
  }
`;

export const UPDATE_AUTO_RENEW = gql`
  mutation updateAutoRenew($customerId: ID!) {
    updateAutoRenew(input: $input)
      @rest(
        type: "Subscription"
        path: "/v4/subscription/auto-renew"
        method: "POST"
      )
  }
`;

export const UPDATE_BUDGET_SUBSCRIPTION = gql`
  mutation updateBudgetSubscription($vacancyId: ID!) {
    updateBudgetSubscription(input: $input, id: $vacancyId)
      @rest(
        type: "UpdatedBudgetSubscription"
        path: "/v4/vacancies/{args.id}/get-started"
        method: "POST"
      ) {
      session_id
    }
  }
`;

export const EXTEND_OR_START_TRIAL = gql`
  mutation extendTrial($customerId: ID!) {
    extendTrial(input: $input)
      @rest(
        type: "TrialExtension"
        path: "/v4/subscription/extend-trial"
        method: "POST"
      )
  }
`;
