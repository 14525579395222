import tw, { styled } from 'twin.macro';
import React from 'react';
import { Candidate, ProcedureActivity } from 'types/candidates/types';
import ContactList from 'components/candidates/contact-list/ContactList';
import CandidateFileList from 'components/candidates/file-list/CandidateFileList';
import CandidateActivityList from 'components/candidates/activity-list/CandidateActivityList';
import { addZeroPrefixToNumber } from 'utils/baseHelpers';

const CandidateGeneralInfo: React.FC<{
  candidate: Candidate;
}> = ({ candidate }) => {
  const formatActivityCreationDate = (activity: ProcedureActivity) => {
    const date = new Date(activity.created_at);
    const year = date.getUTCFullYear().toString();
    return {
      ...activity,
      created_at: `${addZeroPrefixToNumber(
        date.getDate()
      )}/${addZeroPrefixToNumber(date.getMonth() + 1)}/${year.substr(
        year.length - 2
      )}`,
    };
  };

  const activities =
    candidate && candidate.procedureActivities
      ? candidate.procedureActivities
          .sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
          )
          .map((a) => formatActivityCreationDate(a))
          .reverse()
      : [];

  return (
    <ul tw="h-full flex flex-col overflow-y-auto no-scrollbar">
      <InfoSection>
        <ContactList
          candidate={candidate}
          email={candidate.email as string}
          phone={candidate.phone as string}
          source={candidate.source as string}
        />
      </InfoSection>
      <InfoSection>
        <CandidateFileList
          files={candidate?.files ?? []}
          procedureId={candidate.procedureId as number}
        />
      </InfoSection>
      <InfoSection>
        <CandidateActivityList activities={activities} />
      </InfoSection>
    </ul>
  );
};

export const InfoSection = styled.li(
  tw`mb-8 bg-gray-50 rounded-lg p-8 shadow-sm list-none last-of-type:(m-0 pb-8)`
);

export default CandidateGeneralInfo;
