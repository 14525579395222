import React, { useEffect } from 'react';
import { XCircle } from '@phosphor-icons/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tw, { css, styled } from 'twin.macro';
import { IntakeNotification } from 'types/baseTypes';

import 'twin.macro';
import useIntakeCoreContext from 'hooks/context/intakes-context';

const Notification = () => {
  const { notifications, handleRemoveNotification } = useIntakeCoreContext();

  const notify = (notification: IntakeNotification) => {
    toast(getIntakeNotification(notification.message), {
      hideProgressBar: true,
      position: 'top-right',
      onClose: () => handleRemoveNotification(notification),
    });
  };

  const getIntakeNotification = (message: string) => {
    return (
      <div tw="p-4">
        <div tw="flex items-start">
          <div tw="shrink-0">
            <ErrorIcon />
          </div>
          <div tw="ml-3 w-0 flex-1 pt-0.5">
            <p tw="text-sm font-medium text-gray-900">
              Oops, something went wrong.
            </p>
            <p tw="mt-1 text-sm text-gray-500">{message}</p>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!notifications) return;
    notifications.forEach((notification) => notify(notification));
  }, [notifications, notify]);

  return <NotificationContainer />;
};

const NotificationContainer = styled(ToastContainer)(() => [
  css`
    div.Toastify__toast {
      background-color: white;
      border-radius: 0.375rem;
    }
  `,
]);

const ErrorIcon = styled(XCircle)(() => [
  tw`
    text-2xl text-red-400 font-bold
  `,
]);

export default Notification;
