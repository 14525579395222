import { useContext } from 'react';
import { NotificationContext } from 'context/NotificationContext';

export function useToastNotifications() {
  const { addToast } = useContext(NotificationContext);

  return {
    addToast,
  };
}

export function useNotifications() {
  const {
    notifications,
    removeNotification,
    showNotificationTab,
    toggleNotificationTab,
    markAsRead,
    updateNotifications,
  } = useContext(NotificationContext);

  return {
    notifications,
    removeNotification,
    showNotificationTab,
    toggleNotificationTab,
    markAsRead,
    updateNotifications,
  };
}
