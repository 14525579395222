import tw, { css, styled } from 'twin.macro';
import { TypedCustomerResource } from './ResourceTypes';

export const Wrapper = styled.div`
  ${tw`grid w-full py-1`}
  ${css`
    grid-template-rows: 1fr auto;
  `}
`;

export const GridWrapper = styled.div(tw`max-h-[53vh] overflow-y-auto`);

export const Grid = styled.ul(
  tw`grid gap-2 grid-cols-[repeat(auto-fill, minmax(101px, 1fr))] sm:grid-cols-[repeat(auto-fill, minmax(121px, 1fr))]`
);

export const ResourceListItem = styled.li(
  tw`relative aspect-square cursor-pointer transition-all`
);

export const SelectableResource = styled.button<{
  resourceType: TypedCustomerResource['type'];
}>`
  ${tw`h-full w-full rounded-md cursor-pointer disabled:cursor-not-allowed`}

  ${({ resourceType }) =>
    resourceType === 'video' ? tw`bg-gray-50` : tw`disabled:blur-sm`}
`;

export const Img = styled.img<{ selected?: boolean; isDisabled?: boolean }>`
  ${tw`
    h-full w-full
    object-contain rounded-md transition-all shadow-sm overflow-hidden
    hover:(shadow-lg z-10) focus:(shadow-lg z-10)
  `}
  ${css`
    &.resource-image {
      margin: 0;
    }
  `}

  ${({ selected }) => selected && tw`border-4 border-indigo-500`}
  ${({ isDisabled }) => isDisabled && tw`hover:shadow-none focus:shadow-none`}
`;

export const MediaResource = styled.div<{
  selected?: boolean;
  isDisabled?: boolean;
}>`
  ${tw`
    flex flex-col justify-center items-center
    h-full w-full transition-all rounded-md
    hover:(shadow-lg z-10) focus:(shadow-lg z-10)
  `}

  ${({ selected }) => selected && tw`border-4 border-indigo-500`}
  ${({ isDisabled }) => isDisabled && tw`hover:shadow-none focus:shadow-none`}
`;
