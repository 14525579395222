import { gql } from '@apollo/client';

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($customerId: ID!) {
    updateCustomer(input: $input)
      @rest(type: "Customer", path: "/v4/profile", method: "POST")
  }
`;

export const INVITE_COLLEAGUE = gql`
  mutation inviteColleague($customerId: ID!) {
    inviteColleague(input: $input)
      @rest(type: "Customer", path: "/v4/users/invite", method: "POST")
  }
`;
