import React, { useState } from 'react';
import { CheckCircle } from '@phosphor-icons/react';
import 'twin.macro';

import { Modal } from '../page/app-page/Modal';
import { IntakeFormResults } from 'types/formTypes';
import { Routes } from 'types/routeTypes';
import Button from 'components/button/Button';
import useIntakeCoreContext from 'hooks/context/intakes-context';
import { useNavigate } from 'react-router-dom';

const FinishButton: React.FC<{
  intakeFormResults?: IntakeFormResults;
}> = ({ intakeFormResults }) => {
  const navigate = useNavigate();

  const { currentIntakeId, submitIntakeStep, lockIntake } =
    useIntakeCoreContext();
  const [isLocking, setIsLocking] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const saveIntake = async () => {
    if (intakeFormResults) {
      // Submit the intake form with the current form state
      await submitIntakeStep(intakeFormResults);
    } else return Promise.resolve();
  };

  const modalContinueHandler = () => {
    setIsLocking(true);
    saveIntake().then(() => {
      lockIntake(currentIntakeId)
        .then(() => {
          navigate(Routes.INTAKE_DONE, { replace: true });
        })
        .catch(() => {
          setIsLocking(false);
        });
    });
  };

  return (
    <>
      <Modal show={isModalShown} onClose={() => setIsModalShown(false)}>
        <div tw="max-w-sm my-4">
          <span tw="text-xl font-medium mb-2">Are you sure?</span>
          <p tw="my-4">
            By clicking submit, you <b>confirm</b> that all information is{' '}
            <b>present</b> and <b>correct</b>.
          </p>

          <div tw="flex float-right space-x-1">
            <Button variant="outline" onClick={() => setIsModalShown(false)}>
              Cancel
            </Button>
            <Button
              variant="indigo"
              loading={isLocking}
              onClick={modalContinueHandler}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>

      <div tw="mr-auto cursor-pointer">
        <Button
          variant="indigo"
          onClick={() => setIsModalShown(true)}
          icon={<CheckCircle weight="bold" />}
        >
          Finish intake
        </Button>
      </div>
    </>
  );
};

export default FinishButton;
