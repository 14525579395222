import React, { useMemo } from 'react';
import tw, { css, styled, theme } from 'twin.macro';

import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from 'dayjs';

import WrapperEmpty from 'components/wrapper-empty/WrapperEmpty';
import { Metrics } from 'types/adTypes';
import { capitalize, numberToDecimalNotation } from 'utils/baseHelpers';
import { Body } from 'components/typography/Typography';

const MetricGroup: React.FC<{
  metrics?: Metrics[];
  showVacancyPageMetrics?: boolean;
}> = ({ metrics: _metrics = [], showVacancyPageMetrics = true }) => {
  const metrics = useMemo(() => {
    if (!_metrics.length) return [];

    const sortedMetrics = [..._metrics].sort(
      (a, b) => Date.parse(a.date) - Date.parse(b.date)
    );

    const firstDate = new Date(sortedMetrics[0].date);
    const lastDate = new Date(sortedMetrics[sortedMetrics.length - 1].date);

    const daysBetween = Math.ceil(dayjs(lastDate).diff(firstDate, 'day', true));

    const result = [sortedMetrics[0]];

    for (let i = 1; i < daysBetween; i++) {
      const nextDate = dayjs(firstDate).add(i, 'day');
      const metric = sortedMetrics.find((m) =>
        dayjs(m.date).isSame(nextDate, 'day')
      );

      if (metric) {
        result.push(metric);
      } else {
        result.push({
          applications: 0,
          date: nextDate.toISOString().split('T')[0],
          impressions: 0,
          leads: 0,
          spend: 0,
          visits: 0,
          clicks: 0,
        });
      }
    }

    // Ensure the last date is only added if it hasn't already been included
    const lastDateStr = lastDate.toISOString().split('T')[0];
    if (!result.find((m) => m.date === lastDateStr)) {
      result.push(sortedMetrics[sortedMetrics.length - 1]);
    }

    return result;
  }, [_metrics]);

  const getReadingsForMetric = (metricKey: string) => {
    return metrics.map((metric) => ({
      date: metric.date,
      // @ts-ignore
      value: metric[metricKey] as number,
    }));
  };

  const getSumForMetric = (metricKey: string) => {
    return metrics.reduce((acc: number, metric: Metrics) => {
      // @ts-ignore
      return acc + (metric[metricKey] as number);
    }, 0);
  };

  const moneyFormatter = (spent: number) => {
    return '€ ' + numberToDecimalNotation(spent);
  };

  const metricKeys = showVacancyPageMetrics
    ? ['impressions', 'visits', 'applications', 'spend']
    : ['impressions', 'clicks', 'spend'];

  return (
    <>
      {metrics && metrics.length > 0 ? (
        <Wrapper>
          {metricKeys.map((metricKey, index) => (
            <MetricWrapper tw="h-full w-full" key={metricKey}>
              <div tw="flex flex-row items-center justify-between pb-4 text-gray-700 font-medium">
                <div tw="capitalize">{metricKey}</div>
                <div tw="text-sm">
                  {metricKey === 'spend'
                    ? moneyFormatter(getSumForMetric(metricKey))
                    : numberToDecimalNotation(getSumForMetric(metricKey))}
                </div>
                {/* Only for the first 2 metrics */}
                {showVacancyPageMetrics &&
                  index < 2 &&
                  getSumForMetric(metricKeys[index]) && (
                    <div tw="text-sm">
                      {(
                        (getSumForMetric(metricKeys[index + 1]) /
                          getSumForMetric(metricKeys[index])) *
                        100
                      ).toFixed(2)}{' '}
                      % &rarr;
                    </div>
                  )}
              </div>
              <ResponsiveContainer height="80%" debounce={300}>
                <BarChart
                  data={getReadingsForMetric(metricKey)}
                  margin={{ top: 0, right: 0, left: -8, bottom: 0 }}
                >
                  <Tooltip
                    formatter={(value: any) => [
                      (metricKey === 'spend' ? '€ ' : '') +
                        numberToDecimalNotation(value),
                      capitalize(metricKey),
                    ]}
                  />
                  <Bar
                    fillOpacity={1}
                    fill={theme`colors.gray`}
                    opacity={0.5}
                    dataKey="value"
                    barSize={500}
                  />
                  <YAxis
                    axisLine={true}
                    tickLine={false}
                    allowDecimals={false}
                    interval="preserveStartEnd"
                    tickFormatter={(value: number) =>
                      numberToDecimalNotation(value)
                    }
                  />
                  <XAxis
                    axisLine={true}
                    tickLine={true}
                    allowDecimals={false}
                    interval="preserveStartEnd"
                    dataKey="date"
                    domain={['auto', 'auto']}
                    name="Time"
                    tickFormatter={(time: Date) => dayjs(time).format('MMM D')}
                    type="category"
                  />
                </BarChart>
              </ResponsiveContainer>
            </MetricWrapper>
          ))}
        </Wrapper>
      ) : (
        <WrapperEmpty>
          <Body>
            This vacancy doesn't seem to have any metrics for the current
            filters ...
          </Body>
        </WrapperEmpty>
      )}
    </>
  );
};

const Wrapper = styled.ul`
  ${tw`grid grid-cols-4 gap-8`}
`;

const MetricWrapper = styled.li(() => [
  tw`mr-4 bg-white p-4 min-w-[200px] rounded-lg last-of-type:m-0`,
  css`
    tspan {
      fill: ${theme`colors.gray.400`};
      font-size: ${theme`fontSize.xs`};
    }
  `,
]);

export default MetricGroup;
