import ChannelButton from 'components/channel-button/ChannelButton';
import { ToggleGroup, ToggleGroupItem } from 'components/ui/toggle-group';
import React, { useState } from 'react';
import { AD_CHANNELS, AdChannel } from 'types/adTypes';
import { TOGGLE_CHANNEL } from 'graphql/vacancies/mutations';
import 'twin.macro';
import ToolTip from 'components/tooltip/ToolTip';
import { useMutation } from 'hooks/sympl-mutation';
import useNavigationContext from 'hooks/context/nav-context';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import { VacancySpendStatus } from 'types/vacancyTypes';

const ChannelToggleGroup: React.FC = () => {
  const { activeVacancy, currentVacancy } = useNavigationContext();
  const { addToast } = useToastNotifications();
  const [channels, setChannels] = useState<AdChannel[]>(
    currentVacancy?.channels || []
  );
  const [toggleChannel] = useMutation<
    { toggleChannel: { channels: AdChannel[] } },
    { vacancyId: number; input: { channel: string } }
  >(TOGGLE_CHANNEL);

  const handleChannelClick = async (channel: AdChannel) => {
    await toggleChannel({
      variables: {
        vacancyId: activeVacancy as number,
        input: {
          channel: channel,
        },
      },
      onCompleted: (data) => {
        setChannels(data.toggleChannel.channels);
      },
    }).then(() => {
      // TODO: Use sonner component from shadCN with loading state here instead of toast - 03/10/2024-09h13
      addToast({
        description: `${channel.toLowerCase()} was successfully turned ${
          channels.includes(channel) ? 'off' : 'on'
        }.`,
        type: ToastTypes.SUCCESS,
      });
    });
  };

  const activeSpending =
    currentVacancy?.spend_status === VacancySpendStatus.ACTIVE;

  return (
    <ToggleGroup type="multiple">
      {AD_CHANNELS.map((channel) => (
        <ToolTip
          text={
            activeSpending
              ? `Turn ${
                  channels.includes(channel) ? 'off' : 'on'
                } ${channel.toLowerCase()}`
              : 'Start campaign in order to toggle individual channels.'
          }
          placement="top"
          arrow
        >
          <ToggleGroupItem
            tw="px-1"
            key={channel}
            value={channel}
            onClick={() => handleChannelClick(channel)}
          >
            <ChannelButton
              isActive={activeSpending && channels.includes(channel)}
              isToggle
              channelType={channel}
              disabled={!activeSpending}
              grayScale
            />
          </ToggleGroupItem>
        </ToolTip>
      ))}
    </ToggleGroup>
  );
};

export default ChannelToggleGroup;
