import React from 'react';
import tw, { css, styled, theme } from 'twin.macro';

export type ToggleButtonProps = {
  id?: string;
  name?: string;
  value?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  id,
  name,
  value = false,
  onChange,
}) => {
  return (
    <ToggleWrapper>
      <label tw="flex items-center cursor-pointer">
        <div tw="relative">
          <input
            id={id}
            name={name}
            type="checkbox"
            tw="sr-only"
            onChange={(e) => onChange?.(e)}
            checked={value}
          />
          <div id="track" tw="w-10 h-3 bg-gray-200 rounded-full shadow-inner" />
          <div
            id="dot"
            tw="absolute w-5 h-5 bg-gray-300 rounded-full shadow transition"
            css={{ left: '0', top: '-0.25rem' }}
          />
        </div>
      </label>
    </ToggleWrapper>
  );
};

const ToggleWrapper = styled.div(() => [
  tw`flex items-center justify-center`,
  css`
    input:checked ~ #dot {
      transform: translateX(100%);
      background-color: ${theme`colors.indigo.500`};
    }
  `,
  css`
    input:checked ~ #track {
      background-color: ${theme`colors.indigo.300`};
    }
  `,
]);

export default ToggleButton;
