export const getLanguageCode = (language: number) => {
  switch (language) {
    case 385:
      return 'nl';
    case 141:
      return 'en';
    case 167:
      return 'fr';
    case 115:
    case 117:
      return 'de';
    case 237:
      return 'it';
    case 425:
      return 'pl';
    case 148:
      return 'es';
    default:
      return 'en';
  }
};
