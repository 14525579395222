import React, { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Error as ErrorText } from 'components/typography/Typography';
import Input, { InputProps } from '../input/Input';
import SelectInput from './fields/SelectInput';
import InteractiveCheckbox from './fields/InteractiveCheckbox/InteractiveCheckbox';

export enum FieldTypes {
  Text = 'text',
  Number = 'number', // todo
  Select = 'select', // todo
  CreatableSelect = 'creatable-select', // todo
  Radio = 'radio', // todo
  Checkbox = 'checkbox', //to do
  InteractiveCheckbox = 'interactive-checkbox',
}

export type OptionType = {
  value: string;
  label?: string | ReactNode;
  icon?: ReactNode;
};

export interface FieldGeneratorProps {
  name: string;
  label?: string | ReactNode;
  type: FieldTypes;
  rules?: any;
  defaultValue?: string;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  gridColumns?: number;

  // interactive checkbox props
  isCreatable?: boolean;
  creatableProps?: InputProps;
  options?: OptionType[];
  optionRenderer?: (optionObj: any) => ReactNode;
  moveOnAnswer?: boolean;

  currentIndex: number;
  onHandleNavigateToStep: (index: number) => void;
}

const FieldGenerator: FC<FieldGeneratorProps> = (props) => {
  const {
    name,
    label,
    type,
    rules,
    defaultValue,
    placeholder,
    autoFocus,
    className,
    gridColumns = 4,
    // interactive checkbox props
    isCreatable,
    creatableProps,
    options,
    optionRenderer,
    moveOnAnswer,
    currentIndex,
    onHandleNavigateToStep,
  } = props;

  const { errors, control } = useFormContext();

  const handleOnChange = (val: string, onChange: (...event: any[]) => void) => {
    onChange(val);
    if (moveOnAnswer) {
      onHandleNavigateToStep(currentIndex + 1);
    }
  };

  return (
    <div tw="w-full">
      <Controller
        id={name}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ onChange, value }) => (
          <div className={className}>
            {(() => {
              switch (type) {
                case FieldTypes.InteractiveCheckbox:
                  return (
                    <InteractiveCheckbox
                      name={name}
                      label={label}
                      isCreatable={isCreatable}
                      creatableProps={creatableProps}
                      options={options}
                      optionRenderer={optionRenderer}
                      gridColumns={gridColumns}
                      value={value}
                      onChange={(e) => handleOnChange(e.target.value, onChange)}
                    />
                  );
                case FieldTypes.Select:
                  return (
                    <SelectInput
                      name={name}
                      label={label}
                      placeholder={placeholder}
                      value={value}
                      onChange={(e) => handleOnChange(e.target.value, onChange)}
                      options={options}
                    />
                  );
                default:
                  return (
                    <>
                      {label && <label htmlFor={name}>{label}</label>}
                      <Input
                        type={type}
                        value={value}
                        onChange={(e) =>
                          handleOnChange(e.target.value, onChange)
                        }
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                      />
                    </>
                  );
              }
            })()}
          </div>
        )}
      />
      {errors && errors[name] && (
        <ErrorText>
          {errors[name].message.length > 0
            ? errors[name].message
            : 'This field is required'}
        </ErrorText>
      )}
    </div>
  );
};

export default FieldGenerator;
