import React from 'react';

import ButtonGroup from 'components/form/ButtonGroup';
import Select from 'components/form/Select';
import {
  IntakeInputTypes,
  IntakeQuestion,
  IntakeSection,
} from 'types/intakeTypes';
import InviteCoworker from 'components/form/InviteCoworker';
import LanguageSelect from 'components/form/LanguageSelect';
import {
  questionValueToCustomerAddress,
  questionValueToFileUploadsWithText,
  questionValueToSelectableLanguageArray,
  questionValueToStringArray,
} from './typeHelpers';
import IntakeColorPicker from 'components/intake/color-picker/IntakeColorPicker';
import IntakeUpload from 'components/intake/intake-upload/IntakeUpload';
import IntakeTextEditor from 'components/intake/text-editor/IntakeTextEditor';
import IntakeApiDropdown from 'components/intake/api-dropdown/IntakeApiDropdown';
import IntakeInputGroup from 'components/intake/input-group/IntakeInputGroup';
import IntakeInput from 'components/intake/input/IntakeInput';
import IntakeCounter from 'components/intake/counter/IntakeCounter';
import IntakePillGroup from 'components/intake/pill-group/IntakePillGroup';
import IntakeVat from 'components/intake/vat/IntakeVat';
import IntakeCustomerAddress from 'components/intake/customer-address/IntakeCustomerAddress';

const getInputElement = (
  question: IntakeQuestion,
  formId: string,
  section: Omit<IntakeSection, 'hash' | 'steps'>,
  colorQuestions: IntakeQuestion[]
) => {
  switch (question.type) {
    case IntakeInputTypes.TEXT:
    case IntakeInputTypes.AREA:
    case IntakeInputTypes.PHONE:
      return (
        <IntakeInput
          question={question}
          defaultValue={question.value?.toString() as string}
        />
      );
    case IntakeInputTypes.SELECT:
      return (
        <Select
          question={question}
          section={section.name}
          options={question.options as string[]}
        />
      );
    case IntakeInputTypes.TEXTEDITOR:
      return (
        <IntakeTextEditor
          question={question}
          defaultValue={question.value?.toString() as string}
        />
      );
    case IntakeInputTypes.BTNGROUP:
      if (question.options) {
        return (
          <ButtonGroup
            question={question}
            section={section.name}
            options={question.options as string[]}
          />
        );
      } else break;
    case IntakeInputTypes.COLOR:
      return (
        <IntakeColorPicker
          question={question}
          defaultValue={question.value ? (question.value as string) : undefined}
          variant={
            colorQuestions.length > 0 && colorQuestions[0].id === question.id
              ? 'primary'
              : 'secondary'
          }
        />
      );
    case IntakeInputTypes.COUNTER:
      return (
        <IntakeCounter
          question={question}
          defaultValue={
            question.value
              ? (parseInt(question.value.toString()) as number)
              : undefined
          }
        />
      );
    case IntakeInputTypes.SINGLECHOICE:
    case IntakeInputTypes.MULTIPLECHOICE: {
      let defaultValue = questionValueToStringArray(question.value);
      defaultValue =
        defaultValue.length !== 0
          ? defaultValue
          : [defaultValue as unknown as string];

      return (
        <IntakePillGroup
          question={question}
          defaultValue={defaultValue}
          disableAdd={question.addingNotAllowed ?? false}
        />
      );
    }
    case IntakeInputTypes.TEXTGROUP:
    case IntakeInputTypes.TEXTGROUP_WITH_SYMBOL:
      return (
        <IntakeInputGroup
          question={question}
          defaultValue={question.value as string[]}
        />
      );
    case IntakeInputTypes.INVITECOWORKER:
      return (
        <InviteCoworker required={!!question.required} question={question} />
      );
    case IntakeInputTypes.LANGUAGESELECT:
      return (
        <LanguageSelect
          question={question}
          questionValue={questionValueToSelectableLanguageArray(question.value)}
          section={section.name}
        />
      );
    case IntakeInputTypes.CUSTOMER_ADDRESS:
      return (
        <IntakeCustomerAddress
          question={question}
          defaultValue={questionValueToCustomerAddress(question.value)}
        />
      );
    case IntakeInputTypes.API_SELECT:
      return (
        <IntakeApiDropdown
          question={question}
          defaultValue={question.value ? (question.value as string) : undefined}
        />
      );
    case IntakeInputTypes.VAT:
      return (
        <IntakeVat
          question={question}
          defaultValue={question.value?.toString() as string}
        />
      );
    case IntakeInputTypes.UPLOAD:
    case IntakeInputTypes.UPLOAD_MULTI:
    case IntakeInputTypes.UPLOAD_WITH_TEXT:
    case IntakeInputTypes.UPLOAD_MULTI_WITH_TEXT:
      return (
        <IntakeUpload
          form={formId}
          question={question}
          section={section}
          defaultValue={questionValueToFileUploadsWithText(question.value)}
          variant={
            question.type === IntakeInputTypes.UPLOAD_WITH_TEXT ||
            question.type === IntakeInputTypes.UPLOAD_MULTI_WITH_TEXT
              ? 'text'
              : 'normal'
          }
          multiple={
            question.type === IntakeInputTypes.UPLOAD_MULTI ||
            question.type === IntakeInputTypes.UPLOAD_MULTI_WITH_TEXT
          }
        />
      );
    default:
      return (
        <IntakeInput
          question={question}
          defaultValue={question.value?.toString() as string}
        />
      );
  }
};

export default getInputElement;
