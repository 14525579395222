import React from 'react';
import tw, { styled, css } from 'twin.macro';

const PageWrapper: React.FC<{
  heading?: React.ReactNode;
  children: React.ReactNode;
  disableContainer?: boolean;
  tabs?: boolean;
}> = ({ heading, children, tabs = false, disableContainer = false }) => {
  return (
    <>
      {heading && <HeadingWrapper tabs={tabs}>{heading}</HeadingWrapper>}
      <ContentWrapper id="content-wrapper" disableContainer={disableContainer}>
        {children}
      </ContentWrapper>
    </>
  );
};

const HeadingWrapper = styled.div<{ tabs: boolean }>`
  ${tw`flex justify-between w-full sticky top-0 z-30`}
  ${({ tabs }) =>
    tabs
      ? css`
          @media (min-width: 320px) {
            height: 240px;
          }
        `
      : css`
          @media (min-width: 320px) {
            height: auto;
          }
        `}
`;

const ContentWrapper = styled.div<{
  disableContainer: boolean;
}>`
  ${tw`overflow-y-hidden max-w-full h-full grow px-3 md:px-11 xl:px-16`}
  ${({ disableContainer }) =>
    disableContainer && tw`h-full w-full p-0 m-0 md:p-0 xl:p-0`}
`;

export default PageWrapper;
