import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useMutation } from 'hooks/sympl-mutation';
import { SAVE_CANDIDATE_NOTES } from 'graphql/candidates/mutations';

import TextEditor from 'components/form/text-editor/TextEditor';
import Container from 'components/container/Container';
import ContainerAction from 'components/container/container-action/ContainerAction';
import SaveButton from 'components/save-button/SaveButton';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';

interface CandidateNotesProps {
  procedureId: number;
  defaultValue?: string;
  onNotesSaved: (callback?: () => void) => void;
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
}

interface IFormInputs {
  notes: string;
}

interface SaveCandidateNotesRequest {
  proc_id: number;
  input: {
    notes: string;
  };
}

const CandidateNotes: React.FC<CandidateNotesProps> = ({
  procedureId,
  defaultValue = '',
  onNotesSaved,
  setIsSaving,
  isSaving,
}) => {
  const { addToast } = useToastNotifications();
  const { handleSubmit, control } = useForm<IFormInputs>({
    defaultValues: {
      notes: defaultValue,
    },
  });

  const [saveCandidateNotes] = useMutation<
    undefined,
    SaveCandidateNotesRequest
  >(SAVE_CANDIDATE_NOTES);

  const saveNotes: SubmitHandler<IFormInputs> = ({ notes }) => {
    setIsSaving(true);
    saveCandidateNotes({
      variables: {
        proc_id: procedureId,
        input: {
          notes,
        },
      },
    }).then(() => {
      addToast({
        type: ToastTypes.SUCCESS,
        description: 'Notes saved successfully',
      });
      onNotesSaved(() => setIsSaving(false));
    });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(saveNotes)}>
        <Controller
          id="notes"
          name="notes"
          control={control}
          render={({ onChange }) => (
            <TextEditor
              identifier="candidate_notes"
              defaultValue={defaultValue}
              onChange={onChange}
            />
          )}
        />

        <ContainerAction>
          <SaveButton type="submit" loading={isSaving} />
        </ContainerAction>
      </form>
    </Container>
  );
};

export default CandidateNotes;
