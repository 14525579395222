import tw, { styled } from 'twin.macro';
import { useQuery } from 'hooks/sympl-query';
import AppPage from 'components/page/app-page/AppPage';
import { GET_LEAD } from 'graphql/leads/queries';
import useTransitionCandidateMutation from 'hooks/candidates/transitionCandidate';
import useNavigationContext from 'hooks/context/nav-context';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Routes } from 'types/routeTypes';
import { Modal } from 'components/page/app-page/Modal';
import SendMailForm from 'components/candidates/send-mail-form/SendMailForm';
import { FormProvider, useForm } from 'react-hook-form';
import { Lead } from 'types/leads/types';
import ContactList from '../../components/candidates/contact-list/ContactList';
import { InfoSection } from '../candidates/CandidateGeneralInfo';

interface PageParams extends Record<string, string | undefined> {
  lead?: string;
}

const CandidateDetail: React.FC = () => {
  const formMethods = useForm();
  const fetchedVacancy = useRef<number>();
  const { lead } = useParams<PageParams>();
  const { activeVacancy } = useNavigationContext();

  const mailSubjectId = 'candidate-end-subject';
  const mailBodyId = 'candidate-end-body';

  const { transitioningCandidate } = useTransitionCandidateMutation();

  const [isModalShown, setIsModalShown] = useState(false);
  const [leadData, _setLeadData] = useState<Lead>();

  const leadId = useMemo(() => (lead ? parseInt(lead) : 0), [lead]);

  const {
    loading,
    error,
    refetch: refetchLead,
  } = useQuery<{ lead: Lead }, { leadId?: number }>(GET_LEAD, {
    skip: fetchedVacancy.current === activeVacancy ?? 0,
    variables: { leadId },
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      _setLeadData(result.lead as Lead);
    },
  });

  const modalCancelHandler = () => {
    // setIsEndProcedureActive(false);
    setIsModalShown?.(false);
  };

  const skeleton = (
    <ul tw="flex flex-col">
      {[...Array(7)].map((_x, i) => (
        <li key={i} tw="flex flex-col h-full w-full mb-8">
          <div tw="bg-gray-200 h-6 w-full animate-pulse rounded-md" />
          <div tw="mt-2 bg-gray-200 h-4 w-3/5 animate-pulse rounded-md" />
        </li>
      ))}
    </ul>
  );

  useEffect(() => {
    if (!leadId) return;
    refetchLead();
  }, [leadId, refetchLead]);

  // If no procedure given, return to candidate overview
  if (!leadId || (!loading && error))
    return <Navigate to={Routes.CANDIDATES} />;

  return (
    <AppPage
      heading={`${leadData?.firstname} ${leadData?.lastname}` ?? 'Lead details'}
      loading={loading}
      topPaddingOnly={true}
      breadcrumbUrl={Routes.CANDIDATES}
      skeleton={skeleton}
    >
      <FormProvider {...formMethods}>
        <div tw="flex w-full h-full grow-0 shrink-0">
          <InfoSection tw="max-h-64">
            <ContactList
              email={leadData?.email as string}
              phone={leadData?.phone as string}
              source={leadData?.source as string}
            />
          </InfoSection>
        </div>

        <Modal show={isModalShown} onClose={modalCancelHandler}>
          <SendMailFormWrapper>
            <SendMailForm
              variant="normal"
              procedureId={leadId}
              subjectId={mailSubjectId}
              bodyId={mailBodyId}
              onSend={() => {}}
              onSendLoading={transitioningCandidate}
              skippable={true}
              lead={leadData}
            />
          </SendMailFormWrapper>
        </Modal>
      </FormProvider>
    </AppPage>
  );
};

const SendMailFormWrapper = styled.div(tw`mt-2 max-w-4xl md:min-w-[56rem]`);

export default CandidateDetail;
