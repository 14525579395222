import React from 'react';
import tw, { styled } from 'twin.macro';

const ProgressBar: React.FC<{ progressPercentage: number }> = ({
  progressPercentage,
}) => {
  return (
    <div tw="h-1 w-full bg-gray-300">
      <ProgressLine progressPercentage={progressPercentage} />
    </div>
  );
};

const ProgressLine = styled.div<{ progressPercentage: number }>`
  ${tw`h-full bg-sympl opacity-70`}
  width: ${({ progressPercentage }) => `${progressPercentage}%`};
`;

export default ProgressBar;
