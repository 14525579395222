import { FormQuestion } from 'components/survey/form-questions-list/FormQuestionsList';
import useNavigationContext from 'hooks/context/nav-context';
import React, { createContext, useEffect, useMemo, useState } from 'react';

import { CustomerResource } from 'types/apiTypes';
import { v4 as uuid } from 'uuid';
import { useSymplCookie } from 'hooks/symplCookie';
import { GET_VACANCY_SURVEY } from 'graphql/vacancies/queries';
import { useQuery } from 'hooks/sympl-query';
import {
  UPDATE_VACANCY_QUESTIONS,
  UPDATE_VACANCY_SURVEY,
} from 'graphql/vacancies/mutations';
import { useMutation } from 'hooks/sympl-mutation';
import { ApolloError } from '@apollo/client';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';

export type ISurveyContext = {
  questions: FormQuestion[];
  currentOpenQuestion?: string;
  typeformUrl?: string;
  updatingVacancySurvey: boolean;
  updateVacancySurveyError?: ApolloError;
  surveyData?: SurveyQuestionsPayload;
  loadingQuestions: boolean;
  logo: CustomerResource | null;
  primaryColor?: string;
  secondaryColor?: string;
  hasUnsavedChanges: boolean;
  setPrimaryColor: (color: string) => void;
  setSecondaryColor: (color: string) => void;
  setLogo: (logo: CustomerResource | null) => void;
  setQuestions: (questions: FormQuestion[]) => void;
  changeQuestion: (question: FormQuestion) => void;
  createQuestion: () => void;
  deleteQuestion: (questionRef: FormQuestion['ref']) => void;
  cancelEditQuestion: () => void;
  onSave: () => Promise<void>;
  questionClicked: (questionRef?: string) => void;
};

export const SurveyContext = createContext<ISurveyContext>(undefined!);

export interface SurveyQuestionsPayload {
  vacancy: {
    published: boolean;
    typeform_url?: string;
    vacSurvey: {
      id: number;
      logo_id: number | null;
      primary_color: string | null;
      secondary_color: string | null;
      questions: FormQuestion[];
    };
  };
}

interface AllQuestionsMutationPayload {
  vacancyId: number;
  input: {
    survey_questions: FormQuestion[];
  };
}

interface UpdateVacancySurveyPayload {
  logo_id: number;
  primary_color: string | null;
  secondary_color: string | null;
}

export const SurveyContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const { activeVacancy, hasUnsavedChanges, setHasUnsavedChanges } =
    useNavigationContext();
  const { addToast } = useToastNotifications();

  const [questions, _setQuestions] = useState<FormQuestion[]>([]);
  const [logo, setLogo] = useState<CustomerResource | null>(null);
  const [primaryColor, setPrimaryColor] = useState<string>();
  const [secondaryColor, setSecondaryColor] = useState<string>();
  const [cookie, setCookie] = useSymplCookie<number[]>('viewed-surveys');

  const [currentOpenQuestion, setCurrentOpenQuestion] = useState<string>();

  const {
    loading: loadingQuestions,
    data: surveyData,
    refetch: refetchQuestions,
  } = useQuery<SurveyQuestionsPayload, { vacancyId: number }>(
    GET_VACANCY_SURVEY,
    {
      skip: !activeVacancy,
      nextFetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: { vacancyId: activeVacancy ?? 0 },
    }
  );

  const [updateAllQuestions] = useMutation<
    undefined,
    AllQuestionsMutationPayload
  >(UPDATE_VACANCY_QUESTIONS, {
    awaitRefetchQueries: true,
  });

  const [
    updateVacancySurvey,
    { loading: updatingVacancySurvey, error: updateVacancySurveyError },
  ] = useMutation<
    undefined,
    {
      vacancySurveyId: number;
      input: UpdateVacancySurveyPayload;
    }
  >(UPDATE_VACANCY_SURVEY);

  const typeformUrl = useMemo(
    () => surveyData?.vacancy?.typeform_url,
    [surveyData?.vacancy?.typeform_url]
  );

  const setQuestions = (updatedQuestions: FormQuestion[]) => {
    _setQuestions(updatedQuestions);
    if (questions.length > 0 && updatedQuestions !== questions)
      setHasUnsavedChanges(true);
  };

  useEffect(() => {
    (async () => await refetchQuestions())();
  }, [refetchQuestions]);

  useEffect(() => {
    setQuestions(
      (surveyData?.vacancy?.vacSurvey.questions ?? []).map((question) => ({
        ...question,
        required: !!question?.required,
        editable: !!question?.editable,
        custom: !!question?.custom,
        delete: false,
      }))
    );
    return () => setQuestions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyData]);

  const initLogo = useMemo(() => {
    return {
      id: surveyData?.vacancy?.vacSurvey.logo_id,
    } as CustomerResource;
  }, [surveyData?.vacancy]);

  useEffect(() => {
    if (initLogo?.id !== logo?.id) setHasUnsavedChanges(true);
    else setHasUnsavedChanges(false);
  }, [logo, initLogo, setHasUnsavedChanges]);

  const changeQuestion = (question: FormQuestion) => {
    const updatedQuestions = [...questions];
    const questionIndex = questions.findIndex((q) => q.ref === question.ref);

    // Skip unknown questions
    if (questionIndex === -1) return;

    // Update existing question
    updatedQuestions.splice(questionIndex, 1, { ...question });

    // Update questions
    setQuestions(updatedQuestions);

    setCurrentOpenQuestion(undefined);
  };

  /**
   * Creates a new question (with a unique uuid)
   * and appends it to the current question array
   */
  const createQuestion = () => {
    const ref = uuid();

    const zipIndex = questions.findIndex(({ ref }) => ref === 'zipcode');

    const updatedQuestions: FormQuestion[] = [...questions];
    // Insert new object after the one with 'zip_code' ref
    updatedQuestions.splice(zipIndex + 1, 0, {
      label: '',
      type: 'long_text',
      index: 3,
      required: true,
      editable: true,
      custom: true,
      delete: false,
      ref,
    });

    setQuestions(updatedQuestions);

    // Open newly created question
    questionClicked(ref);
  };

  /**
   * Closes all questions except current one or
   * toggles the questions if it is already active
   */
  const questionClicked = (questionRef?: string) => {
    setCurrentOpenQuestion(questionRef);
  };

  /** Removes a question from the question array */
  const deleteQuestion = (questionRef: FormQuestion['ref']) => {
    const updatedQuestions = questions.map((q) =>
      q.ref === questionRef ? { ...q, delete: true } : q
    );
    setQuestions(updatedQuestions);
    setCurrentOpenQuestion(undefined);
  };

  /** Inactivates all questions */
  const cancelEditQuestion = () => setCurrentOpenQuestion(undefined);

  const onSave = async () => {
    if (!activeVacancy) return Promise.reject();

    await updateAllQuestions({
      variables: {
        vacancyId: activeVacancy!,
        input: {
          survey_questions: questions.filter(({ label }) => label !== ''),
        },
      },
    });

    if (logo) {
      await updateVacancySurvey({
        variables: {
          vacancySurveyId: surveyData?.vacancy?.vacSurvey.id ?? 0,
          input: {
            logo_id: logo.id,
            primary_color: primaryColor ?? null,
            secondary_color: secondaryColor ?? null,
          },
        },
      });
    }

    addToast({
      type: ToastTypes.SUCCESS,
      description: 'The survey has been saved',
    });

    refetchQuestions();
    return Promise.resolve();
  };

  useEffect(() => {
    return () => setHasUnsavedChanges(false);
  }, [setHasUnsavedChanges]);

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, []);

  useEffect(() => {
    if (!surveyData) return;

    if (surveyData.vacancy?.vacSurvey.logo_id)
      setLogo({
        id: surveyData.vacancy?.vacSurvey.logo_id,
      } as CustomerResource);

    if (surveyData.vacancy?.vacSurvey.primary_color)
      setPrimaryColor(surveyData.vacancy?.vacSurvey.primary_color);
    if (surveyData.vacancy?.vacSurvey.secondary_color)
      setSecondaryColor(surveyData.vacancy?.vacSurvey.secondary_color);
  }, [surveyData]);

  useEffect(() => {
    if (!activeVacancy) return;

    // First vacancy -> initialize cookie
    if (cookie === undefined) {
      setCookie([activeVacancy]);
      return;
    }

    // Vacancy not in cookie -> add it
    if (!cookie.includes(activeVacancy)) setCookie([...cookie, activeVacancy]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie]);
  return (
    <SurveyContext.Provider
      value={{
        questions,
        currentOpenQuestion,
        typeformUrl,
        updatingVacancySurvey,
        updateVacancySurveyError,
        surveyData,
        loadingQuestions,
        logo,
        primaryColor,
        secondaryColor,
        hasUnsavedChanges,
        setQuestions,
        changeQuestion,
        createQuestion,
        deleteQuestion,
        cancelEditQuestion,
        onSave,
        setLogo,
        setPrimaryColor,
        setSecondaryColor,
        questionClicked,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
