import React from 'react';
import 'twin.macro';

import SvgSympl from '../svg/SvgSympl';
import { Headline } from 'components/typography/Typography';

const AuthHeader = () => (
  <div tw="flex flex-col items-center">
    <div tw="h-20">
      <SvgSympl />
    </div>
    <Headline isCenter>Making recruitment sympl.</Headline>
  </div>
);

export default AuthHeader;
