import Button from 'components/button/Button';
import React, { useRef, useState } from 'react';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';

const Search: React.FC<{
  value: string;
  placeholder?: string;
  onFocus?: () => void;
  onClearFilter?: () => void;
  onChange: (query?: string) => void;
}> = ({ value, placeholder, onFocus, onChange, onClearFilter }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [hasFocus, setHasFocus] = useState(false);

  const blurHandler = () => {
    if (value && value !== '') setHasFocus(true);
    else setHasFocus(false);
  };

  const focusHandler = () => {
    setHasFocus(true);
    onFocus?.();
  };

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Wrapper tabIndex={0} hasFocus={hasFocus}>
      <MagnifyingGlass
        weight="bold"
        size={20}
        tw="text-gray-300 ml-2 hidden md:block"
        onClick={() => inputRef.current?.focus()}
      />
      <Input
        id="query"
        name="query"
        value={value}
        tabIndex={-1}
        ref={inputRef}
        hasFocus={hasFocus}
        placeholder={placeholder ?? 'Search'}
        onBlur={() => blurHandler()}
        onFocus={() => focusHandler()}
        onChange={(e) => searchHandler(e)}
      />
      {value && value !== '' && (
        <Button onClick={onClearFilter} variant="secondary">
          <X weight="bold" />
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ hasFocus: boolean }>`
  ${tw`flex items-center rounded-md bg-white w-full`}

  ${({ hasFocus }) =>
    hasFocus ? tw`ring-2 ring-indigo-500` : tw`ring-1 ring-gray-100`}
`;

const Input = styled.input<{ hasFocus: boolean }>`
  ${tw`text-gray-800 bg-transparent py-3 pl-2 md:pl-0 md:ml-4 font-medium rounded-md text-sm w-full md:w-auto`}
  ${({ hasFocus }) =>
    hasFocus && tw`outline-0 outline-transparent ring-0 ring-transparent`}
`;

export default Search;
