export interface Invoice {
  id: number;
  type: string;
  number: string;
  po_number?: string;
  date: string;
  amount: number;
  amount_excl: number;
  currency: string;
  lineItems?: LineItem[];
  url?: string;
  status: InvoiceStatus;
  is_paid: boolean;
  created_at: string;
}

export interface LineItem {
  id: string | number;
  uuid?: string;
  type: string;
  quantity: number;
  name: string;
  currency: string;
  value: number;
  vat?: {
    percentage: number;
    amount: number;
  };
  discount?: {
    type: 'ABSOLUTE' | 'PERCENTAGE';
    value: number;
  };
  discount_type: string | null;
  discount_value: number | null;
  linkedLineItemUuid?: string;
}

export enum InvoiceStatus {
  OPEN = 1,
  CLOSED = 2,
  SENT = 3,
  MANUAL_CHECK = 99,
}
