import React from 'react';
import { CaretDown } from '@phosphor-icons/react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw, { styled } from 'twin.macro';

export const BrandAccordionHeader: React.FC<{
  title: string;
  index?: number;
  open?: boolean;
}> = ({ title, open, index }) => {
  return (
    <Header open={open} id={`accordion-header-${index}`}>
      <h2 className="w-fit text-lg font-semibold"> {title} </h2>
      <CaretDown
        size={24}
        style={{
          transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s',
        }}
      />
    </Header>
  );
};

const Header = styled.div<{ open?: boolean }>`
  ${tw`w-full flex justify-between items-center px-8 h-12 bg-gray-50 rounded`}
  ${({ open }) => open && tw`rounded-b-none`}
`;
