import React from 'react';
import tw, { styled } from 'twin.macro';

const SkeletonCardList: React.FC<{
  cards?: number;
  variant?: 'sm' | 'base';
}> = ({ cards, variant }) => (
  <Wrapper variant={variant ?? 'base'}>
    {[...Array(cards ?? 4)].map((_, i) => (
      <ListItem key={i} />
    ))}
  </Wrapper>
);

const Wrapper = styled.ul<{ variant: 'sm' | 'base' }>`
  ${tw`flex w-full`}
  ${({ variant }) => variant === 'base' && tw`h-40`};
  ${({ variant }) => variant === 'sm' && tw`h-10`};
`;

const ListItem = styled.li(
  tw`bg-gray-200 h-full w-full animate-pulse rounded-md mr-2 last-of-type:mr-0`
);

export default SkeletonCardList;
