import MetricCard from 'components/reporting/MetricCard';
import useReportingContext from 'hooks/context/reporting-context';
import React, { useEffect, useMemo, useState } from 'react';
import 'twin.macro';
import { ReportingFields, tableHeaders } from './Reporting';
import { useQuery } from 'hooks/sympl-query';
import { GET_REPORTING_DATA } from 'graphql/reporting/queries';
import SkeletonCardList from 'components/skeleton/skeleton-card-list/SkeletonCardList';
import { ReportingData } from '../../context/ReportingContext';
import ToolTip from 'components/tooltip/ToolTip';
import tw from 'twin.macro';
import useNavigationContext from 'hooks/context/nav-context';

interface GeneralReportingData {
  total: ReportingData;
  per_date: {};
}

const General = () => {
  const { queryString, reportingScreen } = useReportingContext();
  const { activeCustomer } = useNavigationContext();
  const [reportingData, setReportingData] = useState<{
    reporting: GeneralReportingData;
  }>();

  const { loading, refetch } = useQuery<
    { reporting: GeneralReportingData },
    { queryString: string }
  >(GET_REPORTING_DATA, {
    fetchPolicy: 'network-only',
    skip: reportingScreen !== 'general' || queryString.trim().length === 0, // Skip when queryString is empty
    variables: {
      queryString: queryString,
    },
    onCompleted: (data) => {
      setReportingData(data);
    },
  });

  useEffect(() => {
    if (queryString.trim().length > 0) refetch();
  }, [queryString, activeCustomer]);

  const data = useMemo(() => {
    return reportingData?.reporting.total as Record<ReportingFields, number>;
  }, [reportingData]);

  const chartData = useMemo(() => {
    return reportingData?.reporting.per_date as {
      [K in ReportingFields]: Array<{
        [key: string]: number;
      }>;
    };
  }, [reportingData]);

  const metrics = [
    'Impressions',
    'Clicks',
    'Visits',
    'Applications',
    'Spend',
    'CTR',
    'Conversion',
    'CPM',
    'CPC',
    'CPA',
  ];
  const budgetMetrics = ['Spend', 'CPM', 'CPC', 'CPA'];
  const percentageMetrics = ['CTR', 'Conversion'];
  const noDecimalMetrics = ['Applications'];

  const getToolTipContent = (metric: string) => {
    return tableHeaders.flatMap((el) => el).find((el) => el.name === metric)
      ?.description;
  };

  while (loading) {
    return (
      <div>
        <SkeletonCardList cards={5} />
      </div>
    );
  }

  return (
    <div tw="mt-8 w-full 3xl:w-fit">
      {/* METRIC CARDS */}
      <div tw="w-full grid grid-cols-5 grid-rows-2 gap-6 3xl:(w-fit gap-10)">
        {metrics.map((el: string, i: number) => {
          return (
            <MetricCard
              key={i}
              title={
                <ToolTip
                  content={getToolTipContent(el)}
                  placement="top"
                  maxWidth="375px"
                  arrow
                  customStyle={tw`text-white text-sm p-2`}
                >
                  <p>{el}</p>
                </ToolTip>
              }
              name={el}
              value={data?.[el.toLowerCase() as ReportingFields]}
              chartData={chartData?.[el.toLowerCase() as ReportingFields]}
              isBudget={budgetMetrics.indexOf(el) !== -1}
              isPercentage={percentageMetrics.indexOf(el) !== -1}
              noDecimal={noDecimalMetrics.indexOf(el) !== -1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default General;
