import 'twin.macro';
import React, { useEffect } from 'react';

import PageLayout from 'components/page/page-layout/PageLayout';

import { fireEvent } from 'utils/eventHelper';

interface PaymentProps {
  variant?: 'success' | 'failed';
}

const Payment: React.FC<PaymentProps> = ({ variant = 'success' }) => {
  useEffect(() => {
    if (variant === 'success') fireEvent('ss_stripe_pay_campaign_budget');
  }, [variant]);

  return (
    <PageLayout>
      <div tw="h-full w-full flex flex-col justify-center items-center">
        {variant === 'success' ? (
          <>
            <p tw="text-xl">You successfully paid for your campaign budget!</p>
            <p tw="text-gray-600 text-sm">
              It could take a few minutes to show up in the app.
            </p>
          </>
        ) : (
          <>
            <p tw="text-xl">
              We failed to process your payment request, please try again.
            </p>
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default Payment;
