import React, { forwardRef } from 'react';
import tw from 'twin.macro';
import InputMask from 'react-input-mask';

export interface MaskedInputProps
  extends React.ComponentPropsWithoutRef<'input'> {
  /**
   * Mask string. Format characters are:
   * * `9`: `0-9`
   * * `a`: `A-Z, a-z`
   * * `\*`: `A-Z, a-z, 0-9`
   *
   * Any character can be escaped with backslash, which usually will appear as double backslash in JS strings.
   * For example, German phone mask with unremoveable prefix +49 will look like `mask="+4\\9 99 999 99"` or `mask={"+4\\\\9 99 999 99"}`
   */
  mask?: string | (string | RegExp)[];
  /**
   * Character to cover unfilled editable parts of mask. Default character is "_".
   */
  placeholder?: string;
  ariaInvalid?: boolean;
  ref?: React.Ref<HTMLInputElement>;
}

const MaskedInput: React.FC<MaskedInputProps> = forwardRef<
  HTMLInputElement,
  MaskedInputProps
>(({ mask, ...props }, ref) => {
  return (
    <InputMask
      {...props}
      ref={ref as any}
      mask={mask ?? ''}
      css={tw`focus:outline-none`}
    />
  );
});

export default MaskedInput;
