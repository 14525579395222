import tinycolor from 'tinycolor2';
import { AdVariant } from 'types/adEditorTypes';
import { AdPlacement } from 'types/adTypes';
import { Brand } from 'types/customer/types';

export enum OVERLAY_OPTIONS {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  DEFAULT_TOP = 'DEFAULT_TOP',
  DEFAULT_CENTER = 'DEFAULT_CENTER',
  OPACITY = 'OPACITY',
  STICKER = 'STICKER',
  CERES = 'CERES',
}

const OVERLAYS = {
  DEFAULT: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="{OVERLAY_WIDTH}" height="{OVERLAY_HEIGHT}" viewBox="0 0 {OVERLAY_VB_WIDTH} {OVERLAY_VB_HEIGHT}" xml:space="preserve">
    <image x="{IMAGE_X}" y="{IMAGE_Y}" width="{DISPLAY_WIDTH}" height="{DISPLAY_HEIGHT}" xlink:href="{IMAGE_URL}" />

    <rect x="0" y="{BANNER_Y}" width="{OVERLAY_VB_WIDTH}" height="{BANNER_HEIGHT}" style="fill: rgb(255, 255, 255); fill-rule: nonzero; opacity: 1;" />
    <text x="{CENTER_X}" y="{WANTED_Y}" xml:space="preserve" font-family="Arial" font-size="55" font-style="normal" font-weight="700" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: {SECONDARY_COLOR}; fill-rule: nonzero; opacity: 1; white-space: pre;" text-anchor="middle">{TEXT_1}</text>
    <text x="{CENTER_X}" y="{FUNCTION_Y}" xml:space="preserve" font-family="Arial" font-size="60" font-style="normal" font-weight="700" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: {PRIMARY_COLOR}; fill-rule: nonzero; opacity: 1; white-space: pre;" text-anchor="middle">{TEXT_2}</text>

    <image x="{LOGO_X}" y="{LOGO_Y}" width="{LOGO_WIDTH}" height="{LOGO_HEIGHT}" xlink:href="{LOGO_URL}" />
  </svg>
`,
  DEFAULT_TOP: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="{OVERLAY_WIDTH}" height="{OVERLAY_HEIGHT}" viewBox="0 0 {OVERLAY_VB_WIDTH} {OVERLAY_VB_HEIGHT}" xml:space="preserve">
    <image x="{IMAGE_X}" y="{IMAGE_Y}" width="{DISPLAY_WIDTH}" height="{DISPLAY_HEIGHT}" xlink:href="{IMAGE_URL}" />

    <rect x="0" y="{BANNER_Y}" width="{OVERLAY_VB_WIDTH}" height="{BANNER_HEIGHT}" style="fill: rgb(255, 255, 255); fill-rule: nonzero; opacity: 1;" />
    <text x="{CENTER_X}" y="{WANTED_Y}" xml:space="preserve" font-family="Arial" font-size="55" font-style="normal" font-weight="700" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: {SECONDARY_COLOR}; fill-rule: nonzero; opacity: 1; white-space: pre;" text-anchor="middle">{TEXT_1}</text>
    <text x="{CENTER_X}" y="{FUNCTION_Y}" xml:space="preserve" font-family="Arial" font-size="60" font-style="normal" font-weight="700" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: {PRIMARY_COLOR}; fill-rule: nonzero; opacity: 1; white-space: pre;" text-anchor="middle">{TEXT_2}</text>

    <image x="{LOGO_X}" y="{LOGO_Y}" width="{LOGO_WIDTH}" height="{LOGO_HEIGHT}" xlink:href="{LOGO_URL}" />
  </svg>
`,
  DEFAULT_CENTER: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="{OVERLAY_WIDTH}" height="{OVERLAY_HEIGHT}" viewBox="0 0 {OVERLAY_VB_WIDTH} {OVERLAY_VB_HEIGHT}" xml:space="preserve">
    <image x="{IMAGE_X}" y="{IMAGE_Y}" width="{DISPLAY_WIDTH}" height="{DISPLAY_HEIGHT}" xlink:href="{IMAGE_URL}" />

    <rect x="0" y="{BANNER_Y}" width="{OVERLAY_VB_WIDTH}" height="{BANNER_HEIGHT}" style="fill: rgb(255, 255, 255); fill-rule: nonzero; opacity: 1;" />
    <text x="{CENTER_X}" y="{WANTED_Y}" xml:space="preserve" font-family="Arial" font-size="55" font-style="normal" font-weight="700" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: {SECONDARY_COLOR}; fill-rule: nonzero; opacity: 1; white-space: pre;" text-anchor="middle">{TEXT_1}</text>
    <text x="{CENTER_X}" y="{FUNCTION_Y}" xml:space="preserve" font-family="Arial" font-size="60" font-style="normal" font-weight="700" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: {PRIMARY_COLOR}; fill-rule: nonzero; opacity: 1; white-space: pre;" text-anchor="middle">{TEXT_2}</text>

    <image x="{LOGO_X}" y="{LOGO_Y}" width="{LOGO_WIDTH}" height="{LOGO_HEIGHT}" xlink:href="{LOGO_URL}" />
  </svg>
`,
  OPACITY: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="{OVERLAY_WIDTH}" height="{OVERLAY_HEIGHT}" viewBox="0 0 {OVERLAY_VB_WIDTH} {OVERLAY_VB_HEIGHT}" xml:space="preserve">
    <image x="{IMAGE_X}" y="{IMAGE_Y}" width="{DISPLAY_WIDTH}" height="{DISPLAY_HEIGHT}" xlink:href="{IMAGE_URL}" />
    <rect x="0" y="0" width="{OVERLAY_VB_WIDTH}" height="{OVERLAY_VB_HEIGHT}" style="fill: rgba(0, 0, 0, 0.2)"></rect>

    <rect x="0" y="{BORDER_Y}" width="{OVERLAY_VB_WIDTH}" height="25" style="fill: {BRIGHT_COLOR};"></rect>

    <text x="{TEXT_1_X}" y="{TEXT_1_Y}" font-family="Arial" font-size="{FUNCTION_FONT_SIZE}" font-weight="700" fill='{BRIGHT_COLOR}'>{TEXT_1}</text>
    <text x="{TEXT_2_X}" y="{TEXT_2_Y}" font-family="Arial" font-size='{FUNCTION_FONT_SIZE}' font-weight="700" fill="rgb(255,255,255)">{TEXT_2}</text>
    <text x="{TEXT_3_X}" y="{TEXT_3_Y}" font-family="Arial" font-size='{FUNCTION_FONT_SIZE}' font-weight="700" fill="rgb(255,255,255)">{TEXT_3}</text>

    <image x="{LOGO_X}" y="{LOGO_Y}" width="{LOGO_WIDTH}" height="{LOGO_HEIGHT}" xlink:href="{LOGO_URL}" />
  </svg>
`,
  STICKER: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="{OVERLAY_WIDTH}" height="{OVERLAY_HEIGHT}" viewBox="0 0 {OVERLAY_VB_WIDTH} {OVERLAY_VB_HEIGHT}" xml:space="preserve">
    <image x="{IMAGE_X}" y="{IMAGE_Y}" width="{DISPLAY_WIDTH}" height="{DISPLAY_HEIGHT}" xlink:href="{IMAGE_URL}" />
    <rect x="0" y="0" width="{OVERLAY_VB_WIDTH}" height="{OVERLAY_VB_HEIGHT}" style="fill: rgba(0, 0, 0, 0.1)"></rect>

    <rect x="0" y="{BANNER_Y}" width="{BANNER_WIDTH}" height="{BANNER_HEIGHT}" style="stroke: none; fill: rgba(255, 255, 255, 0.99);"></rect>

    <rect x="{TEXT_1_BG_X}" y="{TEXT_1_BG_Y}" width="{TEXT_1_BG_WIDTH}" height="{TEXT_1_BG_HEIGHT}" rx="35" ry="35" style="stroke: none; fill: {PRIMARY_COLOR};"></rect>
    <text x="{TEXT_1_X}" y="{TEXT_1_Y}" font-family="Arial" font-size="80px" font-weight="700" style="fill: rgb(255,255,255); filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 4px); text-align:center;" text-anchor="middle">{TEXT_1}</text>
    <text x="{TEXT_2_X}" y="{TEXT_2_Y}" font-family="Arial" font-size="{FUNCTION_FONT_SIZE}" font-weight="600" style="fill: rgb(255,255,255);" text-anchor="middle">{TEXT_2}</text>

    <!--g transform="matrix(1, 0, 0, 1, {STICKER_X}, {STICKER_Y}) rotate(-10 0 -10)">
      <ellipse style="stroke: none; fill: {PRIMARY_COLOR};" rx="120" ry="120"></ellipse>
      <text font-family="Arial" font-size="30" font-weight="600" style="dominant-baseline: middle; fill: rgb(255, 255, 255);" text-anchor="middle">{STICKER_TEXT}</text>
    </g-->

    <image x="{LOGO_X}" y="{LOGO_Y}" width="{LOGO_WIDTH}" height="{LOGO_HEIGHT}" xlink:href="{LOGO_URL}" />
  </svg>
`,
  NONE: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="{OVERLAY_WIDTH}" height="{OVERLAY_HEIGHT}" viewBox="0 0 {OVERLAY_VB_WIDTH} {OVERLAY_VB_HEIGHT}" xml:space="preserve">
    <image x="{IMAGE_X}" y="{IMAGE_Y}" width="{DISPLAY_WIDTH}" height="{DISPLAY_HEIGHT}" xlink:href="{IMAGE_URL}" />

    <image x="{LOGO_X}" y="{LOGO_Y}" width="{LOGO_WIDTH}" height="{LOGO_HEIGHT}" xlink:href="{LOGO_URL}" />
  </svg>
`,
  CERES: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="{OVERLAY_WIDTH}" height="{OVERLAY_HEIGHT}" viewBox="0 0 {OVERLAY_VB_WIDTH} {OVERLAY_VB_HEIGHT}" xml:space="preserve">
    <image x="{IMAGE_X}" y="{IMAGE_Y}" width="{DISPLAY_WIDTH}" height="{DISPLAY_HEIGHT}" xlink:href="{IMAGE_URL}" />

    <rect x="0" y="{BANNER_Y}" width="{OVERLAY_VB_WIDTH}" height="{BANNER_HEIGHT}" style="fill: rgb(255, 255, 255); fill-rule: nonzero; opacity: 1;" />
    <text x="{CENTER_X}" y="{WANTED_Y}" xml:space="preserve" font-family="Arial" font-size="55" font-style="normal" font-weight="700" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: {SECONDARY_COLOR}; fill-rule: nonzero; opacity: 1; white-space: pre;" text-anchor="middle">{TEXT_1}</text>
    <text x="{CENTER_X}" y="{FUNCTION_Y}" xml:space="preserve" font-family="Arial" font-size="60" font-style="normal" font-weight="700" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: {PRIMARY_COLOR}; fill-rule: nonzero; opacity: 1; white-space: pre;" text-anchor="middle">{TEXT_2}</text>

    <image x="{LOGO_X}" y="{LOGO_Y}" width="{LOGO_WIDTH}" height="{LOGO_HEIGHT}" xlink:href="{LOGO_URL}" />
    <image x="{CERES_WATER_X}" y="{CERES_WATER_Y}" width="{CERES_WATER_WIDTH}" height="{CERES_WATER_HEIGHT}" xlink:href="https://spaces.sympltest.be/customers/1932/ceres_watermerk_smallf25550d878f355b7f1d6.png" />
  </svg>
`,
};

const insertNewLines = (text: string, maxLength: number) => {
  const words = text.split(' ');
  let currentLine = '';
  let result = '';

  for (const word of words) {
    if (currentLine.length + word.length + 1 > maxLength) {
      if (result.length > 0) {
        result += '{NEW_LINE_BREAK}';
      }
      result += currentLine.trim();
      currentLine = word + ' ';
    } else {
      currentLine += word + ' ';
    }
  }

  if (currentLine.trim().length > 0) {
    if (result.length > 0) {
      result += '{NEW_LINE_BREAK}';
    }
    result += currentLine.trim();
  }

  return result;
};

/**
const injectText = (haystack: string, needle: string, number: number) =>
  haystack.replaceAll(needle, number.toString());
*/

/**
 * Get an overlay function
 * @param overlay => Overlay enum
 * @param currentVariant => Current active ad variant
 * @param colors? => Default colors for texts
 */
export const getOverlay = (
  overlay = OVERLAY_OPTIONS.DEFAULT,
  currentVariant?: AdVariant,
  brand?: Brand,
  isPreview = false
) => {
  let rText = '';

  const primaryColor = brand?.default_primary_color ?? '#fd535a';
  const secondaryColor = brand?.default_secondary_color ?? 'rgb(200,200,200)';

  const isPrimaryBright = tinycolor(
    brand?.default_primary_color ?? 'rgb(200,200,200)'
  ).isLight();
  const isSecondaryBright = tinycolor(
    brand?.default_secondary_color ?? 'rgb(200,200,200)'
  ).isLight();

  const brightColor = isPrimaryBright
    ? primaryColor
    : isSecondaryBright
    ? secondaryColor
    : 'rgb(255,255,255)';
  const darkColor = !isPrimaryBright
    ? primaryColor
    : !isSecondaryBright
    ? secondaryColor
    : 'rgb(0,0,0)';

  const jobFunction =
    currentVariant?.function.replaceAll(' & ', ' &amp; ') ?? '[JOB]';
  const company =
    currentVariant?.company.replaceAll(' & ', ' &amp; ') ?? '[COMPANY]';

  const isStories = currentVariant?.placement === AdPlacement.STORIES;

  let width, height, vbWidth, vbHeight;
  if (isPreview) {
    width = isStories ? 144 : 144;
    height = isStories ? 276 : 144;
    vbWidth = isStories ? 1080 : 1000;
    vbHeight = isStories ? 1920 : 1000;
  } else {
    width = isStories ? 1080 : 1000;
    height = isStories ? 1920 : 1000;
    vbWidth = width;
    vbHeight = height;
  }

  const defaultTexts = [
    brand?.default_banner_title ?? 'WANTED',
    jobFunction,
    company,
  ];

  // Assume original image dimensions come from currentVariant.path
  const originalImageWidth = currentVariant?.path?.width ?? 1920;
  const originalImageHeight = currentVariant?.path?.height ?? 1080;

  const containerAspect = vbWidth / vbHeight;
  const imageAspect = originalImageWidth / originalImageHeight;

  let imageX = 0;
  let imageY = 0;
  let displayWidth = vbWidth;
  let displayHeight = vbHeight;

  if (imageAspect > containerAspect) {
    // Image is relatively wider than the container
    // Match container height, center horizontally
    const scale = vbHeight / originalImageHeight;
    displayWidth = originalImageWidth * scale;
    imageX = (vbWidth - displayWidth) / 2;
  } else {
    // Image is relatively taller or equal
    // Match container width, center vertically
    const scale = vbWidth / originalImageWidth;
    displayHeight = originalImageHeight * scale;
    imageY = (vbHeight - displayHeight) / 2;
  }

  if (OVERLAYS[overlay].includes('preserveAspectRatio'))
    console.error('Aspect ratio found in SVG');

  // INJECT GENERAL VARIABLES
  rText = OVERLAYS[overlay]
    .replace(
      '{IMAGE_URL}',
      currentVariant?.path?.path ??
        'https://t3.ftcdn.net/jpg/02/68/55/60/360_F_268556012_c1WBaKFN5rjRxR2eyV33znK4qnYeKZjm.jpg'
    )
    .replace('{LOGO_URL}', currentVariant?.logo?.path ?? '')
    .replaceAll('{OVERLAY_WIDTH}', width.toString())
    .replaceAll('{OVERLAY_HEIGHT}', height.toString())
    .replaceAll('{OVERLAY_VB_WIDTH}', vbWidth.toString())
    .replaceAll('{OVERLAY_VB_HEIGHT}', vbHeight.toString())
    .replaceAll('{CENTER_X}', (vbWidth / 2).toString())
    .replaceAll('{CENTER_Y}', (vbHeight / 2).toString())
    .replaceAll('{PRIMARY_COLOR}', primaryColor)
    .replaceAll('{SECONDARY_COLOR}', secondaryColor)
    .replaceAll('{BRIGHT_COLOR}', brightColor)
    .replaceAll('{DARK_COLOR}', darkColor)
    .replace('{IMAGE_X}', imageX.toString())
    .replace('{IMAGE_Y}', imageY.toString())
    .replace('{DISPLAY_WIDTH}', displayWidth.toString())
    .replace('{DISPLAY_HEIGHT}', displayHeight.toString());

  /* eslint-disable */
  switch (overlay) {
    case OVERLAY_OPTIONS.DEFAULT:
    case OVERLAY_OPTIONS.CERES:
      var bannerHeight = 160;
      var margin = 25;
      var bannerY = vbHeight - bannerHeight - (isStories ? 175 : margin);

      rText = rText.replace('{BANNER_HEIGHT}', bannerHeight.toString());
      rText = rText.replace('{BANNER_Y}', bannerY.toString());
      rText = rText.replace('{WANTED_Y}', (bannerY + 65).toString());
      rText = rText.replace('{FUNCTION_Y}', (bannerY + 125).toString());

      if (overlay === OVERLAY_OPTIONS.CERES) {
        rText = rText.replace('{CERES_WATER_X}', (vbWidth * -0.4).toString());
        rText = rText.replace('{CERES_WATER_Y}', (vbHeight * -0.4).toString());
        rText = rText.replace(
          '{CERES_WATER_WIDTH}',
          (vbWidth * 0.95).toString()
        );
        rText = rText.replace(
          '{CERES_WATER_HEIGHT}',
          (vbHeight * 0.95).toString()
        );
      }

      var logo = currentVariant?.logo;
      if (logo) {
        const logoHeight = isStories ? 150 : 120;
        const logoRatio = logo.width! / logo.height!;
        const logoWidth = logoRatio * logoHeight;

        rText = rText.replace('{LOGO_WIDTH}', logoWidth.toString());
        rText = rText.replace('{LOGO_HEIGHT}', logoHeight.toString());
        rText = rText.replace(
          '{LOGO_X}',
          (vbWidth - margin - logoWidth).toString()
        );
        rText = rText.replace('{LOGO_Y}', margin.toString());
      }

      break;

    case OVERLAY_OPTIONS.DEFAULT_TOP:
      var bannerHeight = 160;
      var margin = 25;
      var bannerY = isStories ? 175 : margin;

      rText = rText.replace('{BANNER_HEIGHT}', bannerHeight.toString());
      rText = rText.replace('{BANNER_Y}', bannerY.toString());
      rText = rText.replace('{WANTED_Y}', (bannerY + 65).toString());
      rText = rText.replace('{FUNCTION_Y}', (bannerY + 125).toString());

      var logo = currentVariant?.logo;
      if (logo) {
        const logoHeight = isStories ? 150 : 120;
        const logoRatio = logo.width! / logo.height!;
        const logoWidth = logoRatio * logoHeight;

        rText = rText.replace('{LOGO_WIDTH}', logoWidth.toString());
        rText = rText.replace('{LOGO_HEIGHT}', logoHeight.toString());
        rText = rText.replace(
          '{LOGO_X}',
          (vbWidth - margin - logoWidth).toString()
        );
        rText = rText.replace(
          '{LOGO_Y}',
          (vbHeight - logoHeight - (isStories ? 175 : margin)).toString()
        );
      }

      break;

    case OVERLAY_OPTIONS.DEFAULT_CENTER:
      var bannerHeight = 160;
      var margin = 25;
      var bannerY = vbHeight / 2 - bannerHeight / 2;

      rText = rText.replace('{BANNER_HEIGHT}', bannerHeight.toString());
      rText = rText.replace('{BANNER_Y}', bannerY.toString());
      rText = rText.replace('{WANTED_Y}', (bannerY + 65).toString());
      rText = rText.replace('{FUNCTION_Y}', (bannerY + 125).toString());

      var logo = currentVariant?.logo;
      if (logo) {
        const logoHeight = isStories ? 150 : 120;
        const logoRatio = logo.width! / logo.height!;
        const logoWidth = logoRatio * logoHeight;

        rText = rText.replace('{LOGO_WIDTH}', logoWidth.toString());
        rText = rText.replace('{LOGO_HEIGHT}', logoHeight.toString());
        rText = rText.replace(
          '{LOGO_X}',
          (vbWidth - margin - logoWidth).toString()
        );
        rText = rText.replace('{LOGO_Y}', margin.toString());
      }

      break;

    case OVERLAY_OPTIONS.OPACITY:
      var margin = 50;
      rText = rText.replace('{BORDER_Y}', (vbHeight - 25).toString());

      rText = rText.replace('{TEXT_1}', company);
      rText = rText.replace('{TEXT_1_X}', margin.toString());
      rText = rText.replace('{TEXT_1_Y}', (vbHeight / 2 - 120).toString());

      rText = rText.replace('{TEXT_2}', 'is looking for a'); // TODO: translate
      rText = rText.replace('{TEXT_2_X}', margin.toString());
      rText = rText.replace('{TEXT_2_Y}', (vbHeight / 2).toString());

      rText = rText.replace('{TEXT_3}', insertNewLines(jobFunction, 25));
      rText = rText.replace('{TEXT_3_X}', margin.toString());
      rText = rText.replace('{TEXT_3_Y}', (vbHeight / 2 + 120).toString());

      rText = rText.replaceAll('{FUNCTION_FONT_SIZE}', '85px');

      var logo = currentVariant?.logo;
      if (logo) {
        const logoHeight = isStories ? 200 : 120;
        const logoRatio = logo.width! / logo.height!;
        const logoWidth = logoRatio * logoHeight;

        rText = rText.replace('{LOGO_WIDTH}', logoWidth.toString());
        rText = rText.replace('{LOGO_HEIGHT}', logoHeight.toString());
        rText = rText.replace(
          '{LOGO_X}',
          (vbWidth / 2 - logoWidth / 2).toString()
        );
        rText = rText.replace(
          '{LOGO_Y}',
          (vbHeight - logoHeight - (isStories ? 175 : margin)).toString()
        );
      }

      break;

    case OVERLAY_OPTIONS.STICKER:
      var bannerHeight = 160;
      var margin = 25;

      rText = rText.replace('{BANNER_Y}', (vbHeight - bannerHeight).toString());
      rText = rText.replace('{BANNER_WIDTH}', vbWidth.toString());
      rText = rText.replace('{BANNER_HEIGHT}', bannerHeight.toString());

      rText = rText.replace('{STICKER_X}', '150');
      rText = rText.replace(
        '{STICKER_Y}',
        (vbHeight - bannerHeight - 50).toString()
      );
      rText = rText.replace('{STICKER_TEXT}', 'Vind jouw droomjob'); // TODO: position and translate

      rText = rText.replace('{TEXT_1_X}', (vbWidth / 2).toString());
      rText = rText.replace('{TEXT_1_Y}', (vbHeight / 2 - 70).toString());

      rText = rText.replace('{TEXT_1_BG_X}', (vbWidth / 2 - 250).toString());
      rText = rText.replace(
        '{TEXT_1_BG_Y}',
        (vbHeight / 2 - 50 - 110).toString()
      );
      rText = rText.replace('{TEXT_1_BG_WIDTH}', '500');
      rText = rText.replace('{TEXT_1_BG_HEIGHT}', '130');

      rText = rText.replace('{TEXT_2}', jobFunction);
      rText = rText.replace('{TEXT_2_X}', (vbWidth / 2).toString());
      rText = rText.replace('{TEXT_2_Y}', (vbHeight / 2 + 70).toString());

      rText = rText.replace(
        '{FUNCTION_FONT_SIZE}',
        jobFunction && jobFunction.length > 25 ? '60px' : '80px'
      );

      var logo = currentVariant?.logo;
      if (logo) {
        const logoHeight = 120;
        const logoRatio = logo.width! / logo.height!;
        const logoWidth = logoRatio * logoHeight;

        rText = rText.replace('{LOGO_WIDTH}', logoWidth.toString());
        rText = rText.replace('{LOGO_HEIGHT}', logoHeight.toString());
        rText = rText.replace(
          '{LOGO_X}',
          (vbWidth - logoWidth - margin).toString()
        );
        rText = rText.replace(
          '{LOGO_Y}',
          (vbHeight - logoHeight - margin).toString()
        );
      }

      break;

    case OVERLAY_OPTIONS.NONE:
      var margin = 25;

      var logo = currentVariant?.logo;
      if (logo) {
        const logoHeight = isStories ? 150 : 120;
        const logoRatio = logo.width! / logo.height!;
        const logoWidth = logoRatio * logoHeight;

        rText = rText.replace('{LOGO_WIDTH}', logoWidth.toString());
        rText = rText.replace('{LOGO_HEIGHT}', logoHeight.toString());
        rText = rText.replace(
          '{LOGO_X}',
          (vbWidth - margin - logoWidth).toString()
        );
        rText = rText.replace('{LOGO_Y}', margin.toString());
      }

      break;

    default:
      break;
  }
  /* eslint-enable */

  // REPLACE TEXT
  const regex = /\{TEXT_(\d+)\}/g;
  rText = rText.replace(regex, (match, number) => {
    const index = parseInt(number, 10) - 1;
    return defaultTexts[index] ?? match;
  });

  if (isPreview) rText = rText.replaceAll('{NEW_LINE_BREAK}', '\n');

  return rText;
};

/*
 *
 * Remove the tspan tag
 * <tspan> tag is not supported in FabricJS
 * Source: https://github.com/fabricjs/fabric.js/issues/8439#issuecomment-1315438121
 *
 */
export function deleteTspan(svg: string) {
  const tspanTagMatch = svg.match(/<tspan.*>(.*)<\/tspan>/);
  const tspanAll = svg.match(/<tspan[^>]*>(.*?)<\/tspan>/g);
  let tspanString = null;
  let text: string | null = null;
  let x = null;
  let y = null;
  let groupString = null;
  let matrixString = null;
  let xMatrix = null;
  let yMatrix = null;
  let transformedSvg = svg;
  if (tspanTagMatch && tspanTagMatch.length > 1) {
    text = tspanTagMatch[1] as string;

    tspanString = tspanTagMatch[0];
    const coordMatch = tspanString.match(/x="(-?\d*\.?\d+)" y="(-?\d*\.?\d+)"/);
    if (coordMatch && coordMatch.length > 2) {
      x = parseFloat(coordMatch[1]);
      y = parseFloat(coordMatch[2]);
    }

    const groupTagMatch = svg.match(/<g .*transform.*>/);
    if (groupTagMatch) {
      groupString = groupTagMatch[0];

      // groupString is like <g transform="matrix(a b c d e f)" style=""  > where e = xMatrix et f = yMatrix
      const matrixMatch = groupString.match(
        /matrix\((-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+)\)/
      );
      if (matrixMatch && matrixMatch.length > 6) {
        matrixString = matrixMatch[0];
        xMatrix = parseFloat(matrixMatch[5]);
        yMatrix = parseFloat(matrixMatch[6]);

        if (
          x !== null &&
          y !== null &&
          xMatrix !== null &&
          yMatrix !== null &&
          text !== null &&
          matrixString !== null
        ) {
          const newMatrixString = `matrix(${matrixMatch[1]} ${matrixMatch[2]} ${
            matrixMatch[3]
          } ${matrixMatch[4]} ${x + xMatrix} ${y + yMatrix})`;

          let allText = '';
          tspanAll?.forEach((v, index) => {
            if (index === tspanAll?.length - 1 || tspanAll?.length === 1)
              return;
            const fValue = v.match(/<tspan.*>(.*)<\/tspan>/);
            if (fValue)
              allText +=
                index === 0 ? fValue[1] : `{NEW_LINE_BREAK}${fValue[1]}`;
          });
          allText +=
            tspanAll && tspanAll?.length > 1 ? `{NEW_LINE_BREAK}${text}` : text;
          transformedSvg = svg
            .replace(matrixString, newMatrixString)
            .replace(tspanString, allText);
        }
      }
    }
  }
  return transformedSvg;
}
