import React, { FC } from 'react';
import 'twin.macro';
import Button from 'components/button/Button';
import { SortAscending } from '@phosphor-icons/react';
import ToolTip from 'components/tooltip/ToolTip';
import SectionListSettings from './section-list-settings/SectionListSettings';
import useCareerPageContext from 'hooks/context/career-page-context';

export enum PageSettingTabKeys {
  GENERAL = 'General',
  SECTIONS = 'Sections',
}

const PageSettingsSidebar: FC = () => {
  const { activePageSettingKey: activeTab, handlePageSettingKey } =
    useCareerPageContext();

  // const [activeGeneralSettingKeys, setActiveGeneralSettingKeys] = useState<
  //   GeneralSettingKeys[]
  // >([]);

  // const toggleGeneralSettings = (section: GeneralSettingKeys) => {
  //   if (activeGeneralSettingKeys.includes(section)) {
  //     setActiveGeneralSettingKeys((prev) =>
  //       prev.filter((key) => key !== section)
  //     );
  //   } else {
  //     setActiveGeneralSettingKeys((prev) => [...prev, section]);
  //   }
  // };

  const handleClick = (tab: PageSettingTabKeys) => {
    handlePageSettingKey(tab);
  };

  // const generalSettings: IGeneralSettings[] = [
  //   {
  //     sectionKey: GeneralSettingKeys.GENERAL,
  //     title: 'Page Settings',
  //     component: <PageSettingsForm />,
  //     isAlwaysOpen: true,
  //   },
  //   // for v2
  //   // {
  //   //   sectionKey: GeneralSettingKeys.BRANDING,
  //   //   title: 'Branding Settings',
  //   //   component: <BrandingSettingsForm />,
  //   // },
  //   // {
  //   //   sectionKey: GeneralSettingKeys.SEO,
  //   //   title: 'Seo Settings',
  //   //   component: <SkeletonList rows={4} />,
  //   // },
  // ];

  const tabs = [
    // {
    //   tabKey: PageSettingTabKeys.GENERAL,
    //   label: 'General Settings',
    //   icon: <HiOutlineCog />,
    //   container: (
    //     <GeneralSettings
    //       generalSettings={[]}
    //       activeGeneralSettingKeys={activeGeneralSettingKeys}
    //       onToggleGeneralSettings={toggleGeneralSettings}
    //     />
    //   ),
    // },
    {
      tabKey: PageSettingTabKeys.SECTIONS,
      label: 'Section Settings',
      icon: <SortAscending weight="bold" />,
      container: <SectionListSettings />,
    },
  ];

  return (
    <div tw="flex flex-row ">
      <div tw="h-full p-2 border-r-2 border-gray-100 flex flex-col gap-2">
        {tabs.map((tab) => (
          <ToolTip
            key={tab.tabKey}
            text={tab.label}
            disabled={!!activeTab}
            placement="right"
            arrow
          >
            <div tw="w-full">
              <Button
                variant={activeTab === tab.tabKey ? 'indigo' : 'default'}
                onClick={() => handleClick(tab.tabKey)}
              >
                {tab.icon}
              </Button>
            </div>
          </ToolTip>
        ))}
      </div>
      {activeTab && (
        <div tw="flex h-full flex-col w-[300px] border-r-2 border-gray-100">
          {tabs.map(
            (tab) =>
              activeTab === tab.tabKey && (
                <div key={tab.tabKey} tw="w-full h-full">
                  {tab.container}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default PageSettingsSidebar;
