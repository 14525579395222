import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

import { customFetch } from './utils/apiHelpers';
import {
  getCurrentAuthToken,
  resetCurrentAuthToken,
} from './utils/authHelpers';

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: { headers: Headers }) => {
    const token = getCurrentAuthToken();
    return {
      headers: {
        ...headers,
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  });
  return forward(operation).map((result) => {
    const { restResponses } = operation.getContext();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (result?.message === 'Unauthorized') resetCurrentAuthToken();

    const authTokenResponse = restResponses?.find((res: Response) =>
      res.headers.has('Authorization')
    );

    if (authTokenResponse) {
      localStorage.setItem(
        'token',
        authTokenResponse.headers.get('Authorization')
      );
    }

    return result;
  });
});

const restLink = new RestLink({
  uri: import.meta.env.VITE_BASE_URL_API,
  customFetch,
  bodySerializers: {
    fileEncode: (data: any, headers: Headers) => {
      const formData = new FormData();

      formData.set('file', data.file);

      Object.keys(data)
        .filter((key) => key !== 'file')
        .forEach((key) => formData.set(key, data[key]));

      headers.set('Accept', '*/*');

      return { body: formData, headers };
    },
  },
  responseTransformer: async (response) => {
    return response ?? response?.json
      ? response.json().then((res: any) => {
          const isDataResponse = res.data !== undefined;
          const isArray = res?.data?.constructor === Array;
          const meta: any[] = res?.meta;

          // Single level responses eg: Stencil
          if (!isDataResponse) return res;

          const returnData = isArray
            ? res.data.map((itemData: any) => ({
                ...itemData,
                meta: { ...meta },
              }))
            : { ...res.data, meta: { ...meta } };

          return returnData;
        })
      : response;
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, restLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Vacancy: {
        keyFields: ['key'],
      },
      Candidate: {
        keyFields: ['id'],
      },
      UpdatedBudget: {
        keyFields: ['session_id'],
      },
      PlanInvoiceItems: {
        keyFields: ['id', 'interval'],
      },
    },
  }),
  connectToDevTools: true,
});

export default client;
