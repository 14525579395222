import React, { useMemo } from 'react';
import { Trash } from '@phosphor-icons/react';

import { v4 as uuid } from 'uuid';
import 'twin.macro';

import { LineItem } from 'types/invoicing/types';
import Dropdown from 'components/dropdown/Dropdown';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import { Footnote } from 'components/typography/Typography';
import InputAddOn from 'components/form/input-addon/InputAddOn';

const LINE_ITEM_TYPES = [
  {
    key: 'SERVICES',
    label: 'Service Pack',
  },
  {
    key: 'MEDIA_COST',
    label: 'Media Cost',
  },
  {
    key: 'COMMISSION',
    label: 'Commission',
  },
  {
    key: 'SUBSCRIPTION',
    label: 'Software',
  },
];

export const DEFAULT_ITEM = {
  type: LINE_ITEM_TYPES[0].key,
  name: LINE_ITEM_TYPES[0].label,
  quantity: 1,
  discount_type: 'PERCENTAGE',
  discount_value: 0,
} as LineItem;

export const calculateTotal = (lineItems: LineItem[]) => {
  let total = 0;

  lineItems.forEach((item) => {
    if (item.value && item.quantity) {
      total += item.value * item.quantity;

      if (item.discount_type && item.discount_value) {
        total =
          item.discount_type === 'PERCENTAGE'
            ? total - (item.value * item.discount_value) / 100
            : total - item.discount_value;
      }
    }
  });

  return total;
};

const InvoiceBuilder = ({
  lineItems,
  onChange,
}: {
  lineItems: LineItem[];
  onChange: (value: LineItem[]) => void;
}) => {
  const linkedLineItems = useMemo(
    () =>
      lineItems.map((lineItem) =>
        lineItem.uuid ? lineItem : { ...lineItem, uuid: uuid() }
      ),
    [lineItems]
  );

  const totalAmount = useMemo(
    () => calculateTotal(linkedLineItems),
    [linkedLineItems]
  );

  const onChangeHandler = (uuid: string | undefined, value: LineItem) => {
    onChange(
      linkedLineItems.map((item) =>
        item.uuid === uuid
          ? {
              ...item,
              ...value,
            }
          : item
      )
    );
  };

  const onDeleteHandler = (uuid?: string) => {
    onChange(linkedLineItems.filter((lineItem) => lineItem.uuid !== uuid));
  };

  return (
    <table tw="w-full max-w-2xl">
      <thead tw="w-full text-sm font-medium text-gray-700 text-left">
        <tr>
          <th tw="p-1">Description</th>
          <th tw="p-1">Quantity</th>
          <th tw="p-1">Price</th>
          <th tw="p-1">Discount</th>
          <th tw="p-1"></th>
        </tr>
      </thead>
      <tbody>
        {linkedLineItems.map((item) => (
          <tr key={item.uuid}>
            <td tw="p-1">
              <Dropdown
                items={LINE_ITEM_TYPES}
                value={item.type}
                label={item.name}
                onChange={(value) =>
                  onChangeHandler(item.uuid, {
                    type: value as string,
                    name: LINE_ITEM_TYPES.find(({ key }) => key === value)
                      ?.label,
                  } as LineItem)
                }
              />
            </td>

            <td tw="p-1">
              <Input
                type="number"
                defaultValue={item.quantity}
                onChange={(e) =>
                  onChangeHandler(item.uuid, {
                    quantity: parseInt(e.target.value),
                  } as LineItem)
                }
              />
            </td>

            <td tw="p-1">
              <Input
                type="number"
                defaultValue={item.value}
                onChange={(e) =>
                  onChangeHandler(item.uuid, {
                    value: parseFloat(e.target.value),
                  } as LineItem)
                }
              />
            </td>

            <td tw="p-1">
              <InputAddOn
                defaultValue={item?.discount_value ?? undefined}
                defaultUnit={item?.discount_type === 'ABSOLUTE' ? '€' : '%'}
                units={['€', '%']}
                onValueChange={(value) =>
                  onChangeHandler(item.uuid, {
                    discount_value: value,
                  } as LineItem)
                }
                onUnitChange={(unit) =>
                  onChangeHandler(item.uuid, {
                    discount_type: unit === '€' ? 'ABSOLUTE' : 'PERCENTAGE',
                  } as LineItem)
                }
              />
            </td>

            <td tw="p-1 flex items-center justify-center">
              <Button
                variant={'link'}
                onClick={() => onDeleteHandler(item.uuid)}
              >
                <Trash weight="bold" tw="mx-auto text-xl" />
              </Button>
            </td>
          </tr>
        ))}
        <tr>
          <td tw="py-2" colSpan={5}>
            <Button
              onClick={() => onChange([...linkedLineItems, DEFAULT_ITEM])}
              variant="inverted"
              stretch
            >
              Add line item
            </Button>
            <div tw="float-right mt-4">
              <Footnote isBold isLight={false}>
                {`The total amount is € ${totalAmount.toFixed(2)} (VAT excl.).`}
              </Footnote>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoiceBuilder;
