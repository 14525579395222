import { loadStripe } from '@stripe/stripe-js';

/**
 * Creates a new stripe session using the provided .env file
 * @returns A new Stripe session
 */
const stripeInit = async () => {
  const apiKey = import.meta.env.VITE_STRIPE_KEY;

  if (!apiKey) Promise.reject('API-key not found');

  // Initialize Stripe
  const stripePromise = loadStripe(apiKey as string);

  // Get Stripe.js instance
  const stripe = await stripePromise;

  return stripe;
};

/**
 * Redirects the user to the Stripe Checkout page using a given sessionId
 * @param sessionId The Stripe session id
 */
export const redirectToStripeCheckout = async (sessionId: string) => {
  // Create a new Stripe session
  const stripe = await stripeInit();
  // Redirect to Checkout.
  return await stripe?.redirectToCheckout({
    sessionId,
  });
};
