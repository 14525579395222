import React, { useRef, useState } from 'react';
import 'twin.macro';
import { Controller, useFormContext } from 'react-hook-form';

import { IntakeQuestion } from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';
import { Error } from 'components/typography/Typography';
import InputGroup from 'components/form/input-group/InputGroup';
import useDefocusHandler from 'hooks/defocus';

const IntakeInputGroup: React.FC<{
  question: IntakeQuestion;
  defaultValue?: string[];
}> = ({ question, defaultValue = [] }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { control, errors, setValue } = useFormContext();
  const id = question.id.toString();

  const rules = questionRules(question);

  const [hasMinError, setHasMinError] = useState(false);
  const [hasMaxError, setHasMaxError] = useState(false);

  const [inputValue, setInputValue] = useState(defaultValue);

  const changeHandler = (onChange: (value: any) => void, value: string[]) => {
    setHasMinError(false);
    setHasMaxError(false);
    setInputValue(value);
    onChange(JSON.stringify(value));
  };

  useDefocusHandler(wrapperRef, () => setValue(id, JSON.stringify(inputValue)));

  return (
    <div ref={wrapperRef}>
      <Controller
        id={id}
        name={id}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange }: { onChange: any }) => (
          <InputGroup
            id={id}
            value={inputValue}
            minInputs={question.min}
            maxInputs={question.max}
            ariaInvalid={errors[id]}
            onMaxError={() => setHasMaxError(true)}
            onMinError={() => setHasMinError(true)}
            onChange={(value) => changeHandler(onChange, value)}
          />
        )}
      />

      {(errors[id] || hasMinError || hasMaxError) && (
        <Error>{rules.required?.message}</Error>
      )}
    </div>
  );
};

export default IntakeInputGroup;
