export type IntakeNotification = {
  type: NotificationTypes;
  message: string;
};

export enum NotificationTypes {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
