import 'twin.macro';
import React, { FC, ReactNode, useState } from 'react';
import { OptionType } from '../../FieldGenerator';
import { InputProps } from 'components/form/input/Input';
import { useFormContext } from 'react-hook-form';
import * as Styled from './styles';

interface InteractiveCheckboxProps {
  name: string;
  label?: string | ReactNode;
  options?: OptionType[];
  gridColumns: number;
  value: string;
  creatableProps?: InputProps;
  isMulti?: boolean;
  isCreatable?: boolean;
  optionRenderer?: (optionObj: any) => ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InteractiveCheckbox: FC<InteractiveCheckboxProps> = ({
  name,
  label,
  options,
  gridColumns,
  value,
  isMulti,
  isCreatable,
  creatableProps,
  optionRenderer,
  onChange,
}) => {
  const [openCreatable, setOpenCreatable] = useState(false);
  const { register, setValue } = useFormContext();
  const isLabelString = typeof label === 'string';
  const isWithCreatable = isCreatable && creatableProps;

  const renderOptionLabel = (option: OptionType) => {
    if (optionRenderer) {
      return optionRenderer(option);
    }
    return option.label ?? option.value;
  };

  const handleOpenCreatable = (open?: boolean) => {
    if (open) {
      setValue(name, '');
      setOpenCreatable(true);
    } else {
      if (creatableProps?.name) {
        setValue(creatableProps?.name, '');
        setOpenCreatable(false);
      }
    }
  };

  return (
    <div tw="w-full">
      {label && isLabelString ? <Styled.Label>{label}</Styled.Label> : label}
      <Styled.Grid columns={gridColumns}>
        {options &&
          options.length > 0 &&
          options.map((option, key) => (
            <Styled.Radiobox
              key={`${name}-${key}`}
              htmlFor={`${name}-${key}`}
              onClick={() => {
                handleOpenCreatable(false);
              }}
              selected={openCreatable ? false : option.value === value}
            >
              <input
                type={isMulti ? 'checkbox' : 'radio'}
                id={`${name}-${key}`}
                name={name}
                value={option.value}
                defaultChecked={option.value === value}
                onChange={onChange}
                tw="hidden"
              />
              {renderOptionLabel(option)}
            </Styled.Radiobox>
          ))}

        {isWithCreatable && (
          <Styled.Radiobox
            selected={openCreatable}
            onClick={() => {
              handleOpenCreatable(!openCreatable);
            }}
          >
            {renderOptionLabel({ value: 'other', label: 'Other' })}
          </Styled.Radiobox>
        )}
      </Styled.Grid>
      {isWithCreatable && openCreatable && (
        <div tw="w-full flex flex-col mt-4">
          {creatableProps?.label && (
            <label htmlFor={creatableProps.name}>{creatableProps?.label}</label>
          )}
          <Styled.CreatableInput
            fullWidth
            type="text"
            name={creatableProps.name}
            ref={register({ required: true })}
            defaultValue={creatableProps?.value}
            placeholder={creatableProps?.placeholder}
            autoFocus={creatableProps?.autoFocus}
          />
        </div>
      )}
    </div>
  );
};

export default InteractiveCheckbox;
