import { Candidate } from 'types/candidates/types';
import { GET_CANDIDATE } from 'graphql/candidates/queries';
import { useLazyQuery } from 'hooks/sympl-query';

interface GetCandidateQueryData {
  candidate: Candidate;
}
interface GetCandidateQueryParams {
  vacId: number;
  procedureId: number;
}

const useGetCandidateLazyQuery = () => {
  const [getCandidate, { data: candidateData, loading: loadingCandidate }] =
    useLazyQuery<GetCandidateQueryData, GetCandidateQueryParams>(
      GET_CANDIDATE,
      { fetchPolicy: 'network-only' }
    );

  return { getCandidate, loadingCandidate, candidateData };
};

export default useGetCandidateLazyQuery;
