import { TestimonialKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';
import { faker } from '@faker-js/faker';

export const defaultTestimonialsSettings: TestimonialKeyFields &
  WithPlaceholder = {
  ...getSectionHeaderDefaultConfig(1, {
    placeholderValue: {
      title: 'Some kind words from our colleagues',
      subTitle: '',
      description: '',
      primaryButtonLabel: '',
      linkButtonLabel: '',
    },
  }),
  cards: [...Array(3)].map(() => ({
    avatar: faker.image.avatar(),
    name: faker.person.firstName(),
    jobTitle: faker.person.jobTitle(),
    content: faker.lorem.paragraph(),
    isPlaceholder: true,
  })),
};
