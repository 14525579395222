import tw, { styled } from 'twin.macro';
import React from 'react';

import { Body } from 'components/typography/Typography';

const BoosterGetStarted: React.FC = () => {
  return (
    <div tw="flex flex-col items-center justify-center max-h-[90vh]">
      <div tw="h-full flex flex-col prose my-8">
        <h2 tw="text-4xl mt-0 font-bold tracking-tight text-gray-600">
          What's next? 📋
        </h2>
        <div tw="space-y-6 pb-4">
          <StepWrapper currentStep={1} isCurrentStep={true}>
            <h3 tw="text-lg font-bold tracking-tight text-gray-500 mt-0">
              1. Campaign Preparation 🛠️
            </h3>
            <ul>
              <li>
                Our campaign managers will <b>prepare your campaign</b>
              </li>
              <li>
                There's a bunch of things that need to be done...{' '}
                <b>copywriting, videos, visuals, ads, targeting</b>
                ...
              </li>
              <li>
                You will <b>receive an email</b> when everything is ready to be
                reviewed.
              </li>
            </ul>
            <span tw="text-sm italic">
              This process usually takes 2 to 3 business days. We'll contact you
              if we need more information.
            </span>
            <SectionPill currentStep={1} isCurrentStep={true}>
              Current Phase
            </SectionPill>
          </StepWrapper>
          <StepWrapper currentStep={2} isCurrentStep={false}>
            <h3 tw="text-lg font-bold tracking-tight text-gray-500 mt-0">
              2. Feedback Round 👍
            </h3>
            <ul tw="mb-0">
              <li>
                Your campaign is <b>ready to be reviewed</b>
              </li>
              <li>
                We need your feedback on the campaign deliverables (ads, vacancy
                page, ...)
              </li>
              <li>
                <b>All good?</b> Awesome! We will publish your campaign.
              </li>
              <li>
                <b>Almost there?</b> Leave your feedback and we'll take care of
                it. We'll notify you when it's done.
              </li>
            </ul>
          </StepWrapper>
          <StepWrapper currentStep={3} isCurrentStep={false}>
            <h3 tw="text-lg font-bold tracking-tight text-gray-500 mt-2">
              3. Go Live 🚀
            </h3>
            <ul tw="mb-0">
              <li>
                Your campaign will be <b>published on all channels</b>.
              </li>
              <li>
                A <b>confirmation email</b> will be sent once everything is
                live.
              </li>
            </ul>
          </StepWrapper>
          <section tw="mt-0">
            <h3 tw="text-lg font-bold tracking-tight text-gray-500 mt-0">
              {'Questions?'} 🙋‍♀️
            </h3>
            <Body>
              We're here for you, you can{' '}
              <button
                onClick={() =>
                  (window.location.href = 'mailto:campaigns@sympl.be')
                }
                tw="underline"
              >
                {'contact your campaign manager'}
              </button>{' '}
              {'if you need any help.'}
            </Body>
          </section>
        </div>
      </div>
    </div>
  );
};

const SectionPill = styled.span<{
  currentStep: number;
  isCurrentStep: boolean;
}>`
  ${tw`hidden`}
  ${({ isCurrentStep }) =>
    isCurrentStep &&
    tw`block absolute text-xs  font-medium tracking-wide top-0 right-0 m-6 px-2 w-fit border rounded-full`};
  ${({ isCurrentStep, currentStep }) =>
    isCurrentStep &&
    (currentStep === 3
      ? tw`bg-green-100 border-green-500 text-green-500`
      : tw`bg-blue-100 border-blue-500 text-blue-500`)}
`;

const StepWrapper = styled.section<{
  currentStep: number;
  isCurrentStep: boolean;
}>`
  ${tw`relative`}
  ${({ isCurrentStep }) => isCurrentStep && tw`p-6 rounded-lg`};
  ${({ isCurrentStep, currentStep }) =>
    isCurrentStep && (currentStep === 3 ? tw`bg-green-50` : tw`bg-blue-50/50`)};
`;

export default BoosterGetStarted;
