import { RefObject, useEffect } from 'react';

/**
 * Activates a callback function when a mouse-event is fired outside of a element's parent element
 * @param  {RefObject<any>} elementWrapperRef - The ref of the elements parent element
 * @param  {()=>void} defocusHandler - The callback function containing the logic that needs to be executed
 */
const useDefocusHandler = (
  elementWrapperRef: RefObject<any>,
  defocusHandler: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        elementWrapperRef.current &&
        !elementWrapperRef.current.contains(event.target)
      )
        defocusHandler();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [defocusHandler, elementWrapperRef]);
};

export default useDefocusHandler;
