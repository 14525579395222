import React, { useContext } from 'react';
import tw from 'twin.macro';

import FormQuestionsList from 'components/survey/form-questions-list/FormQuestionsList';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import { SurveyContext } from 'context/SurveyContext';
import Button from 'components/button/Button';

export interface PageContentProps {
  activeTab?: AdEditorSideBarTabs;
}

export const PageContent: React.FC<PageContentProps> = ({ activeTab }) => {
  const { createQuestion } = useContext(SurveyContext);

  return (
    <div
      tw="inline-grid grid-cols-none p-6 h-full overflow-y-auto no-scrollbar"
      css={[activeTab ? tw`w-0 md:w-full` : tw`w-screen lg:w-[75vw] xl:w-full`]}
    >
      <div tw="rounded-md bg-gray-100 w-full p-6">
        <div tw="float-right">
          <Button variant="outline" onClick={createQuestion}>
            Add Question
          </Button>
        </div>
        <FormQuestionsList />
      </div>
    </div>
  );
};

export default PageContent;
