import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { AppEvents } from 'types/appEvents';
import { Routes } from 'types/routeTypes';
import { Credentials } from 'types/userTypes';

import 'twin.macro';

import Label from 'components/form/Label';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import AuthHeader from 'components/other/AuthHeader';
import { Footnote, Error } from 'components/typography/Typography';

import useUserContext from 'hooks/context/user-context';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Divider from 'components/divider/Divider';
import SocialIcon from 'components/icons/SocialIcon';

const SingleSignOn = () => {
  const handleSSO = (provider: string) => {
    window.location.href = `${
      import.meta.env.VITE_BASE_URL_API
    }/v4/auth/sso/${provider}`;
  };

  const SSO_PROVIDERS = [
    {
      provider: 'Google',
      icon: (
        <SocialIcon company="google" width="24" height="24" useNaturalColor />
      ),
    },
    {
      provider: 'Facebook',
      icon: (
        <SocialIcon company="facebook" width="24" height="24" useNaturalColor />
      ),
    },
    {
      provider: 'LinkedIn',
      icon: (
        <SocialIcon company="linkedin" width="24" height="24" useNaturalColor />
      ),
    },
    {
      provider: 'Microsoft',
      icon: (
        <SocialIcon
          company="microsoft"
          width="24"
          height="24"
          useNaturalColor
        />
      ),
    },
  ];

  return (
    <div tw="text-center">
      <span tw="text-gray-600 text-sm font-medium">Log in with</span>
      <div tw="grid grid-cols-2 gap-2 mt-2">
        {SSO_PROVIDERS.map(({ icon, provider }) => (
          <Button
            key={provider}
            onClick={() => handleSSO(provider.toLowerCase())}
            variant="secondary"
            stretch
          >
            <div tw="flex flex-row items-center justify-center gap-2">
              {icon}
              {provider}
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { authenticate, error, loading } = useUserContext();

  const { handleSubmit, control, errors } = useForm();

  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const redirectReason = useMemo(() => params.get('redirect'), [params]);

  const onSubmit = (data: Credentials) => {
    if (loading) return;

    authenticate(data).then((authenticated?: boolean) => {
      if (!authenticated) return;

      const referrer = // @ts-ignore
        location?.state?.referrer || localStorage.getItem('referrer');

      if (referrer) navigate(referrer);
      else navigate(Routes.ROOT);
    });
  };

  useEffect(() => {
    // Save the referrer from localStorage
    const referrer = localStorage.getItem('referrer');

    // Makes sure localStorage is cleared to
    // prevent persisting remaining session info
    localStorage.clear();

    // Set referrer if it existed
    if (referrer) localStorage.setItem('referrer', referrer);
  }, []);

  useEffect(() => {
    // Token expiration handling
    if (redirectReason?.localeCompare('unauthorized') !== 0) return;
    dispatchEvent(new CustomEvent(AppEvents.UNAUTHENTICATED));
    // Reset page search params without refreshing
    window.history.replaceState({}, document.title, '/');
  }, [redirectReason]);

  return (
    <div tw="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
      <AuthHeader />

      <div tw="mt-8 mx-auto w-full max-w-md">
        <div tw="bg-white p-10 shadow rounded-lg">
          <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Label htmlFor="username">Email</Label>
              <Controller
                id="username"
                name="username"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Please fill in an email.',
                  },
                }}
                render={({ onChange, value }) => (
                  <Input
                    type="email"
                    id="username"
                    name="username"
                    autoComplete="username"
                    value={value ?? ''}
                    ariaInvalid={!!errors['username']}
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onChange(e)}
                    autoFocus={true}
                  />
                )}
              />
              <Error>{errors['username']?.message}</Error>
            </div>

            <div tw="mt-3">
              <div tw="flex flex-row items-center">
                <Label htmlFor="password">Password</Label>
                <Link to={Routes.FORGOT_PASSWORD} tabIndex={-1}>
                  <Footnote>Forgot?</Footnote>
                </Link>
              </div>
              <Controller
                id="password"
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Please fill in a password.',
                  },
                }}
                render={({ onChange, value }) => (
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    autoComplete="password"
                    value={value}
                    ariaInvalid={!!errors['password']}
                    onChange={(e) => onChange(e)}
                    onBlur={(e: any) => onChange(e)}
                  />
                )}
              />
              <Error>{errors['password']?.message}</Error>
            </div>

            <div tw="mt-6 flex flex-row gap-2">
              <Button
                variant="inverted"
                stretch
                onClick={() => navigate(Routes.REGISTER)}
              >
                Register
              </Button>
              <Button
                type="submit"
                variant="indigo"
                stretch
                disabled={loading}
                loading={loading}
              >
                Log in
              </Button>
            </div>

            <Error isCenter>{error}</Error>
          </form>

          <Divider />
          <SingleSignOn />
        </div>
      </div>
    </div>
  );
};
