import { RestLink } from 'apollo-link-rest/restLink';
import { AxiosError } from 'axios';
import { AdDateRange } from 'types/adTypes';
import { AppEvents } from 'types/appEvents';
import { HttpStatusCodes } from 'types/requestTypes';
import { Routes } from 'types/routeTypes';
import { getLocalAppSession } from './storageHelpers';

export const intakeApiError = (error: AxiosError) => {
  return new Error(
    `${error?.response?.data.message ?? 'Network error'} with status code: ${
      error?.response
        ? HttpStatusCodes[error.response.status]
        : HttpStatusCodes.NETWORKERROR
    }`
  );
};

export const dateFilterToNumeric = (date: AdDateRange) => {
  switch (date) {
    // TODO: Change this value when all time gets added to the API
    case 'Last year':
      return 365;
    case 'Last quarter':
      return 90;
    case 'Last 14 days':
      return 14;
    case 'Last month':
      return 30;
    case 'Last week':
      return 7;
  }
};

export const getMetricDaysFilter = () => {
  const session = getLocalAppSession();
  return session.activeDateFilter
    ? dateFilterToNumeric(session.activeDateFilter)
    : 90;
};

/**
 *
 * @param uri
 * @param options
 * @returns
 */
export const customFetch: RestLink.CustomFetch = async (
  uri: RequestInfo,
  options: RequestInit
) => {
  let res: Response;

  try {
    res = await fetch(uri, options);

    if (res.status === HttpStatusCodes.UNAUTHORIZED) {
      // Redirect unauthorized users if not already on the login page
      if (window.location.pathname !== Routes.ROOT)
        window.location.replace(Routes.LOGIN + '?redirect=unauthorized');
    } else if (res.status === HttpStatusCodes.TWO_FACTOR_PENDING) {
      if (window.location.pathname !== Routes.TWO_FACTOR)
        window.location.replace(Routes.TWO_FACTOR);
    } else {
      if (!res.ok) {
        dispatchEvent(
          new CustomEvent(AppEvents.NETWORK_ERROR, {
            detail: res,
          })
        );
      }
    }
  } catch (err) {
    return Promise.reject({
      name: (err as any)?.message,
      result: null,
    });
  }

  // Return the network response
  return Promise.resolve(res);
};
