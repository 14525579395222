import React from 'react';
import tw, { styled } from 'twin.macro';

type TextType =
  | 'title1'
  | 'title2'
  | 'title3'
  | 'headline'
  | 'body'
  | 'subhead'
  | 'footnote'
  | 'caption'
  | 'link'
  | 'error';

type TextMb = 0 | 1 | 2 | 3 | 4;
type TextMt = 0 | 1 | 2 | 3 | 4;

export interface TypographyProps {
  type?: TextType;
  isBold?: boolean;
  isUppercase?: boolean;
  isLight?: boolean;
  isCenter?: boolean;
  isFlex?: boolean;
  isBlock?: boolean;
  isTruncate?: boolean;
  mb?: TextMb; //margin
  mt?: TextMt;
  children?: React.ReactNode;
}

export const Title1: React.FC<TypographyProps> = ({
  isBold = true,
  isUppercase,
  isLight,
  isCenter,
  isBlock = true,
  isTruncate = false,
  mt,
  mb = 2,
  children,
}) => {
  return (
    <Typography
      type={'title1'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Title2: React.FC<TypographyProps> = ({
  isBold = true,
  isUppercase,
  isLight,
  isCenter,
  isBlock = true,
  isTruncate = false,
  mt,
  mb = 1,
  children,
}) => {
  return (
    <Typography
      type={'title2'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Title3: React.FC<TypographyProps> = ({
  isBold = true,
  isUppercase,
  isLight,
  isCenter,
  isBlock = true,
  isTruncate = false,
  mt,
  mb = 1,
  children,
}) => {
  return (
    <Typography
      type={'title3'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Headline: React.FC<TypographyProps> = ({
  isBold,
  isUppercase,
  isLight,
  isCenter,
  isFlex,
  isBlock = true,
  isTruncate = false,
  mt,
  mb = 1,
  children,
}) => {
  return (
    <Typography
      type={'headline'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isFlex={isFlex}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Body: React.FC<TypographyProps> = ({
  isBold,
  isUppercase,
  isLight,
  isCenter,
  isFlex,
  isBlock = true,
  isTruncate = false,
  mt,
  mb,
  children,
}) => {
  return (
    <Typography
      type={'body'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isFlex={isFlex}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Subhead: React.FC<TypographyProps> = ({
  isBold,
  isUppercase,
  isLight,
  isCenter,
  isFlex,
  isBlock = true,
  isTruncate = false,
  mt,
  mb,
  children,
}) => {
  return (
    <Typography
      type={'subhead'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isFlex={isFlex}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Footnote: React.FC<TypographyProps> = ({
  isBold,
  isUppercase,
  isLight = true,
  isCenter,
  isFlex,
  isBlock = true,
  isTruncate = false,
  mt,
  mb,
  children,
}) => {
  return (
    <Typography
      type={'footnote'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isFlex={isFlex}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Caption: React.FC<TypographyProps> = ({
  isBold,
  isUppercase,
  isLight = true,
  isCenter,
  isFlex,
  isBlock = true,
  isTruncate = false,
  mt,
  mb,
  children,
}) => {
  return (
    <Typography
      type={'caption'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isFlex={isFlex}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Link: React.FC<TypographyProps> = ({
  isBold,
  isUppercase,
  isLight,
  isCenter,
  isFlex,
  isBlock = true,
  isTruncate = false,
  mt,
  mb,
  children,
}) => {
  return (
    <Typography
      type={'link'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isFlex={isFlex}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

export const Error: React.FC<TypographyProps> = ({
  isBold,
  isUppercase,
  isLight,
  isCenter,
  isFlex,
  isBlock = true,
  isTruncate = false,
  mt,
  mb,
  children,
}) => {
  return (
    <Typography
      type={'error'}
      isBold={isBold}
      isUppercase={isUppercase}
      isLight={isLight}
      isCenter={isCenter}
      isFlex={isFlex}
      isBlock={isBlock}
      isTruncate={isTruncate}
      mt={mt}
      mb={mb}
    >
      {children}
    </Typography>
  );
};

const Typography = styled.span<{
  type?: TextType;
  isBold?: boolean;
  isUppercase?: boolean;
  isCenter?: boolean;
  isFlex?: boolean;
  isBlock?: boolean;
  isTruncate?: boolean;
  mb?: TextMb;
  mt?: TextMt;
  isLight?: boolean;
}>`
  ${tw`
    font-sans text-[#161E2E] block
  `}
  ${({ type }) => type === 'title1' && tw`text-2xl`}
  ${({ type }) => type === 'title2' && tw`text-xl`}
  ${({ type }) => type === 'title3' && tw`text-lg`} 
  ${({ type }) => type === 'headline' && tw`text-base`}
  ${({ type }) => type === 'body' && tw`text-base`} 
  ${({ type }) => type === 'subhead' && tw`text-sm`}
  ${({ type }) => type === 'footnote' && tw`text-xs`}
  ${({ type }) => type === 'caption' && tw`text-[11px]`}
  ${({ type }) =>
    type === 'link' &&
    tw`text-base hover:text-gray-400 ease-linear duration-300`}
    ${({ type }) =>
    type === 'error' && tw`mt-2 text-sm font-medium leading-5 text-red-400`}

  ${({ isBold }) => isBold && tw`font-semibold`}
  ${({ isUppercase }) => isUppercase && tw`uppercase`}
  ${({ isLight }) => isLight && tw`text-[#7b8896]`}
  ${({ isCenter }) => isCenter && tw`text-center`}
  ${({ isFlex }) => isFlex && tw`flex`}
  ${({ isBlock }) => !isBlock && tw`inline-block`}
  ${({ isTruncate }) => isTruncate && tw`truncate`}

  ${({ mt }) => mt === 0 && tw`mt-0`}
  ${({ mt }) => mt === 1 && tw`mt-2`}
  ${({ mt }) => mt === 2 && tw`mt-4`}
  ${({ mt }) => mt === 3 && tw`mt-6`}
  ${({ mt }) => mt === 4 && tw`mt-10`}

  ${({ mb }) => mb === 0 && tw`mb-0`}
  ${({ mb }) => mb === 1 && tw`mb-2`}
  ${({ mb }) => mb === 2 && tw`mb-4`}
  ${({ mb }) => mb === 3 && tw`mb-6`}
  ${({ mb }) => mb === 4 && tw`mb-10`}
`;
