import 'twin.macro';
import React from 'react';

import { CustomerResource } from 'types/apiTypes';
import CustomerResourceItem from './PreviewListItem/ResourceItem';

export interface CustomerResourceListProps {
  resources: CustomerResource[];
  allowDelete?: boolean;
  allowDownload?: boolean;
  onDelete?: (resourceIdx?: number) => void;
}

const CustomerResourceList: React.FC<CustomerResourceListProps> = ({
  resources = [],
  allowDelete = false,
  allowDownload = false,
  onDelete,
}) => {
  const itemDeleteHandler = (resourceIdx?: number) => {
    if (!allowDelete) return;
    onDelete?.(resourceIdx);
  };

  return (
    <ul tw="w-full flex flex-col mt-2">
      <li>
        {resources.map((resource, resourceIdx) => (
          <CustomerResourceItem
            key={resource.id}
            resourceIdx={resourceIdx}
            resource={resource}
            allowDelete={allowDelete}
            allowDownload={allowDownload}
            onDelete={itemDeleteHandler}
          />
        ))}
      </li>
    </ul>
  );
};

export default CustomerResourceList;
