import GlobalStyles from './styles/GlobalStyles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { Integrations } from '@sentry/tracing';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { ErrorPage } from './views';
import client from './setupApollo';
import { AppProvider } from './context/NotificationContext';
import { ModalsProvider } from './context/ModalsContext';
import { NavigationProvider } from './context/NavigationContext';
import { hideIntercom, initIntercom } from './utils/intercomHelper';
import AppRouter from 'routing/AppRouter';
import { GetStartedProvider } from 'context/GetStartedContext';
import LogRocket from 'logrocket';
import { getLocalAppSession } from 'utils/storageHelpers';
import './styles/globals.css';

import '@symplbe/sympl-components/dist/style.css';
import { UserProvider } from 'context/UserContext';

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: 'https://f72bf86b3fd54184b6cb0302df45a950@o104170.ingest.sentry.io/5607062',
    integrations: [new Integrations.BrowserTracing()],
    enableTracing: true,
  });
}

if (import.meta.env.PROD) {
  initIntercom();
  if ((window as Window).innerWidth < 640) hideIntercom();

  const email = getLocalAppSession()?.email;
  if (email) LogRocket.identify(email, { email });
}

dayjs.extend(relativeTime);

function App() {
  return (
    <ErrorBoundary FallbackComponent={() => <ErrorPage />}>
      <AppProvider>
        <ApolloProvider client={client}>
          <UserProvider>
            <GlobalStyles />
            <BrowserRouter>
              <NavigationProvider>
                <GetStartedProvider>
                  <ModalsProvider>
                    <AppRouter />
                  </ModalsProvider>
                </GetStartedProvider>
              </NavigationProvider>
            </BrowserRouter>
          </UserProvider>
        </ApolloProvider>
      </AppProvider>
    </ErrorBoundary>
  );
}

const root = document.getElementById('root');

if (!root) {
  throw new Error('No root element found');
}
const reactRoot = createRoot(root);
reactRoot.render(<App />);
