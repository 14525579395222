import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import CopyWrapper from 'react-copy-to-clipboard';
import { CopySimple, ArrowSquareOut } from '@phosphor-icons/react';

export interface CopyToClipboardProps {
  url: string;
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ url }) => {
  const [isCopied, setIsCopied] = useState(false);

  const urlClickHandler = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <Wrapper tabIndex={-1}>
      <div tw="sm:contents hidden">
        <div tw="ml-2">
          <CopySimple tw="text-gray-200" size="20" />
        </div>
        <div tw="flex justify-between items-center">
          <ClipboardLink
            href={url}
            tabIndex={0}
            target="_blank"
            rel="noreferrer"
          >
            {url.split('?')[0]}
          </ClipboardLink>
          <CopyWrapper tw="cursor-pointer" text={url} onCopy={urlClickHandler}>
            <CopyButton>{!isCopied ? 'Copy' : 'Copied!'}</CopyButton>
          </CopyWrapper>
        </div>
      </div>
      <a
        href={url}
        tabIndex={0}
        target="_blank"
        rel="noreferrer"
        tw="sm:hidden h-full p-2 text-gray-600"
      >
        <ArrowSquareOut tw="mt-auto" size="20" />
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div(() => [
  tw`
    flex items-center rounded-md ring-2 ring-gray-200
    text-center truncate text-gray-700 w-fit float-right
  `,
]);

const ClipboardLink = styled.a(() => [
  tw`
    font-medium text-sm truncate mx-3 w-full max-w-[15vw] xl:max-w-[30vw]
  `,
]);

const CopyButton = styled.span(() => [
  tw`
    h-full ml-3 bg-gray-100 px-3 py-2 cursor-pointer text-sm font-medium text-gray-600 rounded-md m-1
    transition-all
    hover:(bg-indigo-600 shadow text-white)
  `,
]);

export default CopyToClipboard;
