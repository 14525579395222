import React from 'react';
import tw, { styled } from 'twin.macro';

export interface SectionHeadingProps {
  title?: string;
  description?: string;
  structural?: boolean;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  title,
  description,
  structural,
}) => (
  <Wrapper isStructural={structural ?? false}>
    <Heading>{title}</Heading>
    {description && (
      <p tw="mt-2 max-w-4xl text-sm text-gray-500">{description}</p>
    )}
  </Wrapper>
);

const Wrapper = styled.div<{
  isStructural: boolean;
}>`
  ${tw`mb-2`}
  ${({ isStructural }) => isStructural && tw`hidden`}
`;

const Heading = styled.h3(tw`font-medium text-gray-700 text-xl`);

export default SectionHeading;
