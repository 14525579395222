import React from 'react';
import tw, { styled } from 'twin.macro';

import { Controller, useFormContext } from 'react-hook-form';

const MailSubject: React.FC<{
  id: string;
  defaultValue: string;
  inside?: boolean;
}> = ({ id, defaultValue, inside = false }) => {
  const { control } = useFormContext();

  return (
    <Wrapper inside={inside} translate="no">
      <Label htmlFor={id} inside={inside}>
        Subject
      </Label>
      <Controller
        id={id}
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: true }}
        render={({ value, onChange }) => (
          <input
            type="text"
            tw="ml-3 grow outline-none focus:(outline-none border-none) text-gray-700 text-sm"
            value={value}
            onChange={onChange}
            autoFocus
          />
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ inside: boolean }>`
  ${tw`flex`}
  ${({ inside }) =>
    inside
      ? tw`border-0 shadow-none w-full p-0 border-none focus:(border-0)`
      : tw`mb-2 rounded-md border border-t-2 border-gray-50 cursor-text p-3 shadow focus:(border-2 border-indigo-600)`}
`;

const Label = styled.label<{ inside: boolean }>`
  ${({ inside }) =>
    inside
      ? tw`text-gray-700 text-sm font-medium border-r-2 box-content overflow-x-auto no-scrollbar pl-1 pr-3`
      : tw`text-gray-700 text-xs p-1 px-2 rounded-md bg-gray-100 font-medium`}
`;

export default MailSubject;
