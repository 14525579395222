import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import tw, { styled } from 'twin.macro';
import { CaretUpDown } from '@phosphor-icons/react';
import ContextMenu, {
  Section,
} from 'components/dropdown/infinite-api-dropdown/ContextMenu';
import Input from 'components/form/input/Input';
import { DocumentNode } from 'graphql';
import useMetaQuery from 'hooks/metaQuery';
import useDebouncedCallback from '../../../hooks/debounceCallback';

interface CustomerQueryProps {
  pageSize: number;
  currentPage: number;
  filterName: string;
}
export interface IContextMenuItems {
  data: Section[];
  isLoading: boolean;
}
interface IInfiniteApiDropdown {
  API: DocumentNode; //graphql
  onClick?: (item: unknown) => void;
  defaultValue: string;
  labels: string[]; // => label key(s), ["id","name"] => printed 10 - test
  dataKey: string;
  pageSize?: number; // must be minimum 25
  variables?: object;
  customCSS?: (item: unknown) => CSSProperties | undefined;
  beforeRender?: (item: unknown) => React.ReactNode;
}
const InfiniteApiDropDown: React.FC<IInfiniteApiDropdown> = ({
  API,
  onClick,
  defaultValue,
  labels,
  dataKey,
  pageSize = 25,
  variables,
  customCSS,
  beforeRender,
}: IInfiniteApiDropdown) => {
  const [open, setOpen] = useState(false);
  const filterRef = useRef('');
  const [pageNumber, setPageNumber] = useState(1);
  const [items, setItems] = useState<any[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const { refetch: refetchItems } = useMetaQuery<any, CustomerQueryProps>(API, {
    fetchPolicy: 'cache-first',
    skip: true,
    notifyOnNetworkStatusChange: true,
    variables: {
      ...variables,
      pageSize: pageSize,
      currentPage: pageNumber,
      filterName: filterRef.current,
    },
  });

  const listRef = useRef(null);
  const onItemClick = (item: any) => {
    onClick?.(item);
    setSelectedItem(
      labels
        .map((v, i) =>
          labels.length === 1 || i === labels.length - 1
            ? item[v]
            : `${item[v]} - `
        )
        .join(' ')
    );
    setOpen(false);
  };

  const contextMenuItems: IContextMenuItems = useMemo(() => {
    const filterSection: Section = {
      items: [
        {
          element: (
            <Input
              type="text"
              autoFocus
              onChange={(e) => onInputChange(e.target.value)}
              defaultValue={filterRef.current}
            />
          ),
        },
      ],
    };

    const accountsSection: Section = {
      items:
        items.length !== 0
          ? items.map((data: any) => {
              return {
                element: (
                  <>
                    {beforeRender?.(data)}
                    {labels.map((v, i) =>
                      labels.length === 1 || i === labels.length - 1
                        ? data[v]
                        : `${data[v]} - `
                    )}
                  </>
                ),
                onClick: () => {
                  onItemClick(data);
                },
              };
            })
          : new Array(hasNextPage ? 10 : 0)
              .fill(0)
              .map(() => ({ element: <>Loading...</> })),

      data: items,
    };
    return {
      data: [filterSection, accountsSection],
      isLoading: items.length < 1,
    };
  }, [items, hasNextPage]);

  useEffect(() => {
    if (pageNumber === 1 && open) {
      (async () => await loadData(filterRef.current))();
    }
  }, [filterRef.current]);

  const loadData = useDebouncedCallback(async (filterVal: string = '') => {
    if (!open) return;
    if (
      (items.length < (pageNumber - 1) * pageSize && pageNumber !== 1) ||
      (pageNumber === 1 &&
        items.length > 0 &&
        items.length < pageNumber * pageSize)
    ) {
      setHasNextPage(false);
      return;
    }
    if (items.length > 0 && pageNumber === 1) return;
    await refetchItems({
      ...variables,
      pageSize: pageSize,
      currentPage: pageNumber,
      filterName: filterRef.current,
    })
      .then((val) => {
        //checks if request filter and current filter (input) are the same
        if (filterVal !== filterRef.current) return;
        setLoading(true);
        if (open && pageNumber === 1) setItems(val.data[dataKey]);
        else setItems([...items, ...(val.data[dataKey] ?? [])]);

        setPageNumber(pageNumber + 1);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  const onInputChange = (value: string) => {
    setItems([]);
    filterRef.current = value as string;
    setPageNumber(1);
    setHasNextPage(true);
  };

  return (
    <Wrapper ref={listRef} onClick={() => !open && setOpen(true)}>
      <div tw="text-sm w-full text-center lg:(text-left pl-2)">
        {selectedItem}
      </div>
      <CaretUpDown tw="text-gray-400" />
      <ContextMenu
        open={open}
        sections={contextMenuItems}
        hasNextPage={hasNextPage}
        loadNextPage={async () => await loadData(filterRef.current)}
        onClickOutside={() => setOpen(false)}
        isPageLoading={loading}
        customCSS={customCSS}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${tw`flex items-center rounded-md bg-gray-50 p-2 ring-1 border-none ring-black/5 relative hover:(bg-gray-100 text-gray-600 cursor-pointer)`}
`;

export default InfiniteApiDropDown;
