import { gql } from '@apollo/client';

export const GET_SESSION_FLAGS = gql`
  query getSessionFlags {
    flags @rest(type: "SessionFlags", path: "/v4/auth/user/session") {
      user {
        job_position
        is_sympl
      }
    }
  }
`;
