import { Country } from 'types/geo/types';

export const COUNTRIES: Country[] = [
  {
    key: 'AF',
    label: 'Afghanistan',
    flag: '🇦🇫',
    phonePrefix: '+93',
    mask: 'xxx xxx xxxx',
  },
  {
    key: 'AX',
    label: 'Åland Islands',
    flag: '🇦🇽',
    phonePrefix: '+358',
    mask: '18 xx xx xx',
  },
  {
    key: 'AL',
    label: 'Albania',
    flag: '🇦🇱',
    phonePrefix: '+355',
    mask: '4 xxx xxxx',
  },
  {
    key: 'DZ',
    label: 'Algeria',
    flag: '🇩🇿',
    phonePrefix: '+213',
    mask: 'xx xx xx xx',
  },
  {
    key: 'AS',
    label: 'American Samoa',
    flag: '🇦🇸',
    phonePrefix: '+1-684',
    mask: 'xxx-xxxx',
  },
  {
    key: 'AD',
    label: 'Andorra',
    flag: '🇦🇩',
    phonePrefix: '+376',
    mask: 'xx xx xx',
  },
  {
    key: 'AO',
    label: 'Angola',
    flag: '🇦🇴',
    phonePrefix: '+244',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'AI',
    label: 'Anguilla',
    flag: '🇦🇮',
    phonePrefix: '+1-264',
    mask: 'xxx-xxxx',
  },
  {
    key: 'AQ',
    label: 'Antarctica',
    flag: '🇦🇶',
    phonePrefix: '+672',
    mask: 'xxx xxxx',
  },
  {
    key: 'AG',
    label: 'Antigua & Barbuda',
    flag: '🇦🇬',
    phonePrefix: '+1-268',
    mask: 'xxx-xxxx',
  },
  {
    key: 'AR',
    label: 'Argentina',
    flag: '🇦🇷',
    phonePrefix: '+54',
    mask: '(684) xxx-xxxx',
  },
  {
    key: 'AM',
    label: 'Armenia',
    flag: '🇦🇲',
    phonePrefix: '+374',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'AW',
    label: 'Aruba',
    flag: '🇦🇼',
    phonePrefix: '+297',
    mask: 'xxx xxxx',
  },
  {
    key: 'AU',
    label: 'Australia',
    flag: '🇦🇺',
    phonePrefix: '+61',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'AT',
    label: 'Austria',
    flag: '🇦🇹',
    region: 'EU',
    phonePrefix: '+43',
    mask: 'xxx xxx xx',
  },
  {
    key: 'AZ',
    label: 'Azerbaijan',
    flag: '🇦🇿',
    phonePrefix: '+994',
    mask: 'xx xxxxxxx',
  },
  {
    key: 'BS',
    label: 'Bahamas',
    flag: '🇧🇸',
    phonePrefix: '+1-242',
    mask: 'xxx-xxxx',
  },
  {
    key: 'BH',
    label: 'Bahrain',
    flag: '🇧🇭',
    phonePrefix: '+973',
    mask: 'xxxxxxxx',
  },
  {
    key: 'BD',
    label: 'Bangladesh',
    flag: '🇧🇩',
    phonePrefix: '+880',
    mask: 'x-xxxxxxxx',
  },
  {
    key: 'BB',
    label: 'Barbados',
    flag: '🇧🇧',
    phonePrefix: '+1-246',
    mask: 'xxx-xxxx',
  },
  {
    key: 'BY',
    label: 'Belarus',
    flag: '🇧🇾',
    phonePrefix: '+375',
    mask: 'xx xxx xx xx',
  },
  {
    key: 'BE',
    label: 'Belgium',
    flag: '🇧🇪',
    region: 'EU',
    phonePrefix: '+32',
    mask: 'xxx xx xx xx',
  },
  {
    key: 'BZ',
    label: 'Belize',
    flag: '🇧🇿',
    phonePrefix: '+501',
    mask: 'xxx xxxx',
  },
  {
    key: 'BJ',
    label: 'Benin',
    flag: '🇧🇯',
    phonePrefix: '+229',
    mask: 'xx.xx.xx.xx',
  },
  {
    key: 'BM',
    label: 'Bermuda',
    flag: '🇧🇲',
    phonePrefix: '+1-441',
    mask: 'xxx-xxxx',
  },
  {
    key: 'BT',
    label: 'Bhutan',
    flag: '🇧🇹',
    phonePrefix: '+975',
    mask: 'x xxx xxx',
  },
  {
    key: 'BO',
    label: 'Bolivia',
    flag: '🇧🇴',
    phonePrefix: '+591',
    mask: 'x xxxxxxx',
  },
  {
    key: 'BA',
    label: 'Bosnia & Herzegovina',
    flag: '🇧🇦',
    phonePrefix: '+387',
    mask: 'xx xxx xxx',
  },
  {
    key: 'BW',
    label: 'Botswana',
    flag: '🇧🇼',
    phonePrefix: '+267',
    mask: 'xxx xxxx',
  },
  {
    key: 'BV',
    label: 'Bouvet Island',
    flag: '🇧🇻',
    phonePrefix: '+47',
    mask: 'xx xx xx xx',
  },
  {
    key: 'BR',
    label: 'Brazil',
    flag: '🇧🇷',
    phonePrefix: '+55',
    mask: 'xx xxxx xxxx',
  },
  {
    key: 'IO',
    label: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    phonePrefix: '+246',
    mask: 'xxx xxxx',
  },
  {
    key: 'VG',
    label: 'British Virgin Islands',
    flag: '🇻🇬',
    phonePrefix: '+1-284',
    mask: 'xxx-xxxx',
  },
  {
    key: 'BN',
    label: 'Brunei',
    flag: '🇧🇳',
    phonePrefix: '+673',
    mask: 'xxx xxxx',
  },
  {
    key: 'BG',
    label: 'Bulgaria',
    flag: '🇧🇬',
    region: 'EU',
    phonePrefix: '+359',
    mask: 'x xxx xx xx',
  },
  {
    key: 'BF',
    label: 'Burkina Faso',
    flag: '🇧🇫',
    phonePrefix: '+226',
    mask: 'xx xx xx xx',
  },
  {
    key: 'BI',
    label: 'Burundi',
    flag: '🇧🇮',
    phonePrefix: '+257',
    mask: 'xx xxx xxx',
  },
  {
    key: 'KH',
    label: 'Cambodia',
    flag: '🇰🇭',
    phonePrefix: '+855',
    mask: 'xx xxx xxx',
  },
  {
    key: 'CM',
    label: 'Cameroon',
    flag: '🇨🇲',
    phonePrefix: '+237',
    mask: 'xxx xxxxxx',
  },
  {
    key: 'CA',
    label: 'Canada',
    flag: '🇨🇦',
    phonePrefix: '+1',
    mask: '(xxx) xxx-xxxx',
  },
  {
    key: 'CV',
    label: 'Cape Verde',
    flag: '🇨🇻',
    phonePrefix: '+238',
    mask: 'xxx xxxx',
  },
  {
    key: 'BQ',
    label: 'Caribbean Netherlands',
    flag: '🇧🇶',
    phonePrefix: '+599',
    mask: '9 xxx xxxx',
  },
  {
    key: 'KY',
    label: 'Cayman Islands',
    flag: '🇰🇾',
    phonePrefix: '+1-345',
    mask: 'xxx-xxxx',
  },
  {
    key: 'CF',
    label: 'Central African Republic',
    flag: '🇨🇫',
    phonePrefix: '+236',
    mask: 'xxxx xxxx',
  },
  {
    key: 'TD',
    label: 'Chad',
    flag: '🇹🇩',
    phonePrefix: '+235',
    mask: 'xx xx xx xx',
  },
  {
    key: 'CL',
    label: 'Chile',
    flag: '🇨🇱',
    phonePrefix: '+56',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'CN',
    label: 'China',
    flag: '🇨🇳',
    phonePrefix: '+86',
    mask: 'xxx xxx xxxx',
  },
  {
    key: 'CX',
    label: 'Christmas Island',
    flag: '🇨🇽',
    phonePrefix: '+61',
    mask: '8 9164 xxxx',
  },
  {
    key: 'CC',
    label: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    phonePrefix: '+61',
    mask: '8 9162 xxxx',
  },
  {
    key: 'CO',
    label: 'Colombia',
    flag: '🇨🇴',
    phonePrefix: '+57',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'KM',
    label: 'Comoros',
    flag: '🇰🇲',
    phonePrefix: '+269',
    mask: 'xxx xxxx',
  },
  {
    key: 'CG',
    label: 'Congo - Brazzaville',
    flag: '🇨🇬',
    phonePrefix: '+242',
    mask: 'xx xx xxxxx',
  },
  {
    key: 'CD',
    label: 'Congo - Kinshasa',
    flag: '🇨🇩',
    phonePrefix: '+243',
    mask: 'xx xx xxxxx',
  },
  {
    key: 'CK',
    label: 'Cook Islands',
    flag: '🇨🇰',
    phonePrefix: '+682',
    mask: 'x xxxx',
  },
  {
    key: 'CR',
    label: 'Costa Rica',
    flag: '🇨🇷',
    phonePrefix: '+506',
    mask: 'xxxx-xxxx',
  },
  {
    key: 'CI',
    label: 'Côte d’Ivoire',
    flag: '🇨🇮',
    phonePrefix: '+225',
    mask: 'xxxx xxxx',
  },
  {
    key: 'HR',
    label: 'Croatia',
    flag: '🇭🇷',
    region: 'EU',
    phonePrefix: '+385',
    mask: 'xx.xxxxxxx',
  },
  {
    key: 'CU',
    label: 'Cuba',
    flag: '🇨🇺',
    phonePrefix: '+53',
    mask: 'x xxxxxxx',
  },
  {
    key: 'CW',
    label: 'Curaçao',
    flag: '🇨🇼',
    phonePrefix: '+599',
    mask: '9 xxx xxxx',
  },
  {
    key: 'CY',
    label: 'Cyprus',
    flag: '🇨🇾',
    region: 'EU',
    phonePrefix: '+357',
    mask: 'xx xx xx xx',
  },
  {
    key: 'CZ',
    label: 'Czechia',
    flag: '🇨🇿',
    region: 'EU',
    phonePrefix: '+420',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'DK',
    label: 'Denmark',
    flag: '🇩🇰',
    region: 'EU',
    phonePrefix: '+45',
    mask: 'xx xx xx xx',
  },
  {
    key: 'DJ',
    label: 'Djibouti',
    flag: '🇩🇯',
    phonePrefix: '+253',
    mask: 'xx xx xx xx',
  },
  {
    key: 'DM',
    label: 'Dominica',
    flag: '🇩🇲',
    phonePrefix: '+1-767',
    mask: 'xxx-xxxx',
  },
  {
    key: 'DO',
    label: 'Dominican Republic',
    flag: '🇩🇴',
    phonePrefix: '+1',
    mask: '(xxx) xxx-xxxx',
  },
  {
    key: 'EC',
    label: 'Ecuador',
    flag: '🇪🇨',
    phonePrefix: '+593',
    mask: 'x xxxx xxx',
  },
  {
    key: 'EG',
    label: 'Egypt',
    flag: '🇪🇬',
    phonePrefix: '+20',
    mask: 'xx xxx xxx',
  },
  {
    key: 'SV',
    label: 'El Salvador',
    flag: '🇸🇻',
    phonePrefix: '+503',
    mask: 'xxxx xxxx',
  },
  {
    key: 'GQ',
    label: 'Equatorial Guinea',
    flag: '🇬🇶',
    phonePrefix: '+240',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'ER',
    label: 'Eritrea',
    flag: '🇪🇷',
    phonePrefix: '+291',
    mask: 'x xxx xxx',
  },
  {
    key: 'EE',
    label: 'Estonia',
    flag: '🇪🇪',
    region: 'EU',
    phonePrefix: '+372',
    mask: 'xxx xxxx',
  },
  {
    key: 'SZ',
    label: 'Eswatini',
    flag: '🇸🇿',
    phonePrefix: '+268',
    mask: 'xx xx xxxx',
  },
  {
    key: 'ET',
    label: 'Ethiopia',
    flag: '🇪🇹',
    phonePrefix: '+251',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'FK',
    label: 'Falkland Islands',
    flag: '🇫🇰',
    phonePrefix: '+500',
    mask: 'xx xxx',
  },
  {
    key: 'FO',
    label: 'Faroe Islands',
    flag: '🇫🇴',
    phonePrefix: '+298',
    mask: 'xxx xxx',
  },
  {
    key: 'FJ',
    label: 'Fiji',
    flag: '🇫🇯',
    phonePrefix: '+679',
    mask: 'xxx xxxx',
  },
  {
    key: 'FI',
    label: 'Finland',
    flag: '🇫🇮',
    region: 'EU',
    phonePrefix: '+358',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'FR',
    label: 'France',
    flag: '🇫🇷',
    region: 'EU',
    phonePrefix: '+33',
    mask: 'x xx xx xx xx',
  },
  {
    key: 'GF',
    label: 'French Guiana',
    flag: '🇬🇫',
    phonePrefix: '+594',
    mask: 'x xx xx xx xx',
  },
  {
    key: 'PF',
    label: 'French Polynesia',
    flag: '🇵🇫',
    phonePrefix: '+689',
    mask: 'xx xx xx',
  },
  {
    key: 'TF',
    label: 'French Southern Territories',
    flag: '🇹🇫',
    phonePrefix: '+262',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'GA',
    label: 'Gabon',
    flag: '🇬🇦',
    phonePrefix: '+241',
    mask: 'x xx xx xx',
  },
  {
    key: 'GM',
    label: 'Gambia',
    flag: '🇬🇲',
    phonePrefix: '+220',
    mask: 'xxx xxxx',
  },
  {
    key: 'GE',
    label: 'Georgia',
    flag: '🇬🇪',
    phonePrefix: '+995',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'DE',
    label: 'Germany',
    flag: '🇩🇪',
    region: 'EU',
    phonePrefix: '+49',
    mask: 'xx / xxxxxxxxx',
  },
  {
    key: 'GH',
    label: 'Ghana',
    flag: '🇬🇭',
    phonePrefix: '+233',
    mask: 'xx xxxxxxx',
  },
  {
    key: 'GI',
    label: 'Gibraltar',
    flag: '🇬🇮',
    phonePrefix: '+350',
    mask: 'xxx xxxxx',
  },
  {
    key: 'GR',
    label: 'Greece',
    flag: '🇬🇷',
    region: 'EU',
    phonePrefix: '+30',
    mask: 'xxx xxx xxxx',
  },
  {
    key: 'GL',
    label: 'Greenland',
    flag: '🇬🇱',
    phonePrefix: '+299',
    mask: 'xx xx xx',
  },
  {
    key: 'GD',
    label: 'Grenada',
    flag: '🇬🇩',
    phonePrefix: '+1-473',
    mask: 'xxx xxxx',
  },
  {
    key: 'GP',
    label: 'Guadeloupe',
    flag: '🇬🇵',
    phonePrefix: '+590',
    mask: 'xxx xx xx xx',
  },
  {
    key: 'GU',
    label: 'Guam',
    flag: '🇬🇺',
    phonePrefix: '+1-671',
    mask: 'xxx xxxx',
  },
  {
    key: 'GT',
    label: 'Guatemala',
    flag: '🇬🇹',
    phonePrefix: '+502',
    mask: 'xxxx xxxx',
  },
  {
    key: 'GG',
    label: 'Guernsey',
    flag: '🇬🇬',
    phonePrefix: '+44',
    mask: 'xxxx xxxxxx',
  },
  {
    key: 'GN',
    label: 'Guinea',
    flag: '🇬🇳',
    phonePrefix: '+224',
    mask: 'xxxx xxxx',
  },
  {
    key: 'GW',
    label: 'Guinea-Bissau',
    flag: '🇬🇼',
    phonePrefix: '+245',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'GY',
    label: 'Guyana',
    flag: '🇬🇾',
    phonePrefix: '+592',
    mask: 'xxx xxxx',
  },
  {
    key: 'HT',
    label: 'Haiti',
    flag: '🇭🇹',
    phonePrefix: '+509',
    mask: 'xx xx xxxx',
  },
  {
    key: 'HM',
    label: 'Heard & McDonald Islands',
    flag: '🇭🇲',
    phonePrefix: '+672',
    mask: 'xxx xxxx',
  },
  {
    key: 'HN',
    label: 'Honduras',
    flag: '🇭🇳',
    phonePrefix: '+504',
    mask: 'xxxx xxxx',
  },
  {
    key: 'HK',
    label: 'Hong Kong SAR China',
    flag: '🇭🇰',
    phonePrefix: '+852',
    mask: 'xxxx xxxx',
  },
  {
    key: 'HU',
    label: 'Hungary',
    flag: '🇭🇺',
    region: 'EU',
    phonePrefix: '+36',
    mask: 'x xxx xxxx',
  },
  {
    key: 'IS',
    label: 'Iceland',
    flag: '🇮🇸',
    phonePrefix: '+354',
    mask: 'xxx xxxx',
  },
  {
    key: 'IN',
    label: 'India',
    flag: '🇮🇳',
    phonePrefix: '+91',
    mask: 'xx xxxxxxxx',
  },
  {
    key: 'code',
    label: 'Indonesia',
    flag: '🇮🇩',
    phonePrefix: '+62',
    mask: 'xxx xxx xxxx',
  },
  {
    key: 'IR',
    label: 'Iran',
    flag: '🇮🇷',
    phonePrefix: '+98',
    mask: ' xxx xxx xxxx',
  },
  {
    key: 'IQ',
    label: 'Iraq',
    flag: '🇮🇶',
    phonePrefix: '+964',
    mask: ' xxx xxx xxxx',
  },
  {
    key: 'IE',
    label: 'Ireland',
    flag: '🇮🇪',
    region: 'EU',
    phonePrefix: '+353',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'XI',
    label: 'Northern Ireland',
    flag: '🇪🇺',
    region: 'EU',
    phonePrefix: '+44',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'IM',
    label: 'Isle of Man',
    flag: '🇮🇲',
    phonePrefix: '+44',
    mask: 'xxxx xxxxxx',
  },
  {
    key: 'IL',
    label: 'Israel',
    flag: '🇮🇱',
    phonePrefix: '+972',
    mask: 'x xxx xxxx',
  },
  {
    key: 'IT',
    label: 'Italy',
    flag: '🇮🇹',
    region: 'EU',
    phonePrefix: '+39',
    mask: 'xxx xxxxx',
  },
  {
    key: 'JM',
    label: 'Jamaica',
    flag: '🇯🇲',
    phonePrefix: '+1',
    mask: '(xxx) xxx-xxxx',
  },
  {
    key: 'JP',
    label: 'Japan',
    flag: '🇯🇵',
    phonePrefix: '+81',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'JE',
    label: 'Jersey',
    flag: '🇯🇪',
    phonePrefix: '+44',
    mask: 'xxxx xxxxxx',
  },
  {
    key: 'JO',
    label: 'Jordan',
    flag: '🇯🇴',
    phonePrefix: '+962',
    mask: 'x xxx xxxx',
  },
  {
    key: 'KZ',
    label: 'Kazakhstan',
    flag: '🇰🇿',
    phonePrefix: '+7',
    mask: 'xxx xxx xx xx',
  },
  {
    key: 'KE',
    label: 'Kenya',
    flag: '🇰🇪',
    phonePrefix: '+254',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'KI',
    label: 'Kiribati',
    flag: '🇰🇮',
    phonePrefix: '+686',
    mask: 'xxxxx',
  },
  {
    key: 'KW',
    label: 'Kuwait',
    flag: '🇰🇼',
    phonePrefix: '+965',
    mask: 'xxxx xxxx',
  },
  {
    key: 'KG',
    label: 'Kyrgyzstan',
    flag: '🇰🇬',
    phonePrefix: '+996',
    mask: 'xxx xxxxxx',
  },
  {
    key: 'LA',
    label: 'Laos',
    flag: '🇱🇦',
    phonePrefix: '+856',
    mask: 'xx xx xxxx',
  },
  {
    key: 'LV',
    label: 'Latvia',
    flag: '🇱🇻',
    region: 'EU',
    phonePrefix: '+371',
    mask: 'xxxx xxxx',
  },
  {
    key: 'LB',
    label: 'Lebanon',
    flag: '🇱🇧',
    phonePrefix: '+961',
    mask: 'xxx xxxx',
  },
  {
    key: 'LS',
    label: 'Lesotho',
    flag: '🇱🇸',
    phonePrefix: '+266',
    mask: 'xxxx xxxx',
  },
  {
    key: 'LR',
    label: 'Liberia',
    flag: '🇱🇷',
    phonePrefix: '+231',
    mask: 'xx xxx xxx',
  },
  {
    key: 'LY',
    label: 'Libya',
    flag: '🇱🇾',
    phonePrefix: '+218',
    mask: 'xxx xxx xxxx',
  },
  {
    key: 'LI',
    label: 'Liechtenstein',
    flag: '🇱🇮',
    region: 'EU',
    phonePrefix: '+423',
    mask: 'xxx xx xx',
  },
  {
    key: 'LT',
    label: 'Lithuania',
    flag: '🇱🇹',
    region: 'EU',
    phonePrefix: '+370',
    mask: 'xx xx xxxx',
  },
  {
    key: 'LU',
    label: 'Luxembourg',
    flag: '🇱🇺',
    region: 'EU',
    phonePrefix: '+352',
    mask: 'xxx xxxxx',
  },
  {
    key: 'MO',
    label: 'Macao SAR China',
    flag: '🇲🇴',
    phonePrefix: '+853',
    mask: 'xxxx xxxx',
  },
  {
    key: 'MG',
    label: 'Madagascar',
    flag: '🇲🇬',
    phonePrefix: '+261',
    mask: 'xx x xxxxxx',
  },
  {
    key: 'MW',
    label: 'Malawi',
    flag: '🇲🇼',
    phonePrefix: '+265',
    mask: 'x xxx xxx',
  },
  {
    key: 'MY',
    label: 'Malaysia',
    flag: '🇲🇾',
    phonePrefix: '+60',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'MV',
    label: 'Maldives',
    flag: '🇲🇻',
    phonePrefix: '+960',
    mask: 'xxx xxxx',
  },
  {
    key: 'ML',
    label: 'Mali',
    flag: '🇲🇱',
    phonePrefix: '+223',
    mask: 'xxxx xxxx',
  },
  {
    key: 'MT',
    label: 'Malta',
    flag: '🇲🇹',
    region: 'EU',
    phonePrefix: '+356',
    mask: 'xx xxx xxx',
  },
  {
    key: 'MH',
    label: 'Marshall Islands',
    flag: '🇲🇭',
    phonePrefix: '+692',
    mask: '(692) xxx-xxxx',
  },
  {
    key: 'MQ',
    label: 'Martinique',
    flag: '🇲🇶',
    phonePrefix: '+596',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'MR',
    label: 'Mauritania',
    flag: '🇲🇷',
    phonePrefix: '+222',
    mask: 'xxxx xxxx',
  },
  {
    key: 'MU',
    label: 'Mauritius',
    flag: '🇲🇺',
    phonePrefix: '+230',
    mask: 'x xxx xxxx',
  },
  {
    key: 'YT',
    label: 'Mayotte',
    flag: '🇾🇹',
    phonePrefix: '+262',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'MX',
    label: 'Mexico',
    flag: '🇲🇽',
    phonePrefix: '+52',
    mask: 'xxx xxx xxxx',
  },
  {
    key: 'FM',
    label: 'Micronesia',
    flag: '🇫🇲',
    phonePrefix: '+691',
    mask: 'xxx xxxx',
  },
  {
    key: 'MD',
    label: 'Moldova',
    flag: '🇲🇩',
    phonePrefix: '+373',
    mask: 'xxxx xxxx',
  },
  {
    key: 'MC',
    label: 'Monaco',
    flag: '🇲🇨',
    region: 'EU',
    phonePrefix: '+377',
    mask: 'xx xx xx xx',
  },
  {
    key: 'MN',
    label: 'Mongolia',
    flag: '🇲🇳',
    phonePrefix: '+976',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'ME',
    label: 'Montenegro',
    flag: '🇲🇪',
    region: 'EU',
    phonePrefix: '+382',
    mask: 'xx xx xxxx',
  },
  {
    key: 'MS',
    label: 'Montserrat',
    flag: '🇲🇸',
    phonePrefix: '+1-664',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'MA',
    label: 'Morocco',
    flag: '🇲🇦',
    phonePrefix: '+212',
    mask: 'xxxx xxxxx',
  },
  {
    key: 'MZ',
    label: 'Mozambique',
    flag: '🇲🇿',
    phonePrefix: '+258',
    mask: 'xx xxxxxx',
  },
  {
    key: 'MM',
    label: 'Myanmar (Burma)',
    flag: '🇲🇲',
    phonePrefix: '+95',
    mask: 'x xxxxxx',
  },
  {
    key: 'NA',
    label: 'Namibia',
    flag: '🇳🇦',
    phonePrefix: '+264',
    mask: 'xx xxx xx',
  },
  {
    key: 'NR',
    label: 'Nauru',
    flag: '🇳🇷',
    phonePrefix: '+674',
    mask: 'xxx xxxx',
  },
  {
    key: 'NP',
    label: 'Nepal',
    flag: '🇳🇵',
    phonePrefix: '+977',
    mask: 'x xxxx xxx',
  },
  {
    key: 'NL',
    label: 'Netherlands',
    flag: '🇳🇱',
    region: 'EU',
    phonePrefix: '+31',
    mask: 'xx xxx xx xx',
  },
  {
    key: 'NC',
    label: 'New Caledonia',
    flag: '🇳🇨',
    phonePrefix: '+687',
    mask: 'xx xxxx',
  },
  {
    key: 'NZ',
    label: 'New Zealand',
    flag: '🇳🇿',
    phonePrefix: '+64',
    mask: 'x xxx xxxx',
  },
  {
    key: 'NI',
    label: 'Nicaragua',
    flag: '🇳🇮',
    phonePrefix: '+505',
    mask: 'xxxx xxxx',
  },
  {
    key: 'NE',
    label: 'Niger',
    flag: '🇳🇪',
    phonePrefix: '+227',
    mask: 'xxxx xxxx',
  },
  {
    key: 'NG',
    label: 'Nigeria',
    flag: '🇳🇬',
    phonePrefix: '+234',
    mask: 'x xxx xxxx',
  },
  {
    key: 'NU',
    label: 'Niue',
    flag: '🇳🇺',
    phonePrefix: '+683',
    mask: 'xxxx',
  },
  {
    key: 'NF',
    label: 'Norfolk Island',
    flag: '🇳🇫',
    phonePrefix: '+672',
    mask: '3 xx xxx',
  },
  {
    key: 'KP',
    label: 'North Korea',
    flag: '🇰🇵',
    phonePrefix: '+850',
    mask: 'xx xxx xxx',
  },
  {
    key: 'MK',
    label: 'North Macedonia',
    flag: '🇲🇰',
    phonePrefix: '+389',
    mask: '2 xxxxxxx',
  },
  {
    key: 'MP',
    label: 'Northern Mariana Islands',
    flag: '🇲🇵',
    phonePrefix: '+1-670',
    mask: 'xxx xxxx',
  },
  {
    key: 'NO',
    label: 'Norway',
    flag: '🇳🇴',
    region: 'EU',
    phonePrefix: '+47',
    mask: 'xx xx xx xx',
  },
  {
    key: 'OM',
    label: 'Oman',
    flag: '🇴🇲',
    phonePrefix: '+968',
    mask: 'xx xxx xxx',
  },
  {
    key: 'PK',
    label: 'Pakistan',
    flag: '🇵🇰',
    phonePrefix: '+92',
    mask: 'xx xxxxxxx',
  },
  {
    key: 'PW',
    label: 'Palau',
    flag: '🇵🇼',
    phonePrefix: '+680',
    mask: 'xxx xxxx',
  },
  {
    key: 'PS',
    label: 'Palestinian Territories',
    flag: '🇵🇸',
    phonePrefix: '+970',
    mask: 'x xxx xxxx',
  },
  {
    key: 'PA',
    label: 'Panama',
    flag: '🇵🇦',
    phonePrefix: '+507',
    mask: 'xxx-xxxx',
  },
  {
    key: 'PG',
    label: 'Papua New Guinea',
    flag: '🇵🇬',
    phonePrefix: '+675',
    mask: 'xxx xx xxx',
  },
  {
    key: 'PY',
    label: 'Paraguay',
    flag: '🇵🇾',
    phonePrefix: '+595',
    mask: 'xx xxx xxx',
  },
  {
    key: 'PE',
    label: 'Peru',
    flag: '🇵🇪',
    phonePrefix: '+51',
    mask: 'xxxx xxxx',
  },
  {
    key: 'PH',
    label: 'Philippines',
    flag: '🇵🇭',
    phonePrefix: '+63',
    mask: 'x xxxxxxx',
  },
  {
    key: 'PN',
    label: 'Pitcairn Islands',
    flag: '🇵🇳',
    phonePrefix: '+64',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'PL',
    label: 'Poland',
    flag: '🇵🇱',
    region: 'EU',
    phonePrefix: '+48',
    mask: 'xx xxx xx xx',
  },
  {
    key: 'PT',
    label: 'Portugal',
    flag: '🇵🇹',
    region: 'EU',
    phonePrefix: '+351',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'PR',
    label: 'Puerto Rico',
    flag: '🇵🇷',
    phonePrefix: '+1',
    mask: '(xxx) xxx-xxxx',
  },
  {
    key: 'QA',
    label: 'Qatar',
    flag: '🇶🇦',
    phonePrefix: '+974',
    mask: 'xxxx xxxx',
  },
  {
    key: 'RE',
    label: 'Réunion',
    flag: '🇷🇪',
    phonePrefix: '+262',
    mask: 'x xxxx xxxx',
  },
  {
    key: 'RO',
    label: 'Romania',
    flag: '🇷🇴',
    region: 'EU',
    phonePrefix: '+40',
    mask: 'xx xxx xx xx',
  },
  {
    key: 'RU',
    label: 'Russia',
    flag: '🇷🇺',
    phonePrefix: '+7',
    mask: 'xxx xxx xx xx',
  },
  {
    key: 'RW',
    label: 'Rwanda',
    flag: '🇷🇼',
    phonePrefix: '+250',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'WS',
    label: 'Samoa',
    flag: '🇼🇸',
    phonePrefix: '+685',
    mask: 'x xxxx',
  },
  {
    key: 'SM',
    label: 'San Marino',
    flag: '🇸🇲',
    phonePrefix: '+378',
    mask: '0549 xxx xxx',
  },
  {
    key: 'ST',
    label: 'São Tomé & Príncipe',
    flag: '🇸🇹',
    phonePrefix: '+239',
    mask: 'xxx xxxx',
  },
  {
    key: 'SA',
    label: 'Saudi Arabia',
    flag: '🇸🇦',
    phonePrefix: '+966',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'SN',
    label: 'Senegal',
    flag: '🇸🇳',
    phonePrefix: '+221',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'RS',
    label: 'Serbia',
    flag: '🇷🇸',
    phonePrefix: '+381',
    mask: 'xx xxx xx xx',
  },
  {
    key: 'SC',
    label: 'Seychelles',
    flag: '🇸🇨',
    phonePrefix: '+248',
    mask: 'x xx xx xx',
  },
  {
    key: 'SL',
    label: 'Sierra Leone',
    flag: '🇸🇱',
    phonePrefix: '+232',
    mask: 'xx xxxxxx',
  },
  {
    key: 'SG',
    label: 'Singapore',
    flag: '🇸🇬',
    phonePrefix: '+65',
    mask: 'xxxx xxxx',
  },
  {
    key: 'SX',
    label: 'Sint Maarten',
    flag: '🇸🇽',
    phonePrefix: '+1-721',
    mask: 'xxx-xxxx',
  },
  {
    key: 'SK',
    label: 'Slovakia',
    flag: '🇸🇰',
    region: 'EU',
    phonePrefix: '+421',
    mask: 'x xxx xxx xx',
  },
  {
    key: 'SI',
    label: 'Slovenia',
    flag: '🇸🇮',
    region: 'EU',
    phonePrefix: '+386',
    mask: 'x xxx xx xx',
  },
  {
    key: 'SB',
    label: 'Solomon Islands',
    flag: '🇸🇧',
    phonePrefix: '+677',
    mask: 'xx xxxxx',
  },
  {
    key: 'SO',
    label: 'Somalia',
    flag: '🇸🇴',
    phonePrefix: '+252',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'ZA',
    label: 'South Africa',
    flag: '🇿🇦',
    phonePrefix: '+27',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'GS',
    label: 'South Georgia & South Sandwich Islands',
    flag: '🇬🇸',
    phonePrefix: '+500',
    mask: 'xx xxx',
  },
  {
    key: 'KR',
    label: 'South Korea',
    flag: '🇰🇷',
    phonePrefix: '+82',
    mask: 'xx xxxx xxxx',
  },
  {
    key: 'SS',
    label: 'South Sudan',
    flag: '🇸🇸',
    phonePrefix: '+211',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'ES',
    label: 'Spain',
    flag: '🇪🇸',
    region: 'EU',
    phonePrefix: '+34',
    mask: 'xx xxx xx xxs',
  },
  {
    key: 'LK',
    label: 'Sri Lanka',
    flag: '🇱🇰',
    phonePrefix: '+94',
    mask: 'xx xxxxxxx',
  },
  {
    key: 'BL',
    label: 'St. Barthélemy',
    flag: '🇧🇱',
    phonePrefix: '+590',
    mask: 'x xx xx xx xx',
  },
  {
    key: 'SH',
    label: 'St. Helena',
    flag: '🇸🇭',
    phonePrefix: '+290',
    mask: 'xxxx',
  },
  {
    key: 'KN',
    label: 'St. Kitts & Nevis',
    flag: '🇰🇳',
    phonePrefix: '+1-869',
    mask: 'xxx-xxxx',
  },
  {
    key: 'LC',
    label: 'St. Lucia',
    flag: '🇱🇨',
    phonePrefix: '+1-758',
    mask: 'xxx-xxxx',
  },
  {
    key: 'MF',
    label: 'St. Martin',
    flag: '🇲🇫',
    phonePrefix: '+590',
    mask: 'x xx xx xx xx',
  },
  {
    key: 'PM',
    label: 'St. Pierre & Miquelon',
    flag: '🇵🇲',
    phonePrefix: '+508',
    mask: 'xx xx xx',
  },
  {
    key: 'VC',
    label: 'St. Vincent & Grenadines',
    flag: '🇻🇨',
    phonePrefix: '+1-784',
    mask: 'xxx-xxxx',
  },
  {
    key: 'SD',
    label: 'Sudan',
    flag: '🇸🇩',
    phonePrefix: '+249',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'SR',
    label: 'Suriname',
    flag: '🇸🇷',
    phonePrefix: '+597',
    mask: 'xxx xxx',
  },
  {
    key: 'SJ',
    label: 'Svalbard & Jan Mayen',
    flag: '🇸🇯',
    phonePrefix: '+47',
    mask: 'xx xx xx xx',
  },
  {
    key: 'SE',
    label: 'Sweden',
    flag: '🇸🇪',
    region: 'EU',
    phonePrefix: '+46',
    mask: 'x xxx xxx xx',
  },
  {
    key: 'CH',
    label: 'Switzerland',
    flag: '🇨🇭',
    region: 'EU',
    phonePrefix: '+41',
    mask: 'xx xxx xx xx',
  },
  {
    key: 'SY',
    label: 'Syria',
    flag: '🇸🇾',
    phonePrefix: '+963',
    mask: 'xx xxxx xxxx',
  },
  {
    key: 'TW',
    label: 'Taiwan',
    flag: '🇹🇼',
    phonePrefix: '+886',
    mask: 'x xxxxxxxx',
  },
  {
    key: 'TJ',
    label: 'Tajikistan',
    flag: '🇹🇯',
    phonePrefix: '+992',
    mask: 'xxxx xxxxx',
  },
  {
    key: 'TZ',
    label: 'Tanzania',
    flag: '🇹🇿',
    phonePrefix: '+255',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'TH',
    label: 'Thailand',
    flag: '🇹🇭',
    phonePrefix: '+66',
    mask: 'xxxx xxxx',
  },
  {
    key: 'TL',
    label: 'Timor-Leste',
    flag: '🇹🇱',
    phonePrefix: '+670',
    mask: 'xxx xxxx',
  },
  {
    key: 'TG',
    label: 'Togo',
    flag: '🇹🇬',
    phonePrefix: '+228',
    mask: 'xxx xxxx',
  },
  {
    key: 'TK',
    label: 'Tokelau',
    flag: '🇹🇰',
    phonePrefix: '+690',
    mask: 'xxxx',
  },
  {
    key: 'TO',
    label: 'Tonga',
    flag: '🇹🇴',
    phonePrefix: '+676',
    mask: 'xx xxx',
  },
  {
    key: 'TT',
    label: 'Trincodead & Tobago',
    flag: '🇹🇹',
    phonePrefix: '+1-868',
    mask: 'xxx-xxxx',
  },
  {
    key: 'TN',
    label: 'Tunisia',
    flag: '🇹🇳',
    phonePrefix: '+216',
    mask: 'xx xxx xxx',
  },
  {
    key: 'TR',
    label: 'Turkey',
    flag: '🇹🇷',
    phonePrefix: '+90',
    mask: 'xxx xxx xxxx',
  },
  {
    key: 'TM',
    label: 'Turkmenistan',
    flag: '🇹🇲',
    phonePrefix: '+993',
    mask: 'xx xx xx xx',
  },
  {
    key: 'TC',
    label: 'Turks & Caicos Islands',
    flag: '🇹🇨',
    phonePrefix: '+1-649',
    mask: 'xxx-xxxx',
  },
  {
    key: 'TV',
    label: 'Tuvalu',
    flag: '🇹🇻',
    phonePrefix: '+688',
    mask: 'xxxxx',
  },
  {
    key: 'UM',
    label: 'U.S. Outlying Islands',
    flag: '🇺🇲',
    phonePrefix: '+1',
    mask: 'xxx xxxxxxx',
  },
  {
    key: 'VI',
    label: 'U.S. Virgin Islands',
    flag: '🇻🇮',
    phonePrefix: '+1-340',
    mask: 'xxx xxxx',
  },
  {
    key: 'UG',
    label: 'Uganda',
    flag: '🇺🇬',
    phonePrefix: '+256',
    mask: 'xxx xx xxxx',
  },
  {
    key: 'UA',
    label: 'Ukraine',
    flag: '🇺🇦',
    phonePrefix: '+380',
    mask: 'xx xxx xxxx',
  },
  {
    key: 'AE',
    label: 'United Arab Emirates',
    flag: '🇦🇪',
    phonePrefix: '+971',
    mask: 'x xxx xxxx',
  },
  {
    key: 'GB',
    label: 'United Kingdom',
    flag: '🇬🇧',
    region: 'EU',
    phonePrefix: '+44',
    mask: 'xx xxxx xxxx',
  },
  {
    key: 'US',
    label: 'United States',
    flag: '🇺🇸',
    region: 'NA',
    phonePrefix: '+1',
    mask: '(xxx) xxx-xxxx',
  },
  {
    key: 'UY',
    label: 'Uruguay',
    flag: '🇺🇾',
    phonePrefix: '+598',
    mask: 'xxxx xxxx',
  },
  {
    key: 'UZ',
    label: 'Uzbekistan',
    flag: '🇺🇿',
    phonePrefix: '+998',
    mask: 'xx xxx xx xx',
  },
  {
    key: 'VU',
    label: 'Vanuatu',
    flag: '🇻🇺',
    phonePrefix: '+678',
    mask: 'xx xxx',
  },
  {
    key: 'VA',
    label: 'Vatican City',
    flag: '🇻🇦',
    phonePrefix: '+39',
    mask: '06 698 xxxxx',
  },
  {
    key: 'VE',
    label: 'Venezuela',
    flag: '🇻🇪',
    phonePrefix: '+58',
    mask: 'xxx xxx xxxx',
  },
  {
    key: 'VN',
    label: 'Vietnam',
    flag: '🇻🇳',
    phonePrefix: '+84',
    mask: 'xx xxxx xxxx',
  },
  {
    key: 'WF',
    label: 'Wallis & Futuna',
    flag: '🇼🇫',
    phonePrefix: '+681',
    mask: 'xxx xxx',
  },
  {
    key: 'EH',
    label: 'Western Sahara',
    flag: '🇪🇭',
    phonePrefix: '+212',
    mask: 'xxxx xxxxx',
  },
  {
    key: 'YE',
    label: 'Yemen',
    flag: '🇾🇪',
    phonePrefix: '+967',
    mask: 'x xxx xxx',
  },
  {
    key: 'ZM',
    label: 'Zambia',
    flag: '🇿🇲',
    phonePrefix: '+260',
    mask: 'xxx xxx xxx',
  },
  {
    key: 'ZW',
    label: 'Zimbabwe',
    flag: '🇿🇼',
    phonePrefix: '+263',
    mask: 'xx xxx xxxx',
  },
];
