import React, { FC, useEffect, useMemo, useState } from 'react';
import 'twin.macro';

import Badge from 'components/badge/Badge';
import AppPage from 'components/page/app-page/AppPage';
import AutoPagination from 'components/table/auto-table/AutoPagination';
import AutoTable from 'components/table/auto-table/AutoTable';
import { GET_INTAKES } from 'graphql/intakes/queries';
import useMetaQuery from 'hooks/metaQuery';
import { Eye } from '@phosphor-icons/react';
import { Meta } from 'types/apiTypes';
import { Intake } from 'types/intakeTypes';
import { parseDate } from 'utils/baseHelpers';
import IntakesFilterMenu from './settings/intakes/IntakesFilterMenu';
import useNavigationContext from 'hooks/context/nav-context';
import { useNavigate } from 'react-router-dom';

export type FilterMenuOption = 'All' | 'Completed' | 'In progress';

const tableColNames = ['Name', 'Status', 'Last change', 'Actions'];

type IntakesOverviewProps = {};
export const IntakesOverview: FC<IntakesOverviewProps> = () => {
  const [activePage, setActivePage] = useState(1);
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState<FilterMenuOption>('All');

  const { activeCustomer } = useNavigationContext();

  const intakeClickedHandler = (intake: Intake) => {
    navigate(`/intake/${intake.hash}`);
  };

  const fetchMoreIntakesHandler = async (currentPage: number) => {
    setActivePage(currentPage);
    try {
      await fetchMore({
        variables: {
          pageSize: 20,
          currentPage,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
    } catch (_) {
      return Promise.reject();
    } finally {
      return Promise.resolve();
    }
  };

  const activeFilterToString = (): string | undefined => {
    switch (activeFilter) {
      case 'Completed':
        return '1';
      case 'In progress':
        return '0';
      default:
        return '';
    }
  };

  //Fetch intakes
  const { loading, data, meta, refetch, fetchMore } = useMetaQuery<
    {
      intakes: Intake[];
      meta: Meta;
    },
    { pageSize: number; currentPage: number; completed?: string }
  >(GET_INTAKES, {
    variables: {
      pageSize: 20,
      currentPage: activePage,
      completed: activeFilterToString(),
    },
  });

  useEffect(() => {
    refetch();
  }, [activeFilter, activeCustomer, refetch]);

  const paginationMeta = meta.pagination;

  const rows = useMemo(() => {
    return data?.intakes.map((intake) => {
      return [
        <span
          tw="cursor-pointer hover:text-gray-400"
          onClick={() => intakeClickedHandler(intake)}
        >
          {intake.name}
        </span>,
        <p tw="whitespace-nowrap">
          <Badge
            color={intake.completed ? 'gray' : 'green'}
            label={intake.completed ? 'Completed' : 'In Progress'}
          />
        </p>,
        <span tw="text-xs font-semibold text-gray-600">
          {parseDate(intake.last_edit)}
        </span>,
        <Eye
          weight="bold"
          tw="cursor-pointer"
          onClick={() => intakeClickedHandler(intake)}
        />,
      ];
    });
  }, [data?.intakes, intakeClickedHandler]);

  return (
    <AppPage
      heading="Intakes"
      loading={loading}
      allowPageWithoutSubscription
      allowPageWithoutVacancy
    >
      <>
        <div tw="mb-6">
          <IntakesFilterMenu
            activeFilter={activeFilter}
            onChangeActiveFilter={setActiveFilter}
          />
        </div>

        <AutoTable columns={tableColNames} rows={rows} />
        <AutoPagination
          selectedPage={activePage}
          itemsLoaded={paginationMeta?.count}
          maxItems={paginationMeta?.count}
          totalItems={paginationMeta?.total}
          totalPages={paginationMeta?.total_pages}
          onPageChange={fetchMoreIntakesHandler}
        />
      </>
    </AppPage>
  );
};
