import React from 'react';
import tw, { css, styled } from 'twin.macro';

import SourceIcon from 'components/icon-card/SourceIcon';
import dayjs from 'dayjs';
import { Headline } from 'components/typography/Typography';
import { Lead } from 'types/leads/types';
import { Link } from 'react-router-dom';
import { Routes } from 'types/routeTypes';

const LeadCard: React.FC<{
  lead: Lead;
}> = ({ lead }) => {
  const currentyear = new Date().getFullYear();

  return (
    <LeadCardWrapper tabIndex={-1}>
      <Link to={`${Routes.LEAD_DETAIL}/${lead.id}`}>
        <div tw="flex flex-row items-center">
          <div tw="flex-1">
            <div tabIndex={-1} tw="flex items-baseline justify-between mb-4">
              <Headline isBold>
                {lead.firstname} {lead.lastname}
              </Headline>
              {lead.created_at && (
                <div tw="text-gray-400 text-xs font-medium text-right block">
                  {dayjs(lead.created_at).format(
                    new Date(lead.created_at).getFullYear() !== currentyear
                      ? 'DD MMM YYYY'
                      : 'DD MMM'
                  )}
                </div>
              )}
            </div>

            <div tw="flex flex-row justify-between items-center mt-2">
              <div tw="flex flex-row items-center">
                <div tw="block">
                  <SourceIcon source={lead.source} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </LeadCardWrapper>
  );
};

const LeadCardWrapper = styled.div<{ ringColor?: string }>`
  ${tw`bg-white shadow-md rounded-md p-5 block text-gray-800 cursor-pointer min-h-[7rem]`}
  ${({ ringColor }) =>
    !ringColor || ringColor === '#FFFFFF'
      ? tw`hover:shadow-lg`
      : css`
          box-shadow: 0 0 0 calc(3px) ${ringColor};

          :hover {
            box-shadow: 0 0 0 calc(4px) ${ringColor};
          }
        `}
`;

export default LeadCard;
