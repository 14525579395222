import React, { useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Eye, FileArrowDown } from '@phosphor-icons/react';
import { Body } from 'components/typography/Typography';
import JsFileDownloader from 'js-file-downloader';
import FilePreviewer from 'components/file-previewer/FilePreviewer';
import { getType } from 'utils/fileHelpers';

const FileCard: React.FC<{
  name: string;
  src: string;
}> = ({ name, src }) => {
  const url = `${import.meta.env.VITE_DO_SPACES_URL}${src}`;

  const [previewIsShown, setPreviewIsShown] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); // Either downloading the file for saving or for previewing

  const type = useMemo(() => getType(src), [src]);

  const fileClickHandler = () => {
    setIsDownloading(true);

    new JsFileDownloader({
      url,
    })
      .then(() => setIsDownloading(false))
      .catch(() => setIsDownloading(false));
  };

  const previewClickHandler = () => {
    setPreviewIsShown(true);
    setIsDownloading(true);
  };

  return (
    <>
      {type && (
        <FilePreviewer
          name={name}
          url={url}
          type={type}
          isShown={previewIsShown}
          closeModal={() => setPreviewIsShown(false)}
          onDocumentLoad={() => setIsDownloading(false)}
          onDocumentError={() => setIsDownloading(false)}
        />
      )}

      <div tw="flex justify-between items-center text-gray-500 cursor-pointer">
        <Body isTruncate isBold>
          {name}
        </Body>
        <ul tw="flex space-x-4 ml-2">
          {type && (
            <FileActionButton
              loading={previewIsShown}
              onClick={() => previewClickHandler()}
            >
              <Eye weight="bold" size="20" />
            </FileActionButton>
          )}

          <FileActionButton
            loading={type === 'pdf' && isDownloading}
            onClick={() => fileClickHandler()}
          >
            <FileArrowDown size="20" />
          </FileActionButton>
        </ul>
      </div>
    </>
  );
};

const FileActionButton = styled.li<{
  loading: boolean;
}>`
  ${tw`rounded-full bg-red-100 text-sympl p-2`}

  ${({ loading }) => loading && tw`bg-red-50 text-sympl/20 cursor-not-allowed`}
`;

export default FileCard;
