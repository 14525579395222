import React, { useRef } from 'react';
import useDefocusHandler from 'hooks/defocus';

import { StepperStep } from 'types/intakeTypes';
import useIntakeCoreContext from 'hooks/context/intakes-context';
import { StepperDataType } from 'types/intakes/core';
import 'twin.macro';
import * as Styled from './styles';
import ToolTip from 'components/tooltip/ToolTip';

interface IProps {
  onClose: () => void;
}

const StepperOverview = (props: IProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    handleSetStepId,
    handleSetSectionId,
    currentStepId,
    currentIntakeStepperData: stepperData,
  } = useIntakeCoreContext();

  useDefocusHandler(wrapperRef, () => {
    props.onClose();
  });

  const stepClickedHandler = (section: StepperDataType, step: StepperStep) => {
    handleSetSectionId(section.id);
    handleSetStepId(step.id);
  };

  const renderStep = (step: StepperStep, section: StepperDataType) => {
    const { isStepOptional } = step;
    const isCurrentStep = step.id === currentStepId;
    const isUnanswered = step.remainingQuestionCount > 0;
    const isAnswered = step.remainingQuestionCount === 0;
    const isSectionCompleted = isAnswered && section.completed;

    const tooltTipMessage = isStepOptional
      ? 'Optional step'
      : `${step.remainingQuestionCount} remaining ${
          step.remainingQuestionCount > 1 ? 'questions' : 'question'
        } `;

    return (
      <Styled.StepperStepListItem key={step.name}>
        <Styled.StepperButton
          onClick={() =>
            stepClickedHandler(section, step as unknown as StepperStep)
          }
        >
          <ToolTip
            text={tooltTipMessage}
            placement="top"
            arrow
            disabled={!isUnanswered && !isStepOptional}
          >
            <Styled.StepperButtonContent>
              <Styled.ActiveDot
                isAnswered={isAnswered}
                isUnanswered={isUnanswered}
                isCurrentStep={isCurrentStep}
                isSectionCompleted={isSectionCompleted}
              />
              {isUnanswered && (
                <Styled.ItemCount isCurrentStep={isCurrentStep}>
                  {step.remainingQuestionCount}
                </Styled.ItemCount>
              )}
              <Styled.StepName
                isAnswered={isAnswered}
                isUnanswered={isUnanswered}
                isCurrentStep={isCurrentStep}
                isStepOptional={isStepOptional}
                isSectionCompleted={isSectionCompleted}
              >
                {step.name}
              </Styled.StepName>
            </Styled.StepperButtonContent>
          </ToolTip>
        </Styled.StepperButton>
      </Styled.StepperStepListItem>
    );
  };

  const renderSteps = (stepsKey: number[], stepsSection: StepperDataType) => {
    return (
      <Styled.StepperStepList>
        {stepsSection.steps.map((step) => renderStep(step, stepsSection))}
      </Styled.StepperStepList>
    );
  };

  return (
    <Styled.OverviewWrapper ref={wrapperRef}>
      <Styled.IconWrapper>
        <Styled.CloseIcon onClick={() => props.onClose()} />
      </Styled.IconWrapper>
      <Styled.StepperContentList>
        {stepperData.length &&
          stepperData.map((section) => (
            <Styled.StepperSectionItem
              key={section.name}
              completed={section.completed}
            >
              <Styled.SectionNameWrapper>
                {section.completed && <Styled.CheckIcon />}
                <Styled.SectionName completed={section.completed}>
                  {section.name}
                </Styled.SectionName>
              </Styled.SectionNameWrapper>
              {renderSteps(section.steps as unknown as number[], section)}
            </Styled.StepperSectionItem>
          ))}
      </Styled.StepperContentList>
    </Styled.OverviewWrapper>
  );
};

export default StepperOverview;
