import { gql } from '@apollo/client';
export const GET_BRANDS = gql`
  query getCustomerBrands {
    brands @rest(type: "Brand", path: "/v4/brands") {
      id
      name
      page_id
      default_primary_color
      default_secondary_color
      default_tertiary_color
      default_recruiter
      default_language_id
      default_logo
      default_creatives
      default_locations
      default_display_link
      default_banner_title
      default_facebook_page_id
      custom_domain
      custom_domain_validations
    }
  }
`;
