import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_TEMPLATE = gql`
  mutation createOrUpdateTemplate($customerId: ID!) {
    template(input: $input, customerId: $customerId)
      @rest(type: "Template", path: "/v4/templates", method: "POST") {
      id
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($id: ID!) {
    deleteTemplate(id: $id)
      @rest(type: "Template", path: "/v4/templates/{args.id}", method: "DELETE")
  }
`;
