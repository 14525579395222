import React from 'react';
import 'twin.macro';

const SubLabel = ({
  htmlFor,
  children,
}: {
  htmlFor: string;
  children: React.ReactNode;
}) => {
  return (
    <label htmlFor={htmlFor} tw="block text-gray-500 text-xs mb-2">
      {children}
    </label>
  );
};

export default SubLabel;
