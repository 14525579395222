import React from 'react';
import tw from 'twin.macro';
import { PlusCircle } from '@phosphor-icons/react';
import {
  ListItem,
  List,
  LocationName,
  Grid,
} from 'components/multi-location/LocationList';
import { TargetingLocation } from 'types/geolocationTypes';
interface ILocationSuggestList {
  locations: TargetingLocation[];
  onAddLocation: (location: TargetingLocation) => void;
}
export const LocationSuggestList: React.FC<ILocationSuggestList> = ({
  locations,
  onAddLocation,
}: ILocationSuggestList) => {
  const addLocation = (location: TargetingLocation) => {
    onAddLocation(location);
    // locations = locations.filter((v) => v.id !== location.id);
  };
  return (
    <List>
      {locations.map((location, index) => (
        <ListItem
          css={tw`bg-[#fbfbfb] cursor-pointer`}
          key={index}
          tabIndex={0}
          onClick={(e) => {
            e.preventDefault();
            addLocation(location);
          }}
        >
          <Grid tw="grid-cols-2">
            <LocationName title={'Go to location'}>
              {location.name}
            </LocationName>
            <div tw="justify-self-end h-full flex justify-center items-center">
              <label
                htmlFor={`radius-${location.name}`}
                tw="text-gray-400 text-xs cursor-text mr-2 hidden invisible sm:(block visible)"
              >
                Recently used
              </label>
              <PlusCircle weight="bold" tw="text-gray-500" size={18} />
            </div>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};
