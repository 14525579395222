import { gql } from '@apollo/client';

export const TOGGLE_CAMPAIGN = gql`
  mutation ToggleCampaign($id: ID!) {
    campaignStatus(input: $input, id: $id)
      @rest(
        type: "Vacancy"
        path: "/v4/vacancies/{args.id}/status"
        method: "POST"
      )
  }
`;

export const SUBMIT_FEEDBACK = gql`
  mutation submitFeedback($vacancyId: ID!) {
    submitFeedback(input: $input, vacancyId: $vacancyId)
      @rest(
        type: "Feedback"
        path: "/v4/vacancies/{args.vacancyId}/feedback"
        method: "POST"
      )
  }
`;
export const CREATE_VACANCY = gql`
  mutation CreateVacancy {
    createVacancy(input: $input)
      @rest(type: "VacancyCreate", path: "/v4/vacancies", method: "POST") {
      id
    }
  }
`;

export const IMPORT_VACANCY = gql`
  mutation ImportVacancy {
    importVacancy(input: $input)
      @rest(
        type: "VacancyImport"
        path: "/v4/ai/importVacancy"
        method: "POST"
      ) {
      id
      customer_id
    }
  }
`;

export const UPDATE_BUDGET = gql`
  mutation updateBudget($vacancyId: ID!) {
    updatedBudget(input: $input, id: $vacancyId)
      @rest(
        type: "UpdatedBudget"
        path: "/v4/vacancies/{args.id}/budget"
        method: "POST"
      ) {
      session_id
    }
  }
`;

export const REQUEST_EXTENSION = gql`
  mutation requestExtension($vacancyId: ID!) {
    ExtensionRequested(input: $input, id: $vacancyId)
      @rest(
        type: "ExtensionRequested"
        path: "/v4/vacancies/{args.id}/request-extension"
        method: "POST"
      ) {
      id
    }
  }
`;

export const PUBLISH_VACANCY = gql`
  mutation publishVacancy($vacancyId: ID!) {
    publishedVacancy(input: $input, vacancy: $vacancyId)
      @rest(
        type: "PublishedVacancy"
        path: "/v4/vacancies/{args.vacancy}/publish"
        method: "POST"
      )
  }
`;

export const DEPUBLISH_VACANCY = gql`
  mutation depublishVacancy($vacancyId: ID!) {
    depublishedVacancy(input: $input, vacancy: $vacancyId)
      @rest(
        type: "DepublishedVacancy"
        path: "/v4/vacancies/{args.vacancy}/depublish"
        method: "POST"
      )
  }
`;

export const SEND_FEEDBACK_NOTIFICATION = gql`
  mutation sendFeedbackNotification($vacancyId: ID!) {
    feedbackNotification(input: $input, vacancy: $vacancyId)
      @rest(
        type: "FeedbackNotification"
        path: "/v4/vacancies/{args.vacancy}/feedback-notification"
        method: "POST"
      )
  }
`;

export const UPDATE_TARGETING_CONFIG = gql`
  mutation updateTargetingConfig($vacancyId: ID!) {
    targetingConfig(input: $input, vacancy: $vacancyId)
      @rest(
        type: "TargetingConfiguration"
        path: "/v4/vacancies/{args.vacancy}/targeting"
        method: "POST"
      ) {
      id
    }
  }
`;

export const UPDATE_VACANCY_COPY = gql`
  mutation updateVacancyCopy($vacancyId: ID!) {
    vacancy(input: $input, id: $vacancyId)
      @rest(
        type: "VacancyWithCopy"
        path: "/v4/vacancies/{args.id}/copy"
        method: "POST"
      )
  }
`;

export const UPDATE_VACANCY_CUSTOM_URL = gql`
  mutation updateVacancyCopy($vacancyId: ID!) {
    vacancy(input: $input, id: $vacancyId)
      @rest(
        type: "VacancyWithCustomUrl"
        path: "/v4/vacancies/{args.id}/url"
        method: "POST"
      )
  }
`;

export const UPDATE_VACANCY_QUESTIONS = gql`
  mutation updateVacancyQuestions {
    updateVacancyQuestions(input: $input, vacancyId: $vacancyId)
      @rest(
        type: "VacancyQuestionResult"
        path: "/v4/vacancies/{args.vacancyId}/surveys"
        method: "POST"
      )
  }
`;

export const UPDATE_VACANCY_PREFERENCES = gql`
  mutation updateVacancyPreferences($vacancyId: ID!) {
    updateVacancyPreferences(input: $input, id: $vacancyId)
      @rest(
        type: "PreferencesConfiguration"
        path: "/v4/vacancies/{args.id}/preferences"
        method: "POST"
      )
  }
`;

export const DELETE_VACANCY = gql`
  mutation deleteVacancy($vacancyId: ID!) {
    deleteVacancy(id: $vacancyId)
      @rest(type: "Vacancy", path: "/v4/vacancies/{args.id}", method: "DELETE")
  }
`;

export const UPDATE_VACANCY_SURVEY = gql`
  mutation updateVacancySurvey($vacancySurveyId: ID!) {
    updateVacancySurvey(input: $input, id: $vacancySurveyId)
      @rest(
        type: "SurveyQuestions"
        path: "/v4/vacancy-surveys/{args.id}"
        method: "PUT"
      )
  }
`;

export const UPDATE_VACANCY_ADVERTISEMENT = gql`
  mutation updateVacancyAdvertisement($vacancyId: ID!) {
    vacancy(input: $input, id: $vacancyId)
      @rest(
        type: "VacancyAdvertisement"
        path: "/v4/vacancies/{args.id}/advertisement"
        method: "POST"
      )
  }
`;

export const UPDATE_VACANCY_TRAJECTORY_PACE = gql`
  mutation updatPace($vacancyId: ID!, $pace: String!) {
    updatePace(input: { pace: $pace }, id: $vacancyId)
      @rest(
        type: "VacancyTrajectory"
        path: "/v4/vacancies/{args.id}/pace"
        method: "POST"
      )
  }
`;

export const TOGGLE_CHANNEL = gql`
  mutation toggleChannel($vacancyId: ID!) {
    toggleChannel(input: $input, id: $vacancyId)
      @rest(path: "/v4/vacancies/{args.id}/channels", method: "POST") {
      channels
    }
  }
`;

export const RESET_PREFERENCES_TEMPLATE = gql`
  mutation resetMailTemplate($id: ID!) {
    resetMailTemplate(input: $input, id: $id)
      @rest(
        type: "MailTemplate"
        path: "/v4/vacancies/{args.id}/reset-preferences-template"
        method: "POST"
      ) {
      id
      templates
    }
  }
`;
