import 'twin.macro';
import React from 'react';

import { Error } from 'components/typography/Typography';

interface IntakeInitInputProps {
  label: string;
  errorMessage: string;
  hasError: boolean;
}

const IntakeInitInput: React.FC<IntakeInitInputProps> = ({
  errorMessage,
  label,
  children,
  hasError,
}) => (
  <div>
    <div tw="space-y-3">
      <dt tw="text-base font-medium text-gray-500 flex flex-col">{label}</dt>
      <dd tw="flex text-base text-gray-900">{children}</dd>
    </div>
    <dt>{hasError && <Error>{errorMessage}</Error>}</dt>
  </div>
);

export default IntakeInitInput;
