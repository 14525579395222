import 'twin.macro';

import ActiveVacanciesTable from 'components/dashboard/ActiveVacanciesTable';
import ApplicationsList from 'components/dashboard/ApplicationsList';
import EmailsList from 'components/dashboard/EmailsList';
import StatList from 'components/dashboard/StatList';
import AppPage from 'components/page/app-page/AppPage';
import { SkeletonList1 } from 'components/skeleton/skeleton-list/SkeletonList';
import { Title3 } from 'components/typography/Typography';
import { GET_WEEKLY_METRICS } from 'graphql/dashboard/queries';
import { useQuery } from 'hooks/sympl-query';
import React, { useEffect, useMemo } from 'react';
import { WeeklyMetricsResponse } from 'types/dashboard/types';
import { getLocalAppSession } from 'utils/storageHelpers';
import useNavigationContext from 'hooks/context/nav-context';

export const Dashboard: React.FC = () => {
  const { activeCustomer } = useNavigationContext();

  const { loading, data } = useQuery<WeeklyMetricsResponse>(
    GET_WEEKLY_METRICS,
    {
      fetchPolicy: 'network-only',
      skip: !activeCustomer,
    }
  );

  const metrics = useMemo(() => data?.campaignMetrics, [data?.campaignMetrics]);

  useEffect(() => {
    const baseFBConfig = { organization: 'sympl', theme: 'light' };

    // @ts-ignore
    Featurebase('initialize_changelog_widget', {
      ...baseFBConfig,
      fullscreenPopup: true, // Optional - Open a fullscreen announcement of the new feature to the user
      usersName: getLocalAppSession().firstName, // Optional - Show the users name in the welcome message for the fullscreen popup
    });

    // @ts-ignore
    Featurebase('initialize_survey_widget', {
      ...baseFBConfig,
      placement: 'bottom-right', // optional (bottom-right or bottom-left)
    });
  }, []);

  return (
    <AppPage heading="Dashboard">
      <div tw="h-full w-full overflow-y-auto no-scrollbar">
        <div tw="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-6 h-full">
          <div tw="col-span-2">
            <div tw="flex flex-col">
              <Title3>Weekly numbers</Title3>
              {loading ? (
                <div tw="flex gap-4 flex-nowrap">
                  {[...Array(3)].map((_, i) => (
                    <div key={i} tw="w-full">
                      <SkeletonList1 rows={1} />
                    </div>
                  ))}
                </div>
              ) : (
                <StatList metrics={metrics} />
              )}
            </div>
            <div tw="grid grid-cols-1 xl:grid-cols-2 gap-8 mt-6">
              <div>
                <Title3>Latest applications</Title3>
                <ApplicationsList />
              </div>
              <div>
                <Title3>Latest messages</Title3>
                <EmailsList />
              </div>
            </div>
            <div tw="mt-6">
              <Title3>Active campaigns</Title3>
              <ActiveVacanciesTable />
            </div>
          </div>
        </div>
      </div>
    </AppPage>
  );
};
