import { PlaceResult } from 'components/multi-location/LocationSearch';
import { LatLngTuple } from 'leaflet';
import {
  LocationType,
  PolygonData,
  TargetingLocation,
} from 'types/geolocationTypes';
import { api as axios } from 'api/api';
import mergeWith from 'lodash.mergewith';

export const BASE_ZOOM = 10;
export const MIN_RADIUS_KM = 16;
export const MAX_RADIUS_KM = 80;
export const TILE_PROVIDER_URL =
  'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png';
export const MARKER_ICON_URL =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Map_pin_icon.svg/1504px-Map_pin_icon.svg.png';
const OSM_REVERSE_URL = 'https://nominatim.openstreetmap.org/reverse?';
const OSM_REVERSE_PARAMS =
  '&format=json&addressdetails=0&polygon_geojson=1&accept-language=en&polygon_threshold=0.001&zoom=';

const getOsmReverseUrl = (lat: number, lng: number, type: LocationType) => {
  return (
    OSM_REVERSE_URL +
    `lat=${lat}&lon=${lng}` +
    OSM_REVERSE_PARAMS +
    getLocationZoom(type)
  );
};

export const getLocationType = (type?: string) => {
  switch (type) {
    case 'sublocality_level_5':
    case 'sublocality_level_4':
    case 'sublocality_level_3':
    case 'sublocality_level_2':
    case 'sublocality_level_1':
    case 'sublocality':
      return LocationType.SUB_CITY;
    case 'locality':
      return LocationType.CITY;
    case 'administrative_area_level_1':
      return LocationType.REGION;
    case 'administrative_area_level_2':
      return LocationType.STATE;
    case 'country':
      return LocationType.COUNTRY;
    default:
      return LocationType.CITY;
  }
};

// See https://nominatim.org/release-docs/develop/api/Reverse/#result-limitation
// Or https://github.com/lonvia/Nominatim/blob/5e5cff897f2c503b99a054cc053310ab08170655/lib-php/ReverseGeocode.php
const getLocationZoom = (type: LocationType) => {
  switch (type) {
    case LocationType.SUB_CITY:
    case LocationType.CITY:
      return 10;
    case LocationType.REGION:
      return 6;
    case LocationType.STATE:
      return 5;
    case LocationType.COUNTRY:
      return 3;
  }
};

export const getAvailableAddressValues = (
  addressComponents: PlaceResult['address_components']
) => {
  const availableAddressValues: {
    [key: string]: string[];
  } = {};

  // Save all available address values from all address components
  addressComponents?.forEach((entry) => {
    availableAddressValues[entry.types[0]] =
      availableAddressValues[entry.types[0]] ?? [];

    availableAddressValues[entry.types[0]].push(
      entry.types[0] !== 'country' ? entry.long_name : entry.short_name
    );
  });

  return availableAddressValues;
};

export const generateTargetingLocation = ({
  id,
  label,
  lat,
  lng,
  type,
  radius,
  polygon,
  addressComponents,
}: {
  id?: TargetingLocation['id'];
  label: string;
  lat: number;
  lng: number;
  type: string;
  radius?: number;
  polygon?: PolygonData;
  polygonEntity?: string;
  addressComponents: PlaceResult['address_components'][];
}): TargetingLocation => {
  let availableAddressValues: any = {};

  const customizer = (objValue: any, srcValue: any) => {
    if (Array.isArray(objValue)) return objValue.concat(srcValue);
  };

  // TODO: remove merge with
  addressComponents.forEach((addressComponent) => {
    availableAddressValues = mergeWith(
      availableAddressValues,
      getAvailableAddressValues(addressComponent),
      customizer
    );
  });

  const locationType = getLocationType(type);

  return {
    id: id ?? null,
    city:
      locationType === LocationType.SUB_CITY
        ? availableAddressValues.sublocality_level_1?.[0] ??
          availableAddressValues.political?.[0]
        : locationType === LocationType.STATE ||
          locationType === LocationType.REGION ||
          locationType === LocationType.COUNTRY
        ? ''
        : availableAddressValues.locality?.[0] ?? '',
    country_code: availableAddressValues.country[0].toLowerCase(),
    lat,
    lng,
    polygon,
    name: label,
    radius: radius
      ? radius
      : locationType === LocationType.CITY ||
        locationType === LocationType.SUB_CITY
      ? MIN_RADIUS_KM
      : null,
    region:
      locationType !== LocationType.COUNTRY
        ? availableAddressValues.administrative_area_level_1?.[0] ?? ''
        : null,
    state:
      locationType === LocationType.REGION ||
      locationType === LocationType.COUNTRY
        ? ''
        : availableAddressValues.administrative_area_level_2?.[0] ?? '',
    zipcode:
      locationType === LocationType.STATE ||
      locationType === LocationType.REGION ||
      locationType === LocationType.COUNTRY
        ? ''
        : availableAddressValues.postal_code?.[0] ?? '',
    type: locationType,
    isCountry: locationType === LocationType.COUNTRY,
  };
};

export const fetchSearchResults = async (
  query: string,
  language: string,
  types: string, // See https://developers.google.com/maps/documentation/places/web-service/autocomplete#types
  locations?: TargetingLocation[]
): Promise<google.maps.places.AutocompletePrediction[]> =>
  new Promise(async (resolve, reject) => {
    const url =
      import.meta.env.VITE_BASE_URL_API +
      '/v4/proxy/google?url=' +
      encodeURIComponent(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&types=${types}&language=${language}`
      );

    const {
      data: {
        data: {
          body: { status, predictions },
        },
      },
    } = await axios.get<{
      data: {
        body: {
          status: google.maps.places.PlacesServiceStatus;
          predictions: google.maps.places.AutocompletePrediction[];
        };
      };
    }>(url);

    if (status === 'OK') {
      let newResults = predictions;

      // Filter out locations that are already selected
      if (locations?.length) {
        newResults = predictions.filter(
          (result) =>
            !result.terms
              .flatMap(({ value }) => value)
              .some((term) =>
                locations.some(
                  ({ name }) => name.toLowerCase() === term.toLowerCase()
                )
              )
        );
      }

      resolve(newResults);
    } else reject();
  });

const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Earth's radius in kilometers

  const dLat = toRadians(lat2) - toRadians(lat1);
  const dLon = toRadians(lon2) - toRadians(lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const calculateRadius = (geometry?: any) => {
  if (geometry?.viewport) {
    const distance = Math.round(
      haversineDistance(
        geometry.viewport.northeast.lat,
        geometry.viewport.northeast.lng,
        geometry.viewport.southwest.lat,
        geometry.viewport.southwest.lng
      ) / 3 // 2 would be more accurate, but 3 feels more correct for some bigger cities/regions/provinces
    );

    if (distance > 20) return distance;
  }
  return 20;
};

export const fetchPlaceDetailsByPlaceId = async (
  placeId: string,
  language: string,
  fields?: string
): Promise<PlaceResult> =>
  new Promise(async (resolve, reject) => {
    const url =
      import.meta.env.VITE_BASE_URL_API +
      '/v4/proxy/google?url=' +
      encodeURIComponent(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=${
          fields ?? 'name,geometry,address_components,types,formatted_address'
        }&language=${language}`
      );

    const {
      data: {
        data: {
          body: { status, result: place },
        },
      },
    } = await axios.get<{
      data: {
        body: {
          status: google.maps.places.PlacesServiceStatus;
          result: any; // google.maps.places.PlaceResult;
        };
      };
    }>(url);

    if (status === 'OK') {
      return resolve(place);
    } else reject('fetchPlaceDetailsByPlaceId - Could not fetch place details');
  });

export const fetchPolygon = async (
  coordinates: LatLngTuple,
  type: LocationType,
  abortSignal?: AbortSignal
): Promise<{
  boundingbox: number[];
  data: LatLngTuple[];
}> => {
  const result = await fetch(
    getOsmReverseUrl(coordinates[0], coordinates[1], type),
    {
      signal: abortSignal,
    }
  );
  const nominatimData = await result.json();

  if (nominatimData.geojson.type === 'Polygon')
    return {
      boundingbox: nominatimData.boundingbox,
      data: nominatimData.geojson.coordinates[0].map(
        (coordinate: LatLngTuple) => [coordinate[1], coordinate[0]]
      ),
    };

  if (nominatimData.geojson.type === 'MultiPolygon')
    return {
      boundingbox: nominatimData.boundingbox,
      data: nominatimData.geojson.coordinates
        .flat()
        .sort((a: [], b: []) => b.length - a.length)[0]
        .map((coordinate: LatLngTuple) => [coordinate[1], coordinate[0]]),
    };

  return {
    boundingbox: nominatimData.boundingbox,
    data: [nominatimData.geojson.coordinates],
  };
};
