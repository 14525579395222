import React, { FC, useRef } from 'react';
import PageSettingsSidebar from 'components/careers/page-settings-sidebar/PageSettingsSidebar';
import SectionSettingsSidebar from 'components/careers/section-settings-sidebar/SectionSettingsSidebar';
import SectionsRenderer from 'components/careers/section-renderer/SectionsRenderer';
import useCareerPageContext from 'hooks/context/career-page-context';
import { FormProvider, useForm } from 'react-hook-form';
import tw from 'twin.macro';
import useDefocusHandler from 'hooks/defocus';
import CareerSkeleton from './CareerSkeleton';

const CareerPageBuilder: FC = () => {
  const { careerPageConfig, isCareerDataLoading, handlePageSettingKey } =
    useCareerPageContext();

  const pageBuilderRef = useRef(null);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useDefocusHandler(pageBuilderRef, () => {
    handlePageSettingKey(null);
  });

  if (isCareerDataLoading || !careerPageConfig) {
    return <CareerSkeleton />;
  }

  return (
    <FormProvider {...formMethods}>
      <Container ref={pageBuilderRef}>
        <PageSettingsSidebar />
        <div
          className="pageContainer"
          tw="w-full overflow-x-hidden overflow-y-auto no-scrollbar"
        >
          <SectionsRenderer pageConfig={careerPageConfig} />
        </div>
        <SectionSettingsSidebar />
      </Container>
    </FormProvider>
  );
};

const Container = tw.div`
  flex
  flex-nowrap
  w-full
  h-[calc(100vh-64px)]
  overflow-hidden
  font-montserrat
`;

export default CareerPageBuilder;
