import {
  AcceptedSections,
  IBranding as ISymplBranding,
} from '@symplbe/sympl-components';
import { Seo } from './seo';

export enum CareerPageStatusType {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Archived = 'ARCHIVED',
}

export type CareerPageStatusTypes = 'ACTIVE' | 'DRAFT' | 'ARCHIVED';

export interface SectionOrder {
  isHidden: boolean;
  sectionId: string;
}

export interface CareerPageResponse {
  status: CareerPageStatusTypes;
  url?: string;
  created_at: string;
  updated_at: string;
  branding: ISymplBranding;
  seo: Seo;
  sections: Record<string, AcceptedSections>;
  order: SectionOrder[];
}

export interface CareerPageAPIResponse {
  customerCareerPage: CareerPageResponse;
}
