import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import 'twin.macro';

import { IntakeContext } from 'context/IntakeContext';
import { IntakeQuestion } from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';
import ColorPicker from 'components/form/ColorPicker';
import { Error } from 'components/typography/Typography';

const IntakeColorPicker: React.FC<{
  question: IntakeQuestion;
  defaultValue?: string;
  variant?: 'primary' | 'secondary';
}> = ({ question, variant, defaultValue }) => {
  const intakeContext = useContext(IntakeContext);
  const brandColors = intakeContext ? intakeContext.brandColors : null;
  const rules = questionRules(question);

  const fallbackColors = ['#2193b0', '#6dd5ed'];

  const { control, errors } = useFormContext();
  const id = question.id.toString();

  const getColorValue = (value?: string) => {
    if (value) return value;
    else if (defaultValue) return defaultValue;
    else if (brandColors && brandColors.length === 2 && variant === 'primary')
      return brandColors[0];
    else if (brandColors && brandColors.length === 2 && variant === 'secondary')
      return brandColors[1];
    else return variant === 'primary' ? fallbackColors[0] : fallbackColors[1];
  };

  return (
    <>
      <Controller
        id={id}
        name={id}
        control={control}
        defaultValue={getColorValue()}
        rules={rules}
        render={({ onChange, value }: { onChange: any; value: any }) => (
          <ColorPicker
            value={
              getColorValue(value) ??
              (variant === 'primary' ? fallbackColors[0] : fallbackColors[1])
            }
            ariaInvalid={errors[id] !== undefined}
            onChange={(color) => onChange(color)}
          />
        )}
      />
      {errors[id] !== undefined && <Error>{rules.required?.message}</Error>}
    </>
  );
};

export default IntakeColorPicker;
