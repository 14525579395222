import React from 'react';
import { Title2 } from 'components/typography/Typography';
import 'twin.macro';

import { roundNumber } from 'utils/numberHelpers';
import MetricChart from './MetricChart';

import { Card, CardContent, CardHeader } from 'components/ui/card';

type ChartData = {
  [key: string]: number;
};

interface MetricCardProps {
  title: JSX.Element;
  name: string;
  value: number;
  chartData: ChartData[];
  isBudget?: boolean;
  isPercentage?: boolean;
  noDecimal?: boolean;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  name,
  value,
  chartData,
  isBudget = false,
  isPercentage = false,
  noDecimal = false,
}) => {
  return (
    <Card tw="max-w-[12rem] lg:(w-[12.5vw] max-w-[12.5rem]) 2xl:(w-[14vw] max-w-[14vw]) 3xl:(w-[18rem] max-w-[18rem]) shadow-card">
      <CardHeader tw="text-center">
        <Title2>{title}</Title2>
        <p tw="font-bold text-4xl mb-4">
          {isBudget && <span>&euro;</span>}
          {value ? (noDecimal ? value.toFixed(0) : roundNumber(value)) : 0}
          {isPercentage && <span>&#37;</span>}
        </p>
      </CardHeader>
      <CardContent tw="p-2 max-2xl:(h-[120px])">
        <MetricChart
          title={name}
          data={chartData}
          isBudget={isBudget}
          isPercentage={isPercentage}
          type="bar"
        />
      </CardContent>
    </Card>
  );
};

export default MetricCard;
