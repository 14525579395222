import 'twin.macro';
import React, { useRef } from 'react';

import FileCard from 'components/file-card/FileCard';
import EmptyAssetWrapper from '../empty-sidebar-list/EmptySidebarList';
import SidebarListItem from '../sidebar-list-item/SidebarListItem';
import { CandidateFile } from 'types/candidates/types';
import { Footnote, Title3 } from 'components/typography/Typography';
import { PlusCircle } from '@phosphor-icons/react';
import ToolTip from 'components/tooltip/ToolTip';
import { MimeTypes } from 'types/fileTypes';
import useNavigationContext from 'hooks/context/nav-context';
import axios from 'axios';

const CandidateFileList: React.FC<{
  files: CandidateFile[];
  procedureId: number;
}> = ({ files, procedureId }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { activeCustomer } = useNavigationContext();
  const [procedureFiles, setProcedureFiles] =
    React.useState<CandidateFile[]>(files);

  const uploadFile = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);
    formData.append('candidate_id', '1');

    return axios
      .post(
        `${
          import.meta.env.VITE_BASE_URL_API
        }/v4/procedures/${procedureId}/file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'active-customer': activeCustomer!.toString(),
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => setProcedureFiles(res.data.data.files));
  };

  const handleFileUploads = (files: FileList) => {
    if (!files) return;

    return Promise.all(
      Array.from(files).map((file) => {
        return uploadFile(file);
      })
    );
  };

  return (
    <div tw="relative">
      <div tw="flex gap-2 items-center">
        <Title3 tw="w-fit">Files</Title3>
        <div>
          <ToolTip text="Add a file for this candidate" placement="right" arrow>
            <PlusCircle
              weight="bold"
              color="#fd4c5a"
              size={22}
              tw="w-fit mb-2 cursor-pointer"
              onClick={() => fileInputRef.current?.click()}
            />
          </ToolTip>
          <input
            type="file"
            multiple
            accept={Object.values(MimeTypes).join(', ')}
            id="candidateFileUpload"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => handleFileUploads(e.target.files as FileList)}
          />
        </div>
      </div>
      {procedureFiles.length > 0 ? (
        <ul tw="overflow-y-auto">
          {procedureFiles.map((file) => (
            <SidebarListItem key={file.name}>
              <FileCard name={file.name} src={file.url} />
            </SidebarListItem>
          ))}
        </ul>
      ) : (
        <EmptyAssetWrapper>
          <Footnote>No files yet</Footnote>
        </EmptyAssetWrapper>
      )}
    </div>
  );
};

export default CandidateFileList;
