import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ArrowRight } from '@phosphor-icons/react';
import styled from 'styled-components';

import { IBaseInputProps } from 'types/formTypes';
import {
  BaseLanguages,
  BASE_SELECTED_LANGUAGES,
  LanguageLevels,
  LANGUAGE_LEVELS,
} from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';
import Label from './Label';
import RadioGroup from './RadioGroup';

import 'twin.macro';
import tw from 'twin.macro';

interface IProps extends IBaseInputProps {
  questionValue: SelectableLanguage[];
}

export type SelectableLanguage = {
  name: BaseLanguages | string;
  levels: LanguageLevels[];
  selected?: boolean;
  level?: LanguageLevels;
};

const LanguageSelect = (props: IProps) => {
  const { setValue, register, errors } = useFormContext();
  const newLanguageNameRef = useRef<HTMLInputElement>(null);

  const questionId = props.question.id.toString();
  const [addLanguageVisible, setAddLanguageVisibility] = useState(false);
  const [newLanguageLevel, setNewLanguageLevel] = useState(
    LanguageLevels.BEGINNER
  );

  const [selectedLanguages, setSelectedLanguages] = useState<
    SelectableLanguage[]
  >(props.questionValue);

  useEffect(() => {
    // Filters out the base languages
    const filteredLanguages = selectedLanguages.filter(
      (l) => l.level !== undefined
    );

    // Set form element to updated languages
    setValue(
      questionId,
      filteredLanguages.length > 0
        ? JSON.stringify(
            filteredLanguages.map((l) => ({
              name: l.name,
              level: l.level,
            }))
          )
        : null
    );
  }, [questionId, selectedLanguages, setValue]);

  const newLanguageLevelChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.currentTarget) return;
    setNewLanguageLevel(e.currentTarget.value as LanguageLevels);
  };

  const addLanguageHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const language = newLanguageNameRef.current?.value;
    if (!language || language === '' || isDuplicateLanguage(language)) return;

    setSelectedLanguages([
      ...selectedLanguages,
      {
        levels: LANGUAGE_LEVELS,
        selected: true,
        name: language,
        level: newLanguageLevel,
      },
    ]);

    // Hide the form when a language is added
    setAddLanguageVisibility(false);
  };

  const isDuplicateLanguage = (language: string) => {
    return selectedLanguages
      .map((l) => l.name.toLowerCase())
      .includes(language.toLowerCase());
  };

  const hideAddLanguageHandler = () => {
    setAddLanguageVisibility(false);
  };

  const clickLanguageHandler = (
    language: SelectableLanguage,
    level: LanguageLevels
  ) => {
    const oldLangs = [...selectedLanguages];
    const levelsAreTheSame =
      selectedLanguages.find((l) => l.name === language.name)?.level === level;
    const otherLangs = selectedLanguages.filter(
      (l) => l.name !== language.name
    );

    // Find language to edit
    const modifiedLanguage = oldLangs.find((l) => l.name === language.name);

    if (!modifiedLanguage) return;

    // Toggle language based on name and level
    // Ignore base languages to preserve presets
    if (levelsAreTheSame) {
      const isBaseLang = BASE_SELECTED_LANGUAGES.map(
        (lang) => lang.name
      ).includes(language.name as BaseLanguages);

      // Edit language props
      modifiedLanguage.level = undefined;
      modifiedLanguage.selected = false;

      setSelectedLanguages(isBaseLang ? [...oldLangs] : [...otherLangs]);
    } else {
      // Edit language props
      modifiedLanguage.level = level;
      modifiedLanguage.selected = true;

      setSelectedLanguages([...oldLangs]);
    }
  };

  return (
    <LanguageSelectWrapper>
      <input
        tw="hidden"
        name={questionId}
        aria-invalid={errors[questionId] !== undefined}
        ref={register(questionRules(props.question))}
      />

      {selectedLanguages && selectedLanguages.length > 0 && (
        <div>
          {selectedLanguages.map((language) => (
            <LanguageItem key={language.name} selected={language.selected}>
              <p tw="flex items-center p-2 font-semibold text-gray-700">
                {language.name}
              </p>

              <ul tw="flex flex-wrap">
                {language.levels.map((level: LanguageLevels) => (
                  <LevelItem
                    key={`${language.name}-${level}`}
                    active={language.level === level}
                    onClick={() => clickLanguageHandler(language, level)}
                  >
                    {level}
                  </LevelItem>
                ))}
              </ul>
            </LanguageItem>
          ))}
        </div>
      )}

      {!addLanguageVisible && selectedLanguages.length > 0 && (
        <div tw="flex w-full">
          <button
            onClick={() => setAddLanguageVisibility(true)}
            tw="ml-auto mt-4 w-full cursor-pointer rounded-md bg-indigo-50 px-5 py-4 font-medium text-indigo-600 transition duration-150 ease-in-out hover:bg-indigo-100 focus:bg-indigo-100"
          >
            <div tw="flex items-center justify-end">
              Add Language
              <ArrowRight tw="ml-2 text-xl" style={{ marginTop: 2 }} />
            </div>
          </button>
        </div>
      )}
      {(addLanguageVisible || selectedLanguages.length < 1) && (
        <AddLanguageItem>
          <p tw="mb-4 w-full font-semibold leading-tight text-gray-500">
            Add Language
          </p>

          <div tw="flex w-full flex-col sm:flex-row">
            <div tw="w-full">
              <Label htmlFor="form-input w-full">Choose a Language</Label>
              <input
                id="form-input w-full"
                tw="w-full ring-1 ring-black/5"
                name="input-new-language-name"
                type="text"
                ref={newLanguageNameRef}
                placeholder={'Spanish'}
                // TODO: Add aria invalid attribute
              />
              <div tw="hidden w-full sm:flex">
                <button
                  onClick={() => hideAddLanguageHandler()}
                  tw="ml-auto mt-6 w-full cursor-pointer rounded-md px-5 py-2 font-medium text-indigo-400 transition duration-150 ease-in-out hover:bg-indigo-100 focus:bg-indigo-100 sm:w-auto"
                >
                  Cancel
                </button>
                <button
                  onClick={(e) => addLanguageHandler(e)}
                  tw="ml-2 mt-6 w-full cursor-pointer rounded-md border-2 border-indigo-400 px-5 py-2 font-medium text-indigo-500 transition duration-150 ease-in-out hover:bg-indigo-100 focus:bg-indigo-100 sm:w-auto"
                >
                  Add
                </button>
              </div>
            </div>
            <RadioGroupWrapper>
              <Label htmlFor="language-select-radiogroup">
                Choose the level
              </Label>
              <RadioGroup
                name="language-select-radiogroup"
                onChange={(e) => newLanguageLevelChangeHandler(e)}
                items={LANGUAGE_LEVELS.map((level) => {
                  return {
                    id: level,
                    label: level,
                    value: level,
                  };
                })}
              />
            </RadioGroupWrapper>
          </div>

          <div tw="flex w-full sm:hidden">
            <button
              onClick={() => hideAddLanguageHandler()}
              tw="ml-auto mt-6 w-full cursor-pointer rounded-md px-5 py-2 font-medium text-indigo-400 transition duration-150 ease-in-out hover:bg-indigo-100 focus:bg-indigo-100 sm:w-auto"
            >
              Cancel
            </button>
            <button
              onClick={(e) => addLanguageHandler(e)}
              tw="ml-auto mt-6 w-full cursor-pointer rounded-md border-2 border-indigo-400 px-5 py-2 font-medium text-indigo-500 transition duration-150 ease-in-out hover:bg-indigo-100 focus:bg-indigo-100 sm:w-auto"
            >
              Add
            </button>
          </div>
        </AddLanguageItem>
      )}
    </LanguageSelectWrapper>
  );
};

const LanguageSelectWrapper = styled.ul`
  ${tw`mt-2 rounded-md transition duration-100 ease-in-out`}

  width: calc(100vw - 2rem);

  @media (min-width: 500px) {
    width: calc(100vw - 5.3rem);
    max-width: 100%;
  }
`;

const AddLanguageItem = styled.li`
  ${tw`flex cursor-pointer flex-col items-center justify-center rounded-md`}

  padding: 1.2rem 0 1.2rem 0;
  margin: 1rem 0 0 0;
`;

const RadioGroupWrapper = styled.div`
  margin: 1rem 0 0 0;
  width: 100%;

  @media (min-width: 640px) {
    margin: 0 0 0 2rem;
  }
`;

const LanguageItem = styled.li<{
  selected?: boolean;
}>`
  ${tw`flex cursor-pointer justify-between rounded-md ring-1 ring-black/5 sm:flex-row`}

  padding: 5px;
  margin: 0 0 1rem 0;
  background-color: ${(props) => (props.selected ? '#f4f5f7' : 'transparent')};
  filter: ${(props) => (!props.selected ? 'opacity(25%)' : undefined)};

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.selected ? 'transparent' : '#f4f5f7'};
    filter: unset;
  }

  &:last-of-type {
    margin: 0;
  }
`;

interface ILevelItemProps {
  active: boolean;
}

const LevelItem = styled.li<ILevelItemProps>`
  ${tw`mr-3 rounded-md p-2 text-gray-700`}

  display: inline-block;
  color: ${(props) => (props.active ? '#5145cd' : undefined)};
  background-color: ${(props) => (props.active ? '#E5EDFF' : 'transparent')};

  &:hover,
  &:focus {
    color: #5145cd;
    background-color: #e5edff;
  }

  &:last-of-type {
    margin: 0;
  }

  @media (min-width: 500px) {
    margin: 0 2rem 0 0;
  }
`;

export default LanguageSelect;
