import 'twin.macro';
import Dropdown, { DropdownItem } from 'components/dropdown/Dropdown';
import { EditorState } from 'draft-js';
import React, { useEffect } from 'react';
import { sendTextToEditor } from 'utils/templateHelpers';
import {
  autoUpdate,
  computePosition,
  useFloating,
} from '@floating-ui/react-dom';

export interface AutoSuggestProps {
  editorWrapperRef: React.RefObject<HTMLDivElement>;
  visible: boolean;
  setVisible: (value: boolean) => void;
  suggestions: DropdownItem[];
  editorState: EditorState;
  setEditorState: (value: EditorState) => void;
}

type FloatingUIPosition = { top: number; left: number };

const AutoSuggest: React.FC<AutoSuggestProps> = ({
  editorWrapperRef,
  visible,
  setVisible,
  suggestions,
  editorState,
  setEditorState,
}) => {
  // const ref = useRef<HTMLDivElement>(null);

  const suggestionHandler = (shortCode: string) => {
    setVisible(false);
    sendTextToEditor(shortCode, setEditorState, editorState);
  };

  const { strategy, refs } = useFloating({
    strategy: 'absolute',
    whileElementsMounted: autoUpdate,
  });

  const getCursorPosition = (editorState: EditorState): DOMRect | null => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const node = document.querySelector<HTMLElement>(
      `[data-offset-key="${anchorKey}-0-0"]`
    );

    if (!node) return null;
    const selection = window.getSelection();

    if (!selection || selection.rangeCount === 0) return null;
    const range = selection.getRangeAt(0);
    const rects = range.getClientRects();

    // Check if there are any rects; if not, return the node's bounding box as a fallback
    if (rects.length > 0) return rects[0];
    else return node.getBoundingClientRect();
  };

  const getRelativeCursorPosition = (
    editorNode: HTMLElement,
    editorState: EditorState
  ): FloatingUIPosition | null => {
    const cursorRect = getCursorPosition(editorState);
    if (!cursorRect) return null;

    const editorRect = editorNode.getBoundingClientRect();

    const top = cursorRect.top - editorRect.top + editorNode.scrollTop;
    const left = cursorRect.left - editorRect.left;

    return { top, left };
  };

  function positionFloatingElement(
    editorNode: HTMLElement,
    floatingNode: HTMLElement,
    editorState: EditorState
  ) {
    const position = getRelativeCursorPosition(editorNode, editorState);
    if (!position) return;
    const { top, left } = position;

    computePosition(editorNode, floatingNode, {
      placement: 'top',
      strategy: 'absolute',
    }).then(({ y }) => {
      floatingNode.style.top = `${y + top + 32}px`;
      floatingNode.style.left = `${left + 20}px`;
    });

    setVisible(visible);
  }

  useEffect(() => {
    if (!editorWrapperRef.current) return;
    positionFloatingElement(
      editorWrapperRef.current!,
      refs.floating.current!,
      editorState
    );
  }, [
    editorState,
    editorWrapperRef,
    editorWrapperRef.current?.scrollTop,
    setVisible,
  ]);

  return (
    <div ref={refs.setFloating} tw="z-50" style={{ position: strategy }}>
      {visible && (
        <Dropdown
          items={suggestions}
          onChange={(e) => suggestionHandler(e.toString())}
        />
      )}
    </div>
  );
};

export default AutoSuggest;
