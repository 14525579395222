import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';

interface TestimonialSkeletonProps {}

const TestimonialSkeleton: FC<TestimonialSkeletonProps> = () => {
  return <SkeletonList rows={2} />;
};

export default TestimonialSkeleton;
