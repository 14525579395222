import AppPage from 'components/page/app-page/AppPage';
import React, { FC, useEffect } from 'react';
import 'twin.macro';
import CareerPageBuilder from 'components/careers/page-builder/CareerPageBuilder';
import CareerCta from 'components/careers/career-cta/CareerCta';
import { useParams, useNavigate } from 'react-router-dom';
import { Routes } from 'types/routeTypes';
import useCustomerBrandContext from 'hooks/context/customer-brand-context';
import { PageParams } from 'types/careers-page/context';
import tw from 'twin.macro';
import { CopyToClipboard } from 'components/copy-to-clipboard/CopyToClipboard';
import useCareerPageContext from 'hooks/context/career-page-context';
import { CareerPageStatusType } from 'types/careers-page/types';
import VersionHistory from './VersionHistory';
import { Modal } from 'components/page/app-page/Modal';

const Careers: FC = () => {
  const { customerPageId } = useParams<PageParams>();
  const { customerBrands } = useCustomerBrandContext();
  const {
    careerPageConfig,
    versionHistoryModalOpen,
    setVersionHistoryModalOpen,
    refetchCareerDataResponse,
  } = useCareerPageContext();

  const navigate = useNavigate();

  useEffect(() => {
    const hasBrands = customerBrands && customerBrands.length > 0;
    const firstBrandPageId = customerBrands?.[0]?.page_id || null;

    if (hasBrands && firstBrandPageId) {
      // validate if customerPageId exists in customerBrands
      const brandFromUrl =
        (customerPageId &&
          customerBrands.find(
            ({ page_id }) => (page_id as number) === parseInt(customerPageId)
          )) ||
        null;

      // if there is no page Id, get the first page id from brands
      if (!customerPageId && firstBrandPageId !== customerPageId) {
        navigate(`${Routes.CAREERS}/${firstBrandPageId}`);
      }

      // if with page Id and existing in brands
      if (customerPageId && brandFromUrl) {
        navigate(`${Routes.CAREERS}/${brandFromUrl.page_id}`);
      } else {
        navigate(`${Routes.CAREERS}/${firstBrandPageId}`);
      }
    }
  }, [customerBrands, customerPageId]);

  return (
    <AppPage
      heading={'Careers'}
      cta={<CareerCta />}
      disablePadding
      rightPanelClass={tw`overflow-hidden`}
      clipboardCopy={
        careerPageConfig?.url &&
        careerPageConfig?.branding?.brand_slug &&
        careerPageConfig?.status === CareerPageStatusType.Active && (
          <div tw="w-full max-w-full">
            <CopyToClipboard url={careerPageConfig.url} />
          </div>
        )
      }
    >
      <Modal
        show={versionHistoryModalOpen}
        variant="sideBar"
        twclass={tw`w-[340px] max-w-[340px] ml-auto mx-[unset] h-screen bg-white max-h-[unset]`}
        onClose={() => setVersionHistoryModalOpen(false)}
      >
        <VersionHistory
          pId={customerPageId}
          refetchCareerPage={() => refetchCareerDataResponse()}
          onRestore={() => setVersionHistoryModalOpen(false)}
        />
      </Modal>
      {customerPageId && <CareerPageBuilder />}
    </AppPage>
  );
};

export default Careers;
