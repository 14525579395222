import 'twin.macro';
import React, { useEffect } from 'react';

import PageLayout from 'components/page/page-layout/PageLayout';

import { Link } from 'react-router-dom';
import { fireEvent } from 'utils/eventHelper';
import { Body, Subhead, Title2 } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';
import { Routes } from 'types/routeTypes';

interface PaymentProps {
  variant?: 'success' | 'failed' | 'updated';
}

const Subscription: React.FC<PaymentProps> = ({ variant = 'success' }) => {
  const { refetchCustomer } = useNavigationContext();

  useEffect(() => {
    if (variant === 'success') fireEvent('ss_stripe_pay_subscription');
  }, [variant]);

  useEffect(() => {
    refetchCustomer();
  }, []);

  return (
    <PageLayout>
      <div tw="h-full w-full flex flex-col justify-center items-center">
        {variant === 'success' && (
          <>
            <Title2 isBold={false}>
              You successfully started a new subscription!
            </Title2>
            <Subhead isLight>
              It could take a few minutes for your subscription to show up in
              the app.
            </Subhead>
          </>
        )}
        {variant === 'updated' && (
          <>
            <Title2 isBold={false}>
              You successfully changed your subscription!
            </Title2>
            <Subhead isLight>
              It could take a few minutes for your subscription to show up in
              the app.
            </Subhead>
          </>
        )}
        {variant === 'failed' && (
          <>
            <Body>
              We failed to process your subscription request, please try again.
            </Body>
            <Link to={Routes.SUBSCRIPTION_SETTINGS}>Go to settings</Link>
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default Subscription;
