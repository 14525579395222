import usePageSearchParams from './usePageSearchParams';
import {
  REGISTER_COPY,
  REGISTER_ACADEMY_COPY,
  REGISTER_SEA,
} from 'config/register';

type Page = 'register';

const CONFIGS = {
  register: {
    variant: {
      default: REGISTER_COPY,
      academy: REGISTER_ACADEMY_COPY,
      sea: REGISTER_SEA,
    },
  },
};

/**
 * Gets the configuration for a given page using a given configuration key
 * the value of this key gets extracted from the current page's query params [P].
 * @param  {{page:Page;configKey:string;}} config The page to fetch the configuration for,
 * the configuration key to be used to fetch the configuration data
 * @returns T The type of the configuration object
 */
export default function useGetPageParamsConfig<P, T>(config: {
  page: Page;
  configKey: string;
}): T {
  let pageConfig;
  const { page, configKey } = config;
  const { params } = usePageSearchParams<P>();

  // Get the configuration key from the page search params
  const hasConfigKey = params.has(configKey as keyof P);

  const configKeyValue = hasConfigKey
    ? (params.get(configKey as keyof P) as string)
    : undefined;

  if (CONFIGS.hasOwnProperty(page)) {
    const pageConfigs = CONFIGS[page];

    if (pageConfigs.hasOwnProperty(configKey)) {
      const configValue = (pageConfigs as never)[configKey];

      if (
        configValue &&
        configKeyValue &&
        (configValue as any).hasOwnProperty(configKeyValue)
      ) {
        // Get the specific page configuration
        pageConfig = (configValue as any)[configKeyValue];
      }

      if (!pageConfig) {
        // Get the first item as a fallback configuration
        // in case of no params or an invalid config
        pageConfig = Object.entries(configValue)[0][1];
      }
    }
  }

  return pageConfig as T;
}
