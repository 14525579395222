import { Footnote, Subhead } from 'components/typography/Typography';
import React, { FC } from 'react';
import { Check } from '@phosphor-icons/react';
import 'twin.macro';

const PlanFeatures: FC<{ features: string[] }> = ({ features }) => {
  return (
    <div>
      <Footnote mb={1} isBold isUppercase isLight={false}>
        What's included
      </Footnote>
      <ul tw="space-y-2">
        {features.map((feature) => (
          <li key={feature} tw="flex space-x-3">
            <Check
              weight="bold"
              tw="h-5 w-5 shrink-0 text-green-500"
              aria-hidden="true"
            />
            <Subhead isLight>{feature}</Subhead>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlanFeatures;
