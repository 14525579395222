'use client';
import React, { useMemo } from 'react';
import {
  BarChart,
  CartesianGrid,
  Line,
  Bar,
  LineChart,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartConfig, ChartContainer, ChartTooltip } from 'components/ui/chart';
import 'twin.macro';
import { roundNumber } from 'utils/numberHelpers';
import useReportingContext from 'hooks/context/reporting-context';

interface ChartData {
  [key: string]: number;
}

interface MetricChartProps {
  data: ChartData[];
  title: string;
  type: 'line' | 'bar';
  isPercentage?: boolean;
  isBudget?: boolean;
  labelX?: string;
  labelY?: string;
  width?: number;
}

const chartConfig = {} satisfies ChartConfig;

const ChartElements = {
  line: (
    <Line
      dataKey="value"
      type="natural"
      stroke="#4F46E5"
      strokeWidth={2}
      dot={false}
    />
  ),
  bar: <Bar dataKey="value" fill="#4F46E5" radius={1} />,
};

const MetricChart: React.FC<MetricChartProps> = ({
  title,
  data,
  type,
  isPercentage = false,
  isBudget = false,
}) => {
  const { filters } = useReportingContext();
  // Convert start_date and end_date to Date objects and calculate the total number of months between them
  const startDate = new Date(filters.start_date as string);
  const endDate = new Date(filters.end_date as string);
  const totalMonths = useMemo(() => {
    return (
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth())
    );
  }, [startDate, endDate]);

  const chartData = useMemo(() => {
    if (!data || !filters?.start_date || !filters?.end_date) return [];

    // Convert data object to an array of { date, value } objects
    const dataEntries = Object.entries(data).map(([date, value]) => ({
      date: new Date(date), // Convert date strings to Date objects
      value,
    }));

    // Function to aggregate values by a given interval in weeks, months, or quarters (default interval is 1)
    const aggregateByInterval = (
      interval: number,
      unit: 'weeks' | 'months' | 'quarters'
    ) => {
      const result = [];
      let currentStart = new Date(startDate);
      const end = new Date(endDate);

      while (currentStart <= end) {
        const aggregatedData = { date: new Date(currentStart), value: 0 }; // Create a new bucket
        const nextStart = new Date(currentStart);
        let count = 0;

        // Calculate the next start date based on the interval and unit
        if (unit === 'weeks') {
          nextStart.setDate(currentStart.getDate() + interval * 7);
        } else if (unit === 'months') {
          nextStart.setMonth(currentStart.getMonth() + interval);
        } else if (unit === 'quarters') {
          nextStart.setMonth(currentStart.getMonth() + interval * 3);
        }

        dataEntries.forEach((entry) => {
          if (
            entry.date >= currentStart &&
            entry.date < nextStart &&
            Number(entry.value) !== 0
          ) {
            ['ctr', 'conversion', 'cpm', 'cpc', 'cpa'].includes(
              title.toLowerCase()
            ) && count++;

            aggregatedData.value += Number(entry.value);
          }
        });

        if (count > 0) aggregatedData.value /= count;
        result.push(aggregatedData);
        currentStart = nextStart;
      }

      return result;
    };

    if (totalMonths < 1) {
      const result = [];
      const currentDate = new Date(startDate);
      const dataMap = new Map(
        dataEntries.map((entry) => [
          entry.date.toISOString().split('T')[0],
          entry.value,
        ])
      );

      while (currentDate <= endDate) {
        const formattedDate = currentDate.toISOString().split('T')[0];

        if (dataMap.has(formattedDate)) {
          result.push({
            date: formattedDate,
            value: dataMap.get(formattedDate),
          });
        } else {
          result.push({
            date: formattedDate,
            value: 0,
          });
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return result;
    } else if (totalMonths < 6) {
      // Less than 6 months -> Aggregate data weekly
      return aggregateByInterval(1, 'weeks').map((entry) => ({
        date: entry.date.toISOString().split('T')[0],
        value: entry.value,
      }));
    } else if (totalMonths < 24) {
      // Less than 2 years -> aggregate data monthly
      return aggregateByInterval(1, 'months').map((entry) => ({
        date: entry.date.toISOString().split('T')[0],
        value: entry.value,
      }));
    } else {
      // Aggregate data quarterly
      return aggregateByInterval(1, 'quarters').map((entry) => ({
        date: entry.date.toISOString().split('T')[0],
        value: entry.value,
      }));
    }
  }, [data, filters]);

  const ChartType = type === 'bar' ? BarChart : LineChart;

  const getTootltipLabel = (label: string) => {
    if (totalMonths < 1)
      return new Date(label).toLocaleDateString(undefined, {
        day: '2-digit',
        month: 'short',
        year: undefined,
      });
    else if (totalMonths < 6)
      return new Date(label).toLocaleDateString(undefined, {
        day: '2-digit',
        month: 'short',
        year: undefined,
      });
    else if (totalMonths < 24)
      return new Date(label).toLocaleDateString(undefined, {
        month: 'short',
        year: 'numeric',
      });
    else
      return new Date(label).toLocaleDateString(undefined, {
        month: 'short',
        year: 'numeric',
      });
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div tw="w-fit rounded bg-white px-3 py-2 shadow-md">
          <p tw="text-sm font-bold text-slate-800 dark:text-slate-100">
            {getTootltipLabel(label)}
          </p>
          <div tw="flex justify-between gap-6">
            <p tw="text-xs text-slate-500 dark:text-slate-400">{title}</p>
            <p tw="text-xs text-slate-500 dark:text-slate-400">
              {roundNumber(
                payload[0].value,
                isBudget,
                isPercentage,
                ['applications', 'impressions', 'visits', 'clicks'].includes(
                  title.toLowerCase()
                )
              )}
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  const getTickFormat = (axis: 'X' | 'Y', value: string) => {
    if (axis === 'X') {
      if (totalMonths < 1)
        return new Date(value).toLocaleDateString(undefined, {
          day: '2-digit',
          month: 'short',
          year: undefined,
        });
      else if (totalMonths < 6)
        return new Date(value).toLocaleDateString(undefined, {
          day: '2-digit',
          month: 'short',
          year: undefined,
        });
      else if (totalMonths < 24)
        return new Date(value).toLocaleDateString(undefined, {
          day: undefined,
          month: 'short',
          year: '2-digit',
        });
      else
        return new Date(value).toLocaleDateString(undefined, {
          day: undefined,
          month: 'short',
          year: '2-digit',
        });
    }

    return value;
  };

  return (
    <ChartContainer config={chartConfig} tw="min-h-[110px] w-full">
      <ChartType
        accessibilityLayer
        data={chartData}
        margin={{
          left: window.innerWidth < 1536 ? -24 : -14,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 3" opacity={0.8} />
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={(value) => getTickFormat('X', value)}
        />

        <YAxis
          tick={{ fontSize: 10 }}
          tickFormatter={(value) => {
            if (value >= 1_000_000) {
              return `${(value / 1_000_000).toFixed(1)}M`; // For values over 1 million
            }
            if (value >= 1000) {
              return `${(value / 1000).toFixed(0)}K`; // For values over 1000
            }
            return value;
          }}
        />
        <ChartTooltip cursor={false} content={<CustomTooltip />} />
        {ChartElements[type]}
      </ChartType>
    </ChartContainer>
  );
};

export default MetricChart;
