import React, { useLayoutEffect, useRef } from 'react';
import { Sparkle, UploadSimple } from '@phosphor-icons/react';
import tw, { css, styled } from 'twin.macro';
import { AdPlacement } from 'types/adTypes';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { fabric } from 'fabric';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import { initAligningGuidelines } from 'utils/fabricjs/aligning_guidelines';
import Divider from 'components/divider/Divider';
import useVideoGenContext from 'hooks/context/video-gen-context';

export const AdEditorStencilCanvas: React.FC = () => {
  const {
    currentVariant,
    setActiveTab,
    setUploadMode,
    setCanvas,
    canvasWidth,
    canvasHeight,
  } = useAdEditorContext();

  const svgCanvasRef = useRef<HTMLCanvasElement>(null);

  useLayoutEffect(() => {
    const canvaz = new fabric.Canvas(svgCanvasRef.current, {
      selection: true,
      preserveObjectStacking: true,
    });
    setCanvas(canvaz);
    initAligningGuidelines(canvaz);
  }, []);

  const { videoGen } = useVideoGenContext();

  return (
    <AdImageCanvasWrapper placement={currentVariant?.placement}>
      <AdImageCanvas
        ref={svgCanvasRef}
        height={canvasHeight}
        width={canvasWidth}
        tabIndex={1212}
      />
      {!currentVariant?.path && (
        <div
          id="placeholder"
          tw="h-full w-full flex justify-center absolute z-10 top-0 left-0 bg-gray-200"
        >
          <div tw="my-auto h-fit items-center">
            <div
              onClick={() => {
                setActiveTab(AdEditorSideBarTabs.VISUAL_OPTIONS);
                setUploadMode(true);
              }}
              tw="flex flex-col items-center justify-center text-gray-500 font-semibold cursor-pointer"
            >
              <UploadSimple weight="bold" size={32} tw="my-2" />
              Click here to upload an image/video
            </div>
            <Divider dark />
            <div
              onClick={videoGen}
              tw="flex flex-col items-center mt-2 justify-center text-gray-500 font-semibold cursor-pointer"
            >
              <Sparkle weight="fill" size={32} tw="my-1" />
              Generate a video using AI (beta)
            </div>
          </div>
        </div>
      )}
    </AdImageCanvasWrapper>
  );
};

export const AdImageCanvasWrapper = styled.div<{
  placement?: AdPlacement;
}>`
  ${tw`w-full overflow-hidden relative`}

  ${css`
    *:not(#placeholder *) {
      width: 100% !important;
      height: 100% !important;
    }
  `}

  ${({ placement }) =>
    placement === AdPlacement.FEED
      ? tw`aspect-square`
      : placement === AdPlacement.STORIES
      ? tw`aspect-[9/16]`
      : undefined}
`;

const AdImageCanvas = styled.canvas(
  tw`w-full relative z-10 top-0 left-0 object-fill`
);

export default AdEditorStencilCanvas;
