import React, { FC } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import 'twin.macro';
import { FieldGeneratorProps } from '../FieldGenerator';

interface SelectProps
  extends Pick<
    FieldGeneratorProps,
    'name' | 'label' | 'options' | 'placeholder'
  > {
  errors?: DeepMap<Record<string, any>, FieldError>;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectInput: FC<SelectProps> = (props) => {
  const { name, label, options, errors, value, placeholder, onChange } = props;

  return (
    <>
      <select
        tw="block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        id={name}
        name={name}
        aria-invalid={errors && errors[name] !== undefined}
        defaultValue={value}
        placeholder={
          placeholder ?? (label ? `Enter your ${label}` : `Enter field`)
        }
        style={{ minHeight: 50 }}
        onChange={onChange}
      >
        {options &&
          options.length > 0 &&
          options.map((option, key) => (
            <option value={option.value} key={`${option.value}-${key}`}>
              {option.label ?? option.value}
            </option>
          ))}
      </select>
    </>
  );
};

export default SelectInput;
