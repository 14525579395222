import React, { useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';

import {
  PencilSimple,
  ChartLine,
  ThumbsUp,
  Lightbulb,
  Desktop,
  CurrencyDollar,
  Buildings,
  Star,
} from '@phosphor-icons/react';

import EmojiPicker from '../emoji/EmojiPicker';

export type SymbolPickerProps = {
  value?: Symbol;
  isOpen?: boolean;
  showIcons?: boolean;
  onChange?: (symbol: Symbol) => void;
  onToggle?: () => void;
  bgTransparent?: boolean;
  txtColor?: string;
};

export type Symbol = { node: JSX.Element; key: string };
export type PickerIcons =
  | 'FaPencilAlt'
  | 'FaChartLine'
  | 'FaThumbsUp'
  | 'FaLightbulb'
  | 'FaDesktop'
  | 'FaDollarSign'
  | 'FaBuilding'
  | 'FaStar';

export const getSymbolPickerIcons = (): {
  node: JSX.Element;
  key: PickerIcons;
}[] => {
  const symbolSize = 20;
  return [
    {
      node: <PencilSimple weight="bold" size={symbolSize} />,
      key: 'FaPencilAlt',
    },
    { node: <ChartLine weight="bold" size={symbolSize} />, key: 'FaChartLine' },
    { node: <ThumbsUp weight="bold" size={symbolSize} />, key: 'FaThumbsUp' },
    { node: <Lightbulb weight="bold" size={symbolSize} />, key: 'FaLightbulb' },
    { node: <Desktop weight="bold" size={symbolSize} />, key: 'FaDesktop' },
    {
      node: <CurrencyDollar weight="bold" size={symbolSize} />,
      key: 'FaDollarSign',
    },
    { node: <Buildings weight="bold" size={symbolSize} />, key: 'FaBuilding' },
    { node: <Star weight="bold" size={symbolSize} />, key: 'FaStar' },
  ];
  // return [
  //   { node: <FaPencilAlt size={symbolSize} />, key: 'FaPencilAlt' },
  //   { node: <FaChartLine size={symbolSize} />, key: 'FaChartLine' },
  //   { node: <FaThumbsUp size={symbolSize} />, key: 'FaThumbsUp' },
  //   { node: <FaLightbulb size={symbolSize} />, key: 'FaLightbulb' },
  //   { node: <FaDesktop size={symbolSize} />, key: 'FaDesktop' },
  //   { node: <FaDollarSign size={symbolSize} />, key: 'FaDollarSign' },
  //   { node: <FaBuilding size={symbolSize} />, key: 'FaBuilding' },
  //   { node: <FaStar size={symbolSize} />, key: 'FaStar' },
  // ];
};

export const SymbolPicker: React.FC<SymbolPickerProps> = ({
  value,
  isOpen,
  showIcons = false,
  onChange,
  onToggle,
  bgTransparent = false,
  txtColor = '#000',
}) => {
  const icons = useMemo(() => getSymbolPickerIcons(), []);

  const changeHandler = (symbol: Symbol) => {
    onChange?.(symbol);
  };

  return (
    <div tw="flex flex-col">
      <BgColor
        bgTransparent={bgTransparent}
        txtColor={txtColor}
        onClick={() => onToggle?.()}
      >
        <div tw="relative h-4 w-full h-full">
          {value?.node ?? value?.key ?? '🦄'}
        </div>
      </BgColor>
      {isOpen && (
        <Wrapper>
          <EmojiPicker
            onChange={({ emoji }) =>
              changeHandler({ key: emoji, node: <>{emoji}</> })
            }
          />
          {showIcons && (
            <ul tw="flex flex-row">
              {icons.map((icon, index) => (
                <li
                  tw="text-gray-500 h-1/4 w-1/2 cursor-pointer p-1"
                  key={index}
                  onClick={() => changeHandler(icon)}
                >
                  {icon.node}
                </li>
              ))}
            </ul>
          )}
        </Wrapper>
      )}
    </div>
  );
};

const Wrapper = styled.div(
  tw`
    flex flex-col bg-white rounded-md p-2 border-2 z-50 shadow min-w-[312px] w-0 sm:w-auto
  `
);

const BgColor = styled.div<{
  bgTransparent?: boolean;
  txtColor: string;
}>`
  ${tw`
    flex justify-center items-center bg-white rounded-md text-white cursor-pointer hover:(ring-4 ring-indigo-400 rounded-sm) focus:(ring-4 ring-indigo-400 rounded-sm)
  `}
  ${({ bgTransparent }) => bgTransparent && tw`bg-transparent border-none`}

   ${({ txtColor }) =>
    css`
      color: ${txtColor};
    `}
`;

export default SymbolPicker;
