import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { RouteGuards, Routes } from 'types/routeTypes';
import { getCurrentAuthToken } from 'utils/authHelpers';

const GuardedRoute: React.FC<{
  guard: RouteGuards;
  children: React.ReactNode;
}> = ({ children, guard }) => {
  const getRedirectPath = (
    path: string,
    paramString: string
  ): string | null => {
    const authenticated = getCurrentAuthToken();

    // Authentication flow
    if (authenticated && isAuthFlowPath(path)) {
      if (path.includes(Routes.CONFIRM_PASSWORD)) {
        // Allows users to use their password reset link to visit an intake
        const hash = getConfirmPasswordHash(paramString);
        return hash ? `${Routes.INTAKE}/${hash}` : Routes.ROOT;
      } else return Routes.ROOT;
    } else if (!authenticated && isAuthFlowPath(path)) {
      return null;
    }

    // Root-level flow
    if (authenticated && path === Routes.ROOT) {
      return null;
    } else if (!authenticated && path === Routes.ROOT) {
      return Routes.LOGIN;
    }

    // Basic route flow
    if (!authenticated && guard === RouteGuards.AUTHENTICATED) {
      localStorage.setItem(
        'referrer',
        window.location.pathname + window.location.search
      );
      return Routes.LOGIN;
    }

    return null;
  };

  const getQueryParams = (paramString: string) => {
    return paramString
      .substr(1, paramString.length - 1)
      .split('&')
      .map((param) => param.split('='))
      .map((paramKvp) => ({
        key: paramKvp[0],
        value: paramKvp[1],
      }));
  };

  const getConfirmPasswordHash = (paramString: string) => {
    return getQueryParams(paramString).find((param) => param.key === 'hash')
      ?.value;
  };

  const isAuthFlowPath = (path: string) => {
    return (
      path.includes(Routes.LOGIN) ||
      path.includes(Routes.FORGOT_PASSWORD) ||
      path.includes(Routes.CONFIRM_PASSWORD) ||
      path.includes(Routes.REGISTER) ||
      path.includes(Routes.DEMO)
    );
  };

  const pathname = getRedirectPath(
    window.location.pathname,
    window.location.search
  );
  if (pathname !== null) return (<Navigate to={pathname} />) as ReactElement;
  return (<>{children}</>) as ReactElement;
};

export default GuardedRoute;
