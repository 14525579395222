import React, { useEffect } from 'react';
import { Title2 } from '../../components/typography/Typography';
import 'twin.macro';
import { useQuery } from 'hooks/sympl-query';
import { User } from 'types/userTypes';
import useCareerPageContext from 'hooks/context/career-page-context';
import { GET_VERSION_HISTORY } from 'graphql/careers/queries';
import tw from 'twin.macro';
import { ClockCounterClockwise } from '@phosphor-icons/react';
import ToolTip from 'components/tooltip/ToolTip';
import { useMutation } from 'hooks/sympl-mutation';
import { RESTORE_VERSION_HISTORY } from 'graphql/careers/mutations';

interface VersionHistoryItem {
  id: number;
  isCurrent: boolean;
  timestamp: string;
  user: User;
}

const VersionHistory: React.FC<{
  pId?: string;
  refetchCareerPage: () => void;
  onRestore: () => void;
}> = ({ pId, refetchCareerPage, onRestore }) => {
  const { careerPageConfig, versionHistoryModalOpen } = useCareerPageContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageId, _] = React.useState<string>(pId!);
  const [versionHistory, setVersionHistory] = React.useState<
    VersionHistoryItem[]
  >([]);

  const { loading: historyIsLoading, refetch } = useQuery<
    {
      versionHistory: {
        history: VersionHistoryItem[];
      };
    },
    { pageId: string }
  >(GET_VERSION_HISTORY, {
    skip: !careerPageConfig || !versionHistoryModalOpen || !pageId,
    variables: { pageId: `${pageId}` },
    onCompleted: (data) => setVersionHistory(data.versionHistory.history),
  });

  useEffect(() => {
    refetch();
  }, []);

  const [restoreVersionHistory] = useMutation<
    { createVersionHistory: { history: VersionHistoryItem[] } },
    { pageId: number; input: {}; historyId?: number }
  >(RESTORE_VERSION_HISTORY);

  const restoreHistoryPoint = (id: number) => {
    restoreVersionHistory({
      variables: {
        pageId: +pageId,
        input: {},
        historyId: id,
      },
    }).then(() => {
      onRestore();
      refetchCareerPage();
    });
  };

  const formatDate = (date: string) => {
    const d = new Date(date);
    const today = new Date();
    const isToday =
      d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear();

    return isToday
      ? `Today, ${d.getHours()}:${d.getMinutes()}`
      : `${d.toLocaleString('default', {
          month: 'short',
        })} ${d.getDate()}, ${d.getFullYear()} - ${d.getHours()}:${String(
          d.getMinutes()
        ).padStart(2, '0')}`;
  };

  if (historyIsLoading)
    return (
      <div tw="h-full flex flex-col gap-8">
        <Title2 mt={1}>Version History</Title2>
        <div tw="w-full text-center">
          <p>Loading...</p>
        </div>
      </div>
    );

  return (
    <div tw="h-full flex flex-col gap-8">
      <Title2 mt={1}>Version History</Title2>

      {!versionHistory.length ? (
        <div tw="w-full text-center">
          <p>There are no previous versions yet.</p>
        </div>
      ) : (
        <div tw="flex flex-col-reverse gap-4 w-full">
          {versionHistory.map((item) => (
            <div
              key={item.id}
              className="group"
              tw="flex items-center justify-between py-2 px-3 rounded-md border-2"
              css={[
                item.isCurrent
                  ? tw`bg-indigo-50 border-indigo-200 hover:(bg-indigo-100 border-indigo-300)`
                  : tw`bg-gray-100 border-gray-200 hover:(bg-gray-200 border-gray-300)`,
              ]}
            >
              <div tw="flex gap-4 items-center">
                {/* Avatar */}
                {/* TODO: Change to user avatar when added - 23/10/2024 */}
                <div tw="w-9 h-9 rounded-full bg-white grid place-items-center text-base">
                  {item.user.firstname.charAt(0)}
                  {item.user.lastname.charAt(0)}
                </div>

                {/* Text */}
                <div>
                  <p>{formatDate(item.timestamp)}</p>
                  <p tw="text-sm">{`${item.user.firstname} ${item.user.lastname}`}</p>
                </div>
              </div>

              {/* actions (set this verison back) */}
              {!item.isCurrent && (
                <ToolTip text="Restore this version" placement="bottom" arrow>
                  <ClockCounterClockwise
                    tw="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity"
                    weight="regular"
                    size={20}
                    onClick={() => restoreHistoryPoint(item.id)}
                  />
                </ToolTip>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VersionHistory;
