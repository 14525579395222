import { ChatGPTMessage } from 'utils/openAiHelpers';

const baseMessage = (
  language: string,
  type: 'ad' | 'job_posting' = 'job_posting'
): ChatGPTMessage => {
  return {
    role: 'assistant',
    content: `You are a helpful system that gets an English request, which might contain some snippets in other languages. But you always respond in ${language},
        Act as a recruiter.
        ${
          type === 'job_posting'
            ? 'You write a part of a job posting.'
            : 'You write a recruitment ad.'
        }`,
  };
};

const emojiMessage: ChatGPTMessage = {
  role: 'assistant',
  content:
    'Most sentences should contain an emoji, not at the start of the sentence.',
};

const formatMessage = (): ChatGPTMessage => {
  return {
    role: 'assistant',
    content: `Create a bullet point list.
        4 or 5 bullet points.
        Keep it concise.
        Start each bullet point with ✔️.
        Do not use markdown notations for bold or italic text.
        ${emojiMessage}`,
  };
};

const contextMessage = (
  payload: VacancyAIPromptPayloadBase
): ChatGPTMessage => {
  return {
    role: 'assistant',
    content: `Context: The job posting is for the job of ${
      payload.jobFunction
    } at ${payload.companyName}.
        ${payload.companyName} is located in ${payload.location}.
        Some info about ${payload.companyName}: ${payload.companyDescription}
        Required experience level: ${payload.experienceLevel}
        ${
          payload.offers
            ? 'This might convince the candidates ' + payload.offers?.join(', ')
            : 'Look at the previous text to see what might convince the candidates'
        }
        The tone of voice of the text should be ${payload.toneOfVoice}`,
  };
};

export type VacancyAIPromptPayload = VacancyAIPromptPayloadBase & {
  jobCategory: string;
};

type VacancyAIPromptPayloadBase = {
  language: string;
  jobFunction: string;
  companyName: string;
  location: string;
  companyDescription: string;
  experienceLevel: string;
  toneOfVoice: string;
  offers: string[];
};

export const generatePromptEssence = (
  payload: VacancyAIPromptPayloadBase
): Array<ChatGPTMessage> => [
  baseMessage(payload.language),
  contextMessage(payload),
  {
    role: 'assistant',
    content: `The introductory should be a single paragraph with 4-5 sentences.,
        The paragraph should start with some questions towards the candidate and end with a sentence inviting the candidate to apply.,
        ${emojiMessage.content},
        The paragraph should not contain a description of the company.,
        Write the introductory text of a job posting for the job: ${payload.jobFunction}`,
  },
];

/*
  Job Industry: Gezondheidssector,
  Job Function: Accountant,
  Company Name: <Company name>
  Location: <Company location>,


  Job Industry: Sales,
  Job Function: Account Manager,
  Company Name: <Company name>,
  Location: South of the Netherlands,
  */
export const generatePromptQualities = (payload: {
  jobFunction: string;
  companyName: string;
  location: string;
  companyDescription: string;
  experienceLevel: string;
  toneOfVoice: string;
  offers: string[];
  language: string;
  jobCategory: string;
}): ChatGPTMessage[] => [
  {
    role: 'assistant',
    content: `
    Create 3 ${payload.language} lines that highlight the benefits the position entails. Use a bulleted list with an emoji at the beginning of every line. Write it in ${payload.language}.
    Here are some examples:

    Company Description: <Company name> is gespecialiseerd in de invoer, distributie en commercialisering van medisch materiaal in West-Europa en voornamelijk actief in drie regio's: Benelux, Frankrijk en de Duitstalige landen. Deze Belgische onderneming levert (in)direct aan ziekenhuizen, medische speciaalzaken, apotheken, laboratoria en rust- en verzorgingsinstellingen. Kortom: een belangrijke speler in de internationale gezondheidssector!,
    Tone Of Voice formeel,

    Qualities:
    💡 Afwisselende job
    🏢 Goed bereikbaar kantoor
    ⭐ Internationale werkomgeving

    Company Description: At the head of <Company name> is the holy trinity: Peter, Jos and Jef. These three partners in crime built an honest, genuine and quality wine business. <Company name> not only makes its own wine at the Valke Vleug estate in Liezele, Flanders, they also import cool-climate wines from all over the globe. 🍇,
    Tone Of Voice informal,

    Qualities:
    👍 Combining your job with your passion
    💡 Responsible for expansion into the Netherlands
    ⭐ Part of an edgy team

    Your job is to output the "Qualities" part, given the user input. Don't write the word "Qualities", only respond with the qualities.
    `,
  },
  {
    role: 'user',
    content: `Job Category: ${payload.jobCategory}
    Job Function: ${payload.jobFunction}
    Company: ${payload.companyName}
    Location: ${payload.location}
    Company Description: ${payload.companyDescription}
    Experience level: ${payload.experienceLevel}
    Tone Of Voice: ${payload.toneOfVoice}
    Offers: ${payload.offers?.join(', ')}
  `,
  },
];

export const generatePromptRequirements = (
  payload: VacancyAIPromptPayloadBase
): Array<ChatGPTMessage> => [
  baseMessage(payload.language),
  formatMessage(),
  contextMessage(payload),
  {
    role: 'user',
    content: `What requirements(for example education, experience, soft skills, hard skills) are needed for the job of ${payload.jobFunction} at ${payload.companyName}?`,
  },
];

export const generatePromptTasks = (
  payload: VacancyAIPromptPayloadBase
): Array<ChatGPTMessage> => [
  baseMessage(payload.language),
  formatMessage(),
  contextMessage(payload),
  {
    role: 'user',
    content: `What are daily tasks for the job of ${payload.jobFunction} at ${payload.companyName}?"`,
  },
];

export const generatePromptOffer = (
  payload: VacancyAIPromptPayloadBase
): Array<ChatGPTMessage> => [
  baseMessage(payload.language),
  formatMessage(),
  contextMessage(payload),
  {
    role: 'user',
    content: `What are the perks and benefits for the job of ${payload.jobFunction} at ${payload.companyName}?"`,
  },
];

export const generatePromptAbout = (
  payload: VacancyAIPromptPayloadBase
): Array<ChatGPTMessage> => [
  baseMessage(payload.language),
  emojiMessage,
  contextMessage(payload),
  {
    role: 'user',
    content: `Write a paragraph introducing the company (${payload.companyName}).`,
  },
];

export const generatePromptEssenceShort = (payload: {
  language: string;
  companyName: string;
  jobFunction: string;
  location: string;
}): Array<ChatGPTMessage> => [
  {
    role: 'assistant' as const, // const needed for type inference
    content: `You are a writing assistant that helps recruiters write recruitment ads.
          Maximum 5 lines. Start with 3 questions to trigger the interest of candidates in the job. Start each question with one emoji.
          Finish with a call-to-action for candidates to apply, it contains the location and company name. Don't mention email addresses, or "send CV" in the call-to-action. Keep it concise. Don't use hashtags.
          The user will specify the language, company name, job title and location.`,
  },
  {
    role: 'user' as const, // const needed for type inference
    content: JSON.stringify({
      // Content needs to be: location, company name, job title, language
      location: payload?.location,
      company: payload?.companyName,
      job_title: payload?.jobFunction,
      language: payload?.language,
    }),
  },
];

export const generatePromptKeywords = (): ChatGPTMessage => ({
  role: 'user' as const,
  content: `Give me a list of the most important words of this text. The list should include approximately 10 words.`,
});
