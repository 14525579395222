import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IntakeInputTypes, IntakeQuestion } from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';
import Input from 'components/form/input/Input';

const IntakeInput: React.FC<{
  question: IntakeQuestion;

  defaultValue?: string;
}> = ({ question, defaultValue }) => {
  const { control, errors } = useFormContext();

  const [value, setValue] = useState(defaultValue ?? '');

  const id = useMemo(() => question.id.toString(), [question.id]);
  const rules = useMemo(() => questionRules(question), [question]);
  const isArea = useMemo(
    () => question.type === IntakeInputTypes.AREA,
    [question.type]
  );

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget?.value;
    if ((!val && val !== '') || value === val) return;
    setValue(val);
  };

  const placeholder =
    question?.placeholder && question?.placeholder.length > 0
      ? question.placeholder
      : undefined;

  return (
    <Controller
      id={id}
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange }: { onChange: any }) => (
        <Input
          id={id}
          name={id}
          textarea={isArea}
          rows={isArea ? 4 : undefined}
          value={value}
          placeholder={placeholder}
          ariaInvalid={errors[id] !== undefined}
          onChange={(e) => changeHandler(e)}
          onBlur={() => onChange(value)}
        />
      )}
    />
  );
};

export default IntakeInput;
