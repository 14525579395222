import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';

interface TeamSkeletonProps {}

const TeamSkeleton: FC<TeamSkeletonProps> = () => {
  return <SkeletonList rows={2} />;
};

export default TeamSkeleton;
