import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';

interface FeatureSkeletonProps {}

const FeatureSkeleton: FC<FeatureSkeletonProps> = () => {
  return <SkeletonList rows={2} />;
};

export default FeatureSkeleton;
