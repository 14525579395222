import React from 'react';

import { RouteGuards } from 'types/routeTypes';

import GuardedRoute from 'routing/GuardedRoute';
import Careers from './Careers';
import { CareerPageProvider } from 'context/CareerPageContext';
import { CustomerBrandProvider } from 'context/CustomerBrandContext';
import { Route, Routes } from 'react-router-dom';

const CareersRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path=":customerPageId"
        element={
          <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
            <CareerPageProvider>
              <CustomerBrandProvider>
                <Careers />
              </CustomerBrandProvider>
            </CareerPageProvider>
          </GuardedRoute>
        }
      />
      <Route
        path="/"
        element={
          <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
            <CareerPageProvider>
              <CustomerBrandProvider>
                <Careers />
              </CustomerBrandProvider>
            </CareerPageProvider>
          </GuardedRoute>
        }
      />
    </Routes>
  );
};

export default CareersRouter;
