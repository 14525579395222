import tw, { styled } from 'twin.macro';
import React from 'react';
import { Body } from 'components/typography/Typography';

export interface BudgetSelectorProps {
  id: string;
  currentValue: number;
  options: number[];
  allowCustomValue?: boolean;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
}

const BudgetSelector: React.FC<BudgetSelectorProps> = ({
  options,
  currentValue,
  onChange,
  allowCustomValue = false,
  minValue = 0,
  maxValue,
}) => {
  return (
    <BudgetItemGroup>
      {options.map((option: number) => (
        <BudgetItem
          key={option}
          highlight={currentValue === option}
          onClick={() => onChange(option)}
        >
          {`€ ${option.toLocaleString()}`}
        </BudgetItem>
      ))}

      {allowCustomValue && (
        <BudgetItem highlight={!options.includes(currentValue)}>
          <div tw="flex gap-2">
            <Body isBlock={false}>€</Body>
            <BudgetInputField
              tw="outline-none"
              type="number"
              step="0.01"
              min={minValue}
              {...(maxValue && maxValue > minValue && { max: maxValue })}
              defaultValue={!options.includes(currentValue) ? currentValue : ''}
              onChange={(e) =>
                onChange(Math.round(parseFloat(e.target.value)) || currentValue)
              }
            />
          </div>
        </BudgetItem>
      )}
    </BudgetItemGroup>
  );
};

const BudgetItemGroup = styled.div`
  ${tw`flex flex-wrap gap-4 mt-1`}
`;

const BudgetInputField = styled.input`
  ${tw`outline-none bg-transparent`}
`;

const BudgetItem = styled.div<{ highlight: boolean }>`
  ${tw`ring-2 ring-gray-200 py-3 px-6 rounded cursor-pointer`}
  ${({ highlight }) => highlight && tw`ring-2 ring-green-300 bg-green-50`}
`;

export default BudgetSelector;
