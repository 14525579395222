import { DropdownItem } from 'components/dropdown/Dropdown';
import MultiSelectDropdown from 'components/dropdown/multiselect-dropdown/MultiSelectDropdow';
import React from 'react';
import { ReportingFilterTypes } from 'views/reporting/Reporting';

interface ReportingFilterProps {
  title: string;
  type: ReportingFilterTypes;
  items: DropdownItem[];
  value: DropdownItem[];
  onChange: (
    type: ReportingFilterTypes,
    selected: Array<string | number>
  ) => void;
}

const ReportingFilter: React.FC<ReportingFilterProps> = ({
  title,
  type,
  items,
  value,
  onChange,
}) => {
  const handleChange = (value: DropdownItem[]) => {
    const filterKeys = value.map((val) => val.key);
    onChange?.(type, filterKeys);
  };

  return (
    <MultiSelectDropdown
      items={items}
      title={title}
      value={value}
      onChange={(value) => handleChange(value!)}
    />
  );
};

export default ReportingFilter;
