import Dropdown from 'components/dropdown/Dropdown';
import React, { FC } from 'react';
import 'twin.macro';
import { useParams, useNavigate } from 'react-router-dom';
import { PageParams } from 'types/careers-page/context';
import { Routes } from 'types/routeTypes';
import useCustomerBrandContext from 'hooks/context/customer-brand-context';

const CustomerDropdown: FC = () => {
  const navigate = useNavigate();
  const { customerPageId } = useParams<PageParams>();
  const { customerBrands, loadingBrands } = useCustomerBrandContext();

  return (
    <div tw="flex items-center flex-row justify-start flex-nowrap w-auto">
      {customerBrands && customerBrands.length > 1 && (
        <div tw="ml-4">
          <Dropdown
            label={'Select a brand'}
            {...(customerPageId && { defaultValue: parseInt(customerPageId) })}
            items={customerBrands.map(({ page_id, name }) => ({
              key: parseInt(page_id as string),
              label: name,
            }))}
            onChange={(page_id) => {
              navigate(`${Routes.CAREERS}/${page_id}`);
            }}
            loading={loadingBrands}
            noResultsLabel={'No results found or create new brand'}
            enableCustom
          />
        </div>
      )}
    </div>
  );
};

export default CustomerDropdown;
