import { Modal } from '../../components/page/app-page/Modal';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import Title from 'components/title/Title';
import { CREATE_FACEBOOK_PAGE } from 'graphql/facebook-pages/mutations';
import { useToastNotifications } from 'hooks/notificationHooks';
import React, { useState } from 'react';
import { useMutation } from 'hooks/sympl-mutation';
import { ToastTypes } from 'types/notificationTypes';
import 'twin.macro';
import { Footnote } from 'components/typography/Typography';
import tw from 'twin.macro';
import ToolTip from 'components/tooltip/ToolTip';
import { fireEvent } from 'utils/eventHelper';

interface FacebookModalProps {
  brandId: number;
  onComplete: () => void;
}

interface CreateFacebookPagePayload {
  brandId: number;
  input: {
    channel_id: string;
  };
}

const FacebookPageModal: React.FC<FacebookModalProps> = ({
  brandId,
  onComplete,
}) => {
  const [showFacebookPageModal, setShowFacebookPageModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [facebookPage, setFacebookPage] = useState<string>('');
  const { addToast } = useToastNotifications();

  const [createFacebookPage, { loading }] = useMutation<
    {
      updateFacebookPage: {
        sessionId?: string;
      };
    },
    CreateFacebookPagePayload
  >(CREATE_FACEBOOK_PAGE);

  const handleNewFacebookPage = () => {
    createFacebookPage({
      variables: {
        brandId,
        input: {
          channel_id: facebookPage,
        },
      },
    }).then(() => {
      setShowFacebookPageModal(false);
      setFacebookPage('');
      addToast({
        description: `Your Facebook page was successfully created.`,
        type: ToastTypes.SUCCESS,
      });
      fireEvent('request_fb_page_connection', {
        brand: brandId,
        channel_id: facebookPage,
      });

      onComplete();
    });
  };

  return (
    <>
      <Button onClick={() => setShowFacebookPageModal(true)}>
        Add Facebook page
      </Button>
      <Modal
        show={showFacebookPageModal}
        onClose={() => {
          setShowFacebookPageModal(false);
          setFacebookPage('');
        }}
      >
        <Title tw="mb-6 w-96">Connect your Facebook page</Title>
        <div>
          <ToolTip
            text="A facebook page id consists of only numbers"
            visible={showTooltip}
            placement="top"
            arrow
          >
            <Input
              id="facebookPage"
              placeholder="Enter a valid Facebook page id (e.g. 1166895576701130)"
              value={facebookPage}
              onChange={(e) => {
                const validCharacters = /^[0-9]+$/;
                if (!validCharacters.test(e.target.value as string)) {
                  e.target.value = e.target.value.replace(/\D/g, '');
                  if (!showTooltip) {
                    setShowTooltip(true);
                    setTimeout(() => {
                      setShowTooltip(false);
                    }, 2000);
                  }
                }
                setFacebookPage(e.target.value);
              }}
            />
          </ToolTip>
          <a
            tw="mt-2 block"
            href="https://intercom.help/sympl-a27647b3ab23/en/articles/9215996-can-i-advertise-through-our-own-social-media-pages"
            target="_blank"
          >
            <Footnote isBold>Where can I find this id?</Footnote>
          </a>
          <Button
            onClick={handleNewFacebookPage}
            loading={loading}
            customStyle={tw`mt-2 float-right`}
          >
            Request connection
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default FacebookPageModal;
