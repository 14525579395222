import React from 'react';

const Privacy = () => (
  <iframe
    width="100%"
    height="100%"
    src="https://gosympl.com/privacy-statement"
  ></iframe>
);

export default Privacy;
