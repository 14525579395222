import dayjs from 'dayjs';
import {
  Interval,
  Subscription,
  Tier,
  TIER_RANKS,
} from 'types/subscriptions/types';

export const convertToMonthPrice = (
  amount: number,
  interval: Interval
): number => {
  let monthPrice = amount;

  if (interval === Interval.QUARTERLY) monthPrice /= 3;
  if (interval === Interval.TWO_QUARTERLY) monthPrice /= 6;
  if (interval === Interval.THREE_QUARTERLY) monthPrice /= 9;
  if (interval === Interval.YEARLY) monthPrice /= 12;

  return Math.round(monthPrice);
};

export const isUpgradeDowngrade = (
  currentTier: Tier | undefined,
  newTier: Tier
): 'Upgrade' | 'Downgrade' => {
  if (currentTier === undefined) return 'Upgrade';

  const currentRank = TIER_RANKS[currentTier];
  const newRank = TIER_RANKS[newTier];

  if (newRank > currentRank) return 'Upgrade';
  if (newRank < currentRank) return 'Downgrade';

  return 'Upgrade'; // Could be changed to 'Same' at some point
};

export const daysLeft = (subscription: Subscription) =>
  dayjs(subscription.trial_ends).fromNow(true);

export const isWTTrial = (subscription: Subscription) =>
  subscription.is_trial && subscription.payment_method === 'WIRE_TRANSFER';
