import { FeedbackStatus } from 'components/feedback/FeedbackWidget';
import useGetStartedContext from 'hooks/context/get-started-context';
import useNavigationContext from 'hooks/context/nav-context';
import React, { useMemo } from 'react';
import { CheckCircle, WarningCircle } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';

import tw, { styled } from 'twin.macro';

const SVG_CIRCLE = (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M 21 12 C 21 18.928 13.5 23.258 7.5 19.794 C 4.715 18.187 3 15.215 3 12 C 3 5.072 10.5 0.742 16.5 4.206 C 19.285 5.813 21 8.785 21 12 Z"
    />
  </svg>
);

const MenuLink: React.FC<{
  to: {
    pathname: string;
    search: string;
  };
  isActive?: boolean;
  isCampaign: boolean;
  isDisabled?: boolean;
  isCompleted?: boolean;
  feedbackStatus?: FeedbackStatus;
  icon: React.ReactNode;
  label: string;
}> = ({
  to,
  isActive = false,
  isDisabled = false,
  isCampaign,
  isCompleted,
  feedbackStatus,
  icon,
  label,
}) => {
  const { hasVacancies, vacIsBooster, vacIsPublished, vacancyLoading } =
    useNavigationContext();
  const { checklist, checklistLoading } = useGetStartedContext();

  const isPhase1 = useMemo(() => {
    const feedbackList = checklist.filter((item) => item.feedback_status);

    if (!vacIsBooster || !feedbackList.length) return false;

    return (
      feedbackList.filter(
        ({ feedback_status }) => feedback_status === FeedbackStatus.IN_PROGRESS
      ).length === feedbackList.length
    );
  }, [checklist, vacIsBooster]);

  return (
    <Container
      isActive={isActive}
      isCompleted={!vacIsPublished && !!isCompleted}
    >
      <div tw="text-sm flex justify-between place-items-center">
        <Link
          to={to}
          tw="flex w-full justify-between p-2.5"
          css={[isDisabled && tw`pointer-events-none opacity-50`]}
        >
          <div tw="flex items-center pr-3" css={[isCompleted && tw`-ml-px`]}>
            {!vacancyLoading &&
              !vacIsPublished &&
              !!checklist.length &&
              isCompleted === false &&
              [FeedbackStatus.APPROVED, FeedbackStatus.NO_STATUS].includes(
                feedbackStatus ?? FeedbackStatus.NO_STATUS
              ) &&
              !isPhase1 && <div tw="-ml-px">{SVG_CIRCLE}</div>}

            {!vacancyLoading &&
              !vacIsPublished &&
              isCampaign &&
              feedbackStatus === FeedbackStatus.APPROVAL_NEEDED && (
                <WarningCircle
                  weight="bold"
                  color={'darkorange'}
                  style={{
                    opacity: 0.8,
                    marginLeft: '-1px',
                  }}
                  size={20}
                />
              )}
            {isCampaign && !vacIsPublished && isCompleted && (
              <CheckCircle weight="bold" color={'limegreen'} size={20} />
            )}
            {(checklistLoading ||
              vacancyLoading ||
              vacIsPublished ||
              !hasVacancies ||
              (!isCompleted && !isCampaign)) && <>{icon}</>}
            <div tw="flex flex-col">
              <span
                css={[
                  vacancyLoading ||
                  vacIsPublished ||
                  feedbackStatus === undefined
                    ? tw`ml-[10px]`
                    : tw`ml-[9px]`,
                ]}
              >
                {label}
              </span>
              {!checklistLoading &&
                vacIsBooster &&
                !vacIsPublished &&
                !isCompleted &&
                isCampaign && (
                  <Pill>
                    {(feedbackStatus &&
                    feedbackStatus !== FeedbackStatus.IN_PROGRESS
                      ? feedbackStatus
                      : 'WAIT_FOR_SYMPL'
                    ).replaceAll('_', ' ')}
                  </Pill>
                )}
            </div>
          </div>
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.div<{
  isActive: boolean;
  isCompleted: boolean;
}>`
  ${tw`font-medium w-full cursor-pointer rounded-md hover:(text-gray-800 bg-gray-100 [svg]:text-gray-800)`}
  ${({ isActive }) =>
    isActive
      ? tw`text-gray-800 bg-gray-100 [svg]:text-gray-800`
      : tw`text-gray-500 [svg]:text-gray-400`}
  ${({ isCompleted }) =>
    isCompleted &&
    tw`text-[rgb(50, 205, 50)] bg-[rgba(50, 205, 50, 0.1)] hover:(text-[rgb(50, 205, 50)] bg-[rgba(50, 205, 50, 0.25)])`}
`;

const Pill = styled.span(
  tw`px-2 w-fit mt-1 ml-2 bg-gray-100 border rounded-full text-[0.6rem]`
);

export default MenuLink;
