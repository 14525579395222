import { EditorState } from 'draft-js';

import { SelectableLanguage } from 'components/form/LanguageSelect';
import { Coworker } from 'types/formTypes';
import {
  BASE_SELECTED_LANGUAGES,
  IntakeQuestion,
  LANGUAGE_LEVELS,
  SelectedLanguage,
} from 'types/intakeTypes';
import { isJsonString } from './baseHelpers';
import {
  getSymbolPickerIcons,
  PickerIcons,
} from 'components/form/symbol/SymbolPicker';
import { FileUploadsWithText } from 'types/fileTypes';
import { CustomerAddress } from 'types/geolocationTypes';

const isStringArray = (value: any): boolean => {
  if (value === undefined || value.constructor !== Array) return false;
  (value as any[]).forEach((v) => {
    if (typeof v !== 'string') return false;
  });
  return true;
};

export const canBeParsedAsJson = (value: any) => {
  return (
    value &&
    value.constructor === String &&
    value !== '' &&
    isJsonString(value.toString())
  );
};

function isSelectedLanguageArray(
  questionValue: IntakeQuestion['value']
): questionValue is SelectedLanguage[] {
  if (!questionValue) return false;

  try {
    (questionValue as SelectedLanguage[]).forEach((val) => {
      if (val.name.constructor !== String || !val.level) return false;
    });

    return true;
  } catch (_error) {
    return false;
  }
}

export function isEditorState(obj: any): obj is EditorState {
  try {
    return obj ? (obj as EditorState).toJS() !== undefined : false;
  } catch (_error) {
    return false;
  }
}

function isFileUploadWithText(
  questionValue: IntakeQuestion['value']
): questionValue is FileUploadsWithText {
  if (!questionValue) return false;

  try {
    const val = questionValue as FileUploadsWithText;

    if (
      (val.text && val.text.constructor !== String) ||
      (val.files && val.files.constructor !== Array) ||
      (!val.text && !val.files)
    )
      return false;

    return true;
  } catch (_error) {
    return false;
  }
}

function isCoworkerArray(
  questionValue: IntakeQuestion['value']
): questionValue is Coworker[] {
  if (!questionValue) return false;

  try {
    (questionValue as Coworker[]).forEach((val) => {
      if (
        val.email.constructor !== String ||
        val.firstname.constructor !== String ||
        val.function.constructor !== String ||
        val.isLeadRecruiter.constructor !== Boolean ||
        val.lastname.constructor !== String
      )
        return false;
    });

    return true;
  } catch (_error) {
    return false;
  }
}

export function isCustomerAddress(
  questionValue: IntakeQuestion['value']
): questionValue is CustomerAddress {
  if (!questionValue) return false;

  try {
    const val = questionValue as CustomerAddress;

    if (
      (val.city && val.city.constructor !== String) ||
      (val.country_code && val.country_code.constructor !== String) ||
      (val.house_number && val.house_number.constructor !== String) ||
      (val.road && val.road.constructor !== String) ||
      (val.box_number && val.box_number.constructor !== String) ||
      (val.zip_code && val.zip_code.constructor !== String) ||
      (!val.city &&
        !val.country_code &&
        !val.house_number &&
        !val.road &&
        !val.box_number &&
        !val.zip_code)
    )
      return false;

    return true;
  } catch (_error) {
    return false;
  }
}

const mapSelectedLangToSelectableLang = (
  selectedLanguages: SelectedLanguage[]
) => {
  return selectedLanguages.map((lang: SelectedLanguage) => {
    return {
      ...lang,
      selected: true,
      levels: LANGUAGE_LEVELS,
    };
  }) as SelectableLanguage[];
};

export const questionValueToStringArray = (
  questionValue: IntakeQuestion['value']
): string[] => {
  // Return value if value is already a string array
  if (isStringArray(questionValue)) return questionValue as string[];
  // Do basic type checks & Check JSON value
  if (!canBeParsedAsJson(questionValue)) {
    if (typeof questionValue === 'string') return [questionValue as string];
    return [] as string[];
  }

  // Return the value as a string array
  try {
    const parsed = JSON.parse(questionValue!.toString());
    return parsed.constructor !== Array
      ? ([] as string[])
      : (parsed as string[]);
  } catch (error) {
    return [] as string[];
  }
};

export const questionValueToSelectableLanguageArray = (
  questionValue: IntakeQuestion['value']
): SelectableLanguage[] => {
  // Return remapped value if value is a selected language
  if (isSelectedLanguageArray(questionValue)) {
    return questionValue.length > 0
      ? mapSelectedLangToSelectableLang(questionValue)
      : BASE_SELECTED_LANGUAGES;
  } else {
    // Do basic type checks & Check JSON value
    if (!canBeParsedAsJson(questionValue)) return BASE_SELECTED_LANGUAGES;

    // Return the value as a selectable language array
    try {
      const parsed = JSON.parse(questionValue!.toString());
      return isSelectedLanguageArray(parsed) && parsed.length > 0
        ? mapSelectedLangToSelectableLang(parsed as SelectedLanguage[])
        : BASE_SELECTED_LANGUAGES;
    } catch (error) {
      return BASE_SELECTED_LANGUAGES;
    }
  }
};

export const questionValueToFileUploadsWithText = (
  questionValue: IntakeQuestion['value']
): FileUploadsWithText => {
  const fallback: FileUploadsWithText = { files: [], text: '' };

  if (isFileUploadWithText(questionValue)) {
    let val = questionValue as FileUploadsWithText;
    if (!val.text) val = { ...val, text: '' };
    if (!val.files) val = { ...val, files: [] };
    return val;
  } else {
    if (!canBeParsedAsJson(questionValue)) return fallback;

    try {
      const parsed = JSON.parse(questionValue!.toString());
      let val = isFileUploadWithText(parsed)
        ? (parsed as FileUploadsWithText)
        : fallback;
      if (!val.text) val = { ...val, text: '' };
      if (!val.files) val = { ...val, files: [] };
      return val;
    } catch (error) {
      return fallback;
    }
  }
};

export const questionValueToCoworkerArray = (
  questionValue: IntakeQuestion['value']
): Coworker[] => {
  if (isCoworkerArray(questionValue)) return questionValue as Coworker[];
  else {
    if (!canBeParsedAsJson(questionValue)) return [] as Coworker[];

    try {
      const parsed = JSON.parse(questionValue!.toString());
      return isCoworkerArray(parsed)
        ? (parsed as Coworker[])
        : ([] as Coworker[]);
    } catch (error) {
      return [] as Coworker[];
    }
  }
};

export const questionValueToCustomerAddress = (
  questionValue: IntakeQuestion['value']
): CustomerAddress => {
  if (isCustomerAddress(questionValue)) return questionValue as CustomerAddress;
  if (!canBeParsedAsJson(questionValue)) return {} as CustomerAddress;

  try {
    const parsed = JSON.parse(questionValue!.toString());

    return isCustomerAddress(parsed)
      ? (parsed as CustomerAddress)
      : ({} as CustomerAddress);
  } catch (error) {
    return {} as CustomerAddress;
  }
};

export const updateInputGroupValue = (
  value: string[],
  data: string,
  inputIndex: number
): string[] => {
  // Find the max size the array should be
  const maxSize = Math.max(value.length, inputIndex + 1);

  return [...Array(maxSize)].map((_, index) => {
    // Set the value if index matches
    if (index === inputIndex) return data;

    // Return the value if index does not match or a default value if the value is undefined
    return value[index] ?? '';
  });
};

export const pickerIcons = [
  'FaPencilAlt',
  'FaChartLine',
  'FaThumbsUp',
  'FaLightbulb',
  'FaDesktop',
  'FaDollarSign',
  'FaBuilding',
  'FaStar',
];

export const getPickerIconKeyForSymbolKey = (symbolKey: string) => {
  return (
    (pickerIcons as PickerIcons[]).find((k) => k === symbolKey) ?? 'FaPencilAlt'
  );
};

export const getJsxForSymbol = (symbolKey: string) => {
  const jsxArray = getSymbolPickerIcons();
  const iconKey = getPickerIconKeyForSymbolKey(symbolKey);
  return jsxArray.find((i) => i.key === iconKey)?.node;
};
