import SkeletonCardList from 'components/skeleton/skeleton-card-list/SkeletonCardList';
import React, { useState } from 'react';
import { NotePencil } from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';

import { Modal } from 'components/page/app-page/Modal';
import Budget from 'components/payment/Budget';

const SocialStats: React.FC<{
  loading: boolean;
  applications?: number | null;
  spent: number;
  totalBudget?: number | null;
  showResume?: boolean;
  showApplications?: boolean;
  onSubmit?: () => void;
}> = ({
  loading,
  applications = 0,
  spent,
  totalBudget = 0,
  showResume = false,
  showApplications = true,
  onSubmit,
}) => {
  const [budgetModalShown, setBudgetModalShown] = useState(false);

  if (loading)
    return (
      <div tw="mb-4 sm:(mt-4) xl:(ml-6 mt-8 w-96)">
        <SkeletonCardList variant="sm" cards={3} />
      </div>
    );

  return (
    <Wrapper>
      <Modal show={budgetModalShown} onClose={() => setBudgetModalShown(false)}>
        <Budget
          title={'Add budget to your campaign'}
          cta={'Increase budget'}
          showResume={showResume}
          closeModal={() => setBudgetModalShown(false)}
          onSubmit={onSubmit}
        />
      </Modal>

      {showApplications && (
        <StatItem>
          <StatLabel>Applications</StatLabel>
          <Stat>{applications}</Stat>
        </StatItem>
      )}

      <StatItem>
        <StatLabel>Total spent</StatLabel>
        <Stat>€ {spent}</Stat>
      </StatItem>

      <StatItem pointer tabIndex={1} onClick={() => setBudgetModalShown(true)}>
        <StatLabel>Budget</StatLabel>
        <div tw="flex justify-end items-center w-full">
          <NotePencil weight="bold" tw="text-indigo-500 mr-2" size="18" />
          <Stat tw="sm:ml-0">€ {totalBudget}</Stat>
        </div>
      </StatItem>
    </Wrapper>
  );
};

const Wrapper = styled.ul(
  tw`
    flex flex-row justify-between my-auto gap-8 overflow-x-auto max-w-full
    xl:(overflow-x-hidden)
  `
);

const StatItem = styled.li<{ pointer?: boolean }>`
  ${tw`
    flex flex-col gap-1 items-end
    sm:(items-end pb-0)
  `}
  ${({ pointer }) => pointer && tw`cursor-pointer`}
`;

const StatLabel = styled.span(
  tw`text-gray-400 w-full whitespace-nowrap text-right`
);

const Stat = styled.span(
  tw`
    text-2xl font-extrabold text-gray-700 whitespace-nowrap
    sm:(ml-auto text-right)
  `
);

export default SocialStats;
