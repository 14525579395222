import { Validate } from 'react-hook-form';
import { IntakeFormResult, IntakeFormResults } from 'types/formTypes';
import { IntakeQuestion, IntakeState } from 'types/intakeTypes';
import { MAIL_REGEX, URL_REGEX } from './regexHelpers';

const fallbackMsg = 'This field is required';

export type RegisteredInputRules = {
  pattern?: {
    value: RegExp;
    message: string;
  };
  required?: {
    value: boolean;
    message: string;
  };
  maxLength?: {
    value: number;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  max?: {
    value: number;
    message: string;
  };
  min?: {
    value: number;
    message: string;
  };
  network?: {
    value: boolean;
    message: string;
  };

  validate?: Validate;
};

export const questionRules = (
  question: IntakeQuestion
): RegisteredInputRules => {
  const { rules } = question;
  const isRequired = question.required === true || question.required === 1;

  // TODO: Add internationalization support to messages
  return rules
    ? ({
        pattern: {
          value: new RegExp(question.rules?.pattern?.value ?? ''),
          message: question.rules?.pattern?.message,
        },
        required: {
          value: isRequired,
          message: isRequired ? fallbackMsg : '',
        },
        minLength: {
          value: rules.length?.min?.value,
          message: rules.length?.min?.message,
        },
        maxLength: {
          value: rules.length?.max?.value,
          message: rules.length?.max?.message,
        },
        min: {
          value: rules.size?.min?.value,
          message: rules.size?.min?.message,
        },
        max: {
          value: rules.size?.max?.value,
          message: rules.size?.max?.message,
        },
      } as RegisteredInputRules)
    : { required: { value: false, message: '' } };
};

export type QuestionError = {
  type?: string;
  types?: string[];
  question: IntakeQuestion;
};

export enum InputErrorTypes {
  // React hook form types
  MAX = 'max',
  MAX_LENGTH = 'maxLength',
  MIN = 'min',
  MIN_LENGTH = 'minLength',
  PATTERN = 'pattern',
  REQUIRED = 'required',
  // Custom network type
  NETWORK = 'network',
}

export const getQuestionErrorMessage = (error: QuestionError): string => {
  const { type, question } = error;

  switch (type) {
    case InputErrorTypes.REQUIRED:
      return fallbackMsg;
    case InputErrorTypes.PATTERN:
      return question.rules?.pattern?.message ?? fallbackMsg;
    case InputErrorTypes.MAX:
      return question.rules?.size?.max.message ?? fallbackMsg;
    case InputErrorTypes.MIN:
      return question.rules?.size?.min.message ?? fallbackMsg;
    case InputErrorTypes.MAX_LENGTH:
      return question.rules?.length?.max.message ?? fallbackMsg;
    case InputErrorTypes.MIN_LENGTH:
      return question.rules?.length?.min.message ?? fallbackMsg;
    default:
      return fallbackMsg;
  }
};

/**
 * Gets the intake form values (values & annotations) from the intake FormData object
 * @param  {FormData} data The intake FormData object
 * @returns {[[FormData[], FormData[]]]} A tuple of form values where [0] = values & [1] = annotations
 */
const getIntakeFormValues = (data: FormData): [FormData[], FormData[]] => {
  return [
    Object.entries(data).filter((entry) => !entry[0].includes('annotation')),
    Object.entries(data)
      .filter((entry) => entry[0].includes('annotation') && entry[1])
      .map((entry) => {
        return { [`${entry?.[0].toString().split('-')[0]}`]: entry[1] };
      }),
  ] as unknown as [FormData[], FormData[]];
};

/**
 * Gets the intake form field value for a given field
 * @param  {IntakeQuestion['value']} value The value to extract
 * @param  {number} questionId The question id
 * @param  {IntakeQuestion[]} intakeQuestions The intake questions
 * @returns {string} result - The question value as a JSON-string
 */
const getIntakeFormFieldValue = (
  value: IntakeQuestion['value'],
  questionId: number,
  intakeQuestions: IntakeQuestion[]
): string => {
  if (!value) return '';
  // Return the question value as a string
  else if (
    value.constructor !== String &&
    value.constructor !== Number &&
    value.constructor !== Array
  ) {
    // Check if the question type is an upload type if the value is an editor-state
    const question = intakeQuestions.find((q) => q.id === questionId);
    if (!question) return (value as string).toString();
    // Return upload question with the updated text value
    else return JSON.stringify(value);
  } else return value.toString();
};

/**
 * Gets the intake question results from the intake form values
 * @param  {FormData[]} formValues The intake form values
 * @param  {FormData[]} annotations The intake annotations
 * @param  {IntakeQuestion[]} intakeQuestions The intake questions
 */
const getIntakeQuestionResults = (
  formValues: FormData[],
  annotations: FormData[],
  intakeQuestions: IntakeQuestion[]
) => {
  return Object.entries(formValues).map((fieldEntry) => {
    // @ts-ignore
    const questionId = parseInt(fieldEntry[1][0].toString());

    const annotation =
      // @ts-ignore
      Object.values(annotations).find(
        (a) => parseInt(Object.keys(a)[0]) === questionId
      )?.[questionId] ?? undefined;

    const question = {
      id: questionId,
      value: getIntakeFormFieldValue(
        //@ts-ignore
        fieldEntry[1][1],
        questionId,
        intakeQuestions
      ),
    };

    return (
      annotation
        ? {
            ...question,
            annotation,
          }
        : { ...question }
    ) as IntakeFormResult;
  });
};

/**
 * Gets the parsed results of the given intake form data
 * @param  {FormData} data The intake form data
 * @returns {IntakeFormResults | undefined} results The intake form results
 */
export const getIntakeResults = (
  data: FormData | undefined,
  currentIntakeState: IntakeState,
  intakeQuestions: IntakeQuestion[]
): IntakeFormResults | undefined => {
  if (!data) return undefined;

  const [formValues, annotations] = getIntakeFormValues(data);

  const { currentIntakeId, currentSectionId, currentStepId } =
    currentIntakeState;

  return {
    intake: currentIntakeId,
    section: currentSectionId,
    step: currentStepId,
    questions: getIntakeQuestionResults(
      formValues,
      annotations,
      intakeQuestions
    ),
  };
};

export const stringValidator = (text: string) => !!text.trim();

/**
 * Used for React Hook Form Validate
 * @param  {string} email the email to validate
 */
export const emailValidator = (email?: string) => {
  return email && stringValidator(email) && !!email.match(MAIL_REGEX);
};

export const urlValidator = (link?: string) => {
  return link && stringValidator(link) && !!link.match(URL_REGEX);
};
