export enum CTALabel {
  APPLY_NOW = 'Apply Now',
  LEARN_MORE = 'Learn More',
  SIGN_UP = 'Sign Up',
}

export const ctaLabels = (
  Object.keys(CTALabel) as Array<keyof typeof CTALabel>
).map((ctaLabel) => {
  return {
    key: ctaLabel,
    label: CTALabel[ctaLabel],
  };
});
