import React, { useEffect, useRef, useState, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { CaretRight, CaretDown, CaretUpDown } from '@phosphor-icons/react';

import useDefocusHandler from 'hooks/defocus';

export interface NestedDropdownProps {
  onChangeCategory: (val: number | string) => void;
  onChangeItem: (val: number | string | null) => void;
  itemValue?: string | number | null;
  categoryValue?: string | number;
  items: NestedDropdownCategory[];
  disabled?: boolean;
}

export interface NestedDropdownCategory {
  key: string | number;
  label: string;
  items?: NestedDropdownItem[];
}

export interface NestedDropdownItem {
  key: number | string;
  label: string;
}

const NestedDropdown: React.FC<NestedDropdownProps> = ({
  items,
  itemValue,
  categoryValue,
  onChangeCategory,
  onChangeItem,
  disabled = false,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [filter, setFilter] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [openCategory, _setOpenCategory] = useState<
    NestedDropdownCategory | undefined
  >(undefined);
  const [currentItem, setCurrentItem] = useState<
    NestedDropdownItem | NestedDropdownCategory | undefined | null
  >(null);

  const changeHandler = (
    category: NestedDropdownCategory,
    item: NestedDropdownItem | null
  ) => {
    setCurrentItem(item);

    onChangeCategory?.(category.key);
    onChangeItem?.(item ? item.key : null);
  };

  const setOpenCategory = (el?: NestedDropdownCategory) => {
    if (el?.items?.length === 0) {
      setCurrentItem(el);
      changeHandler(el, null);
      setOpen(false);
      return;
    }
    _setOpenCategory(el);
  };

  useEffect(() => {
    setCurrentItem(
      itemValue
        ? items
            .flatMap((i) => {
              return i.items;
            })
            .filter((i) => i?.key === itemValue)?.[0]
        : items.filter((i) => i.key === categoryValue)?.[0]
    );
    setOpen(false);
  }, [setCurrentItem, items, itemValue, categoryValue]);

  useDefocusHandler(wrapperRef, () => {
    setOpen(false);
  });

  const categoryPrefix = useMemo(() => {
    // Guard for when currentItem is a category in stead of an item
    if (!currentItem || 'items' in currentItem) return '';
    const current = items.filter(({ items }) =>
      items?.find((item) => item.label === currentItem?.label)
    )[0];
    if (current) return current.label + ' -> ';
    return '';
  }, [currentItem, items]);

  const MAX_FILTERS = 2;
  const isFilterValid = useMemo(
    () => !!filter && filter.length >= MAX_FILTERS,
    [filter]
  );

  const filteredItems = useMemo(() => {
    if (!isFilterValid) return items;

    return items
      .filter((item) =>
        filter
          ? [item.label]
              .concat(
                ...[
                  item.items
                    ? item.items.map((item) => item.label).flat()
                    : [''],
                ]
              )
              .find((item) => item.toLowerCase().includes(filter.toLowerCase()))
          : true
      )
      .map((item) => {
        const filteredSubItems = item.items
          ? item.items.filter((subItem) =>
              subItem.label.toLowerCase().includes(filter.toLowerCase())
            )
          : [];

        return {
          ...item,
          items: filteredSubItems,
        };
      });
  }, [filter, isFilterValid, items]);

  return (
    <Wrapper ref={wrapperRef} disabled={disabled}>
      <InputWrapper>
        <div tw="flex justify-between items-center w-full">
          <Input
            ref={inputRef}
            value={filter}
            placeholder={`${categoryPrefix}${currentItem?.label ?? ''}`}
            onChange={(e) => setFilter(e.target.value)}
            onFocus={() => !disabled && setOpen(true)}
            disabled={disabled}
          />
          <div
            tw="flex justify-center min-h-[20px] items-center cursor-pointer"
            onClick={() => {
              if (!disabled) {
                setOpen(true);
                inputRef.current?.focus();
              }
            }}
          >
            {!disabled && (
              <CaretUpDown size="24" tw="ml-1 absolute right-2 text-gray-400" />
            )}
          </div>
        </div>
      </InputWrapper>

      {isOpen && (
        <div tw="w-full z-50 overflow-y-scroll max-h-80 origin-top-right absolute text-sm right-0 mt-2 rounded-md shadow-lg bg-white ring-2 ring-black/5">
          <ul
            tw="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {/* If there are no search results, show the "Other" category */}
            {(!filteredItems.length &&
            !filteredItems.find((item) => item.label !== 'Other')
              ? items.filter((item) => item.label === 'Other')
              : filteredItems
            ).map((el: NestedDropdownCategory) => (
              <NestedDropdownCategoryRow
                key={el.key}
                label={el.label}
                items={el.items}
                setCurrentValue={(item) => changeHandler(el, item)}
                isOpen={isFilterValid ? true : openCategory === el}
                onClick={() =>
                  setOpenCategory(el !== openCategory ? el : undefined)
                }
                isFilterValid={isFilterValid}
                filter={filter}
                setFilter={setFilter}
              />
            ))}
          </ul>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  disabled: boolean;
}>`
  ${tw`relative cursor-pointer inline-block text-left ring-1 border ring-black/5 rounded w-full`}

  ${({ disabled }) => (disabled ? tw`border-gray-50` : tw`border-transparent`)}
`;

export interface NestedDropdownCategoryProps {
  key: string | number;
  label: string;
  setCurrentValue: (item: NestedDropdownItem) => void;
  onClick: () => void;
  items?: NestedDropdownItem[];
  isOpen: boolean;
  isFilterValid: boolean;
  filter: string;
  setFilter: (val: string) => void;
}

const NestedDropdownCategoryRow: React.FC<NestedDropdownCategoryProps> = ({
  items,
  label,
  setCurrentValue,
  isOpen,
  onClick,
  isFilterValid,
  filter,
  setFilter,
}) => {
  const splittedLabel = label.toLowerCase().split(filter.toLowerCase());

  return (
    <>
      <DropdownItem role="menuitem" onClick={onClick}>
        <div tw="flex w-full">
          {isFilterValid &&
          label.toLowerCase().includes(filter.toLowerCase()) ? (
            <>
              <TextLeft>{splittedLabel[0]}</TextLeft>
              <TextCenter splittedLabel={splittedLabel}>{filter}</TextCenter>

              {splittedLabel[1].startsWith(' ') ? (
                <span tw="capitalize">{splittedLabel[1]}</span>
              ) : (
                splittedLabel[1].split(' ').map((word, index) => (
                  <TextRight
                    key={word}
                    filter={filter}
                    index={index}
                    splittedLabel={splittedLabel}
                  >
                    {!!index && ' '}
                    {word}
                  </TextRight>
                ))
              )}
            </>
          ) : (
            <p>{label}</p>
          )}
          {items && (
            <div tw="h-full my-auto ml-auto flex">
              {items?.length !== 0 && (
                <>{isOpen ? <CaretDown /> : <CaretRight />}</>
              )}
            </div>
          )}
        </div>
      </DropdownItem>

      {isOpen &&
        items &&
        items.map((el: NestedDropdownItem) => {
          const splittedLabel = el.label
            .toLowerCase()
            .split(filter.toLowerCase());

          return (
            <DropdownItem
              role="menuitem"
              key={el.key}
              onClick={() => {
                setFilter('');
                setCurrentValue(el);
              }}
            >
              <div tw="pl-3">
                {isFilterValid &&
                el.label.toLowerCase().includes(filter.toLowerCase()) ? (
                  <>
                    <TextLeft>{splittedLabel[0]}</TextLeft>
                    <TextCenter splittedLabel={splittedLabel}>
                      {filter}
                    </TextCenter>

                    {splittedLabel[1].startsWith(' ') ? (
                      <span tw="capitalize">{splittedLabel[1]}</span>
                    ) : (
                      splittedLabel[1].split(' ').map((word, index) => (
                        <TextRight
                          key={word}
                          filter={filter}
                          index={index}
                          splittedLabel={splittedLabel}
                        >
                          {!!index && ' '}
                          {word}
                        </TextRight>
                      ))
                    )}
                  </>
                ) : (
                  <p>{el.label}</p>
                )}
              </div>
            </DropdownItem>
          );
        })}
    </>
  );
};

const InputWrapper = styled.div(
  tw`
    flex items-center w-full relative cursor-text
    text-sm font-medium text-gray-700 bg-gray-50 rounded-sm
    focus-within:(outline-none outline outline-offset-[3px] outline-blue-500)
  `
);

const Input = styled.input<{ disabled: boolean }>`
  ${tw`
    flex h-full w-full items-center bg-white p-3 pr-8 text-sm font-medium text-gray-700 focus:outline-none placeholder:text-gray-700
  `}

  ${({ disabled }) =>
    disabled &&
    tw`bg-gray-50 text-gray-500 placeholder:text-gray-500 cursor-not-allowed`}
`;

const DropdownItem = styled.li`
  ${tw`block px-4 cursor-pointer py-2 text-sm text-gray-700 whitespace-pre-wrap transition-colors hover:(bg-gray-100 text-gray-800 font-medium)`}
`;

const TextLeft = styled.span`
  ${tw`capitalize`}
`;

const TextCenter = styled.span<{
  splittedLabel: string[];
}>`
  ${tw`font-bold`}

  ${({ splittedLabel }) =>
    (!splittedLabel[0].length ||
      splittedLabel[0].substring(splittedLabel[0].length - 1) === ' ') &&
    tw`capitalize`}
`;

const TextRight = styled.span<{
  filter: string;
  index: number;
  splittedLabel: string[];
}>`
  ${({ filter, index, splittedLabel }) =>
    (filter.endsWith(' ') ||
      (!!index &&
        splittedLabel[1].split(' ').length > 1 &&
        splittedLabel[1].includes(' '))) &&
    tw`capitalize`}
`;

export default NestedDropdown;
