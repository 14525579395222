import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';

interface FooterSkeletonProps {}

const FooterSkeleton: FC<FooterSkeletonProps> = () => {
  return <SkeletonList rows={2} />;
};

export default FooterSkeleton;
