import tw, { styled, css } from 'twin.macro';
import {
  CareerPageStatusTypes,
  CareerPageStatusType,
} from 'types/careers-page/types';

export const StatusIndicator = styled.div<{ status?: CareerPageStatusTypes }>`
  ${tw`
    text-sm
    tracking-wider
    font-semibold
    uppercase
    text-[var(--status-color)]
    flex 
    items-center
    justify-center
    pl-2
    pr-4

    before:mr-[1rem]
    before:block
    before:content-[' ']
    before:w-[.5em]
    before:h-[.5em]
    before:rounded-full
    before:bg-[var(--status-color)]
    before:ring-4
    before:ring-[var(--status-color-outer)]
  `}

  ${({ status }) =>
    status === CareerPageStatusType.Active &&
    tw`
    before:animate-pulse
  `}

  ${({ status }) =>
    status === CareerPageStatusType.Active &&
    css`
      --status-color: #10b981;
      --status-color-outer: #a7f3d0;
    `}
  ${({ status }) =>
    status === CareerPageStatusType.Archived &&
    css`
      --status-color: #f87171;
      --status-color-outer: #fecaca;
    `}
  ${({ status }) =>
    status === CareerPageStatusType.Draft &&
    css`
      --status-color: #fbbf24;
      --status-color-outer: #fef3c7;
    `}
`;
