import useIntakeCoreContext from 'hooks/context/intakes-context';
import React, { createContext, useMemo } from 'react';
import { NormalizedSection, NormalizedStep } from 'types/intakeTypes';
import { IntakeQuestion } from 'types/intakes/question';
import {
  IntakeStepContextType,
  IntakeStepProviderProps,
} from 'types/intakes/steps';
import {
  getMissingQuestionsFromQuestionList,
  objectToArray,
} from 'utils/intakeHelpers';

export const IntakeStepContext = createContext<IntakeStepContextType>(
  {} as IntakeStepContextType
);

export const IntakeStepProvider: React.FC<IntakeStepProviderProps> = ({
  children,
}) => {
  const {
    intakeConfig,
    currentIntakeId,
    currentActiveStep,
    currentActiveSection,
  } = useIntakeCoreContext();

  const isFirstStep = useMemo(() => {
    if (
      !currentIntakeId ||
      !intakeConfig ||
      !intakeConfig?.intakes ||
      !intakeConfig?.sections ||
      !currentActiveStep ||
      !currentActiveSection
    )
      return false;

    const firstSectionId = intakeConfig.intakes[currentIntakeId].sections[0];
    const firstStepId = intakeConfig.sections[firstSectionId].steps[0];
    const activeStepID = currentActiveStep.id;

    return firstStepId === activeStepID;
  }, [
    intakeConfig,
    intakeConfig?.intakes,
    intakeConfig?.sections,
    currentIntakeId,
    currentActiveStep,
    currentActiveSection,
  ]);

  const isLastStep = useMemo(() => {
    if (!currentActiveStep || !currentActiveSection) return false;

    const activeStepID = currentActiveStep.id;
    const questionSteps = currentActiveSection.steps;

    return questionSteps[questionSteps.length] === activeStepID;
  }, [currentActiveStep, currentActiveSection]);

  const questions = useMemo(() => {
    if (!intakeConfig?.questions || !currentActiveStep?.questions) return [];

    const questionKeys = currentActiveStep?.questions as unknown as number[];
    const questionsToPush: IntakeQuestion[] = [];

    questionKeys.forEach((questionId) => {
      questionsToPush.push(intakeConfig.questions[questionId]);
    });
    return questionsToPush;
  }, [intakeConfig?.questions, currentActiveStep?.questions]);

  const missingIntakeQuestions = useMemo(() => {
    if (
      !intakeConfig?.sections ||
      !intakeConfig?.steps ||
      !intakeConfig?.questions
    )
      return [];

    const { questions, sections, steps } = intakeConfig;
    const filterRequiredQuestions = getMissingQuestionsFromQuestionList(
      objectToArray(questions)
    );

    const finalQuestions = filterRequiredQuestions.map((q) => {
      const questionId = q.id;
      const questionStep = [...objectToArray(steps)].find((s) => {
        return (Object.values(s.questions) as unknown as number[]).includes(
          questionId
        );
      }) as NormalizedStep;

      const stepSection = [...objectToArray(sections)].find((s) => {
        return (Object.values(s.steps) as unknown as number[]).includes(
          questionStep?.id ?? -1
        );
      }) as NormalizedSection;

      return {
        id: questionId,
        label: q.label,
        sectionId: stepSection.id,
        stepId: questionStep.id,
      };
    });

    return finalQuestions;
  }, [intakeConfig?.questions]);

  return (
    <IntakeStepContext.Provider
      value={{
        currentId: currentActiveStep?.id ?? null,
        questions,
        isFirstStep,
        isLastStep,
        missingIntakeQuestions,
      }}
    >
      {children}
    </IntakeStepContext.Provider>
  );
};
