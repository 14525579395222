import { gql } from '@apollo/client';

export const GET_APPLICATION_METRICS = gql`
  query getApplicationMetrics {
    campaignMetrics
      @rest(type: "[CampaignMetrics]", path: "/v4/dashboard/applications") {
      applications {
        vac_id
        proc_id
        can_id
        candidate_name
        inbound_date
        vac_name
        source
      }
    }
  }
`;
