import React, { forwardRef } from 'react';
import Tippy from '@tippyjs/react/headless';
import { Question } from '@phosphor-icons/react';
import { animated, useSpring } from 'react-spring/web';

import 'twin.macro';

interface IProps {
  tip: string;
}

const TooltipWrapper = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>((props, ref) => <div ref={ref}>{props.children}</div>);

const Tooltip = (props: IProps) => {
  const config = { tension: 300, friction: 15 };
  const initialStyles = { opacity: 0, transform: 'scale(0.5)' };
  const [spring, setSpring] = useSpring(() => initialStyles);

  const onMount = () => {
    setSpring({
      opacity: 1,
      transform: 'scale(1)',
      onRest: () => {},
      config,
    });
  };

  const onHide = (instance: any) => {
    setSpring({
      ...initialStyles,
      onRest: instance.unmount,
      config: { ...config, clamp: true },
    });
  };

  return (
    <Tippy
      onMount={onMount}
      onHide={onHide}
      render={(attrs) => (
        <animated.div
          tw="rounded-md bg-gray-700 p-3 text-white ring-1 ring-black/5"
          tabIndex={-1}
          style={spring}
          {...attrs}
        >
          <p tw="text-sm">{props.tip}</p>
        </animated.div>
      )}
    >
      <TooltipWrapper>
        <Question
          weight="fill"
          tw="ml-1 cursor-pointer text-xl text-gray-400"
        />
      </TooltipWrapper>
    </Tippy>
  );
};

export default Tooltip;
