import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import ProgressCircle from '../form/ProgressCircle';
import { pageView } from 'utils/eventHelper';
import useNavigationContext from 'hooks/context/nav-context';

import 'twin.macro';
import useIntakeCoreContext from 'hooks/context/intakes-context';
import FormStep from 'views/intake/FormStep';
import IntakeControls from './IntakeControls';
import useIntakeStepContext from 'hooks/context/intake-step-context';
import IntakeLayout from 'views/intake/IntakeLayout';
import Notification from 'components/notifications/notification/Notification';

interface IntakeFormProps {
  id: string;
}

const IntakeForm: React.FC<IntakeFormProps> = ({ id }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { prevLocation } = useNavigationContext();
  const {
    currentIntakeProgress,
    currentActiveSection: currentSection,
    currentActiveStep: currentStep,
  } = useIntakeCoreContext();

  const { isFirstStep, isLastStep } = useIntakeStepContext();

  const methods = useForm();

  const [isStepperShown, setIsStepperShown] = useState(false);

  const clickProgressHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsStepperShown(!isStepperShown);
  };

  const closeStepperOverviewHandler = () => {
    setIsStepperShown(false);
  };

  const resetScroll = useCallback(() => {
    scrollContainerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [scrollContainerRef]);

  useEffect(() => {
    if (currentStep?.reference) {
      pageView(prevLocation, '/intake/' + currentStep.reference, {
        event: 'Pageview',
        pagePath: '/intake/' + currentStep.reference,
        pageTitle: currentStep.reference,
        pageId: 'intake',
      });
    }
  }, [currentStep, currentStep?.reference, prevLocation]);

  if (!currentStep || !currentSection) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <IntakeLayout
        id={id}
        step={currentStep}
        image={currentStep.info_image}
        isStepperShown={isStepperShown}
        onStepperClose={() => closeStepperOverviewHandler()}
      >
        <div tw="m-auto flex h-full w-full grow flex-col items-center justify-between p-4">
          <div tw="flex w-full flex-row self-start">
            <div>
              <h2 tw="text-lg font-medium text-gray-500">
                {currentSection.name}
              </h2>
              <div tw="flex w-full items-baseline">
                <h3 tw="mb-6 text-xl font-extrabold leading-10 tracking-tight text-gray-700 sm:text-2xl sm:leading-none md:text-4xl">
                  {currentStep.name}
                  <p tw="mb-1 text-sm font-normal text-gray-500">
                    {currentStep.info_header}
                  </p>
                </h3>
              </div>
            </div>
            <div tw="ml-auto flex items-center">
              <Notification />
              <ProgressCircle
                onClick={(e) => clickProgressHandler(e)}
                radius={38}
                stroke={5}
                progress={currentIntakeProgress}
              />
            </div>
          </div>
          <div
            tw="flex h-full w-full flex-col overflow-y-scroll p-1"
            id="scroll-container"
            ref={scrollContainerRef}
          >
            <form id="intake-form" name="intake-form">
              <FormStep />
            </form>
          </div>
          <div tw="ml-auto pt-2">
            <IntakeControls
              isFirstStep={isFirstStep}
              isLastStep={isLastStep}
              onChange={() => resetScroll()}
            />
          </div>
        </div>
      </IntakeLayout>
    </FormProvider>
  );
};

export default IntakeForm;
