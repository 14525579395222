import React from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { globalStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`
    #root {
        height: 100vh;
        max-width: 100%;
        min-width: 320px;
        overflow: hidden;
    }

    :root {
      --rs-primary-50:  #ffffff;
      --rs-primary-100: #e6e6e6;
      --rs-primary-200: #cccccc;
      --rs-primary-300: #b3b3b3;
      --rs-primary-400: #999999;
      --rs-primary-500: #808080;
      --rs-primary-600: #666666;
      --rs-primary-700: #4d4d4d;
      --rs-primary-800: #333333;
      --rs-primary-900: #000000;
    }

    .isAdmin {
        ${tw`rounded-md ring-2 ring-red-100 ring-offset-4 ring-offset-red-50`}
    }

    .szh-accordion__item-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    }

    .szh-accordion__item-btn {
      ${tw`w-full`}
    }

    button{
        ${tw`bg-transparent`}
    }

    .react-pdf__Page {
      min-width: unset !important;
      max-width: 100% !important;
    }

    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }

    .react-pdf__Page__annotations {
      display: none;
    }

    .public-DraftStyleDefault-block {
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
      width: fit-content;
      padding-right: 1rem;
    }

    .rdw-emoji-modal {
      top: -180px;
      z-index: 200;
    }

    ::-webkit-scrollbar {
      background: transparent;
      width: 0;
    }

    ::-webkit-scrollbar-track {
      background-color: #fff;
    }

    ::-webkit-scrollbar-track:hover {
      background-color: #f4f4f4;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #fff;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 16px;
      border: 5px solid #fff;
    }

    :hover::-webkit-scrollbar-thumb {
      background-color: #a0a0a5;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }

    #intake-container {
      // Reset scrollbar for intake
      ::-webkit-scrollbar {
          background: initial;
          width: initial;
      }

      ::-webkit-scrollbar-track {
          background-color: initial;
      }

      ::-webkit-scrollbar-track:hover {
          background-color: initial;
      }

      ::-webkit-scrollbar-thumb {
          background-color: initial;
          border-radius: initial;
          border: initial;
      }

      ::-webkit-scrollbar-thumb:hover {
          background-color: initial;
          border: initial;
      }

      ::-webkit-scrollbar-thumb {
          background-color: initial;
          border-radius: initial;
          border: initial;
      }

      :hover::-webkit-scrollbar-thumb {
          background-color: initial;
      }

      ::-webkit-scrollbar-thumb:hover {
          background-color: initial;
          border: initial;
      }

      ::-webkit-scrollbar-button {
          display: initial;
      }
    }
`;

const BaseStyles = createGlobalStyle`
  ${Object.fromEntries(
    Object.entries(globalStyles).map(([key, value]) => {
      if (key === "button, [type='button'], [type='reset'], [type='submit']") {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { backgroundColor: _, ...newStyles } = value;
        return [key, newStyles];
      } else {
        return [key, value];
      }
    })
  )}
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
