import React, { FC, useState } from 'react';
import 'twin.macro';
import WizardStep, { IWizardStep } from './WizardStep';
import { FormProvider, useForm } from 'react-hook-form';
import WizardNavigation from './WizardNavigation';

export interface WizardFormProps {
  steps: IWizardStep[];
  onHandleSubmitForm: (fields: { [x: string]: any }) => void;
}

const WizardForm: FC<WizardFormProps> = ({ steps, onHandleSubmitForm }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stateSteps, setStateSteps] = useState<IWizardStep[]>(steps);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, getValues, formState, trigger, setValue } = formMethods;

  const getCurrentRequiredFields = (): string[] => {
    const requiredFields: string[] = [];

    // get required fields
    stateSteps[currentIndex].fields?.forEach((field) => {
      if (field.rules?.required) {
        requiredFields.push(field.name);
      }
    });

    return requiredFields;
  };

  const isStepValid = (): boolean => {
    const requiredFields = getCurrentRequiredFields();

    // get fieldState from required fields
    const requiredFieldsWithErrors = requiredFields?.filter((field) => {
      const { errors } = formState;
      return !!errors[field];
    });

    return requiredFieldsWithErrors?.length === 0;
  };

  const markStepAsAnswered = (index: number) => {
    const newSteps = [...stateSteps];
    newSteps[index].answered = true;
    setStateSteps(newSteps);
  };

  const handleNavigateToStep = (index: number) => {
    const requiredFields = getCurrentRequiredFields();

    // manually trigger required fields
    if (requiredFields.length > 0) {
      const currentLoggedValues = getValues(requiredFields);

      trigger(requiredFields);
      Object.keys(currentLoggedValues).forEach((key) => {
        setValue(key, currentLoggedValues[key], { shouldValidate: true });
      });
    }

    if (isStepValid()) {
      markStepAsAnswered(currentIndex);
      setCurrentIndex(index);
    }
  };

  const handleSubmitForm = () => {
    const values = getValues();
    onHandleSubmitForm({ ...values });
  };

  return (
    <div tw="w-full">
      <FormProvider {...formMethods}>
        <form tw="w-full" onSubmit={handleSubmit(handleSubmitForm)}>
          {stateSteps.map((step, key) => (
            <WizardStep
              {...step}
              key={key}
              stepIndex={key}
              currentIndex={currentIndex}
              onHandleNavigateToStep={handleNavigateToStep}
            />
          ))}

          <WizardNavigation
            currentIndex={currentIndex}
            totalSteps={stateSteps.length}
            currentStep={stateSteps[currentIndex]}
            onHandleNavigateToStep={handleNavigateToStep}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default WizardForm;
