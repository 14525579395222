import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

/**
 * Hook that can be used to manipulate sympl-specific cookies
 * @param key The cookie key
 * @returns A tuple containing the cookie, a setter and a removal function
 */
export function useSymplCookie<T>(
  key: string
): [
  T | undefined,
  (value: T, options?: CookieSetOptions) => void,
  (name: string, options?: CookieSetOptions) => void
] {
  const [cookies, setCookie, removeCookie] = useCookies<string, T>([key]);

  const setSymplCookie = (value: T, options?: CookieSetOptions) => {
    setCookie(key, value, {
      ...options,
      expires: options?.expires ?? new Date('2038-01-19 04:14:07'),
    });
  };

  return [(cookies as any)[key], setSymplCookie, removeCookie];
}
