import Button from 'components/button/Button';
import SymbolPicker from 'components/form/symbol/SymbolPicker';
import TextEditor from 'components/form/text-editor/TextEditor';
import ImagePlaceholder from 'components/vacancy/ImagePlaceholder';
import React, { forwardRef } from 'react';
import tw from 'twin.macro';
import {
  VacancyContextType,
  VacancyCopy,
  VacancyCopyKeyname,
} from 'types/vacancyTypes';
import { WordPressIconItem } from 'types/wordpressTypes';
import { getJsxForSymbol } from 'utils/typeHelpers';
import { MAX_INPUT_LENGTH } from './VacancyPreviewer';
import * as VacancyPreviewerStyles from './VacancyPreviewerStyles';
import { PencilSimple } from '@phosphor-icons/react';
import { TrashIcon } from './VacancyPreviewerStyles';
import { getInitialPrompt } from 'utils/vacancy-generator/vacancyHelpers';
import { VacancyAIPromptPayload } from 'utils/vacancy-generator/prompts';
import useNavigationContext from 'hooks/context/nav-context';
import { getFormattedText } from 'utils/openAiHelpers';

export type VacancyHeaderProps = {
  isTextEssenceEmpty: boolean;
  dummyTxt: string;
  openSymbolPickerIndex: number | undefined;
  vacancyData: VacancyCopy;
  isEditing: boolean;
  generatingFields: (keyof VacancyCopy)[];
  onSetUploadMode: React.Dispatch<
    React.SetStateAction<'logo' | 'cb_1' | 'cb_3' | undefined>
  >;
  onSetVacancyData: VacancyContextType['setVacancyData'];
  onSetIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onSetOpenSymbolPickerIndex: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  vacancyAIPayload?: VacancyAIPromptPayload;
};

const keyName: VacancyCopyKeyname = 'vac_text_essence';

const VacancyHeader = forwardRef<HTMLDivElement, VacancyHeaderProps>(
  (props, TextEditorRef) => {
    const {
      isTextEssenceEmpty,
      isEditing,
      openSymbolPickerIndex,
      generatingFields,
      dummyTxt,
      vacancyData,
      onSetUploadMode,
      onSetVacancyData,
      onSetIsEditing,
      onSetOpenSymbolPickerIndex,
      vacancyAIPayload,
    } = props;

    const { activeVacancy } = useNavigationContext();

    const onFinished = (value: string, thread: string) => {
      if (!activeVacancy) {
        console.error('No vacancy set');
        return;
      }
      return getFormattedText(value, activeVacancy, keyName, thread);
    };

    return (
      <header id="header">
        <div className="wrapper">
          <section className="logo-wrapper">
            {vacancyData.pictures?.logo ? (
              <img
                id="logo-img"
                className="highlight"
                src={vacancyData.pictures?.logo?.path}
                alt={'ambience'}
                loading="lazy"
                onClick={() => onSetUploadMode('logo')}
              />
            ) : (
              <ImagePlaceholder
                color={vacancyData.secondary_color}
                type="logo"
                onClick={() => onSetUploadMode('logo')}
              />
            )}
          </section>
          <section>
            <h1
              id="title"
              className="highlight"
              contentEditable="true"
              suppressContentEditableWarning={true}
              placeholder={dummyTxt}
              onBlur={(e) =>
                onSetVacancyData(
                  (prev) =>
                    ({
                      ...prev,
                      title:
                        prev &&
                        e.target.textContent &&
                        e.target.textContent.length > MAX_INPUT_LENGTH
                          ? prev.title
                          : e.target.textContent ?? '',
                    } as any)
                )
              }
              onPaste={(e) => {
                // Cancel paste
                e.preventDefault();

                // Get text representation of clipboard
                const text = e.clipboardData.getData('text/plain');

                // Insert text manually
                onSetVacancyData(
                  (prev) =>
                    ({
                      ...prev,
                      title: text,
                    } as any)
                );
              }}
            >
              {vacancyData.title}
            </h1>
          </section>
          <hr id="vertical-spacer" />
          <section id="job-description">
            {isEditing ? (
              <div tw="my-8" ref={TextEditorRef}>
                <TextEditor
                  identifier={keyName}
                  defaultValue={vacancyData.vac_text_essence ?? ''}
                  onChange={(e) =>
                    onSetVacancyData(
                      (prev) =>
                        ({
                          ...prev,
                          vac_text_essence: e,
                        } as any)
                    )
                  }
                  aiEnabled={!!vacancyAIPayload}
                  onAIGenFinished={onFinished}
                  // TODO: add location
                  initialPrompt={
                    vacancyAIPayload
                      ? getInitialPrompt(keyName, vacancyAIPayload)
                      : undefined
                  }
                  aiSuggestedActions={[
                    {
                      icon: <PencilSimple weight="fill" tw="text-indigo-500" />,
                      label: 'Write an introduction',
                      prompt: 'Write an introduction',
                      instantPrompt: true,
                      highlighted: true,
                    },
                  ]}
                />
              </div>
            ) : (
              <div
                css={[
                  isTextEssenceEmpty && tw`font-dummy text-4xl opacity-70`,
                  generatingFields.includes(keyName) && tw`cursor-wait`,
                ]}
                className={`${
                  !generatingFields.includes(keyName) && 'highlight'
                }`}
                dangerouslySetInnerHTML={{
                  __html: isTextEssenceEmpty
                    ? `<p></p>${dummyTxt.substring(0, 100)}`
                    : vacancyData.vac_text_essence!,
                }}
                onClick={() =>
                  onSetIsEditing(!generatingFields.includes(keyName))
                }
              />
            )}
          </section>
          <section id="job-qualities">
            {vacancyData.vac_text_uniqueness?.map(
              (item: WordPressIconItem, outerIndex: number) => (
                <div tw="flex relative" key={outerIndex}>
                  <VacancyPreviewerStyles.SymbolWrapper>
                    <SymbolPicker
                      value={
                        {
                          key: item.icon ?? '⭐️',
                          node: item.icon.includes('Fa')
                            ? getJsxForSymbol(item.icon)
                            : item.icon ?? '⭐️',
                        } as any
                      }
                      isOpen={openSymbolPickerIndex === outerIndex}
                      onChange={(val) => {
                        onSetVacancyData(
                          (prev) =>
                            ({
                              ...prev,
                              vac_text_uniqueness:
                                prev?.vac_text_uniqueness?.map(
                                  (value, innerIndex) =>
                                    innerIndex === outerIndex
                                      ? {
                                          ...value,
                                          icon: val.key,
                                        }
                                      : value
                                ),
                            } as VacancyCopy)
                        );
                        onSetOpenSymbolPickerIndex(undefined);
                      }}
                      onToggle={() =>
                        onSetOpenSymbolPickerIndex(
                          openSymbolPickerIndex === outerIndex
                            ? undefined
                            : outerIndex
                        )
                      }
                      bgTransparent={true}
                      txtColor={vacancyData.secondary_color}
                      showIcons={true}
                    />
                  </VacancyPreviewerStyles.SymbolWrapper>
                  <span
                    css={[
                      generatingFields.includes('vac_text_uniqueness') &&
                        tw`cursor-wait`,
                    ]}
                    tw="ml-16 w-full empty:before:(font-dummy !text-3xl opacity-70 content-[attr(placeholder)]) empty:focus:before:content-['']"
                    className={`${
                      !generatingFields.includes('vac_text_uniqueness') &&
                      'highlight'
                    }`}
                    placeholder={dummyTxt.substring(0, 40)}
                    contentEditable={
                      !generatingFields.includes('vac_text_uniqueness')
                    }
                    suppressContentEditableWarning={true}
                    onBlur={(e) =>
                      onSetVacancyData(
                        (prev) =>
                          ({
                            ...prev,
                            vac_text_uniqueness: prev?.vac_text_uniqueness?.map(
                              (value, innerIndex) =>
                                innerIndex === outerIndex && e.target
                                  ? {
                                      ...value,
                                      value: e.target.textContent,
                                    }
                                  : value
                            ),
                          } as VacancyCopy)
                      )
                    }
                    onPaste={(e) => {
                      // Cancel paste
                      e.preventDefault();

                      // Get text representation of clipboard
                      const text = e.clipboardData.getData('text/plain');

                      // Insert text manually
                      onSetVacancyData(
                        (prev) =>
                          ({
                            ...prev,
                            vac_text_uniqueness: prev?.vac_text_uniqueness?.map(
                              (value, innerIndex) =>
                                innerIndex === outerIndex && e.target
                                  ? {
                                      ...value,
                                      value: text,
                                    }
                                  : value
                            ),
                          } as VacancyCopy)
                      );
                    }}
                  >
                    {item.value}
                  </span>
                  <TrashIcon
                    isgenerating={generatingFields.includes(
                      'vac_text_uniqueness'
                    )}
                    onClick={() => {
                      if (!generatingFields.includes('vac_text_uniqueness'))
                        onSetVacancyData(
                          (prev) =>
                            ({
                              ...prev,
                              vac_text_uniqueness:
                                prev?.vac_text_uniqueness?.filter(
                                  (_value, index) => index !== outerIndex
                                ),
                            } as VacancyCopy)
                        );
                    }}
                  />
                </div>
              )
            )}

            <Button
              variant="transparent"
              onClick={() =>
                onSetVacancyData(
                  (prev) =>
                    ({
                      ...prev,
                      vac_text_uniqueness: [
                        ...prev!.vac_text_uniqueness!,
                        {
                          icon: 'FaPencilAlt',
                          value: '',
                        },
                      ],
                    } as VacancyCopy)
                )
              }
              disabled={generatingFields.includes('vac_text_uniqueness')}
            >
              <VacancyPreviewerStyles.PlusIcon />
            </Button>
          </section>
        </div>
      </header>
    );
  }
);

export default VacancyHeader;
