import { normalize } from 'normalizr';

import {
  Intake,
  IntakeQuestion,
  IntakeSection,
  IntakeStep,
} from 'types/intakeTypes';
import { intakeEntity } from 'types/schemas/schema';

export const normalizedIntake = (data: Intake) =>
  normalize<
    any,
    {
      intakes: { [key: string]: Intake };
      questions: { [key: string]: IntakeQuestion };
      sections: { [key: string]: IntakeSection };
      steps: { [key: string]: IntakeStep };
    }
  >(data, intakeEntity);
