import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';

interface CarouselSkeletonProps {}

const CarouselSkeleton: FC<CarouselSkeletonProps> = () => {
  return <SkeletonList rows={2} />;
};

export default CarouselSkeleton;
