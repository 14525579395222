import { gql } from '@apollo/client';

export const GET_PREVIOUS_BRAND_LOCATIONS = gql`
  query getPreviousLocations($id: ID!) {
    locationData(id: $id)
      @rest(
        type: "TargetingLocations"
        path: "/v4/brands/{args.id}?include=locations"
      ) {
      id
      name
      locations
    }
  }
`;
