import { DropdownItem } from 'components/dropdown/Dropdown';
import { ContentState, convertFromHTML, EditorState, Modifier } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export const suggestions: DropdownItem[] = [
  { key: '!CAN_F_NAME', label: 'Candidate - first name' },
  { key: '!CAN_L_NAME', label: 'Candidate - last name' },
  { key: '!REC_F_NAME', label: 'Recruiter - first name' },
  { key: '!REC_L_NAME', label: 'Recruiter - last name' },
  { key: '!REC_FUNCTION', label: 'Recruiter - function' },
  { key: '!VAC_FUNCTION', label: 'Vacancy - function' },
  { key: '!BRAND_NAME', label: 'Brand - name' },
  { key: '!BRAND_LOGO', label: 'Brand - logo' },
];

export const sendTextToEditor = (
  text: string,
  editorStateHandler: (arg: EditorState) => void,
  editorState: EditorState
) => {
  editorStateHandler(insertText(text, editorState));
};

const insertText = (text: string, editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    text
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    'insert-characters'
  );
  return EditorState.forceSelection(
    newEditorState,
    newContent.getSelectionAfter()
  );
};

export const createStateFromHtml = (html: string) => {
  const blocksFromHTML = convertFromHTML(html);

  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  return EditorState.createWithContent(state);
};

export const createStateFromDefaultValue = (defaultValue: string) => {
  const blocksFromHtml = htmlToDraft(defaultValue);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap // ?
  );

  return EditorState.moveFocusToEnd(
    EditorState.createWithContent(contentState)
  );
};
