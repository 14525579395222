import React, { useEffect } from 'react';

import AppPage from 'components/page/app-page/AppPage';
import OnBoardingForm from 'components/onboarding/OnBoardingForm';
import { Routes } from 'types/routeTypes';
import LogRocket from 'logrocket';
import { useNavigate, useLocation } from 'react-router-dom';

const OnBoarding: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { demo: boolean };

  useEffect(() => {
    if (import.meta.env.PROD) {
      LogRocket.init('2ky20e/sympl-customer-intake');
    }
  });

  const handleSubmitAnswers = () => {
    navigate(Routes.GETTING_STARTED, {
      state: { demo: !!state.demo },
    });
  };

  return (
    <AppPage allowPageWithoutSubscription allowPageWithoutVacancy>
      <OnBoardingForm onHandleSubmitAnswers={handleSubmitAnswers} />
    </AppPage>
  );
};

export default OnBoarding;
