import ToggleCampaignButton from 'components/campaign/toggle-campaign-button/ToggleCampaignButton';
import ChannelButtonGroup from 'components/channel-button-group/ChannelButtonGroup';
import DateButton from 'components/date-button/DateButton';
import MetricGroup from 'components/metrics/metric-group/MetricGroup';
import AppPage from 'components/page/app-page/AppPage';
import SectionHeading from 'components/section/section-heading/SectionHeading';
import { SectionWrapper } from 'components/section/section-wrapper/SectionWrapper';
import SkeletonCardList from 'components/skeleton/skeleton-card-list/SkeletonCardList';
import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import AdList from 'components/social-media/ad-list/AdList';
import SocialStats from 'components/social-media/stats/SocialStats';
import { Subhead } from 'components/typography/Typography';
import {
  GET_VACANCY_ADS,
  GET_VACANCY_METRICS,
  GET_VACANCY_TRAJECTORY_PACE,
} from 'graphql/vacancies/queries';
import { useQuery } from 'hooks/sympl-query';
import React, { useEffect, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { Ad, AdChannel, AdDateRange } from 'types/adTypes';
import { VacancyMetric, VacancySpendStatus } from 'types/vacancyTypes';
import { dateFilterToNumeric, getMetricDaysFilter } from 'utils/apiHelpers';
import { roundMoney } from 'utils/baseHelpers';

import { filterAdsByChannels, statusToInt } from 'utils/socialMediaHelpers';
import useNavigationContext from 'hooks/context/nav-context';
import useSocialMediaContext from 'hooks/context/social-media-context';
import ChannelToggleGroup from 'components/channel-toggle-group/ChannelToggleGroup';
import PaceControls, {
  CustomPace,
  Pace,
} from 'components/social-media/pacing/PaceControls';
import { useMutation } from 'hooks/sympl-mutation';
import { UPDATE_VACANCY_TRAJECTORY_PACE } from 'graphql/vacancies/mutations';

export const SocialMedia: React.FC = () => {
  const {
    activeChannels,
    activeDateFilter,
    toggleChannelFilter,
    setDateRangeFilter,
  } = useSocialMediaContext();

  const {
    activeVacancy,
    vacIsPublished: isPublished,
    hasSymplVacancyPage,
  } = useNavigationContext();

  const metricsDayFilter = getMetricDaysFilter();

  const metricPayload = {
    vacancyId: activeVacancy ?? 0,
    days: 0,
    channel: activeChannels.join(','),
  };

  const {
    loading: vacanciesLoading,
    data: vacanciesData,
    refetch: refetchAds,
  } = useQuery<
    {
      ads: Ad[];
    },
    { vacancyId: number }
  >(GET_VACANCY_ADS, {
    skip: !activeVacancy,
    variables: { vacancyId: activeVacancy ?? 0 },
  });

  const {
    loading: metricsLoading,
    data: metricsData,
    refetch: refetchMetrics,
  } = useQuery<
    {
      metrics: VacancyMetric;
    },
    { vacancyId: number; days: number; channel: string }
  >(GET_VACANCY_METRICS, {
    skip: !activeVacancy,
    notifyOnNetworkStatusChange: true,
    variables: {
      ...metricPayload,
      days: metricsDayFilter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const vacBudget = metricsData?.metrics?.vacBudget;
  const totalSpent = roundMoney(metricsData?.metrics?.vacSpent ?? 0);

  const ads = (vacanciesData?.ads ?? [])
    .filter(
      ({ status }) =>
        status &&
        [
          'INACTIVE',
          'ACTIVE',
          'COMPLETED',
          'ARCHIVED',
          'PAUSED',
          'REVISION',
        ].includes(status)
    )
    .sort(
      (a, b) =>
        statusToInt(b.status!) - statusToInt(a.status!) ||
        b.adMetric!.apmi! - a.adMetric!.apmi!
    );

  const campaignStatus = useMemo(
    () => metricsData?.metrics?.spend_status ?? VacancySpendStatus.INACTIVE,
    [metricsData?.metrics?.spend_status]
  );

  const updateDateFilterHandler = (
    contextSetter: (range: AdDateRange) => void,
    range: AdDateRange
  ) => {
    contextSetter(range);
    refetchMetrics({ ...metricPayload, days: dateFilterToNumeric(range) });
  };

  const budgetSpent = (vacBudget?.total_budget ?? 1) <= totalSpent;

  const [updatePace] = useMutation<
    undefined,
    { vacancyId: number; pace: Pace }
  >(UPDATE_VACANCY_TRAJECTORY_PACE);

  const changePace = async (newPace: string | number) => {
    if (newPace == getPace?.pace) return; // Don't do triple equation since type might be different
    await updatePace({
      variables: {
        vacancyId: activeVacancy!,
        pace: newPace as Pace,
      },
    });
    await paceRefetch({ vacancyId: activeVacancy! });
  };

  const {
    loading: paceLoading,
    data: { getPace } = {},
    refetch: paceRefetch,
  } = useQuery<{ getPace: { pace: Pace | CustomPace } }, { vacancyId: number }>(
    GET_VACANCY_TRAJECTORY_PACE,
    {
      skip: !activeVacancy || !isPublished,
      variables: { vacancyId: activeVacancy! },
    }
  );

  useEffect(() => {
    !!activeVacancy && paceRefetch({ vacancyId: activeVacancy! });
  }, [activeVacancy]);

  return (
    <AppPage
      heading="Social Media"
      loading={vacanciesLoading}
      cta={
        <div tw="flex gap-12">
          {!!getPace?.pace && (
            <PaceControls
              loading={paceLoading}
              value={getPace?.pace}
              onChange={changePace}
            />
          )}
          <ChannelToggleGroup />
          {!!isPublished && (
            <ToggleCampaignButton
              id={activeVacancy ?? 0}
              disabled={budgetSpent}
              disabledReason={
                budgetSpent
                  ? 'The budget of this campaign is insufficient, top-up extra budget in order to to republish'
                  : ''
              }
              campaignStatus={campaignStatus}
              onStop={() => {
                refetchAds();
                refetchMetrics();
              }}
            />
          )}
        </div>
      }
      styles={{
        maxWidth: '100%',
      }}
    >
      <ControlsWrapper>
        <div tw="flex flex-col">
          <Subhead isLight mb={1}>
            Filters
          </Subhead>
          <ul tw="flex items-center flex-row">
            <li tw="w-full">
              <ChannelButtonGroup
                channels={activeChannels}
                onChannelClicked={(channel: AdChannel) => {
                  toggleChannelFilter(channel);
                }}
              />
            </li>

            <li tw="w-full sm:(w-auto ml-8 mt-0)">
              <DateButton
                value={activeDateFilter}
                onClear={() =>
                  updateDateFilterHandler(setDateRangeFilter, 'Last quarter')
                }
                onChange={(filter) =>
                  updateDateFilterHandler(
                    setDateRangeFilter,
                    filter as AdDateRange
                  )
                }
              />
            </li>
          </ul>
        </div>
        <div tw="flex gap-8 justify-between">
          <SocialStats
            loading={metricsLoading}
            applications={metricsData?.metrics?.vacApplications}
            spent={totalSpent}
            totalBudget={vacBudget?.total_budget}
            showResume={
              isPublished &&
              [VacancySpendStatus.DRAFT, VacancySpendStatus.INACTIVE].includes(
                campaignStatus
              )
            }
            showApplications={hasSymplVacancyPage}
            onSubmit={refetchMetrics}
          />
        </div>
      </ControlsWrapper>

      <SectionWrapper>
        <SectionHeading title="Metrics" structural />
        {metricsLoading ? (
          <SkeletonCardList cards={4} />
        ) : (
          <MetricGroup
            metrics={metricsData?.metrics?.vacMetrics}
            showVacancyPageMetrics={hasSymplVacancyPage}
          />
        )}
      </SectionWrapper>

      <SectionWrapper tw="pb-8">
        <SectionHeading title="Ads" />
        {vacanciesLoading ? (
          <SkeletonList rows={3} />
        ) : (
          <AdList ads={filterAdsByChannels(activeChannels, ads)} />
        )}
      </SectionWrapper>
    </AppPage>
  );
};

const ControlsWrapper = styled.div(
  tw`flex px-1 flex-col-reverse gap-y-2 2xl:flex-row justify-between mb-4 md:mb-11` // Inline padding prevents cutoff of borders
);
