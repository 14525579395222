import React, { useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';
import { Sparkle, UploadSimple } from '@phosphor-icons/react';
import { AdPlacement } from 'types/adTypes';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import useLoadVideo from 'hooks/load-video';
import Divider from 'components/divider/Divider';
import useVideoGenContext from 'hooks/context/video-gen-context';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';

// TODO: check if this is duplicate code - StencilCanvas.tsx already has this
export const AdEditorVideoContainer: React.FC = () => {
  const {
    currentVariant,
    uploadMode,
    videoRef,
    setActiveTab,
    canvasWidth,
    canvasHeight,
  } = useAdEditorContext();

  const hasValidSrc = useMemo(
    () => !!currentVariant?.path?.path,
    [currentVariant?.path]
  );

  const src = useMemo(
    () => (hasValidSrc ? `${currentVariant?.path?.path}#t=0.1` : '#'),
    [hasValidSrc, currentVariant?.path]
  );

  useLoadVideo({ videoRef });

  const { videoGen } = useVideoGenContext();

  return (
    <VideoContainer
      editMode={uploadMode}
      hasValidSrc={hasValidSrc}
      placement={currentVariant?.placement}
    >
      {currentVariant?.path ? (
        <Video
          loop
          muted
          id="video"
          key={currentVariant?.path?.path}
          placement={currentVariant?.placement}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          ref={videoRef}
          preload="metadata"
        >
          <source id="source" src={src} type="video/mp4" />
        </Video>
      ) : (
        <div tw="h-full w-full flex justify-center absolute z-0 bg-gray-200">
          <div tw="my-auto h-fit items-center">
            <div
              onClick={() => setActiveTab?.(AdEditorSideBarTabs.VISUAL_OPTIONS)}
              tw="flex flex-col items-center justify-center text-gray-500 font-semibold cursor-pointer"
            >
              <UploadSimple weight="bold" size={32} tw="my-1" />
              Click here to upload your video
            </div>
            <Divider dark />
            <div
              onClick={videoGen}
              tw="flex flex-col items-center mt-2 justify-center text-gray-500 font-semibold cursor-pointer"
            >
              <Sparkle weight="fill" size={32} tw="my-1" />
              Generate a video using AI (beta)
            </div>
          </div>
        </div>
      )}
    </VideoContainer>
  );
};

const VideoContainer = styled.div<{
  editMode: boolean;
  hasValidSrc: boolean;
  placement?: AdPlacement;
}>`
  ${tw`relative flex flex-col justify-center`}

  ${({ placement }) =>
    placement === AdPlacement.FEED && tw`w-full min-w-[200px] min-h-[200px]`}

  ${({ placement }) =>
    placement && [AdPlacement.STORIES, AdPlacement.REELS].includes(placement)
      ? tw`aspect-[9/16]`
      : tw`aspect-[1080/1350]`}

  ${({ editMode }) => editMode && tw`pt-0 bg-gray-100`}

  ${({ hasValidSrc }) => !hasValidSrc && tw`min-h-[350px]`}
`;

const Video = styled.video<{
  placement: AdPlacement;
  canvasWidth: number;
  canvasHeight: number;
}>`
  ${tw`absolute top-0 left-1/2 -translate-x-1/2 w-full h-full object-contain max-w-full max-h-full`}

  ${({ canvasWidth, canvasHeight }) =>
    css`
      aspect-ratio: ${canvasWidth / canvasHeight};
    `}

  ${({ placement }) =>
    placement === AdPlacement.STORIES && tw`max-w-full max-h-full`}
`;

export default AdEditorVideoContainer;
