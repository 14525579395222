import React, { CSSProperties, useRef, useState } from 'react';
import useDefocusHandler from 'hooks/defocus';
import tw, { styled } from 'twin.macro';
import { InfiniteLoader } from 'react-virtualized/dist/es/InfiniteLoader';
import { List, ListRowProps } from 'react-virtualized/dist/es/List';
import { Index } from 'react-virtualized';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import {
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized/dist/es/CellMeasurer';
import { IContextMenuItems } from './InfiniteApiDropdown';

export interface ContextMenuProps {
  open: boolean;
  sections: IContextMenuItems;
  onClickOutside?: () => void;
  hasNextPage: boolean;
  loadNextPage: () => Promise<any>;
  isPageLoading: boolean;
  customCSS?: (item: unknown) => CSSProperties | undefined;
}

export interface Section {
  items: Item[];
  data?: any[];
}

export interface Item {
  element: JSX.Element;
  onClick?: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  sections,
  open,
  onClickOutside,
  hasNextPage,
  loadNextPage,
  isPageLoading,
  customCSS,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const nodeRef = useRef(null);
  useDefocusHandler(ref, () => {
    onClickOutside?.();
  });

  const [cache] = useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 40,
      minHeight: 32, // row height
    })
  );
  const rowCount =
    hasNextPage && !isPageLoading
      ? sections.data[1].items.length + 1
      : sections.data[1].items.length;
  const isRowLoaded = (index: Index) => {
    return index.index < sections.data[1].items.length || !hasNextPage;
  };
  const loadMoreRows = isPageLoading ? async () => {} : loadNextPage;

  const rowRenderer = ({ key, index, style, parent }: ListRowProps) => {
    const content =
      !!sections.data[1].items[index] && !sections.isLoading ? (
        sections.data[1].items[index].element
      ) : (
        <div tw={'w-full h-full bg-gray-200 rounded-md'}></div>
      );
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={index}
        rowIndex={index}
        parent={parent}
        ref={nodeRef}
      >
        {({ registerChild }) => (
          <ItemWrapper
            key={key}
            role="menuitem"
            ref={registerChild as any}
            style={{
              ...style,
              ...(customCSS ? customCSS(sections.data[1].data?.[index]) : ''),
              textWrap: 'nowrap',
            }}
            onClick={
              !sections.data[1].items[index]
                ? undefined
                : sections.data[1].items[index].onClick
            }
          >
            {content}
          </ItemWrapper>
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      {open && (
        <div
          tw="z-[70] absolute left-0 top-8 py-1 w-full min-w-full max-w-[240px] lg:w-max mt-2 rounded-md shadow-lg bg-white"
          ref={ref}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {sections.data?.map((currentItem, index) => (
            <React.Fragment key={index}>
              {index === 0 ? (
                <>
                  <ItemWrapper
                    key={`item-wrapper-${index}`}
                    role="menuitem"
                    onClick={currentItem.items[0].onClick}
                  >
                    {currentItem.items[0].element}
                  </ItemWrapper>
                  <Line key={`line-${index}`} />
                </>
              ) : (
                <InfiniteLoader
                  key={`infinite-loader-${index}`}
                  isRowLoaded={isRowLoaded}
                  loadMoreRows={loadMoreRows}
                  rowCount={rowCount}
                  threshold={10}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer
                      style={{
                        width: '100%',
                      }}
                    >
                      {({ width }) => (
                        <List
                          key={`list-${index}`}
                          tw="shadow-md bg-white no-scrollbar"
                          height={325}
                          onRowsRendered={onRowsRendered}
                          rowHeight={cache.rowHeight}
                          ref={registerChild}
                          rowRenderer={rowRenderer}
                          deferredMeasurementCache={cache}
                          width={width}
                          rowCount={rowCount}
                          noRowsRenderer={() => (
                            <ItemWrapper>No results found</ItemWrapper>
                          )}
                        />
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};
export default ContextMenu;

const ItemWrapper = styled.li(
  tw`block w-full h-full capitalize bg-white cursor-pointer p-2 text-sm text-gray-700 hover:(bg-gray-50)`
);

const Line = styled.div(tw`h-px bg-gray-100 mx-4 my-1 shadow-md`);
