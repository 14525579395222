import { faker } from '@faker-js/faker';
import { capitalize } from 'lodash';

interface SectionHeaderDefaultConfig {
  subTitle: string;
  title: string;
  description: string;
  primaryButtonLabel: string;
  linkButtonLabel: string;
  placeholderValue: {
    subTitle: string;
    title: string;
    description: string;
    primaryButtonLabel: string;
    linkButtonLabel: string;
  };
}

export const getSectionHeaderDefaultConfig = (
  paragraphs = 1,
  values: Partial<SectionHeaderDefaultConfig> = {}
): SectionHeaderDefaultConfig => {
  return {
    subTitle: values.subTitle || '',
    title: values.title || '',
    description: values.description || '',
    primaryButtonLabel: '',
    linkButtonLabel: '',
    placeholderValue: {
      //subTitle: capitalize(faker.lorem.words(2)),
      subTitle:
        values.placeholderValue?.subTitle ||
        capitalize(faker.company.buzzVerb() + ' ' + faker.company.buzzNoun()),
      //title: capitalize(faker.lorem.words(5)),
      title:
        values.placeholderValue?.title ||
        capitalize(faker.company.buzzPhrase()),
      description:
        values.placeholderValue?.description ||
        faker.lorem.paragraphs(paragraphs),
      primaryButtonLabel: 'Check our open positions',
      linkButtonLabel: 'Contact us',
    },
  };
};

export const defaultTextClasses = (
  title?: string,
  description?: string,
  subTitle?: string
) => {
  const textClasses: { [key: string]: string } = {};
  if (!title) textClasses.title = 'opacity-50';
  if (!description) textClasses.description = 'opacity-50';
  if (!subTitle) textClasses.subTitle = 'opacity-50';

  return textClasses;
};
