import 'twin.macro';

import React from 'react';
import ColleaguesListItem from './ColleaguesListItem';
import { User } from 'types/userTypes';

const ColleaguesList = ({
  onResend,
  onUnlink,
  colleagues,
  loading,
}: {
  onResend: (colleague: User) => void;
  onUnlink: (colleague: User) => void;
  colleagues: User[];
  loading: boolean;
}) => (
  <div tw="flex w-full flex-row flex-wrap gap-3">
    {colleagues.length === 0 && "You haven't added any colleagues yet"}
    {colleagues.map((colleague: User, _, arr) => (
      <div key={colleague.id} tw="w-full">
        <ColleaguesListItem
          colleague={colleague}
          onResend={() => onResend(colleague)}
          onUnlink={() => onUnlink(colleague)}
          removable={arr.length > 1}
          loading={loading}
        />
      </div>
    ))}
  </div>
);

export default ColleaguesList;
