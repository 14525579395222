import tw, { TwStyle, css, styled } from 'twin.macro';

export const Container = styled.div<{
  width?: number;
  isFrom: 'left' | 'right';
  show?: boolean;
  styles?: TwStyle;
}>`
  ${({ width }) =>
    width &&
    css`
      --max-container-width: ${width}px;
    `}

  ${tw`
    w-full
    h-full
    max-w-0
    overflow-hidden
    bg-white
    border-gray-200
    transition-[max-width]
    ease-in-out
    duration-200
  `}

  ${({ styles }) => styles}

  ${({ isFrom }) => (isFrom === 'left' ? tw`border-r-2` : tw`border-l-2`)}

  ${({ show }) =>
    show &&
    tw`
      max-w-[var(--max-container-width)]
    `}
`;
