import { gql } from '@apollo/client';

export const UPDATE_EDITOR_DATA = gql`
  mutation updateEditorData {
    editorData(input: $input)
      @rest(type: "AdEditorData", path: "/v4/ad-variants", method: "POST")
  }
`;

export const DELETE_VARIANTS = gql`
  mutation updateEditorData($variantId: ID!) {
    editorData(input: $input, id: $variantId)
      @rest(
        type: "AdEditorData"
        path: "/v4/ad-variants/{args.id}"
        method: "DELETE"
      )
  }
`;

export const GENERATE_VIDEO = gql`
  mutation generateVideo($vacancyId: Int!, $format: String!) {
    video(input: { vacancy_id: $vacancyId, format: $format })
      @rest(
        type: "CustomerResource"
        path: "/v4/video/generate"
        method: "POST"
      ) {
      id
      thread_id
      path
      thumbnail_path
      type
      meta
      created_at
      width
      height
    }
  }
`;

export const UPDATE_VIDEO = gql`
  mutation updateVideo(
    $vacancyId: Int!
    $format: String!
    $feedback: String!
    $threadId: String!
    $oldVideoId: String!
  ) {
    video(
      input: {
        vacancy_id: $vacancyId
        format: $format
        feedback: $feedback
        thread_id: $threadId
        old_video_id: $oldVideoId
      }
    )
      @rest(
        type: "CustomerResource"
        path: "/v4/video/update"
        method: "POST"
      ) {
      id
      thread_id
      path
      thumbnail_path
      type
      meta
      created_at
      width
      height
    }
  }
`;

export const COPY_VIDEO = gql`
  mutation copyVideo($vacancyId: Int!, $format: String!, $threadId: String!) {
    video(
      input: { vacancy_id: $vacancyId, format: $format, thread_id: $threadId }
    ) @rest(type: "CustomerResource", path: "/v4/video/copy", method: "POST") {
      id
      thread_id
      path
      thumbnail_path
      type
      meta
      created_at
      width
      height
    }
  }
`;
