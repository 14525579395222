import 'twin.macro';
import React, { useMemo } from 'react';
import FacebookFeedPreview from './channels/facebook/FeedPreview';
import InstagramFeedPreview from './channels/instagram/FeedPreview';
import LinkedInFeedPreview from './channels/linkedin/FeedPreview';
import InstagramStoryPreview from './channels/instagram/StoryPreview';
import FacebookReelsPreview from './channels/facebook/ReelsPreview';
import InstagramReelsPreview from './channels/instagram/ReelsPreview';
import TikTokPreview from './channels/tiktok/Preview';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import AdEditorToolbar from './Toolbar';
import { AdChannel, AdPlacement } from 'types/adTypes';
import VideoGenProvider from 'context/VideoGenContext';

export const AdEditorPreview: React.FC = () => {
  const { currentVariant, previewChannel, setActiveTab } = useAdEditorContext();

  const previewer = useMemo(() => {
    switch (currentVariant?.placement) {
      case AdPlacement.FEED:
        switch (previewChannel) {
          case AdChannel.FACEBOOK:
            return <FacebookFeedPreview />;
          case AdChannel.INSTAGRAM:
            return <InstagramFeedPreview />;
          case AdChannel.LINKEDIN:
            return <LinkedInFeedPreview />;
          default:
            return <FacebookFeedPreview />;
        }

      case AdPlacement.STORIES:
        switch (previewChannel) {
          case AdChannel.FACEBOOK:
          case AdChannel.INSTAGRAM:
            return <InstagramStoryPreview />;
          default:
            return <InstagramStoryPreview />;
        }

      case AdPlacement.REELS:
        switch (previewChannel) {
          case AdChannel.FACEBOOK:
            return <FacebookReelsPreview />;
          case AdChannel.INSTAGRAM:
            return <InstagramReelsPreview />;
          case AdChannel.TIKTOK:
            return <TikTokPreview />;
          default:
            return <FacebookReelsPreview />;
        }
    }
  }, [currentVariant?.placement, previewChannel]);

  return (
    <div tw="relative">
      <VideoGenProvider>
        {previewer}
        <div
          id="toolbar"
          tw="mb-2 max-w-fit sm:(mt-0 absolute top-0 -right-16 left-auto) z-50 fixed bg-white bottom-0 left-0"
        >
          <AdEditorToolbar setActiveTab={setActiveTab} />
        </div>
      </VideoGenProvider>
    </div>
  );
};

export default AdEditorPreview;
