import Button, { ButtonProps } from 'components/button/Button';
import { Error, Body, Title1 } from 'components/typography/Typography';
import useModalContext from 'hooks/context/modals-context';
import { omit } from 'lodash';
import React, { FC, ReactNode, useState } from 'react';
import * as Styled from './styles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Input from 'components/form/input/Input';
import 'twin.macro';
import RadioGroup from 'components/form/RadioGroup';
import tw from 'twin.macro';

export interface ConfirmActionModalProps {
  modalId: string;
  actionBtnPostion?: 'left' | 'center' | 'between' | 'right';
  header?: string | ReactNode;
  body?: string | ReactNode;

  // confirm button
  confirmButtonLabel?: string;
  confirmButtonProps?: ButtonProps;
  confirmActionFn?: (args?: { reason: string }) => void;
  // cancel button
  cancelButtonLabel?: string;
  cancelButtonProps?: ButtonProps;
  cancelActionFn?: () => void;

  // optional feature
  reasons?: string[];
}

/**
 * this can only be used if the modal is created using the useModalContext hook
 * NEEDED PARAMS:
 * @param modalId Modal ID
 * @param actionBtnPostion Action Button Position - default is between - choices: 'left' | 'center' | 'between' | 'right'
 *
 */
const ConfirmActionModal: FC<ConfirmActionModalProps> = ({
  modalId,
  actionBtnPostion = 'between',
  header,
  body,
  confirmButtonLabel,
  confirmButtonProps,
  confirmActionFn,
  cancelButtonLabel,
  cancelButtonProps,
  cancelActionFn,
  reasons,
}) => {
  const { removeModal } = useModalContext();
  const [reasonChoice, setReasonChoice] = useState('');

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      reason: '',
    },
  });

  const {
    control,
    errors,
    setValue,
    getValues,
    formState,
    reset,
    handleSubmit,
  } = formMethods;

  const hasCancel = cancelButtonLabel !== undefined;
  const hasConfirm = confirmButtonLabel !== undefined && confirmActionFn;

  const showContent = header || body;
  const showActions = hasCancel || hasConfirm;

  const handleConfirm = (formValues?: any) => {
    if (confirmActionFn) {
      confirmActionFn(formValues ?? null);
      reset();
    }
    removeModal(modalId);
  };

  const handleCancel = () => {
    cancelActionFn?.();
    removeModal(modalId);
  };

  const handleFormSubmit = () => {
    const formValues = getValues();
    if (
      formValues?.reason !== '' &&
      Object.keys(formState.errors).length === 0
    ) {
      handleConfirm(formValues);
    }
  };

  const isButtonDisabled =
    reasons !== undefined &&
    ((!formState.isValid && !formState.isDirty) ||
      Object.keys(errors).length > 0);

  const handleReasonChoices = (choice: string) => {
    if (choice !== 'Other') {
      setValue('reason', choice, {
        shouldDirty: true,
      });
    }
    setReasonChoice(choice);
  };

  const isOther = reasonChoice === 'Other';

  return (
    <FormProvider {...formMethods}>
      <form tw="w-full flex flex-col" onSubmit={handleSubmit(handleFormSubmit)}>
        <div tw="w-full">
          {showContent && (
            <Styled.ContentContainer>
              {header && (
                <Title1 tw="font-medium text-lg mb-2">{header}</Title1>
              )}
              {body && <Body>{body}</Body>}
            </Styled.ContentContainer>
          )}

          {reasons !== undefined && (
            <div tw="w-full max-w-[600px] p-4 flex-col">
              <div tw="w-full">
                <RadioGroup
                  name="language-select-radiogroup"
                  onChange={(e) => {
                    if (!e.currentTarget) return;
                    handleReasonChoices(e.currentTarget.value);
                  }}
                  items={reasons.map((ans) => {
                    return {
                      id: ans,
                      label: ans,
                      value: ans,
                    };
                  })}
                />
              </div>

              {/**
               * do not unmount since react form hooks
               * cannot see the value of "reason". just use
               * CSS Hide/Show
               */}
              <div tw="w-full" css={[isOther ? tw`block` : tw`hidden`]}>
                <Controller
                  id="reason"
                  name="reason"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Reason is required',
                    },
                  }}
                  render={({ value, onChange }) => (
                    <>
                      <Input
                        textarea
                        id="reason"
                        name="reason"
                        value={value}
                        placeholder="Enter a Reason"
                        ariaInvalid={errors['reason'] !== undefined}
                        onChange={onChange}
                      />
                      {formState.errors['reason']?.message && (
                        <Error>{formState.errors['reason'].message}</Error>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          )}

          {showActions && (
            <Styled.ActionsContainer position={actionBtnPostion}>
              {hasConfirm && (
                <Button
                  type="submit"
                  disabled={isButtonDisabled}
                  role="button"
                  variant={
                    isButtonDisabled
                      ? 'default'
                      : confirmButtonProps?.variant ?? 'indigo'
                  }
                  {...omit(confirmButtonProps, ['children', 'onClick'])}
                >
                  {confirmButtonLabel}
                </Button>
              )}

              {hasCancel && (
                <Button
                  role="button"
                  variant="transparent"
                  onClick={handleCancel}
                  {...omit(cancelButtonProps, ['children', 'onClick'])}
                >
                  {cancelButtonLabel}
                </Button>
              )}
            </Styled.ActionsContainer>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default ConfirmActionModal;
