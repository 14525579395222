import React from 'react';
import tw, { styled } from 'twin.macro';

import { AdChannel } from 'types/adTypes';
import ChannelIcon from '../channel-icon/ChannelIcon';

export interface ChannelButtonProps {
  channelType: AdChannel;
  isActive?: boolean;
  isToggle?: boolean;
  disabled?: boolean;
  grayScale?: boolean;
}
const ChannelButton: React.FC<ChannelButtonProps> = ({
  channelType,
  isActive = true,
  isToggle = false,
  disabled = false,
  grayScale = false,
}) => {
  return (
    <Wrapper isActive={isActive} disabled={disabled} isToggle={isToggle}>
      {isActive && isToggle && (
        <div tw="bg-green-300 p-1.5 w-0.5 h-0.5 rounded-full absolute -top-1 -right-1"></div>
      )}
      <ChannelIcon
        channel={channelType}
        disabled={!isActive}
        grayScale={grayScale}
      />
    </Wrapper>
  );
};

const Wrapper = styled.button<{
  isActive: boolean;
  isToggle?: boolean;
}>`
  ${tw`
    h-11 w-11 rounded-lg bg-gray-50 text-gray-600 flex justify-center items-center
    border border-gray-100 shadow-sm relative cursor-pointer transition-colors
    hover:(border-2 border-green-300)
    disabled:(opacity-50 border-none)
  `}
  ${({ isActive }) =>
    isActive &&
    tw`
      border-2 border-gray-600 text-gray-600 pointer-events-auto
      hover:(border-2 border-gray-400 text-gray-400)
      disabled:(opacity-90 border-gray-600 text-gray-700 bg-gray-100 border-solid)
  `}
  ${({ isActive, isToggle }) =>
    isActive &&
    isToggle &&
    tw`border-green-300 bg-green-50 hover:(border-gray-100 bg-transparent)`}
  ${({ disabled }) => disabled && tw`!opacity-50`}
`;

export default ChannelButton;
