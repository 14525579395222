import React from 'react';
import Dropdown, { DropdownItem } from '../Dropdown';
import { TwStyle } from 'twin.macro';
import Button from 'components/button/Button';
import tw from 'twin.macro';

interface MultiSelectDropDownProps {
  items: DropdownItem[];
  title: string;
  value: DropdownItem[];
  disabled?: boolean;
  placeholder?: string;
  noResultsLabel?: string;
  enableCustom?: boolean;
  enableSearch?: boolean;
  customStyle?: TwStyle;
  onChange?: (value?: DropdownItem[]) => void;
  onClear?: () => void;
}

const MultiSelectDropdown: React.FC<MultiSelectDropDownProps> = ({
  items,
  title,
  value,
  disabled,
  enableCustom = false,
  enableSearch = true,
  noResultsLabel,
  onChange,
  onClear,
}) => {
  const [selectedItems, setSelectedItems] =
    React.useState<DropdownItem[]>(value);

  React.useEffect(() => {
    if (value.length !== selectedItems.length) setSelectedItems(value);
  }, [value]);
  React.useEffect(() => onChange?.(selectedItems), [selectedItems]);

  const changeHandler = (key: string | number) => {
    setSelectedItems((prev) => {
      return prev.find((item) => item.key === key)
        ? prev.filter((item) => item.key !== key)
        : [...prev, { key: key.toString(), label: key.toString() }];
    });
  };

  const clearAll = () => setSelectedItems([]);
  const selectAll = () => setSelectedItems(items);

  const dropdownTitle = React.useMemo(() => {
    if (selectedItems.length === 0) return title;
    if (selectedItems.length === 1)
      return items.find((item) => item.key === selectedItems[0].key)?.label;
    return `${selectedItems.length} selected`;
  }, [selectedItems, title, value]);

  return (
    <div tw="h-fit">
      <Dropdown
        isMultiSelect
        items={items}
        selectedItems={selectedItems}
        onChange={changeHandler}
        onClear={onClear}
        disabled={disabled}
        enableCustom={enableCustom}
        title={dropdownTitle}
        noResultsLabel={noResultsLabel}
        searchable={enableSearch}
        quickActions={
          <div tw="flex justify-between gap-2">
            <Button
              variant="outline"
              customStyle={tw`py-1.5 px-3 text-indigo-500 bg-indigo-50 text-xs`}
              onClick={() => clearAll()}
            >
              Clear Selection
            </Button>
            <Button
              variant="outline"
              customStyle={tw`py-1.5 px-3 text-indigo-500 bg-indigo-50 text-xs`}
              onClick={() => selectAll()}
            >
              Select All
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default MultiSelectDropdown;
