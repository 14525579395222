import React from 'react';
import { RouteGuards } from 'types/routeTypes';
import GuardedRoute from 'routing/GuardedRoute';
import LeadsOverview from './LeadsOverview';
import { Route, Routes } from 'react-router-dom';

const Leads: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
            <LeadsOverview />
          </GuardedRoute>
        }
      />
    </Routes>
  );
};

export default Leads;
