import 'twin.macro';
import React, { FC } from 'react';
import Button, { ButtonType } from 'components/button/Button';
import tw, { styled } from 'twin.macro';
import { IWizardStep } from './WizardStep';

interface WizardNavigationProps {
  currentIndex: number;
  totalSteps: number;
  currentStep: IWizardStep;
  onHandleNavigateToStep: (index: number) => void;
}

const WizardNavigation: FC<WizardNavigationProps> = ({
  currentIndex,
  totalSteps,
  currentStep,
  onHandleNavigateToStep,
}) => {
  if (!currentStep) return null;

  const isEndStep = currentIndex === totalSteps - 1;
  const { fields } = currentStep;

  // if all fields have "moveOnAnswer" set to true, then we don't need to show the nav button
  const fieldsWithMoveOnAnswer = fields?.filter((field) => field.moveOnAnswer);
  const hideNavButton = fieldsWithMoveOnAnswer?.length === fields?.length;

  const buttonProps: {
    key: string;
    type: ButtonType;
    customStyle: any;
    onClick?: () => void;
  } = {
    key: isEndStep ? 'submit' : 'next',
    type: isEndStep ? 'submit' : 'button',
    customStyle: tw`w-full md:w-auto justify-center`,
    ...(!isEndStep && {
      onClick: () => onHandleNavigateToStep(currentIndex + 1),
    }),
  };

  if (hideNavButton) return null;

  return (
    <ActionGroup>
      <Button {...buttonProps}>Next</Button>
    </ActionGroup>
  );
};

const ActionGroup = styled.div`
  ${tw`w-full flex items-center flex-nowrap mt-4 gap-2 justify-end`}
`;

export default WizardNavigation;
