import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query Notifications {
    notifications
      @rest(type: "Notification", path: "/v4/notifications", method: "GET") {
      id
      title
      date
      type
      description
      url
      status
    }
  }
`;
