import { useQuery } from 'hooks/sympl-query';
import { Image, VideoCamera } from '@phosphor-icons/react';
import AutoTable from 'components/table/auto-table/AutoTable';
import { Title3 } from 'components/typography/Typography';
import {
  GET_REPORTING_DATA_PER_CREATIVE,
  GET_IMAGE_DATA,
  GET_VIDEO_DATA,
} from 'graphql/reporting/queries';
import useReportingContext from 'hooks/context/reporting-context';
import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { roundNumber } from 'utils/numberHelpers';
import { ReportingData } from 'context/ReportingContext';
import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import { tableColumns } from './Reporting';
import useNavigationContext from 'hooks/context/nav-context';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/ui/hover-card';

type CreativeReportingType = {
  [key in 'image' | 'video']: {
    [key: string]: ReportingData;
  };
} & { total: ReportingData };

type DetailReportingData = {
  [key: string]: ReportingData & {
    path_url: string;
    thumbnail_url: string;
  };
};

type ImageDetailsType = {
  reporting: {
    imageDetails: DetailReportingData;
  };
};

type VideoDetailsType = {
  reporting: {
    videoDetails: DetailReportingData;
  };
};

const Visuals: React.FC = () => {
  const { queryString, reportingScreen } = useReportingContext();
  const { activeCustomer } = useNavigationContext();
  const [data, setData] = useState<{ reporting: CreativeReportingType }>();
  const [imageData, setImageData] = useState<DetailReportingData>();
  const [videoData, setVideoData] = useState<DetailReportingData>();

  const { loading, refetch } = useQuery<
    { reporting: CreativeReportingType },
    { queryString: string }
  >(GET_REPORTING_DATA_PER_CREATIVE, {
    fetchPolicy: 'network-only',
    skip: reportingScreen !== 'creatives',
    variables: {
      queryString: queryString,
    },
    onCompleted: (data) => {
      setData(data);
    },
  });

  const { loading: imageDetailsLoading, refetch: refetchImageDetails } =
    useQuery<ImageDetailsType, { queryString: string }>(GET_IMAGE_DATA, {
      fetchPolicy: 'network-only',
      skip: reportingScreen !== 'creatives',
      variables: {
        queryString: queryString,
      },
      onCompleted: (data) => setImageData(data.reporting.imageDetails),
    });

  const { loading: videoDetailsLoading, refetch: refetchVideoDetails } =
    useQuery<VideoDetailsType, { queryString: string }>(GET_VIDEO_DATA, {
      fetchPolicy: 'network-only',
      skip: reportingScreen !== 'creatives',
      variables: {
        queryString: queryString,
      },
      onCompleted: (data) => setVideoData(data.reporting.videoDetails ?? {}),
    });

  useEffect(() => {
    refetch();
    refetchImageDetails();
    refetchVideoDetails();
  }, [queryString, activeCustomer]);

  const cols = (title: string = 'Channels') => {
    const [first, ...rest] = tableColumns;
    return [[title, ...first], ...rest];
  };

  const getCreativeMetric = (
    field: keyof ReportingData,
    creativeType: 'image' | 'video' | 'total',
    unit?: 'currency' | 'percentage',
    noDecimals: boolean = false,
    isDetail?: boolean,
    path_url?: string
  ) => {
    const round = noDecimals ? true : false;
    if (isDetail && path_url) {
      const metricType = creativeType === 'image' ? imageData : videoData;
      return roundNumber(
        metricType?.[path_url]?.[field] ?? 0,
        unit === 'currency',
        unit === 'percentage',
        round
      );
    } else {
      const metricType =
        creativeType === 'total'
          ? data?.reporting.total
          : data?.reporting[creativeType];
      return roundNumber(
        (metricType as ReportingData)?.[field] ?? 0,
        unit === 'currency',
        unit === 'percentage',
        round
      );
    }
  };

  const createRow = (
    creativeType: 'image' | 'video' | 'total',
    index: number,
    isDetail: boolean = false,
    customFirstElement?: React.ReactNode,
    pathUrl: string | undefined = undefined
  ) => {
    return [
      <>
        {customFirstElement ? (
          customFirstElement
        ) : (
          <div tw="flex items-center gap-2" key={index}>
            {creativeType === 'image' ? (
              <Image size={16} weight="fill" />
            ) : creativeType === 'video' ? (
              <VideoCamera size={16} weight="fill" />
            ) : null}
            <p>{creativeType}</p>
          </div>
        )}
      </>,
      <p>
        {getCreativeMetric(
          'impressions',
          creativeType,
          undefined,
          undefined,
          isDetail,
          pathUrl
        )}
      </p>,
      <p>
        {getCreativeMetric(
          'clicks',
          creativeType,
          undefined,
          true,
          isDetail,
          pathUrl
        )}
      </p>,
      <p>
        {getCreativeMetric(
          'visits',
          creativeType,
          undefined,
          true,
          isDetail,
          pathUrl
        )}
      </p>,
      <p>
        {getCreativeMetric(
          'applications',
          creativeType,
          undefined,
          true,
          isDetail,
          pathUrl
        )}
      </p>,
      <p>
        {getCreativeMetric(
          'spend',
          creativeType,
          'currency',
          undefined,
          isDetail,
          pathUrl
        )}
      </p>,
      <p data-divider={true}></p>,
      <p>
        {getCreativeMetric(
          'ctr',
          creativeType,
          'percentage',
          undefined,
          isDetail,
          pathUrl
        )}
      </p>,
      <p>
        {getCreativeMetric(
          'conversion',
          creativeType,
          'percentage',
          undefined,
          isDetail,
          pathUrl
        )}
      </p>,
      <p>
        {getCreativeMetric(
          'cpm',
          creativeType,
          'currency',
          undefined,
          isDetail,
          pathUrl
        )}
      </p>,
      <p>
        {getCreativeMetric(
          'cpc',
          creativeType,
          'currency',
          undefined,
          isDetail,
          pathUrl
        )}
      </p>,
      <p>
        {getCreativeMetric(
          'cpa',
          creativeType,
          'currency',
          undefined,
          isDetail,
          pathUrl
        )}
      </p>,
    ];
  };

  const rows = React.useMemo(() => {
    return (['image', 'video'] as Array<'image' | 'video'>).map(
      (creativeType, index) => {
        return createRow(creativeType, index);
      }
    );
  }, [data?.reporting]);

  const imageRows = React.useMemo(() => {
    return Object.entries(imageData ?? {}).map((el, i) => {
      return createRow(
        'image',
        i,
        true,
        <HoverCard openDelay={300}>
          <HoverCardTrigger>
            <img src={el[0]} alt="Base visual" tw="h-8" />
          </HoverCardTrigger>
          <HoverCardContent side="right">
            <img
              src={el[1].path_url}
              alt="Base visual"
              tw="max-w-[600px] max-h-[600px]"
            />
          </HoverCardContent>
        </HoverCard>,
        el[0]
      );
    });
  }, [imageData]);

  const videoRows = React.useMemo(() => {
    return Object.entries(videoData ?? {}).map((el, i) => {
      return createRow(
        'video',
        i,
        true,
        <HoverCard openDelay={300}>
          <HoverCardTrigger>
            <img src={el[0]} alt="Base visual" tw="h-8" />
          </HoverCardTrigger>
          <HoverCardContent side="right">
            <video tw="max-w-[600px] max-h-[600px]" muted autoPlay>
              <source src={el[1].path_url} type="video/mp4" />
              We can't show this video. Our apologies.
            </video>
          </HoverCardContent>
        </HoverCard>,
        el[0]
      );
    });
  }, [videoData]);

  const summationRow = React.useMemo(() => {
    return createRow('total', 0);
  }, [data]);

  return (
    <div tw="mt-8 flex flex-col gap-12">
      {/* Overview */}
      {loading ? (
        <div tw="mt-8">
          <SkeletonList />
        </div>
      ) : (
        <AutoTable
          tw="mt-4"
          rows={rows}
          columns={cols('Visual type')}
          summationRow={summationRow}
          headerStyling={tw`text-center w-24`}
          firstElementStyling={tw`w-40 flex justify-center`}
          elementStyling={tw`w-24 text-right`}
          scrollable={false}
          alternate
          emptyLabel="You have no reporting data yet. Please wait a couple of hours after your campaign was published."
          emptyLabelStyling={tw`justify-start pl-14`}
        />
      )}

      {/* IMAGES */}
      {!imageDetailsLoading && imageData ? (
        <div tw="flex flex-col gap-2">
          <Title3>Images</Title3>
          <AutoTable
            tw="mt-20"
            rows={imageRows}
            columns={cols('Visual')}
            headerStyling={tw`text-center w-24`}
            firstElementStyling={tw`w-40 flex justify-center`}
            elementStyling={tw`w-24 text-right`}
            scrollable={false}
            alternate
            emptyLabel="There are no images used in your selected campaigns"
            emptyLabelStyling={tw`justify-start pl-14`}
          />
        </div>
      ) : (
        <div tw="mt-8">
          <SkeletonList />
        </div>
      )}

      {/* VIDEOS */}
      {!videoDetailsLoading && videoData ? (
        <div tw="flex flex-col gap-2">
          <Title3>Video's</Title3>
          <AutoTable
            tw="mt-20"
            rows={videoRows}
            columns={cols('Visual')}
            headerStyling={tw`text-center w-24`}
            firstElementStyling={tw`w-40 flex justify-center`}
            elementStyling={tw`w-24 text-right`}
            scrollable={false}
            alternate
            emptyLabel="There are no video's used in your selected campaigns"
            emptyLabelStyling={tw`justify-start pl-14`}
          />
        </div>
      ) : (
        <div tw="mt-8">
          <SkeletonList />
        </div>
      )}
    </div>
  );
};

export default Visuals;
