import React, { createRef, useEffect, useRef, useState } from 'react';
import { Plus, Trash } from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';
import { updateInputGroupValue } from 'utils/typeHelpers';

export type InputGroupProps = {
  id: string;
  placeholder?: string;
  value: string[];
  minInputs?: number;
  maxInputs?: number;
  ariaInvalid?: boolean;
  onMaxError?: () => void;
  onMinError?: () => void;
  onChange: (value: string[]) => void;
  onInputAdded?: (index: number) => void;
  onInputRemoved?: (index: number) => void;
};

export const InputGroup: React.FC<InputGroupProps> = ({
  id,
  placeholder,
  value,
  minInputs = 0,
  maxInputs,
  ariaInvalid,
  onMaxError,
  onMinError,
  onChange,
  onInputAdded,
  onInputRemoved,
}) => {
  const addedNewInput = useRef(false);

  const [inputIndices, setInputIndices] = useState<number[]>([]);

  useEffect(
    () =>
      setInputIndices(
        Array.from(Array(Math.max(value?.length ?? 0, minInputs)), (_d, i) => i)
      ),
    [minInputs, value]
  );

  const inputBlurHandler = (
    e: React.FocusEvent<HTMLInputElement>,
    inputRef: React.RefObject<HTMLInputElement>,
    inputIndex: number
  ) => {
    e.preventDefault();

    if (
      !inputRef.current ||
      value?.[inputIndex] === undefined ||
      value?.[inputIndex].constructor !== String
    )
      return;

    // Modify the input values
    const oldValues = [...value];
    oldValues[inputIndex] = inputRef.current.value;

    // Update the input values
    onChange(value);
  };

  const inputKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') addInputHandler(e);
  };

  const addInputHandler = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    // Add the new input index
    if (maxInputs && inputIndices.length >= maxInputs) {
      onMaxError?.();
      return;
    }
    addedNewInput.current = true;

    setInputIndices([...inputIndices, inputIndices.length + 1]);
    onInputAdded?.(inputIndices.length + 1);
  };

  const removeInputHandler = (inputIndex: number) => {
    // Only remove item if there are enough items left
    if (inputIndices.length - 1 < minInputs) {
      onMinError?.();
      return;
    }

    setInputIndices(
      inputIndices.filter((_value, index) => index !== inputIndex)
    );

    if (value) {
      onChange?.(value.filter((_value, index) => index !== inputIndex));
      onInputRemoved?.(inputIndex);
    }
  };

  const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputIndex: number
  ) => onChange(updateInputGroupValue(value, e.target.value, inputIndex));

  const getInputValue = (inputIndex: number) => {
    if (!value || !value.length || !value[inputIndex]) return '';

    return value[inputIndex];
  };

  return (
    <div tw="max-w-full">
      <input
        name={id}
        type="text"
        tw="hidden invisible"
        aria-invalid={ariaInvalid}
      />

      <ul tw="space-y-2">
        {inputIndices.map((val, index) => {
          const inputRef = createRef<HTMLInputElement>();
          const inputId = `${id}-${index}`;

          return (
            <ItemWrapper key={val + index}>
              <Input
                type="text"
                id={inputId}
                name={inputId}
                ref={inputRef}
                placeholder={placeholder}
                className="input-group-field"
                defaultValue={getInputValue(index)}
                onBlur={(e) => inputBlurHandler(e, inputRef, index)}
                onKeyDown={(e) => inputKeyDownHandler(e)}
                onChange={(e) => inputChangeHandler(e, index)}
                autoFocus={
                  index === inputIndices.length - 1 && addedNewInput.current
                }
              />
              {inputIndices.length > minInputs && (
                <Trash
                  weight="bold"
                  onClick={() => removeInputHandler(index)}
                  tw="absolute text-2xl text-gray-300 mr-4 top-3 left-auto -right-px z-[1] cursor-pointer hover:text-gray-500 focus:text-gray-500 "
                />
              )}
            </ItemWrapper>
          );
        })}
      </ul>

      {minInputs !== maxInputs && inputIndices.length !== maxInputs && (
        <AddButton onClick={(e) => addInputHandler(e)}>
          <Plus weight="bold" size={24} />
        </AddButton>
      )}
    </div>
  );
};

const ItemWrapper = styled.li(tw`relative`);

const Input = styled.input(
  tw` block w-full min-h-[50px] px-4 border border-gray-100 rounded hover:cursor-text focus:(outline-none outline outline-blue-500)`
);

const AddButton = styled.button`
  ${tw`
    bg-gray-50 py-2 mt-3 rounded text-gray-400 w-full
    flex justify-center border border-gray-100 cursor-pointer
    hover:bg-gray-100 active:bg-gray-200/70
  `}
`;

export default InputGroup;
