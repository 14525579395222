import React from 'react';
import Container from 'components/container/Container';
import Button from 'components/button/Button';
import 'twin.macro';

import { Body } from 'components/typography/Typography';
import { CheckCircle } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

export const IntegrationSucceeded = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div tw="flex flex-col items-center gap-8">
        <div tw="flex flex-col items-center">
          <CheckCircle weight="bold" color="green" size={40} />
          <Body>Integrated successfully</Body>
        </div>
        <Body isLight>
          It could take a couple of minutes for your data to be synced.
        </Body>
        <div tw="flex flex-col items-center gap-2">
          <Button onClick={() => navigate('integrations')}>
            Add another integration
          </Button>
          <Button variant="link" onClick={() => navigate('integrations')}>
            Go back
          </Button>
        </div>
      </div>
    </Container>
  );
};
