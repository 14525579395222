import React from 'react';
import { SealCheck, SealWarning } from '@phosphor-icons/react';
import 'twin.macro';

import Callout from 'components/callout/Callout';
import { showIntercom } from 'utils/intercomHelper';

interface DnsStatusProps {
  status: 'verified' | 'pending';
  validations: Validation[];
  label?: string;
}

export interface Validation {
  type: string;
  name: string;
  value: string;
}

const DnsStatus: React.FC<DnsStatusProps> = ({
  status,
  label,
  validations,
}) => {
  return status === 'verified' ? (
    <div tw="bg-green-100 text-green-800 p-2 mt-4 flex justify-between">
      <div tw="flex items-center">
        <Callout color="green" icon={<SealCheck weight="fill" size={24} />} />
        Your domain is verified and connected.
      </div>
      <p
        tw="text-green-800/30 text-sm align-middle mt-0.5 cursor-pointer"
        onClick={() => showIntercom()}
      >
        Want to change your subdomain?
      </p>
    </div>
  ) : (
    <div tw="mt-4 px-4 p-2 bg-gray-100 rounded-sm flex flex-col">
      {label && (
        <div tw="bg-yellow-100 text-yellow-800 p-2 mt-2 mb-4 flex justify-between">
          <div tw="flex items-center">
            <Callout
              color="yellow"
              icon={<SealWarning weight="fill" size={24} />}
            />
            {label}
          </div>
        </div>
      )}
      <div tw="flex text-gray-500 border-b-2">
        <p tw="min-w-[8ch]">Type</p>
        <p tw="min-w-[16ch] pl-2 mr-2 border-x-2">Name</p>
        <p tw="w-full">Value</p>
      </div>
      <div>
        {validations.map((validation, index) => (
          <div key={index} tw="flex my-1 first-of-type:mt-2 last-of-type:mb-2">
            <p tw="min-w-[8ch]">{validation.type}</p>
            <p tw="min-w-[16ch] pl-2 mr-2 border-x-2">{validation.name}</p>
            <p tw="w-full">{validation.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DnsStatus;
