import React from 'react';
import { LogoIconProps } from 'components/icons/SocialIcon';

const LogoMicrosoft: React.FC<LogoIconProps> = ({
  width = '16px',
  height = '16px',
  color = 'currentColor',
  grayScale = false,
  useNaturalColor = false,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill={color}
      filter={grayScale ? 'grayscale(1)' : 'none'}
    >
      <path
        fill={useNaturalColor ? '#ff5722' : color}
        d="M6 6H22V22H6z"
        transform="rotate(-180 14 14)"
      ></path>
      <path
        fill={useNaturalColor ? '#4caf50' : color}
        d="M26 6H42V22H26z"
        transform="rotate(-180 34 14)"
      ></path>
      <path
        fill={useNaturalColor ? '#ffc107' : color}
        d="M26 26H42V42H26z"
        transform="rotate(-180 34 34)"
      ></path>
      <path
        fill={useNaturalColor ? '#03a9f4' : color}
        d="M6 26H22V42H6z"
        transform="rotate(-180 14 34)"
      ></path>
    </svg>
  );
};

export default LogoMicrosoft;
