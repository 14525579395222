import { OperationVariables, ApolloQueryResult } from '@apollo/client';
import { GET_BRANDS } from 'graphql/brands/queries';
import useNavigationContext from 'hooks/context/nav-context';
import { useQuery } from 'hooks/sympl-query';
import React, { createContext, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PageParams } from 'types/careers-page/context';
import { Brand } from 'types/customer/types';

export interface CustomerBrandType {
  customerBrands?: Brand[];
  loadingBrands?: boolean;
  refetchBrands?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<
    ApolloQueryResult<{
      brands: Brand[];
    }>
  >;
}

export const CustomerBrandContext = createContext<CustomerBrandType>(
  {} as CustomerBrandType
);

export const CustomerBrandProvider: FC = ({ children }) => {
  const { customerPageId } = useParams<PageParams>();
  const { activeCustomer } = useNavigationContext();
  const {
    data,
    loading: loadingBrands,
    refetch: refetchBrands,
  } = useQuery<{
    brands: Brand[];
  }>(GET_BRANDS, { variables: { page_id: customerPageId ?? '' } });

  useEffect(() => {
    if (activeCustomer) refetchBrands();
  }, [activeCustomer]);

  return (
    <CustomerBrandContext.Provider
      value={{
        customerBrands: data?.brands ?? [],
        loadingBrands,
        refetchBrands,
      }}
    >
      {children}
    </CustomerBrandContext.Provider>
  );
};
