import React from 'react';
import 'twin.macro';
import {
  ArrowRight,
  CalendarBlank,
  ClipboardText,
  EnvelopeSimple,
  Sparkle,
  UserPlus,
  X,
} from '@phosphor-icons/react';

import SidebarListItem from '../sidebar-list-item/SidebarListItem';
import { Footnote, Subhead, Title3 } from 'components/typography/Typography';
import { uniqueId } from 'lodash';

type Activity = {
  created_at: string;
  activity: string;
  type: string;
};

const CandidateActivityList: React.FC<{
  activities: Activity[];
}> = ({ activities }) => {
  const getIconForActivity = (type: string) => {
    const size = '20';
    const style = 'text-gray-700';

    switch (type) {
      case 'transition':
        return <ArrowRight className={style} size={size} />;
      case 'mail_outgoing':
        return <EnvelopeSimple className={style} size={size} />;
      case 'mail_incoming':
        return <EnvelopeSimple className={style} size={size} />;
      case 'new':
        return <Sparkle className={style} size={size} />;
      case 'survey_done':
        return <ClipboardText className={style} size={size} />;
      case 'end_procedure':
        return <X className={style} size={size} />;
      case 'invitation':
        return <UserPlus className={style} size={size} />;
      default:
        return <CalendarBlank className={style} size={size} />;
    }
  };

  return (
    <div>
      <Title3>Activity</Title3>
      <ul tw="overflow-y-auto">
        {activities.map(({ created_at, type, activity }) => (
          <SidebarListItem
            tw="flex items-center"
            key={`${created_at}-${type}-${uniqueId()}`}
          >
            <div tw="mr-5">{getIconForActivity(type)}</div>
            <div tw="flex flex-col">
              <Footnote>{created_at}</Footnote>
              <Subhead>{activity}</Subhead>
            </div>
          </SidebarListItem>
        ))}
      </ul>
    </div>
  );
};

export default CandidateActivityList;
