import { gql } from '@apollo/client';

export const GET_ACTIVE_PLANS = gql`
  query getActivePlans {
    plans @rest(type: "Plan", path: "/v4/plans?include=prices") {
      name
      tier
      prices
      description
      features
    }
  }
`;

export const GET_PLANS_INVOICE_ITEMS = gql`
  query getPlansInvoiceItems($hasActiveSub: Boolean) {
    plans @rest(type: "[PlanInvoiceItems]", path: "/v4/plans/invoice-items") {
      id
      interval
      name
      commission_percentage
    }
  }
`;
