import { COUNTRIES } from 'data/geo/countries';
import { CustomerAddress } from 'types/geolocationTypes';

const addressIsEmpty = (address: CustomerAddress) => {
  if (!address || !Object.keys(address).length) return true;

  return (
    !address.road.trim() &&
    !address.house_number?.trim() &&
    !address.zip_code.trim() &&
    !address.city?.trim() &&
    !address.country_code.trim()
  );
};

const containsEmptyAddressField = (address: CustomerAddress) => {
  if (!address || !Object.keys(address).length) return true;

  return (
    !address.road.trim() ||
    !address.house_number?.trim() ||
    !address.zip_code.trim() ||
    !address.city?.trim() ||
    !address.country_code.trim() ||
    !COUNTRIES.find(
      (country) =>
        country.key.toLowerCase() === address.country_code.toLowerCase()
    )
  );
};

export const addressValidationHandler = (
  required: boolean,
  address: CustomerAddress
): boolean => {
  // If it isn't required it must be completely empty
  if (!required && addressIsEmpty(address)) return true;

  return !containsEmptyAddressField(address);
};
