import React, { useState } from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import { Chats, CaretLeft } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';

import ToolbarPanel from './panel/ToolbarPanel';
import { AppPageProps } from '../app-page/AppPage';
import Title from 'components/title/Title';
import Button from 'components/button/Button';

export interface ToolbarProps {
  title: string | React.ReactNode;
  cta?: React.ReactNode;
  clipboardCopy?: React.ReactNode;
  feedback?: AppPageProps['feedback'];
  enableFeedback?: boolean;
  breadcrumbUrl?: string;
}

const Toolbar: React.FC<ToolbarProps> = ({
  cta,
  title,
  feedback,
  breadcrumbUrl,
  clipboardCopy,
  enableFeedback = false,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);

  return (
    <>
      <Wrapper>
        <TitleWrapper
          // @ts-ignore
          title={title}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {breadcrumbUrl && (
            <Link to={breadcrumbUrl}>
              <div tw="p-2 hover:(rounded-full bg-gray-100)">
                <CaretLeft weight="bold" tw="text-gray-700" size={20} />
              </div>
            </Link>
          )}
          <Title
            style={{ marginLeft: breadcrumbUrl ? '5px' : 0 }}
            tw="min-w-max"
          >
            {title}
          </Title>
        </TitleWrapper>

        {clipboardCopy && <div tw="me-auto sm:me-0">{clipboardCopy}</div>}

        {(enableFeedback || cta) && (
          <ControlsWrapper>
            {enableFeedback && (
              <Button
                variant="outline"
                onClick={() => setShowFeedback(true)}
                disabled={!feedback}
              >
                <IconWrapper tabIndex={-1} isDisabled={!feedback}>
                  <Chats
                    weight="bold"
                    size="16"
                    style={{
                      color: feedback
                        ? theme`colors.white`
                        : theme`colors.gray.200`,
                    }}
                  />
                </IconWrapper>
                Give feedback
              </Button>
            )}

            {cta && <div tw="ml-4">{cta}</div>}
          </ControlsWrapper>
        )}
      </Wrapper>

      {showFeedback && (
        <ToolbarPanel
          feedback={feedback}
          onClose={() => setShowFeedback((prev) => !prev)}
        />
      )}
    </>
  );
};

const Wrapper = styled.div(() => [
  tw`
    border-b border-gray-100 flex justify-between max-w-full gap-x-4 items-center w-full overflow-x-auto no-scrollbar
  `,
  css`
    background-color: #fdfdfd;
    min-width: 320px;
    padding: 6px 12px;

    @media (min-width: 768px) {
      padding: 10px 35px;
      min-height: 61px;
    }

    @media (min-width: 1500px) {
      padding: 12px 65px;
    }
  `,
]);

const ControlsWrapper = styled.div(() => [
  tw`
    flex items-center justify-end
  `,
]);

const TitleWrapper = styled.div<{ title: string }>(() => [
  `${tw`
    flex flex-row items-center space-x-2
  `}`,
  css`
    @media (min-width: 433px) {
      margin-top: 0;
      width: 100%;
    }
  `,
  ({ title }) =>
    title === 'My Candidates' &&
    css`
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    `,
]);

const IconWrapper = styled.div<{ isDisabled: boolean }>`
  ${tw`rounded-full p-1 cursor-pointer bg-sympl mr-2`}
  ${({ isDisabled }) => isDisabled && tw`bg-gray-50`}
`;

export default Toolbar;
