import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';

interface VacanciesSkeletonProps {}

const VacanciesSkeleton: FC<VacanciesSkeletonProps> = () => {
  return <SkeletonList rows={2} />;
};

export default VacanciesSkeleton;
