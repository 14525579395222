import 'twin.macro';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ConfigFormQuestionType } from './ConfigForm';
import Dropdown, { DropdownItem } from 'components/dropdown/Dropdown';
import Checkbox from 'components/checkbox/Checkbox';
import { Error } from 'components/typography/Typography';
import Button from 'components/button/Button';
import { Modal } from 'components/page/app-page/Modal';
import Input from 'components/form/input/Input';
import { GET_TEMPLATES } from 'graphql/templates/queries';
import { PaginatedTemplates } from 'views/templates/TemplateOverview';
import useMetaQuery from 'hooks/metaQuery';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import { useMutation } from 'hooks/sympl-mutation';
import {
  DEPUBLISH_VACANCY,
  PUBLISH_VACANCY,
} from 'graphql/vacancies/mutations';
import useNavigationContext from 'hooks/context/nav-context';
import InformationToolTip from 'components/tooltip/InformationToolTip';
import { Routes } from 'types/routeTypes';
import { useNavigate } from 'react-router-dom';

export interface ConfigInputProps {
  id: string;
  isDisabled?: boolean;
  value?: string | number | boolean;
  type?: ConfigFormQuestionType;
  label?: string;
  placeholder?: string;
  onChange?: (value: string | number | boolean) => void;
  handler?: () => void;
}

export interface LeadRecruiterData {
  customer: {
    users: {
      id: number;
      fullname: string;
    }[];
  };
}

const ConfigInput: React.FC<ConfigInputProps> = ({
  id,
  value,
  isDisabled = false,
  type = 'text',
  label,
  placeholder,
  onChange,
  handler,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { activeVacancy, currentVacancy, refetchCurrentVacancy } =
    useNavigationContext();

  const navigate = useNavigate();

  const [deleteModalShown, setDeleteModalShown] = useState(false);

  const [listData, setListData] = useState<DropdownItem[]>([]);

  const TEMPLATE_QUESTIONS = ['survey-done', 'ask-for-cv', 'got-cv'];

  /** Checks if the question type is a dropdown specific type*/
  const isTextInputType = useMemo(() => type === 'name', [type]);

  const isTemplatesDropdownType = useMemo(
    () => TEMPLATE_QUESTIONS.includes(type),
    [type]
  );

  const requiredFields = ['name', 'lead-recruiter'];

  const isCheckboxType = useMemo(
    () =>
      [
        'notification-check',
        'vacancy-template-enabled',
        'hide-from-careers-page',
      ].includes(type),
    [type]
  );

  const dropdownChangeHandler = (key: number | string) =>
    onChange?.(key as number);

  const changeHandler = (
    onInputChange: (value: string) => void,
    e?: React.ChangeEvent<HTMLInputElement>
  ) => {
    e?.preventDefault();

    const val = e?.currentTarget?.value;
    if (!val) return;

    onInputChange(val);
    onChange?.(val);
  };

  const { data: templates, loading: templatesLoading } = useMetaQuery<
    PaginatedTemplates,
    {
      pageSize: number;
      currentPage: number;
      languageId: string;
      slug?: string;
      type?: string;
    }
  >(GET_TEMPLATES, {
    fetchPolicy: 'network-only',
    variables: {
      pageSize: 100,
      currentPage: 1,
      languageId: '',
      slug: '',
      type: 'base',
    },
  });

  const deleteVacancy = () => {
    handler?.();
    setDeleteModalShown(false);
  };

  const [publish] = useMutation(PUBLISH_VACANCY, {
    onCompleted: refetchCurrentVacancy,
  });

  const [depublish] = useMutation(DEPUBLISH_VACANCY, {
    onCompleted: refetchCurrentVacancy,
  });

  const getCurrentDropdownLabel = (id: number) =>
    listData.find((e: DropdownItem) => e.key === Number(id))?.label ??
    placeholder ??
    '';

  useEffect(() => {
    if (TEMPLATE_QUESTIONS.includes(type)) {
      setListData(
        templates?.templates.map((template) => {
          return { key: template.id, label: template.name } as DropdownItem;
        }) ?? []
      );
    }
  }, [templates?.templates]);

  return (
    <div tw="flex flex-col">
      <Controller
        id={id}
        name={id}
        control={control}
        defaultValue={value ?? ''}
        rules={{ required: requiredFields.includes(type) }}
        render={({ onChange: onInputChange }: { onChange: any }) => (
          <>
            {type === 'lead-recruiter' && (
              <ApiDropdown
                type="recruiters"
                mode="key"
                value={value as number}
                onChange={(val) => {
                  const key = (val as DropdownItem).key as number;
                  dropdownChangeHandler(key);
                  onInputChange(key);
                }}
                onCreate={() => navigate(Routes.USER_SETTINGS)}
                createLabel="Add user"
              />
            )}
            {isTemplatesDropdownType && (
              <Dropdown
                items={[
                  {
                    key: 'null',
                    label: 'No email',
                    customStyle: {
                      color: '#fd4c5a',
                    },
                  },
                  ...listData,
                ]}
                loading={templatesLoading}
                label={
                  value === 'null'
                    ? 'No email'
                    : getCurrentDropdownLabel(Number(value))
                }
                onChange={(key) => {
                  dropdownChangeHandler(key);
                  onInputChange(key);
                }}
                onCreate={() => navigate(Routes.TEMPLATE_SETTINGS)}
                createLabel="Create new template"
              />
            )}
            {isCheckboxType && (
              <Checkbox
                value={Boolean(value)}
                onChange={(checked) => {
                  onChange?.(checked);
                  onInputChange(checked);
                }}
                label={label}
              />
            )}
            {type === 'delete-button' && (
              <>
                <div tw="flex gap-2">
                  {currentVacancy?.status === 'INACTIVE' && (
                    <Button
                      onClick={() => {
                        publish({
                          variables: {
                            vacancyId: activeVacancy,
                            input: {},
                          },
                        });
                      }}
                    >
                      Re-open Campaign
                      <InformationToolTip
                        name="restart_campaign_button"
                        text="Clicking this button will re-open the campaign, the vacancy page will be visible to candidates again. For restarting ad campaigns, visit the social media menu item."
                      />
                    </Button>
                  )}
                  {currentVacancy?.status === 'ACTIVE' && (
                    <Button
                      onClick={() => {
                        depublish({
                          variables: {
                            vacancyId: activeVacancy,
                            input: {},
                          },
                        });
                      }}
                    >
                      Close Campaign
                      <InformationToolTip
                        name="close_campaign_button"
                        text="Clicking this button will close the campaign, the vacancy page will no longer be visible to candidates. In case this campaign is still served on social media, those campaigns will also stop running."
                      />
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    onClick={() => setDeleteModalShown(true)}
                    disabled={isDisabled}
                  >
                    Archive Campaign
                  </Button>
                </div>

                <Modal
                  show={deleteModalShown}
                  onClose={() => setDeleteModalShown(false)}
                >
                  <div tw="my-4">
                    <label tw="mr-10 block text-base font-medium text-gray-900">
                      Are you sure you want to archive this campaign?
                    </label>
                    <p tw="mb-4 text-sm leading-5 text-gray-500">
                      This action is irreversible.
                    </p>
                    <div tw="float-right mb-2">
                      <Button variant="danger" onClick={deleteVacancy}>
                        Archive
                      </Button>
                    </div>
                  </div>
                </Modal>
              </>
            )}
            {isTextInputType && (
              <Input
                id={id}
                name={id}
                type="text"
                defaultValue={value?.toString() ?? ''}
                ariaInvalid={errors[id] !== undefined}
                onBlur={(e) => changeHandler?.(onInputChange, e)}
                disabled={isDisabled}
              />
            )}
          </>
        )}
      />
      {errors[id] !== undefined && <Error>{'This field is required'}</Error>}
    </div>
  );
};

export default ConfigInput;
