import { PreferencesQuestion } from './PreferencesConfigTypes';

type TQuestion = PreferencesQuestion;

export const name: TQuestion = {
  key: 'name',
  label: 'The name of this campaign',
  helper: 'For internal use only. This will be used in the menu.',
  type: 'name',
};

export const lead_recruiter: TQuestion = {
  key: 'lead_recruiter',
  label: 'Who is the lead recruiter?',
  helper:
    "This person's email signature will be used in the automatic mails to candidates.",
  type: 'lead-recruiter',
};

export const hide_from_careers_page: TQuestion = {
  key: 'hide_from_careers_page',
  type: 'hide-from-careers-page',
  label: 'Hide this campaign from the careers page',
};

export const vacancy_template_enabled: TQuestion = {
  key: 'vacancy_template_enabled',
  label: 'Send automated mails',
  type: 'vacancy-template-enabled',
};

export const vacancy_template_done_survey: TQuestion = {
  key: 'vacancy_template_done_survey',
  label: 'Application confirmation',
  helper: 'Used to confirm the receival of the application form.',
  type: 'survey-done',
  placeholder: 'Default mail',
};

export const vacancy_template_ask_for_cv: TQuestion = {
  key: 'vacancy_template_ask_for_cv',
  label: 'CV reminder',
  helper:
    'Sent after application, if no CV was included in the application form.',
  type: 'ask-for-cv',
  placeholder: 'Default mail',
};

export const vacancy_template_got_cv: TQuestion = {
  key: 'vacancy_template_got_cv',
  label: 'CV received confirmation',
  helper: 'Sent upon receiving the CV.',
  type: 'got-cv',
  placeholder: 'Default mail',
};

export const email_on_candidate: TQuestion = {
  key: 'email_on_candidate',
  label: 'Email me when a candidate applies',
  type: 'notification-check',
};

export const email_on_communication: TQuestion = {
  key: 'email_on_communication',
  label: 'Email me when a candidate sends an email',
  type: 'notification-check',
};

export const delete_vacancy: TQuestion = {
  key: 'delete_vacancy',
  label: '',
  type: 'delete-button',
};
