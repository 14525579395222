import React, { useEffect, useMemo, useState } from 'react';

import 'twin.macro';

import Container from 'components/container/Container';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import { Title2 } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';

import PlanCard from './PlanCard';
import { showIntercom } from 'utils/intercomHelper';
import { fireEvent } from 'utils/eventHelper';

import { EXTEND_OR_START_TRIAL } from 'graphql/subscriptions/mutations';
import { useMutation } from 'hooks/sympl-mutation';
import Input from 'components/form/input/Input';
import Label from '../../../components/form/Label';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';

const SubscriptionSettings: React.FC = () => {
  const { subscription, refetchCustomer, isAdmin } = useNavigationContext();
  const [days, setDays] = useState(14);
  const { addToast } = useToastNotifications();

  const hasActiveSubscription = useMemo(
    () => subscription && subscription.status === 'ACTIVE',
    [subscription]
  );

  useEffect(() => {
    refetchCustomer();
  }, []);

  const [extendOrStartTrial, { loading }] = useMutation<
    {},
    { input: { days: number } }
  >(EXTEND_OR_START_TRIAL, { onCompleted: refetchCustomer });

  const handleTrialExtension = () => {
    extendOrStartTrial({
      variables: {
        input: { days },
      },
    }).then(() => {
      setDays(0);
      const verb = subscription?.is_trial ? 'extended' : 'started';
      addToast({
        type: ToastTypes.SUCCESS,
        description: `The trial has been ${verb} successfully!`,
      });
    });
  };

  return (
    <Container id="plans">
      <ContainerHeader>
        <Title2>Your subscription</Title2>
      </ContainerHeader>
      <div tw="flex flex-col items-center">
        {subscription && (
          <div tw="my-8">
            <PlanCard
              subscription={subscription}
              plan={subscription.plan}
              selectedInterval={subscription.interval}
            />
          </div>
        )}

        <Button
          variant="link"
          onClick={() => {
            fireEvent('interested_in_enterprise');
            showIntercom("Hi! I'm interested in your custom plans 🙋‍♂️🙋‍♀️");
          }}
        >
          {subscription
            ? 'Need a custom plan? Contact us!'
            : 'Contact us to start your new subscription'}
        </Button>
        {isAdmin && (!hasActiveSubscription || subscription?.is_trial) && (
          <div
            id="extend-trial"
            className="isAdmin"
            tw="mt-16 p-4 flex flex-col gap-2 w-72"
          >
            <Title2>Extend or start a trial</Title2>
            <Label htmlFor="trial-days">Amount of days:</Label>
            <Input
              id="trial-days"
              type="number"
              max={60}
              min={0}
              value={days}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDays(parseInt(e.target.value))
              }
            />
            <Button stretch loading={loading} onClick={handleTrialExtension}>
              {subscription?.is_trial ? 'Extend this trial' : 'Start a trial'}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default SubscriptionSettings;
