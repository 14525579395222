import React from 'react';
import { useFormContext } from 'react-hook-form';

import { IBaseInputProps } from 'types/formTypes';
import { questionRules } from 'utils/formHelpers';
import { toLowercaseDashedString } from 'utils/urlHelpers';

import 'twin.macro';

interface IProps extends IBaseInputProps {
  options: string[];
}

const Select = (props: IProps) => {
  const { register, errors } = useFormContext();

  const questionId = props.question.id.toString();

  return (
    <>
      <select
        tw="block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        id={`${props.section}-${toLowercaseDashedString(props.question.label)}`}
        name={`${props.section}-${toLowercaseDashedString(
          props.question.label
        )}`}
        ref={register(questionRules(props.question))}
        aria-invalid={errors[questionId] !== undefined}
        defaultValue={props.question.value?.toString()}
        style={{ minHeight: 50 }}
      >
        {props.options &&
          props.options.length > 0 &&
          props.options.map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
      </select>
    </>
  );
};

export default Select;
