import React, { useEffect, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { FileUploadsWithText } from 'types/fileTypes';
import { IntakeQuestion } from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';
import TextEditor from 'components/form/text-editor/TextEditor';

const IntakeTextEditor: React.FC<{
  question: IntakeQuestion;
  defaultValue?: string;
  onChange?: (value: string) => void;
  variant?: 'normal' | 'upload';
}> = ({ question, defaultValue, onChange, variant }) => {
  const { control, setValue } = useFormContext();

  const id = useMemo(() => question.id.toString(), [question.id]);
  const rules = useMemo(() => questionRules(question), [question]);

  const changeHandler = (text: string) =>
    onChange ? onChange(text) : setValue(id, text);

  useEffect(() => {
    if (variant === 'upload') {
      setValue(
        id,
        JSON.stringify({
          files: (question.value as FileUploadsWithText).files ?? [],
          text: defaultValue,
        })
      );
    }
  }, [defaultValue, id, question.value, setValue, variant]);

  return (
    <>
      <Controller
        id={id}
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={() => (
          <TextEditor
            identifier="intake_text_editor"
            defaultValue={defaultValue ?? ''}
            onChange={changeHandler}
          />
        )}
      />
    </>
  );
};

export default IntakeTextEditor;
