import React from 'react';
import Picker from 'emoji-picker-react';

export type EmojiPickerProps = {
  onChange?: ({ emoji }: { emoji: string }) => void;
};

export const EmojiPicker: React.FC<EmojiPickerProps> = ({ onChange }) => (
  <Picker
    onEmojiClick={onChange}
    searchDisabled
    skinTonesDisabled
    previewConfig={{
      showPreview: false,
    }}
  />
);

export default EmojiPicker;
