import React from 'react';
import { List, X, UserList } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import tw, { css, styled, theme } from 'twin.macro';
import { useNotifications } from 'hooks/notificationHooks';

import { Routes } from 'types/routeTypes';
import NotificationTab from '../notifications/notifications-tab/NotificationTab';
import SvgSymplS from '../svg/SvgSymplS';
import AccountSelector from './AccountSelector';
import Menu from './menu/Menu';
import CandidateSearch from 'components/candidates/candidate-search/CandidateSearch';
import useModalContext from 'hooks/context/modals-context';
import { fireEvent } from 'utils/eventHelper';

export interface NavbarProps {
  // Is the navbar currently open?
  isOpen: boolean;
  // Function to change the open-state of the navbar
  setIsOpen: (isOpen: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ isOpen, setIsOpen }) => {
  const { showNotificationTab } = useNotifications();
  const { pushModal, removeModal } = useModalContext();

  const handleCandidateSidebar = () => {
    fireEvent('candidate-search:open');
    pushModal({
      key: 'candidate-search',
      show: true,
      twclass: tw`w-full min-w-[640px] h-screen bg-white max-h-[unset]`,
      variant: 'sideBar',
      onClose: () => removeModal('candidate-search'),
      children: <CandidateSearch />,
    });
  };

  return (
    <Wrapper isOpen={isOpen}>
      <NavHeader>
        <Link tw="cursor-pointer h-6" to={Routes.ROOT}>
          <SvgSymplS />
        </Link>
        <div tw="flex-1 mx-4 lg:mx-2">
          <AccountSelector />
        </div>
        <span
          tw="lg:hidden cursor-pointer ml-2 text-gray-700 text-2xl"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <X weight="bold" /> : <List weight="bold" />}
        </span>

        <UserList
          tw="cursor-pointer ml-0 text-gray-700 text-2xl"
          onClick={handleCandidateSidebar}
          size={24}
        />
      </NavHeader>

      <MobileWrapper id="mobile-wrapper">
        <Menu isMobile={true} isOpen={isOpen} />
      </MobileWrapper>

      <DesktopWrapper>
        <Menu isMobile={false} />
      </DesktopWrapper>

      {showNotificationTab && <NotificationTab />}
    </Wrapper>
  );
};

const MobileWrapper = styled.div<{ isOpen?: boolean }>`
  ${tw`visible flex flex-auto flex-col lg:hidden z-[60] bg-[#fdfdfd] relative overflow-y-auto no-scrollbar`}
`;

const DesktopWrapper = styled.div(
  tw`hidden w-[250px] h-full flex-auto lg:(flex flex-col flex-nowrap) overflow-y-auto no-scrollbar`
);

const Wrapper = styled.div<{
  isOpen?: boolean;
}>`
  ${tw`flex flex-col border-r-2 bg-[#fdfdfd] border-gray-100 z-10 max-h-full md:max-h-full`}
  ${(props) => css`
    width: 100vw;
    border-bottom: 1px solid ${theme`colors.gray.100`};
    min-height: ${props.isOpen ? '100vh' : 'auto'};
    @media (min-width: 1024px) {
      height: 100vh;
      min-height: 100vh;
      width: 250px;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .example::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const NavHeader = styled.h1(
  tw`text-xl text-gray-600 flex justify-between items-center p-4 lg:p-2`
);

export default Navbar;
