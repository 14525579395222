import React, { FC, ReactNode } from 'react';
import FieldGenerator, {
  FieldGeneratorProps,
} from '../field-generator/FieldGenerator';
import 'twin.macro';
import tw, { styled } from 'twin.macro';
import { Check } from '@phosphor-icons/react';
import ToolTip from 'components/tooltip/ToolTip';

export interface IWizardStep {
  title: string;
  description?: string;
  stepId?: string;
  answered?: boolean;
  fields?: Omit<
    FieldGeneratorProps,
    'currentIndex' | 'onHandleNavigateToStep'
  >[];
  component?: ReactNode;
}

export interface WizardFormStepProps extends IWizardStep {
  stepIndex: number;
  currentIndex: number;
  onHandleNavigateToStep: (index: number) => void;
}

const WizardStep: FC<WizardFormStepProps> = ({
  stepIndex,
  currentIndex,
  title,
  stepId,
  answered,
  fields,
  component,
  onHandleNavigateToStep,
}) => {
  const sectionId = stepId ?? title.replaceAll(' ', '-').toLocaleLowerCase();
  const isStepActive = currentIndex === stepIndex;
  return (
    <StepContainer active={isStepActive} id={sectionId} tw="w-full">
      <div tw="w-full flex-col flex">
        <div tw="w-full flex flex-col mb-8">
          <StepHeader answered={answered}>
            {answered && (
              <ToolTip text="Answered" placement="top" arrow>
                <span tw="text-green-600 p-1 rounded-full bg-green-100 mr-2">
                  <Check weight="bold" />
                </span>
              </ToolTip>
            )}
            {title}
          </StepHeader>
        </div>
        {component && <div className="special-component">{component}</div>}
        {fields && fields.length > 0 && (
          <div className="generated-fields" tw="w-full">
            {fields.map((field, key) => (
              <FieldGenerator
                {...field}
                key={`${key}-${field.type}-${field.name}`}
                currentIndex={currentIndex}
                onHandleNavigateToStep={onHandleNavigateToStep}
              />
            ))}
          </div>
        )}
      </div>
    </StepContainer>
  );
};

const StepHeader = styled.h2<{ answered?: boolean }>`
  ${tw`
    text-xl
    font-semibold
    flex
    justify-center
    items-center
    text-center
    flex-col

    flex-nowrap

    md:text-left
    md:justify-start
    md:items-center
    md:flex-row
  `}
  ${({ answered }) => answered && tw`text-green-500`}
`;

const StepContainer = styled.section<{ active?: boolean }>`
  ${tw`
    hidden
    pointer-events-none
    w-full
  `}

  ${({ active }) =>
    active &&
    tw`
      flex
      opacity-100
      pointer-events-auto
    `}
`;

export default WizardStep;
