import React, { useContext, useRef } from 'react';
import tw, { styled } from 'twin.macro';

import { Candidate } from 'types/candidates/types';
import useDefocusHandler from 'hooks/defocus';
import { CandidateContext } from 'context/CandidateContext';
import SidebarToggle from './Toggle';
import CandidateGeneralInfo from 'views/candidates/CandidateGeneralInfo';

const CandidateSidebar: React.FC<{
  candidate?: Candidate;
}> = ({ candidate }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const candidateContext = useContext(CandidateContext);

  const sidebarIsOpen = candidateContext
    ? candidateContext.sidebarIsOpen
    : false;

  const setSidebarIsOpen = candidateContext
    ? candidateContext.setSidebarIsOpen
    : () => {};

  useDefocusHandler(wrapperRef, () => {
    setSidebarIsOpen(false);
  });

  return (
    <>
      <Wrapper ref={wrapperRef} sidebarIsOpen={sidebarIsOpen}>
        <div tw="flex w-full justify-end">
          <SidebarToggle variant="close" />
        </div>

        {candidate && <CandidateGeneralInfo candidate={candidate} />}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div<{ sidebarIsOpen: boolean }>`
  ${tw`h-full px-6 pt-4 pb-8 overflow-y-hidden hidden invisible w-1/2 max-h-full xl:(flex flex-col visible)`}
  ${({ sidebarIsOpen }) =>
    sidebarIsOpen &&
    tw`absolute right-0 top-0 bg-white shadow flex flex-col visible
    xl:(static shadow-none)`}
`;

export default CandidateSidebar;
