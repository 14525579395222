import AppPage from 'components/page/app-page/AppPage';
import React, { useMemo } from 'react';

import tw from 'twin.macro';
import ReportingFilter from 'components/reporting/filtering/Filter';
import DateRangePicker from 'components/date-range-picker/DateRangePicker';
import useReportingContext from 'hooks/context/reporting-context';

import General from './General';
import Channels from './Channels';
import Visuals from './Visuals';
import { RouteGuards, Routes as RouteTypes } from 'types/routeTypes';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import GuardedRoute from 'routing/GuardedRoute';
import { FiltersType, ReportingScreens } from 'context/ReportingContext';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';
import ToolTip from 'components/tooltip/ToolTip';
import { DropdownItem } from 'components/dropdown/Dropdown';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import useApiList from 'hooks/useApiList';
import { ApiListItem } from 'types/apiTypes';

export type ReportingFilterTypes =
  | 'customers'
  | 'brands'
  | 'vacancies'
  | 'start_date'
  | 'end_date';

export type ReportingFields =
  | 'impressions'
  | 'visits'
  | 'clicks'
  | 'spend'
  | 'applications'
  | 'ctr'
  | 'conversion'
  | 'cpc'
  | 'cpm'
  | 'cpa';

type Header = {
  name: string;
  short: string;
  description: string | React.ReactNode;
};

export const tableHeaders = [
  [
    {
      name: 'Impressions',
      short: 'IMPR',
      description: (
        <div>
          <b>Impressions:</b>
          <p>The number of times an ad is viewed by users.</p>
        </div>
      ),
    },
    {
      name: 'Clicks',
      short: 'CLICK',
      description: (
        <div>
          <b>Clicks:</b>
          <p>The number of times users clicked on an ad.</p>
        </div>
      ),
    },
    {
      name: 'Visits',
      short: 'VISIT',
      description: (
        <div>
          <b>Visits:</b>
          <p>
            The number of times users visited a page after clicking on an ad.
          </p>
        </div>
      ),
    },
    {
      name: 'Applications',
      short: 'APPL',
      description: (
        <div>
          <b>Applications:</b>
          <p>
            The number of times users submitted an application after clicking on
            an ad.
          </p>
        </div>
      ),
    },
    {
      name: 'Spend',
      short: 'Spend',
      description: (
        <div>
          <b>Spend:</b>
          <p>The total amount of money spent on an ad.</p>
        </div>
      ),
    },
  ],
  [
    {
      name: 'CTR',
      short: 'CTR',
      description: (
        <div>
          <b>CTR:</b>
          <p>The ratio of clicks to impressions.</p>
        </div>
      ),
    },
    {
      name: 'Conversion',
      short: 'CONV',
      description: (
        <div>
          <b>Conversion:</b>
          <p>The ratio of applications to clicks.</p>
        </div>
      ),
    },
    {
      name: 'CPM',
      short: 'CPM',
      description: (
        <div>
          <b>CPM:</b>
          <p>The cost per thousand impressions.</p>
        </div>
      ),
    },
    {
      name: 'CPC',
      short: 'CPC',
      description: (
        <div>
          <b>CPC:</b>
          <p>The cost per click.</p>
        </div>
      ),
    },
    {
      name: 'CPA',
      short: 'CPA',
      description: (
        <div>
          <b>CPA:</b>
          <p>The cost per application.</p>
        </div>
      ),
    },
  ],
];

export const tableColumns = tableHeaders.map((headerSection) =>
  headerSection.map((header: Header) => (
    <ToolTip
      key={header.name.toLowerCase()}
      content={header.description}
      placement="top"
      maxWidth="375px"
      arrow
      customStyle={tw`text-white text-sm p-2`}
    >
      <p className="cursor-help">{header.short}</p>
    </ToolTip>
  ))
);

const Reporting: React.FC = () => {
  const navigate = useNavigate();
  const {
    filters,
    defaultStartDate,
    defaultEndDate,
    setFilters,
    setReportingScreen,
  } = useReportingContext();
  const { brands, isAdmin } = useNavigationContext();

  const { data } = useApiList('vacancies');

  const getVacLabel = (vac: ApiListItem) => {
    if (isAdmin) return `${vac.key} - ${vac.label}`;
    return vac.label;
  };

  const vacancies = useMemo(
    () =>
      data.map((vacancy) => {
        return { key: `${vacancy.key}`, label: getVacLabel(vacancy) };
      }),
    [data]
  );

  const filterValues = useMemo(() => {
    return {
      brands: brands.map((brand) => {
        return { key: `${brand.id}`, label: brand.name };
      }),
      vacancies: vacancies,
    };
  }, [brands, vacancies]);

  const reportingfilters = React.useMemo(() => filters, [filters]);
  const getFilterDate = (date: Date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const handleFilterChange = (
    type: ReportingFilterTypes,
    filterValue: Array<string | number> | Date
  ) => {
    const newFilters = {
      ...reportingfilters,
      [type]: Array.isArray(filterValue)
        ? filterValue.map((item) => String(item))
        : getFilterDate(filterValue),
    };
    setFilters(newFilters as FiltersType);
  };

  const handleDateChages = (val: [Date, Date] | undefined) => {
    if (!val)
      setFilters({
        ...reportingfilters,
        start_date: defaultStartDate,
        end_date: defaultEndDate,
      });
    const dateStart = new Date(val![0]);
    const dateEnd = new Date(val![1]);
    setFilters({
      ...reportingfilters,
      start_date: getFilterDate(dateStart),
      end_date: getFilterDate(dateEnd),
    });
  };

  const clearAllFilters = () => {
    setFilters({
      customers: [],
      brands: [],
      vacancies: [],
      start_date: defaultStartDate,
      end_date: defaultEndDate,
    });
  };

  /**
  const generatePDF = async () => {
    const canvas = await html2canvas(document.querySelector('.content')!);
    const canvasData = canvas.toDataURL('image/png');

    const widthInPT =
      (document.querySelector('.content')!.clientWidth * 72) / 96;
    const heightInPT =
      (document.querySelector('.content')!.clientHeight * 72) / 96;

    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'pt', // using 'pt' for points
      format: [widthInPT, heightInPT], // custom size in points (converted from pixels)
    });
    const imgProps = pdf.getImageProperties(canvasData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Add the image to the PDF
    pdf.addImage(canvasData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    // Download the generated PDF
    pdf.save(`sympl-analytics-export-${getDateString()}.pdf`);
  };
  */

  const tabs = [
    { item: 'General', route: RouteTypes.GENERAL_REPORTING },
    { item: 'Channels', route: RouteTypes.CHANNELS_REPORTING },
    { item: 'Creatives', route: RouteTypes.VISUALS_REPORTING },
  ];

  const activeTabIndex =
    tabs.findIndex((tab) => location.pathname.includes(tab.route)) ?? 0;

  return (
    <AppPage
      heading="Analytics"
      tabs={tabs.map(({ item }) => item)}
      activeTabIndex={activeTabIndex}
      onTabChange={(index) => {
        setReportingScreen(tabs[index].item.toLowerCase() as ReportingScreens);
        navigate(tabs[index].route);
      }}
      /**
      cta={
        reportingScreen === 'general' ? (
          <Button onClick={generatePDF}>Export</Button>
        ) : null
      }
      */
    >
      <div tw="w-full p-2 pt-0">
        {/* TOP SECTION - FILTERS + SCREEN SWITCHER */}
        <div tw="flex gap-4 mb-6 pt-4" data-html2canvas-ignore>
          <ReportingFilter
            type="brands"
            title="All brands"
            items={filterValues.brands}
            value={reportingfilters.brands as unknown as DropdownItem[]}
            onChange={handleFilterChange}
          />
          <ReportingFilter
            type="vacancies"
            title="All Campaigns"
            value={reportingfilters.vacancies as unknown as DropdownItem[]}
            items={filterValues.vacancies}
            onChange={handleFilterChange}
          />
          <DateRangePicker
            tw="h-fit my-6"
            value={null}
            onChange={(val: DateRange | undefined) => handleDateChages(val)}
          />
          <Button variant="link" onClick={clearAllFilters}>
            Clear all filters
          </Button>
        </div>

        {/* TABS */}
        <Routes>
          <Route
            path={'general'}
            element={
              <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
                <General />
              </GuardedRoute>
            }
          />
          <Route
            path={'channels'}
            element={
              <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
                <Channels />
              </GuardedRoute>
            }
          />
          <Route
            path={'creatives'}
            element={
              <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
                <Visuals />
              </GuardedRoute>
            }
          />
          <Route path="*" element={<Navigate replace to={`general`} />} />
        </Routes>
        <Outlet />
      </div>
    </AppPage>
  );
};

export default Reporting;
