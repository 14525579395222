import React from 'react';
import ToolTip, { ToolTipPlacementType } from './ToolTip';
import { Question, Info } from '@phosphor-icons/react';
import { fireEvent } from 'utils/eventHelper';

interface InformationToolTipProps {
  name: string;
  text: string;
  placement?: ToolTipPlacementType;
  icon?: 'question' | 'information';
  color?: string;
  weight?: 'light' | 'regular' | 'bold';
  size?: number;
}

const InformationToolTip: React.FC<InformationToolTipProps> = ({
  name,
  text,
  placement = 'bottom',
  icon = 'question',
  color = 'currentColor',
  weight = 'regular',
  size = 14,
}) => {
  const IconComponent = icon === 'question' ? Question : Info;

  return (
    <ToolTip text={text} arrow placement={placement}>
      <IconComponent
        style={{ pointerEvents: 'all' }}
        size={`${size}px`}
        color={color}
        weight={weight}
        onMouseEnter={() =>
          fireEvent('tooltip', {
            name,
          })
        }
      />
    </ToolTip>
  );
};

export default InformationToolTip;
