import { LogoIconProps } from 'components/icons/SocialIcon';
import React from 'react';

const SvgEmptyCircle: React.FC<LogoIconProps> = ({
  width = '24px',
  height = '24px',
  color = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill={color}
      strokeWidth="0"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M 21 12 C 21 18.928 13.5 23.258 7.5 19.794 C 4.715 18.187 3 15.215 3 12 C 3 5.072 10.5 0.742 16.5 4.206 C 19.285 5.813 21 8.785 21 12 Z"
      />
    </svg>
  );
};

export default SvgEmptyCircle;
