import React, { useContext } from 'react';

import { NavigationContext } from 'context/NavigationContext';
import AppPage from 'components/page/app-page/AppPage';
import { useQuery } from 'hooks/sympl-query';
import { Lead } from 'types/leads/types';
import { GET_LEADS } from 'graphql/leads/queries';
import 'twin.macro';
import { Link } from 'react-router-dom';
import { Routes } from 'types/routeTypes';
import LeadCard from 'components/leads/LeadCard';
import { Title2 } from 'components/typography/Typography';

const LeadsOverview: React.FC = () => {
  const navContext = useContext(NavigationContext);
  const activeVacancy = navContext ? navContext.activeVacancy : null;

  const { loading: loadingLeads, data: leadsData } = useQuery<
    {
      leads: Lead[];
    },
    { vacancyId: number }
  >(GET_LEADS, {
    skip: !activeVacancy,
    variables: { vacancyId: activeVacancy ?? 0 },
  });

  const leadsApplied = leadsData?.leads.map(
    (lead) => lead.procedure_id && <LeadCard lead={lead} />
  );
  const leadsPending = leadsData?.leads.map(
    (lead) => !lead.procedure_id && <LeadCard lead={lead} />
  );

  return (
    <AppPage heading="My Leads" loading={loadingLeads}>
      <div>
        <div tw="mt-10">
          <Link
            tw="bg-gray-600 text-white px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md transition ease-in-out duration-150 mt-4"
            to={Routes.CANDIDATES}
          >
            &#x2039; Go back to candidates
          </Link>
        </div>
        <Title2 mt={4}>Already applied</Title2>
        <div tw="h-full w-full flex flex-col bg-gray-50 p-10 rounded-xl">
          <ul tw="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 ">
            {leadsApplied}
          </ul>
        </div>
        <Title2 mt={4}>Pending</Title2>
        <div tw="h-full w-full flex flex-col bg-gray-50 p-10 rounded-xl ">
          <ul tw="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 ">
            {leadsPending}
          </ul>
        </div>
      </div>
    </AppPage>
  );
};

export default LeadsOverview;
