import React, {
  forwardRef,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Eye, EyeSlash, Smiley } from '@phosphor-icons/react';
import tw, { css, styled, TwStyle } from 'twin.macro';
import { v4 as uuid } from 'uuid';
import { EmojiPicker } from '../emoji/EmojiPicker';
import useDefocusHandler from 'hooks/defocus';

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  rows?: number;
  textarea?: boolean;
  enableEmoji?: boolean;
  ariaInvalid?: boolean;
  /** Structural inputs are invisible & don't take up space in the DOM */
  structural?: boolean;
  /** Makes the textarea grow depending on the content inside of it */
  autoGrow?: boolean;
  submitOnEnter?: boolean;
  fullWidth?: boolean;
  onEmojiChange?: (updatedValue: string) => void;
  bgTransparent?: boolean;
  label?: string;
  inputStyles?: TwStyle;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      bgTransparent = false,
      textarea,
      rows,
      enableEmoji,
      ariaInvalid,
      structural,
      autoGrow,
      submitOnEnter,
      fullWidth = true,
      disabled,
      onEmojiChange,
      inputStyles,
      ...props
    },
    ref
  ) => {
    const emojiWrapperRef = useRef<HTMLDivElement>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [hasAutoFocused, setHasAutoFocused] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);

    const styles = useMemo(
      () => [
        tw`bg-white text-sm font-medium text-gray-700 ring-1 ring-black/5 flex rounded justify-between items-center`,
        structural && tw`hidden invisible`,
        disabled && tw`bg-gray-50 text-gray-500 cursor-not-allowed`,
        fullWidth && tw`w-full`,
        bgTransparent && tw`bg-transparent border-none ring-transparent`,
        textarea && tw`p-3 focus:(outline-none outline outline-blue-500)`,
        props.type === 'password' && tw`relative`,
        inputStyles && inputStyles,
      ],
      [structural, disabled, fullWidth, bgTransparent, textarea, props.type]
    );

    const [showEmoji, setShowEmoji] = useState(false);

    const blurHandler = (
      e:
        | React.FocusEvent<HTMLInputElement>
        | React.FocusEvent<HTMLTextAreaElement>
    ) => {
      const EMOJI_BTN_ID = 'emoji-btn';

      const newlyFocusedEle = e.relatedTarget as HTMLElement | null;
      const newElementId = newlyFocusedEle?.id;

      // Only fire the onBlur event if the focus went outside the input element
      if (newElementId === EMOJI_BTN_ID) return;

      if (showEmoji) return;

      props?.onBlur?.(e as React.FocusEvent<HTMLInputElement>);
    };

    const focusHandler = (
      e:
        | React.FocusEvent<HTMLInputElement>
        | React.FocusEvent<HTMLTextAreaElement>
    ) => {
      const { value } = e.target;

      if (props.type !== 'email' && props.type !== 'number') {
        // Resets the caret position when there is an existing value
        e.target?.setSelectionRange(value.length, value.length);
      }

      props?.onFocus?.(e as React.FocusEvent<HTMLInputElement>);
    };

    const emojiShowHandler = () => {
      setShowEmoji(!showEmoji);
    };

    const emojiPickHandler = (emoji: string) => {
      setShowEmoji(false);

      const inputElement = window.document.getElementById(
        id
      ) as HTMLTextAreaElement | null;

      if (!inputElement) return;

      // Focus the input element
      inputElement.focus();

      // Update the value
      const firstCharIdx = inputElement.selectionStart;
      const lastCharIdx = inputElement.selectionEnd;

      if (
        firstCharIdx?.constructor === Number &&
        lastCharIdx?.constructor === Number &&
        cursorPosition?.constructor === Number
      ) {
        // Insert the emoji at the cursor position
        const updatedValue =
          inputElement.value.substring(0, cursorPosition) +
          emoji +
          inputElement.value.substring(
            cursorPosition,
            inputElement.value.length
          );
        onEmojiChange?.(updatedValue);

        // set the cursor to the end of the emoji
        inputElement.selectionStart = cursorPosition + emoji.length;
        inputElement.selectionEnd = cursorPosition + emoji.length;
      } else {
        // Push the emoji
        onEmojiChange?.(emoji);
      }
    };

    const handleSelectCursorPosition = (
      event: React.SyntheticEvent<HTMLTextAreaElement>
    ) => {
      const cursorPosition = (event.target as HTMLTextAreaElement)
        .selectionStart;
      setCursorPosition(cursorPosition);
    };

    useDefocusHandler(emojiWrapperRef, () => {
      setShowEmoji(false);
    });

    const id = useMemo(
      () => (textarea ? props.id + '-' + uuid() : props.id) ?? uuid(),
      [props.id, textarea]
    );

    const autoAdjustTextAreaHeight = (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const keyDownHandler: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
      switch (e.key) {
        case 'Enter':
          if (submitOnEnter) {
            e.preventDefault();
            props.onKeyDown?.(
              e as unknown as React.KeyboardEvent<HTMLInputElement>
            );
          }
          return;
        default:
          return;
      }
    };

    useEffect(() => {
      if (props?.autoFocus && !hasAutoFocused && props?.type !== 'email') {
        const ele = document.getElementById(id);
        ele?.focus?.();
        setHasAutoFocused(ele !== null);
      }
    }, [props?.autoFocus, hasAutoFocused, id, props?.type]);

    return (
      <>
        {textarea ? (
          <div tw="relative">
            <Textarea
              id={id}
              {...(props as any)}
              ref={ref}
              rows={rows}
              placeholder={props.placeholder}
              aria-invalid={ariaInvalid}
              css={styles}
              spellCheck="off"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              autoSave="off"
              autoFocus={false}
              onSelect={(e) => handleSelectCursorPosition?.(e)}
              onBlur={blurHandler}
              onFocus={focusHandler}
              onInput={(e) => {
                autoGrow &&
                  autoAdjustTextAreaHeight(
                    e as React.ChangeEvent<HTMLTextAreaElement>
                  );
              }}
              onKeyDown={(e) => keyDownHandler(e)}
              style={{
                height: 'auto',
                minHeight: '20px',
                maxHeight: '158px',
                lineHeight: '1.5',
                overflow: 'hidden',
              }}
            />
            {enableEmoji && (
              <div tw="absolute top-0 right-0">
                <button
                  id="emoji-btn"
                  tw="p-1 bg-gray-200 mr-2 mt-2 rounded-full"
                  onClick={emojiShowHandler}
                >
                  <Smiley weight="bold" tw="text-gray-800" size={20} />
                </button>
                {showEmoji && (
                  <EmojiPickerWrapper ref={emojiWrapperRef}>
                    <EmojiPicker
                      onChange={({ emoji }) => emojiPickHandler(emoji)}
                    />
                  </EmojiPickerWrapper>
                )}
              </div>
            )}
          </div>
        ) : (
          <div css={styles}>
            <input
              {...(props as any)}
              id={id}
              disabled={disabled}
              onBlur={(e) => blurHandler(e)}
              onFocus={(e) => focusHandler(e)}
              ref={ref}
              aria-invalid={ariaInvalid}
              tw="w-full bg-transparent rounded p-3 focus:(outline-none outline outline-blue-500) placeholder-gray-300"
              type={
                props.type === 'password' && showPassword ? 'text' : props.type
              }
            />
            {props.type === 'password' && (
              <div
                onClick={() => setShowPassword(!showPassword)}
                tw="absolute right-0 mx-4 cursor-pointer text-gray-400 hover:text-gray-300"
              >
                {showPassword ? (
                  <Eye weight="bold" />
                ) : (
                  <EyeSlash weight="bold" />
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);

const EmojiPickerWrapper = styled.div(() => [
  tw`
    z-[99]
    top-[100%]
    right-0
    absolute
  `,
  css`
    .emoji-picker-react {
      z-index: inherit;
    }
  `,
]);

const Textarea = styled.textarea`
  ${tw`resize-none overflow-x-hidden overflow-y-auto max-h-[150px] text-sm p-0 placeholder-gray-300`}
`;

export default Input;
