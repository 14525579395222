import React, { useCallback } from 'react';
import tw, { styled } from 'twin.macro';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { getOverlay, OVERLAY_OPTIONS } from 'utils/overlayHelpers';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';
import { fireEvent } from 'utils/eventHelper';
import { showIntercom } from 'utils/intercomHelper';

const OverlayPreview = ({ overlay }: { overlay: OVERLAY_OPTIONS }) => {
  const { currentVariant, changeOverlay } = useAdEditorContext();
  const { currentVacancy } = useNavigationContext();

  const SVGComponent = useCallback(
    (overlay: OVERLAY_OPTIONS) => {
      return getOverlay(overlay, currentVariant, currentVacancy?.brand, true);
    },
    [
      currentVariant?.uuid,
      currentVariant?.company,
      currentVariant?.function,
      currentVariant?.logo?.path,
    ]
  );
  return (
    <div
      css={[
        tw`relative flex cursor-pointer flex-col gap-4 rounded bg-gray-100`,
      ]}
      onClick={() => changeOverlay(overlay)}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: SVGComponent(overlay),
        }}
      />
    </div>
  );
};

export const PlacementMenu = () => {
  const { currentVariant } = useAdEditorContext();
  const { activeCustomer } = useNavigationContext();

  const CERES_ID = 55;

  const placements =
    activeCustomer === CERES_ID
      ? Object.values(OVERLAY_OPTIONS)
      : Object.values(OVERLAY_OPTIONS).filter(
          (value) => value !== OVERLAY_OPTIONS.CERES
        );

  return (
    <div>
      {currentVariant?.path ? (
        <PlacementMenuOptionContainer>
          {placements.map((value) => (
            <OverlayPreview key={value} overlay={value as OVERLAY_OPTIONS} />
          ))}
        </PlacementMenuOptionContainer>
      ) : (
        <div>
          <span>Select an image before picking an overlay.</span>
        </div>
      )}
      <div tw="flex flex-col items-center mt-8">
        <Button
          onClick={() => {
            fireEvent('custom_overlay_interested');
            showIntercom(
              'Hi, I want to use my own custom overlays. Help me out.'
            );
          }}
        >
          Add your own overlay
        </Button>
      </div>
    </div>
  );
};

const PlacementMenuOptionContainer = styled.div(tw`grid grid-cols-2 gap-6`);
