import React from 'react';
import { FooterKeyFields } from '@symplbe/sympl-components';
import SocialIcon from 'components/icons/SocialIcon';

const ICON_SIZE = '16px';
export const defaultFooterSettings: FooterKeyFields = {
  companyName: 'Test NV',
  year: '2023',
  links: [
    {
      name: 'Facebook',
      url: '',
      icon: (
        <SocialIcon
          company="facebook"
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={'currentColor'}
          grayScale
        />
      ),
    },
    {
      name: 'Instagram',
      url: '',
      icon: (
        <SocialIcon
          company="instagram"
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={'currentColor'}
          grayScale
        />
      ),
    },
    {
      name: 'TikTok',
      url: '',
      icon: (
        <SocialIcon
          company="tiktok"
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={'currentColor'}
          grayScale
        />
      ),
    },
    {
      name: 'X',
      url: '',
      icon: (
        <SocialIcon
          company="twitter"
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={'currentColor'}
          grayScale
        />
      ),
    },
    {
      name: 'LinkedIn',
      url: '',
      icon: (
        <SocialIcon
          company="linkedin"
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={'currentColor'}
          grayScale
        />
      ),
    },
    {
      name: 'YouTube',
      url: '',
      icon: (
        <SocialIcon
          company="youtube"
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={'currentColor'}
          grayScale
        />
      ),
    },
  ],
};
