export const EUROPE_VAT_MASKS = [
  {
    key: 'AT',
    vat_mask: 'ATU99999999',
  },
  {
    key: 'BE',
    vat_mask: 'BE9999999999',
    validator:
      () =>
      (vat?: string): boolean => {
        if (!vat) return false;
        return /^\d{10}$/.test(vat.substring(2));
      },
  },
  {
    key: 'BG',
    vat_mask: 'BG9999999999',
  },
  {
    key: 'CY',
    vat_mask: 'CY99999999L',
  },
  {
    key: 'CZ',
    vat_mask: 'CZ9999999999',
  },
  {
    key: 'DE',
    vat_mask: 'DE999999999',
  },
  {
    key: 'DK',
    vat_mask: 'DK99999999',
  },
  {
    key: 'EE',
    vat_mask: 'EE999999999',
  },
  {
    key: 'EL',
    vat_mask: 'GR999999999',
  },
  {
    key: 'ES',
    vat_mask: 'ESa999999999*',
  },
  {
    key: 'FR',
    vat_mask: 'FRaa999999999',
  },
  {
    key: 'FI',
    vat_mask: 'FI99999999',
  },
  {
    key: 'HR',
    vat_mask: 'HR99999999999',
  },
  {
    key: 'HU',
    vat_mask: 'HU99999999',
  },
  {
    key: 'IE',
    vat_mask: 'IE9a99999W',
  },
  {
    key: 'IT',
    vat_mask: 'IT99999999999',
  },
  {
    key: 'LT',
    vat_mask: 'LT999999999999',
  },
  {
    key: 'LU',
    vat_mask: 'LU99999999',
  },
  {
    key: 'LV',
    vat_mask: 'LV99999999999',
  },
  {
    key: 'MT',
    vat_mask: 'MT99999999',
  },
  {
    key: 'NL',
    vat_mask: 'NL999999999B99',
  },
  {
    key: 'PL',
    vat_mask: 'PL9999999999',
  },
  {
    key: 'PT',
    vat_mask: 'PT999999999',
  },
  {
    key: 'RO',
    vat_mask: 'RO999999999',
  },
  {
    key: 'SE',
    vat_mask: 'SE999999999999',
  },
  {
    key: 'SI',
    vat_mask: 'SI99999999',
  },
  {
    key: 'SK',
    vat_mask: 'SK9999999999',
  },
  {
    key: 'XI',
    vat_mask: 'XI999999999',
  },
];
