import 'twin.macro';
import Button from 'components/button/Button';
import Dropdown from 'components/dropdown/Dropdown';
import KomboButton from 'components/integrations/KomboButton';
import { Title1, Body, Subhead } from 'components/typography/Typography';
import { FC } from 'react';
import {
  ExternalJob,
  PreferencesConfiguration,
  handleUnlinkExternalJobFn,
} from './PreferencesConfigTypes';
import React from 'react';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { Integration } from '@sentry/types';

export type PreferencesIntegrationsProps = {
  configData?: PreferencesConfiguration;
  externalJobs: any[];
  loadingExternalJobs: boolean;
  selectedExternalJob?: string;
  onHandleLinkExternalJob: () => void;
  onSetSelectedExternalJob: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  onRefetchExternalJobs?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  onHandleUnlinkExternalJob: handleUnlinkExternalJobFn;
};

type TIntegrationWithIconURL = Integration & { icon_url: string | undefined };

const PreferencesIntegrations: FC<PreferencesIntegrationsProps> = ({
  configData,
  externalJobs,
  loadingExternalJobs,
  selectedExternalJob,
  onSetSelectedExternalJob,
  onHandleLinkExternalJob,
  onRefetchExternalJobs,
  onHandleUnlinkExternalJob,
}) => {
  const renderItems = () => {
    return configData?.preferencesConfig.integrationEntities?.map(
      ({ integration, job_info }, key) => {
        const { icon_url } = integration as TIntegrationWithIconURL;
        return (
          <li tw="flex flex-row items-center py-3 gap-2" key={key}>
            <img tw="h-10 mr-2" alt="logo" src={icon_url} />
            {job_info.job_code && <Body>{job_info.job_code}</Body>}
            <Body>{job_info.name}</Body>
            <Button
              variant="link"
              onClick={() => onHandleUnlinkExternalJob(job_info as ExternalJob)}
            >
              Unlink
            </Button>
          </li>
        );
      }
    );
  };

  const renderDropdown = () => (
    <div tw="mt-4 flex flex-row gap-2">
      <Dropdown
        items={externalJobs.map((job: any) => ({
          key: job.id,
          label: job.name,
        }))}
        loading={loadingExternalJobs}
        value={selectedExternalJob}
        onChange={(key) => onSetSelectedExternalJob(key as string)}
        onClear={() => onSetSelectedExternalJob(undefined)}
        label="Select an external campaign"
        noResultsLabel="Go to your account settings to add an integration"
      />
      <Button onClick={onHandleLinkExternalJob} disabled={!selectedExternalJob}>
        Link to sympl campaign
      </Button>
    </div>
  );

  const noIntegrationsLinked =
    !configData?.preferencesConfig.integrationEntities.length;

  return (
    <div tw="border shadow-sm p-6 rounded-md bg-white mt-4">
      <Title1>Integrations</Title1>

      {noIntegrationsLinked && (
        <Subhead mb={2} isLight>
          Please note: new jobs in your ATS are synced periodically, it might
          take up to an hour for them to show up in sympl.
        </Subhead>
      )}

      <div>
        <ul>{renderItems()}</ul>
      </div>

      {externalJobs.length === 0 && loadingExternalJobs && (
        <div>Loading...</div>
      )}

      {externalJobs.length === 0 && !loadingExternalJobs && (
        <KomboButton onIntegrationCreated={onRefetchExternalJobs} />
      )}

      {externalJobs.length > 0 && renderDropdown()}
    </div>
  );
};

export default PreferencesIntegrations;
