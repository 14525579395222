import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Roadmap: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Open roadmap in new view / tab
    window.open('https://roadmap.sympl.works/', '_blank')?.focus();

    // Go back to previous view
    navigate(-1);
  }, []);

  return <></>;
};
