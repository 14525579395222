import tw, { styled } from 'twin.macro';

export const IconCircle = styled.div`
  ${tw`
    text-2xl
    p-4 
    rounded-full 
    m-4
  `}
`;
export const FormContainer = styled.div`
  ${tw`
    shadow-lg 
    rounded-lg 
    p-10 
    bg-white 
    w-full 
    max-w-[750px] 
    mx-auto
  `}
`;
export const PageContainer = styled.div`
  ${tw`
      flex
      items-center
      justify-center
      flex-col
      w-full 
      h-auto
      min-h-screen
  `}
`;
