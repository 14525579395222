import 'twin.macro';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'hooks/sympl-mutation';

import TextEditor from '../form/text-editor/TextEditor';
import { SUBMIT_FEEDBACK } from 'graphql/vacancies/mutations';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';
import { Body, Caption } from 'components/typography/Typography';
import { stripHtml } from 'utils/baseHelpers';

export enum FeedbackType {
  WEBSITE = 'website',
  ADVERTISING = 'ad',
}

export enum FeedbackStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVAL_NEEDED = 'APPROVAL_NEEDED',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  APPROVED = 'APPROVED',
  NO_STATUS = 'NO_STATUS',
}

export interface Feedback {
  status: FeedbackStatus;
  type: FeedbackType;
  annotation: string;
  name?: string;
  created_at?: Date;
}

export interface FeedbackWidgetProps {
  status: FeedbackStatus;
  type: FeedbackType;
  defaultValue?: string;
  previousFeedback?: Feedback[];
  onSubmit?: () => void;
}

interface FormFields {
  annotation: string;
}

interface FeedbackPayload {
  vacancyId: number;
  input: {
    status: string;
    type: FeedbackType;
    annotation: string;
  };
}

export const getFriendlyFeedbackStatus = (status: FeedbackStatus) => {
  switch (status) {
    case FeedbackStatus.IN_PROGRESS:
      return 'In progress';
    case FeedbackStatus.APPROVAL_NEEDED:
      return 'Approval needed from customer';
    case FeedbackStatus.CHANGES_REQUESTED:
      return 'Changes requested by customer';
    case FeedbackStatus.APPROVED:
      return 'Changes approved';
    default:
      return 'In progress';
  }
};

const decodeHTMLEntities = (str: string) => {
  const element = document.createElement('div');
  element.innerHTML = str;
  return element.innerText;
};

const FeedbackWidget: React.FC<FeedbackWidgetProps> = ({
  status,
  type,
  previousFeedback,
  onSubmit,
}) => {
  const { control, handleSubmit, reset: resetForm } = useForm();

  const { activeVacancy, isAdmin } = useNavigationContext();

  const [submitFeedback] = useMutation<undefined, FeedbackPayload>(
    SUBMIT_FEEDBACK
  );

  const [textEditor, setTextEditor] = useState('');

  const submitForReview = handleSubmit((data) =>
    submitHandler(FeedbackStatus.APPROVAL_NEEDED, data as FormFields)
  );

  const requestChanges = handleSubmit((data) =>
    submitHandler(FeedbackStatus.CHANGES_REQUESTED, data as FormFields)
  );

  const approve = handleSubmit((data) =>
    submitHandler(FeedbackStatus.APPROVED, data as FormFields)
  );

  const submitHandler = (newStatus: FeedbackStatus, data: FormFields) => {
    if (activeVacancy === undefined) return;

    if (
      window.confirm(
        "Are you sure you want to submit this feedback? You won't be able to change it after this."
      )
    ) {
      submitFeedback({
        variables: {
          vacancyId: activeVacancy,
          input: {
            status: newStatus,
            type,
            annotation: data.annotation,
          },
        },
      }).then(() => {
        resetForm();
        setTextEditor(' ');
        onSubmit?.();
      });
    }
  };

  return (
    <div tw="p-2 bg-gray-100 rounded-md">
      <div tw="flex flex-row items-center justify-between text-xs cursor-pointer p-4">
        <span tw="uppercase font-semibold">
          {getFriendlyFeedbackStatus(status)}
        </span>
      </div>

      <div tw="max-h-96 flex flex-col px-4 pb-4 md:flex-row">
        {previousFeedback && (
          <div tw="flex flex-col md:(mr-5 pr-5) md:border-r-2 overflow-y-scroll max-w-sm">
            <span tw="text-xs font-medium text-gray-400 float-right mb-2">
              Previous feedback
            </span>
            {previousFeedback
              .filter((fb) => fb.annotation)
              .reverse()
              .map((fb) => {
                return (
                  <div tw="mb-3">
                    <Body>{decodeHTMLEntities(stripHtml(fb.annotation))}</Body>
                    <Caption>
                      &ndash; {fb?.name} &ndash;{' '}
                      {fb.created_at && (
                        <span>
                          {new Date(fb.created_at).toLocaleDateString()}
                        </span>
                      )}
                    </Caption>
                  </div>
                );
              })}
          </div>
        )}
        {(status === FeedbackStatus.APPROVAL_NEEDED || isAdmin) && (
          <div tw="flex-1 mt-2 md:mt-0">
            <form>
              <Controller
                id={'annotation'}
                name={'annotation'}
                defaultValue={textEditor}
                control={control}
                render={({
                  onChange,
                }: {
                  value: string;
                  onChange: (value: string) => void;
                }) => (
                  <TextEditor
                    identifier="feedback_widget"
                    defaultValue={textEditor}
                    onChange={(value) => {
                      setTextEditor(value);
                      onChange(value);
                    }}
                    placeholder={'Leave feedback'}
                  />
                )}
              />
            </form>
            <div tw="flex flex-row justify-end mt-6">
              {isAdmin ? (
                <div className="isAdmin" tw="space-x-2">
                  <Button variant="secondary" onClick={approve}>
                    Force Approval
                  </Button>
                  <Button onClick={submitForReview}>Submit for review</Button>
                </div>
              ) : (
                <div tw="space-x-2">
                  <Button variant="secondary" onClick={requestChanges}>
                    Request changes
                  </Button>
                  <Button onClick={approve}>Approve deliverables</Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackWidget;
