import 'twin.macro';
import React from 'react';

import AppPage from 'components/page/app-page/AppPage';
import GeneralSettings from './general/GeneralSettings';
import SubscriptionSettings from './subscriptions/SubscriptionSettings';
import GuardedRoute from 'routing/GuardedRoute';
import { RouteGuards, Routes as RouteTypes } from 'types/routeTypes';
import InvoicingSettings from './invoicing/InvoicingSettings';
import MediaSettings from './media/MediaSettings';
import UsersConfig from './users/UsersConfig';
import AccountSettings from './account/AccountSettings';
import TemplatesRouter from 'views/templates/TemplatesRouter';
import IntegrationSettings from './integrations/IntegrationSettings';
import { IntegrationSucceeded } from './integrations/IntegrationSucceeded';
import { BrandsSettings } from './brands/BrandsSettings';
import BalanceSettings from './balance/BalanceSettings';
import { Routes, Route, useNavigate, Navigate, Outlet } from 'react-router-dom';

const Settings: React.FC = () => {
  const tabs = [
    { item: 'General', route: RouteTypes.GENERAL_SETTINGS },
    { item: 'Account', route: RouteTypes.ACCOUNT_SETTINGS },
    { item: 'Users', route: RouteTypes.USER_SETTINGS },
    { item: 'Brands', route: RouteTypes.BRAND_SETTINGS },
    { item: 'Integrations', route: RouteTypes.INTEGRATION_SETTINGS },
    { item: 'Subscription', route: RouteTypes.SUBSCRIPTION_SETTINGS },
    { item: 'Invoices', route: RouteTypes.INVOICING_SETTINGS },
    { item: 'Media', route: RouteTypes.MEDIA_SETTINGS },
    { item: 'Templates', route: RouteTypes.TEMPLATE_SETTINGS },
    { item: 'Balance', route: RouteTypes.BALANCE_SETTINGS },
  ];

  // const url = useResolvedPath('').pathname;
  const navigate = useNavigate();

  const activeTabIndex =
    tabs.findIndex((tab) => location.pathname.includes(tab.route)) ?? 0;

  return (
    <AppPage
      heading="Settings"
      tabs={tabs.map(({ item }) => item)}
      activeTabIndex={activeTabIndex}
      onTabChange={(index) => navigate(tabs[index].route)}
      allowPageWithoutSubscription
      allowPageWithoutVacancy
    >
      <Routes>
        <Route
          path={'general'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <GeneralSettings />
            </GuardedRoute>
          }
        />
        <Route
          path={'account'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <AccountSettings />
            </GuardedRoute>
          }
        />
        <Route
          path={'users'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <UsersConfig />
            </GuardedRoute>
          }
        />
        <Route
          path={'integrations'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <IntegrationSettings />
            </GuardedRoute>
          }
        />
        <Route
          path={'integrations-succeeded'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <IntegrationSucceeded />
            </GuardedRoute>
          }
        />
        <Route
          path={'subscription'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <SubscriptionSettings />
            </GuardedRoute>
          }
        />
        <Route
          path={'invoicing'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <InvoicingSettings />
            </GuardedRoute>
          }
        />
        <Route
          path={'media'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <MediaSettings />
            </GuardedRoute>
          }
        />
        <Route
          path={'templates'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <TemplatesRouter />
            </GuardedRoute>
          }
        />
        <Route
          path={'templates/*'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <TemplatesRouter />
            </GuardedRoute>
          }
        />
        <Route
          path={'brands'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <BrandsSettings />
            </GuardedRoute>
          }
        />
        <Route
          path={'balance'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <BalanceSettings />
            </GuardedRoute>
          }
        />
        <Route path="*" element={<Navigate replace to={`general`} />} />
      </Routes>
      <Outlet />
    </AppPage>
  );
};

export default Settings;
