import React from 'react';
import { Draggable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Flipper } from 'react-flip-toolkit';
import tw, { styled } from 'twin.macro';

import { Candidate, CandidateStates } from 'types/candidates/types';
import CandidateCard from '../card/CandidateCard';

// TODO: check out https://github.com/hello-pangea/dnd as replacement for react-beautiful-dnd

const CandidateColumn: React.FC<{
  state: CandidateStates;
  filteredCandidates: Candidate[];
  boardIsBusy: boolean;
  provided: any;
  snapshot?: DroppableStateSnapshot;
}> = ({ state, snapshot, boardIsBusy, provided, filteredCandidates }) => {
  return (
    <Wrapper ref={provided.innerRef} {...provided.droppableProps} key={state}>
      <List isActive={snapshot?.isDraggingOver}>
        <Flipper flipKey={filteredCandidates.join('')} spring="veryGentle">
          {filteredCandidates.map((candidate, index) => (
            <Draggable
              index={index}
              key={candidate.id}
              draggableId={candidate.id.toString()}
              isDragDisabled={boardIsBusy}
            >
              {(provided) => (
                <div
                  tw="mb-5"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <CandidateCard candidate={candidate} />
                </div>
              )}
            </Draggable>
          ))}
        </Flipper>
      </List>
      {provided.placeholder}
    </Wrapper>
  );
};

const Wrapper = styled.div(tw`
  w-full 
  h-[calc(100%-_4rem)]
  grow 
  block 
  bg-gray-100
  overflow-y-hidden
`);

const List = styled.ul<{ isActive?: boolean }>`
  ${tw`
    h-full
    overflow-y-auto
    no-scrollbar
    p-6 
    rounded-md 
    mt-2 
    w-full
  `}
  ${({ isActive }) => isActive && tw`shadow-lg border-2 border-indigo-500`}
`;

export default CandidateColumn;
