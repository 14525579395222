import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import {
  AppNotification,
  NotificationStatus,
  NotificationTypes,
} from 'types/notificationTypes';
import { X } from '@phosphor-icons/react';
import { useNotifications } from 'hooks/notificationHooks';
import dayjs from 'dayjs';
import { useMutation } from 'hooks/sympl-mutation';
import { MARK_AS_READ } from 'graphql/notifications/mutations';
import { Body } from 'components/typography/Typography';

export interface NotificationProps {
  notification: AppNotification;
  onChange?: () => void;
  icon?: NotificationTypes;
}

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const { markAsRead } = useNotifications();
  const [fromNow] = React.useState(dayjs(notification.date).fromNow());

  const notificationId = notification.id;
  const [markNotificationAsRead] = useMutation(MARK_AS_READ, {
    variables: {
      notificationId,
    },
  });
  const [status] = useState(notification.status);

  const readHandler = async () => {
    if (status === NotificationStatus.NEW) {
      await markNotificationAsRead();
      markAsRead(notificationId);
    }
    if (notification.url) window.location.replace(notification.url);
  };

  const emoji = (type: NotificationTypes) => {
    switch (type) {
      case NotificationTypes.PROCEDURE_OVERVIEW:
        return '👩‍💼';
      case NotificationTypes.COMMUNICATION_INCOMING:
        return '📩';
      default:
        return '🔥';
    }
  };
  return (
    <div tw="w-full h-full max-h-full " onClick={readHandler}>
      <Wrapper>
        <NotificationWrapper>
          <div tw="flex ">
            <EmojiWrapper type={notification.type}>
              <Body>{emoji(notification.type)}</Body>
            </EmojiWrapper>

            <div tw="ml-4">
              <TitleLabel tw="flex" status={notification.status}>
                <span tw="relative w-auto flex items-center mb-1">
                  {notification.title}
                  {notification.status === NotificationStatus.NEW && (
                    <BallIcon>NEW</BallIcon>
                  )}
                </span>
              </TitleLabel>
              <Message status={notification.status}>
                {notification.description}
              </Message>
              <DateLabel status={notification.status}>{fromNow}</DateLabel>
            </div>
          </div>
          <CloseWrapper>
            <CloseButton
              onClick={() => {
                //removeNotification(notification.id);
              }}
              hidden={true}
            >
              <span tw="sr-only">Close</span>
              <X weight="bold" tw="h-5 w-5" aria-hidden="true" />
            </CloseButton>
          </CloseWrapper>
        </NotificationWrapper>
      </Wrapper>
    </div>
  );
};

const EmojiWrapper = styled.p<{ type: NotificationTypes }>`
  ${tw`text-2xl p-8 flex items-center justify-center h-10 w-10 shrink-0 place-self-center rounded-full bg-yellow-200`}
  ${({ type }) =>
    type === NotificationTypes.PROCEDURE_OVERVIEW && tw`bg-green-200`};
  ${({ type }) =>
    type === NotificationTypes.COMMUNICATION_INCOMING && tw`bg-red-200`};
`;

const BallIcon = styled.span(
  tw`px-2 py-1.5 bg-sympl rounded-full text-white ml-5 -right-12 text-xs leading-3`
);

const CloseButton = styled.button<{
  hidden: boolean;
}>`
  ${tw` rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400`}
  ${(hidden) => hidden && tw`hidden`}
`;

const CloseWrapper = styled.div(tw`ml-4 shrink-0 flex self-center`);

const TitleLabel = styled.p<{ status: NotificationStatus }>`
  ${tw`text-gray-800 text-sm md:text-base `}
  ${({ status }) =>
    status === NotificationStatus.NEW && tw`text-sympl font-semibold`} /*${({
    status,
  }) => status === NotificationStatus.SEEN && tw`text-gray-400`}
  ${({ status }) => status === NotificationStatus.READ && tw`text-gray-400`}*/
`;

const Message = styled.p<{ status: NotificationStatus }>`
  ${tw`text-sm text-gray-600 mb-1.5`}
  ${({ status }) =>
    status === NotificationStatus.NEW &&
    tw`text-gray-800 first-letter:capitalize`}
`;
const DateLabel = styled.p<{ status: NotificationStatus }>`
  ${tw`text-gray-400 text-xs`}
  ${({ status }) =>
    status === NotificationStatus.NEW && tw`text-gray-700 font-semibold`} /*${({
    status,
  }) => status === NotificationStatus.SEEN && tw`text-gray-400`}
  ${({ status }) => status === NotificationStatus.READ && tw`text-gray-400`}*/
`;

const NotificationWrapper = styled.div(tw`flex items-center justify-between`);

const Wrapper = styled.div(
  tw`
    justify-between items-center outline-none
    shadow bg-white border-b-2 px-6 py-3
    last-of-type:(m-0 cursor-pointer) hover:bg-gray-200 md:(px-5 py-5)
  `
);

export default Notification;
