import React, { useEffect, useMemo, useState } from 'react';
import 'twin.macro';

import AutoTable from 'components/table/auto-table/AutoTable';
import EmptyState from 'components/empty-state/EmptyState';
import Button from 'components/button/Button';
import Dropdown, { DropdownItem } from 'components/dropdown/Dropdown';
import { Trash, CopySimple, Plus } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { GET_TEMPLATES } from 'graphql/templates/queries';
import useMetaQuery from 'hooks/metaQuery';
import { Meta } from 'types/apiTypes';
import { Template } from 'types/templateTypes';
import AutoPagination from 'components/table/auto-table/AutoPagination';
import { Routes } from 'types/routeTypes';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import useApiList from 'hooks/useApiList';
import { Body } from 'components/typography/Typography';
import { useMutation } from 'hooks/sympl-mutation';
import { DELETE_TEMPLATE } from 'graphql/templates/mutations';
import useNavigationContext from 'hooks/context/nav-context';
import Input from 'components/form/input/Input';
import InformationToolTip from 'components/tooltip/InformationToolTip';

export interface PaginatedTemplates {
  templates: Template[];
  meta: Meta;
}

export const TemplateOverview = () => {
  const [languageFilter, setLanguageFilter] = useState<string>('');
  const [slugFilter, setSlugFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [templatesType, setTemplatesType] = useState<string>('all');

  const { isAdmin } = useNavigationContext();

  const {
    loading,
    data,
    meta,
    refetch: refetchTemplates,
    fetchMore,
  } = useMetaQuery<
    PaginatedTemplates,
    {
      pageSize: number;
      currentPage: number;
      languageId: string;
      slug?: string;
      type?: string;
    }
  >(GET_TEMPLATES, {
    fetchPolicy: 'network-only',
    variables: {
      pageSize: 20,
      currentPage: currentPage,
      languageId: languageFilter,
      slug: slugFilter,
      type: templatesType,
    },
  });

  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
    onCompleted: refetchTemplates,
  });

  const { data: availableLanguages } = useApiList('languages');
  const { pagination: paginationMeta } = meta;

  const resetLanguageFilter = () => {
    setLanguageFilter(availableLanguages.map(({ key }) => key).join(','));
  };

  useEffect(() => {
    resetLanguageFilter();
  }, [availableLanguages]);

  const templates = data?.templates ?? [];

  useEffect(() => {
    setCurrentPage(1);
    fetchMore({
      variables: {
        pageSize: 20,
        currentPage: 1,
        languageId: languageFilter,
        slug: slugFilter,
        type: templatesType,
      },
    });
  }, [templatesType, languageFilter, slugFilter, fetchMore]);

  const languageChangeHandler = (selection?: string | DropdownItem) => {
    if (!selection) return;
    if (typeof selection === 'string') setLanguageFilter(selection);
    else setLanguageFilter(selection.key.toString());
  };

  const paginationHandler = async (currentPage: number) => {
    setCurrentPage(currentPage);
    try {
      await fetchMore({
        variables: {
          pageSize: 20,
          currentPage,
        },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult ?? prev,
      });
    } catch (_) {
      return Promise.reject();
    } finally {
      return Promise.resolve();
    }
  };

  const columns = [
    'Name',
    'Language',
    ...(isAdmin ? [<span className="isAdmin">Slug</span>] : []),
    'Actions',
  ];

  const AUTO_SLUGS = [
    'sympl_template_ask_for_resume',
    'sympl_template_resume_received',
    'sympl_template_survey_done',
  ];

  const rows = useMemo(
    () =>
      templates
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(
          ({
            id,
            name,
            language_id: languageId,
            customer_id: customerId,
            subject,
            body,
            slug,
          }) => {
            return [
              <Link
                to={`${Routes.TEMPLATE_SETTINGS}/${id}`}
                tw="flex flex-row gap-1 items-center"
              >
                <Body
                  key={id}
                  tw="text-gray-500 hover:cursor-pointer hover:text-black py-1 px-2"
                >
                  {name}
                </Body>
                {slug && AUTO_SLUGS.includes(slug) && (
                  <InformationToolTip
                    text="This is one of our standard templates, it's used when candidates apply. Feel free to duplicate this template and give it your own take. Afterwards, you can select the new template via the preferences menu item."
                    name="auto_template_tooltip"
                    icon="information"
                  />
                )}
              </Link>,
              <Body tw="text-gray-500">
                {
                  availableLanguages.find(({ key }) => key === languageId)
                    ?.label
                }
              </Body>,
              ...(isAdmin ? [<Body>{slug}</Body>] : []),
              <div tw="flex flex-row gap-2">
                <Link
                  to={`${Routes.TEMPLATE_SETTINGS}/create`}
                  state={{
                    subject,
                    body,
                    language: languageId,
                  }}
                >
                  <Button
                    variant="inverted"
                    size="small"
                    icon={<CopySimple weight="bold" />}
                  >
                    Duplicate
                  </Button>
                </Link>
                <Button
                  variant="inverted"
                  size="small"
                  icon={<Trash weight="bold" />}
                  disabled={!customerId || !id}
                  onClick={() => {
                    const confirmed = window.confirm(
                      'Are you sure you want to delete this template?'
                    );
                    if (confirmed) deleteTemplate({ variables: { id } });
                  }}
                >
                  Delete
                </Button>
              </div>,
            ];
          }
        ),
    [templates, deleteTemplate, availableLanguages, isAdmin]
  );

  if (!loading && !rows)
    return <EmptyState title={'Create your first template'} />;

  return (
    <div tw="p-1">
      <div tw="flex gap-20 justify-between items-center mb-5 pt-2">
        <div tw="flex gap-x-6 items-center">
          <div>
            <Dropdown
              items={[
                { key: 'all', label: 'All Templates' },
                { key: 'base', label: 'My Templates' },
                { key: 'starter', label: 'Starter Templates' },
                ...(isAdmin ? [{ key: 'slug', label: 'Admin Templates' }] : []),
              ]}
              label={'All Templates'}
              onChange={(key) => setTemplatesType(key as string)}
              onClear={() => setTemplatesType('all')}
            />
          </div>
          <div>
            <ApiDropdown
              enableSearch
              mode="key"
              type="languages"
              placeholder="All Languages"
              onChange={(value) => languageChangeHandler(value)}
              onClear={() => resetLanguageFilter()}
            />
          </div>
          {isAdmin && (
            <div className="isAdmin">
              <Input
                value={slugFilter}
                placeholder="Search by slug"
                onChange={(e) => {
                  setTemplatesType('slug');
                  setSlugFilter(e.target.value);
                }}
              />
            </div>
          )}
        </div>
        <div>
          <Link to={`${Routes.TEMPLATE_SETTINGS}/create`}>
            <Button variant="primary" icon={<Plus weight="bold" />}>
              New template
            </Button>
          </Link>
        </div>
      </div>
      <div>
        <AutoTable columns={columns} rows={rows} />
        <AutoPagination
          selectedPage={currentPage}
          itemsLoaded={paginationMeta?.count}
          maxItems={paginationMeta?.count}
          totalItems={paginationMeta?.total}
          totalPages={paginationMeta?.total_pages}
          onPageChange={paginationHandler}
        />
      </div>
    </div>
  );
};

export default TemplateOverview;
