import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import useWindowDimensions from 'hooks/viewport';

import SvgAdd from '../svg/SvgAdd';

export type EmptyStateTextPosition = 'left' | 'right';

const EmptyState: React.FC<{
  title: string;
  subtitle?: string;
  illustration?: React.ReactNode;
  textPosition?: EmptyStateTextPosition;
}> = ({ title, subtitle, illustration, textPosition, children }) => {
  const { width } = useWindowDimensions();

  const showIllustration = useMemo(() => width > 570, [width]);

  return (
    <div tw="flex justify-center items-center w-full h-full">
      {showIllustration && (
        <div
          css={[
            (textPosition ?? 'right') === 'left' ? tw`order-2` : tw`order-1`,
          ]}
        >
          {illustration ?? <SvgAdd />}
        </div>
      )}
      <DropdownWrapper
        showIllustration={showIllustration}
        textPosition={textPosition ?? 'right'}
      >
        <h2 tw="text-2xl text-gray-700 font-medium">{title}</h2>
        {subtitle && <p tw="text-gray-600 mt-1">{subtitle}</p>}
        {children && <div tw="mt-8 w-full">{children}</div>}
      </DropdownWrapper>
    </div>
  );
};

const DropdownWrapper = styled.div<{
  showIllustration: boolean;
  textPosition: EmptyStateTextPosition;
}>`
  ${tw`flex flex-col justify-start w-72`}

  ${({ showIllustration }) => showIllustration && tw`ml-12`}

  ${({ textPosition }) => (textPosition === 'left' ? tw`order-1` : tw`order-2`)}
`;

export default EmptyState;
