import React, { useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'twin.macro';

import Button from 'components/button/Button';
import Checkbox from 'components/form/checkbox/Checkbox';
import { Error } from 'components/typography/Typography';
import Input from 'components/form/input/Input';
import Label from 'components/form/Label';
import { FormQuestion } from '../form-questions-list/FormQuestionsList';
import { SurveyContext } from 'context/SurveyContext';

export interface FormQuestionItemProps {
  question: FormQuestion;
}

export const QuestionItemForm: React.FC<FormQuestionItemProps> = ({
  question,
}) => {
  const formMethods = useForm();
  const { changeQuestion, cancelEditQuestion, deleteQuestion } =
    useContext(SurveyContext);

  const { control, handleSubmit, formState, setError } = formMethods;

  const errors = useMemo(() => formState.errors, [formState.errors]);

  const typeId = `question-form-input-type-${question?.ref}`;
  const requiredId = `question-form-input-req-${question?.ref}`;
  const valueId = `question-form-input-value-${question?.ref}`;

  const [requiredValue, setRequiredValue] = useState(
    question?.required ?? false
  );

  const getFormValues = (data: { [key: string]: string | boolean }[]) => {
    const dataEntries = Object.entries(data);

    // Gets form values by their respective ids
    const value = dataEntries.find(
      (entry) => entry[0] === valueId
    )?.[1] as unknown as string;

    const required = dataEntries.find(
      (entry) => entry[0] === requiredId
    )?.[1] as unknown as boolean;

    const type = dataEntries.find(
      (entry) => entry[0] === typeId
    )?.[1] as unknown as string;

    return { value, required, type };
  };

  const onSubmit = (data: { [key: string]: string | boolean }[]) => {
    const { value, required } = getFormValues(data);

    const parsedValue = value.trim();

    if (parsedValue === '') {
      setError(valueId, { type: 'required' });
      return;
    }

    changeQuestion?.({
      ...question,
      label: parsedValue,
      required,
    });
  };

  const requiredChangeHandler = (
    required: boolean,
    onChange: (data: boolean) => void
  ) => {
    setRequiredValue(required);
    onChange(required);
  };

  const cancelHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!question) return;
    cancelEditQuestion?.();
  };

  const deleteHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    deleteQuestion?.(question?.ref!);
  };

  return (
    <form tw="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div tw="flex flex-col">
          <Label htmlFor={valueId}>{'Question'}</Label>
          <Controller
            id={valueId}
            name={valueId}
            rules={{ required: true }}
            control={control}
            defaultValue={question?.label}
            render={({ value, onChange }) => (
              <Input defaultValue={value} onChange={onChange} />
            )}
          />
          {errors[valueId] !== undefined && (
            <div tw="flex justify-start mb-4">
              <Error>{'The question value is required'}</Error>
            </div>
          )}
        </div>

        <div tw="flex flex-col mt-2">
          <Label
            htmlFor={requiredId}
            tw="text-sm pb-2 w-min md:pb-0"
            fullWidth={false}
          >
            Required
          </Label>
          <Controller
            id={requiredId}
            name={requiredId}
            rules={{ required: false }}
            control={control}
            defaultValue={requiredValue}
            render={({ onChange }: { onChange: any }) => (
              <Checkbox
                type="checkbox"
                id={requiredId}
                name={requiredId}
                checked={requiredValue}
                onCheck={(checked) => requiredChangeHandler(checked, onChange)}
              />
            )}
          />
        </div>
      </div>

      <div tw="w-full flex mt-4">
        <Button variant="danger" onClick={deleteHandler}>
          Delete
        </Button>

        <div tw="flex space-x-2 ml-auto">
          <Button variant="outline" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </div>
      </div>
    </form>
  );
};

export default QuestionItemForm;
