import { IntakeTemplate, IntakeType } from 'types/intakeTypes';
import { IntakeNotification, TIntakeConfig } from 'types/intakes/core';

export const DEFAULT_INTAKE_STATE_CONFIG: {
  errors: Error[];
  templates: IntakeTemplate[];
  skippedSteps: number[];
  templateTypes: IntakeType[];
  notifications: IntakeNotification[];
  filesBeingUploaded: string[];
  intakeConfig: TIntakeConfig | null;
  currentIntakeHash: string | null;
  currentIntakeId: number;
  currentSectionId: number;
  currentStepId: number;
  isCompleted: boolean;
  hasStarted: boolean;
  hasFinished: boolean;
  loading: boolean;
} = {
  errors: [],
  templates: [],
  skippedSteps: [],
  templateTypes: [],
  notifications: [],
  filesBeingUploaded: [],
  intakeConfig: null,
  currentIntakeHash: null,
  currentIntakeId: -1,
  currentSectionId: -1,
  currentStepId: -1,
  isCompleted: false,
  hasStarted: false,
  hasFinished: false,
  loading: false,
};
