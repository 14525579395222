import useNavigationContext from 'hooks/context/nav-context';
import React, { createContext, useEffect, useState } from 'react';
export interface FiltersType {
  customers: Array<string>;
  brands: Array<string>;
  vacancies: Array<string>;
  start_date: string | null;
  end_date: string | null;
}

export type ReportingData = {
  applications: number;
  impressions: number;
  spend: number;
  visits: number;
  clicks: number;
  ctr: number;
  conversion: number;
  cpm: number;
  cpc: number;
  cpa: number;
};

export type ReportingContextType = {
  filters: FiltersType;
  queryString: string;
  reportingScreen: ReportingScreens;
  defaultStartDate: string;
  defaultEndDate: string;
  setFilters: (filters: FiltersType) => void;
  setQueryString: (s: string) => void;
  setReportingScreen: (s: ReportingScreens) => void;
};

export const ReportingContext = createContext<ReportingContextType>(undefined!);

export type ReportingScreens = 'general' | 'channels' | 'creatives';

export const ReportingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const FILTER_DELAY = 1500;
  const { activeCustomer } = useNavigationContext();
  const [queryString, setQueryString] = useState<string>('');
  const [reportingScreen, setReportingScreen] =
    useState<ReportingScreens>('general');
  const today = new Date();
  const defaultStartDate = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split('T')[0];

  const defaultEndDate = today.toISOString().split('T')[0];

  const defaultFilters: FiltersType = {
    customers: [],
    brands: [],
    vacancies: [],
    start_date: `${defaultStartDate}`,
    end_date: `${defaultEndDate}`,
  };

  const [filters, setFilters] = useState<FiltersType>(defaultFilters);
  useEffect(() => {
    if (activeCustomer) {
      setFilters(defaultFilters);
    }
  }, [activeCustomer]);

  const buildQueryFiltering = () => {
    let params = '';
    if (filters && filters.customers.length)
      params += `filter[customers]=${filters.customers.join(',')}&`;
    if (filters && filters.brands.length)
      params += `filter[brands]=${filters.brands.join(',')}&`;
    if (filters && filters.vacancies.length)
      params += `filter[vacancies]=${filters.vacancies.join(',')}&`;
    if (filters && filters.start_date)
      params += `filter[start_date]=${filters.start_date}&`;
    if (filters && filters.end_date)
      params += `filter[end_date]=${filters.end_date}&`;
    if (params.charAt(params.length - 1) === '&') params = params.slice(0, -1);
    return params;
  };

  let debounceTimeout: NodeJS.Timeout | null = null;
  useEffect(() => {
    let isCancelled = false;
    if (debounceTimeout) clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      if (!isCancelled) setQueryString(buildQueryFiltering());
    }, FILTER_DELAY);

    // Cleanup function to cancel the request when filters change again
    return () => {
      isCancelled = true;
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, [filters]);

  return (
    <ReportingContext.Provider
      value={{
        filters,
        queryString,
        reportingScreen,
        defaultStartDate,
        defaultEndDate,
        setFilters,
        setQueryString,
        setReportingScreen,
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};
