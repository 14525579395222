export type ApiResponse<T> = {
  success: boolean;
  message: string;
  errors: string[];
  data: T;
  meta: string[];
};

export enum HttpStatusCodes {
  UNAUTHORIZED = 401,
  TWO_FACTOR_PENDING = 468,
  NETWORKERROR = 'network error',
}
