import React, { useContext } from 'react';
import { X, User } from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';

import { CandidateContext } from 'context/CandidateContext';

const Toggle: React.FC<{
  variant: 'open' | 'close';
}> = ({ variant }) => {
  const candidateContext = useContext(CandidateContext);

  const sidebarIsOpen = candidateContext
    ? candidateContext.sidebarIsOpen
    : false;

  const setSidebarIsOpen = candidateContext
    ? candidateContext.setSidebarIsOpen
    : () => {};

  return (
    <Wrapper
      isOpen={sidebarIsOpen}
      variant={variant}
      onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
    >
      {variant !== 'open' ? (
        <CloseWrapper isOpen={sidebarIsOpen}>
          <X size="24" tw="text-sympl" />
        </CloseWrapper>
      ) : (
        <OpenWrapper isOpen={sidebarIsOpen}>
          <User size="24" tw="text-sympl" />
        </OpenWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isOpen: boolean; variant: 'open' | 'close' }>`
  ${tw`
  bg-red-200 z-10 rounded-full p-2 shadow-xl cursor-pointer w-auto xl:(invisible hidden)
  `}

  ${({ isOpen, variant }) =>
    isOpen && variant === 'open' && tw`invisible hidden`}
`;

const CloseWrapper = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => isOpen && tw`visible block`}
`;

const OpenWrapper = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => isOpen && tw`invisible hidden`}
`;

export default Toggle;
