import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';

const BlurredPage: React.FC<{
  popup?: JSX.Element;
}> = ({ popup }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowPopup(true), 500);
    return () => clearTimeout(timer);
  }, [popup]);

  if (!showPopup) return <></>;
  return (
    <div tw="h-full w-full relative animate-fade-in-up">
      <PopupWrapper>
        <div tw="flex flex-col items-center">{popup}</div>
      </PopupWrapper>
    </div>
  );
};

const PopupWrapper = styled.div(
  tw`absolute table inset-x-1/2 bg-white border-gray-300 border-2 rounded-lg shadow-2xl p-5 w-max top-1/2 max-w-lg -translate-x-1/2 -translate-y-1/2`
);

export default BlurredPage;
