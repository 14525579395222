import React, { useContext } from 'react';
import 'twin.macro';

import Title from 'components/title/Title';
import { Headline } from 'components/typography/Typography';
import ColorPicker from 'components/form/ColorPicker';
import { SurveyContext } from 'context/SurveyContext';

export function ColorSideBar() {
  const { primaryColor, secondaryColor, setPrimaryColor, setSecondaryColor } =
    useContext(SurveyContext);

  return (
    <div tw="w-full p-5 xl:w-[350px]">
      <div tw="mx-auto">
        <div tw="mb-8 flex flex-col overflow-x-hidden">
          <div tw="mb-3 border-b-2 border-gray-100">
            <Title>Select Color</Title>
          </div>
          <div tw="min-h-screen space-y-2">
            <div tw="flex place-items-center justify-between px-4">
              <Headline mb={0}>Buttons</Headline>
              <ColorPicker
                value={primaryColor}
                ariaInvalid={false}
                onChange={(color: string) => setPrimaryColor(color)}
              />
            </div>
            <div tw="flex place-items-center justify-between px-4">
              <Headline mb={0}>Questions</Headline>
              <ColorPicker
                value={secondaryColor}
                ariaInvalid={false}
                onChange={(color: string) => setSecondaryColor(color)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
