import React from 'react';
import tw, { styled } from 'twin.macro';

import NumberStat from './NumberStat';
import { numberToDecimalNotation, roundMoney } from 'utils/baseHelpers';
import { CampaignMetrics } from 'types/dashboard/types';

export interface StatListProps {
  metrics?: CampaignMetrics;
}

const StatList: React.FC<StatListProps> = ({
  metrics: {
    windowed_impressions = 0,
    windowed_spend = 0,
    windowed_applications = 0,
  } = {},
}) => {
  return (
    <ul tw="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 col-span-2 gap-6">
      <StatItem>
        <NumberStat
          statIcon="eye"
          upperLabel="Impressions"
          lowerLabel="This week"
          value={numberToDecimalNotation(windowed_impressions)}
        />
      </StatItem>
      <StatItem>
        <NumberStat
          statIcon="money"
          upperLabel="Budget Spent"
          lowerLabel="This week"
          value={`€${roundMoney(windowed_spend)}`}
        />
      </StatItem>
      <StatItem>
        <NumberStat
          statIcon="people"
          upperLabel="Applicants"
          lowerLabel="This week"
          value={numberToDecimalNotation(windowed_applications)}
        />
      </StatItem>
    </ul>
  );
};

const StatItem = styled.li(() => [
  tw`
    w-full
  `,
]);

export default StatList;
