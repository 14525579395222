import { useMutation } from 'hooks/sympl-mutation';
import GenerateVideoModal from 'components/modals/GenerateVideoModal';
import {
  COPY_VIDEO,
  GENERATE_VIDEO,
  UPDATE_VIDEO,
} from 'graphql/ad-variants/mutations';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CustomerResource } from 'types/apiTypes';
import useNavigationContext from 'hooks/context/nav-context';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import Button from 'components/button/Button';
import 'twin.macro';
import tw from 'twin.macro';
import Input from 'components/form/input/Input';
import { Controller, useForm } from 'react-hook-form';
import { AdCreativeType, AdPlacement } from 'types/adTypes';
import { ApolloError } from '@apollo/client';
import SpinningSymplLoader from 'components/loading/spinningSymplLoader';
import { Footnote } from 'components/typography/Typography';
import { fireEvent } from 'utils/eventHelper';

export type VideoGenContextType = {
  videoGen: () => void;
  copyVideo: () => void;
};

export const VideoGenContext = createContext<VideoGenContextType>(
  {} as VideoGenContextType
);

const VideoGenProvider: React.FC<ReactNode> = ({ children }) => {
  const [videoGenOpen, setVideoGenOpen] = useState(false);

  const { activeVacancy, currentVacancy } = useNavigationContext();
  const { updateCurrentVariant, currentVariant, variants, addVariant } =
    useAdEditorContext();

  // Make a controller to abort the request
  const videoRequestAborterController = new AbortController();

  const [videoGenError, setVideoGenError] = useState<string[] | null>(null);
  const [activeThread, setActiveThread] = useState<string>('');

  // TODO: this is a long request, so an abortController should be made
  const [generateVideoRequest, { loading: loadingGenerate }] = useMutation<
    { video: CustomerResource },
    { vacancyId: number; format: string }
  >(GENERATE_VIDEO, {
    abortController: videoRequestAborterController,
    onError: (e: ApolloError) => handleError(e.message),
  });

  const [updateVideoRequest, { loading: loadingUpdate }] = useMutation<
    { video: CustomerResource },
    {
      vacancyId: number;
      format: string;
      feedback: string;
      threadId: string;
      oldVideoId: number;
    }
  >(UPDATE_VIDEO, {
    abortController: videoRequestAborterController,
    onError: (e: ApolloError) => handleError(e.message),
  });

  const [copyVideoRequest, { loading: loadingCopy }] = useMutation<
    { video: CustomerResource },
    {
      vacancyId: number;
      format: string;
      threadId: string;
    }
  >(COPY_VIDEO, {
    abortController: videoRequestAborterController,
    onError: (e: ApolloError) => handleError(e.message),
  });

  const loading = useMemo(
    () => loadingCopy || loadingUpdate || loadingGenerate,
    [loadingCopy, loadingUpdate, loadingGenerate]
  );

  // Use this to handle the errors
  const handleError = (error: string) => {
    if (error.includes('400')) {
      setVideoGenError([
        'Our video generator needs more images in order to create a video.',
        'Make sure you uploaded at least 3 visuals, either linked to your brand or as ad variants for this campaign.',
      ]);
    } else {
      setVideoGenError([
        'Something went wrong while processing your request. Please note this is a beta feature. We appreciate your feedback via the button on the right.',
      ]);
    }
  };

  const videoGen = async () => {
    fireEvent('video_gen');
    // There has to be an active thread and a variant on display in order to allow for feedback
    if (!!activeThread && !!currentVariant?.path) {
      setVideoGenOpen(true);
    } else {
      await generateVideoHandler();
    }
  };

  const generateVideoHandler = async () => {
    setVideoGenOpen(true);
    // Try catch does not work
    const { data, errors } = await generateVideoRequest({
      variables: {
        vacancyId: activeVacancy!,
        format: currentVariant!.placement,
      },
    });

    // If errors occured, they will be handled by the error handler
    if (!!errors) {
      return;
    }

    setVideoGenOpen(false);
    updateCurrentVariant('path', data!.video, true);
    setVideoGenError(null);
  };

  // Copy video to other formats (feed <-> reels & story)
  const copyVideo = async () => {
    if (!currentVariant?.placement) return;

    const newPlacements =
      currentVariant!.placement === AdPlacement.FEED
        ? [AdPlacement.REELS, AdPlacement.STORIES]
        : [AdPlacement.FEED];

    setVideoGenOpen(true);

    const { data, errors } = await copyVideoRequest({
      variables: {
        vacancyId: activeVacancy!,
        format: newPlacements[0],
        threadId: activeThread,
      },
    });

    // If errors occured, they will be handled by the error handler
    if (!!errors) {
      return;
    }

    newPlacements.forEach((newPlacement) => {
      // TODO: this code is duplicated from addPreviewSubmitHandler and should be deduped
      // TODO: => extract to function
      // Make a new variant and set it to the generated stuff
      const referenceVariant =
        variants.find(({ placement: p }) => p === newPlacement) ?? variants[0];

      if (!referenceVariant) return;

      const bannerTitle =
        currentVacancy?.brand?.default_banner_title ?? 'WANTED';
      // Create a new variant using the existing common properties
      addVariant({
        ...referenceVariant,
        id: undefined,
        path: null,
        ...(newPlacement === AdPlacement.REELS && { logo: null }),
        ...([AdPlacement.STORIES, AdPlacement.REELS].includes(newPlacement) && {
          description: '',
          ...(newPlacement === AdPlacement.REELS && {
            text: `${bannerTitle}: ${currentVacancy?.title} ${
              currentVacancy?.location ? `in ${currentVacancy.location}` : ''
            }`,
          }),
        }),
        svg: null,
        isDeleted: false,
        isDirty: true,
        placement: newPlacement,
        creative_type: AdCreativeType.VIDEO,
      });

      updateCurrentVariant('path', data!.video, true);
    });

    setVideoGenOpen(false);
    setVideoGenError(null);
  };

  const videoFeedbackHandler = async (feedback: string) => {
    setVideoGenOpen(true);

    const { data, errors } = await updateVideoRequest({
      variables: {
        vacancyId: activeVacancy!,
        format: currentVariant!.placement,
        feedback,
        threadId: activeThread,
        oldVideoId: currentVariant!.path!.id,
      },
    });

    // If errors occured, they will be handled by the error handler
    if (!!errors) {
      return;
    }

    setVideoGenOpen(false);
    updateCurrentVariant('path', data!.video, true);
    setVideoGenError(null);
  };

  // Function to abort the request. Also closes modal
  const abortVideoRequest = () => {
    videoRequestAborterController.abort();
    setVideoGenOpen(false);
    setVideoGenError(null);
  };

  // Update active thread when switching ad variants
  useEffect(() => {
    setActiveThread(currentVariant?.path?.thread_id ?? '');
  }, [currentVariant]);

  const retry = () => {
    setVideoGenError(null);
    if (!activeThread || !currentVariant?.path) {
      generateVideoHandler();
    }
  };

  return (
    <>
      <GenerateVideoModal
        show={videoGenOpen}
        hideCloseButton={loading}
        onClose={abortVideoRequest}
      >
        <>
          {!!videoGenError ? (
            <div tw="flex flex-col gap-2 items-center mb-2">
              {videoGenError.map((e, idx) => (
                <p key={`videogen-error-${idx}`} tw="break-words">
                  {e}
                </p>
              ))}
              <p>If the problem persists, please let us know.</p>
              <Button customStyle={tw`mt-4`} onClick={retry}>
                Retry
              </Button>
            </div>
          ) : (
            <>
              {!!activeThread && !loading ? (
                <FeedbackModal feedbackFunc={videoFeedbackHandler} />
              ) : (
                <AbortModal
                  abortFunc={abortVideoRequest}
                  isFeedbackRun={!!activeThread}
                />
              )}
            </>
          )}
        </>
      </GenerateVideoModal>
      <VideoGenContext.Provider value={{ videoGen, copyVideo }}>
        {children}
      </VideoGenContext.Provider>
    </>
  );
};

interface IFeedbackModal {
  feedbackFunc: (feedback: string) => void;
}

interface IFeedbackForm {
  feedback: string;
}

const FeedbackModal: React.FC<IFeedbackModal> = ({ feedbackFunc }) => {
  const { handleSubmit, control } = useForm<IFeedbackForm>({
    defaultValues: { feedback: '' },
  });
  const submitFunc = async ({ feedback }: IFeedbackForm) => {
    feedbackFunc(feedback);
  };

  return (
    <form
      tw="flex flex-col items-center gap-4"
      onSubmit={handleSubmit(submitFunc)}
    >
      <h2>Give feedback to the generated video</h2>
      <Controller
        control={control}
        name="feedback"
        render={({ onChange, onBlur, value }) => (
          <Input
            tw="w-[30vw] max-w-[40ch]"
            textarea
            autoGrow
            placeholder="e.g.: Switch the image in the first and second slide"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      <Button type="submit">Regenerate</Button>
    </form>
  );
};

interface IAbortModal {
  abortFunc: () => void;
  isFeedbackRun: boolean;
}

const AbortModal: React.FC<IAbortModal> = ({ abortFunc, isFeedbackRun }) => {
  return (
    <div tw="flex flex-col gap-2 items-center mb-2">
      <SpinningSymplLoader
        messages={[
          'Starting up the robots 🤖',
          isFeedbackRun
            ? 'Handling your feedback 🤝'
            : 'Writing the script for your video 📝',
          'Gathering visuals & texts',
          'Started generating the video 📸',
          'Take some time and enjoy a coffee ☕',
          'Tip: You can make changes to the video by \nclicking the ✨ icon in the toolbar',
          'Almost done! 🚀',
          "We're finishing up... ✅",
        ]}
        delay={7000}
        loading={true}
      />
      <Button customStyle={tw`mx-auto inline-block`} onClick={abortFunc}>
        Cancel
      </Button>
      <Footnote mt={1} isCenter>
        Please note this is a beta feature.
        <br /> We appreciate your feedback via the button on the right.
      </Footnote>
    </div>
  );
};

export default VideoGenProvider;
