import React from 'react';
import tw, { styled } from 'twin.macro';

import { Ad } from 'types/adTypes';
import AdListItem from '../ad-list-item/AdListItem';
import WrapperEmpty from 'components/wrapper-empty/WrapperEmpty';

import { Body } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';

const AdList: React.FC<{ ads: Ad[] }> = ({ ads }) => {
  const { isAdmin, hasSymplVacancyPage: showVacancyPageMetrics } =
    useNavigationContext();

  if (ads.length === 0)
    return (
      <WrapperEmpty>
        <Body>
          There are currently no ads. It could take some time for your ads to
          show up here after publishing your campaign.
        </Body>
      </WrapperEmpty>
    );

  const showConversions = ads.some((ad) => !!ad.adMetric?.convs3075);

  return (
    <div tw="flex flex-col overflow-x-auto shadow m-1 sm:rounded-lg">
      <table tw="min-w-full overflow-x-scroll divide-y divide-gray-200">
        <thead tw="bg-gray-50">
          <tr tw="h-full">
            <TableHeading scope="col">Ad Type</TableHeading>
            <TableHeading scope="col">Published on</TableHeading>
            <TableHeading scope="col">Impressions</TableHeading>
            {!showVacancyPageMetrics && (
              <TableHeading scope="col">Clicks</TableHeading>
            )}
            {showVacancyPageMetrics && (
              <TableHeading scope="col">Visits</TableHeading>
            )}
            {isAdmin && (showVacancyPageMetrics || showConversions) && (
              <TableHeading isAdmin scope="col">
                Conversions
              </TableHeading>
            )}
            {showVacancyPageMetrics && (
              <TableHeading scope="col">Leads</TableHeading>
            )}

            {showVacancyPageMetrics && (
              <TableHeading scope="col">Applications</TableHeading>
            )}

            {isAdmin && showVacancyPageMetrics && (
              <TableHeading isAdmin scope="col">
                APMI
              </TableHeading>
            )}

            {isAdmin && (
              <TableHeading isAdmin scope="col">
                Priority
              </TableHeading>
            )}

            <TableHeading scope="col">Spent</TableHeading>
            <TableHeading scope="col">Status</TableHeading>
          </tr>
        </thead>
        <tbody tw="bg-white divide-y divide-gray-200">
          {ads.map((ad) => (
            <AdListItem
              ad={ad}
              key={ad.id}
              showVacancyPageMetrics={showVacancyPageMetrics}
              showConversions={showConversions}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TableHeading = styled.th<{ isAdmin?: boolean }>`
  ${tw`
    px-6 
    py-3 
    text-left 
    text-xs 
    font-medium 
    text-gray-500 
    uppercase 
    tracking-wider 
    truncate
  `}
  ${({ isAdmin }) =>
    isAdmin &&
    tw`
      bg-red-50
      text-red-900
      font-bold
      border-b-2
      border-b-red-500
    `}
`;

export default AdList;
