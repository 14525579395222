import { gql } from '@apollo/client';

export const GET_EMAIL_METRICS = gql`
  query getEmailMetrics {
    campaignMetrics
      @rest(type: "[CampaignMetrics]", path: "/v4/dashboard/emails") {
      emails {
        vac_id
        proc_id
        can_id
        candidate_name
        vac_name
        inbound_date
      }
    }
  }
`;
