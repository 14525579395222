import React from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import ToolTip from 'components/tooltip/ToolTip';

import SvgSpinner from '../svg/SvgSpinner';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'indigo'
  | 'sympl'
  | 'outline'
  | 'inverted'
  | 'gray'
  | 'danger'
  | 'success'
  | 'transparent'
  | 'link'
  | 'default'
  | 'pill';
export type ButtonType = 'button' | 'submit';
type ButtonSize = 'small';

export interface ButtonProps {
  id?: string;
  tabIndex?: number;
  type?: ButtonType;
  loading?: boolean;
  children?: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  stretch?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  autoFocus?: boolean;
  customStyle?: TwStyle;
  role?: string;
  icon?: React.ReactNode;
  tooltip?: {
    text?: string;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    arrow?: boolean;
  };
}

const BaseButton: React.FC<ButtonProps> = ({
  id,
  tabIndex = 0,
  type = 'button',
  loading = false,
  variant = 'primary',
  disabled = false,
  stretch = false,
  onClick,
  children,
  size,
  autoFocus = false,
  customStyle,
  role,
  icon,
}) => {
  return (
    <Container
      id={id}
      role={role}
      tabIndex={tabIndex}
      disabled={disabled}
      isLoading={loading}
      isStretched={stretch}
      type={type}
      variant={variant}
      size={size}
      onClick={(e) => onClick?.(e)}
      autoFocus={autoFocus}
      customStyle={customStyle}
    >
      {loading && <SvgSpinner />}
      {icon && !loading && icon}
      {children}
    </Container>
  );
};

const Button: React.FC<ButtonProps> = ({ tooltip, ...props }: ButtonProps) => {
  return tooltip ? (
    <ToolTip
      text={tooltip.text}
      placement={tooltip.placement}
      arrow={tooltip.arrow}
    >
      <div>
        <BaseButton {...props} />
      </div>
    </ToolTip>
  ) : (
    <BaseButton {...props} />
  );
};

const Container = styled.button<{
  isLoading: boolean;
  variant: ButtonVariant;
  size?: ButtonSize;
  isStretched: boolean;
  disabled: boolean;
  customStyle?: TwStyle;
}>`
  ${tw`
    inline-flex items-center px-4 text-center py-2 border border-gray-100 rounded-md text-sm font-medium shadow-sm gap-1
    hover:bg-gray-50 focus:outline-none
    transition whitespace-nowrap
  `}

  ${({ isStretched }) => isStretched && tw`w-full text-center inline-block`}

  ${({ size }) => size === 'small' && tw`text-sm px-3 font-medium py-1`}

  ${({ disabled }) =>
    disabled && tw`cursor-not-allowed! opacity-50! pointer-events-none!`}

  ${({ isLoading }) =>
    isLoading && tw`cursor-wait! opacity-50! pointer-events-none!`}

  ${({ variant }) =>
    variant === 'primary' && tw`bg-gray-800 text-white hover:(bg-gray-700)`}

  ${({ variant }) => variant === 'secondary' && tw`bg-white text-gray-700`}

  ${({ variant }) =>
    variant === 'indigo' && tw`bg-indigo-600 text-white hover:(bg-indigo-700)`}

  ${({ variant }) =>
    variant === 'sympl' && tw`bg-sympl border text-white hover:(bg-gray-700)`}

  ${({ variant }) =>
    variant === 'danger' &&
    tw`bg-red-500 border-none text-white border-2 hover:(bg-red-400)`}

  ${({ variant }) =>
    variant === 'outline' &&
    tw`bg-white shadow-none text-gray-700 hover:(bg-gray-200)`}

  ${({ variant }) =>
    variant === 'transparent' &&
    tw`bg-white/10 border-none hover:(bg-transparent)`}

  ${({ variant }) => variant === 'inverted' && tw`bg-gray-100 text-gray-700`}
  ${({ variant }) =>
    variant === 'gray' &&
    tw`bg-gray-600 text-white hover:bg-gray-500 active:bg-gray-700`}
  ${({ variant }) =>
    variant === 'success' && tw`bg-green-100 text-green-700 hover:bg-green-50`}

  ${({ variant }) =>
    variant === 'link' &&
    tw`border-none shadow-none p-0 bg-transparent text-black outline-none hover:(bg-transparent text-slate-700) focus:(outline-none ring-0)`}

    ${({ variant }) =>
    variant === 'pill' &&
    tw`border-indigo-500 text-indigo-500 border-2 text-xs px-3 py-0.5 rounded-full bg-transparent hover:(border-indigo-400 text-indigo-400) focus:(outline-none ring-0)`}

  ${({ variant }) =>
    variant === 'default' &&
    tw`bg-gray-100 border text-gray-800 hover:(bg-gray-300)`}

  ${({ customStyle }) => customStyle}
`;

export default Button;
