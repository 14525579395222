import React, { forwardRef, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import styled from 'styled-components';
import { ChatCenteredDots, Check, X } from '@phosphor-icons/react';

import { animated, useSpring } from 'react-spring/web';
import { useFormContext } from 'react-hook-form';

import 'twin.macro';

import { IntakeQuestion } from 'types/intakeTypes';

interface IProps {
  withText?: boolean;
  question: IntakeQuestion;
}

const AnnotationWrapper = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>((props, ref) => (
  <div tw="ml-auto" ref={ref}>
    {props.children}
  </div>
));

const Annotation = (props: IProps) => {
  const { register } = useFormContext();
  const [visible, setVisible] = useState(false);

  const config = { tension: 300, friction: 15 };
  const initialStyles = { opacity: 0, transform: 'scale(0.5)' };
  const [spring, setSpring] = useSpring(() => initialStyles);

  const annotationId = `${props.question.id.toString()}-annotation`;

  const onMount = () => {
    setSpring({
      opacity: 1,
      transform: 'scale(1)',
      onRest: () => {},
      config,
    });
  };

  const onHide = (instance: any) => {
    setSpring({
      ...initialStyles,
      onRest: instance.unmount,
      config: { ...config, clamp: true },
    });
  };

  const hideAnnotationHandler = (
    e: Event | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setVisible(false);
  };

  const showAnnotationHandler = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault();
    setVisible(true);
  };

  return (
    <Tippy
      visible={visible}
      onClickOutside={(_instance, e) => hideAnnotationHandler(e)}
      onMount={onMount}
      onHide={onHide}
      placement="bottom"
      interactive={true}
      render={(attrs) => (
        <AnnotationContentWrapper
          tw="rounded-md border border-gray-300 bg-white p-4 text-gray-700 shadow-md"
          tabIndex={-1}
          style={spring}
          {...attrs}
        >
          <div tw="flex flex-col items-start">
            <p tw="mb-3 font-medium text-gray-800">Questions or remarks?</p>

            <textarea
              tw="mb-4 block h-full w-full border-none p-2 text-sm text-gray-500 ring-1 ring-black/5"
              defaultValue={props.question.annotation}
              form="intake-form"
              id={annotationId}
              name={annotationId}
              placeholder={'Write your feedback here.'}
              ref={register()}
              rows={7}
            />
            <div tw="flex w-full justify-end gap-2">
              <button
                onClick={(e) => hideAnnotationHandler(e)}
                tw="cursor-pointer rounded-md border border-gray-200 px-4 py-2 text-gray-700"
              >
                <Check weight="bold" size={20} />
              </button>
              <button
                onClick={(e) => hideAnnotationHandler(e)}
                tw="cursor-pointer rounded-md border border-gray-200 bg-gray-50 px-4 py-2 text-gray-700"
              >
                <X weight="bold" size={20} />
              </button>
            </div>
          </div>
        </AnnotationContentWrapper>
      )}
    >
      <AnnotationWrapper>
        <span
          tw="flex cursor-pointer flex-row items-center text-sm text-gray-500 hover:text-gray-400"
          onClick={(e) => showAnnotationHandler(e)}
          onMouseOver={(e) => showAnnotationHandler(e)}
        >
          <ChatCenteredDots
            weight="bold"
            tw="text-lg"
            style={{ marginTop: !props.withText ? 1 : 0 }}
          />
          {props.withText && (
            <span tw="ml-1 hidden md:visible">Add annotation</span>
          )}
        </span>
      </AnnotationWrapper>
    </Tippy>
  );
};

const AnnotationContentWrapper = styled(animated.div)`
  @media (min-width: 500px) {
    min-width: 500px;
  }
`;

export default Annotation;
