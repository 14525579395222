import React, { useState } from 'react';

import tw from 'twin.macro';
import { Modal } from 'components/page/app-page/Modal';
import CreateCampaignForm from './CreateCampaignForm';
import useNavigationContext from 'hooks/context/nav-context';
import ImportCampaignForm from './ImportCampaignForm';
import { Routes } from 'types/routeTypes';
import { useNavigate } from 'react-router-dom';

export type ModalProps = {
  // Modal props
  show: boolean;
  onClose?: () => void;
  setShowForm: (show: boolean) => void;

  // CreateCampaignForm props
  onCreate?: (vacancyId: number) => void;
  onCancel?: () => void;
  buttonText?: string;
};

const CreateCampaignModal: React.FC<ModalProps> = ({
  show,
  onClose,
  setShowForm,
  onCreate,
  buttonText,
}) => {
  const { setActiveVacancy } = useNavigationContext();

  const navigate = useNavigate();

  const [modal, setModal] = useState<'create' | 'import'>('create');

  return (
    <Modal
      show={show}
      onClose={onClose}
      twclass={tw`flex flex-col gap-4 p-12! overflow-y-hidden`}
    >
      <div tw="flex flex-row w-full bg-gray-200/60 p-1 mt-4 box-border rounded-md">
        <TabItem
          onClick={() => setModal('create')}
          selected={modal === 'create'}
        >
          New campaign
        </TabItem>
        <TabItem
          onClick={() => setModal('import')}
          selected={modal === 'import'}
        >
          Import campaign
        </TabItem>
      </div>

      {/* Modal depending on tabs */}
      {modal === 'create' && (
        <CreateCampaignForm
          onCreate={(vacancyId) => {
            setShowForm(false);
            onCreate?.(vacancyId);
          }}
          buttonText={buttonText}
          showTitle={false}
        />
      )}
      {modal === 'import' && (
        <ImportCampaignForm
          onCreate={(vacancyId) => {
            setShowForm(false);
            setActiveVacancy({ vacancyId });
            navigate(Routes.GETTING_STARTED);
          }}
        />
      )}
    </Modal>
  );
};

const TabItem: React.FC<{ onClick: () => void; selected: boolean }> = ({
  children,
  onClick,
  selected,
}) => {
  return (
    <div
      onClick={onClick}
      tw="flex items-center text-center justify-center p-2 py-1 cursor-pointer grow text-gray-600 rounded-md"
      css={[selected && tw`bg-white`]}
    >
      {children}
    </div>
  );
};

export default CreateCampaignModal;
