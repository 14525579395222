import React from 'react';
import tw, { styled } from 'twin.macro';
import { ToastTypes } from 'types/notificationTypes';
import { Check, Warning, Sparkle, PlayCircle } from '@phosphor-icons/react';
import { CustomToast } from 'context/NotificationContext';
import { Title3 } from 'components/typography/Typography';

export const Toast: React.FC<CustomToast> = ({
  type,
  title,
  description,
  cta,
  customIcon,
  customAction,
}) => {
  return (
    <Wrapper>
      <Container onClick={customAction}>
        <div tw="p-4 w-fit">
          <div tw="flex items-start w-fit">
            <IconWrapper type={type}>
              {customIcon ?? <IconType type={type} />}
            </IconWrapper>
            <TextWrapper>
              <Title3 mb={0}>{title}</Title3>
              <Description>{description}</Description>
            </TextWrapper>
          </div>
        </div>
        {cta && (
          <ButtonWrapper>
            <a
              href={cta.route}
              tw="cursor-pointer text-base font-medium text-indigo-700 hover:text-indigo-500"
            >
              {cta.text}
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </ButtonWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${tw`
    inset-0 flex items-end justify-end content-end p-2 pointer-events-none max-w-sm w-full animate-fade-in-up sm:items-end lg:animate-fade-in-right xl:max-w-xl xl:w-fit
`}
`;

const ButtonWrapper = styled.div(tw`w-full h-10 py-2 pl-16 bg-gray-50`);

const TextWrapper = styled.div`
  ${tw`ml-3 w-fit self-center flex flex-col`}
`;

const Description = styled.p(tw`text-base text-gray-500 w-fit`);

const IconWrapper = styled.div<{
  type: ToastTypes;
}>`
  ${tw`shrink-0 place-self-center rounded-full p-1`}
  ${({ type }) => type === 'error' && tw`bg-red-200`}
  ${({ type }) => type === 'warning' && tw`bg-orange-200`}
  ${({ type }) => type === 'success' && tw`bg-green-200`}
  ${({ type }) => type === 'info' && tw`bg-blue-200`}
  ${({ type }) => type === 'action' && tw`bg-gray-200`}
`;

const Container = styled.div`
  ${tw`w-fit bg-white shadow-[0_0_1rem_-4px_rgba(0,0,0,0.4)] rounded-lg pointer-events-auto ring-black/5 overflow-hidden transition cursor-pointer hover:bg-gray-100`}
`;

const IconType: React.FC<{ type: ToastTypes }> = ({ type }) => {
  switch (type) {
    case 'error':
      return <Warning weight="fill" tw="text-red-500 text-xl" />;
    case 'info':
      return <Sparkle weight="fill" tw="text-blue-400 text-xl" />;
    case 'warning':
      return <Warning weight="fill" tw="text-orange-400 text-xl" />;
    case 'success':
      return <Check weight="bold" tw="text-green-400 text-xl" />;
    case 'action':
      return <PlayCircle weight="fill" tw="text-sympl text-xl" />;
    default:
      return <Sparkle weight="fill" tw="text-blue-400 text-xl" />;
  }
};

export default Toast;
