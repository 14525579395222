import { SkeletonItem } from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';
import tw from 'twin.macro';
import HeroSkeleton from '../section-renderer/sections-with-config/hero/HeroSkeleton';

const CareerSkeleton: FC = () => {
  return (
    <div tw="w-full h-screen flex-nowrap flex">
      <div tw="w-[70px] h-full border-r-2 border-r-gray-100 flex flex-col items-center justify-start py-2">
        <SkeletonItem width="50px" height="50px" twStyle={tw`mb-2`} />
        <SkeletonItem width="50px" height="50px" twStyle={tw`mb-2`} />
        <SkeletonItem width="50px" height="50px" twStyle={tw`mb-2`} />
      </div>
      <div tw="w-full">
        <HeroSkeleton />
      </div>
    </div>
  );
};

export default CareerSkeleton;
