import React, { useContext, useMemo, useState } from 'react';
import {
  CaretDown,
  CaretUp,
  LockKey,
  Star,
  DotsSixVertical,
} from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';

import Badge from 'components/badge/Badge';
import QuestionItemForm from '../question-item-form/QuestionItemForm';
import { FormQuestion } from '../form-questions-list/FormQuestionsList';
import { SurveyContext } from 'context/SurveyContext';
import ToolTip from 'components/tooltip/ToolTip';

export interface FormQuestionItemProps {
  question: FormQuestion;
}

export const FormQuestionItem: React.FC<FormQuestionItemProps> = ({
  question,
}) => {
  const { currentOpenQuestion, questionClicked, cancelEditQuestion } =
    useContext(SurveyContext);
  const [hasFocus, setHasFocus] = useState(false);

  const isOpen = useMemo(
    () => question.ref === currentOpenQuestion,
    [currentOpenQuestion, question.ref]
  );

  const isLocked = [
    'first_name',
    'email',
    'mobile_number',
    'zipcode',
    'lastname',
    'sympl_privacy',
  ].includes(question?.ref);

  const clickHandler = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    if (!isOpen) openContentHandler();
    if (isOpen) questionClicked(undefined);
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') openContentHandler();
  };

  const openContentHandler = () => {
    if (isLocked) return;
    questionClicked(question.ref);
  };

  return (
    <>
      {!isOpen && (
        <Wrapper
          tabIndex={0}
          hasFocus={hasFocus}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          onKeyDown={(e) => keyDownHandler(e)}
          onClick={(e) => clickHandler(e)}
          isLocked={isLocked}
        >
          <QuestionWrapper>
            <div>
              <DotsSixVertical
                weight="bold"
                size="20"
                tw="text-gray-500 mr-2 cursor-move"
              />
            </div>
            <QuestionLabel>
              <span>{question?.label}</span>
              {question.custom && (
                <ToolTip text={'Custom Question'} placement="top" arrow>
                  <span tw="ml-2">
                    <Star weight="fill" tw="text-amber-300" size={24} />
                  </span>
                </ToolTip>
              )}
            </QuestionLabel>
          </QuestionWrapper>

          <ToggleWrapper>
            {!question?.required && <Badge label={'Optional'} color="gray" />}
            {isLocked ? (
              <LockKey weight="fill" size="18" tw="text-gray-700" />
            ) : (
              <CaretDown size="18" tw="text-gray-700" />
            )}
          </ToggleWrapper>
        </Wrapper>
      )}

      {isOpen && (
        <div tw="w-full px-6 py-5 bg-white rounded-md mt-1 mb-4 shadow transition-all">
          <ToggleWrapper>
            <CaretUp
              weight="bold"
              size="18"
              tw="text-gray-700 cursor-pointer"
              onClick={() => cancelEditQuestion?.()}
            />
          </ToggleWrapper>
          <QuestionItemForm question={question} />
        </div>
      )}
    </>
  );
};

const Wrapper = styled.li<{
  hasFocus: boolean;
  isLocked: boolean;
}>`
  ${tw`
    flex justify-between items-center outline-none bg-white rounded-md shadow mb-1 p-4 cursor-pointer
  `}

  ${({ hasFocus, isLocked }) => hasFocus && isLocked && tw`border-indigo-500`}

  ${({ isLocked }) => isLocked && tw`cursor-not-allowed`}
`;

const QuestionWrapper = styled.div(tw`flex justify-end items-center`);
const QuestionLabel = styled.p(
  tw`text-gray-700 text-sm md:text-base flex items-center justify-start`
);

const ToggleWrapper = styled.div(tw`flex justify-end`);

export default FormQuestionItem;
