import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';

interface CardsSkeletonProps {}

const CardsSkeleton: FC<CardsSkeletonProps> = () => {
  return <SkeletonList rows={2} />;
};

export default CardsSkeleton;
