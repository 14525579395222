import React from 'react';

import LogoFacebook from './socials/LogoFacebook';
import LogoInstagram from './socials/LogoInstagram';
import LogoLinkedin from './socials/LogoLinkedin';
import LogoTiktok from './socials/LogoTiktok';
import LogoYoutube from './socials/LogoYoutube';
import LogoTwitter from './socials/LogoTwitter';
import LogoWhatsapp from './socials/LogoWhatsapp';
import LogoGoogle from './socials/LogoGoogle';
import LogoIndeed from './socials/LogoIndeed';
import LogoMicrosoft from './socials/LogoMicrosoft';
import { AdChannel } from 'types/adTypes';

export interface LogoIconProps {
  width?: string;
  height?: string;
  color?: string;
  grayScale?: boolean;
  useNaturalColor?: boolean;
}

interface SocialIconProps extends LogoIconProps {
  company: string;
  grayScale?: boolean;
  useNaturalColor?: boolean;
}

const FallbackIcon: React.FC<LogoIconProps> = ({
  width = '16',
  height = '16',
  color = 'currentColor',
  grayScale,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 256 256"
    fill={color}
    filter={grayScale ? 'grayscale(1)' : 'none'}
  >
    <path d="M241,150.65s0,0,0-.05a51.33,51.33,0,0,0-2.53-5.9L196.93,50.18a12,12,0,0,0-2.5-3.65,36,36,0,0,0-50.92,0A12,12,0,0,0,140,55V76H116V55a12,12,0,0,0-3.51-8.48,36,36,0,0,0-50.92,0,12,12,0,0,0-2.5,3.65L17.53,144.7A51.33,51.33,0,0,0,15,150.6s0,0,0,.05A52,52,0,1,0,116,168V100h24v68a52,52,0,1,0,101-17.35ZM80,62.28a12,12,0,0,1,12-1.22v63.15a51.9,51.9,0,0,0-35.9-7.62ZM64,196a28,28,0,1,1,28-28A28,28,0,0,1,64,196ZM164,61.06a12.06,12.06,0,0,1,12,1.22l23.87,54.31a51.9,51.9,0,0,0-35.9,7.62ZM192,196a28,28,0,1,1,28-28A28,28,0,0,1,192,196Z"></path>
  </svg>
);

const SocialIcon: React.FC<SocialIconProps> = ({
  company,
  width,
  height,
  color,
  grayScale = false,
  useNaturalColor,
}) => {
  switch (company.toLowerCase()) {
    case AdChannel.FACEBOOK:
    case 'facebook':
      return (
        <LogoFacebook
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case AdChannel.INSTAGRAM:
    case 'instagram':
      return (
        <LogoInstagram
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case AdChannel.LINKEDIN:
    case 'linkedin':
      return (
        <LogoLinkedin
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case AdChannel.TIKTOK:
    case 'tiktok':
      return (
        <LogoTiktok
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case 'youtube':
      return (
        <LogoYoutube
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case 'twitter':
      return (
        <LogoTwitter
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case 'whatsapp':
      return (
        <LogoWhatsapp
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case 'google':
      return (
        <LogoGoogle
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case 'indeed':
      return (
        <LogoIndeed
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    case 'microsoft':
      return (
        <LogoMicrosoft
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
          useNaturalColor={useNaturalColor}
        />
      );
    default:
      return (
        <FallbackIcon
          width={width}
          height={height}
          color={color}
          grayScale={grayScale}
        />
      );
  }
};

export default SocialIcon;
