import tw, { styled } from 'twin.macro';
import Button from 'components/button/Button';
import TextEditor from 'components/form/text-editor/TextEditor';
import TextEditorDropdown from 'components/form/text-editor/TextEditorDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  EnvelopeSimple,
  PaperclipHorizontal,
  StopCircle,
  VideoCamera,
  X,
} from '@phosphor-icons/react';

import { useQuery } from 'hooks/sympl-query';
import { Communication } from 'views/candidates/CandidateCommunication';
import { MailTemplate } from 'types/mailTypes';
import { GET_MAIL_TEMPLATES } from 'graphql/candidates/queries';
import MailSubject from './MailSubject';
import { Candidate } from 'types/candidates/types';
import useNavigationContext from 'hooks/context/nav-context';
import { getLocalAppSession } from 'utils/storageHelpers';
import { getLanguageCode } from 'utils/i18nHelper';
import { Lead } from 'types/leads/types';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import {
  checkMaxFileSize,
  getBackgroundColourByFileType,
} from 'utils/fileHelpers';
import ToolTip from 'components/tooltip/ToolTip';
import { Error } from 'components/typography/Typography';

const SendMailForm: React.FC<{
  variant?: 'normal' | 'reply';
  communication?: Communication;
  procedureId: number;
  subjectId: string;
  bodyId: string;
  onSend: () => void;
  onSendLoading: boolean;
  skippable?: boolean;
  onSkip?: () => void;
  candidate?: Candidate;
  lead?: Lead;
  inside?: boolean;
  hasFocus?: boolean;
  enableAttachments?: boolean;
  onClose?: () => void;
  messageHistory?: string;
}> = ({
  // variant,
  communication,
  procedureId,
  subjectId,
  bodyId,
  onSend,
  onSendLoading,
  skippable = false,
  onSkip,
  candidate,
  lead,
  inside = false,
  hasFocus,
  enableAttachments = false,
  onClose,
  messageHistory = '',
}) => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { isDirty, dirtyFields },
  } = useFormContext();

  const { addToast } = useToastNotifications();
  const { currentVacancy, jobPosition } = useNavigationContext();
  const language = getLanguageCode(currentVacancy?.language_id ?? 141);

  const session = getLocalAppSession();

  const [mailBody, setMailBody] = useState<string>('');

  const subjectWatch = watch(subjectId);
  const bodyWatch = watch(bodyId);
  const attachmentsWatch = watch('attachments');
  const attachments = useMemo<File[]>(() => {
    return attachmentsWatch ? Array.from(attachmentsWatch) : [];
  }, [attachmentsWatch]);

  const MAX_FILE_SIZE = 20;

  const handleTemplateChange = (template: MailTemplate) => {
    setMailBody(template.body);
    setValue(bodyId, template.body);
    if (!mailSubject) setValue(subjectId, template.subject);
  };

  const getSubject = (subject: string) => {
    if (subject.toLowerCase().includes('re: ')) return subject;
    return `${subject ? `Re: ${subject}` : ''}`;
  };

  const [mailSubject, setMailSubject] = useState<string>(
    getSubject(communication?.subject ?? '')
  );

  const { data: mailsData, loading: loadingTemplates } = useQuery<
    {
      templates: MailTemplate[];
    },
    { procedureId: number }
  >(GET_MAIL_TEMPLATES, {
    variables: { procedureId: procedureId },
  });

  useEffect(() => {
    setMailSubject(getValues()[subjectId]);
  }, [getValues, subjectId]);

  const cleanText = (text: string): string => {
    return text
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&nbsp;/gi, '')
      .trim();
  };

  const mailError = useMemo(() => {
    if (!isDirty) return false;
    return (
      (dirtyFields['candidate-free-body'] &&
        (!bodyWatch || cleanText(bodyWatch).length === 0)) ||
      (dirtyFields['candidate-free-subject'] &&
        (!subjectWatch || cleanText(subjectWatch).length === 0))
    );
  }, [isDirty, watch, subjectId, bodyId]);

  const handleFiles = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const input = e.target;
    const files = input.files as FileList;

    if (checkMaxFileSize(files, MAX_FILE_SIZE)) {
      input.value = '';

      addToast({
        type: ToastTypes.ERROR,
        description: `Your attachments cannot exceed ${MAX_FILE_SIZE}MB.`,
      });
    }
  };

  const getName = (): string => {
    if (candidate) return candidate?.name as string;
    else if (lead) return `${lead?.firstname} ${lead?.lastname}`;
    return '';
  };

  const handleFileUpload = (files: FileList): File[] => {
    if (!attachments) return Array.from(files);

    const filteredFiles = Array.from(files).filter(
      (file) =>
        !attachments.find(
          (attachment: File) =>
            attachment.name === file.name &&
            attachment.size === file.size &&
            attachment.type === file.type
        )
    );
    return [...attachments, ...filteredFiles];
  };

  const handleFileRemove = (index: number) => {
    const newAttachments = attachments?.filter((_, i) => i !== index);
    setValue('attachments', newAttachments);
  };

  return (
    <div
      tabIndex={-1}
      tw="flex flex-col justify-end outline-none w-full h-full relative"
      style={inside ? { paddingTop: '0px' } : {}}
    >
      {inside && attachments && attachments?.length > 0 && (
        <div tw="flex items-center border-[#e5e7eb] border-b-0 border-[1px] rounded-t-md">
          <PaperclipHorizontal
            weight="regular"
            tw="rotate-90 cursor-pointer ml-2"
            size={20}
          />
          <div tw="flex gap-2 m-2 flex-wrap w-full">
            {attachments?.map((attachment: File, index: number) => (
              <div
                key={index}
                tw="px-2 py-1 border-gray-800/10 border-2 rounded-md flex gap-1 items-center"
                style={{
                  backgroundColor: getBackgroundColourByFileType(attachment),
                }}
              >
                <ToolTip text={attachment.name} placement="top" arrow>
                  <p
                    tw="max-w-[30ch] text-sm truncate"
                    dangerouslySetInnerHTML={{ __html: attachment.name }}
                  ></p>
                </ToolTip>
                <X
                  type="bold"
                  size={20}
                  tw="cursor-pointer mt-px"
                  onClick={() => handleFileRemove(index)}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {!inside && (
        <p tw="mb-5 md:mb-6 text-gray-700 font-medium text-lg">Send mail</p>
      )}
      <form
        tabIndex={-1}
        tw="outline-none relative"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSend);
        }}
      >
        {!inside ? (
          <div tw="mb-4 relative">
            <MailSubject
              id={subjectId}
              defaultValue={mailSubject}
              inside={inside}
            />
          </div>
        ) : (
          <MailSubjectWrapper attachments={!!attachments.length}>
            <MailSubject
              id={subjectId}
              defaultValue={mailSubject}
              inside={inside}
            />
            {/* DEVNOTE: below is the code for cc | BCC showing up in the textEditor */}
            {/* {inside && <p tw="text-gray-400 text-xs pr-2 shrink-0">CC | BCC</p>} */}
            <div tw="h-10 pr-2 flex justify-between items-center pl-2 absolute top-0 right-0 cursor-pointer">
              <X size={20} onClick={onClose} />
            </div>
          </MailSubjectWrapper>
        )}
        {mailError && (
          <MailError inside={inside}>
            <Error>Please fill in both your subject and body text.</Error>
          </MailError>
        )}
        <div tw="relative">
          <Controller
            id={bodyId}
            name={bodyId}
            control={control}
            rules={{ required: true }}
            render={({ onChange }) => (
              <TextEditor
                identifier="send_mail_form"
                hasFocus={hasFocus}
                key={mailBody}
                defaultValue={mailBody}
                rows={inside ? 8 : 20}
                onChange={onChange}
                insideControls={inside}
                handleSend={handleSubmit(onSend)}
                aiEnabled={true}
                initialContext={[
                  {
                    role: 'assistant',
                    content: `You generate an email to a job candidate,
                      only the email body is needed, do not include a subject.
                      Write the email in the language corresponding to the language
                      code ${language}, unless asked otherwise. Always end the email with an email
                      signature mentioning the current user, their function and
                      the company name. The candidate is called: ${getName()}.
                      The candidate applied for the job: ${
                        currentVacancy?.title
                      }.
                      The company is called: ${currentVacancy?.company}.
                      The person sending the email is called: ${
                        session.firstName
                      } ${
                      session.lastName
                    } and has the job position of ${jobPosition}.`,
                  },
                  ...(messageHistory.length > 25 // If there is message history, use it for context and writing style
                    ? [
                        {
                          role: 'assistant' as const,
                          content: `Use the following message history as context. Write in the same language and writing style as the sender: ${messageHistory}`,
                        },
                      ]
                    : []),
                ]}
                aiSuggestedActions={[
                  {
                    icon: <StopCircle weight="fill" tw="text-indigo-500" />,
                    label: 'End procedure because of ...',
                    prompt: 'End procedure because of ',
                  },
                  {
                    icon: <VideoCamera weight="fill" tw="text-indigo-500" />,
                    label: 'Invite for videocall',
                    prompt: 'Invite candidate for videocall.',
                    instantPrompt: true,
                  },
                ]}
                customControls={[
                  <div
                    id="custom-control"
                    tw="mb-1 max-w-[12rem] md:max-w-[calc(100%/4)] flex gap-4 z-50 ml-[55px] max-h-8"
                  >
                    {/* TODO: attachment handler */}
                    {enableAttachments && (
                      <Controller
                        name="attachments"
                        control={control}
                        visible={enableAttachments}
                        render={({ onChange }) => (
                          <div tw="relative" className="rdw-option-wrapper">
                            <label htmlFor="email-attachments">
                              <ToolTip
                                text="Your attachments may not be larger than 20MB in total."
                                placement="top"
                                arrow
                              >
                                <PaperclipHorizontal
                                  weight="regular"
                                  tw="rotate-90 cursor-pointer"
                                  size={16}
                                />
                              </ToolTip>
                              {attachments.length > 0 && (
                                <p tw="absolute -top-1 right-0 font-bold text-indigo-500">
                                  {attachments?.length}
                                </p>
                              )}
                            </label>
                            <input
                              type="file"
                              id="email-attachments"
                              hidden
                              multiple
                              onChange={(e) => {
                                if (!e.target.files) return;
                                handleFiles(e);
                                onChange(handleFileUpload(e.target.files));
                              }}
                            />
                          </div>
                        )}
                      />
                    )}

                    <TextEditorDropdown
                      customStyle={tw`pl-2 min-w-[10rem] max-md:hidden`}
                      loadingTemplates={loadingTemplates}
                      templates={mailsData?.templates}
                      onTemplateChange={(template) => {
                        handleTemplateChange(template);
                      }}
                      insideControls={inside}
                    />
                  </div>,
                ]}
              />
            )}
          />
        </div>

        {!inside && (
          <div tw="mt-4 float-right flex gap-4">
            {skippable && (
              <Button variant="link" onClick={onSkip}>
                Don't send an email
              </Button>
            )}
            <Button
              onClick={handleSubmit(onSend)}
              loading={onSendLoading}
              disabled={onSendLoading}
              icon={<EnvelopeSimple size="16" />}
            >
              Send mail
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

const MailSubjectWrapper = styled.div<{ attachments: boolean }>`
  ${tw`h-10 border-[#e5e7eb] border-b-0 border-[1px] flex justify-between items-center relative pl-2`}
  ${({ attachments }) => !attachments && tw`rounded-t-md`}
`;

const MailError = styled.div<{ inside: boolean }>`
  ${tw`flex justify-start w-fit mb-2 px-2 absolute z-50 text-sm rounded-sm h-fit`}
  ${({ inside }) => (inside ? tw`top-[40px] right-2` : tw`bottom-0 left-0`)}
`;

export default SendMailForm;
