import React from 'react';

import ClipLoader from 'react-spinners/ClipLoader';

interface IProps {
  color?: string;
}

const Spinner = (props: IProps) => {
  return (
    <ClipLoader size={20} color={props.color ?? '#5850ec'} loading={true} />
  );
};

export default Spinner;
