import { EditorState } from 'draft-js';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { getEditorValuesFromEditorState } from 'utils/baseHelpers';

import { isEditorState } from 'utils/typeHelpers';
import { getIntakeResults } from 'utils/formHelpers';
import { NotificationTypes } from 'types/baseTypes';
import { IntakeFormResults } from 'types/formTypes';
import Button from 'components/button/Button';

import 'twin.macro';
import useIntakeCoreContext from 'hooks/context/intakes-context';
import useIntakeStepContext from 'hooks/context/intake-step-context';
import FinishButton from 'components/other/FinishButton';

const IntakeControls: React.FC<{
  isFirstStep: boolean;
  isLastStep: boolean;
  onChange: () => void;
}> = ({ isLastStep, isFirstStep, onChange }) => {
  const { trigger, getValues, setError, watch } = useFormContext();
  const [canSkip, setCanSkip] = useState(false);

  const {
    intakeConfig,
    currentStepId,
    currentSectionId,
    currentIntakeId,
    currentActiveSection,
    currentActiveIntake,
    skippedSteps,
    submitIntakeStep,
    filesBeingUploaded,
    hasFinished: hasFinishedIntake,
    handleSetStepId,
    handleSetSectionId,
    handleSetSkippedSteps,
    handleStepCompleted,
    handleAddNotification,
    loading: submittingPreviousResult,
  } = useIntakeCoreContext();

  const { questions: intakeQuestions, missingIntakeQuestions } =
    useIntakeStepContext();

  const formData = watch();
  const intakeFormResults = useMemo(
    () =>
      getIntakeResults(
        formData as FormData,
        {
          currentIntakeId,
          currentSectionId,
          currentStepId,
        },
        intakeQuestions
      ),
    [
      currentIntakeId,
      currentSectionId,
      currentStepId,
      formData,
      intakeQuestions,
    ]
  );

  // get indices
  const currentSectionIndex = useMemo(() => {
    return (
      currentActiveIntake?.sections.findIndex(
        (id) => id === currentSectionId
      ) ?? -1
    );
  }, [currentActiveIntake?.sections, currentSectionId]);
  const currentStepIndex = useMemo(() => {
    return (
      currentActiveSection?.steps.findIndex((id) => id === currentStepId) ?? -1
    );
  }, [currentActiveSection?.steps, currentStepId]);

  // currentSections
  const intakeSections = useMemo(() => {
    if (!currentActiveIntake?.sections) return null;
    return currentActiveIntake.sections;
  }, [currentActiveIntake?.sections]);

  const isEditorValid = (editorState: EditorState) => {
    const editorValues = getEditorValuesFromEditorState(editorState);
    return editorValues && editorValues?.length > 0 && editorValues?.[0] !== '';
  };

  const getEditorStatesFromFormData = (data: { [x: string]: any }) => {
    return Object.entries(data).filter((entry) =>
      isEditorState(entry[1])
    ) as unknown as [string, EditorState][];
  };

  const validateTextEditors = (textEditors: [string, EditorState][]) => {
    let hasErrors = false;

    try {
      textEditors.forEach((editor) => {
        const editorQuestion = intakeQuestions.find(
          (q) => q.id === parseInt(editor[0])
        );

        if (
          !isEditorValid(editor[1]) &&
          editorQuestion &&
          editorQuestion.required
        ) {
          setError(editor[0], {
            message: editorQuestion.rules?.pattern?.message,
          });
          hasErrors = true;
        }
      });
    } catch (_error) {
      hasErrors = true;
    }

    return hasErrors;
  };

  const validateForm = async () => {
    const formValues = getValues();

    // Validate form before routing
    const formHasErrors = !(await trigger());

    // Validate custom text editors manually
    const editorHasErrors = validateTextEditors(
      getEditorStatesFromFormData(formValues)
    );

    return !formHasErrors && !editorHasErrors;
  };

  const handleMovement = useCallback(
    (movement: 'previous' | 'next') => {
      if (
        (currentActiveSection?.steps &&
          currentActiveSection.steps.length < 1) ||
        (currentActiveIntake?.sections &&
          currentActiveIntake.sections.length < 1)
      )
        return;

      const intakesSectionList = currentActiveIntake?.sections ?? [];
      const sectionStepsList = currentActiveSection?.steps ?? [];
      let hasNextSection = false;
      let hasPrevSection = false;
      let isFirstStepOfSection = false;
      let isLastStepOfSection = false;

      // check if currentStep is the 1st step
      if (currentActiveSection?.steps[0] === currentStepId) {
        isFirstStepOfSection = true;
      }
      // check if currentStep is last step of section
      if (sectionStepsList[sectionStepsList.length - 1] === currentStepId) {
        isLastStepOfSection = true;
      }
      // check if currentStep has next section
      if (currentActiveIntake?.sections[currentSectionIndex + 1]) {
        hasNextSection = true;
      }
      // check if currentStep has prev section
      if (currentActiveIntake?.sections[currentSectionIndex - 1]) {
        hasPrevSection = true;
      }

      if (movement === 'next') {
        // set next section Id and next 1st step id
        if (isLastStepOfSection && hasNextSection) {
          const nextSectionId = intakesSectionList[currentSectionIndex + 1];
          const nextSectionFirstStepId =
            intakeConfig?.sections[nextSectionId].steps[0] ?? 0;
          setCanSkip(false);
          handleSetSectionId(nextSectionId);
          handleSetStepId(nextSectionFirstStepId);
          onChange();
        }

        if (!isLastStepOfSection) {
          const nextStepId = sectionStepsList[currentStepIndex + 1];
          setCanSkip(false);
          handleSetStepId(nextStepId);
          onChange();
        }
      } else {
        // handle previous
        if (isFirstStepOfSection && hasPrevSection && intakeConfig?.sections) {
          const prevSectionId = intakesSectionList[currentSectionIndex - 1];
          const prevStepsInPrevSection =
            intakeConfig.sections[prevSectionId].steps;
          const prevSectionLastStepId =
            prevStepsInPrevSection[prevStepsInPrevSection.length - 1];

          handleSetSectionId(prevSectionId);
          handleSetStepId(prevSectionLastStepId);
        }

        if (!isFirstStepOfSection) {
          const prevStepId = sectionStepsList[currentStepIndex - 1];
          handleSetStepId(prevStepId);
        }
      }
    },
    [intakeConfig, currentActiveSection, currentStepId, currentStepIndex]
  );

  const backHandler = () => {
    if (
      !intakeConfig ||
      submittingPreviousResult ||
      !currentActiveSection ||
      !currentActiveSection.steps ||
      !currentActiveIntake
    )
      return;

    saveIntake(true);
    handleMovement('previous');
    onChange();
  };

  const nextHandler = (skipValidation: boolean = false) => {
    // Disable next button if there are files being uploaded
    if (filesBeingUploaded.length > 0) return;

    validateForm().then((isValid) => {
      const formHasErrors = !isValid;
      // Allow user to skip step
      setCanSkip(true);
      if (!skipValidation && formHasErrors) return;
      if (
        !currentActiveSection ||
        !currentActiveSection.steps ||
        !intakeSections
      )
        return;

      saveIntake(skipValidation);

      // Set step as completed - NOT SURE
      if (!skipValidation) handleStepCompleted(currentStepId);

      handleMovement('next');
    });
  };

  const saveIntake = (skipValidation: boolean) => {
    // Add step id to skipped steps
    if (skipValidation && !skippedSteps.includes(currentStepId))
      handleSetSkippedSteps(currentStepId);

    // Submit the intake form
    if (intakeFormResults) submitIntake(intakeFormResults);
  };

  const submitIntake = (data: IntakeFormResults) => {
    // Submit the intake form with the current form state
    submitIntakeStep(data).catch(() => {
      handleAddNotification({
        type: NotificationTypes.ERROR,
        message:
          'Something went wrong while submitting previous step, please try again.',
      });
    });
  };

  return (
    <div tw="flex flex-1 items-center justify-end gap-2">
      {canSkip && (
        <Button variant="link" onClick={() => nextHandler(true)}>
          Fill in later
        </Button>
      )}
      {!isFirstStep && (
        <Button variant="secondary" onClick={backHandler}>
          Back
        </Button>
      )}
      {!isLastStep && (
        <Button
          loading={submittingPreviousResult || filesBeingUploaded.length > 0}
          onClick={() => nextHandler()}
        >
          Next
        </Button>
      )}
      {!hasFinishedIntake && missingIntakeQuestions.length === 0 && (
        <FinishButton intakeFormResults={intakeFormResults} />
      )}
    </div>
  );
};

export default IntakeControls;
