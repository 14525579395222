import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import tw, { theme, styled, css } from 'twin.macro';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { convertToRaw, Modifier, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { emojis } from 'utils/emojiHelpers';
import {
  createStateFromDefaultValue,
  createStateFromHtml,
} from 'utils/templateHelpers';
import draftToHtml from 'draftjs-to-html';
import { removeStyling } from 'utils/baseHelpers';
import TextEditorLinkPopup from './TextEditorLinkPopup';
import { ChatGPTMessage } from 'utils/openAiHelpers';
import TextEditorAIPlugin, { AIAction } from './TextEditorAIPlugin';
import { VacancyCopyKeyname } from 'types/vacancyTypes';

// TODO: this needs to be cleaned up/slimmed down
export type TextEditorIdentifier =
  | VacancyCopyKeyname
  | 'ad_builder'
  | 'template_editor'
  | 'intake_text_editor'
  | 'text_editor'
  | 'procedure_text'
  | 'vacancy_copy'
  | 'text_field'
  | 'feedback_widget'
  | 'send_mail_form'
  | 'candidate_notes';

export interface TextEditorProps {
  editorWrapperRef?: React.RefObject<HTMLDivElement>;
  placeholder?: string;
  defaultValue: string;
  emojiIsEnabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  insideControls?: boolean;
  handleSend?: () => void;
  rows?: number;
  externalState?: EditorState;
  setExternalState?: (value: EditorState) => void;
  autoSuggest?: React.ReactElement;
  readOnly?: boolean;
  disabled?: boolean;
  customControls?: JSX.Element[];
  aiEnabled?: boolean;
  aiSuggestedActions?: AIAction[];
  initialPrompt?: ChatGPTMessage[];
  initialContext?: ChatGPTMessage[];
  identifier: TextEditorIdentifier;
  hasFocus?: boolean;
  onAIGenFinished?: (
    finalContents: string,
    thread: string
  ) => void | string | Promise<void | string>;
}

const TextEditor: React.FC<TextEditorProps> = ({
  editorWrapperRef,
  placeholder,
  defaultValue,
  emojiIsEnabled = true,
  onChange,
  onBlur,
  insideControls = false,
  handleSend,
  rows,
  externalState,
  setExternalState,
  autoSuggest,
  readOnly = false,
  disabled = false,
  customControls = [],
  aiEnabled = false,
  aiSuggestedActions = [],
  initialPrompt,
  initialContext = [],
  identifier,
  hasFocus,
  onAIGenFinished,
}) => {
  const [internalState, setInternalState] = useState(
    defaultValue
      ? createStateFromDefaultValue(defaultValue)
      : EditorState.createEmpty()
  );

  const editorState = externalState ?? internalState;
  const setEditorState = setExternalState ?? setInternalState;
  const editorRef = React.useRef(null);

  useEffect(() => {
    if (editorRef.current && hasFocus) {
      if (editorRef.current) {
        const newEditorState = EditorState.moveFocusToEnd(editorState);
        setEditorState(newEditorState);
      }
    }
  }, [hasFocus]);

  const toolbar = useMemo(
    () => ({
      options: [
        'inline',
        'list',
        ...(!insideControls ? ['link'] : []),
        ...(emojiIsEnabled ? ['emoji'] : []),
      ],
      inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline'],
      },
      list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered'],
      },
      link: {
        inDropdown: false,
        className: undefined,
        component: TextEditorLinkPopup,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        linkCallback: undefined,
      },
      emoji: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emojiIsEnabled, insideControls, editorState]
  );

  const handleEditorChange = useCallback(
    (state: EditorState) => setEditorState?.(state),
    [setEditorState]
  );

  const changeHandler = () => {
    const editorHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    onChange?.(editorHtml);
  };

  const minEditorHeight = useMemo(() => (rows ?? 10) * 16, [rows]);

  const handlePaste = (
    _text: string,
    html: string,
    editorState: EditorState,
    onChange: (editorState: EditorState) => void
  ) => {
    // TODO: needs to be revised
    if (html && html.includes('<img data-emoji=')) {
      let tempHtml = html;
      const imgReg = new RegExp('<img.*?data-emoji="(.*?)">', 'gm');
      const emojis = Array.from(html.matchAll(imgReg));

      emojis.forEach((emojiImage) => {
        const imageTag = emojiImage[0];
        const e = new DOMParser().parseFromString(emojiImage[0], 'text/html');
        const image = e.body.children[0];
        const emojiValue = image.getAttribute('data-emoji');
        tempHtml = tempHtml.replace(imageTag, emojiValue!);
      });

      onChange?.(createStateFromHtml(tempHtml));
      return true;
    }

    if (html) {
      // Insert the copied content at the current cursor position
      // Note: we use the Modifier.replaceWithFragment instead of Modifier.replaceText to preserve HTML tags (e.g. ul, li, etc.)
      const contentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        createStateFromDefaultValue(removeStyling(html))
          .getCurrentContent()
          .getBlockMap()
      );

      // Merge editor and content state
      const newEditorState = EditorState.push(
        editorState,
        contentState,
        'insert-characters'
      );

      // Set the new editor state
      setEditorState(newEditorState);

      // Move the cursor to the end of the inserted content
      EditorState.forceSelection(
        newEditorState,
        contentState.getSelectionAfter()
      );

      return true;
    }

    return false;
  };

  return (
    <Wrapper
      ref={editorWrapperRef}
      minHeight={minEditorHeight}
      disabled={disabled}
      aiEnabled={aiEnabled}
      insideControls={insideControls}
      translate="no"
    >
      {autoSuggest}
      <Editor
        ref={editorRef}
        toolbar={toolbar}
        editorState={editorState}
        placeholder={placeholder}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbarCustomButtons={customControls}
        handlePastedText={handlePaste}
        onChange={changeHandler}
        onBlur={onBlur}
        readOnly={readOnly || disabled}
        spellCheck={true}
      />

      {/* Show button only when AI is enabled AND
        there is an initial prompt or an active thread or has text */}
      {aiEnabled && (
        <TextEditorAIPlugin
          identifier={identifier}
          initialPrompt={initialPrompt}
          initialContext={initialContext}
          aiSuggestedActions={aiSuggestedActions}
          editorState={editorState}
          setEditorState={setEditorState}
          onChange={onChange}
          onFinished={onAIGenFinished}
          insideControls={insideControls}
        />
      )}

      {insideControls && (
        <button
          tw="absolute bottom-0 right-0 flex justify-center items-center mb-1 p-2 md:p-4 mx-2 md:h-[47px] text-sm font-semibold text-gray-600 cursor-pointer"
          onClick={() => {
            if (!editorState.getCurrentContent().hasText()) return;
            handleSend!();
            setEditorState(EditorState.createEmpty());
          }}
        >
          Send
        </button>

        // DEVNOTE: this is the original code that detects the OS and shows the shortcut
        // <div tw="absolute bottom-0 right-0 flex justify-center items-center gap-1 h-[2.75rem] pb-1 text-sm text-gray-400 opacity-[0.5]">
        //   Send with{' '}
        //   <span tw="text-gray-600">
        //     {/(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent)
        //       ? 'Cmd'
        //       : 'Ctrl'}{' '}
        //     + Enter
        //   </span>
        // </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  minHeight: number;
  disabled: boolean;
  aiEnabled: boolean;
  insideControls?: boolean;
}>`
  ${tw`flex flex-col relative max-w-full`}

  ${({ minHeight, disabled, insideControls }) => css`
    .wrapper-class {
      border: 1px solid ${theme`colors.gray.200`};
      border-radius: ${insideControls ? '0px 0px 6px 6px' : '6px'};
      background-color: white;
      display: flex;
      flex-direction: ${insideControls ? 'column-reverse' : 'column'};

      & focus {
        outline: none;
        border: none;
      }
    }

    .editor-class {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 0.2rem 1rem;
      min-height: ${minHeight}px;
      max-height: 200px;
      background-color: ${disabled
        ? theme`colors.gray.50`
        : theme`colors.white`};
      color: ${disabled ? theme`colors.gray.400` : theme`colors.black`};
      overflow-y: auto;
      overflow-x: auto;
      scrollbar-width: none;
      border-top: ${insideControls
        ? ''
        : `1px solid ${theme`colors.gray.200`}`};
      box-sizing: border-box !important;
    }

    .editor-class::-webkit-scrollbar {
      width: none;
      ms-overflow-style: none;
    }

    .toolbar-class {
      max-width: ${insideControls ? '70%' : 'min(60%, 500px)'};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border: none;
      align-items: center;
      background-color: ${disabled
        ? theme`colors.gray.50`
        : theme`colors.white`};

      & > div:not(#custom-control) {
        margin-bottom: 0;
        height: 100%;
        max-height: 40px;
      }

      & focus {
        outline: none;
        border: none;
      }
    }

    @media (1280px <= width < 1536px) {
      .toolbar-class {
        max-width: ${insideControls ? '460px' : 'min(60%, 500px)'};
      }
    }

    @media only screen and (max-width: 768px) {
      .rdw-inline-wrapper,
      .rdw-list-wrapper,
      .rdw-link-wrapper,
      .rdw-emoji-wrapper {
        display: none;
      }
    }

    .rdw-editor-toolbar {
      box-sizing: border-box !important;
      margin-bottom: 0;
      padding: 6px 0;
      padding-left: 6px;
    }

    .rdw-inline-wrapper,
    .rdw-list-wrapper,
    .rdw-link-wrapper,
    .rdw-emoji-wrapper,
    .rdw-editor-toolbar > div {
      min-height: 28px;
      display: contents;
    }

    .rdw-option-wrapper {
      border: none;
      padding: 7px;
      min-width: 26px;
      min-height: 30px;
      border-radius: 2px;
      margin: 0 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box !important;
      background-color: ${disabled
        ? theme`colors.gray.50`
        : theme`colors.white`};
      text-transform: capitalize;
      &:hover,
      &:active {
        box-shadow: none;
        background-color: ${theme`colors.gray.100`};
      }

      & > img {
        filter: ${disabled ? 'invert(0.7)' : 'invert(0)'};
      }
    }

    .rdw-option-active {
      box-shadow: none;
      background-color: ${theme`colors.gray.200`};
    }

    .rdw-option-disabled {
      opacity: 0.3;
      cursor: default;
    }
  `}
`;

export default TextEditor;
