import React, { FC } from 'react';
import 'twin.macro';
import useCareerPageContext from 'hooks/context/career-page-context';

import CustomerDropdown from './CustomerDropdown';
import SaveButton from 'components/save-button/SaveButton';
import PageStatusDropDown from './PageStatusDropDown';
import { FormProvider, useForm } from 'react-hook-form';
import { ClockCounterClockwise } from '@phosphor-icons/react';
import ToolTip from 'components/tooltip/ToolTip';

const CareerCta: FC = () => {
  const {
    careerPageConfig,
    isPageDirty,
    handleSave,
    setVersionHistoryModalOpen,
  } = useCareerPageContext();
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return (
    <FormProvider {...formMethods}>
      <div tw="flex flex-nowrap items-center">
        <CustomerDropdown />
        {careerPageConfig && (
          <>
            <PageStatusDropDown />
            <ToolTip text="See the edit history of this page." arrow>
              <div
                tw="mr-4 p-2 cursor-pointer"
                onClick={() => setVersionHistoryModalOpen(true)}
              >
                <ClockCounterClockwise tw="h-6 w-6 text-gray-800" />
              </div>
            </ToolTip>

            <SaveButton shouldSave={isPageDirty} onDone={handleSave} />
          </>
        )}
      </div>
    </FormProvider>
  );
};

export default CareerCta;
