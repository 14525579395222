import { AdChannel } from 'types/adTypes';

export const getValidationRules = (channel: AdChannel) => {
  switch (channel) {
    case AdChannel.TIKTOK:
      return [
        'Aspect ratio should be 9:16',
        'Resolution must be a minimum of 540*960px',
        'Duration should be between 5 and 60 seconds',
        'Video must have sound',
      ];
    default:
      return [];
  }
};

export const isVideoInvalid = (
  channel: AdChannel,
  metadata: {
    width: number;
    height: number;
    duration: number;
    hasAudio: boolean;
  }
) => {
  const { width, height, duration, hasAudio } = metadata;

  switch (channel) {
    case AdChannel.TIKTOK:
      return (
        width / height !== 9 / 16 ||
        width < 540 ||
        height < 960 ||
        duration < 5 ||
        duration > 60 ||
        !hasAudio
      );
    default:
      return false;
  }
};
