import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'types/routeTypes';
import { handleAuth } from 'utils/authHelpers';

const SSOCallback = () => {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('access_token');
  const refreshToken = urlParams.get('refresh_token');

  if (accessToken && refreshToken) {
    handleAuth('sso', {
      access_token: accessToken,
      refresh_token: refreshToken,
      email: urlParams.get('email')!,
      first_name: urlParams.get('first_name')!,
      last_name: urlParams.get('last_name')!,
      id: parseInt(urlParams.get('id')!),
      is_sympl: !!urlParams.get('is_sympl')!,
      active_customer: parseInt(urlParams.get('active_customer')!),
      active_vacancy: urlParams.get('active_vacancy')
        ? parseInt(urlParams.get('active_vacancy')!)
        : undefined,
    });

    navigate(Routes.ROOT);
  }

  const error = urlParams.get('error');
  if (error) {
    // TODO: add some styling
    return (
      <div>
        <p>Something went wrong: {error}</p>
      </div>
    );
  }

  return (
    <div>
      <p>Loading... If this takes too long, please retry.</p>
    </div>
  );
};

export default SSOCallback;
