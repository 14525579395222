import 'twin.macro';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  IntakeInputTypes,
  IntakeQuestion,
  IntakeSection,
} from 'types/intakeTypes';
import { getQuestionErrorMessage, questionRules } from 'utils/formHelpers';
import IntakeTextEditor from '../text-editor/IntakeTextEditor';
import { Error } from 'components/typography/Typography';

import { FileUploadsWithText } from 'types/fileTypes';
import FileUpload from 'components/upload/ResourceUpload';
import { CustomerResource } from 'types/apiTypes';
import { DropzoneFileType } from 'components/upload/dropzone/Dropzone';

interface IntakeUploadProps {
  form: string;
  section: Omit<IntakeSection, 'hash' | 'steps'>;
  question: IntakeQuestion;
  defaultValue?: FileUploadsWithText;
  variant?: 'normal' | 'text';
  multiple?: boolean;
}

type IntakeFileUpload = Omit<FileUploadsWithText, 'files'> & {
  files: CustomerResource[] | number[];
};

const IntakeUpload: React.FC<IntakeUploadProps> = ({
  question,
  variant,
  defaultValue: _defaultValue,
  multiple,
}) => {
  const { control, errors, setValue } = useFormContext();

  const defaultValue = useMemo(() => {
    if (!_defaultValue) {
      return {
        text: '',
        files: [],
      };
    } else {
      return typeof _defaultValue.files?.[0] === 'number'
        ? _defaultValue
        : {
            ..._defaultValue,
            files: _defaultValue.files.map(({ id }) => id),
          };
    }
  }, [_defaultValue]);

  const [questionValue, _setQuestionValue] =
    useState<IntakeFileUpload>(defaultValue);

  const id = useMemo(() => question.id.toString(), [question.id]);
  const rules = useMemo(() => questionRules(question), [question]);
  const files = useMemo(() => defaultValue?.files, [defaultValue?.files]);

  const fileTypes = useMemo(
    () =>
      question?.uploadConstraints?.types &&
      (question?.uploadConstraints?.types?.map((t) =>
        t.toLowerCase()
      ) as DropzoneFileType[]),
    [question?.uploadConstraints?.types]
  );

  const isTextMode = useMemo(
    () => (defaultValue?.files?.length ?? 0) <= 0 && variant === 'text',
    [defaultValue, variant]
  );

  const [showText, setShowText] = useState(isTextMode);

  const isLogoUpload = useMemo(
    () =>
      question.name === 'branding_logo' &&
      (question.type === IntakeInputTypes.UPLOAD_MULTI ||
        question.type === IntakeInputTypes.UPLOAD),
    [question.name, question.type]
  );

  const showTextHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setShowText(!showText);
  };

  const getSavableUploadData = (data: IntakeFileUpload) => {
    const { files } = data;

    const parsedFiles =
      typeof files?.[0] !== 'number'
        ? (files as CustomerResource[]).map(({ id }) => id)
        : files;

    return {
      ...data,
      files: parsedFiles,
    };
  };

  const setQuestionValue = (data: IntakeFileUpload) => {
    _setQuestionValue(data);
    setValue(id, getSavableUploadData(data));
  };

  const textChangeHandler = (text: string) => {
    setQuestionValue({
      ...questionValue,
      text,
    });
  };

  const uploadChangeHandler = (newResources: CustomerResource[]) => {
    setQuestionValue({
      ...questionValue,
      files: [...newResources],
    });
  };

  const uploadDeleteHandler = (resourceIdx: number) => {
    setQuestionValue({
      ...questionValue,
      files: [...questionValue.files].filter(
        (_, i) => i !== resourceIdx
      ) as never,
    });
  };

  const hasValidFiles = (value: IntakeFileUpload) => {
    try {
      let hasValidObjects;

      const files =
        value?.files?.length > 0 ? value.files : questionValue?.files;
      const hasEnoughFiles = files.length >= 1;

      const hasValidId = (id: number) => id > 0 && id !== -1;

      if (!rules.required?.value) return true;

      if (typeof value.files?.[0] === 'number') {
        hasValidObjects = (value.files as number[]).every(hasValidId);
      } else {
        hasValidObjects = (value.files as CustomerResource[]).every(({ id }) =>
          hasValidId(id)
        );
      }

      return hasValidObjects && hasEnoughFiles;
    } catch (_) {
      return false;
    }
  };

  return (
    <div tw="flex flex-col">
      {!showText ? (
        <div tw="flex flex-col">
          <Controller
            id={id}
            name={id}
            control={control}
            defaultValue={defaultValue}
            rules={{ ...rules, validate: hasValidFiles }}
            render={() => (
              <FileUpload
                id={id}
                defaultValue={files}
                isLogo={isLogoUpload}
                maxFiles={!multiple ? 1 : undefined}
                fileTypes={fileTypes}
                onChange={uploadChangeHandler}
                onDelete={uploadDeleteHandler}
                onLoad={uploadChangeHandler}
              />
            )}
          />
          {errors[id] && (
            <Error>
              {getQuestionErrorMessage({
                type: errors[question.id]?.type,
                question,
              })}
            </Error>
          )}
        </div>
      ) : (
        <>
          <IntakeTextEditor
            variant="upload"
            question={question}
            onChange={(text) => textChangeHandler(text)}
            defaultValue={defaultValue?.text ?? ''}
          />
        </>
      )}
      {variant === 'text' && (
        <div tw="text-center">
          <p tw="text-gray-600 my-3">
            or{' '}
            <button
              tw="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline"
              onClick={(e) => showTextHandler(e)}
            >
              {showText
                ? 'upload your text'
                : 'click here to upload your text manually'}
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

export default IntakeUpload;
