import React, { FC } from 'react';

import CustomUrlForm from './CustomUrlForm';
import 'twin.macro';

type CustomUrlPageProps = {
  onSubmit: () => void;
};
const CustomUrlPage: FC<CustomUrlPageProps> = ({ onSubmit }) => {
  return (
    <div tw="bg-gray-100 w-full h-full pt-32">
      <div tw="bg-white mx-auto w-full max-w-[600px] flex items-center justify-center p-8">
        <CustomUrlForm onSubmit={onSubmit} isUpdateView />
      </div>
    </div>
  );
};

export default CustomUrlPage;
