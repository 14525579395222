import React, { createContext, useState } from 'react';

import { getLocalAppSession, setLocalAppSession } from 'utils/storageHelpers';

export type IntakeContextType = {
  brandColors?: [string, string];
  setBrandColors: (colors: [string, string]) => void;
};

export const IntakeContext = createContext<IntakeContextType>(undefined!);

export const IntakeProvider: React.FC<React.ReactNode> = ({ children }) => {
  const session = getLocalAppSession();

  const [brandColors, setCurrentBrandColors] = useState<
    [string, string] | undefined
  >(session?.brandColors ?? undefined);

  const setBrandColors = (colors: [string, string]) => {
    setCurrentBrandColors(colors);
    setLocalAppSession({ brandColors: colors });
  };

  return (
    <IntakeContext.Provider
      value={{
        brandColors,
        setBrandColors,
      }}
    >
      {children}
    </IntakeContext.Provider>
  );
};
