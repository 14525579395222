import 'twin.macro';

import Badge from 'components/badge/Badge';
import Button from 'components/button/Button';
import React from 'react';
import { User } from 'types/userTypes';

const ColleaguesListItem = ({
  colleague,
  removable,
  onResend,
  onUnlink,
  loading,
}: {
  colleague: User;
  removable: boolean;
  onResend: () => void;
  onUnlink: () => void;
  loading: boolean;
}) => (
  <div tw="mt-1 flex w-full flex-row flex-wrap justify-between rounded border-2 border-solid border-gray-200 p-4">
    <div tw="flex flex-col">
      <span tw="float-left flex flex-row font-bold">
        {`${colleague?.firstname} ${colleague?.lastname}`}
      </span>
      <span tw="text-sm text-gray-500">{colleague?.email}</span>
    </div>

    <div tw="my-auto">
      <div tw="flex items-center space-x-4 py-4">
        {!colleague?.verified && (
          <>
            <Badge label={'Pending'} color="gray" />
            <Button variant="link" onClick={onResend} loading={loading}>
              {loading ? 'Resending invite' : 'Resend invite'}
            </Button>
          </>
        )}
        {removable && (
          <Button variant="link" onClick={onUnlink}>
            Unlink
          </Button>
        )}
      </div>
    </div>
  </div>
);

export default ColleaguesListItem;
