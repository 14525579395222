import { useState, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';

/**
 * Checks if there are any unsaved changes for a given form.
 * @param formMethods The formMethods object linked to the form you want to watch
 * @returns A tuple containing a boolean indicating whether there are unsaved changes, a callback to reset the boolean
 */
const useUnsavedFields = (
  formMethods: UseFormMethods<any>
): [boolean, () => void] => {
  const [shouldSaveChanges, setShouldSaveChanges] = useState(false);

  /** Reset handler */
  const resetFlag = () => {
    setShouldSaveChanges(false);
    formMethods.reset();
  };

  /** Checks the formstate for unsaved changes */
  useEffect(() => {
    const amountOfDirtyFields = Object.keys(
      formMethods.formState.dirtyFields
    ).length;

    // Toggles the unsaved changes flag when there are dirty fields
    if (amountOfDirtyFields > 0) setShouldSaveChanges(true);

    return () => {
      // Reset the flag
      setShouldSaveChanges(false);
    };
  }, [formMethods.formState]);

  return [shouldSaveChanges, resetFlag];
};

export default useUnsavedFields;
