import React from 'react';

import { RouteGuards } from 'types/routeTypes';

import GuardedRoute from 'routing/GuardedRoute';

import TemplateOverview from './TemplateOverview';
import TemplateEditor from './TemplateEditor';
import { Routes, Route, Outlet } from 'react-router-dom';

const TemplatesRouter: React.FC = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <TemplateOverview />
            </GuardedRoute>
          }
        />
        <Route
          path="create"
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <TemplateEditor />
            </GuardedRoute>
          }
        />
        <Route
          path=":template"
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <TemplateEditor />
            </GuardedRoute>
          }
        />
      </Routes>
      <Outlet />
    </>
  );
};

export default TemplatesRouter;
