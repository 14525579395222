import tw, { styled } from 'twin.macro';
import React, { useState, useMemo } from 'react';
import SendMailForm from 'components/candidates/send-mail-form/SendMailForm';

import {
  Candidate,
  CandidateCommunications,
  CandidateStates,
} from 'types/candidates/types';
import { useFormContext } from 'react-hook-form';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import CandidateMailItem, {
  CommunicationAttachment,
} from './CandidateMailItem';
import { getLocalAppSession } from 'utils/storageHelpers';
import { getCurrentAuthToken } from 'utils/authHelpers';

export type Communication = {
  time: Date;
  id: number;
  to: string;
  from: string;
  message?: any;
  subject: string;
  text: string;
  html?: string;
  type: 'SENT' | 'RECEIVED';
  sent_at?: string;
  parsed_html?: string;
  parsed_text?: string;
  clientIsSender: boolean;
  sender_fn?: string;
  sender_ln?: string;
  receiver_fn?: string;
  receiver_ln?: string;
  created_at?: string;
  attachments?: CommunicationAttachment[];
};

export type MailAttachment = {
  name: string;
  type: string;
  size: number;
  content: string;
};

const MAIL_COLORS = [
  '#9ADCB330', // green
  '#A6C4F530', // blue
  '#F0969630', // red
  '#CBB8F530', // purple
  '#FABE8E30', // orange
  '#FBD59A30', // yellow
  '#F098CF30', // pink
  '#8EDCD430', // turquoise
  '#B1B4E930', // lavender
  '#BCC2CC30', // gray
];

const CandidateCommunication: React.FC<{
  newState?: CandidateStates;
  candidate: Candidate;
  communications?: CandidateCommunications[];
  onSendMail: () => void;
}> = ({ candidate, communications = [], onSendMail }) => {
  const { getValues, reset } = useFormContext();
  const { addToast } = useToastNotifications();
  const [editorFocus, setEditorFocus] = useState<boolean>(false);

  const comms = useMemo(
    () =>
      communications
        .map((c) => ({
          ...c.communicationDetails,
          time: new Date(c.created_at),
          clientIsSender: c.communicationDetails.from.includes('mail.sympl'),
        }))
        .sort((a, b) => a.time.getTime() - b.time.getTime())
        .reverse(),
    [communications]
  ) as Communication[];

  const subjectId = 'candidate-free-subject';
  const bodyId = 'candidate-free-body';

  const allSenders: string[] = [];
  comms?.forEach((communication) => {
    if (
      communication.type === 'SENT' &&
      !allSenders.includes(
        `${communication.sender_fn} ${communication.sender_ln}`
      )
    ) {
      allSenders.push(`${communication.sender_fn} ${communication.sender_ln}`);
    }
  });

  const [editorIsOpen, setEditorIsOpen] = useState<boolean>(false);

  const messageHistory = useMemo(() => {
    return JSON.stringify(comms.map((c) => ({ from: c.from, text: c.text })));
  }, [comms]);

  const sendMailHandler = async () => {
    const data = getValues();
    setEditorIsOpen?.(false);

    const formData = new FormData();
    formData.append('subject', data[subjectId]);
    formData.append('body', data[bodyId]);
    const attachments = Array.from(data.attachments || []) as File[];
    attachments.forEach((file) => formData.append('attachments[]', file));

    fetch(
      `${import.meta.env.VITE_BASE_URL_API}/v4/procedures/${
        candidate.procedureId ?? 0
      }/emails`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${getCurrentAuthToken()}`,
          'active-customer': `${getLocalAppSession()?.activeCustomer}`,
        },
      }
    ).then((res) => {
      if (res.ok) {
        addToast({
          description: `An email was sent to ${candidate.name}`,
          type: ToastTypes.SUCCESS,
        });

        onSendMail();
      } else {
        addToast({
          description: 'We could not send the email. Please try again.',
          type: ToastTypes.ERROR,
        });
      }

      setEditorIsOpen?.(false);
      reset();

      const fileInput = document.querySelector(
        '#email-attachments'
      ) as HTMLInputElement;

      if (fileInput) fileInput.value = '';
      reset({ attachments: [] });
    });
  };

  const handleFocusEditor = () => {
    setEditorIsOpen(true);
    setEditorFocus(true);
  };

  return (
    <div tw="flex flex-col-reverse w-full h-full relative grow-0">
      <div
        tw="w-full max-w-full flex justify-end mb-4 h-12 cursor-pointer text-gray-400 rounded-t-md"
        style={editorIsOpen ? { height: 0 } : {}}
      >
        <FocusInput
          editorIsOpen={editorIsOpen}
          placeholder="Click here to reply..."
          onFocus={handleFocusEditor}
        ></FocusInput>
      </div>
      <SendMailFormWrapper editorIsOpen={editorIsOpen}>
        <SendMailForm
          communication={
            comms.length > 0
              ? {
                  ...comms[0],
                  time: new Date(comms[0].sent_at!),
                }
              : undefined
          }
          procedureId={candidate.procedureId ?? 0}
          subjectId={subjectId}
          bodyId={bodyId}
          inside={true}
          onSend={() => sendMailHandler()}
          // onSendLoading={sendingMail}
          onSendLoading={false}
          candidate={candidate}
          hasFocus={editorFocus}
          enableAttachments={true}
          onClose={() => setEditorIsOpen?.(false)}
          messageHistory={messageHistory}
        />
      </SendMailFormWrapper>
      <div tw="h-full flex flex-col-reverse overflow-y-auto no-scrollbar overflow-x-hidden">
        {comms.map((communication) => (
          <CandidateMailItem
            key={communication.id}
            text={
              communication.parsed_html &&
              communication.parsed_html.trim().length
                ? communication.parsed_html
                : communication.parsed_text!
            }
            sender={`${communication.sender_fn} ${communication.sender_ln}`}
            mailType={communication.type}
            timestamp={new Date(`${communication.created_at}`)}
            color={
              communication.type === 'RECEIVED'
                ? '#F5F5F5'
                : MAIL_COLORS[
                    allSenders.indexOf(
                      `${communication.sender_fn} ${communication.sender_ln}`
                    ) % MAIL_COLORS.length
                  ]
            }
            attachments={communication.attachments}
          />
        ))}
      </div>
    </div>
  );
};

const SendMailFormWrapper = styled.div<{ editorIsOpen: boolean }>`
  ${tw`
    flex flex-col w-full grow-0
  `}
  ${({ editorIsOpen }) => !editorIsOpen && tw`h-0 hidden`}
`;

const FocusInput = styled.input<{ editorIsOpen: boolean }>`
  ${tw`
    flex items-center justify-center w-full h-full mx-2 px-2
  `}
  ${({ editorIsOpen }) => editorIsOpen && tw`hidden`}
`;

export default CandidateCommunication;
