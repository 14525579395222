import React from 'react';
import 'twin.macro';

interface IProps {
  radius: number;
  stroke: number;
  progress: number;
  onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

const ProgressCircle = (props: IProps) => {
  const { radius, stroke, progress } = props;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div tw="relative cursor-pointer" onClick={(e) => props.onClick(e)}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          style={{
            transition: 'stroke-dashoffset 0.35s',
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
            strokeDashoffset,
          }}
          stroke="#6875f5"
          fill="transparent"
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeDasharray={circumference + ' ' + circumference}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <span tw="absolute left-0 top-0 flex h-full w-full items-center justify-center text-right text-xl font-medium text-gray-600">
        {progress}
        <span tw="text-sm text-gray-500">%</span>
      </span>
    </div>
  );
};

export default ProgressCircle;
