import { gql } from '@apollo/client';

export const TRANSITION_CANDIDATE = gql`
  mutation transitionCandidate($procedureId: ID!) {
    candidateTransition(input: $input, procedureId: $procedureId)
      @rest(
        type: "CandidateTransition"
        path: "/v4/procedures/{args.procedureId}/apply-transition"
        method: "POST"
      )
  }
`;

export const UPDATE_CANDIDATE_COLOR = gql`
  mutation updateCandidateColor($proc_id: ID!) {
    updatedCandidateColor(input: $input, proc_id: $proc_id)
      @rest(
        type: "Procedure"
        path: "/v4/procedures/{args.proc_id}"
        method: "PUT"
      ) {
      color
    }
  }
`;

export const SEND_FREE_CANDIDATE_MAIL = gql`
  mutation sendFreeCandidateMail($procedureId: ID!) {
    templates(input: $input, procedureId: $procedureId)
      @rest(
        type: "FreeCandidateMail"
        path: "/v4/procedures/{args.procedureId}/emails"
        method: "POST"
      )
  }
`;

export const SAVE_CANDIDATE_NOTES = gql`
  mutation saveCandidateNotes($proc_id: ID!) {
    saveCandidateNotes(input: $input, proc_id: $proc_id)
      @rest(
        type: "CandidateNotes"
        path: "/v4/procedures/{args.proc_id}"
        method: "PUT"
      ) {
      notes
    }
  }
`;
