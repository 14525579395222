import React from 'react';
import { Lead } from 'types/leads/types';
import tw, { styled } from 'twin.macro';
import LeadCard from 'components/candidates/card/LeadCard';
import InformationToolTip from 'components/tooltip/InformationToolTip';

const LeadColumn: React.FC<{
  leads: Lead[];
  boardIsBusy: boolean;
}> = ({ boardIsBusy, leads }) => {
  return (
    <Wrapper boardIsBusy={boardIsBusy}>
      <div tw="flex flex-row items-center md:items-baseline uppercase font-semibold text-gray-500 tracking-tight h-[2rem] pr-4">
        <span tw="uppercase text-gray-500 whitespace-nowrap flex items-center gap-2">
          Leads
          <InformationToolTip
            icon="information"
            name="Leads"
            text="These leads cannot be moved to the candidates board."
          />
        </span>
        <span tw="ml-4 md:ml-auto text-gray-700 bg-slate-200 md:bg-transparent rounded-full p-1 text-center md:text-right md:p-0 w-6 md:w-auto text-xs font-bold whitespace-nowrap">
          {leads.length}
        </span>
      </div>
      <LeadCardContainer boardIsBusy={boardIsBusy}>
        {leads.map((lead, index) => (
          <LeadCard key={index} lead={lead} />
        ))}
      </LeadCardContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ boardIsBusy: boolean }>`
  ${tw`mt-2 h-full w-full overflow-hidden`}
  ${({ boardIsBusy }) =>
    boardIsBusy && tw`pointer-events-none opacity-50 filter grayscale`}
`;

const LeadCardContainer = styled.div<{ boardIsBusy: boolean }>`
  ${tw`w-[300px] h-[calc(100% - 2rem)] grow flex flex-col gap-5 bg-gray-100 overflow-y-auto no-scrollbar p-6 pt-8`}
`;

export default LeadColumn;
