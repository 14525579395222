import React, { useContext } from 'react';
import tw, { styled } from 'twin.macro';
import {
  GlobeHemisphereWest,
  MapPin,
  EnvelopeSimple,
  Phone,
} from '@phosphor-icons/react';

import { Candidate } from 'types/candidates/types';
import { CandidateContext, CandidateDetailTab } from 'context/CandidateContext';
import { Body, Title3 } from 'components/typography/Typography';
import WhatsAppButton from 'components/button/WhatsAppButton';
import SourceIcon from 'components/icon-card/SourceIcon';

const ContactList: React.FC<{
  email: string;
  phone: string;
  source: string;
  candidate?: Candidate;
}> = ({ candidate, email, phone, source }) => {
  const candidateContext = useContext(CandidateContext);

  const setActiveTab = candidateContext
    ? candidateContext.setActiveDetailTab
    : null;

  const setSidebarIsOpen = candidateContext
    ? candidateContext.setSidebarIsOpen
    : null;

  const setMailModalIsOpen = candidateContext
    ? candidateContext.setMailModalIsOpen
    : null;

  const onEmailClicked = () => {
    setActiveTab?.(CandidateDetailTab.COMMUNICATION);
    setMailModalIsOpen?.(true);
    setSidebarIsOpen?.(false);
  };

  return (
    <>
      <Title3>Contact</Title3>
      <ul>
        <ContactListItem tw="text-left break-all">
          <EnvelopeSimple tw="text-gray-400 mr-2" size="20" />
          <Body isLight>{email}</Body>
        </ContactListItem>
        {phone && (
          <ContactListItem tw="text-left">
            <Phone tw="text-gray-400 mr-2" size="20" />
            <Body isLight>{phone}</Body>
          </ContactListItem>
        )}
        {candidate?.nationality && (
          <ContactListItem tw="text-left">
            <GlobeHemisphereWest tw="text-gray-400 mr-2" size="20" />
            <Body isLight isUppercase>
              {candidate.nationality}
            </Body>
          </ContactListItem>
        )}
        {candidate?.address && (
          <ContactListItem tw="text-left truncate">
            <MapPin tw="text-gray-400 mr-2" size="20" />
            <Body isLight>{candidate.address}</Body>
          </ContactListItem>
        )}
        {source && (
          <ContactListItem tw="text-left truncate gap-2">
            <SourceIcon source={source} />
            <Body isLight>Via {source.toLowerCase()}</Body>
          </ContactListItem>
        )}
        <ContactListItem tw="text-left flex-wrap gap-x-2 gap-y-2">
          {candidate ? (
            <ContactButton onClick={() => onEmailClicked()}>
              Email
            </ContactButton>
          ) : (
            <ContactLink
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
            >
              Email
            </ContactLink>
          )}
          {phone && (
            <>
              <WhatsAppButton phone={phone} />
              <ContactLink href={`tel:${phone}`}>Call</ContactLink>
            </>
          )}
        </ContactListItem>
      </ul>
    </>
  );
};

const ContactListItem = styled.li(
  tw`flex items-center text-gray-600 mb-3 last-of-type:(m-0 mt-6)`
);

const ContactLink = styled.a(
  tw`
    rounded-md inline-flex items-center px-4 py-2 border border-gray-100 transition
  bg-white text-gray-700 text-sm font-medium
  hover:bg-gray-50
    focus:(ring-2 ring-offset-2 ring-indigo-400 outline-none)
  `
);

const ContactButton = styled.button(
  tw`
    rounded-md inline-flex items-center px-4 py-2 border border-gray-100 transition
  bg-white text-gray-700 text-sm font-medium
    hover:bg-gray-50
    focus:(ring-2 ring-offset-2 ring-indigo-400 outline-none)
  `
);

export default ContactList;
