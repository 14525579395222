import { gql } from '@apollo/client';

export const GET_CUSTOMER_CAREER_PAGE = gql`
  query fetchCustomerCareerPage($pageId: String!) {
    customerCareerPage(pageId: $pageId)
      @rest(type: "CustomerResource", path: "/v4/pages/{args.pageId}") {
      status
      url
      created_at
      updated_at
      branding
      seo
      sections
      order
    }
  }
`;

export const GET_VERSION_HISTORY = gql`
  query fetchVersionHistory($pageId: String!) {
    versionHistory(pageId: $pageId)
      @rest(
        type: "VersionHistory"
        path: "/v4/pages/{args.pageId}/version-history"
      ) {
      history
    }
  }
`;
