import 'twin.macro';
import { Title1 } from 'components/typography/Typography';
import React from 'react';
import { FC } from 'react';
import { FieldTypes } from 'components/form/field-generator/FieldGenerator';
import { IWizardStep } from 'components/form/wizard-form/WizardStep';
import WizardForm from 'components/form/wizard-form/WizardForm';
import OnboardingCheckbox, {
  OnboardingCheckboxType,
} from 'components/form/cards/onboarding-cards/OnboardingCheckbox';
import { Newspaper, Users, UserRectangle } from '@phosphor-icons/react';
import { useMutation } from 'hooks/sympl-mutation';
import { ANSWER_ONBOARDING_QUESTIONS } from 'graphql/onboarding/mutations';
import * as Styled from './styles';
import SvgSymplS from 'components/svg/SvgSymplS';
import LogoLinkedin from 'components/icons/socials/LogoLinkedin';
import LogoInstagram from 'components/icons/socials/LogoInstagram';
import LogoFacebook from 'components/icons/socials/LogoFacebook';
export interface OnBoardingFormProps {
  onHandleSubmitAnswers: () => void;
}

interface OnboardingQuestionsPayload {
  input: {
    [key: string]: string;
  };
}

const OnBoardingForm: FC<OnBoardingFormProps> = ({ onHandleSubmitAnswers }) => {
  const [answerOnboardingQuestions] = useMutation<
    undefined,
    OnboardingQuestionsPayload
  >(ANSWER_ONBOARDING_QUESTIONS);

  const handleSubmitForm = (fields: OnboardingQuestionsPayload['input']) => {
    // if other is answered make it the main answer
    if (
      fields['q-how-did-you-hear-about-sympl-other'] &&
      fields['q-how-did-you-hear-about-sympl-other'] !== ''
    ) {
      fields['q-how-did-you-hear-about-sympl'] =
        fields['q-how-did-you-hear-about-sympl-other'];
      delete fields['q-how-did-you-hear-about-sympl-other'];
    }

    const isEmptyAnswers = Object.values(fields).every((value) => value === '');

    if (!isEmptyAnswers) {
      answerOnboardingQuestions({
        variables: {
          input: {
            ...fields,
          },
        },
      });
    }

    onHandleSubmitAnswers();
  };

  const fieldSteps: IWizardStep[] = [
    {
      title: 'How many people work at your company?',
      fields: [
        {
          name: 'q-how-many-employees',
          type: FieldTypes.InteractiveCheckbox,
          rules: { required: false },
          defaultValue: '',
          placeholder: 'Enter your answer',
          autoFocus: true,
          gridColumns: 3,
          moveOnAnswer: true,
          optionRenderer: (option) => (
            <OnboardingCheckbox
              type={OnboardingCheckboxType.Employee}
              option={option}
            />
          ),
          options: [
            { value: '1-10' },
            { value: '10-25' },
            { value: '25-100' },
            { value: '100-250' },
            { value: '250-1000' },
            { value: '>1000' },
          ],
        },
      ],
    },
    {
      title: 'How many vacancies do you have per year?',
      fields: [
        {
          name: 'q-how-many-vacancies-per-year',
          type: FieldTypes.InteractiveCheckbox,
          rules: { required: false },
          defaultValue: '',
          placeholder: 'Enter your answer',
          autoFocus: true,
          gridColumns: 3,
          moveOnAnswer: true,
          optionRenderer: (option) => (
            <OnboardingCheckbox
              type={OnboardingCheckboxType.Vacancies}
              option={option}
            />
          ),
          options: [
            { value: ' 1-3 ' },
            { value: '4-10' },
            { value: '10-20' },
            { value: '20-50' },
            { value: '50-100' },
            { value: '>100' },
          ],
        },
      ],
    },
    {
      title: 'How did you hear about sympl ?',
      fields: [
        {
          name: 'q-how-did-you-hear-about-sympl',
          type: FieldTypes.InteractiveCheckbox,
          rules: { required: false },
          defaultValue: '',
          placeholder: 'Select or create your answer',
          autoFocus: true,
          isCreatable: true,
          creatableProps: {
            name: 'q-how-did-you-hear-about-sympl-other',
            placeholder: 'Can you specify?',
          },
          optionRenderer: (option) => (
            <OnboardingCheckbox
              type={OnboardingCheckboxType.Exposure}
              option={option}
            />
          ),
          options: [
            {
              value: 'LinkedIn',
              icon: (
                <Styled.IconCircle tw="bg-blue-100 text-blue-500">
                  <LogoLinkedin width="16px" height="16px" />
                </Styled.IconCircle>
              ),
            },
            {
              value: 'Instagram',
              icon: (
                <Styled.IconCircle tw="bg-fuchsia-100 text-fuchsia-500">
                  <LogoInstagram width="16px" height="16px" />
                </Styled.IconCircle>
              ),
            },
            {
              value: 'Facebook',
              icon: (
                <Styled.IconCircle tw="bg-blue-100 text-blue-700">
                  <LogoFacebook width="16px" height="16px" />
                </Styled.IconCircle>
              ),
            },
            {
              value: 'Webinar',
              icon: (
                <Styled.IconCircle tw="bg-amber-100 text-amber-500">
                  <UserRectangle weight="bold" />
                </Styled.IconCircle>
              ),
            },
            {
              value: 'Website',
              icon: (
                <Styled.IconCircle tw="bg-red-100 text-red-500">
                  <div tw="w-5 h-5 block">
                    <SvgSymplS />
                  </div>
                </Styled.IconCircle>
              ),
            },
            {
              value: 'News',
              icon: (
                <Styled.IconCircle tw="bg-lime-100 text-lime-500">
                  <Newspaper weight="bold" />
                </Styled.IconCircle>
              ),
            },
            {
              value: 'Colleague',
              icon: (
                <Styled.IconCircle tw="bg-teal-100 text-teal-500">
                  <Users weight="bold" />
                </Styled.IconCircle>
              ),
            },
          ],
        },
      ],
    },
  ];

  return (
    <Styled.PageContainer>
      <Title1 mb={4}>Let us get to know you a little bit more 👐🏼</Title1>
      <Styled.FormContainer>
        <WizardForm steps={fieldSteps} onHandleSubmitForm={handleSubmitForm} />
      </Styled.FormContainer>
    </Styled.PageContainer>
  );
};

export default OnBoardingForm;
