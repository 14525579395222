import { Candidate } from 'types/candidates/types';
import { useQuery } from '../sympl-query';
import { GET_PROCEDURES_FROM_VACANCY } from 'graphql/candidates/queries';

interface GetCandidatesQueryData {
  candidates: Candidate[];
}
interface GetCandidatesQueryParams {
  vacId: number;
}

const useGetCandidatesQuery = (activeVacancy?: number | null) => {
  const { loading: loadingCandidates, data: candidatesData } = useQuery<
    GetCandidatesQueryData,
    GetCandidatesQueryParams
  >(GET_PROCEDURES_FROM_VACANCY, {
    skip: !activeVacancy,
    variables: { vacId: activeVacancy ?? 0 },
  });

  return { loadingCandidates, candidatesData };
};

export default useGetCandidatesQuery;
