import AutoTable from 'components/table/auto-table/AutoTable';
import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { roundNumber } from 'utils/numberHelpers';
import SocialIcon from 'components/icons/SocialIcon';
import useReportingContext from 'hooks/context/reporting-context';
import { useQuery } from 'hooks/sympl-query';
import { GET_REPORTING_DATA_PER_CHANNEL } from 'graphql/reporting/queries';
import { ReportingData } from 'context/ReportingContext';
import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import { tableColumns } from './Reporting';
import useNavigationContext from 'hooks/context/nav-context';
import ToolTip from 'components/tooltip/ToolTip';
import { ShareNetwork } from '@phosphor-icons/react';

interface ChannelReportingData {
  total: ReportingData;
  per_channel: {
    [channel: string]: ReportingData;
  };
}

const Channels = () => {
  const { queryString, reportingScreen } = useReportingContext();
  const { activeCustomer } = useNavigationContext();
  const [data, setData] = useState<{ reporting: ChannelReportingData }>();

  const { loading, refetch } = useQuery<
    { reporting: ChannelReportingData },
    { queryString: string }
  >(GET_REPORTING_DATA_PER_CHANNEL, {
    fetchPolicy: 'network-only',
    skip: reportingScreen !== 'channels',
    variables: {
      queryString: queryString,
    },
    onCompleted: (data) => {
      setData(data);
    },
  });

  useEffect(() => {
    refetch();
  }, [queryString, activeCustomer]);

  const cols = () => {
    const [first, ...rest] = tableColumns;
    return [['Channels', ...first], ...rest];
  };

  const getChannelMetric = (
    field: keyof ReportingData,
    channel?: string,
    unit?: 'currency' | 'percentage',
    noDecimals: boolean = false
  ) => {
    const round = noDecimals ? true : false;
    const metricType = channel
      ? data?.reporting.per_channel[channel]
      : data?.reporting.total;
    return roundNumber(
      metricType?.[field] ?? 0,
      unit === 'currency',
      unit === 'percentage',
      round
    );
  };

  const createChannelRow = (channel?: string, firstElement?: JSX.Element) => {
    return [
      <>{firstElement || <p tw="ml-4 px-2">Total</p>}</>,
      <p>{getChannelMetric('impressions', channel)}</p>,
      <p>{getChannelMetric('clicks', channel, undefined, true)}</p>,
      <p>{getChannelMetric('visits', channel, undefined, true)}</p>,
      <p>{getChannelMetric('applications', channel, undefined, true)}</p>,
      <p>{getChannelMetric('spend', channel, 'currency')}</p>,
      <p data-divider={true}></p>,
      <p>{getChannelMetric('ctr', channel, 'percentage')}</p>,
      <p>{getChannelMetric('conversion', channel, 'percentage')}</p>,
      <p>{getChannelMetric('cpm', channel, 'currency')}</p>,
      <p>{getChannelMetric('cpc', channel, 'currency')}</p>,
      <p>{getChannelMetric('cpa', channel, 'currency')}</p>,
    ];
  };

  const rows = React.useMemo(() => {
    return [
      'Facebook',
      'Instagram',
      'Linkedin',
      'Tiktok',
      'Indeed',
      'Google',
      'Other',
    ].map((channel) =>
      createChannelRow(
        channel.toLowerCase(),
        channel === 'Other' ? (
          <ToolTip
            content={
              <p tw="text-gray-100 text-sm">
                Other sources of candidates <br /> (eg. Indeed, Google, direct,
                ...)
              </p>
            }
            placement="top"
            arrow
          >
            <div tw="flex items-center gap-2">
              <ShareNetwork width={18} height={18} weight="fill" />
              <p>{channel}</p>
            </div>
          </ToolTip>
        ) : (
          <div tw="flex items-center gap-2">
            <SocialIcon company={channel.toLowerCase()} grayScale />
            <p>{channel}</p>
          </div>
        )
      )
    );
  }, [data]);

  const summationRow = React.useMemo(() => {
    return createChannelRow();
  }, [data]);

  while (loading) return <SkeletonList rows={5} />;

  return (
    <div tw="mt-8">
      <AutoTable
        tw="mt-4"
        rows={rows}
        columns={cols()}
        summationRow={summationRow}
        headerStyling={tw`text-center w-24`}
        firstElementStyling={tw`w-40`}
        elementStyling={tw`w-24 text-right`}
        scrollable={false}
        alternate
        emptyLabel="You have no reporting data yet. Please wait a couple of hours after your campaign was published."
      />
    </div>
  );
};

export default Channels;
