import { OptionType } from 'components/form/field-generator/FieldGenerator';
import { Title3 } from 'components/typography/Typography';
import React, { FC } from 'react';
import 'twin.macro';
import { Megaphone, UsersThree, Users } from '@phosphor-icons/react';

export enum OnboardingCheckboxType {
  Employee = 'employee',
  Vacancies = 'vacancies',
  Exposure = 'exposure',
}

interface OnboardingCheckboxProps {
  type: OnboardingCheckboxType;
  option: OptionType;
}

const OnboardingCheckbox: FC<OnboardingCheckboxProps> = (props) => {
  const {
    type,
    option: { label, value, icon },
  } = props;

  switch (type) {
    case OnboardingCheckboxType.Employee:
      return (
        <div tw="w-full">
          <div tw="flex flex-col items-center space-x-2">
            <div tw="text-2xl p-4 rounded-full m-4 bg-indigo-200 text-indigo-700">
              {value === '>1000' ? <UsersThree /> : <Users />}
            </div>
            <div tw="flex flex-col">
              <Title3 mb={0} isCenter>
                {label ?? value}
              </Title3>
            </div>
          </div>
        </div>
      );
    case OnboardingCheckboxType.Vacancies:
      return (
        <div tw="w-full">
          <div tw="flex flex-col items-center space-x-2">
            <div tw="text-2xl p-4 rounded-full m-4 bg-orange-100 text-orange-700">
              <Megaphone weight="bold" mirrored />
            </div>

            <div tw="flex flex-col">
              <Title3 mb={0} isCenter>
                {label ?? value}
              </Title3>
            </div>
          </div>
        </div>
      );
    case OnboardingCheckboxType.Exposure:
      return (
        <div tw="w-full">
          <div tw="flex flex-col items-center space-x-2">
            {icon && icon}
            <div tw="flex flex-col">
              <Title3 mb={0} isCenter>
                {label ?? value}
              </Title3>
            </div>
          </div>
        </div>
      );
  }

  return (
    <div tw="w-full">
      <div tw="flex flex-col items-center space-x-2">
        <div tw="flex flex-col">
          <Title3 mb={0}>{label ?? value}</Title3>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCheckbox;
