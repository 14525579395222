export const getRandomText = (length = 80) => {
  let text = '';
  for (let words = 0; words < Math.random() * 50 + length; words++) {
    for (let wLength = 0; wLength < Math.random() * 12; wLength++) {
      text += 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'[
        Math.floor(Math.random() * 52)
      ];
    }
    text += ' ';
  }
  return text;
};
