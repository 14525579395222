import { CalendarDots } from '@phosphor-icons/react';
import React from 'react';
import DateRangePicker, { DateRange, RangeType } from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';
import { datePickerRanges } from './ranges';
import './dateRangePickerStyles.css';
import useReportingContext from 'hooks/context/reporting-context';
import dayjs from 'dayjs';

interface DatePickerProps {
  defaultDateRange?: string;
  value: DateRange | null;
  onChange: (dates: DateRange | undefined) => void;
}

// customize colours in Globalstyles.tsx (:root)
const DatePicker: React.FC<DatePickerProps> = ({ value, onChange }) => {
  const { filters } = useReportingContext();
  return (
    <DateRangePicker
      format="dd-MM-yyyy"
      placement="bottomEnd"
      placeholder={`${dayjs(filters.start_date).format('DD-MM-YYYY')} - ${dayjs(
        filters.end_date
      ).format('DD-MM-YYYY')}`}
      value={value}
      onChange={(value: DateRange | null) => onChange?.(value || undefined)}
      caretAs={CalendarDots}
      isoWeek
      ranges={datePickerRanges}
      onShortcutClick={(range: RangeType) =>
        onChange?.(range.value as DateRange)
      }
      onClean={() => onChange?.(undefined)}
    />
  );
};

export default DatePicker;
