import React, { FC } from 'react';
import { convertToMonthPrice } from 'utils/subscriptions/helpers';
import 'twin.macro';
import { Interval } from 'types/subscriptions/types';
import { Footnote, Headline } from 'components/typography/Typography';

const PlanPrice: FC<{
  price: number;
  interval: Interval;
}> = ({ price, interval }) => {
  return (
    <div>
      <span tw="text-4xl font-extrabold text-gray-800 mr-2">
        €{convertToMonthPrice(price, interval)}
      </span>
      <Headline isBlock={false} isLight>
        /mo <Footnote>(billed {interval.toLowerCase()})</Footnote>
      </Headline>
    </div>
  );
};

export default PlanPrice;
