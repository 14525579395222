import { AdPlacement } from 'types/adTypes';

export const getCanvasHeight = (placement?: AdPlacement): number => {
  switch (placement) {
    case AdPlacement.FEED:
      return 1000;
    case AdPlacement.STORIES:
    case AdPlacement.REELS:
      return 1920;
    default:
      return 1;
  }
};

export const getCanvasWidth = (placement?: AdPlacement): number => {
  switch (placement) {
    case AdPlacement.FEED:
      return 1000;
    case AdPlacement.STORIES:
    case AdPlacement.REELS:
      return 1080;
    default:
      return 1;
  }
};
