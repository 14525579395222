import { AcceptedSections, SectionTypeLabels } from '@symplbe/sympl-components';
import SectionIcons from 'components/careers/page-settings-sidebar/section-list-settings/SectionIcons';
import React, { FC, ReactNode } from 'react';
import tw, { styled } from 'twin.macro';

interface SectionContainerProps {
  children: ReactNode;
  sectionProps: AcceptedSections;
  index: number;
}
const SectionContainer: FC<SectionContainerProps> = ({
  children,
  sectionProps,
  index,
}) => {
  return (
    <Container
      // always on editMode
      isEditMode
      // used so its a lot easier to find the section in the dom
      id={`${index}-${sectionProps.type}-${sectionProps.sectionId}`}
    >
      <Label className="section-container-label">
        <span tw="mr-2">
          <SectionIcons type={sectionProps.type} size={16} />
        </span>
        {SectionTypeLabels[sectionProps.type]} Section
      </Label>
      {children}
    </Container>
  );
};

const Label = styled.div`
  ${tw`
    absolute 
    top-0 
    left-0 
    z-[20]
    bg-blue-500
    py-[.25rem]
    px-4
    leading-none
    text-xs
    text-white
    opacity-0
    pointer-events-none

    flex
    items-center
    flex-nowrap
    justify-start

  `}
`;

const Container = styled.div<{
  isEditMode?: boolean;
}>`
  ${tw`
    relative

    before:content-['']
    before:absolute
    before:top-0
    before:left-0
    before:w-full
    before:h-full
    before:bg-blue-500
    before:bg-opacity-0
    before:z-[10]
    before:invisible
    before:pointer-events-none

  `}
`;

export default SectionContainer;
