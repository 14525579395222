import { gql } from '@apollo/client';

export const UPDATE_PO_NUMBER = gql`
  mutation updatePoNumber($invoiceId: ID!) {
    invoices(invoiceId: $invoiceId, input: $input)
      @rest(
        type: "Invoice"
        path: "/v4/invoices/{args.invoiceId}"
        method: "POST"
      )
  }
`;

export const REGENERATE_INVOICE = gql`
  mutation regenerateInvoice($invoiceId: ID!) {
    invoices(invoiceId: $invoiceId, input: {})
      @rest(
        type: "Invoice"
        path: "/v4/invoices/{args.invoiceId}/generate"
        method: "PUT"
      )
  }
`;

export const CLOSE_INVOICE = gql`
  mutation closeInvoice($invoiceId: ID!) {
    invoices(invoiceId: $invoiceId, input: {})
      @rest(
        type: "Invoice"
        path: "/v4/invoices/{args.invoiceId}/close"
        method: "PUT"
      )
  }
`;
