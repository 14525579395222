import 'twin.macro';
import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'hooks/sympl-query';

import { Intake, IntakeInputTypes } from 'types/intakeTypes';
import QuestionAnswerList from 'components/questions/QuestionAnswerList';
import AppPage from 'components/page/app-page/AppPage';
import { questionValueToFileUploadsWithText } from 'utils/typeHelpers';
import { isJsonString } from 'utils/baseHelpers';
import Button from 'components/button/Button';
import { downloadDOFile } from 'utils/fileHelpers';
import { GET_INTAKE } from 'graphql/intakes/queries';

export const IntakeDetails: React.FC<{
  hash?: string;
}> = ({ hash }) => {
  const { loading, data } = useQuery<
    {
      intake: Intake;
    },
    { hash: string }
  >(GET_INTAKE, {
    skip: hash === undefined,
    variables: { hash: hash ?? '' },
  });

  const intakeQuestions = useMemo(() => {
    return (
      data?.intake?.sections.flatMap((section) =>
        section?.steps.flatMap((step) =>
          step.questions.map((question) => ({
            ...question,
            answer: question?.friendlyValue ?? question?.value,
          }))
        )
      ) ?? []
    );
  }, [data?.intake?.sections]);

  const isUploadQuestion = useCallback(
    (answer: any, questionType: IntakeInputTypes) =>
      answer &&
      questionType
        .toLowerCase()
        .replaceAll('_', '')
        .includes(IntakeInputTypes.UPLOAD),
    []
  );

  const files = useMemo(
    () =>
      intakeQuestions
        .filter(({ answer, type }) => isUploadQuestion(answer, type))
        .map((question) => {
          const answer = (question?.answer ?? '')?.toString();
          const parsed = isJsonString(answer) ? JSON.parse(answer) : answer;
          return questionValueToFileUploadsWithText(parsed);
        })
        .filter((file) => file.files.length > 0),
    [intakeQuestions, isUploadQuestion]
  );

  const pathIsImagePath = (path: string) => {
    const extension = [...path.split('.')].reverse()?.[0] ?? '';
    return path && !['mp4', 'docx', 'pdf', 'doc'].includes(extension);
  };

  const downloadableImages = useMemo(
    () =>
      files
        .flatMap(({ files }) => files)
        .map(({ path }) => path)
        .filter((path) => path !== undefined && pathIsImagePath(path)),
    [files]
  );

  const imagesDownloadHandler = () =>
    downloadableImages.forEach((path) => downloadDOFile(path));

  return (
    <AppPage
      heading="Intake details"
      allowPageWithoutSubscription
      allowPageWithoutVacancy
      loading={loading}
      skeleton={
        <div tw="max-w-3xl">
          <ul tw="flex flex-col">
            {[...Array(11)].map((_x, i) => (
              <li key={i} tw="flex flex-col h-full w-full mb-8">
                <div tw="bg-gray-200 h-6 w-full animate-pulse rounded-md" />
                <div tw="mt-2 bg-gray-200 h-4 w-3/5 animate-pulse rounded-md" />
              </li>
            ))}
          </ul>
        </div>
      }
      cta={<Button onClick={imagesDownloadHandler}>Download all images</Button>}
    >
      <QuestionAnswerList items={intakeQuestions} name={data?.intake?.name} />
    </AppPage>
  );
};
