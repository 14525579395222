import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { CreditCard, Eye, UsersThree } from '@phosphor-icons/react';

type StatIcon = 'eye' | 'money' | 'people';

const NumberStat: React.FC<{
  upperLabel: string;
  lowerLabel?: string;
  value?: string;
  statIcon: StatIcon;
}> = ({ upperLabel, value = 0, statIcon }) => {
  const ICON_SIZE = 28;

  const icon = useMemo(() => {
    switch (statIcon) {
      case 'eye':
        return <Eye weight="bold" size={ICON_SIZE} />;
      case 'money':
        return <CreditCard weight="bold" size={ICON_SIZE} />;
      case 'people':
        return <UsersThree weight="bold" size={ICON_SIZE} />;
    }
  }, [statIcon]);

  return (
    <Wrapper>
      <div tw="text-gray-400">{icon}</div>
      <div tw="flex flex-row md:flex-col items-center justify-between grow mb-2 mt-1 md:px-4">
        <p tw="ml-3 md:m-0 text-sm font-medium text-gray-500">{upperLabel}</p>
        <span tw="text-xl md:(text-3xl) font-bold text-gray-600">{value}</span>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  tw`rounded-md px-4 py-2 shadow flex items-center grow w-full md:py-4`
);

export default NumberStat;
