import React from 'react';
import tw, { TwStyle, css, styled } from 'twin.macro';

const SkeletonList: React.FC<{ rows?: number }> = ({ rows }) => (
  <ul>
    {[...Array(rows ?? 4)].map((_, i) => (
      <ListItem key={i} />
    ))}
  </ul>
);

export const SkeletonList1: React.FC<{ rows?: number }> = ({ rows }) => (
  <ul>
    {[...Array(rows ?? 4)].map((_, i) => (
      <div key={i} tw="w-full flex items-center flex-nowrap p-2">
        <div tw="w-[50px] h-[50px]">
          <SkeletonItem height="100%" />
        </div>
        <div tw="w-[calc(100%-_50px)] pl-2 flex flex-col justify-center">
          <SkeletonItem height="15px" width="80%" twStyle={tw`mb-2`} />
          <SkeletonItem height="15px" />
        </div>
      </div>
    ))}
  </ul>
);

export const SkeletonItem = styled.div<{
  height?: string;
  width?: string;
  twStyle?: TwStyle;
}>`
  ${tw`bg-gray-200 h-10 w-full animate-pulse rounded-md mb-2 last-of-type:mb-0`}
  ${({ twStyle }) => twStyle && twStyle}

  ${({ height }) =>
    height &&
    css`
      --height: ${height};
    `}
  ${({ height }) => height && tw`h-[var(--height)]`}
  ${({ width }) =>
    width &&
    css`
      --width: ${width};
    `}
  ${({ width }) => width && tw`w-[var(--width)]`}
`;

const ListItem = styled.li(
  tw`bg-gray-200 h-10 w-full animate-pulse rounded-md mb-2 last-of-type:mb-0`
);

export default SkeletonList;
