import React, { useContext, useState } from 'react';

import 'twin.macro';
import PageContent from './PageContent';
import CopyToClipboard from 'components/copy-to-clipboard/CopyToClipboard';
import AppPage from 'components/page/app-page/AppPage';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import SaveButton from 'components/save-button/SaveButton';
import { SurveyContext } from 'context/SurveyContext';
import { LogoSideBar } from './components/logo-sidebar';
import { ColorSideBar } from './components/color-sidebar';
import Button from 'components/button/Button';
import { GearSix } from '@phosphor-icons/react';

export const SurveyPage = () => {
  const {
    typeformUrl,
    updateVacancySurveyError,
    updatingVacancySurvey,
    onSave,
    hasUnsavedChanges,
  } = useContext(SurveyContext);
  const [activeTab, setActiveTab] = useState<AdEditorSideBarTabs>();

  return (
    <AppPage
      heading="Application Form"
      disablePadding={true}
      clipboardCopy={typeformUrl && <CopyToClipboard url={typeformUrl} />}
      cta={
        <div tw="flex items-center w-full gap-2">
          <Button
            onClick={() => setActiveTab?.(AdEditorSideBarTabs.LOGO_OPTIONS)}
            variant="secondary"
          >
            <GearSix weight="bold" size={20} />
          </Button>
          <SaveButton
            shouldSave={hasUnsavedChanges}
            loading={updatingVacancySurvey}
            error={updateVacancySurveyError}
            onClick={onSave}
            disabled={updatingVacancySurvey}
          />
        </div>
      }
      isSideBarOpen={!!activeTab}
      setActiveTab={setActiveTab}
      sideBarMenu={[AdEditorSideBarTabs.LOGO_OPTIONS]}
      activeTabIndex={Object.values(AdEditorSideBarTabs).indexOf(
        activeTab ?? AdEditorSideBarTabs.LOGO_OPTIONS
      )}
      sideBarContent={
        <>
          {activeTab === AdEditorSideBarTabs.LOGO_OPTIONS && <LogoSideBar />}
          {activeTab === AdEditorSideBarTabs.COLOR_OPTIONS && <ColorSideBar />}
        </>
      }
    >
      <PageContent activeTab={activeTab} />
    </AppPage>
  );
};

export default SurveyPage;
