import { gql } from '@apollo/client';

export const GET_WEEKLY_METRICS = gql`
  query getCampaignMetrics {
    campaignMetrics
      @rest(type: "[CampaignMetrics]", path: "/v4/dashboard/total") {
      windowed_impressions
      windowed_applications
      windowed_spend
    }
  }
`;
