import { gql } from '@apollo/client';

export const GET_CUSTOMER_RESOURCES = gql`
  query fetchCustomerResources(
    $pageSize: Int!
    $currentPage: Int!
    $types: String!
    $vacancyFilter: Number
    $brandFilter: Number
  ) {
    customerResources(
      pageSize: $pageSize
      currentPage: $currentPage
      types: $types
      vacancyFilter: $vacancyFilter
      brandFilter: $brandFilter
    )
      @rest(
        type: "CustomerResource"
        path: "/v4/customer-resources?sort=-created_at&page%5Bsize%5D={args.pageSize}&page%5Bnumber%5D={args.currentPage}&filter%5Btype%5D={args.types}&vacancy_id={args.vacancyFilter}&brand_id={args.brandFilter}"
      ) {
      id
      path
      thumbnail_path
      type
      meta
      created_at
      width
      height
    }
  }
`;
