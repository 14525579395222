import { SelectableLanguage } from 'components/form/LanguageSelect';
import { ApiListType } from './apiTypes';
import { FileUploadConstraints, FileUploadsWithText } from './fileTypes';
import { Coworker, Language } from './formTypes';
import { NormalizedIndex } from './stateTypes';
import { CustomerAddress } from './geolocationTypes';

/*
  Intake types
*/

export type IntakeTemplate = Pick<Intake, 'id' | 'name'>;

export type IntakeType = {
  key: string;
  name: string;
};

export type IntakeShort = Pick<
  Intake,
  'id' | 'name' | 'friendlyName' | 'hash'
> & {
  customerId: number;
};

export type UserIntake = Pick<
  Intake,
  'id' | 'hash' | 'name' | 'completed' | 'type'
> & {
  last_edit: string;
  created_at: string;
  updated_at: string;
};

export type Intake = {
  id: number;
  type: string | null;
  name: string;
  friendlyName: string;
  hash: string;
  sections: IntakeSection[];
  completed: boolean;
  customer_id: number | null;
  last_edit?: string;
  created_at?: string;
};

export type IntakeSection = {
  description: string;
  id: number;
  name: string;
  hash: string;
  steps: IntakeStep[];
};

export type IntakeStep = {
  completed: boolean;
  id: number;
  name: string;
  hash: string;
  questions: IntakeQuestion[];
  info_image?: string;
  info_header?: string;
  reference?: string;
};

export type IntakeQuestion = {
  additionalInfo?: IntakeAdditionalInfo;
  annotation?: string;
  canAddOptions?: boolean;
  conditional?: IntakeConditional;
  cropper?: boolean;
  hasOptionOverview?: boolean;
  helper: string;
  hide?: boolean;
  hideText?: string;
  id: number;
  increments?: number;
  label: string;
  locked?: boolean;
  max?: number;
  min?: number;
  name: string;
  apiList?: ApiListType;
  options?: string[] | Language[];
  optionsShown?: number;
  addingNotAllowed?: boolean;
  placeholder?: string;
  description?: string;
  completed?: boolean;
  required?: boolean | number;
  rules?: IntakeInputRule;
  type: IntakeInputTypes;
  unit?: string;
  unique?: boolean;
  disableRadius?: boolean;
  blacklist?: string[];
  uploadConstraints?: FileUploadConstraints;
  value?:
    | string
    | number
    | string[]
    | SelectedLanguage[]
    | Coworker[]
    | FileUploadsWithText
    | CustomerAddress;
  friendlyValue?: string | number;
  variant?: string;
};

export type IntakeState = {
  currentIntakeId: number;
  currentSectionId: number;
  currentStepId: number;
};

export type SelectedLanguage = {
  name: string;
  level: LanguageLevels;
};

export type IntakeAdditionalInfo = {
  header?: string;
  items?: string[];
};

export type IntakeConditional = {
  questionId: number;
  type: IntakeConditionalTypes;
  value: string | number;
};

export type IntakeInputRule = {
  pattern?: PatternRule;
  length?: LengthRule;
  size?: SizeRule;
};

export type ValidationRule = {
  message: string;
};

export type PatternRule = Pick<ValidationRule, 'message'> & {
  value: RegExp;
};

export type LengthRule = {
  min: Pick<ValidationRule, 'message'> & {
    value: number;
  };
  max: Pick<ValidationRule, 'message'> & {
    value: number;
  };
};

export type SizeRule = LengthRule;

export enum IntakeInputTypes {
  ADDRESS = 'address',
  API_SELECT = 'apiselect',
  AREA = 'textarea',
  BTNGROUP = 'buttongroup',
  COLOR = 'color',
  COUNTER = 'counter',
  CUSTOMER_ADDRESS = 'customeraddress',
  GEOLOCATION = 'geolocation', // legacy
  INVITECOWORKER = 'invitecoworker',
  LANGUAGESELECT = 'languageselect',
  MULTIPLECHOICE = 'multiplechoice',
  PHONE = 'tel',
  VAT = 'vat',
  RANGE = 'rangeslider',
  SELECT = 'select',
  SINGLECHOICE = 'singlechoice',
  TEXT = 'input',
  TEXTEDITOR = 'texteditor',
  TEXTGROUP = 'textgroup',
  TEXTGROUP_WITH_SYMBOL = 'textgroupwithsymbol',
  UPLOAD = 'upload',
  UPLOAD_MULTI = 'uploadmulti',
  UPLOAD_MULTI_WITH_TEXT = 'uploadmultiwithtext',
  UPLOAD_WITH_TEXT = 'uploadwithtext',
}

export enum IntakeConditionalTypes {
  EQUALS = 'equals',
}

export enum LanguageLevels {
  BEGINNER = 'Beginner',
  ADVANCED = 'Advanced',
  PROFESSIONAL = 'Professional',
  NATIVE = 'Native',
}

export enum BaseLanguages {
  DUTCH = 'Dutch',
  ENGLISH = 'English',
  FRENCH = 'French',
  GERMAN = 'German',
}

export const LANGUAGE_LEVELS = [
  LanguageLevels.BEGINNER,
  LanguageLevels.ADVANCED,
  LanguageLevels.PROFESSIONAL,
  LanguageLevels.NATIVE,
];

export const BASE_SELECTED_LANGUAGES: SelectableLanguage[] = [
  {
    name: BaseLanguages.DUTCH,
    level: undefined,
    selected: true,
    levels: LANGUAGE_LEVELS,
  },
  {
    name: BaseLanguages.ENGLISH,
    level: undefined,
    selected: false,
    levels: LANGUAGE_LEVELS,
  },
  {
    name: BaseLanguages.FRENCH,
    level: undefined,
    selected: false,
    levels: LANGUAGE_LEVELS,
  },
  {
    name: BaseLanguages.GERMAN,
    level: undefined,
    selected: false,
    levels: LANGUAGE_LEVELS,
  },
];

/*
  Intake types - Normalized
*/

export type NormalizedIntake = Pick<
  Intake,
  'id' | 'hash' | 'name' | 'completed' | 'type' | 'customer_id'
> & {
  sections: NormalizedIndex[];
};

export type NormalizedSection = Pick<
  IntakeSection,
  'description' | 'id' | 'name' | 'hash'
> & {
  steps: NormalizedIndex[];
};

export type NormalizedStep = Pick<
  IntakeStep,
  'completed' | 'id' | 'name' | 'hash'
> & {
  questions: NormalizedIndex[];
};

/* Stepper */
export type StepperSection = {
  id: number;
  name: string;
  completed: boolean;
  steps: StepperStep[];
};

export type StepperStep = {
  id: number;
  name: string;
  path: string;
  isStepOptional: boolean;
  remainingQuestionCount: number;
};
