import React, { useEffect } from 'react';
import 'twin.macro';

import { Routes } from 'types/routeTypes';
import { showIntercom } from 'utils/intercomHelper';
import { Body, Footnote } from 'components/typography/Typography';
import { fireEvent } from 'utils/eventHelper';

import useNavigationContext from 'hooks/context/nav-context';

const CampaignPublished: React.FC = () => {
  const { activeCustomer, activeVacancy, vacIsBooster, vacSpendEnabled } =
    useNavigationContext();

  useEffect(() => {
    if (!vacIsBooster) {
      fireEvent('ss_go_live');
    }
  }, [activeCustomer, activeVacancy, vacIsBooster]);

  const backLink = (
    <a href={Routes.ROOT} tw="my-4">
      <Footnote>Back to app</Footnote>
    </a>
  );
  return (
    <div tw="flex flex-col items-center justify-start overflow-y-auto max-h-screen">
      {backLink}
      <div tw="h-full flex flex-col my-6 prose">
        <h2 tw="text-3xl font-bold leading-3 tracking-tight text-gray-600">
          Your campaign is published 🚀
        </h2>
        <div>
          <img
            src="/gifs/campaign-published.webp"
            alt="Campaign published"
            width="480"
            height="271"
            tw="mt-0"
          />
        </div>
        <div>
          <h3 tw="text-lg font-bold tracking-tight text-gray-500">
            So what's next?
          </h3>
          <ul>
            <li>
              Our system will <b>review your campaign</b>.
            </li>
            {vacSpendEnabled && (
              <li>
                Your ads will be <b>reviewed by</b> and{' '}
                <b>published to Facebook, Instagram, ...</b>
              </li>
            )}
            <li>
              You will <b>receive an email &amp; in-app notifications</b> when
              candidates apply or send you a message.
            </li>
          </ul>
          <span tw="text-sm italic">
            Publishing your campaign usually takes a couple of minutes.
            Sometimes this could take slightly longer.
          </span>
        </div>
        {vacSpendEnabled && (
          <div>
            <h3 tw="text-lg font-bold tracking-tight text-gray-500">
              What to expect during the campaign?
            </h3>
            <ul>
              <li>
                The sympl algorithm will publish a <b>couple of ads</b> at a
                time. Together they spend approximately <b>50 euros per day</b>.
              </li>
              <li>
                The campaign is <b>self-learning &amp; optimizing</b>, bad
                performing ads will be replaced by other <b>new variants</b>.
              </li>
              <li>
                Your campaign will <b>automatically stop</b> when the total
                budget is reached. Or when you decide to manually stop the
                campaign.
              </li>
            </ul>
          </div>
        )}
        <div>
          <h3 tw="text-lg font-bold tracking-tight text-gray-500">
            How do I manage my campaign?
          </h3>
          <ul>
            <li>
              Via the <a href={Routes.CANDIDATES}>candidates view</a> you can{' '}
              <b>manage your candidates</b>: communication, CV, interview
              answers, ...
            </li>
            {vacSpendEnabled && (
              <li>
                Via the <a href={Routes.SOCIAL_MEDIA}>social media view</a> you
                can check the <b>analytics</b> for your campaign. This is also
                the place where you <b>adjust total budget</b>, as well as{' '}
                <b>pause/turn off</b> your campaign.
              </li>
            )}
            <li>
              Wanna{' '}
              <b>
                change your {vacSpendEnabled && <>ads, </>}vacancy text, ...?
              </b>{' '}
              No problem! We've got you covered. You can change all these
              things, we'll make sure your campaign assets are updated in
              real-time.
            </li>
          </ul>
        </div>
        <div>
          <h3 tw="text-lg font-bold tracking-tight text-gray-500">
            Waiting therapy
          </h3>
          <ul>
            <li>
              Make sure to check <a href={Routes.ACADEMY}>our academy</a>, it
              contains countless interesting webinars, whitepapers, checklists,
              ... that will help you increase the success of your campaign.
            </li>
          </ul>
        </div>
        <div>
          <h3 tw="text-lg font-bold tracking-tight text-gray-500">
            Questions?
          </h3>
          <Body>
            We're here for you,{' '}
            <button onClick={() => showIntercom()} tw="underline">
              contact us
            </button>{' '}
            if you need help.
          </Body>
        </div>
      </div>
      {backLink}
    </div>
  );
};

export default CampaignPublished;
