import tw, { styled } from 'twin.macro';
import React, { useCallback } from 'react';
import { useMutation } from 'hooks/sympl-mutation';

import Button from 'components/button/Button';
import { Routes } from 'types/routeTypes';
import { fireEvent } from 'utils/eventHelper';
import { useToastNotifications } from 'hooks/notificationHooks';
import AppPage from 'components/page/app-page/AppPage';
import { ToastTypes } from 'types/notificationTypes';
import {
  CREATE_VACANCY,
  SEND_FEEDBACK_NOTIFICATION,
} from 'graphql/vacancies/mutations';
import { Body, Error, Title3 } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';
import useGetStartedContext from 'hooks/context/get-started-context';
import { Check } from '@phosphor-icons/react';
import { showIntercom } from 'utils/intercomHelper';
import { Controller, useForm } from 'react-hook-form';
import Input from 'components/form/input/Input';
import { FeedbackStatus } from 'components/feedback/FeedbackWidget';
import { capitalize } from 'utils/baseHelpers';
import { useNavigate, useLocation, Link } from 'react-router-dom';

interface SendFeedbackNotificationPayload {
  vacancyId: number;
  input: {};
}

const GetStarted: React.FC = () => {
  const navigate = useNavigate();
  const state = useLocation().state as { demo: boolean };

  const { checklist } = useGetStartedContext();

  const { control, errors, handleSubmit } = useForm();

  const {
    activeCustomer,
    activeVacancy,
    vacIsBooster,
    vacSpendEnabled,
    hasVacancies,
    isAdmin,
    setActiveVacancy,
    refetchCustomer,
  } = useNavigationContext();

  const { addToast } = useToastNotifications();

  const [createVacancy, { loading: creatingVacancy }] =
    useMutation(CREATE_VACANCY);

  const [sendFeedbackNotification] = useMutation<
    { success: boolean },
    SendFeedbackNotificationPayload
  >(SEND_FEEDBACK_NOTIFICATION);

  const feedbackNotificationHandler = async (): Promise<string> => {
    if (!activeVacancy) return Promise.reject('No active vacancy');

    await sendFeedbackNotification({
      variables: {
        vacancyId: activeVacancy,
        input: {},
      },
    });

    try {
      addToast({
        title: 'Notification sent for vacancy' + ' - ' + activeVacancy,
        description: `Notification sent for vacancy - ${activeVacancy}`,
        type: ToastTypes.INFO,
      });
    } catch (e) {
      return Promise.reject(
        `Failed to send notification for vacancy: ${activeVacancy}`
      );
    }

    return Promise.resolve('');
  };

  const steps = [
    {
      title: 'Targeting 🎯',
      description:
        'Create your ideal applicant profile to target the right audience',
      item: checklist.find(({ key }) => key === 'targeting'),
      route: Routes.TARGETING,
    },
    ...(vacSpendEnabled
      ? [
          {
            title: 'Advertising 📢',
            description: 'Build your ads',
            item: checklist.find(({ key }) => key === 'advertising'),
            route: Routes.AD_EDITOR,
          },
        ]
      : []),
    {
      title: 'Vacancy Page 💼',
      description: 'Set up your vacancy page to convince visitors to apply',
      item: checklist.find(({ key }) => key === 'vacancy_page'),
      route: Routes.JOB_POSTING,
    },
    {
      title: 'Application Form 💬',
      description:
        'Build your online questionnaire so you can easily screen candidates',
      item: checklist.find(({ key }) => key === 'typeform'),
      route: Routes.INTERVIEW,
    },
  ];

  const feedbackList = checklist.filter((item) => item.feedback_status);

  const currentStep = useCallback(() => {
    if (
      feedbackList.filter(
        ({ feedback_status }) => feedback_status === FeedbackStatus.IN_PROGRESS
      ).length === feedbackList.length
    ) {
      return 1;
    }

    if (
      feedbackList.filter(
        ({ feedback_status }) => feedback_status === FeedbackStatus.APPROVED
      ).length === feedbackList.length
    ) {
      return 3;
    }

    return 2;
  }, [feedbackList]);

  const getLink = (key: string): Routes => {
    switch (key) {
      case 'targeting':
        return Routes.TARGETING;
      case 'advertising':
        return Routes.AD_EDITOR;
      case 'vacancy_page':
        return Routes.JOB_POSTING;
      case 'typeform':
        return Routes.INTERVIEW;
      default:
        return Routes.TARGETING;
    }
  };

  const onSubmit = async (data: { 'vacancy-name': string }) => {
    const response = await createVacancy({
      variables: {
        input: {
          name: data['vacancy-name'],
          customer_id: activeCustomer,
          demo: !!state?.demo,
          enable_spend: true, // TODO: make dynamic, based on input of user?
        },
      },
    });

    if (!response?.data?.createVacancy?.id) return;

    refetchCustomer();
    setActiveVacancy({
      vacancyId: response.data.createVacancy.id as number,
    });
  };

  const nextUpIndex = checklist.findIndex(({ completed }) => !completed);

  if (!hasVacancies) {
    return (
      <AppPage allowPageWithoutVacancy>
        <div tw="grid place-items-center w-full h-screen">
          <form
            tw="shadow-lg rounded-lg p-10 bg-white"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Title3 mb={0}>Which profile are your looking for? 🔍</Title3>

            <div tw="flex flex-col space-y-5 w-auto sm:w-96">
              <Controller
                id="vacancy-name"
                name="vacancy-name"
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                render={({ value, onChange }) => (
                  <div tw="mt-4">
                    <Input
                      type="text"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      disabled={creatingVacancy}
                      placeholder="e.g. Digital Marketeer"
                      autoFocus
                    />
                  </div>
                )}
              />
              {errors['vacancy-name'] && (
                <Error>{'This field is required'}</Error>
              )}

              <div>
                <Button
                  type="submit"
                  disabled={creatingVacancy}
                  loading={creatingVacancy}
                >
                  Get started &rarr;
                </Button>
              </div>
            </div>
          </form>
        </div>
      </AppPage>
    );
  }

  if (vacIsBooster) {
    return (
      <AppPage>
        <div tw="flex flex-col items-center justify-center">
          <div tw="h-full flex flex-col prose my-8">
            <h2 tw="text-4xl mt-0 font-bold tracking-tight text-gray-600">
              What's next? 📋
            </h2>
            <div tw="space-y-6">
              <StepWrapper currentStep={1} isCurrentStep={currentStep() === 1}>
                <h3 tw="text-lg font-bold tracking-tight text-gray-600 mt-0">
                  1. Campaign Preparation 🛠️
                </h3>
                <ul>
                  <li>
                    Our campaign managers will <b>prepare your campaign</b>
                  </li>
                  <li>
                    There's a bunch of things that need to be done...{' '}
                    <b>copywriting, videos, visuals, ads, targeting</b>
                    ...
                  </li>
                  <li>
                    You will <b>receive an email</b> when everything is ready to
                    be reviewed.
                  </li>
                </ul>
                <span tw="text-sm italic">
                  This process usually takes 2 to 3 business days. We'll contact
                  you if we need more information.
                </span>
                <SectionPill
                  currentStep={1}
                  isCurrentStep={currentStep() === 1}
                >
                  Current Phase
                </SectionPill>
              </StepWrapper>
              <StepWrapper currentStep={2} isCurrentStep={currentStep() === 2}>
                <h3 tw="text-lg font-bold tracking-tight text-gray-600 mt-0">
                  2. Feedback Round 👍
                </h3>
                <ul tw="mb-0">
                  <li>
                    Your campaign is <b>ready to be reviewed</b>
                  </li>
                  {currentStep() === 2 && (
                    <li>
                      Give your feedback on:
                      <div tw="flex flex-row gap-2 my-4">
                        {feedbackList
                          .filter(
                            (item) =>
                              item.feedback_status !== FeedbackStatus.APPROVED
                          )
                          .map((item, index) => (
                            <div
                              key={index}
                              tw="hover:(cursor-pointer opacity-75)"
                            >
                              <Button
                                onClick={() =>
                                  currentStep() > 1 &&
                                  navigate(getLink(item.key))
                                }
                              >
                                {capitalize(item.key.replaceAll('_', ' '))}{' '}
                                &rarr;
                              </Button>
                            </div>
                          ))}
                      </div>
                    </li>
                  )}
                  <li>
                    <b>All good?</b> Awesome! After you <b>approve</b> the
                    deliverables, we will publish your campaign.
                  </li>
                  <li>
                    <b>Almost there?</b> Leave your feedback and we'll take care
                    of it. We'll notify you when it's done.
                  </li>
                </ul>
                <SectionPill
                  currentStep={2}
                  isCurrentStep={currentStep() === 2}
                >
                  Current Phase
                </SectionPill>
              </StepWrapper>
              <StepWrapper currentStep={3} isCurrentStep={currentStep() === 3}>
                <h3 tw="text-lg font-bold tracking-tight text-gray-600 mt-2">
                  3. Go Live 🚀
                </h3>
                <ul tw="mb-0">
                  <li>
                    Your campaign will be <b>published on all channels</b>.
                  </li>
                  <li>
                    A <b>confirmation email</b> will be sent to you once
                    everything is live.
                  </li>
                </ul>
                <SectionPill
                  currentStep={3}
                  isCurrentStep={currentStep() === 3}
                >
                  Current Phase
                </SectionPill>
              </StepWrapper>
              <section tw="mt-0">
                <h3 tw="text-lg font-bold tracking-tight text-gray-600 mt-0">
                  {'Questions?'} 🙋‍♀️
                </h3>
                <Body>
                  We're here for you, you can{' '}
                  <button
                    onClick={() =>
                      (window.location.href = 'mailto:campaigns@sympl.be')
                    }
                    tw="underline"
                  >
                    {'contact your campaign manager'}
                  </button>{' '}
                  {'if you need any help.'}
                </Body>
              </section>
              {isAdmin && (
                <section tw="mt-auto">
                  <div className="isAdmin" tw="mt-8">
                    <Button
                      stretch
                      variant="secondary"
                      onClick={feedbackNotificationHandler}
                    >
                      Send feedback notification
                    </Button>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </AppPage>
    );
  }
  return (
    <AppPage>
      <div tw="shadow-lg rounded-lg p-10 w-full">
        <h1 tw="text-2xl font-semibold">
          Setup your campaign in {steps.length} easy steps
        </h1>
        <div tw="my-10">
          <ol tw="overflow-hidden">
            {steps.map((step, stepIdx) => (
              <li key={step.title} tw="relative pb-10">
                <>
                  {stepIdx < steps.length - 1 && (
                    <div
                      css={[
                        tw`absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300`,
                        step.item?.completed && tw`bg-indigo-600`,
                      ]}
                      aria-hidden="true"
                    />
                  )}
                  <Link
                    to={step.route}
                    className="group"
                    tw="relative flex items-start"
                  >
                    <span tw="flex h-8 items-center">
                      {step.item?.completed ? (
                        <span tw="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                          <Check
                            weight="bold"
                            size={16}
                            tw="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span
                          css={[
                            tw`relative z-10 flex h-8 w-8 items-center justify-center rounded-full font-medium border-2 border-gray-300 bg-white text-gray-400 group-hover:border-gray-400`,
                            stepIdx === nextUpIndex &&
                              tw`border-indigo-300 bg-indigo-50 text-indigo-400 group-hover:border-indigo-400`,
                          ]}
                        >
                          {stepIdx + 1}
                        </span>
                      )}
                    </span>
                    <div tw="ml-4 flex min-w-0 flex-col">
                      <span tw="text-lg font-medium">{step.title}</span>
                      <span tw="text-gray-500">{step.description}</span>
                      <div tw="mt-4 flex flex-row gap-1">
                        {nextUpIndex === stepIdx && (
                          <Button>Get Started &nbsp;&rarr;</Button>
                        )}
                      </div>
                    </div>
                  </Link>
                </>
              </li>
            ))}
          </ol>
        </div>
        <section tw="w-full">
          <Title3>Need help?</Title3>
          <div tw="mt-4 flex flex-row gap-2 w-full justify-between">
            <Button variant="inverted" onClick={() => showIntercom()}>
              Chat with our experts
            </Button>
            <Button
              variant="inverted"
              onClick={() => {
                fireEvent('ss_calendly_clicked');
                window.open(
                  'https://calendly.com/sympl-demo/10-minute-chat-sympl-recruiting-software',
                  '_blank'
                );
              }}
            >
              Schedule a demo
            </Button>
            <div tw="ml-auto">
              <Button
                variant="outline"
                onClick={() => navigate(Routes.PREFERENCES)}
              >
                Archive Campaign
              </Button>
            </div>
          </div>
        </section>
      </div>
    </AppPage>
  );
};

const SectionPill = styled.span<{
  currentStep: number;
  isCurrentStep: boolean;
}>`
  ${tw`hidden`}
  ${({ isCurrentStep }) =>
    isCurrentStep &&
    tw`block absolute text-xs  font-medium tracking-wide top-0 right-0 m-6 px-2 w-fit border rounded-full`};
  ${({ isCurrentStep, currentStep }) =>
    isCurrentStep &&
    (currentStep === 3
      ? tw`bg-green-100 border-green-500 text-green-500`
      : tw`bg-indigo-100 border-indigo-500 text-indigo-500`)}
`;

const StepWrapper = styled.section<{
  currentStep: number;
  isCurrentStep: boolean;
}>`
  ${tw`relative`}
  ${({ isCurrentStep }) => isCurrentStep && tw`p-6 rounded-lg`};
  ${({ isCurrentStep, currentStep }) =>
    isCurrentStep && (currentStep === 3 ? tw`bg-green-50` : tw`bg-indigo-50`)};
`;

export default GetStarted;
