const fontFamilies = [
  { key: 'Arial', label: 'Arial' },
  { key: 'Arial Black', label: 'Arial Black' },
  /**
  {
    key: 'Calibri, sans-serif',
    label: 'Calibri',
  },
  */
  {
    key: 'Comic Sans MS',
    label: 'Comic Sans MS',
  },
  { key: 'Courier', label: 'Courier' },
  { key: 'Georgia', label: 'Georgia' },
  { key: 'Helvetica', label: 'Helvetica' },
  { key: 'Impact', label: 'Impact' },
  { key: 'Lucida', label: 'Lucida' },
  { key: 'Luminari', label: 'Luminari' },
  {
    key: 'Montserrat',
    label: 'Montserrat',
  },
  //{ key: 'Palatino', label: 'Palatino' },
  //{ key: 'Tahoma', label: 'Tahoma' },
  { key: 'Roboto', label: 'Roboto' },
  {
    key: 'Times New Roman',
    label: 'Times New Roman',
  },
  { key: 'Trebuchet MS', label: 'Trebuchet MS' },
  { key: 'Verdana', label: 'Verdana' },
];

export default fontFamilies;
