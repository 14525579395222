import 'twin.macro';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'hooks/sympl-mutation';

import Label from 'components/form/Label';
import { Error, Footnote } from 'components/typography/Typography';
import type { DropdownItem } from 'components/dropdown/Dropdown';
import Input from 'components/form/input/Input';
import { getLocalAppSession } from 'utils/storageHelpers';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import Button from 'components/button/Button';
import useNavigationContext from 'hooks/context/nav-context';
import { CREATE_VACANCY } from 'graphql/vacancies/mutations';
import Checkbox from 'components/form/checkbox/Checkbox';
import { Title2 } from 'components/typography/Typography';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import CreateBrandDropdown from 'components/dropdown/create-brand-dropdown/CreateBrandDropdown';

const CreateCampaignForm: React.FC<{
  onCreate?: (vacancyId: number) => void;
  onCancel?: () => void;
  buttonText?: string;
  showTitle?: boolean;
}> = ({ onCreate, onCancel, buttonText, showTitle = true }) => {
  const { addToast } = useToastNotifications();

  const { errors, control, handleSubmit, reset: resetForm } = useForm();

  const { activeCustomer, setActiveVacancy, hasVacancies } =
    useNavigationContext();

  const formRef = useRef<HTMLFormElement>(null);

  const [createVacancy, { loading }] = useMutation(CREATE_VACANCY);

  const [formError, setFormError] = useState<string>();

  const onSubmit = (data: {
    'vacancy-name': string;
    'vacancy-enable-spend'?: boolean; // TODO: make required
    'vacancy-previous'?: number;
    'vacancy-brand'?: number;
  }) => {
    const customer = activeCustomer ?? getLocalAppSession()?.activeCustomer;

    // Error flow
    if (!data['vacancy-name']) {
      addToast({
        description:
          'We were unable to create a new vacancy, please try again. Please contact us if this problem persists!',
        type: ToastTypes.ERROR,
      });

      return;
    }

    // Success flow
    createVacancy({
      variables: {
        input: {
          name: data['vacancy-name'],
          customer_id: customer,
          vacancy_id:
            data['vacancy-previous'] &&
            data['vacancy-previous'].toString() !== ''
              ? data['vacancy-previous']
              : undefined,
          enable_spend: data['vacancy-enable-spend'] ?? true, // TODO: remove fallback when switched to required
          brand_id: data['vacancy-brand'] ?? undefined,
        },
      },
    })
      .then(async (response: any) => {
        resetForm();

        setFormError(undefined);

        addToast({
          description: 'We successfully created your new vacancy!',
          type: ToastTypes.SUCCESS,
        });

        if (response?.data?.createVacancy?.id && customer !== undefined) {
          const vacancyId = response.data.createVacancy.id as number;

          setActiveVacancy({
            vacancyId,
          });

          onCreate?.(vacancyId);
        }
      })
      .catch(() => {
        resetForm();
        setFormError('Something went wrong. Please refresh and try again.');
      });
  };

  return (
    <div tw="w-full flex flex-col justify-center items-center">
      <form
        id="create-vacancy-form"
        ref={formRef}
        tw="min-w-[25rem]"
        onSubmit={handleSubmit(onSubmit)}
      >
        {showTitle && (
          <Title2 tw="text-xs tracking-wider text-gray-500">
            Start a new campaign
          </Title2>
        )}

        <div tw="mt-4">
          <Label htmlFor="vacancy-name">Function title*</Label>
          <Controller
            id="vacancy-name"
            name="vacancy-name"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <Input
                autoFocus
                type="text"
                id="vacancy-name"
                name="vacancy-name"
                value={value}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onChange(e)}
              />
            )}
          />
          <Error>
            {errors['vacancy-name'] && 'Please provide a valid function title'}
          </Error>
        </div>

        <div tw="mt-4">
          <Label htmlFor="vacancy-brand">Brand</Label>

          <Controller
            id="vacancy-brand"
            name="vacancy-brand"
            control={control}
            defaultValue=""
            render={({ onChange, value }: { onChange: any; value: any }) => (
              <CreateBrandDropdown
                value={value}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </div>

        {hasVacancies && (
          <div tw="mt-4">
            <Label htmlFor="vacancy-previous">
              Base on a previous campaign
            </Label>
            <Controller
              id="vacancy-previous"
              name="vacancy-previous"
              control={control}
              defaultValue=""
              render={({ onChange }: { onChange: any }) => (
                <ApiDropdown
                  enableSearch
                  mode="key"
                  type="vacancies"
                  onChange={(value) => onChange((value as DropdownItem)?.key)}
                />
              )}
            />
          </div>
        )}

        <div tw="mt-4">
          <Controller
            id="vacancy-enable-spend"
            name="vacancy-enable-spend"
            control={control}
            defaultValue={true}
            render={({ onChange, value }: { onChange: any; value: any }) => (
              <div tw="flex gap-4 items-center">
                <Checkbox
                  id="spendEnabled"
                  name="spendEnabled"
                  checked={value}
                  onCheck={onChange}
                />
                <Label htmlFor="vacancy-enable-spend">
                  I want to advertise on social media
                </Label>
              </div>
            )}
          />
        </div>
        <div tw="flex justify-center mt-3 mb-4">
          {formError && <Error>{formError}</Error>}
        </div>
        <div tw="flex flex-row items-center">
          {onCancel && (
            <div tw="mr-2">
              <Button variant="inverted" disabled={loading} onClick={onCancel}>
                Cancel
              </Button>
            </div>
          )}
          <Button type="submit" loading={loading} disabled={loading} stretch>
            {buttonText}
          </Button>
        </div>
        <div tw="mt-2">
          <Footnote>* fields are required</Footnote>
        </div>
      </form>
    </div>
  );
};

export default CreateCampaignForm;
