import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { IntakeQuestion } from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';

import Counter from 'components/form/counter/Counter';

const IntakeCounter: React.FC<{
  question: IntakeQuestion;
  defaultValue?: number;
}> = ({ question, defaultValue }) => {
  const { control, errors } = useFormContext();

  const id = question.id.toString();
  const rules = questionRules(question);

  // Counter config for intake
  const increments = question?.increments ?? 1;
  const min = question?.min;
  const max = question?.max;
  const unit = question?.unit;

  return (
    <Controller
      id={id}
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value }: { onChange: any; value: any }) => (
        <Counter
          id={id}
          min={min}
          max={max}
          unit={unit}
          increments={increments}
          value={value ?? defaultValue ?? ''}
          ariaInvalid={errors[id] !== undefined}
          onChange={(val) => onChange(val)}
        />
      )}
    />
  );
};

export default IntakeCounter;
