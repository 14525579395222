import React, { createContext, useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  AppNotification,
  NotificationStatus,
  ToastTypes,
} from 'types/notificationTypes';
import { AppEvents } from 'types/appEvents';
import {
  extendUserSession,
  getCurrentAuthToken,
  isTokenExpired,
  resetCurrentAuthToken,
} from 'utils/authHelpers';

import { Routes } from 'types/routeTypes';
import toast, { Toast, Toaster } from 'react-hot-toast';
import ToastPopup from 'components/toasts/toast-popup/ToastPopup';
import { fireEvent } from 'utils/eventHelper';

export interface CustomToast
  extends Omit<
    Toast,
    | 'type'
    | 'id'
    | 'createdAt'
    | 'message'
    | 'pauseDuration'
    | 'ariaProps'
    | 'visible'
  > {
  id?: string;
  title?: string;
  description: string;
  message?: string;
  type: ToastTypes;
  date?: Date;
  cta?: {
    route: Routes;
    text: string;
  };
  pauseDuration?: number;
  ariaProps?: {
    'aria-live': 'polite' | 'assertive';
    role: 'alert' | 'status';
  };
  visible?: boolean;
  customIcon?: React.ReactNode;
  customAction?: () => void;
}

export type NotificationContextTypes = {
  notifications: AppNotification[];
  addToast: (notification: CustomToast) => void;
  removeNotification: (notificationId: string) => void;
  showNotificationTab: boolean;
  toggleNotificationTab: (show: boolean) => void;
  markAsRead: (notificationId: string) => void;
  updateNotifications: (notifications: AppNotification[]) => void;
};

export const NotificationContext = createContext<NotificationContextTypes>(
  undefined!
);

export const AppProvider: React.FC<React.ReactNode> = ({ children }) => {
  const JWT_CHECK_INTERVAL_IN_SECONDS = 30;

  const [networkErrors, setNetworkErrors] = useState<
    { id: string; response: Response }[]
  >([]);
  const [notifications, updateNotifications] = useState<AppNotification[]>([]);
  const [showNotificationTab, toggleNotificationTab] = useState(false);

  /**
  const closeToast = (id: string) => {
    toast.dismiss(id);
    toast.remove(id);
  };
  */

  /**
   * Removes a notification from the notifications list
   * @param id
   */
  const removeNotification = (id: string) => {
    const tempNotificationsArray: AppNotification[] = [...notifications];

    const toRemoveNotificationIndex = tempNotificationsArray.findIndex(
      (notification) => notification.id === id
    );

    tempNotificationsArray.splice(toRemoveNotificationIndex, 1);
    updateNotifications(tempNotificationsArray);
  };

  /**
   * Changes the status of the selected notification to 'READ'
   * @param id
   */
  const markAsRead = (id: string) => {
    const tempNotificationsArray: AppNotification[] = [...notifications];
    const readNotification = tempNotificationsArray.findIndex(
      (notification) => notification.id === id
    );

    const Notification = tempNotificationsArray[readNotification];

    if (Notification.status !== NotificationStatus.READ) {
      Notification.status = NotificationStatus.READ;
      tempNotificationsArray[readNotification] = Notification;
      updateNotifications(tempNotificationsArray);
    }
  };

  const addToast = (toastContent: CustomToast) => {
    if (!toastContent.id) toastContent.id = uuid();

    toast.custom(() => (
      <ToastPopup
        title={toastContent.title}
        description={toastContent.description}
        type={toastContent.type}
        id={toastContent.id}
        date={toastContent.date}
        key={toastContent.id}
        customAction={toastContent.customAction}
        cta={toastContent.cta}
        message={toastContent.message}
        pauseDuration={toastContent.pauseDuration ?? 0}
        ariaProps={toastContent.ariaProps}
        visible={toastContent.visible}
      />
    ));
  };

  useEffect(() => {
    window.addEventListener(AppEvents.NETWORK_ERROR, handleNetworkError, false);
    return () => {
      window.removeEventListener(AppEvents.NETWORK_ERROR, handleNetworkError);
    };
  });

  useEffect(() => {
    window.addEventListener(
      AppEvents.UNAUTHENTICATED,
      handleUnauthorizedError,
      false
    );
    return () => {
      window.removeEventListener(
        AppEvents.UNAUTHENTICATED,
        handleUnauthorizedError
      );
    };
  });

  const handleUnauthorizedError = useCallback(() => {
    addToast({
      type: ToastTypes.INFO,
      id: uuid(),
      date: new Date(),
      title: 'Your session has expired.',
      description: 'Please login to continue.',
      visible: true,
      pauseDuration: 0,
      ariaProps: {
        'aria-live': 'polite',
        role: 'alert',
      },
    });
  }, [addToast]);

  const handleNetworkError = useCallback(
    async (e: any) => {
      const authenticated = getCurrentAuthToken('preserve');

      // Don't display network errors when redirecting to login
      if (!authenticated) return;

      const id = uuid();
      const response = (e as CustomEvent)?.detail as Response;

      const message = await response
        .clone()
        .json()
        .then((o) => {
          return o.message;
        });

      const success =
        (await response
          .clone()
          .json()
          .then((o) => {
            return o.success;
          })) ?? false;

      const type = success ? ToastTypes.SUCCESS : ToastTypes.ERROR;
      const status = response?.status ?? 500;

      const description = message;
      const cta =
        status === 470
          ? {
              text: 'Upgrade now',
              route: Routes.SUBSCRIPTION_SETTINGS,
            }
          : undefined;

      const customAction = () => {
        console.error({
          Type: 'Network error',
          status: response.status,
          body: response.body,
          statusText: response.statusText,
          url: response.url,
        });
      };
      // Create a new network error using the provided response
      setNetworkErrors([
        ...networkErrors,
        {
          id,
          response,
        },
      ]);
      fireEvent(AppEvents.NETWORK_ERROR, response);
      // Show a new toast notification for the given response
      addToast({
        type,
        id,
        date: new Date(),
        description,
        cta,
        customAction,
        pauseDuration: 0,
        ariaProps: {
          'aria-live': 'polite',
          role: 'alert',
        },
        visible: true,
      });
    },
    [networkErrors, addToast]
  );

  const jwtExpirationHandler = useCallback(async () => {
    // Get the current token without mutating it
    const token = getCurrentAuthToken('preserve');

    // Ignore empty tokens to prevent spamming the user with toasts
    const isInvalidToken = token && isTokenExpired(token);

    // Check if the token is still active
    if (!isInvalidToken) return;

    // Try and refresh the token
    try {
      await extendUserSession();
      // Skip remaining logic if the token has been refreshed
      return;
    } catch (_) {}

    // Start the unauthorized flow
    dispatchEvent(new CustomEvent(AppEvents.UNAUTHENTICATED));
    resetCurrentAuthToken();

    // Redirect the user back to the login page
    window.location.replace(Routes.LOGIN);
  }, []);

  useEffect(() => {
    // Handles expired toasts and network errors
    const interval = setInterval(
      jwtExpirationHandler,
      JWT_CHECK_INTERVAL_IN_SECONDS * 1000
    );
    return () => clearInterval(interval);
  }, [jwtExpirationHandler]);

  return (
    <NotificationContext.Provider
      value={{
        addToast,
        notifications,
        removeNotification,
        showNotificationTab,
        toggleNotificationTab,
        markAsRead,
        updateNotifications,
      }}
    >
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={1}
        toastOptions={{
          className: 'sympl-toast',
          duration: 3000,
        }}
      />

      {children}
    </NotificationContext.Provider>
  );
};
