import { OperationVariables } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types';
import { useMutation as useApolloMutation } from '@apollo/client';
import { getLocalAppSession } from 'utils/storageHelpers';

/**
 * Will add the current active customer to a custom header
 * @param query
 * @param options
 */
export function useMutation<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & {
    abortController?: AbortController;
  }
): MutationTuple<TData, TVariables> {
  const customer = getLocalAppSession()?.activeCustomer;
  const abortController = options?.abortController;

  const context = options?.context;
  const headers = context?.headers;
  const optionsWithAdditionalHeaders = {
    ...options,
    context: {
      ...context,
      headers: { ...headers, 'Active-Customer': customer },
    },
    signal: abortController?.signal,
  };

  return useApolloMutation<TData, TVariables>(
    query,
    optionsWithAdditionalHeaders
  );
}
