import React from 'react';

import { RouteGuards } from 'types/routeTypes';
import GuardedRoute from 'routing/GuardedRoute';
import CandidateDetail from './CandidateDetail';
import { CandidateProvider } from 'context/CandidateContext';
import { Route, Routes } from 'react-router-dom';

const ProceduresRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path=":procedure"
        element={
          <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
            <CandidateProvider>
              <CandidateDetail />
            </CandidateProvider>
          </GuardedRoute>
        }
      />
    </Routes>
  );
};

export default ProceduresRouter;
