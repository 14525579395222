import 'twin.macro';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'hooks/sympl-mutation';
import { SealCheck, XCircle } from '@phosphor-icons/react';

import Spinner from 'components/loading/spinner';
import { Routes } from 'types/routeTypes';
import { activateUserSession } from 'utils/authHelpers';
import { wait } from 'utils/baseHelpers';
import { CONFIRM_EMAIL } from 'graphql/auth/mutations';
import { Body } from 'components/typography/Typography';
import { useNavigate } from 'react-router-dom';

interface ConfirmEmailPayload {
  input: { token: string };
}

export const ConfirmEmail: React.FC = () => {
  const navigate = useNavigate();

  const SYMPL_COLOR = '#FF0054';
  const ICON_SIZE = 30;

  const [confirmEmail] = useMutation<undefined, ConfirmEmailPayload>(
    CONFIRM_EMAIL
  );

  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');

    if (token) {
      confirmEmail({
        variables: {
          input: {
            token,
          },
        },
      })
        .then(async () => {
          setSuccess(true);
          await wait(2.5);

          activateUserSession({
            token: '',
            trackLogRocket: false,
          }).then(() => navigate(Routes.ROOT, { replace: true }));
        })
        .catch(() => setError(true));
    }
  }, [navigate, confirmEmail, setSuccess, setError]);

  return (
    <div tw="w-full h-full flex justify-center items-center text-lg">
      {isSuccess ? (
        <div tw="flex flex-col items-center">
          <SealCheck weight="fill" color={SYMPL_COLOR} size={ICON_SIZE} />
          <Body isBlock={false}>
            Email was successfully confirmed, redirecting now...
          </Body>
        </div>
      ) : error ? (
        <div tw="flex flex-col items-center">
          <XCircle weight="fill" color="#FF0054" size={ICON_SIZE} />
          <Body isBlock={false}>
            Oops something went wrong, try again later...
          </Body>
        </div>
      ) : (
        <div tw="flex flex-col items-center">
          <Spinner color={SYMPL_COLOR} />
          <Body isBlock={false}>Confirming e-mail...</Body>
        </div>
      )}
    </div>
  );
};
