import React from 'react';

import { RouteGuards } from 'types/routeTypes';
import GuardedRoute from 'routing/GuardedRoute';
import LeadDetail from './LeadDetail';
import { Routes, Route } from 'react-router-dom';

const LeadDetailRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path=":lead"
        element={
          <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
            <LeadDetail />
          </GuardedRoute>
        }
      />
    </Routes>
  );
};

export default LeadDetailRouter;
