import React from 'react';

const Terms = () => (
  <iframe
    width="100%"
    height="100%"
    src="https://gosympl.com/terms-and-conditions"
  ></iframe>
);

export default Terms;
