import { FeatureKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';

export const defaultFeatureSettings: FeatureKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig(),
  image: '',
  contentAlignment: 'center',
  imagePlacement: 'right',
};
