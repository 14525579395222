import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import tw, { styled } from 'twin.macro';

import { Coworker } from 'types/formTypes';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import IntakeInitInput from 'components/form/input/IntakeInitInput';
import { Modal } from 'components/page/app-page/Modal';
import { MAIL_REGEX } from 'utils/regexHelpers';
import Checkbox from 'components/form/checkbox/Checkbox';

interface IModalCoWorker {
  isShown: boolean;
  onModalClose: () => void;
  currentCoWorker?: Coworker;
  isEditMode: boolean;
  onSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSaveLoading?: boolean;
  includeLeadRecruiter?: boolean;
}
const ModalCoWorker: React.FC<IModalCoWorker> = ({
  isShown,
  onModalClose,
  currentCoWorker,
  isEditMode,
  onSave,
  onSaveLoading,
  includeLeadRecruiter = true,
}: IModalCoWorker) => {
  const { errors, control } = useFormContext();
  const stringValidator = (value: string) => !!value.trim();
  const emailValidator = (value: string) =>
    stringValidator(value) && !!value.match(MAIL_REGEX);

  return (
    <Modal show={isShown} onClose={onModalClose}>
      <CoworkerControlsWrapper>
        <div tw="flex space-x-4">
          <IntakeInitInput
            label={'Firstname'}
            errorMessage={'This field is required'}
            hasError={errors['firstname']}
          >
            <Controller
              id="firstname"
              name="firstname"
              control={control}
              defaultValue={currentCoWorker?.firstname ?? ''}
              rules={{
                validate: stringValidator,
              }}
              render={({ value, onChange }) => (
                <Input
                  id="firstname"
                  name="firstname"
                  placeholder="Jane"
                  value={value}
                  ariaInvalid={errors['firstname']}
                  onChange={onChange}
                />
              )}
            />
          </IntakeInitInput>
          <IntakeInitInput
            label={'Lastname'}
            errorMessage={'This field is required'}
            hasError={errors['lastname']}
          >
            <Controller
              id="lastname"
              name="lastname"
              control={control}
              defaultValue={currentCoWorker?.lastname ?? ''}
              rules={{
                validate: stringValidator,
              }}
              render={({ value, onChange }) => (
                <Input
                  id="lastname"
                  name="lastname"
                  placeholder="Doe"
                  value={value}
                  ariaInvalid={errors['lastname']}
                  onChange={onChange}
                />
              )}
            />
          </IntakeInitInput>
        </div>

        <IntakeInitInput
          label={'Email'}
          errorMessage={'This field is required or invalid'}
          hasError={errors['email']}
        >
          <Controller
            id="email"
            name="email"
            control={control}
            defaultValue={currentCoWorker?.email ?? ''}
            rules={{
              validate: emailValidator,
            }}
            render={({ value, onChange }) => (
              <Input
                id="email"
                name="email"
                placeholder="jane.doe@sympl.be"
                value={value}
                ariaInvalid={errors['email']}
                onChange={onChange}
              />
            )}
          />
        </IntakeInitInput>

        <div tw="flex space-x-4">
          <div tw="grow">
            <IntakeInitInput
              label={'Function'}
              errorMessage={'This field is required'}
              hasError={errors['function']}
            >
              <Controller
                id="function"
                name="function"
                control={control}
                defaultValue={currentCoWorker?.function ?? ''}
                rules={{
                  validate: stringValidator,
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="function"
                    name="function"
                    placeholder="Recruiter"
                    value={value}
                    ariaInvalid={errors['function']}
                    onChange={onChange}
                  />
                )}
              />
            </IntakeInitInput>
          </div>
          {includeLeadRecruiter && (
            <div tw="self-center">
              <IntakeInitInput
                label={'Lead Recruiter'}
                errorMessage={'This field is required'}
                hasError={errors['isLeadRecruiter']}
              >
                <Controller
                  id="isLeadRecruiter"
                  name="isLeadRecruiter"
                  control={control}
                  defaultValue={currentCoWorker?.isLeadRecruiter ?? false}
                  render={({ value, onChange }) => (
                    <div tw="mx-auto">
                      <Checkbox
                        id="isLeadRecruiter"
                        name="isLeadRecruiter"
                        placeholder="Recruiter"
                        checked={value}
                        ariaInvalid={errors['isLeadRecruiter']}
                        onCheck={onChange}
                      />
                    </div>
                  )}
                />
              </IntakeInitInput>
            </div>
          )}
        </div>
        <div tw="pt-4">
          <Button
            variant="gray"
            onClick={onSave}
            loading={onSaveLoading}
            stretch
          >
            {isEditMode ? 'Edit' : 'Add'}
          </Button>
        </div>
      </CoworkerControlsWrapper>
    </Modal>
  );
};
const CoworkerControlsWrapper = styled.div(() => [
  tw`rounded-md pt-10 pb-4 space-y-2`,
]);

export default ModalCoWorker;
