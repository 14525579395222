export type RegisterPageConfig = typeof REGISTER_ACADEMY_COPY;

export const REGISTER_COPY = {
  title: 'Nice to meet you! 👋',
  optionalFormFields: [],
  cta: 'Sign Up',
  next: 'Next Up?',
  bulletPoints: [
    '🎯 Target your ideal applicants',
    '✏️ Set up your vacancy page',
    '📝 Build your online questionnaire',
    '🔨 Create your set of ads',
  ],
};

export const REGISTER_ACADEMY_COPY = {
  title: 'Welcome to our sympl academy!',
  subtitle: 'Register for free & get access to all our resources.',
  optionalFormFields: ['phone'],
  cta: 'Get access to our resources',
  next: 'Learn everything about ...',
  bulletPoints: [
    '📈 Data-driven recruitment',
    '📱 Recruiting on Facebook & Instagram',
    '💻 Or just online recruitment in general!',
  ],
};

export const REGISTER_SEA = {
  title: 'Easy-to-use software to recruit more candidates',
  subtitle:
    "Yay, you're almost on board! Register below for your 14-day free trial and discover how easy it is to attract candidates online with our recruitment tool.",
  optionalFormFields: [],
  cta: 'Sign Up',
  next: 'Next steps to launch your first campaign?',
  bulletPoints: [
    '1. Target your ideal applicants',
    '2. Set up your vacancy page',
    '3. Build your online questionnaire',
    '4. Create your set of ads',
    '5. Launch your campaign!',
  ],
};
