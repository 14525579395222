import { gql } from '@apollo/client';

export const GET_LEADS = gql`
  query getLeads($vacancyId: ID!) {
    leads(id: $vacancyId)
      @rest(type: "[Lead]", path: "/v4/vacancies/{args.id}/leads") {
      id
      procedure_id
      source
      email
      phone
      firstname
      lastname
      captured_at
    }
  }
`;

export const GET_LEAD = gql`
  query getLead($leadId: ID!) {
    lead(id: $leadId) @rest(type: "Lead", path: "/v4/leads/{args.id}") {
      id
      procedure_id
      source
      email
      phone
      firstname
      lastname
      captured_at
    }
  }
`;
