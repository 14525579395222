import React from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { Routes } from 'types/routeTypes';
import { useFormContext } from 'react-hook-form';
import { getIntakeResults } from 'utils/formHelpers';
import { NotificationTypes } from 'types/baseTypes';
import { IntakeFormResults } from 'types/formTypes';
import Img from 'components/image/Image';
import useNavigationContext from 'hooks/context/nav-context';
import useIntakeCoreContext from 'hooks/context/intakes-context';
import useIntakeStepContext from 'hooks/context/intake-step-context';
import { IntakeStepNumberedQuestions } from 'types/intakes/steps';
import StepperOverview from 'components/form/stepper-overview/StepperOverview';

interface IntakeLayoutProps {
  id: string;
  children: React.ReactNode;
  step?: IntakeStepNumberedQuestions;
  image?: string;
  biggerImage?: boolean;
  isStepperShown?: boolean;
  onStepperClose?: () => void;
}

const IntakeLayout: React.FC<IntakeLayoutProps> = ({
  id,
  children,
  step,
  image,
  isStepperShown,
  biggerImage,
  onStepperClose,
}) => {
  const formContext = useFormContext();
  const getValues = formContext ? formContext.getValues : undefined;
  const baseImgUrl = import.meta.env.VITE_DO_SPACES_URL;

  const { hasVacancies } = useNavigationContext();

  const {
    skippedSteps,
    currentStepId,
    currentSectionId,
    currentIntakeId,
    submitIntakeStep,
    handleAddNotification,
    handleSetSkippedSteps,
  } = useIntakeCoreContext();

  const { questions: intakeQuestions } = useIntakeStepContext();

  const intakeExitHandler = () => {
    // Add step id to skipped steps
    if (!skippedSteps.includes(currentStepId))
      handleSetSkippedSteps(currentStepId);

    const intakeResults = getIntakeResults(
      getValues?.() as FormData,
      {
        currentIntakeId,
        currentSectionId,
        currentStepId,
      },
      intakeQuestions
    );

    if (intakeResults) submitIntake(intakeResults);
  };

  const submitIntake = (data: IntakeFormResults) => {
    // Submit the intake form with the current form state
    submitIntakeStep(data).catch(() => {
      handleAddNotification({
        type: NotificationTypes.ERROR,
        message:
          'Something went wrong while submitting previous step, please try again.',
      });
    });
  };

  return (
    <div id={id} tw="h-full w-full flex justify-center relative">
      {isStepperShown && <StepperOverview onClose={() => onStepperClose?.()} />}
      {hasVacancies && (
        <Link
          to={Routes.ROOT}
          onClick={intakeExitHandler}
          tw="text-sm text-gray-700 font-medium cursor-pointer absolute bottom-6 left-6 sm:top-6"
        >
          Back to app
        </Link>
      )}
      <VisualWrapper tw="hidden sm:(flex items-center justify-center bg-gray-50)">
        {image && (
          <Img
            style={{
              width: '90%',
              maxWidth: biggerImage ? '600px' : '400px',
              borderRadius: '20px',
            }}
            src={baseImgUrl + image}
            alt={step ? step.name : ''}
          />
        )}
      </VisualWrapper>
      <IntakeWrapper tw="bg-white">
        <div tw="h-full w-full">{children}</div>
      </IntakeWrapper>
    </div>
  );
};

const IntakeWrapper = styled.div(
  tw`max-h-full max-w-full flex-[70%] xl:max-w-4xl`
);

const VisualWrapper = styled.div(tw`hidden flex-[30%] xl:flex`);

export default IntakeLayout;
