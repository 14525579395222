import React, { useEffect, useMemo, useState } from 'react';
import { swapNewlines } from 'utils/baseHelpers';
import { AdVariant } from 'types/adEditorTypes';

export const useAdInput = (
  adText: string | undefined,
  setCopy: (field: keyof AdVariant, value: string) => void
) => {
  const [editMode, setEditMode] = useState(false);

  const adTextHtml = useMemo(() => {
    if (!adText) return '';
    return swapNewlines(adText);
  }, [adText]);

  const [adTextEditorValue, setAdTextEditorValue] = useState(
    swapNewlines(adTextHtml)
  );

  const updateAdTextCopy = (value: string) => {
    setAdTextEditorValue(value);
  };

  const adTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    updateAdTextCopy(value);
  };

  const adTextEmojiChangeHandler = (value?: string) => {
    if (value?.constructor === String) updateAdTextCopy(value);
  };

  const adTextUpdateHandler = () => {
    setCopy('text', adTextEditorValue);
    setEditMode(false);
  };

  useEffect(() => {
    setAdTextEditorValue(swapNewlines(adTextHtml));
  }, [adTextHtml]);

  return {
    editMode,
    setEditMode,
    adTextHtml,
    adTextEditorValue,
    adTextChangeHandler,
    adTextEmojiChangeHandler,
    adTextUpdateHandler,
  };
};
