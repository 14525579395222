import React from 'react';
import 'twin.macro';
import tw from 'twin.macro';
import { styled } from 'twin.macro';

interface IDivider {
  text?: string;
  dark?: boolean;
}

const Divider: React.FC<IDivider> = ({ text = 'or', dark = false }) => {
  return (
    <div tw="flex items-center mt-8 mb-4">
      {/* <div tw={`grow border-t ${lineColor}`}></div>
      <span tw={`mx-2 ${textColor} text-sm`}>{text}</span>
      <div tw={`grow border-t ${lineColor}`}></div> */}
      <Line dark={dark} />
      <TextWrapper dark={dark}>{text}</TextWrapper>
      <Line dark={dark} />
    </div>
  );
};

const Line = styled.div<{ dark: boolean }>`
  ${tw`grow border-t border-gray-200`}
  ${({ dark }) => dark && tw`border-gray-300`}
`;

const TextWrapper = styled.span<{ dark: boolean }>`
  ${tw`mx-2 text-sm text-gray-400`}
  ${({ dark }) => dark && tw`text-gray-500`}
`;

export default Divider;
