import tw from 'twin.macro';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import TextEditor from 'components/form/text-editor/TextEditor';
import useDefocusHandler from 'hooks/defocus';
import { wait } from 'utils/baseHelpers';
import { FloatingButton } from './VacancyEditorColor';
import ColorPicker from 'components/form/ColorPicker';
import useVacancyContext from 'hooks/context/vacancy-context';
import { VacancyCopyKeyname } from 'types/vacancyTypes';
import { PencilSimple, TextAUnderline } from '@phosphor-icons/react';
import { getInitialPrompt } from 'utils/vacancy-generator/vacancyHelpers';
import { VacancyAIPromptPayload } from 'utils/vacancy-generator/prompts';
import useNavigationContext from 'hooks/context/nav-context';
import { getFormattedText } from 'utils/openAiHelpers';

const VacancyContentInput = ({
  id,
  keyName,
  title,
  text,
  cta,
  vacancyAIPayload,
  onTitleChange,
  onTextChange,
  onCtaChange,
}: {
  id?: string;
  keyName?: VacancyCopyKeyname;
  title?: string;
  text?: string;
  cta?: string;
  vacancyAIPayload?: VacancyAIPromptPayload;
  onTitleChange: (value: string | null) => void;
  onTextChange: (value: string) => void;
  onCtaChange?: (value: string) => void;
}) => {
  const { generatingFields, vacancyData, setVacancyData } = useVacancyContext();

  const { activeVacancy } = useNavigationContext();

  const TextEditorRef = useRef<HTMLDivElement>(null);
  const ColorRef = useRef<HTMLDivElement>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [showTxtColors, setShowTxtColors] = useState(false);

  // TODO: revise
  useDefocusHandler(
    TextEditorRef,
    () => wait(0.05).then(() => setIsEditing(false)) // To prevent the onChange event of the TextEditor to not be triggered
  );
  useDefocusHandler(ColorRef, () => setShowTxtColors(false));

  const isGenerating = useMemo(
    () => !!keyName && generatingFields.includes(keyName),
    [generatingFields, keyName]
  );

  useEffect(() => {
    if (isGenerating && isEditing) setIsEditing(false);
  }, [isEditing, isGenerating]);

  const handleColorWrapperClick = (element: HTMLDivElement) => {
    if (element.closest('#txtColor') === null) setShowTxtColors(!showTxtColors);
  };

  const getSuggestedAction = () => {
    switch (keyName) {
      case 'vac_text_todo':
        return [
          {
            icon: <PencilSimple weight="fill" />,
            prompt: 'Write about daily tasks & job responsibilities',
            instantPrompt: true,
            highlighted: true,
          },
        ];
      case 'vac_text_expectations':
        return [
          {
            icon: <PencilSimple weight="fill" />,
            prompt: 'Write about required experience, education, skills',
            instantPrompt: true,
            highlighted: true,
          },
        ];
      case 'vac_text_offer':
        return [
          {
            icon: <PencilSimple weight="fill" />,
            prompt: 'Write about the offer, benefits & perks',
            instantPrompt: true,
            highlighted: true,
          },
        ];
      default:
        return [];
    }
  };

  const onFinished = async (value: string, thread: string) => {
    if (!activeVacancy) {
      console.error('No vacancy set');
      return;
    }
    return getFormattedText(value, activeVacancy, keyName ?? 'default', thread);
  };

  return (
    <>
      <article id={id} className="card">
        <section>
          <div tw="flex">
            <h2
              className="section-heading highlight-white"
              contentEditable="true"
              suppressContentEditableWarning={true}
              onBlur={(e) => onTitleChange(e.currentTarget.textContent)}
              onPaste={(e) => {
                // Cancel paste
                e.preventDefault();

                // Get text representation of clipboard
                const text = e.clipboardData.getData('text/plain');

                // Insert text manually
                onTitleChange(text);
              }}
            >
              {title}
            </h2>
            <FloatingButton
              tw="absolute top-0 right-0 -mt-4 sm:-mr-4"
              loading={isGenerating}
              onClick={(e) => {
                if (!isGenerating)
                  handleColorWrapperClick(e.target as HTMLDivElement);
              }}
              ref={ColorRef}
            >
              <TextAUnderline weight="bold" tw="text-xl" />
              {showTxtColors && (
                <ColorPicker
                  id="txtColor"
                  onChange={(color) =>
                    setVacancyData({
                      ...vacancyData!,
                      tertiary_color: color,
                    })
                  }
                  value={vacancyData?.tertiary_color}
                  variant={'barebones'}
                />
              )}
            </FloatingButton>
          </div>

          {isEditing ? (
            <div tw="my-6" ref={TextEditorRef}>
              <TextEditor
                identifier={keyName ?? 'vacancy_copy'}
                defaultValue={text ?? ''}
                onChange={onTextChange}
                aiEnabled={!!vacancyAIPayload}
                // TODO: add location
                initialPrompt={
                  vacancyAIPayload
                    ? getInitialPrompt(keyName ?? 'default', vacancyAIPayload)
                    : undefined
                }
                onAIGenFinished={onFinished}
                aiSuggestedActions={[...getSuggestedAction()]}
              />
            </div>
          ) : (
            <div
              css={[tw`min-h-[7rem]`, isGenerating && tw`cursor-wait`]}
              className={`${!isGenerating && 'highlight-white'}`}
              dangerouslySetInnerHTML={{
                __html: text ?? '',
              }}
              onClick={() => setIsEditing(!isGenerating)}
            ></div>
          )}
        </section>
      </article>

      {cta && (
        <section className="form-link">
          <a
            className="highlight-white"
            href="?"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onBlur={(e) => onCtaChange?.(e.currentTarget.textContent ?? '')}
          >
            <span>{cta}</span>
          </a>
        </section>
      )}
    </>
  );
};

export default VacancyContentInput;
