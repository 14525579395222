import { IntakeConfig, IntakeFormResults } from 'types/formTypes';
import {
  Intake,
  IntakeTemplate,
  IntakeType,
  UserIntake,
} from 'types/intakeTypes';
import { intakeApiError } from 'utils/apiHelpers';
import { get, post } from '../api';

export const fetchIntakeTemplates = async (): Promise<IntakeTemplate[]> => {
  try {
    const { data } = await get<any>(`/v4/intakes/templates`);
    return data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const fetchIntakes = async (): Promise<UserIntake[]> => {
  try {
    const { data } = await get<any>('/v4/intakes');
    return data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const fetchIntakeTypes = async (): Promise<IntakeType[]> => {
  try {
    const { data } = await get<any>(`/v4/intakes/types`);
    return data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const fetchIntakeByHash = async (hash: string): Promise<Intake> => {
  try {
    const { data } = await get<Intake>(`/v4/intakes/${hash}`);
    return data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const storeIntake = async (data: IntakeConfig): Promise<Intake> => {
  try {
    const result = (await post<Intake>(`/v4/intakes`, {
      create_invoice: data.createInvoice,
      line_items: data.lineItems,
      send_mails: data.sendMails,
      invitees: data.invitees,
      intake_id: data.intakeId,
      name: data.name,
      locale: data.locale,
      template_id: data.templateId,
      type: data.type,
      customer: data.customer,
      plan_id: data.planId,
      interval: data.interval,
      payment_term: data.paymentTerm,
    })) as any;

    return result.data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const submitStep = async (
  formData: IntakeFormResults
): Promise<Pick<IntakeFormResults, 'step' | 'questions' | 'completed'>> => {
  try {
    const result = (await post<Pick<IntakeFormResults, 'step' | 'questions'>>(
      `/v4/intakes/answers`,
      formData
    )) as any;

    return {
      step: result.data.data.step,
      questions: result.data.data.questions,
      completed: result.data.data.intake_completed,
    };
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const lockCustomerIntake = async (intakeId: number): Promise<number> => {
  try {
    const response = (await post<string[]>(
      `/v4/intakes/${intakeId}/completed`,
      {}
    )) as any;

    return response.data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};
