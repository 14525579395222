import { useRef, useEffect } from 'react';

function useLoadVideo(config: {
  videoRef: React.RefObject<HTMLVideoElement>;
  retryCount?: number;
  retryDelayMs?: number;
}) {
  const { videoRef, retryCount = 3, retryDelayMs = 2_000 } = config;

  const retryRef = useRef<number>(retryCount);

  useEffect(() => {
    const video = videoRef.current;
    const source = document.getElementById('source');

    if (!video || !source) return;

    // Video play listener event
    const playListener = () => {
      const videoPromise = video.play();
      if (videoPromise !== undefined) {
        videoPromise
          .then(() => {})
          .catch(() => console.warn('autoplay was prevented'));
      }
    };
    // Video error listener event
    const errorListener = () => {
      if (retryRef.current?.constructor === Number) {
        setTimeout(() => {
          // Reset the video source element
          video.appendChild(source);
        }, retryDelayMs);
        // Increment the retry counter
        retryRef.current = retryRef.current - 1;
      } else {
        // TODO: Show toast here
        console.log('[DEBUG]: Ad Builder - Unable to load video source');
      }
    };

    if (video && source) {
      // https://stackoverflow.com/a/6877530
      const isVideoPlaying = !!(
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > 2
      );

      if (!isVideoPlaying)
        // Listen for source related errors
        source.addEventListener('error', errorListener);
    }

    return () => {
      // Removes all active event listeners
      video.removeEventListener('canplaythrough', playListener);
      source.removeEventListener('error', errorListener);
    };
  });
}

export default useLoadVideo;
