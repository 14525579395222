import tw, { styled } from 'twin.macro';

export const AdUserInteractionsWrapper = styled.div(
  tw`flex justify-between mx-3 p-1 text-gray-500`
);

export const AdUserInteraction = styled.div(tw`grow justify-center shrink-0`);

export const AdInteractionButton = styled.button(
  tw`flex w-full items-center font-medium justify-center h-10 my-1 rounded transition cursor-default hover:bg-gray-200`
);
