import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface PageSearchParams<T> {
  params: Map<keyof T, string>;
  query: string;
}

export default function usePageSearchParams<T>(): PageSearchParams<T> {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const paramsMap = new Map(params.entries());

  return {
    params: paramsMap,
    query: search,
  } as unknown as PageSearchParams<T>;
}
