import 'twin.macro';
import React, { useMemo } from 'react';
import ResourceList from 'components/upload/preview/preview-list/ResourceList';
import useMetaQuery from 'hooks/metaQuery';
import {
  PaginatedResourceRequestPayload,
  PaginatedResources,
} from 'components/pickers/resource-picker/ResourceTypes';
import { useMutation } from 'hooks/sympl-mutation';
import { GET_CUSTOMER_RESOURCES } from 'graphql/customer-resources/queries';
import { DELETE_CUSTOMER_RESOURCE } from 'graphql/customer-resources/mutations';
import groupBy from 'lodash/groupBy';
import Button from 'components/button/Button';
import { DownloadSimple } from '@phosphor-icons/react';
import { CustomerResource } from 'types/apiTypes';
import { downloadFile } from 'utils/fileHelpers';
import { Body } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';
import Spinner from 'components/loading/spinner';

interface deleteCustomerResourcePayload {
  res_id: number;
}

const PAGINATION_PAGE_SIZE = 100;

const MediaSettings: React.FC = () => {
  const { activeCustomer } = useNavigationContext();

  const {
    data,
    refetch: refetchResources,
    loading,
  } = useMetaQuery<PaginatedResources, PaginatedResourceRequestPayload>(
    GET_CUSTOMER_RESOURCES,
    {
      variables: {
        pageSize: PAGINATION_PAGE_SIZE,
        currentPage: 0,
        types: 'logo,image,video,document',
      },
      fetchPolicy: 'network-only',
      skip: !activeCustomer,
    }
  );

  const [deleteResource] = useMutation<
    undefined,
    deleteCustomerResourcePayload
  >(DELETE_CUSTOMER_RESOURCE, {
    onCompleted: refetchResources,
  });

  const resourcesByType = useMemo(
    () => groupBy(data?.customerResources, (r) => r.type),
    [data]
  );

  const deleteHandlerFor = (type: string) => {
    return async (resourceIdx?: number) => {
      if (typeof resourceIdx !== 'number') return;
      await deleteResource({
        variables: {
          res_id: resourcesByType[type][resourceIdx].id,
        },
      });
    };
  };

  const handleDownloadAll = (resources: CustomerResource[]) => {
    resources.forEach(({ path }) => downloadFile(path));
  };

  if (loading)
    return (
      <div tw="mt-10 flex flex-col items-center">
        <Spinner />
      </div>
    );

  return Object.keys(resourcesByType).length ? (
    <div tw="grid 2xl:grid-cols-2 gap-6">
      {Object.keys(resourcesByType)
        .sort((a, b) => a.localeCompare(b))
        .map((type) => {
          const resources = resourcesByType[type];
          return (
            <div key={type}>
              <div tw="flex justify-between items-center">
                <span tw="font-bold text-xs text-gray-400 tracking-wider capitalize">
                  {type}
                </span>
                <Button
                  variant="inverted"
                  size="small"
                  onClick={() => handleDownloadAll(resources)}
                  icon={<DownloadSimple weight="bold" />}
                >
                  Download all
                </Button>
              </div>

              <ResourceList
                resources={resources}
                allowDownload={true}
                allowDelete={true}
                onDelete={deleteHandlerFor(type)}
              />
            </div>
          );
        })}
    </div>
  ) : (
    <Body tw="text-center font-medium text-lg">
      You have no media items yet. <br /> Get started by uploading some when
      making your first campaign
    </Body>
  );
};

export default MediaSettings;
