import { schema } from 'normalizr';
import { FileSizes, FileTypes } from '../fileTypes';
import { IntakeConditionalTypes, IntakeInputTypes } from '../intakeTypes';

/*
 Normalizr entity schemas
*/

type EntityEnumType = {
  [key: string]: string;
};

const ENTITY_ENUMS: EntityEnumType = {
  ...IntakeInputTypes,
  ...IntakeConditionalTypes,
  ...FileSizes,
  ...FileTypes,
};

const getTsTypeFromStringArray = (entity: any): string[] | any => {
  if (!entity) return entity;

  return (entity.toString() as string)
    .replace(/[[\]' ']/g, '')
    .split(',')
    .map((val: string) => getTsTypeFromString(val));
};

const getTsTypeFromString = (entity: any): string | any => {
  if (!entity) return entity;

  let tsType = '';
  const value = entity.toString() as string;

  try {
    tsType = ENTITY_ENUMS[value];
  } catch (error) {
    throw error;
  }

  return tsType;
};

export const questionEntity = new schema.Entity(
  'questions',
  {},
  {
    idAttribute: 'id',
    processStrategy: (entity) => {
      return {
        ...entity,
        type: entity.type
          ? getTsTypeFromString(entity.type)
          : IntakeInputTypes.TEXT,
        rules: entity.rules
          ? {
              ...entity.rules,
              value: entity.rules.value
                ? entity.rules.value.toString()
                : undefined,
            }
          : undefined,
        conditional: entity.conditional
          ? {
              ...entity.conditional,
              type: getTsTypeFromString(entity.conditional.type),
            }
          : undefined,
        uploadConstraints: entity.uploadConstraints
          ? {
              filesize: entity.uploadConstraints.filesize
                ? {
                    min: entity.uploadConstraints.filesize.min
                      ? {
                          ...entity.uploadConstraints.filesize.min,
                          unit: getTsTypeFromString(
                            entity.uploadConstraints.filesize.min.unit
                          ),
                        }
                      : undefined,
                    max: entity.uploadConstraints.filesize.max
                      ? {
                          ...entity.uploadConstraints.filesize.max,
                          unit: getTsTypeFromString(
                            entity.uploadConstraints.filesize.max.unit
                          ),
                        }
                      : undefined,
                  }
                : undefined,
              types: entity.uploadConstraints.types
                ? getTsTypeFromStringArray(entity.uploadConstraints.types)
                : undefined,
            }
          : undefined,
      };
    },
  }
);

export const stepEntity = new schema.Entity(
  'steps',
  { questions: [questionEntity] },
  { idAttribute: 'id' }
);

export const sectionEntity = new schema.Entity(
  'sections',
  { steps: [stepEntity] },
  { idAttribute: 'id' }
);

export const intakeEntity = new schema.Entity(
  'intakes',
  { sections: [sectionEntity] },
  { idAttribute: 'id' }
);
