import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import AuthHeader from 'components/other/AuthHeader';
import { Link } from 'react-router-dom';
import 'twin.macro';
import { Routes } from 'types/routeTypes';

import Label from 'components/form/Label';
import Button from '../components/button/Button';
import Input from '../components/form/input/Input';
import useUserContext from 'hooks/context/user-context';

export const ForgotPassword: FC = () => {
  const { forgotPassword, loading } = useUserContext();

  const { register, handleSubmit } = useForm();

  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const onSubmit = (data: { email: string }) => {
    const { email } = data;

    if (loading) return;
    setIsPasswordReset(false);

    forgotPassword({ email }).then(() => {
      setIsPasswordReset(true);
    });
  };

  return (
    <>
      <div tw="flex flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8 min-h-full">
        <AuthHeader />

        <div tw="mx-auto mt-8 w-full max-w-md">
          <div tw="rounded-lg bg-white p-8 shadow">
            <form id="forgot-password-form" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Label
                  htmlFor="email"
                  tw="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  autoFocus={true}
                  required
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ref={register({ required: true })}
                />
              </div>

              <div tw="mt-6">
                <Button
                  type="submit"
                  variant="indigo"
                  stretch
                  loading={loading}
                >
                  Reset Password
                </Button>
              </div>

              <div tw="mt-2">
                <Link
                  tw="block text-center text-sm text-gray-700"
                  to={Routes.LOGIN}
                >
                  Back to sign in
                </Link>
              </div>
            </form>
          </div>

          {isPasswordReset && (
            <p tw="mt-3 h-4 w-full text-center text-gray-600">
              We've sent you an email with a link to reset your password
            </p>
          )}
        </div>
      </div>
    </>
  );
};
