import { Modal, ModalProps } from 'components/page/app-page/Modal';
import React from 'react';
import tw from 'twin.macro';

interface IGenerateVideoModal extends ModalProps {}

const GenerateVideoModal: React.FC<IGenerateVideoModal> = ({
  show,
  children,
  hideCloseButton,
  onClose,
}) => {
  return (
    <Modal
      show={show}
      hideCloseButton={hideCloseButton}
      onClose={onClose}
      twclass={tw`grid grid-flow-row justify-center rounded-md`}
    >
      <div tw="mt-3 mx-6">{children}</div>
    </Modal>
  );
};

export default GenerateVideoModal;
