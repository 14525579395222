import 'twin.macro';
import React from 'react';

import { Footnote } from 'components/typography/Typography';
import { Link } from 'react-router-dom';
import { Routes } from 'types/routeTypes';
import useNavigationContext from 'hooks/context/nav-context';
import BoosterGetStarted from 'views/get-started/BoosterGetStarted';

const IntakeFinish: React.FC = () => {
  const { hasVacancies } = useNavigationContext();

  return (
    <div tw="h-full w-full flex flex-col items-center justify-center max-h-screen overflow-y-auto">
      {hasVacancies && (
        <Link to={Routes.ROOT} tw="mb-6 pt-2">
          <Footnote>Back to app</Footnote>
        </Link>
      )}

      <BoosterGetStarted />
    </div>
  );
};

export default IntakeFinish;
