import { ApolloQueryResult } from '@apollo/client/core/types';
import { FeedbackStatus } from 'components/feedback/FeedbackWidget';
import { GET_VACANCY_CHECKLIST } from 'graphql/vacancies/queries';
import useNavigationContext from 'hooks/context/nav-context';
import { useQuery } from 'hooks/sympl-query';
import { useSymplCookie } from 'hooks/symplCookie';
import React, { createContext, useEffect, useState } from 'react';

export interface VacancyChecklistItem {
  key: VacancyChecklistKeys;
  completed: boolean;
  feedback_status?: FeedbackStatus;
  optional?: boolean;
}

type VacancyChecklistKeys =
  | 'targeting'
  | 'typeform'
  | 'vacancy_page'
  | 'advertising';

export type GetStartedContextType = {
  checklist: VacancyChecklistItem[];
  checklistLoading: boolean;
  nextItem?: VacancyChecklistItem;
  refetchCheckList: () => Promise<
    ApolloQueryResult<{
      checklist: VacancyChecklistItem[];
    }>
  >;
};

export const GetStartedContext = createContext<GetStartedContextType>(
  undefined!
);

export const GetStartedProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [viewedSurveys] = useSymplCookie<number[]>('viewed-surveys');

  const [_checklist, setChecklist] = useState<VacancyChecklistItem[]>([]);

  const { activeCustomer, activeVacancy, currentVacancy } =
    useNavigationContext();

  const {
    data: checklistData,
    loading: checklistLoading,
    refetch: refetchCheckList,
  } = useQuery<
    {
      checklist: VacancyChecklistItem[];
    },
    { id: number }
  >(GET_VACANCY_CHECKLIST, {
    skip: !activeCustomer || !activeVacancy,
    variables: { id: activeVacancy as number },
    onCompleted: (data) => setChecklist(data.checklist),
  });

  useEffect(() => {
    if (checklistData && checklistData.checklist?.length)
      setChecklist(checklistData.checklist);
    else setChecklist([]);
  }, [checklistData, setChecklist]);

  const checklist = _checklist.map((item) =>
    item.key === 'typeform'
      ? {
          ...item,
          completed:
            currentVacancy?.published ||
            (viewedSurveys?.includes(activeVacancy ?? 0) ?? false),
        }
      : item
  );

  const nextItem = checklist.filter(({ completed }) => !completed)[0];

  return (
    <GetStartedContext.Provider
      value={{
        checklist,
        checklistLoading,
        nextItem,
        refetchCheckList,
      }}
    >
      {children}
    </GetStartedContext.Provider>
  );
};
