import tw, { styled } from 'twin.macro';
import React from 'react';
import Tabs from 'components/tabs/Tabs';
import { X } from '@phosphor-icons/react';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';

const SideBarMenu: React.FC<{
  menu: AdEditorSideBarTabs[];
  activeTabIndex: number;
  setActiveTab: (value: AdEditorSideBarTabs) => void;
}> = ({ menu, activeTabIndex, setActiveTab }) => {
  const handleOnChange = (currentTabIndex: number) => {
    setActiveTab(Object.values(AdEditorSideBarTabs)[currentTabIndex]);
  };

  return (
    <Tabs
      items={menu}
      onChange={handleOnChange}
      activeTabIndex={activeTabIndex}
    />
  );
};

const SideBar = ({
  menu,
  children,
  activeTabIndex,
  setActiveTab,
  isOpen,
}: {
  menu?: AdEditorSideBarTabs[];
  children: React.ReactNode;
  activeTabIndex?: number;
  setActiveTab?: (value?: AdEditorSideBarTabs) => void;
  isOpen?: boolean;
}) => (
  <SideBarWrapper>
    <div tw="flex flex-col h-full">
      {isOpen && (
        <div tw="flex flex-row justify-between items-center p-4">
          {menu &&
            activeTabIndex !== undefined &&
            activeTabIndex >= 0 &&
            setActiveTab && (
              <SideBarMenu
                menu={menu}
                activeTabIndex={activeTabIndex}
                setActiveTab={setActiveTab}
              />
            )}
          <div
            tw="cursor-pointer text-gray-500 hover:text-gray-400 ml-12 p-2 rounded bg-gray-200"
            onClick={() => setActiveTab?.(undefined)}
          >
            <X weight="bold" size={10} />
          </div>
        </div>
      )}

      {children}
    </div>
  </SideBarWrapper>
);

const SideBarWrapper = styled.div`
  ${tw`bg-white w-full z-10 h-screen shadow-md lg:(static w-[fit-content] shadow-lg) overflow-y-hidden`}
`;

export default SideBar;
