import React, { useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import tw, { css, styled, TwStyle } from 'twin.macro';

import useDefocusHandler from 'hooks/defocus';
import useNavigationContext from 'hooks/context/nav-context';

export interface ColorPickerProps {
  id?: string;
  ariaInvalid?: boolean;
  value?: string;
  variant?: 'button' | 'barebones' | 'nohex';
  format?: 'hex' | 'hsl' | 'rgb';
  onChange?: (color: string) => void;
  align?: 'left' | 'right';
  extraTop?: boolean;
  customStyles?: TwStyle;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  id,
  align = 'right',
  value = '#000',
  ariaInvalid,
  format = 'hex',
  variant = 'button',
  extraTop = false,
  customStyles,
  onChange,
}) => {
  const { customerColors } = useNavigationContext();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isPickerVisible, setPickerVisible] = useState(variant === 'barebones');

  useDefocusHandler(wrapperRef, () => {
    if (variant !== 'barebones') setPickerVisible(false);
  });

  const colorChangeHandler = (color: ColorResult) => {
    switch (format) {
      case 'hex':
        onChange?.(color.hex);
        break;
      case 'hsl':
        onChange?.(color.hsl.toString());
        break;
      case 'rgb':
        onChange?.(
          color.rgb.a !== 1
            ? `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
            : color.hex
        );
        break;
    }
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') setPickerVisible(!isPickerVisible);
  };

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setPickerVisible(!isPickerVisible);
  };

  return (
    <Wrapper id={id} ref={wrapperRef} customStyles={customStyles}>
      {variant !== 'barebones' && (
        <Button
          tabIndex={0}
          tw="flex items-center rounded-lg ring-1 ring-black/5 px-3 py-2 cursor-pointer"
          onClick={(e) => clickHandler(e)}
          onKeyDown={(e) => keyDownHandler(e)}
          color={value}
        >
          <ColorPreview color={value} />
          {variant !== 'nohex' && (
            <span tw="font-semibold text-base text-gray-500 pl-2 truncate w-[8ch] uppercase">
              {value}
            </span>
          )}
        </Button>
      )}
      {isPickerVisible && (
        <Picker
          largeTop={extraTop}
          align={align}
          tw="z-50"
          aria-invalid={ariaInvalid}
          color={value}
          disableAlpha={format !== 'rgb'}
          onChange={(color) => colorChangeHandler(color)}
          presetColors={customerColors.slice(0, 16)}
        />
      )}
    </Wrapper>
  );
};

const Picker = styled(SketchPicker)<{
  align: 'left' | 'right';
  largeTop: boolean;
}>`
  ${tw`absolute top-7`}
  ${({ align }) => (align === 'left' ? tw`left-0` : tw`right-0`)}
  ${({ largeTop }) => largeTop && tw`top-11`}
`;

const Wrapper = styled.div<{ customStyles?: TwStyle }>`
  ${tw`relative rounded-md`}
  ${({ customStyles }) => customStyles}
`;

const Button = styled.button<{ color: string }>`
  ${({ color }) =>
    css`
      border: 2px solid ${color};
    `}
`;

const ColorPreview = styled.div<{ color: string }>`
  ${({ color }) => [
    tw`w-5 h-5 rounded-md border transition-colors`,
    css`
      background-color: ${color};
    `,
  ]}
`;

export default ColorPicker;
