import 'twin.macro';
import React from 'react';

import { timeSince } from 'utils/baseHelpers';

export interface TimeStampProps {
  time: string | Date;
}

const TimeStamp: React.FC<TimeStampProps> = ({ time }) => {
  return (
    <span tw="text-sm text-gray-500">
      {timeSince(
        time.constructor === String ? new Date(time) : (time as Date),
        'short'
      )}
    </span>
  );
};

export default TimeStamp;
