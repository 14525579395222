import { SkeletonItem } from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC } from 'react';
import tw from 'twin.macro';

interface HeroSkeletonProps {}

const HeroSkeleton: FC<HeroSkeletonProps> = () => {
  return (
    <div tw="w-full h-[70vh] flex items-center justify-center max-w-[1280px] mx-auto">
      <div tw="w-[calc(100%-_50px)] pl-2 flex flex-col justify-center">
        <SkeletonItem height="10rem" width="10rem " twStyle={tw`mb-8`} />
        <SkeletonItem height="1rem" width="20ch" />
        <SkeletonItem height="3rem" width="60%" twStyle={tw`mb-2`} />
        <SkeletonItem height="3rem" width="70%" twStyle={tw`mb-2`} />
        <SkeletonItem height="3rem" width="70%" twStyle={tw`mb-10`} />
        <SkeletonItem height="1rem" width="40%" twStyle={tw`mb-2`} />
        <SkeletonItem height="1rem" width="20%" twStyle={tw`mb-[5rem]`} />
        <div tw="flex items-center justify-start w-2/3 gap-2">
          <SkeletonItem height="3rem" width="50%" twStyle={tw`mr-[2rem]`} />
          <SkeletonItem height="1.5rem" width="50%" />
        </div>
      </div>
      <div tw="w-1/2 h-[500px]">
        <SkeletonItem width="100%" height="100%" />
      </div>
    </div>
  );
};

export default HeroSkeleton;
