import React from 'react';
import tw, { styled } from 'twin.macro';

import Banner from 'components/banner/Banner';
import { AppPageProps } from 'components/page/app-page/AppPage';
import FeedbackWidget from 'components/feedback/FeedbackWidget';

export interface ToolbarPanelProps {
  feedback?: AppPageProps['feedback'];
  onClose?: () => void;
}

const ToolbarPanel: React.FC<ToolbarPanelProps> = ({ feedback, onClose }) => {
  if (!feedback) throw Error('Please provide feedback');

  return (
    <Wrapper>
      <Banner heading="Feedback" onClose={onClose} closeText="Close">
        {feedback && (
          <div tw="mt-3">
            <FeedbackWidget
              status={feedback?.status}
              type={feedback.type}
              previousFeedback={feedback?.previousFeedback}
              onSubmit={feedback?.onSubmit}
            />
          </div>
        )}
      </Banner>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  tw`border-b border-gray-100 bg-[#fdfdfd] min-w-[320px] py-1 px-3 bg-white`
);

export default ToolbarPanel;
