/**
 * Push event to GTM data layer
 * @param event Event identifier
 * @param payload Optional custom payload
 */
export const fireEvent = (event: string, payload = {}) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: event,
    ...payload,
  });
};

/**
 * Push page view event to GTM data layer
 * @param page Page
 * @param payload Optional custom payload
 */
export const pageView = (page: string, payload = {}) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: 'Pageview',
    pagePath: page,
    pageTitle: page,
    ...payload,
  });
};
