import React from 'react';
import tw, { styled } from 'twin.macro';

export interface AdEditorIconProps {
  isVideo?: boolean;
  isSelected?: boolean;
  isReel?: boolean;
}
export const AdEditorStoryIcon: React.FC<AdEditorIconProps> = ({
  isVideo,
  isReel,
}) => {
  return (
    <>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="66"
        height="117"
        viewBox="0 0 66 117"
      >
        <defs>
          <filter
            id="story-media"
            x="0"
            y="0"
            width="66"
            height="117"
            filterUnits="userSpaceOnUse"
          >
            <feOffset />
            <feGaussianBlur stdDeviation="1.5" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Story_vertical"
          data-name="Story/vertical"
          transform="translate(-162.5 -237.5)"
        >
          <g
            transform="matrix(1, 0, 0, 1, 162.5, 237.5)"
            filter="url(#story-media)"
          >
            <rect
              id="story-media-2"
              data-name="story-media"
              width="57"
              height="108"
              rx="5"
              transform="translate(4.5 4.5)"
              fill="#d3d3d3"
            />
          </g>
          <circle
            id="user-picture"
            cx="4.5"
            cy="4.5"
            r="4.5"
            transform="translate(171 245)"
            fill="#f0f0f0"
          />
          <rect
            id="username"
            width="22"
            height="3"
            rx="1.5"
            transform="translate(182 246)"
            fill="#f0f0f0"
          />
          <rect
            id="story-button"
            width="13"
            height="6"
            rx="3"
            transform="translate(189 339)"
            fill="#f0f0f0"
          />
          {isVideo && !isReel && (
            <path
              id="video-play"
              d="M10.913,4.408a3,3,0,0,1,5.174,0l8.261,14.073A3,3,0,0,1,21.761,23H5.239a3,3,0,0,1-2.587-4.519Z"
              transform="translate(209 282.5) rotate(90)"
              fill="#fff"
            />
          )}
          {isReel && (
            <path
              id="reel"
              d="M13.34 4.13L20.26 16H4v-1C4 9.48 8.05 4.92 13.34 4.13zM33.26 16L22.57 16 15.57 4 26.26 4zM46 15v1H35.57l-7-12H35C41.08 4 46 8.92 46 15zM4 18v17c0 6.08 4.92 11 11 11h20c6.08 0 11-4.92 11-11V18H4zM31 32.19l-7.99 4.54C21.68 37.49 20 36.55 20 35.04v-9.08c0-1.51 1.68-2.45 3.01-1.69L31 28.81C32.33 29.56 32.33 31.44 31 32.19z"
              transform="translate(176.9 278.5), scale(0.73 0.73)"
              fill="#fff"
            />
          )}
        </g>
      </SVG>
    </>
  );
};

export default AdEditorStoryIcon;

const SVG = styled.svg`
  ${tw`hover:cursor-pointer`}
`;
