import {
  authenticateUser,
  forgotUserPassword,
  resetUserPassword,
} from 'api/auth/authApi';
import React, { createContext, FC, useState } from 'react';
import Error from 'types/errorTypes';

import { UserContextType } from 'types/user/context';
import {
  ConfirmPasswordData,
  Credentials,
  ForgotPasswordData,
} from 'types/userTypes';
import { activateUserSession, handleAuth } from 'utils/authHelpers';

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

export const UserProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const reset = () => {
    setError(null);
    setLoading(false);
  };

  const authenticate = async (credentials: Credentials) => {
    setLoading(true);
    try {
      const userResponse = await authenticateUser(credentials);

      setLoading(false);

      handleAuth('login', {
        access_token: userResponse.data.access_token,
        refresh_token: userResponse.data.refresh_token,
        email: credentials.username,
        first_name: userResponse.data.first_name ?? '',
        last_name: userResponse.data.last_name ?? '',
        id: parseInt(userResponse.data.id ?? '0'),
        is_sympl: !!userResponse.data.is_sympl,
        active_customer: userResponse.data.active_customer ?? 0,
        active_vacancy: userResponse.data.active_vacancy,
      });

      return userResponse.data.access_token !== undefined;
    } catch (responseError) {
      setError(responseError as Error);
      setLoading(false);
    }
  };

  const resetPassword = async (data: ConfirmPasswordData) => {
    setLoading(true);

    try {
      const userResponse = await resetUserPassword(data);
      const token = userResponse?.data?.access_token;

      await activateUserSession({
        token,
        uid: data?.email,
        firstName: userResponse?.data?.first_name,
        lastName: userResponse?.data?.last_name,
        customerId: userResponse?.data?.active_customer,
      });

      setLoading(false);
      setError(null);
      return token !== undefined;
    } catch (responseError) {
      setLoading(false);
      setError(responseError as Error);
    }
  };

  const forgotPassword = async (data: ForgotPasswordData) => {
    setLoading(true);
    try {
      await forgotUserPassword(data);

      setLoading(false);
      setError(null);
    } catch (responseError) {
      setLoading(false);
      setError(responseError as Error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        loading,
        error,

        // handlers
        reset,

        // async handler
        authenticate,
        resetPassword,
        forgotPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
