import { gql } from '@apollo/client';

export const MARK_AS_READ = gql`
  mutation markNotificationAsRead($notificationId: number!) {
    notification(input: {}, notificationItem: $notificationId)
      @rest(
        type: "Notification"
        path: "/v4/notifications/{args.notificationItem}/read"
        method: "POST"
      )
  }
`;

export const MARK_ALL_AS_READ = gql`
  mutation Notifications {
    notifications(input: {})
      @rest(
        type: "Notification"
        path: "/v4/notifications/all-read"
        method: "POST"
      )
  }
`;
