import tw, { css, styled } from 'twin.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import LocationSearch, {
  PlaceResult,
} from 'components/multi-location/LocationSearch';
import {
  BASE_ZOOM,
  calculateRadius,
  fetchPolygon,
  generateTargetingLocation,
  MAX_RADIUS_KM,
  MIN_RADIUS_KM,
} from 'utils/geolocationHelpers';
import { LocationType, TargetingLocation } from 'types/geolocationTypes';
import LocationList from 'components/multi-location/LocationList';
import { LocationSuggestList } from 'components/multi-location/LocationSuggestList';
import Map from './Map';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';

export interface MultiLocationProps {
  placeholder?: string;
  defaultValue?: TargetingLocation[];
  language: string;
  onChange: (value: TargetingLocation[]) => void;
  suggestions?: TargetingLocation[];
}

const TOLERANCE = 0.000001;
const MultiLocation: React.FC<MultiLocationProps> = ({
  placeholder = 'e.g. Antwerp, Belgium',
  defaultValue = [],
  language,
  onChange,
  suggestions,
}) => {
  const { addToast } = useToastNotifications();
  const searchRef = useRef<HTMLInputElement>(null);

  const [targetingLocations, setTargetingLocations] = useState<
    TargetingLocation[]
  >([]);

  const [suggestLocations, setSuggestLocations] =
    useState<TargetingLocation[]>();

  const DEFAULT_POSITION = defaultValue[0] ?? {
    lat: 51.20849,
    lng: 4.4719297,
  };

  const locationExist = useCallback(
    (location: TargetingLocation) => {
      return (
        targetingLocations.filter(
          (v) =>
            (v.lng === location.lng && v.lat === location.lat) ||
            (Math.abs(v.lng - location.lng) <= TOLERANCE &&
              Math.abs(v.lat - location.lat) <= TOLERANCE)
        ).length !== 0
      );
    },
    [targetingLocations]
  );

  const searchResultClickHandler = async ({
    geometry,
    name,
    types,
    address_components: addressComponents,
  }: PlaceResult) => {
    const newLocation = generateTargetingLocation({
      label: name!,
      lat: (geometry?.location as any)?.lat ?? 0,
      lng: (geometry?.location as any)?.lng ?? 0,
      type: types[0],
      addressComponents: [addressComponents!],
    });

    if (newLocation.type === LocationType.COUNTRY) {
      try {
        newLocation.polygon = await fetchPolygon(
          [newLocation.lat, newLocation.lng],
          newLocation.type
        );

        newLocation.isCountry = true;
      } catch (error: any) {
        console.error(error);
      }
    } else {
      newLocation.radius = calculateRadius(geometry);
    }

    if (locationExist(newLocation)) return;
    const overlappingLocations = targetingLocations.filter(
      (v) =>
        v.country_code === newLocation.country_code &&
        ((newLocation.type === LocationType.COUNTRY &&
          v.type !== LocationType.COUNTRY) ||
          (newLocation.type !== LocationType.COUNTRY &&
            v.type === LocationType.COUNTRY))
    );

    if (overlappingLocations.length >= 1) {
      addToast({
        title: 'Overlapping countries and cities not allowed',
        description: `${
          newLocation.name
        } overlaps with the following location(s): ${overlappingLocations
          .map((v) => v.name)
          .join(', ')}. Please remove them first.`,
        type: ToastTypes.ERROR,
        visible: true,
      });
      return;
    }

    updateTargetingLocations([...targetingLocations, newLocation]);
  };

  const locationDeleteHandler = (locationIndex: number) => {
    updateTargetingLocations([
      ...targetingLocations.slice(0, locationIndex),
      ...targetingLocations.slice(locationIndex + 1),
    ]);
  };

  const addSuggestLocation = (location: TargetingLocation) => {
    if (locationExist(location)) return;
    updateTargetingLocations([
      ...targetingLocations,
      {
        ...location,
        id: null,
      },
    ]);
  };
  const locationRadiusChangeHandler = (
    locationIndex: number,
    radius: number
  ) => {
    updateTargetingLocations(
      targetingLocations.map((location, index) =>
        index === locationIndex ? { ...location, radius } : location
      )
    );
  };

  const updateTargetingLocations = (
    targetingLocations: TargetingLocation[]
  ) => {
    setTargetingLocations(targetingLocations);
    onChange(targetingLocations);
  };

  useEffect(() => {
    // Generate targeting locations if default value is not empty and targeting locations are empty
    if (defaultValue.length && !targetingLocations.length) {
      setTargetingLocations(defaultValue);
    }
  }, [defaultValue, targetingLocations.length]);

  useEffect(() => {
    setSuggestLocations(suggestions?.filter((v) => !locationExist(v)));
  }, [locationExist, suggestions, targetingLocations]);

  return (
    <Wrapper>
      <div tw="col-span-3 order-2 xl:order-1 z-30">
        <MapContainer
          zoom={BASE_ZOOM}
          attributionControl={false}
          zoomControl={false}
          center={DEFAULT_POSITION}
          scrollWheelZoom={false}
        >
          <Map targetingLocations={targetingLocations} />
        </MapContainer>
      </div>
      <SearchWrapper>
        <LocationSearch
          placeholder={placeholder}
          searchRef={searchRef}
          locations={targetingLocations}
          language={language}
          onClick={searchResultClickHandler}
        />

        <LocationSuggestList
          onAddLocation={(location) => addSuggestLocation(location)}
          locations={suggestLocations ?? []}
        />

        <LocationList
          searchRef={searchRef}
          locations={targetingLocations}
          minRadius={MIN_RADIUS_KM}
          maxRadius={MAX_RADIUS_KM}
          onDelete={locationDeleteHandler}
          onRadiusChange={locationRadiusChangeHandler}
        />
      </SearchWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${tw`w-full rounded-md flex flex-col flex-nowrap xl:(grid grid-cols-5)`}
  ${css`
    .leaflet-container {
      min-height: 600px;
      width: 100%;
    }
  `}
`;

const SearchWrapper = styled.div`
  ${tw`p-0 xl:(p-2 pt-0 order-2) col-span-2 order-1`}
`;

export default MultiLocation;
