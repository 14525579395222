import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IntakeQuestion } from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import { DropdownItem } from 'components/dropdown/Dropdown';

const IntakeApiDropdown: React.FC<{
  question: IntakeQuestion;
  defaultValue?: string;
}> = ({ question, defaultValue }) => {
  const { control } = useFormContext();
  const id = question.id.toString();

  const [value, setValue] = useState<string | number | undefined>(defaultValue);

  const rules = questionRules(question);

  const changeHandler = (
    onChange: (value: string | number) => void,
    value?: { key: string | number; label: string }
  ) => {
    if (value) {
      onChange(value.key);
      setValue(value.key);
    }
  };

  const clearHandler = () => {
    setValue(undefined);
  };

  return (
    <Controller
      id={id}
      name={id}
      rules={rules}
      control={control}
      defaultValue={defaultValue ?? ''}
      render={({ onChange }: { onChange: any }) => (
        <ApiDropdown
          mode={'key'}
          value={value}
          type={question.apiList!}
          onChange={(value) =>
            changeHandler(onChange, value as DropdownItem | undefined)
          }
          onClear={() => clearHandler()}
        />
      )}
    />
  );
};

export default IntakeApiDropdown;
