import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC, Suspense, lazy, useRef } from 'react';
import 'twin.macro';
import * as Styled from '../styles';
import useCareerPageContext from 'hooks/context/career-page-context';
import useDefocusHandler from 'hooks/defocus';
import Title from 'components/title/Title';
import { Body, Title1 } from 'components/typography/Typography';
import { SquaresFour } from '@phosphor-icons/react';
import {
  AcceptedSections,
  CardsProps,
  CarouselProps,
  ContactProps,
  FeatureProps,
  FooterProps,
  HeroProps,
  SectionType,
  TeamProps,
  TestimonialProps,
  VacanciesProps,
  VideoProps,
} from '@symplbe/sympl-components';
import Button from 'components/button/Button';
import CardsConfig from '../section-renderer/sections-with-config/cards/CardsConfig';

const HeroConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/hero/HeroConfig'
    )
);

const TeamConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/team/TeamConfig'
    )
);

const FeatureConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/feature/FeatureConfig'
    )
);

const VacanciesConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/vacancies/VacanciesConfig'
    )
);

const VideoConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/video/VideoConfig'
    )
);

const CarouselConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/carousel/CarouselConfig'
    )
);

const TestimonialsConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/testimonial/TestimonialConfig'
    )
);

const ContactConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/contact/ContactConfig'
    )
);

const FooterConfig = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/footer/FooterConfig'
    )
);

const SectionSettingsSidebar: FC = () => {
  const {
    activeSection,
    careerPageConfig,
    handleDeleteSection,
    handleEditSection,
  } = useCareerPageContext();

  const sideBarRef = useRef(null);

  const activeSectionConfig = careerPageConfig?.sections[
    activeSection as string
  ] as AcceptedSections;

  useDefocusHandler(sideBarRef, () => {
    handleEditSection(null);
  });

  const renderForm = () => {
    switch (activeSectionConfig?.type) {
      case SectionType.Hero:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <HeroConfig sectionProps={activeSectionConfig as HeroProps} />
          </Suspense>
        );
      case SectionType.Team:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <TeamConfig sectionProps={activeSectionConfig as TeamProps} />
          </Suspense>
        );
      case SectionType.Feature:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <FeatureConfig sectionProps={activeSectionConfig as FeatureProps} />
          </Suspense>
        );
      case SectionType.Carousel:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <CarouselConfig
              sectionProps={activeSectionConfig as CarouselProps}
            />
          </Suspense>
        );
      case SectionType.Video:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <VideoConfig sectionProps={activeSectionConfig as VideoProps} />
          </Suspense>
        );
      case SectionType.Vacancies:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <VacanciesConfig
              sectionProps={activeSectionConfig as VacanciesProps}
            />
          </Suspense>
        );
      case SectionType.Cards:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <CardsConfig
              sectionProps={activeSectionConfig as unknown as CardsProps}
            />
          </Suspense>
        );
      case SectionType.Testimonials:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <TestimonialsConfig
              sectionProps={activeSectionConfig as unknown as TestimonialProps}
            />
          </Suspense>
        );
      case SectionType.Contact:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <ContactConfig
              sectionProps={activeSectionConfig as unknown as ContactProps}
            />
          </Suspense>
        );
      case SectionType.Footer:
        return (
          <Suspense fallback={<SkeletonList rows={4} />}>
            <FooterConfig sectionProps={activeSectionConfig as FooterProps} />
          </Suspense>
        );
      default:
        return (
          <Title1>
            Section {activeSectionConfig.type} being constructed. Stay tuned!
          </Title1>
        );
    }
  };

  return (
    <Styled.Container
      ref={sideBarRef}
      width={300}
      isFrom="right"
      show={!!activeSection}
    >
      {activeSection && careerPageConfig ? (
        <div tw="p-2 h-full flex flex-col">
          {activeSection && careerPageConfig && renderForm()}
          <div tw="border-t-2 border-gray-100 w-full mt-auto p-4">
            {activeSectionConfig.type !== SectionType.Footer && (
              <Button
                role="button"
                variant="danger"
                stretch
                onClick={() => handleDeleteSection(activeSection)}
              >
                Delete Section
              </Button>
            )}
          </div>
          <button
            tw="mb-4 text-sm text-gray-700 font-medium"
            onClick={() => handleEditSection(null)}
          >
            Close
          </button>
        </div>
      ) : (
        <div tw="flex flex-col items-center justify-center h-full text-center p-2">
          <SquaresFour size={40} tw="mb-4" />
          <Title> Section Settings </Title>
          <Body> click on a section to show settings</Body>
          <SkeletonList rows={2} />
        </div>
      )}
    </Styled.Container>
  );
};

export default SectionSettingsSidebar;
