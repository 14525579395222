import SymplLogo from 'assets/sympl-logo-cropped.png';
import AdEditorStencilCanvas from 'components/ad-builder/StencilCanvas';
import AdEditorVideoContainer from 'components/ad-builder/VideoContainer';
import Img from 'components/image/Image';
import { Caption } from 'components/typography/Typography';
import useDefocusHandler from 'hooks/defocus';
import React, { useRef, useState } from 'react';
import { CaretUp } from '@phosphor-icons/react';
import tw, { css, styled } from 'twin.macro';

import useAdEditorContext from 'hooks/context/ad-editor-context';
import { AdCreativeType } from 'types/adTypes';
import { CTALabel } from 'data/adEditorCTA';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import useNavigationContext from 'hooks/context/nav-context';

export const StoryPreview: React.FC = () => {
  const { currentVariant, setActiveTab } = useAdEditorContext();
  const { currentVacancy } = useNavigationContext();

  const containerRef = useRef<HTMLDivElement>(null);

  const [hasFocus, setHasFocus] = useState(false);

  useDefocusHandler(containerRef, () => setHasFocus(false));

  return (
    <StoryContainer
      ref={containerRef}
      onMouseEnter={() => setHasFocus(true)}
      onMouseLeave={() => setHasFocus(false)}
    >
      {!hasFocus && (
        <StoryHeader>
          <div tw="flex justify-between items-center px-2">
            <StoryUser>
              <BrandImage>
                <Img
                  src={
                    currentVacancy?.brand?.facebookPage?.logo_url ?? SymplLogo
                  }
                  alt={'instragram-story-brand-logo'}
                  tw="w-full h-full"
                />
              </BrandImage>
              <div tw="flex-col">
                <BrandName>
                  {currentVacancy?.brand?.facebookPage?.name ?? 'sympl'}
                </BrandName>
                <Caption>Sponsored</Caption>
              </div>
            </StoryUser>
          </div>
        </StoryHeader>
      )}
      <StoryCanvasWrapper>
        {currentVariant?.creative_type === AdCreativeType.VIDEO ? (
          <AdEditorVideoContainer />
        ) : (
          <AdEditorStencilCanvas />
        )}
      </StoryCanvasWrapper>
      <StoryFooter>
        {!hasFocus && (
          <CaretUp
            size={24}
            tw="col-start-2 row-start-1 justify-self-center whitespace-nowrap scale-y-75"
          />
        )}
        <StoryCtaContainer>
          <StoryCtaButton
            onClick={() => setActiveTab?.(AdEditorSideBarTabs.BANNER_OPTIONS)}
          >
            <span>{currentVariant?.cta || CTALabel.SIGN_UP}</span>
          </StoryCtaButton>
        </StoryCtaContainer>
      </StoryFooter>
    </StoryContainer>
  );
};

const StoryContainer = styled.div`
  ${tw`
  text-white relative rounded-xl overflow-hidden shadow-md w-[22rem] max-[400px]:(w-[95vw]) sm:w-96
`}
  ${css`
    aspect-ratio: inherit;

    .logo {
      height: 32px;
      width: 32px;
    }

    .font-sm {
      font-size: 12px;
    }

    .font-xs {
      font-size: 10px;
    }

    .padding-2 {
      padding: 2%;
    }

    .padding-3 {
      padding: 3%;
    }
  `}
`;

const StoryCanvasWrapper = styled.div(tw`rounded-b-md h-full overflow-hidden`);

const StoryHeader = styled.div(tw`p-3 absolute top-0 z-30`);

const StoryUser = styled.div(tw`flex place-items-center gap-3 items-center`);

const BrandImage = styled.picture(
  tw`overflow-hidden rounded-full flex relative cursor-pointer h-8 w-8`
);

const BrandName = styled.p(
  tw`font-semibold text-xs leading-tight cursor-pointer`
);

const StoryFooter = styled.div(
  tw`absolute bottom-0 w-full pointer-events-none grid grid-cols-3 px-5 pb-3 pt-3 items-center z-30`
);

const StoryCtaContainer = styled.div(
  tw`col-start-2 col-end-3 flex pointer-events-auto place-content-center flex-col items-center`
);

const StoryCtaButton = styled.div(
  tw`px-5 py-2 bg-gray-700 rounded-full text-sm font-semibold text-center whitespace-nowrap flex place-content-center text-gray-300 cursor-pointer hover:(bg-white text-gray-700 ring-2 ring-indigo-400)`
);

export default StoryPreview;
