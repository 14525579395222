import React, { useEffect, useState } from 'react';
import { OTPInput, SlotProps } from 'input-otp';
import tw from 'twin.macro';
import { Title2 } from 'components/typography/Typography';
import Button from 'components/button/Button';
import { useMutation } from 'hooks/sympl-mutation';
import { useLazyQuery } from 'hooks/sympl-query';
import { VALIDATE_TWO_FACTOR } from 'graphql/two-factor/mutations';
import { REQUEST_TWO_FACTOR } from 'graphql/two-factor/queries';
import { Routes } from 'types/routeTypes';

const TwoFactor = () => {
  const [twoFactorCode, setTwoFactorCode] = useState('');

  const urlParams = new URLSearchParams(window.location.search);

  const [validateTwoFactor, { loading: validating }] =
    useMutation(VALIDATE_TWO_FACTOR);
  const [requestTwoFactor, { called: requested }] =
    useLazyQuery(REQUEST_TWO_FACTOR);

  useEffect(() => {
    setTwoFactorCode(urlParams.get('two_factor_code')!);
  }, [urlParams.get('two_factor_code')]);

  const handleTwoFactorSubmit = (value: string) => {
    validateTwoFactor({
      variables: {
        input: {
          two_factor_code: value,
        },
      },
    }).then(() => {
      window.location.replace(localStorage.getItem('referrer') ?? Routes.ROOT);
    });
  };

  return (
    <div tw="flex flex-col items-center justify-center h-screen">
      <Title2 mb={2}>We've sent you a code via email</Title2>
      <OTPInput
        maxLength={6}
        autoFocus
        disabled={validating}
        containerClassName="group flex items-center has-[:disabled]:opacity-30"
        value={twoFactorCode ?? ''}
        onChange={setTwoFactorCode}
        onComplete={handleTwoFactorSubmit}
        render={({ slots }) => (
          <>
            <div className="flex">
              {slots.slice(0, 3).map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>

            <FakeDash />

            <div className="flex">
              {slots.slice(3).map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>
          </>
        )}
      />
      <div tw="mt-4">
        <Button
          variant="link"
          onClick={() => requestTwoFactor()}
          disabled={requested || validating}
        >
          Request a new code
        </Button>
      </div>
    </div>
  );
};

function Slot(props: SlotProps) {
  return (
    <div
      css={[
        tw`relative h-16 w-12 text-[2rem] text-gray-700 flex items-center justify-center transition-all duration-300 border border-y border-r first:rounded-l-md first:border-l last:rounded-r-md group-hover:border-gray-700/20 group-focus-within:border-gray-700/20 outline outline-0 outline-gray-700/20`,
        props.isActive && tw`outline-4 outline-indigo-300`,
      ]}
    >
      {props.char !== null && <div>{props.char}</div>}
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}

// You can emulate a fake textbox caret!
function FakeCaret() {
  return (
    <div tw="animate-caret-blink pointer-events-none absolute inset-0 flex items-center justify-center">
      <div tw="h-8 w-px bg-gray-300" />
    </div>
  );
}

// Inspired by Stripe's MFA input.
function FakeDash() {
  return (
    <div tw="flex w-10 items-center justify-center">
      <div tw="bg-gray-600 h-1 w-3 rounded-full" />
    </div>
  );
}

export default TwoFactor;
