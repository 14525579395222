import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorBoundary } from 'react-error-boundary';

import { IntakeConditionalTypes, IntakeInputTypes } from 'types/intakeTypes';
import { toLowercaseDashedString } from 'utils/urlHelpers';
import Label from 'components/form/Label';
import getInputElement from 'utils/inputElementFactory';
import { getQuestionErrorMessage } from 'utils/formHelpers';
import { Error } from 'components/typography/Typography';

import 'twin.macro';
import useIntakeCoreContext from 'hooks/context/intakes-context';
import useIntakeStepContext from 'hooks/context/intake-step-context';
import tw, { styled } from 'twin.macro';

const FormStep = () => {
  const { errors } = useFormContext();
  const {
    currentActiveSection: currentSection,
    currentActiveStep: currentStep,
  } = useIntakeCoreContext();

  const { questions } = useIntakeStepContext();

  const [erroredQuestions, setErroredQuestions] = useState<number[]>([]);

  const filteredQuestions = () => {
    return questions.filter((question) => {
      // Filter out bad questions
      if (erroredQuestions.includes(question.id)) return false;
      if (question.conditional) {
        const otherQuestion = questions.find(
          (q) => q.id === question.conditional?.questionId
        );

        if (otherQuestion) {
          switch (question.conditional.type) {
            case IntakeConditionalTypes.EQUALS:
              return otherQuestion.value === question.conditional.value;
            default:
              return true;
          }
        }
      }
      return true;
    });
  };

  const colorQuestions = filteredQuestions().filter(
    (question) => question.type === IntakeInputTypes.COLOR
  );

  const getInvalidQuestions = useCallback(
    () =>
      questions.filter((question) =>
        Object.keys(errors).includes(question.id.toString())
      ),
    [errors, questions]
  );

  const isUploadQuestion = (questionType: IntakeInputTypes) => {
    return (
      questionType === IntakeInputTypes.UPLOAD ||
      questionType === IntakeInputTypes.UPLOAD_MULTI ||
      questionType === IntakeInputTypes.UPLOAD_MULTI_WITH_TEXT ||
      questionType === IntakeInputTypes.UPLOAD_WITH_TEXT
    );
  };

  useEffect(() => {
    const invalidQuestions = getInvalidQuestions();
    if (invalidQuestions.length > 0) {
      const questionElement = document.getElementById(
        invalidQuestions[0].id.toString()
      );
      if (questionElement) {
        questionElement.scrollIntoView({ behavior: 'smooth' });
        questionElement.focus();
      }
    }
  }, [getInvalidQuestions]);

  if (!currentSection || !currentStep) return null;

  return (
    <div id={`formsection-${toLowercaseDashedString(currentStep.name)}`}>
      {questions &&
        questions.length > 0 &&
        filteredQuestions().map((question, index) => (
          <ErrorBoundary
            key={question.id}
            FallbackComponent={() => <></>}
            onReset={() => {
              setErroredQuestions([...erroredQuestions, question.id]);
            }}
            resetKeys={[erroredQuestions]}
          >
            <FormField id={question.id.toString()}>
              {question.type !== IntakeInputTypes.INVITECOWORKER && (
                <div tw="flex flex-col">
                  <Label
                    question={question}
                    htmlFor={question.id.toString()}
                    hasAnnotation={true}
                    showAnnotationText={index === 0}
                  >
                    {question.label}
                  </Label>
                  {question.description && (
                    <p tw="mb-3 text-sm text-gray-500">
                      {question.description}
                    </p>
                  )}
                </div>
              )}
              <div tw="mb-6">
                {getInputElement(
                  question,
                  'intake-form',
                  currentSection,
                  colorQuestions
                )}
                {errors[question.id] && !isUploadQuestion(question.type) && (
                  <Error>
                    {getQuestionErrorMessage({
                      type: errors[question.id]?.type,
                      question,
                    })}
                  </Error>
                )}
              </div>
              {question?.additionalInfo && (
                <div tw="text-gray-700">
                  <span tw="mb-2 block font-semibold">
                    {question.additionalInfo.header}
                  </span>
                  <ul tw="list-inside list-disc">
                    {question.additionalInfo.items?.map((item) => (
                      <li key={item} tw="py-1">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </FormField>
          </ErrorBoundary>
        ))}
    </div>
  );
};

const FormField = styled.div<{ zIndex?: number }>`
  ${tw`mb-8 flex flex-col relative`}
`;

export default FormStep;
