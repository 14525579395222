import React from 'react';
import 'twin.macro';

export type RadioItem = {
  id: string;
  value: string;
  label: string;
  disabled?: boolean;
};

interface IProps {
  groupName?: string;
  item: RadioItem;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = (props: IProps) => {
  return (
    <div tw="flex items-baseline">
      <input
        tw="ml-4 mr-2 cursor-pointer p-2"
        type="radio"
        id={props.item.id}
        name={props.groupName ?? props.item.id}
        value={props.item.value}
        onChange={(e) => props.onChange(e)}
        disabled={props.item.disabled}
      />
      <label tw="grow cursor-pointer p-2 text-gray-700" htmlFor={props.item.id}>
        {props.item.label}
      </label>
    </div>
  );
};

export default RadioButton;
