import React from 'react';
import { ShareNetwork, EnvelopeSimple, PushPin } from '@phosphor-icons/react';
import SocialIcon from 'components/icons/SocialIcon';

interface SourceIconProps {
  source?: string;
  fill?: string;
}
const SourceIcon = ({ source, fill }: SourceIconProps) => {
  const ICON_SIZE = 22;
  const ICON_COLOR = fill || '#4b5563';

  switch (source) {
    case 'FACEBOOK':
    case 'GOOGLE':
    case 'INSTAGRAM':
    case 'TIKTOK':
    case 'LINKEDIN':
    case 'INDEED':
      return (
        <SocialIcon
          company={source.toLowerCase()}
          tw="ml-0 mr-5"
          width={`${ICON_SIZE}px`}
          height={`${ICON_SIZE}px`}
          color={ICON_COLOR}
          grayScale
        />
      );
    case 'VDAB':
      return (
        <EnvelopeSimple
          tw="ml-0 mr-5"
          weight="bold"
          size={ICON_SIZE}
          color={ICON_COLOR}
        />
      );
    case 'JOBBOARD':
    case 'BELGIE_VAC_GROUP':
    case 'STUDENTBE':
      return (
        <PushPin
          tw="ml-0 mr-5"
          weight="bold"
          size={ICON_SIZE}
          color={ICON_COLOR}
        />
      );
    default:
      return (
        <ShareNetwork
          tw="ml-0 mr-5"
          weight="fill"
          size={ICON_SIZE}
          color={ICON_COLOR}
        />
      );
  }
};

export default SourceIcon;
