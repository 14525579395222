import Badge from 'components/badge/Badge';
import React from 'react';
import { FilterMenuOption } from 'views/IntakesOverview';
import 'twin.macro';

const IntakesFilterMenu = ({
  activeFilter,
  onChangeActiveFilter,
}: {
  activeFilter: FilterMenuOption;
  onChangeActiveFilter: (value: FilterMenuOption) => void;
}) => {
  const filters: FilterMenuOption[] = ['All', 'Completed', 'In progress'];
  return (
    <div tw="flex cursor-pointer flex-row gap-1">
      {filters.map((filterMenuOption: FilterMenuOption, index) => (
        <div key={index} onClick={() => onChangeActiveFilter(filterMenuOption)}>
          <Badge
            label={filterMenuOption.toString()}
            color={activeFilter === filterMenuOption ? 'green' : 'gray'}
          ></Badge>
        </div>
      ))}
    </div>
  );
};

export default IntakesFilterMenu;
