import { gql } from '@apollo/client';

export const TOPUP_BALANCE = gql`
  mutation topupBalance($budget: ID!) {
    topupBalance(input: $input)
      @rest(
        type: "TopupBalance"
        path: "/v4/financial-transactions/topup-balance"
        method: "POST"
      ) {
      id
    }
  }
`;
