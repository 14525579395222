import { CardsKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';
import { faker } from '@faker-js/faker';

export const defaultCardSettings: CardsKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig(),
  cards: [...Array(3)].map(() => ({
    emoji: faker.internet.emoji(),
    title: faker.company.buzzNoun(),
    content: faker.lorem.paragraph(),
    isPlaceholder: true,
  })),
};
