import React from 'react';
import tw, { styled } from 'twin.macro';

import Input from '../input/Input';

export interface CounterProps {
  id: string;
  value?: number;
  increments?: number;
  ariaInvalid?: boolean;
  min?: number;
  max?: number;
  unit?: string;
  onChange?: (value: number) => void;
}

const Counter: React.FC<CounterProps> = ({
  id,
  value = 0,
  increments = 1,
  min,
  max,
  ariaInvalid,
  unit,
  onChange,
}) => {
  // Check if the passed props are valid
  if (increments <= 0)
    throw new Error('Increments should be a number greater than zero');

  const counterDecrementHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    // Decrement value if greater than minimum
    if (min === undefined || (min !== undefined && value > min))
      onChange?.(value - increments);
  };

  const counterIncrementHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    // Increment value if less than maximum
    if (max === undefined || (max !== undefined && value < max))
      onChange?.(value + increments);
  };

  return (
    <>
      <Input
        id={id}
        name={id}
        readOnly
        structural
        value={value}
        ariaInvalid={ariaInvalid}
      />

      <div tw="w-full flex justify-between ring-1 ring-black/5 rounded-md p-2">
        <Button onClick={(e) => counterDecrementHandler(e)}>&minus;</Button>

        <span tw="flex flex-1 items-center justify-center">
          {value ?? 0}
          {unit && <p tw="hidden sm:block ml-1">{unit}</p>}
        </span>

        <Button onClick={(e) => counterIncrementHandler(e)}>+</Button>
      </div>
    </>
  );
};

const Button = styled.button(
  tw`flex-1 h-12 rounded-md items-center justify-center ring-1 ring-black/5 bg-gray-200 text-gray-700 text-lg font-medium`
);

export default Counter;
