import React from 'react';
import { AdVariant } from 'types/adEditorTypes';
import { AdChannel, AdCreativeType, AdPlacement } from 'types/adTypes';
import AdEditorFeedIcon from 'components/ad-builder/ad-editor-preview-icons/FeedIcon';
import AdEditorStoryIcon from 'components/ad-builder/ad-editor-preview-icons/StoryIcon';
import SocialIcon from 'components/icons/SocialIcon';
import { uniqueId } from 'lodash';

// Function to get most prominent colors
export const getMostProminentColors = (
  imageData: ImageData,
  topN: number = 5
): number[][] => {
  const colorMap: { [color: string]: number } = {};
  for (let i = 0; i < imageData.data.length; i += 4) {
    const r = imageData.data[i];
    const g = imageData.data[i + 1];
    const b = imageData.data[i + 2];
    const color = `${r},${g},${b}`;
    colorMap[color] = (colorMap[color] || 0) + 1;
  }

  return Object.entries(colorMap)
    .sort((a, b) => b[1] - a[1])
    .slice(0, topN)
    .map(([color]) => color.split(',').map(Number));
};

export const getFormattedChannel = (channel: AdChannel) => {
  switch (channel) {
    case AdChannel.FACEBOOK:
      return 'Facebook';
    case AdChannel.INSTAGRAM:
      return 'Instagram';
    case AdChannel.LINKEDIN:
      return 'LinkedIn';
    case AdChannel.TIKTOK:
      return 'TikTok';
    case AdChannel.INDEED:
      return 'Indeed';
    case AdChannel.GOOGLE:
      return 'Google';
  }
};

export const getChannelIcon = (channel: AdChannel, size?: number) => {
  if (!size) size = 16;
  return (
    <SocialIcon
      company={channel}
      width={`${size}px`}
      height={`${size}px`}
      color={'currentColor'}
      grayScale
    />
  );
};

export const getVariantIcon = (
  placement: AdPlacement,
  creativeType = AdCreativeType.IMAGE
) => {
  switch (placement) {
    case AdPlacement.FEED:
      switch (creativeType) {
        case AdCreativeType.IMAGE:
          return <AdEditorFeedIcon />;
        case AdCreativeType.VIDEO:
          return <AdEditorFeedIcon isVideo />;
      }
    // eslint-disable-next-line no-fallthrough
    case AdPlacement.STORIES:
      switch (creativeType) {
        case AdCreativeType.IMAGE:
          return <AdEditorStoryIcon />;
        case AdCreativeType.VIDEO:
          return <AdEditorStoryIcon isVideo />;
      }
    // eslint-disable-next-line no-fallthrough
    case AdPlacement.REELS:
      return <AdEditorStoryIcon isReel />;
  }
};

export const isVariantSupported = (
  placement: AdPlacement,
  channel: AdChannel,
  creativeType: AdCreativeType
) => {
  switch (placement) {
    case AdPlacement.FEED:
      switch (creativeType) {
        case AdCreativeType.IMAGE:
        case AdCreativeType.VIDEO:
          switch (channel) {
            case AdChannel.FACEBOOK:
            case AdChannel.INSTAGRAM:
            case AdChannel.LINKEDIN:
              return true;
            default:
              return false;
          }
      }
    // eslint-disable-next-line no-fallthrough
    case AdPlacement.STORIES:
      switch (creativeType) {
        case AdCreativeType.IMAGE:
        case AdCreativeType.VIDEO:
          switch (channel) {
            case AdChannel.FACEBOOK:
            case AdChannel.INSTAGRAM:
              return true;
            default:
              return false;
          }
      }
    // eslint-disable-next-line no-fallthrough
    case AdPlacement.REELS:
      switch (creativeType) {
        case AdCreativeType.VIDEO:
          switch (channel) {
            case AdChannel.FACEBOOK:
            case AdChannel.INSTAGRAM:
            case AdChannel.TIKTOK:
              return true;
            default:
              return false;
          }
        default:
          return false;
      }
    default:
      return false;
  }
};

// Fabric SVG export includes a <rect> element in the <g> element containing the <text> element, this is unwanted
export const removeRectFromTextGroup = (svgElement: string) => {
  const fullSVG = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1">${svgElement}</svg>`;
  const svgDoc = new DOMParser().parseFromString(fullSVG, 'image/svg+xml');
  const groups = svgDoc.querySelectorAll('g');

  groups.forEach((group) => {
    const rect = group.querySelector('rect');
    const text = group.querySelector('text');

    // If the <g> contains both a <rect> and a <text>, remove the <rect>
    if (rect && text) group.removeChild(rect);
  });

  const s = new XMLSerializer().serializeToString(svgDoc.documentElement);
  return s.replace(/^<svg[^>]*>|<\/svg>$/g, '');
};

export const DEFAULT_VARIANT: AdVariant = {
  id: undefined,
  path: null,
  function: '',
  company: '',
  headline: '',
  display_link: undefined,
  logo: null,
  description: '',
  text: '',
  isDeleted: false,
  isDirty: true,
  placement: AdPlacement.FEED,
  creative_type: AdCreativeType.IMAGE,
  svg: null,
  uuid: uniqueId(),
};
