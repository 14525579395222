import React, { useEffect, useMemo } from 'react';
import AdEditorNavBar from 'components/ad-builder/ad-editor-preview-ad-variant-bar/Navbar';
import AdEditorPreview from 'components/ad-builder/Preview';
import AdEditorToolbar, {
  AdEditorButton,
  ToolbarOrientation,
} from 'components/ad-builder/Toolbar';
import Button from 'components/button/Button';
import Centered from 'components/centered/Centered';
import {
  Feedback,
  FeedbackStatus,
  FeedbackType,
} from 'components/feedback/FeedbackWidget';
import AppPage from 'components/page/app-page/AppPage';
import SaveButton from 'components/save-button/SaveButton';
import {
  DELETE_VARIANTS,
  UPDATE_EDITOR_DATA,
} from 'graphql/ad-variants/mutations';
import { GET_EDITOR_DATA } from 'graphql/vacancies/queries';
import useNavigationContext from 'hooks/context/nav-context';
import { useMutation } from 'hooks/sympl-mutation';
import { useQuery } from 'hooks/sympl-query';

import tw, { styled } from 'twin.macro';
import { AdEditorSavablePreviewState, AdVariant } from 'types/adEditorTypes';
import {
  AdChannel,
  AdCreativeType,
  AdPlacement,
  VISUAL_AD_CHANNELS,
} from 'types/adTypes';

import VariantGenerator from './VariantGenerator';
import MissingFields from 'components/page/app-page/MissingFields';
import useGetStartedContext from 'hooks/context/get-started-context';
import useApiList from 'hooks/useApiList';
import { EXPERIENCE } from 'types/targetingTypes';
import { Routes } from 'types/routeTypes';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import {
  getChannelIcon,
  getFormattedChannel,
  isVariantSupported,
} from 'utils/adEditorHelpers';
import ToolTip from 'components/tooltip/ToolTip';
import Sidebar from './Sidebar';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import { useNavigate } from 'react-router-dom';
import LogRocket from 'logrocket';
import { uniqueId } from 'lodash';

export interface AdEditorQueryResponse {
  editorData: {
    adFeedback?: {
      feedback_status: FeedbackStatus;
      previous_feedback: Feedback[];
    };
    adVariants: AdVariant[];
  };
}

interface AdEditorQueryPayload {
  vacancy: number;
}

export interface AdEditorDeleteVariantPayload {
  variantId: number;
  input: { vacancy_id: number };
}

export interface AdEditorUpdatePayload {
  input: { vacancy_id: number; adVariants: AdEditorSavablePreviewState[] };
}

export enum AdEditorSideBarTabs {
  VISUAL_OPTIONS = 'Visuals',
  OVERLAY_OPTIONS = 'Overlays',
  BANNER_OPTIONS = 'Banner Options',
  LOGO_OPTIONS = 'Logo',
  TEXT_OPTIONS = 'Text',
  SHAPE_OPTIONS = 'Shape',
  COLOR_OPTIONS = 'Color',
}

const AdEditor: React.FC = () => {
  const navigate = useNavigate();

  const { nextItem, refetchCheckList } = useGetStartedContext();
  const { addToast } = useToastNotifications();

  const { activeVacancy, vacIsBooster, brands, currentVacancy } =
    useNavigationContext();

  const {
    activeTab,
    currentVariant,
    previewChannel,
    setActiveTab,
    setPayload,
    setPreviewChannel,
    initVariants,
    variants,
  } = useAdEditorContext();

  const nonDeletedVariants = useMemo(
    () =>
      [...variants]
        .filter(({ isDeleted }) => !isDeleted)
        .sort(
          (a, b) =>
            Object.values(AdPlacement).indexOf(a.placement) -
            Object.values(AdPlacement).indexOf(b.placement)
        ),
    [variants]
  );

  const missingFields = useMemo(() => {
    return nonDeletedVariants.flatMap((variant) => {
      const messages = [];

      if (!variant.path)
        messages.push(`No visual set for ${variant.placement} variant`);

      if (variant.placement !== AdPlacement.STORIES && !variant.text)
        messages.push(`No ad text set for ${variant.placement} variant`);

      return messages;
    });
  }, [nonDeletedVariants]);

  const {
    loading: loadingEditorData,
    data: editorResponse,
    refetch: refetchEditorData,
  } = useQuery<AdEditorQueryResponse, AdEditorQueryPayload>(GET_EDITOR_DATA, {
    skip: !activeVacancy,
    variables: { vacancy: activeVacancy! },
    fetchPolicy: 'network-only',
  });

  const targeting = useMemo(() => {
    return currentVacancy?.targeting;
  }, [currentVacancy]);

  const previousFeedback =
    editorResponse?.editorData?.adFeedback?.previous_feedback;

  const feedbackStatus =
    editorResponse?.editorData?.adFeedback?.feedback_status;

  const hasUnsavedChanges = useMemo(
    () => !!variants.find(({ isDirty, isDeleted }) => isDirty || isDeleted),
    [variants]
  );

  useEffect(() => {
    if (editorResponse?.editorData.adVariants) {
      initVariants(
        editorResponse?.editorData.adVariants.map((variant) => ({
          ...variant,
          isDirty: false,
          isDeleted: false,
          company: variant.company ?? currentVacancy?.brand?.name, // TODO: can we remove this one?
          uuid: uniqueId(),
        }))
      );
    }
  }, [editorResponse?.editorData.adVariants]);

  const [
    updateVariants,
    { loading: updatingVariants, error: updateVariantsError },
  ] = useMutation<{}, AdEditorUpdatePayload>(UPDATE_EDITOR_DATA);

  const [
    deleteVariants,
    { loading: deletingVariants, error: deletingVariantsError },
  ] = useMutation<{}, AdEditorDeleteVariantPayload>(DELETE_VARIANTS);

  const updateAdHandler = async (): Promise<void> => {
    try {
      // Filter only dirty, non-deleted variants
      const dirtyVariants = nonDeletedVariants.filter(({ isDirty }) => isDirty);

      if (dirtyVariants.length) await updateVariantsHandler(dirtyVariants);

      const variantsToDelete = variants
        .filter(({ isDeleted }) => isDeleted)
        .filter(({ id }) => id);

      if (variantsToDelete.length)
        await Promise.all(
          variantsToDelete.map(({ id }) => deleteVariantsHandler(id!))
        );

      await refetchEditorData();
      await refetchCheckList();
    } catch (_) {}
  };

  const updateVariantsHandler = async (
    dirtyVariants: AdVariant[]
  ): Promise<void> => {
    try {
      const savableVariants = dirtyVariants.map((v) => ({
        ...v,
        creative_type:
          v.creative_type ??
          (v.placement === AdPlacement.REELS
            ? AdCreativeType.VIDEO
            : AdCreativeType.IMAGE),
        path_id: v.path?.id ?? null,
        logo_id: v.logo?.id ?? null,

        //DUMMY DATA
        logo_position: 'northeast',
        banner: true,
        banner_position: 'south',
        banner_title: currentVacancy?.brand?.default_banner_title ?? 'WANTED',
        banner_font_family: 'Arial',
        banner_font_size: '55',
      }));

      const { errors } = await updateVariants({
        variables: {
          input: {
            vacancy_id: activeVacancy as number,
            adVariants: savableVariants,
          },
        },
      });

      if (errors) Promise.reject();

      return Promise.resolve();
    } catch (_) {
      addToast({
        type: ToastTypes.ERROR,
        description: 'An error occurred while updating the ad variants',
      });
      return Promise.reject();
    }
  };

  const deleteVariantsHandler = async (variantId: number): Promise<void> => {
    try {
      const { errors } = await deleteVariants({
        variables: {
          variantId,
          input: {
            vacancy_id: activeVacancy as number,
          },
        },
      });

      addToast({
        type: errors ? ToastTypes.ERROR : ToastTypes.SUCCESS,
        description: errors
          ? 'An error occurred while deleting the ad variants'
          : 'Your ads have been successfully deleted',
      });

      return errors ? Promise.reject() : Promise.resolve();
    } catch (_) {
      return Promise.reject();
    }
  };

  const showVariantGenerator = useMemo(
    () => !loadingEditorData && !nonDeletedVariants.length,
    [nonDeletedVariants]
  );

  const { data: languages } = useApiList('languages');

  useEffect(() => {
    if (import.meta.env.PROD) LogRocket.init('2ky20e/sympl-customer-intake');
  }, []);

  useEffect(() => {
    // No need to fetch other data if we can't generate payload anyways
    if (!targeting) return;

    (async () => {
      const brandsData = brands;
      const jobCategory = targeting?.sub_job_type_name;

      const language =
        languages.find(({ key }) => key === targeting?.language_id)?.label ??
        'Dutch';

      setPayload({
        jobFunction: targeting?.vac_name ?? '',
        companyName:
          brandsData?.find(({ id }) => id === targeting?.brand_id)?.name ?? '',
        location: targeting?.locations?.[0]?.name ?? 'Antwerp',
        jobCategory: jobCategory ?? '',
        experienceLevel:
          targeting?.experience
            ?.map((code) => EXPERIENCE.find(({ code: c }) => c === code)?.name)
            .join(', ') ?? 'None',
        language,
      });
    })();
  }, [targeting, languages, setPayload]);

  const checkVariantSupported = (channel: AdChannel): boolean =>
    !!currentVariant &&
    isVariantSupported(
      currentVariant.placement,
      channel,
      currentVariant.creative_type ??
        (currentVariant.placement === AdPlacement.REELS ||
          channel === AdChannel.TIKTOK)
        ? AdCreativeType.VIDEO
        : AdCreativeType.IMAGE
    );

  const displaySaveBttn = () => {
    return (
      !showVariantGenerator && (
        <SaveButton
          loading={updatingVariants}
          shouldSave={hasUnsavedChanges}
          disabled={
            updatingVariants ||
            deletingVariants ||
            !hasUnsavedChanges ||
            missingFields.length > 0
          }
          error={updateVariantsError || deletingVariantsError}
          onClick={updateAdHandler}
        />
      )
    );
  };

  const showSidebar = !!activeTab && !showVariantGenerator;

  return (
    <AppPage
      heading="Ad Builder"
      disablePadding={true}
      loading={loadingEditorData || updatingVariants || deletingVariants}
      enableFeedback={true}
      feedback={
        vacIsBooster && feedbackStatus
          ? {
              status: feedbackStatus,
              type: FeedbackType.ADVERTISING,
              previousFeedback: previousFeedback ?? [],
              onSubmit: refetchEditorData,
            }
          : undefined
      }
      cta={
        <div tw="flex space-x-5">
          {!!(nonDeletedVariants.length && missingFields.length) && (
            <MissingFields missingFields={missingFields} />
          )}
          {!hasUnsavedChanges && nextItem?.key === 'vacancy_page' ? (
            <Button
              variant="indigo"
              onClick={() => navigate(Routes.JOB_POSTING)}
            >
              Go to next step &rarr;
            </Button>
          ) : (
            displaySaveBttn()
          )}
        </div>
      }
      isSideBarOpen={showSidebar}
      setActiveTab={setActiveTab}
      sideBarMenu={[
        AdEditorSideBarTabs.VISUAL_OPTIONS,
        AdEditorSideBarTabs.OVERLAY_OPTIONS,
      ]}
      activeTabIndex={
        Object.values(AdEditorSideBarTabs).indexOf(
          activeTab || AdEditorSideBarTabs.VISUAL_OPTIONS
        ) ?? 0
      }
      sideBarContent={showSidebar && <Sidebar />}
    >
      <div
        css={[
          tw`flex flex-col h-full lg:flex-row`,
          !showVariantGenerator ? tw`relative` : tw`py-20 bg-gray-50`,
        ]}
      >
        {!showVariantGenerator && <AdEditorNavBar />}
        <div tw="w-full h-full overflow-y-auto">
          <Centered id="container" tw="h-auto">
            {showVariantGenerator ? (
              <VariantGenerator refetchEditorData={refetchEditorData} />
            ) : (
              <EditorWrapper id="editor-wrapper" activeTab={activeTab}>
                <PreviewContainer>
                  <div>
                    <div tw="mb-4">
                      <AdEditorToolbar
                        orientation={ToolbarOrientation.HORIZONTAL}
                        divide={false}
                        customButtons={VISUAL_AD_CHANNELS.map((channel) => (
                          <ToolTip
                            text={
                              !checkVariantSupported(channel)
                                ? `${getFormattedChannel(
                                    channel
                                  )} does not support ${
                                    currentVariant?.placement
                                  } ads`
                                : 'Show a preview for' +
                                  ' ' +
                                  getFormattedChannel(channel)
                            }
                          >
                            <AdEditorButton
                              isActive={previewChannel === channel}
                              isDisabled={!checkVariantSupported(channel)}
                              onClick={() => {
                                if (!checkVariantSupported(channel)) return;
                                setPreviewChannel(channel);
                              }}
                            >
                              <div tw="[svg]:p-1">
                                {getChannelIcon(channel, 32)}
                              </div>
                            </AdEditorButton>
                          </ToolTip>
                        ))}
                      />
                    </div>

                    <AdEditorPreview />
                  </div>
                </PreviewContainer>
              </EditorWrapper>
            )}
          </Centered>
        </div>
      </div>
    </AppPage>
  );
};

const EditorWrapper = styled.div<{ activeTab?: AdEditorSideBarTabs }>`
  ${tw`relative h-full pt-4 pb-16 lg:pb-1 lg:grid`}
  ${({ activeTab }) => activeTab && tw`hidden`}
`;

const PreviewContainer = styled.div(
  tw`relative pb-5 flex flex-col-reverse items-center w-full lg:(block p-0 m-0) lg:w-full`
);

export default AdEditor;
