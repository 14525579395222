import WhatsAppButton from 'components/button/WhatsAppButton';
import SourceIcon from 'components/icon-card/SourceIcon';
import {
  Body,
  Caption,
  Headline,
  Link as LinkTypo,
} from 'components/typography/Typography';
import React from 'react';
import { ArrowRight, EnvelopeSimple, Phone } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import 'twin.macro';
import tw, { styled } from 'twin.macro';
import { Lead } from 'types/leads/types';
import { Routes } from 'types/routeTypes';

interface LeadCardProps {
  lead: Lead;
}
const LeadCard: React.FC<LeadCardProps> = ({ lead }) => {
  return (
    <li
      key={lead.id}
      tw="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
    >
      <div tw="flex w-full items-center justify-between space-x-6 p-6 relative min-h-[195px]">
        <div tw="flex-1 truncate">
          <div tw="flex items-center space-x-3 ">
            <Headline mb={0} isBold>
              {lead.firstname} {lead.lastname}
            </Headline>

            <div tw="absolute top-5 right-0">
              <SourceIcon source={lead.source} />
            </div>
          </div>
          <Caption mb={2}>{lead.captured_at}</Caption>
          <Body isLight>{lead.email}</Body>
          {lead.phone && <Body isLight>{lead.phone}</Body>}
          {lead.procedure_id && (
            <Link to={`${Routes.PROCEDURES}/${lead.procedure_id}`}>
              <LinkTypo isFlex isBold mt={2}>
                View information
                <span tw="ml-1.5 mt-1">
                  <ArrowRight weight="bold" />
                </span>
              </LinkTypo>
            </Link>
          )}
        </div>
      </div>
      <div>
        <div tw="-mt-px flex divide-x divide-gray-200">
          <ContactIcon>
            <a href={`mailto:${lead.email}`}>
              <EnvelopeSimple
                weight="bold"
                tw="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {!lead.phone && <span tw="ml-3">Email</span>}
            </a>
          </ContactIcon>
          {lead.phone && (
            <>
              <ContactIcon>
                <a href={`tel:${lead.phone}`}>
                  <Phone
                    weight="fill"
                    tw="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </a>
              </ContactIcon>
              <ContactIcon>
                <WhatsAppButton phone={lead?.phone} isIcon />
              </ContactIcon>
            </>
          )}
        </div>
      </div>
    </li>
  );
};

const ContactIcon = styled.div(() => [
  tw`
    flex w-0 flex-1 justify-center items-center py-4
  `,
]);

export default LeadCard;
