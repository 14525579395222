import React from 'react';
import { styled } from 'twin.macro';

import RadioButton, { RadioItem } from './RadioButton';

interface IProps {
  name: string;
  items: RadioItem[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup = (props: IProps) => {
  return (
    <ul id={props.name} tw="w-full">
      {props.items.map((item) => (
        <RadioListItem tw="rounded-md ring-1 ring-black/5" key={item.id}>
          <RadioButton
            groupName={props.name}
            onChange={(e) => props.onChange(e)}
            item={item}
          />
        </RadioListItem>
      ))}
    </ul>
  );
};

const RadioListItem = styled.li`
  margin: 0 0 0.5rem 0;

  &:last-of-type {
    margin: 0;
  }
`;

export default RadioGroup;
