import tw, { styled } from 'twin.macro';
export const ITEM_HEIGHT = 70;

export const SectionColumn = styled.div<{ isDragging?: boolean }>`
  ${tw`
    w-full 
    h-full
    flex 
    flex-col
    p-2
  `}
  ${({ isDragging }) =>
    isDragging &&
    tw`
      bg-gray-100
  `}
`;

export const SectionItemsContainer = styled.div`
  ${tw`
    p-2 
    pb-8
    w-full 
    h-full 
    flex 
    flex-col 
    items-start 
    justify-start 
    flex-nowrap 
    overflow-y-auto
  `}
`;
export const SectionList = styled.div`
  ${tw`
    flex
    w-full 
    h-auto
    flex-grow
    overflow-y-auto
  `}
`;
export const SectionItem = styled.div<{ isHidden?: boolean }>`
  ${tw`
    w-full 
    flex 
    items-center 
    justify-start
    m-0
    p-4 
    border-2 
    border-gray-100 
    rounded 
    bg-white
  `}
  ${({ isHidden }) =>
    isHidden &&
    tw`
    bg-gray-300
    opacity-20
  `}
`;
