import React from 'react';
import { Routes } from 'types/routeTypes';

interface Notification {
  id: string;
  type: NotificationTypes;
  description?: string;
}

export interface AppNotification extends Notification {
  actionLabel?: string;
  customAction?: () => void;
  customIcon?: React.ReactNode;
  date: Date;
  filter?: NotificationFilterTypes;
  id: string;
  status: NotificationStatus;
  title: string;
  type: NotificationTypes;
  url?: string;
}

export interface ToastNotification
  extends Omit<AppNotification, 'type' | 'filter' | 'status' | 'url'> {
  type?: ToastTypes;
  cta?: {
    route: Routes;
    text: string;
  };
}

export interface NewAppNotification
  extends Omit<AppNotification, 'id' | 'date' | 'status'> {
  date?: Date;
  id?: string;
  type: NotificationTypes;
}

export interface NewToastNotification
  extends Omit<ToastNotification, 'id' | 'date' | 'status'> {
  date?: Date;
  id?: string;
  type?: ToastTypes;
}

export enum NotificationStatus {
  NEW,
  SEEN,
  READ,
}

export enum NotificationTypes {
  INVALID_REQUEST,
  PROCEDURE_OVERVIEW,
  COMMUNICATION_INCOMING,
}

export enum NotificationFilterTypes {
  SOCIAL = 'Social',
  APPLICATION = 'Application',
}

export enum ToastTypes {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  ACTION = 'action',
  SUCCESS = 'success',
}
