import tw, { styled } from 'twin.macro';

const Title = styled.h2(() => [
  tw`
    text-base font-bold leading-7 text-gray-700
    sm:(text-lg truncate)
    md:(text-xl)
  `,
]);

export default Title;
