import { SectionType, AvailableSections } from '@symplbe/sympl-components';
import React, { FC } from 'react';
import SectionIcons from '../page-settings-sidebar/section-list-settings/SectionIcons';
import Button from 'components/button/Button';
import 'twin.macro';
import tw from 'twin.macro';
import { Title1, Title2 } from 'components/typography/Typography';

interface CareerAddSectionModalProps {
  onAddSection: (sectionType: SectionType) => void;
}

const CareerAddSectionModal: FC<CareerAddSectionModalProps> = ({
  onAddSection,
}) => {
  const renderSections = () => {
    return (
      <div tw="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5  gap-4">
        {(Object.keys(SectionType) as Array<keyof typeof SectionType>)
          .filter((section) => section !== 'Footer')
          .map((sectionType, key) => {
            const isNotAvailable =
              !Object.keys(AvailableSections).includes(sectionType);
            return (
              <Button
                key={key}
                variant="default"
                disabled={isNotAvailable}
                customStyle={tw`flex flex-col items-center justify-center p-4`}
                onClick={() => onAddSection(SectionType[sectionType])}
              >
                <span tw="mb-2">
                  <SectionIcons type={SectionType[sectionType]} size={40} />
                </span>
                <Title2>{sectionType}</Title2>
                {isNotAvailable && (
                  <span tw="text-xs text-gray-500">
                    Coming soon. Stay tuned!
                  </span>
                )}
              </Button>
            );
          })}
      </div>
    );
  };
  return (
    <div tw="w-full max-w-[1000px] flex flex-col items-center justify-start">
      <Title1>Pick a section</Title1>
      {renderSections()}
    </div>
  );
};

export default CareerAddSectionModal;
