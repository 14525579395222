import React, { FC, useCallback } from 'react';
import { Title1 } from 'components/typography/Typography';
import InputColorPicker from 'components/form/input/InputColorPicker';
import { CardsProps } from '@symplbe/sympl-components';
import useCareerPageContext from 'hooks/context/career-page-context';
import {
  DEFAULT_PRIMARY_COLOR,
  setColorsBySectionPrimaryColor,
} from 'utils/careers/section-helpers/sectionHelpers';

interface CardsConfigProps {
  sectionProps: CardsProps;
}

const CardsConfig: FC<CardsConfigProps> = ({ sectionProps }) => {
  const { handleUpdatePageSections } = useCareerPageContext();

  /**
   * The mainColor props currently holds the primary color
   * based on the "branding_color.primary_color".
   * The mainColor is only applied whenever an addSection Event is fired
   *
   * outside addSection Event, the mainColor is independent from the branding_color's config
   * since in v1 there is no way to update the branding_color's config
   */
  const primaryColor = sectionProps.mainColor ?? DEFAULT_PRIMARY_COLOR;

  const updatePrimaryColor = useCallback(
    (color) => {
      if (sectionProps) {
        const { textColors, mainColor } = setColorsBySectionPrimaryColor(
          color,
          false
        );
        handleUpdatePageSections(sectionProps.sectionId as string, {
          mainColor,
          textColors: {
            ...sectionProps.textColors,
            ...textColors,
          },
        });
      }
    },
    [sectionProps]
  );

  return (
    <div>
      <Title1>Cards Config</Title1>
      <div tw="w-full">
        <div tw="mt-2">Color</div>
        <InputColorPicker
          name="primaryColor"
          defaultValue={primaryColor}
          rules={{ required: true }}
          onChange={updatePrimaryColor}
        />
      </div>
    </div>
  );
};

export default CardsConfig;
