import tw, { styled, css } from 'twin.macro';

export const CreatableInput = styled.input<{
  disabled?: boolean;
  fullWidth?: boolean;
  bgTransparent?: boolean;
}>`
  ${tw`
    bg-white 
    text-base 
    font-medium 
    text-gray-700 
    ring-1 
    ring-black/5 
    rounded 
    p-2
  `}
  ${({ disabled }) =>
    disabled &&
    tw`
      bg-gray-50 
      text-gray-500 
      cursor-not-allowed
    `}
  ${({ fullWidth }) => fullWidth && tw`w-full`}
`;

export const Label = styled.p`
  ${tw`text-xl`}
`;

export const Grid = styled.div<{ columns: number }>`
  ${tw` 
    grid 
    gap-4
    grid-cols-2
    md:grid-cols-[repeat(var(--grid-cols),_1fr)]
  `}

  ${({ columns }) => css`
    --grid-cols: ${columns};
  `}
`;

export const Radiobox = styled.label<{ selected?: boolean }>`
  ${tw`
      relative
      flex 
      items-center 
      justify-center 
      flex-col
      flex-wrap
      w-full
      p-4
      border 
      border-2
      border-gray-100
      rounded-md 
      cursor-pointer

      hover:border-gray-200
      hover:shadow-lg
  `}
  ${({ selected }) =>
    selected &&
    tw`
    ring-4
    ring-blue-500
    hover:shadow-lg
  `}
`;
