import tw, { styled } from 'twin.macro';

export const ContentContainer = tw.div`
  flex 
  flex-col 
  items-center 
  justify-start 
  w-full 
  text-center 
  max-w-[600px]
  px-4
  py-8 
  gap-5
`;

export const ActionsContainer = styled.div<{
  position?: 'left' | 'center' | 'between' | 'right';
}>`
  ${tw`
    flex 
    w-full 
    items-center 
    justify-between 
    border-t 
    border-t-2 
    border-gray-200
    gap-2
    p-4
  `}
  ${({ position }) => position === 'left' && tw` justify-start `}
  ${({ position }) => position === 'center' && tw`justify-center`}
  ${({ position }) => position === 'between' && tw`justify-between`}
  ${({ position }) => position === 'right' && tw`justify-end`}
`;
