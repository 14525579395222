import { LatLngTuple, Icon } from 'leaflet';
import {
  MIN_RADIUS_KM,
  TILE_PROVIDER_URL,
  MARKER_ICON_URL,
} from 'utils/geolocationHelpers';
import {
  Circle,
  Polyline,
  TileLayer,
  ZoomControl,
  Marker,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LocationType, TargetingLocation } from 'types/geolocationTypes';
import { useEffect, useState } from 'react';
import React from 'react';
const getLocationZoom = (type: LocationType) => {
  switch (type) {
    case LocationType.CITY:
      return 10;
    case LocationType.REGION:
      return 8;
    case LocationType.STATE:
      return 9;
    case LocationType.COUNTRY:
      return 7;
    default:
      return 10;
  }
};
const Map: React.FC<{ targetingLocations: TargetingLocation[] }> = ({
  targetingLocations = [],
}) => {
  const map = useMap();
  const MARKER_ICON = new Icon({
    iconUrl: MARKER_ICON_URL,
    iconSize: [27 * 0.734375, 27],
  });
  const resetZoomAndPosition = (location: TargetingLocation) => {
    map?.flyTo(location, getLocationZoom(location.type));
  };
  const [previousLocations, setPreviousLocations] = useState<
    TargetingLocation[]
  >([]);
  useEffect(() => {
    setPreviousLocations(targetingLocations);
  }, [targetingLocations]);
  useEffect(() => {
    if (previousLocations.length < targetingLocations.length) {
      // new item added, zoom to that
      const lastAddedLocation =
        targetingLocations[targetingLocations.length - 1];
      resetZoomAndPosition(lastAddedLocation);
    } else if (previousLocations.length === targetingLocations.length) {
      // an existing item was possibly changed
      for (let i = 0; i < targetingLocations.length; i++) {
        if (targetingLocations[i] !== previousLocations[i]) {
          // this item was changed, zoom to it
          resetZoomAndPosition(targetingLocations[i]);
          break;
        }
      }
    }
  }, [targetingLocations, previousLocations]);
  return (
    <>
      <ZoomControl position="topright" />
      <TileLayer url={TILE_PROVIDER_URL} />
      {targetingLocations.map(
        ({ polygon, lat, lng, radius, isCountry }, index) => {
          return (
            <div key={index}>
              {isCountry && polygon ? (
                <Polyline
                  key={'polyline-' + index}
                  pathOptions={{
                    color: 'green',
                    fill: true,
                    fillOpacity: 0.1,
                  }}
                  positions={polygon.data}
                  noClip={true}
                />
              ) : (
                <Marker
                  icon={MARKER_ICON}
                  key={'marker-' + index}
                  position={[lat, lng]}
                ></Marker>
              )}
              {!isCountry && (
                <Circle
                  key={'circle-' + index}
                  stroke={true}
                  opacity={0.75}
                  color="blue"
                  fillColor="lightblue"
                  center={[lat, lng] as LatLngTuple}
                  radius={(radius ?? MIN_RADIUS_KM) * 1000}
                />
              )}
            </div>
          );
        }
      )}
    </>
  );
};
export default Map;
