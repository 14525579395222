import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT_SETTINGS = gql`
  mutation updateAccountSettings {
    updateAccountSettings(input: $input)
      @rest(type: "User", path: "/v4/users/settings", method: "POST")
  }
`;

export const RESEND_INVITE_COLLEAGUE = gql`
  mutation resendInviteColleague($userId: ID!) {
    resendInviteColleague(input: {}, userId: $userId)
      @rest(
        type: "User"
        path: "/v4/users/{args.userId}/resend-invite"
        method: "POST"
      )
  }
`;

export const UNLINK_USER = gql`
  mutation unlinkUser($userId: ID!) {
    unlinkUser(input: {}, userId: $userId)
      @rest(
        type: "User"
        path: "/v4/users/{args.userId}/unlink"
        method: "POST"
      )
  }
`;

export const UPDATE_LAST_USED_CUSTOMER = gql`
  mutation updateLastUsedCustomer {
    updateLastUsedCustomer(input: $input)
      @rest(
        type: "Customer"
        path: "/v4/users/last-used-customer"
        method: "POST"
      )
  }
`;
