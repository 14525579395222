import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'hooks/sympl-mutation';
import { FormProvider, useForm } from 'react-hook-form';
import 'twin.macro';
import ConfigForm, { ConfigFormQuestionType } from './ConfigForm';
import SaveButton from 'components/save-button/SaveButton';
import AppPage from 'components/page/app-page/AppPage';
import { useQuery } from 'hooks/sympl-query';
import useNavigationContext from 'hooks/context/nav-context';
import { TargetingLocation } from 'types/geolocationTypes';
import { GET_TARGETING_CONFIG } from 'graphql/vacancies/queries';
import { UPDATE_TARGETING_CONFIG } from 'graphql/vacancies/mutations';
import useGetStartedContext from 'hooks/context/get-started-context';
import MissingFields from 'components/page/app-page/MissingFields';
import { JobType } from 'types/targetingTypes';
import { GET_JOBTYPES_SUBJOBTYPES } from 'graphql/jobtypes/queries';
import { getOpenAiThreadsResponse } from 'utils/openAiHelpers';
import Button from 'components/button/Button';
import { Routes } from 'types/routeTypes';
import { unset } from 'lodash';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import { useNavigate } from 'react-router-dom';

export type ConfigFormQuestionKey =
  | 'experience'
  | 'function_name'
  | 'job_type'
  | 'sub_job_type'
  | 'vac_name'
  | 'brand'
  | 'language'
  | 'location'
  | 'radius';

export interface TargetingFormData {
  section: string;
  questions: {
    key: ConfigFormQuestionKey;
    label: string;
    helper?: string;
    type: ConfigFormQuestionType;
    value?: string | number | TargetingLocation[] | string[] | null;
  }[];
}

export interface TargetingConfiguration {
  targetingConfig: {
    published: boolean;
    targeting: {
      job_type?: number;
      sub_job_type?: number | null;
      experience?: string[];
      locations?: TargetingLocation[];
      language_id?: number;
      vac_name?: string;
      brand_id?: number | null;
    };
  };
}

export interface TargetingPayload {
  vacancyId: number;
  input: {
    locations: TargetingLocation[];
    can_exp: string[];
    can_range: number;
    job_type: number;
    sub_job_type: number | null;
    language_id: number;
    vac_name: string;
    brand_id: number | null;
  };
}

const TargetingConfig: React.FC = () => {
  const navigate = useNavigate();
  const formMethods = useForm();
  const { nextItem, refetchCheckList } = useGetStartedContext();

  const {
    activeVacancy,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    brands,
    refetchCurrentVacancy,
  } = useNavigationContext();
  const { addToast } = useToastNotifications();

  const [formKey, setFormKey] = useState(0);
  const [vacancyIsPublished, setVacancyIsPublished] = useState(false);
  const [formData, setFormData] = useState<TargetingFormData[]>();
  const [isDoneUpdating, setIsDoneUpdating] = useState(false);
  const [suggestedJobType, setSuggestedJobType] = useState<JobType>();
  const [suggestedSubJobType, setSuggestedSubJobType] = useState<JobType>();

  const watchVac = formMethods.watch('vac_name');
  const watchBrand = formMethods.watch('brand');
  const watchJobType = formMethods.watch('job_type');
  const watchSubJobType = formMethods.watch('sub_job_type');
  const watchLocation = formMethods.watch('location');
  const missingFields = useMemo(
    () => [
      ...(!watchVac ? ['No function name provided'] : []),
      ...(!watchBrand ? ['No brand name provided'] : []),
      ...(!watchJobType ? ['No function type provided'] : []),
      ...(!(watchSubJobType || watchJobType === 18)
        ? ['No function subtype provided']
        : []),
      ...(!watchLocation || !(watchLocation as TargetingLocation[]).length
        ? ['No locations provided']
        : []),
    ],
    [watchBrand, watchJobType, watchLocation, watchSubJobType, watchVac]
  );

  const {
    loading: loadingConfig,
    data: configData,
    refetch,
  } = useQuery<TargetingConfiguration, { vacancyId: number }>(
    GET_TARGETING_CONFIG,
    {
      skip: !activeVacancy,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: { vacancyId: activeVacancy ?? 0 },
    }
  );

  const [
    updateConfig,
    { loading: updateConfigLoading, error: updateConfigError },
  ] = useMutation<undefined, TargetingPayload>(UPDATE_TARGETING_CONFIG);

  /**
   * Updates the form field value using a given section & question index.
   * @param sectionIndex The form section index
   * @param questionIndex The form question index
   * @param key The form question key
   * @param value The updated value
   */
  const formDataChangeHandler = (
    sectionIndex: number,
    questionIndex: number,
    _key: ConfigFormQuestionKey,
    value: string | number | TargetingLocation[] | string[] | null
  ) => {
    const data = formData ? [...formData] : [];
    if (!data?.[sectionIndex]?.questions?.[questionIndex]) return;
    data[sectionIndex].questions[questionIndex].value = value;
    setFormData(data);
    setHasUnsavedChanges(true);
  };

  /**
   * Gets a question value using it's corresponding key
   * @param key The question key
   * @returns The question values
   */
  const getConfigValueByKey = (key: ConfigFormQuestionKey) => {
    return formData
      ?.flatMap(({ questions }) => questions)
      ?.find((q) => q.key === key)?.value;
  };

  const submitForm = async () => {
    let isValid = await formMethods.trigger();
    // Sub job type not required when 'other' category is chosen
    if (
      formMethods.getValues('job_type') !== 18 &&
      !formMethods.getValues('sub_job_type')
    ) {
      formMethods.setError('sub_job_type', { type: 'required' });
      isValid = false;
    }
    if (loadingConfig || !isValid || !activeVacancy) return Promise.reject();

    // Parse the candidate location
    const locations = getConfigValueByKey('location') as
      | TargetingLocation[]
      | undefined;

    if (!locations) return Promise.reject();

    // if the locations' id matches the id of one or the default locations, remove the id
    const brandDefaultLocations = brands?.find(
      (b) => b.id === getConfigValueByKey('brand')
    )?.default_locations;

    locations.forEach((location) => {
      if (brandDefaultLocations?.some((l) => l.id === location.id)) {
        unset(location, 'id');
      }
    });

    const brand = getConfigValueByKey('brand') as string | number;

    // Construct the mutation payload
    const input: TargetingPayload['input'] = {
      locations: locations?.map((location) => {
        return { ...location, lon: location.lng };
      }) as any,
      can_exp: (getConfigValueByKey('experience') as string[]) ?? ['exp_none'],
      can_range: (getConfigValueByKey('radius') as number) ?? 16,
      job_type: (getConfigValueByKey('job_type') as number) ?? 0,
      sub_job_type: (getConfigValueByKey('sub_job_type') as number) ?? null,
      language_id: (getConfigValueByKey('language') as number) ?? 141,
      vac_name: getConfigValueByKey('vac_name')?.toString() ?? '',
      brand_id: (brand as number) ?? null,
    };

    await updateConfig({
      variables: {
        vacancyId: activeVacancy,
        input,
      },
    });

    addToast({
      type: ToastTypes.SUCCESS,
      description: 'Your targeting settings have been successfully updated',
    });

    refetch();
    refetchCurrentVacancy();

    refetchCheckList();
    setHasUnsavedChanges(false);
    return Promise.resolve();
  };

  const { data: jobsAndSubjobTypes } = useQuery<{ jobTypes: JobType[] }, {}>(
    GET_JOBTYPES_SUBJOBTYPES
  );

  const activeBrand = useMemo(
    () =>
      brands?.find(
        (b) => b.id === configData?.targetingConfig?.targeting?.brand_id
      ) ?? null,
    [brands, configData?.targetingConfig?.targeting]
  );

  const updateWithExternalData = useCallback(async () => {
    const config = configData?.targetingConfig?.targeting;

    setVacancyIsPublished(!!configData?.targetingConfig?.published);

    const data: TargetingFormData[] = [
      {
        section: 'What',
        questions: [
          {
            key: 'vac_name',
            label: 'What is the function title?',
            helper: 'This is the function title candidates will see.',
            type: 'vac-name',
            value: config?.vac_name ?? '',
          },
          {
            key: 'brand',
            label: "What is your company's brand name?",
            type: 'brand',
            value: config?.brand_id ?? null,
          },
          // The next two need to be adjacent to each other.
          // job_type needs to be followed directly by sub_job_type!
          {
            key: 'job_type',
            label: 'What is the function type?',
            helper: 'Choose the function type that is closest to your vacancy',
            type: 'job-type',
            value: config?.job_type ?? null,
          },
          {
            key: 'sub_job_type',
            label: '',
            type: 'sub-job-type',
            value: config?.sub_job_type ?? null,
          },
        ],
      },
      {
        section: 'Who',
        questions: [
          {
            key: 'language',
            label: "What's the language of this campaign?",
            helper:
              'This will be used for your ads, vacancy page & application form',
            type: 'language',
            value:
              config?.language_id ?? activeBrand?.default_language_id ?? '',
          },
          {
            key: 'experience',
            label: 'How many years of experience are ideal for this function?',
            type: 'experience',
            value: config?.experience ?? ['exp_none'],
          },
        ],
      },
      {
        section: 'Where',
        questions: [
          {
            key: 'location',
            label: 'Where do you want to look for candidates?',
            helper: 'Provide one or more towns, cities or countries.',
            type: 'geolocation',
            value: config?.locations ?? [],
          },
        ],
      },
    ];

    setFormData(data);
    setIsDoneUpdating(true);
    setFormKey(Math.random());
  }, [
    configData?.targetingConfig?.published,
    configData?.targetingConfig?.targeting,
  ]);

  useEffect(() => {
    updateWithExternalData();
  }, [configData?.targetingConfig?.targeting, updateWithExternalData]);

  useEffect(() => {
    const config = configData?.targetingConfig?.targeting;
    (async () => {
      // If there is no job type, use AI to pre-fill it
      if (config && !config.job_type) {
        try {
          const subJobTypeResponse = await getOpenAiThreadsResponse({
            vacancy_id: activeVacancy!,
            identifier: 'vac_targeting_jobtype',
            messages: [
              {
                role: 'user',
                content: `Identify the single most relevant category & subJob type for the function title '${config.vac_name}'`,
              },
            ],
          });
          const parsedResponse = JSON.parse(subJobTypeResponse.response);

          // traverse through Job types returned from the backend and look for the subJob that matches the description
          jobsAndSubjobTypes?.jobTypes.forEach((jobType: JobType) => {
            const foundSubJobType = jobType.sub_job_types.find(
              ({ name }) =>
                name.toLowerCase() === parsedResponse.subJob.toLowerCase()
            );

            if (foundSubJobType) {
              setSuggestedJobType(jobType);
              setSuggestedSubJobType(foundSubJobType as JobType);
            }
          });
        } catch (error: any) {
          throw new Error(error);
        }
      } else {
      }
    })();
  }, [configData?.targetingConfig?.targeting, jobsAndSubjobTypes?.jobTypes]);

  return (
    <AppPage
      heading="Targeting"
      loading={!isDoneUpdating}
      skeleton={
        <div
          tw="h-full grid grid-cols-2 grid-rows-2 gap-4 mt-8"
          style={{
            gridTemplateAreas: '"what who" "where where"',
          }}
        >
          <div
            tw="bg-gray-200 h-full w-full animate-pulse rounded-md"
            css={{
              gridArea: 'what',
            }}
          />
          <div
            tw="bg-gray-200 h-full w-full animate-pulse rounded-md"
            css={{
              gridArea: 'who',
            }}
          />
          <div
            tw="bg-gray-200 h-full w-full animate-pulse rounded-md"
            css={{
              gridArea: 'where',
            }}
          />
        </div>
      }
      cta={
        <div tw="flex space-x-5">
          {!!missingFields.length && (
            <MissingFields missingFields={missingFields} />
          )}
          {!hasUnsavedChanges && nextItem && nextItem?.key !== 'targeting' ? (
            <Button
              variant="indigo"
              onClick={() =>
                navigate(
                  nextItem.key === 'advertising'
                    ? Routes.AD_EDITOR
                    : Routes.JOB_POSTING
                )
              }
            >
              Go to next step &rarr;
            </Button>
          ) : (
            <SaveButton
              shouldSave={hasUnsavedChanges}
              loading={updateConfigLoading}
              error={updateConfigError}
              onClick={submitForm}
            />
          )}
        </div>
      }
    >
      <FormProvider {...formMethods}>
        <ConfigForm
          formData={formData}
          key={formKey}
          isPublished={vacancyIsPublished}
          onChange={formDataChangeHandler}
          suggestedJobType={suggestedJobType}
          suggestedSubJobType={suggestedSubJobType}
        />
      </FormProvider>
    </AppPage>
  );
};

export default TargetingConfig;
