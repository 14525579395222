import React, { useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';

import { IntakeInputTypes } from 'types/intakeTypes';
import {
  getJsxForSymbol,
  questionValueToCoworkerArray,
  questionValueToFileUploadsWithText,
  questionValueToSelectableLanguageArray,
} from 'utils/typeHelpers';
import {
  commaSeparatedStringToStringArray,
  getTrimmedHtml,
  isTextEditorEmpty,
} from 'utils/baseHelpers';
import { FileUploadsWithText } from 'types/fileTypes';
import CustomerResourcePreviewList from '../upload/preview/preview-list/ResourceList';
import { AdEditorProvider } from 'context/AdEditorContext';
import { Body, Subhead, Title3 } from 'components/typography/Typography';
import { ModifiedIntakeQuestion } from 'components/questions/QuestionAnswerList';
import { COUNTRIES } from 'data/geo/countries';
import { EXPERIENCE } from 'types/targetingTypes';

const QuestionAnswer: React.FC<{
  item: ModifiedIntakeQuestion;
}> = ({ item: { type, value, answer, annotation, label, apiList } }) => {
  const questionType = type
    ? type.replaceAll('_', '').toLowerCase()
    : IntakeInputTypes.TEXT;

  const isUploadQuestion =
    value &&
    (questionType === IntakeInputTypes.UPLOAD ||
      questionType === IntakeInputTypes.UPLOAD_MULTI ||
      questionType === IntakeInputTypes.UPLOAD_MULTI_WITH_TEXT ||
      questionType === IntakeInputTypes.UPLOAD_WITH_TEXT);

  const isNormalQuestion =
    value &&
    questionType !== IntakeInputTypes.API_SELECT &&
    questionType !== IntakeInputTypes.TEXTEDITOR &&
    questionType !== IntakeInputTypes.COLOR &&
    questionType !== IntakeInputTypes.INVITECOWORKER &&
    questionType !== IntakeInputTypes.LANGUAGESELECT &&
    questionType !== IntakeInputTypes.MULTIPLECHOICE &&
    questionType !== IntakeInputTypes.UPLOAD &&
    questionType !== IntakeInputTypes.UPLOAD_MULTI &&
    questionType !== IntakeInputTypes.UPLOAD_MULTI_WITH_TEXT &&
    questionType !== IntakeInputTypes.UPLOAD_WITH_TEXT &&
    questionType !== IntakeInputTypes.TEXTGROUP_WITH_SYMBOL &&
    questionType !== IntakeInputTypes.TEXTGROUP &&
    questionType !== IntakeInputTypes.SINGLECHOICE;

  const isTextGroupQuestion =
    value !== undefined && questionType === IntakeInputTypes.TEXTGROUP;

  const isTextGroupSymbolQuestion =
    value !== undefined &&
    questionType === IntakeInputTypes.TEXTGROUP_WITH_SYMBOL;

  const coworkers =
    questionType === IntakeInputTypes.INVITECOWORKER
      ? questionValueToCoworkerArray(value)
      : [];

  const languages =
    questionType === IntakeInputTypes.LANGUAGESELECT
      ? questionValueToSelectableLanguageArray(value)
      : [];

  const uploads = isUploadQuestion
    ? questionValueToFileUploadsWithText(value)
    : ({ files: [], text: '' } as FileUploadsWithText);

  const textGroupValue = value as string[];

  const getStringValue = () => {
    return isNormalQuestion
      ? isTextGroupQuestion
        ? (JSON.parse(value?.toString() as string) as string[])
        : commaSeparatedStringToStringArray(value?.toString() as string)
      : undefined;
  };

  const address = useMemo((): any => {
    if (questionType === IntakeInputTypes.CUSTOMER_ADDRESS && value) {
      return JSON.parse(value as string);
    }

    if (questionType === IntakeInputTypes.GEOLOCATION && value) {
      return JSON.parse(value as string)[0];
    }

    return {};
  }, [questionType, value]);

  const getExperience = () => {
    // Split the value by commas and trim whitespace
    const experienceCodes =
      value
        ?.toString()
        .split(',')
        .map((code) => code.trim()) ?? [];

    const experienceNames = experienceCodes
      .map(
        (code) => EXPERIENCE.find(({ code: expCode }) => expCode === code)?.name
      )
      .filter((name) => name !== undefined); // Filter out undefined names

    // Return a readable string or a default message if no experiences are found
    return experienceNames.length > 0 ? experienceNames.join(', ') : 'None';
  };

  return (
    <QuestionAnswerWrapper>
      <p tw="font-semibold">{label}</p>
      {value && !isTextEditorEmpty(value as string) ? (
        <div>
          {isNormalQuestion && getStringValue() && (
            <>
              {questionType !== IntakeInputTypes.GEOLOCATION &&
              questionType !== IntakeInputTypes.CUSTOMER_ADDRESS &&
              (questionType === IntakeInputTypes.ADDRESS ||
                [getStringValue()].length === 1) ? (
                <Answer>{answer}</Answer>
              ) : (
                address && (
                  <Answer>
                    {address.road} {address.house_number}{' '}
                    {address.box_number !== 'null' ? address.box_number : ''}
                    <br />
                    {address.zip_code} {address.city}
                    {', '}
                    {address.country_code &&
                      COUNTRIES.filter(
                        (country) =>
                          country.key.toLowerCase() === address.country_code
                      )[0]?.label}
                    {!address.country_code &&
                      address.country &&
                      address.country}
                  </Answer>
                )
              )}
            </>
          )}
          {questionType === IntakeInputTypes.SINGLECHOICE && (
            <Answer>{value?.toString().slice(2, -2)}</Answer>
          )}
          {questionType === IntakeInputTypes.COLOR && (
            <Answer>
              <Color color={value as string} />
              {value}
            </Answer>
          )}
          {(isTextGroupQuestion || isTextGroupSymbolQuestion) &&
            textGroupValue && (
              <>
                {isTextGroupQuestion ? (
                  <BulletList>
                    {(textGroupValue as string[]).map((item, index) => (
                      <BulletListItem key={index}>{item}</BulletListItem>
                    ))}
                  </BulletList>
                ) : (
                  <ul>
                    {(
                      textGroupValue as {
                        value?: string;
                        symbol?: string;
                      }[]
                    ).map((item, index) => (
                      <li tw="flex text-gray-700 font-semibold" key={index}>
                        {item?.symbol && getJsxForSymbol(item.symbol)}{' '}
                        <p tw="ml-2">{item?.value}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          {isUploadQuestion && (
            <UploadWrapper>
              {uploads.files && uploads.files.length > 0 && (
                <AdEditorProvider>
                  <CustomerResourcePreviewList
                    allowDelete={false}
                    allowDownload={true}
                    resources={uploads.files}
                  />
                </AdEditorProvider>
              )}
              <div />
              {uploads.text && (
                <EditorTextWrapper>
                  <p
                    tw="w-full"
                    className="dangerouslySetInnerHTML"
                    dangerouslySetInnerHTML={{
                      __html: getTrimmedHtml(uploads.text as string),
                    }}
                  />
                </EditorTextWrapper>
              )}
            </UploadWrapper>
          )}
          {questionType === IntakeInputTypes.INVITECOWORKER && (
            <ul tw="flex flex-wrap mt-2 gap-4">
              {coworkers &&
                coworkers.map((worker) => (
                  <CoworkerItem key={worker.email}>
                    <Title3 isTruncate mb={0}>
                      {worker.firstname} {worker.lastname}
                    </Title3>
                    <Subhead isTruncate>{worker.function}</Subhead>
                    <Body isLight isTruncate>
                      {worker.isLeadRecruiter ? 'Lead recruiter' : <br />}
                    </Body>
                    <Body isLight isTruncate>
                      {worker.email}
                    </Body>
                    {worker.mobile && (
                      <Body isLight isTruncate>
                        {worker.mobile}
                      </Body>
                    )}
                  </CoworkerItem>
                ))}
            </ul>
          )}
          {questionType === IntakeInputTypes.API_SELECT && (
            <Answer>
              <p
                css={[
                  apiList && apiList === 'languages'
                    ? tw`uppercase`
                    : tw`capitalize`,
                ]}
              >
                {apiList && apiList === 'years_of_experience'
                  ? getExperience()
                  : (answer as string).replaceAll('_', ' ').toLowerCase()}
              </p>
            </Answer>
          )}
          {questionType === IntakeInputTypes.LANGUAGESELECT && (
            <ul tw="flex flex-wrap space-x-4">
              {languages &&
                languages.map((language) => (
                  <LanguageItem key={language.name}>
                    <Body>
                      {language.name} - {language.level}
                    </Body>
                  </LanguageItem>
                ))}
            </ul>
          )}
          {questionType === IntakeInputTypes.MULTIPLECHOICE && (
            <BulletList>
              {JSON.parse(value as string).map((item: string) => (
                <BulletListItem key={item}>{item}</BulletListItem>
              ))}
            </BulletList>
          )}
          {questionType === IntakeInputTypes.TEXTEDITOR &&
            getTrimmedHtml(value as string) && (
              <EditorTextWrapper>
                <p
                  tw="w-full"
                  className="dangerouslySetInnerHTML"
                  dangerouslySetInnerHTML={{
                    __html: getTrimmedHtml(value as string),
                  }}
                />
              </EditorTextWrapper>
            )}
          {annotation && (
            <div tw="mt-3 mb-4 flex bg-gray-100 flex-col text-gray-700 p-2 border border-gray-200 rounded-md">
              <p tw="uppercase text-gray-500 tracking-wide text-xs">
                Annotation
              </p>
              <Body>{annotation}</Body>
            </div>
          )}
        </div>
      ) : (
        <Answer isEmpty>N/A</Answer>
      )}
    </QuestionAnswerWrapper>
  );
};

const QuestionAnswerWrapper = styled.div(tw`border shadow-lg p-6 rounded-md`);

const Answer = styled.p<{ isEmpty?: boolean }>`
  ${tw`flex items-center text-gray-700 mt-0`}
  ${({ isEmpty }) =>
    isEmpty &&
    tw`
   text-xs text-gray-400 font-semibold italic
`}
`;

const BulletList = styled.ul(tw`list-disc list-inside`);

const BulletListItem = styled.li(tw`text-gray-700 font-semibold`);

const Color = styled.button<{
  color: string;
}>`
  ${tw`mr-2 rounded-lg h-4 w-4`}
  ${({ color }) => css`
    background-color: ${color};
  `}
`;

const EditorTextWrapper = styled.div(
  tw`flex rounded-md p-4 bg-white w-full border mt-1 min-h-[50px]`
);

const CoworkerItem = styled.li(
  tw`flex flex-col ring-1 ring-black/5 p-4 rounded-md shadow-sm mb-0`
);

const LanguageItem = styled.li(tw`ring-1 ring-black/5 p-4 rounded-md my-2`);

const UploadWrapper = styled.div(tw`flex flex-col mb-1`);

export default QuestionAnswer;
