import { gql } from '@apollo/client';

export const GET_REPORTING_DATA = gql`
  query getReportingData($queryString: String) {
    reporting(queryString: $queryString)
      @rest(type: "ReportingData", path: "/v4/reporting?{args.queryString}") {
      total {
        clicks
        visits
        impressions
        applications
        spend
        ctr
        conversion
        cpc
        cpm
        cpa
      }
      per_date
    }
  }
`;

export const GET_REPORTING_DATA_PER_CHANNEL = gql`
  query getReportingDataPerChannel($queryString: String) {
    reporting(queryString: $queryString)
      @rest(
        type: "ReportingData"
        path: "/v4/reporting/channels?{args.queryString}"
      ) {
      total {
        clicks
        visits
        impressions
        applications
        spend
        ctr
        conversion
        cpc
        cpm
        cpa
      }
      per_channel
    }
  }
`;

export const GET_REPORTING_DATA_PER_CREATIVE = gql`
  query getReportingDataPerCreative($queryString: String) {
    reporting(queryString: $queryString)
      @rest(
        type: "ReportingData"
        path: "/v4/reporting/creatives?{args.queryString}"
      ) {
      image {
        clicks
        visits
        impressions
        applications
        spend
        ctr
        conversion
        cpc
        cpm
        cpa
      }
      video {
        clicks
        visits
        impressions
        applications
        spend
        ctr
        conversion
        cpc
        cpm
        cpa
      }
      total {
        clicks
        visits
        impressions
        applications
        spend
        ctr
        conversion
        cpc
        cpm
        cpa
      }
    }
  }
`;

export const GET_IMAGE_DATA = gql`
  query getImageData($queryString: String) {
    reporting(queryString: $queryString)
      @rest(
        type: "ReportingData"
        path: "/v4/reporting/creatives/image?{args.queryString}"
      ) {
      imageDetails
    }
  }
`;

export const GET_VIDEO_DATA = gql`
  query getVideoData($queryString: String) {
    reporting(queryString: $queryString)
      @rest(
        type: "ReportingData"
        path: "/v4/reporting/creatives/video?{args.queryString}"
      ) {
      videoDetails
    }
  }
`;
