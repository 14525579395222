import React from 'react';
import { Plan, Interval, Tier, Subscription } from 'types/subscriptions/types';
import { Body, Title2 } from 'components/typography/Typography';

import Button from 'components/button/Button';
import { useMutation } from 'hooks/sympl-mutation';
import tw, { styled } from 'twin.macro';
import PlanPrice from './PlanPrice';
import PlanFeatures from './PlanFeatures';
import useNavigationContext from 'hooks/context/nav-context';
import { isUpgradeDowngrade, isWTTrial } from 'utils/subscriptions/helpers';
import { getDateString } from 'utils/dateHelpers';
import { UPDATE_AUTO_RENEW } from 'graphql/subscriptions/mutations';
import ConfirmActionModal from 'components/modals/confirm-action-modal/ConfirmActionModal';
import useModalContext from 'hooks/context/modals-context';
import { fireEvent } from 'utils/eventHelper';
import { showIntercom } from 'utils/intercomHelper';

interface PlanCardProps {
  subscription?: Subscription;
  plan: Plan;
  selectedInterval: Interval;
}

const PlanCard: React.FC<PlanCardProps> = ({ plan, selectedInterval }) => {
  const { subscription, refetchCustomer, isAdmin } = useNavigationContext();
  const { pushModal } = useModalContext();

  /** Old code, for whenever tiers return
  const [updatePlanCall, { loading: updatingPlan }] = useMutation<
    { data: { session_id?: string } },
    { customerId: number; input: { plan_price_id: number } }
  >(UPDATE_PLAN);

  const updatePlanHandler = (tier: Tier) => {
    const planPrice = plans
      ?.find((p) => p.tier === tier)
      ?.prices?.find((pr) => pr.interval === selectedInterval)?.id;

    updatePlanCall({
      variables: {
        customerId: activeCustomer ?? 0,
        input: {
          plan_price_id: planPrice ?? 0,
        },
      },
    }).then(async (res) => {
      const sessionId = res?.data?.data.session_id;
      if (sessionId) {
        redirectToStripeCheckout(sessionId);
      } else {
        navigate(Routes.SUBSCRIPTION_UPDATED);
      }
    });
  };
  */

  const scheduledSubscription = subscription?.scheduled;

  const [updateAutoRenew, { loading: updatingAutoRenew }] = useMutation<
    undefined,
    {
      input: {
        auto_renew: boolean;
        reason?: string;
      };
    }
  >(UPDATE_AUTO_RENEW);

  const confirmCancelSubscription = () => {
    const modalId = `update-auto-renew`;
    pushModal({
      show: true,
      twclass: tw`sm:(pl-0 pt-[40px] pb-0 pr-0) max-h-[unset]`,
      children: (
        <ConfirmActionModal
          modalId={modalId}
          actionBtnPostion="right"
          header={<div tw="px-10">Let us know why you are opting out.</div>}
          confirmButtonLabel="Cancel Subscription"
          confirmButtonProps={{ variant: 'danger' }}
          confirmActionFn={(response) =>
            updateAutoRenewHandler(false, response?.reason)
          }
          cancelButtonLabel="Cancel"
          reasons={[
            "Don't use it enough",
            'Too expensive',
            'Found another solution',
            'Quality of candidates is insufficient',
            'Quantity of candidates is insufficient',
            'Product is not what we expected',
            'Other',
          ]}
        />
      ),
      key: modalId,
    });
  };

  const updateAutoRenewHandler = (autoRenew: boolean, reason?: string) => {
    updateAutoRenew({
      variables: {
        input: {
          auto_renew: autoRenew,
          ...(reason && { reason }),
        },
      },
    })
      .then(async () => refetchCustomer())
      .then(() =>
        fireEvent('subscription_cancelled', {
          subscription: subscription?.id,
        })
      );
  };

  const toggleIsCancelled = () => {
    if (subscription?.is_cancelled) {
      updateAutoRenewHandler(subscription.is_cancelled);
    } else {
      confirmCancelSubscription();
    }
  };

  const isCurrentPlan =
    subscription?.plan.tier === plan.tier &&
    subscription?.interval === selectedInterval;

  const showUpgradeButton =
    !subscription?.scheduled && subscription?.plan.tier !== Tier.ENTERPRISE;

  return (
    <SubcriptionCardContainer key={plan.name} isActive={isCurrentPlan}>
      {isCurrentPlan && (
        <CardBadge isSelected isActive={isCurrentPlan}>
          Current plan
        </CardBadge>
      )}
      <SubscriptionCard>
        <div tw="grid grid-cols-2 gap-10">
          <div>
            <Title2>{plan.name}</Title2>

            <div>
              {subscription && !isWTTrial(subscription) && (
                <PlanPrice
                  price={subscription.price}
                  interval={subscription.interval}
                />
              )}

              {subscription && (
                <Body mt={2}>
                  {`Your ${subscription.is_trial ? 'trial' : 'subscription'} ${
                    subscription.is_cancelled ? 'ends' : 'renews'
                  } on ${getDateString(subscription.current_period.end)}.`}
                  <br />
                  {scheduledSubscription &&
                    ` It will change to the ${
                      scheduledSubscription.plan.name
                    } (${scheduledSubscription.interval
                      .replace('_', '-')
                      .toLowerCase()}) plan.`}
                </Body>
              )}

              {subscription && (!subscription?.disable_cancel || isAdmin) && (
                <div tw="mt-4">
                  <Button
                    stretch
                    variant={subscription?.is_cancelled ? 'link' : 'inverted'}
                    onClick={() => {
                      fireEvent('could_cancel_subscription', {
                        subscription: subscription?.id,
                      });
                      toggleIsCancelled();
                    }}
                    disabled={updatingAutoRenew}
                  >
                    {subscription?.is_cancelled ? 'Do not cancel ' : 'Cancel '}
                    subscription
                  </Button>
                </div>
              )}

              {showUpgradeButton && (
                <div tw="mt-2 flex flex-col items-center">
                  <Button
                    stretch
                    onClick={() => {
                      fireEvent('interested_in_upgrade');
                      showIntercom('Hi! I want to upgrade my plan 🙋‍♂️🙋‍♀️');
                    }}
                  >
                    {isUpgradeDowngrade(subscription?.plan.tier, plan.tier) +
                      ' subscription'}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <PlanFeatures features={plan.features ?? []} />
        </div>
      </SubscriptionCard>
    </SubcriptionCardContainer>
  );
};

const SubcriptionCardContainer = styled.div<{
  isActive: boolean;
}>`
  ${tw` relative rounded-lg px-7 py-8 shadow-sm ring ring-gray-300`}

  ${({ isActive }) => isActive && tw`ring-gray-700`}
`;

const SubscriptionCard = styled.div`
  ${tw`
    flex 
    flex-col 
    justify-center 
    divide-y 
    divide-solid 
    divide-gray-200
    md:flex-row 
    md:divide-x 
    md:divide-y-0
  `}
`;

const CardBadge = styled.span<{
  isSelected?: boolean;
  isActive?: boolean;
}>`
  ${tw`absolute right-0 top-0 mx-auto rounded-bl-md px-6 py-1 text-sm text-white`}

  ${({ isSelected, isActive }) => (isSelected || isActive) && tw`bg-gray-700`}
`;

export default PlanCard;
