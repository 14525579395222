import { gql } from '@apollo/client';

export const GET_JOBTYPES_SUBJOBTYPES = gql`
  query getJobTypes {
    jobTypes @rest(type: "[JobType]", path: "/v4/jobtypes") {
      id
      name
      sub_job_types @type(name: "[SubJobType]") {
        id
        name
      }
    }
  }
`;
