import { gql } from '@apollo/client';

export const CREATE_INTEGRATION = gql`
  mutation CreateIntegration {
    createIntegration(input: $input)
      @rest(type: "Integration", path: "/v4/integrations", method: "POST") {
      id
    }
  }
`;

export const LINK_VACANCY_TO_EXTERNAL_JOB = gql`
  mutation LinkVacancyToExternalJob {
    linkVacancyToExternalJob(input: $input)
      @rest(
        type: "IntegrationExternalJobLink"
        path: "/v4/integrations/external-jobs/link"
        method: "POST"
      ) {
      id
    }
  }
`;

export const UNLINK_VACANCY_TO_EXTERNAL_JOB = gql`
  mutation UnlinkVacancyFromExternalJob {
    unlinkVacancyFromExternalJob(input: $input)
      @rest(
        type: "IntegrationExternalJobLink"
        path: "/v4/integrations/external-jobs/unlink"
        method: "POST"
      ) {
      id
    }
  }
`;
