import { gql } from '@apollo/client';

export const DELETE_CUSTOMER_RESOURCE = gql`
  mutation deleteCustomerResource($res_id: ID!) {
    deleteCustomerResource(res_id: $res_id)
      @rest(
        type: "CustomerResource"
        path: "/v4/customer-resources/{args.res_id}"
        method: "DELETE"
      )
  }
`;
