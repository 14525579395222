import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CareerPageAPIResponse,
  CareerPageResponse,
  SectionOrder,
} from 'types/careers-page/types';
import { GET_CUSTOMER_CAREER_PAGE } from 'graphql/careers/queries';
import { useQuery } from 'hooks/sympl-query';
import {
  AcceptedSections,
  SectionType,
  SectionTypeLabels,
} from '@symplbe/sympl-components';
import { useParams } from 'react-router-dom';
import {
  CREATE_VERSION_HISTORY,
  FORCE_UPDATE_PAGE,
  UPDATE_PAGE_BRANDING,
  UPDATE_PAGE_SECTIONS,
  UPDATE_PAGE_SECTIONS_ORDER,
  UPDATE_PAGE_SEO,
  UPDATE_PAGE_STATUS,
} from 'graphql/careers/mutations';
import { useMutation } from 'hooks/sympl-mutation';
import {
  CareerPageProviderProps,
  CareerPageType,
  ForceUpdatePagePayload,
  PageParams,
  TActiveBlockType,
  TActiveEditedBlock,
  TAddSectionFn,
  UpdatePageBrandingPayload,
  UpdatePageSectionsOrderPayload,
  UpdatePageSectionsPayload,
  UpdatePageSeoPayload,
  UpdatePageStatusPayload,
} from 'types/careers-page/context';
import { isEqual, uniq } from 'lodash';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import {
  filterDeletedOrder,
  filterDeletedSections,
  getContactCards,
  getDefaultSectionValues,
  getHiddenKeys,
} from 'utils/careers/section-helpers/sectionHelpers';
import useModalContext from 'hooks/context/modals-context';
import ConfirmActionModal from 'components/modals/confirm-action-modal/ConfirmActionModal';
import tw from 'twin.macro';
import CareerAddSectionModal from 'components/careers/career-add-section-modal/CareerAddSectionModal';
import { v4 as uuid } from 'uuid';
import { PageSettingTabKeys } from 'components/careers/page-settings-sidebar/PageSettingsSidebar';
import useJobTypesContext from 'hooks/context/job-types-context';
import { fireEvent } from 'utils/eventHelper';

export const CareerPageContext = createContext<CareerPageType>(
  {} as CareerPageType
);

export const CareerPageProvider: FC<CareerPageProviderProps> = ({
  children,
}) => {
  const { pushModal, removeModal } = useModalContext();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [versionHistoryModalOpen, setVersionHistoryModalOpen] =
    useState<boolean>(false);

  // used for vacancies section
  const { jobsAndSubjobTypes } = useJobTypesContext();

  const [updatedKeys, setUpdatedKeys] = useState<string[]>([]);
  const [deletedSectionIds, setDeletedSectionIds] = useState<string[]>([]);

  const [activePageSettingKey, setActivePageSettingKey] =
    useState<PageSettingTabKeys | null>(null);

  const [activeBlockSection, setActiveBlockSection] =
    useState<TActiveBlockType | null>(null);
  const [activeEditedBlock, setActiveEditedBlock] =
    useState<TActiveEditedBlock | null>(null);

  const { addToast } = useToastNotifications();

  const [pageConfig, setPageConfig] = useState<CareerPageResponse>();
  const [pageInitialConfig, setPageInitialConfig] =
    useState<CareerPageResponse>();
  const { customerPageId } = useParams<PageParams>();

  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);
  const [hiddenInitialKeys, setHiddenInitialKeys] = useState<string[]>([]);

  const [updatePageStatus, { loading: pageStatusLoading }] = useMutation<
    undefined,
    UpdatePageStatusPayload
  >(UPDATE_PAGE_STATUS);

  const [updatePageBranding, { loading: pageBrandingLoading }] = useMutation<
    undefined,
    UpdatePageBrandingPayload
  >(UPDATE_PAGE_BRANDING);

  const [updatePageSeo, { loading: pageSeoLoading }] = useMutation<
    undefined,
    UpdatePageSeoPayload
  >(UPDATE_PAGE_SEO);

  const [updatePageSections, { loading: pageSectionsLoading }] = useMutation<
    undefined,
    UpdatePageSectionsPayload
  >(UPDATE_PAGE_SECTIONS);

  const [updatePageSectionsOrder, { loading: pageSectionsOrderLoading }] =
    useMutation<undefined, UpdatePageSectionsOrderPayload>(
      UPDATE_PAGE_SECTIONS_ORDER
    );

  const [createVersionHistory] = useMutation<
    undefined,
    { pageId: number; input: {} }
  >(CREATE_VERSION_HISTORY);

  const [forceUpdatePage, { loading: forceUpdatePageLoading }] = useMutation<
    undefined,
    ForceUpdatePagePayload
  >(FORCE_UPDATE_PAGE);

  const addSection: TAddSectionFn = useCallback(
    (type: SectionType, customConfig?: Partial<AcceptedSections>) => {
      if (!pageConfig) return;

      const newSectionId = `${type}-${uuid()}`;
      const newOrder: SectionOrder = {
        sectionId: newSectionId,
        isHidden: false,
      };

      const defaultValues = getDefaultSectionValues(
        {
          sectionId: newSectionId,
          type,
        },
        pageConfig,
        customConfig,
        jobsAndSubjobTypes
      );

      setPageConfig((prev) => {
        const prevOrder = prev?.order ?? [];
        const prevSections = prev?.sections ?? {};

        const tempSections = {
          ...prevSections,
          [newSectionId]: defaultValues,
        };

        const tempOrder =
          prevOrder && prevOrder.length > 0
            ? [...prevOrder, newOrder]
            : [newOrder];

        if (tempOrder.length > 1) {
          const hasFooter = tempOrder.find((o) =>
            o.sectionId.includes('footer-')
          );
          // if footer is present, move it to the end
          if (hasFooter) {
            const footerIndex = tempOrder.findIndex((o) =>
              o.sectionId.includes('footer-')
            );
            const footerOrder = tempOrder[footerIndex];
            tempOrder.splice(footerIndex, 1);
            tempOrder.push(footerOrder);
          }
        }

        return {
          ...prev,
          sections: tempSections,
          order: tempOrder,
        } as CareerPageResponse;
      });

      logUpdatedKeys('sections');
      logUpdatedKeys('order');
    },
    [pageConfig]
  );

  const deleteSection = useCallback(
    (sectionId: string) => {
      // active section is set to null
      if (sectionId && sectionId === activeSection) {
        handleSetActiveSection(null);
      }

      // add to deleted section ids
      if (sectionId) {
        setDeletedSectionIds((prev) => [...prev, sectionId]);
      }

      // set updated fields for section and order
      logUpdatedKeys('sections');
      logUpdatedKeys('order');
    },
    [deletedSectionIds, setDeletedSectionIds]
  );

  const logUpdatedKeys = (key: string) => {
    const temp = updatedKeys.length > 0 ? [...updatedKeys, key] : [key];

    setUpdatedKeys((prev) => {
      const combined = [...prev, ...temp];
      return [...uniq(combined)];
    });
  };

  const handleUpdatePageStatus = useCallback(
    (newStatus) => {
      // TEMPORARY:
      setPageConfig((prev) => ({
        ...(prev as CareerPageResponse),
        status: newStatus,
      }));
      logUpdatedKeys('status');
    },
    [updatedKeys, pageConfig, updatePageStatus, pageStatusLoading]
  );

  const handleUpdatePageBranding = useCallback(
    (newBranding) => {
      // TEMPORARY:
      setPageConfig((prev) => ({
        ...(prev as CareerPageResponse),
        branding: {
          ...(prev as CareerPageResponse).branding,
          ...newBranding,
        },
      }));

      logUpdatedKeys('branding');
    },
    [updatedKeys, pageConfig, updatePageBranding, pageBrandingLoading]
  );

  const handleUpdatePageSeo = useCallback(() => {
    // TODO: update page seo
    // updatePageSeo()
  }, [pageConfig, updatePageSeo, pageSeoLoading]);

  const handleUpdatePageSections = useCallback(
    (sectionId, sectionUpdates: Partial<AcceptedSections>) => {
      if (pageConfig?.sections?.[sectionId]) {
        const { sections } = pageConfig;
        setPageConfig({
          ...pageConfig,
          sections: {
            ...sections,
            [sectionId]: {
              ...sections[sectionId],
              ...sectionUpdates,
            },
          },
        });

        logUpdatedKeys('sections');
      }
    },
    [updatedKeys, pageConfig?.sections, updatePageSections, pageSectionsLoading]
  );

  const handleUpdatePageSectionsOrder = useCallback(
    (newOrder) => {
      if (newOrder) {
        setPageConfig((prev) => ({
          ...(prev as CareerPageResponse),
          order: newOrder,
        }));
        setHiddenKeys(getHiddenKeys(newOrder));
        logUpdatedKeys('order');
      }
    },
    [updatedKeys, pageConfig, updatePageSectionsOrder, pageSectionsOrderLoading]
  );

  const handleForceUpdatePage = useCallback(() => {}, [
    pageConfig,
    forceUpdatePage,
    forceUpdatePageLoading,
  ]);

  const handlePageSettingKey = useCallback(
    (key: PageSettingTabKeys | null) => {
      if (key === activePageSettingKey) {
        setActivePageSettingKey(null);
      } else {
        setActivePageSettingKey(key);
      }
    },
    [activePageSettingKey]
  );

  const handleSetActiveEditedBlock = useCallback(
    (activeBlock: TActiveEditedBlock | null) => {
      if (activeEditedBlock?.blockId !== activeBlock?.blockId) {
        setActiveEditedBlock(activeBlock);
      } else {
        setActiveEditedBlock(null);
        setActiveBlockSection(null);
      }
    },
    [activeEditedBlock, activeBlockSection]
  );

  const handleSetActiveSection = useCallback(
    (sectionId: string | null, sectionBlockObj?: TActiveBlockType) => {
      const isSameSection = sectionId && activeSection === sectionId;

      if (!sectionId || (!sectionBlockObj && isSameSection)) {
        setActiveSection(null);
        setActiveBlockSection(null);
        setActiveEditedBlock(null);
      } else {
        setActiveSection(sectionId);
      }

      if (sectionBlockObj) {
        setActiveBlockSection(sectionBlockObj);
      } else {
        setActiveBlockSection(null);
        setActiveEditedBlock(null);
      }
    },
    [
      activeSection,
      activeBlockSection,
      setActiveBlockSection,
      setActiveBlockSection,
    ]
  );

  const handleEditSection = useCallback(
    (sectionId: string | null, sectionBlockObj?: TActiveBlockType) => {
      handleSetActiveSection(sectionId, sectionBlockObj);
    },
    [handleSetActiveSection]
  );

  const handleDeleteSection = useCallback(
    (sectionId: string) => {
      const sectionType = pageConfig?.sections[sectionId].type as SectionType;
      const modalId = 'delete-section-modal';
      pushModal({
        show: true,
        twclass: tw`sm:(pl-0 pt-[40px] pb-0 pr-0) max-h-[unset]`,
        children: (
          <ConfirmActionModal
            modalId={modalId}
            actionBtnPostion="right"
            header={
              <div tw="px-[100px]">
                Are you sure you want to delete
                <br />
                {`"${SectionTypeLabels[sectionType]}" section`} ?
              </div>
            }
            body="Deleting this section will remove it from the page."
            confirmButtonLabel="Delete"
            confirmButtonProps={{ variant: 'danger' }}
            confirmActionFn={() => deleteSection(sectionId)}
            cancelButtonLabel="Cancel"
          />
        ),
        key: modalId,
      });
    },
    [deletedSectionIds, setDeletedSectionIds, pageConfig]
  );

  const handleAddSection = useCallback(() => {
    const modalId = 'add-section-modal';
    pushModal({
      show: true,
      twclass: tw`sm:(px-8 py-8)`,
      children: (
        <CareerAddSectionModal
          onAddSection={(sectionType) => {
            addSection(sectionType);
            removeModal(modalId);
          }}
        />
      ),
      key: modalId,
    });
  }, [pageConfig]);

  const handleSave = useCallback(() => {
    const promises = updatedKeys.map((key) => {
      if (key === 'status' && pageConfig?.status) {
        return updatePageStatus({
          variables: {
            pageId: parseInt(customerPageId ?? `1`),
            input: {
              status: pageConfig?.status,
            },
          },
          onCompleted: () => {
            if (key === 'status' && pageConfig?.status === 'ACTIVE')
              fireEvent('activate_careers', {
                pageId: customerPageId,
                brand: pageConfig?.branding?.brand_name,
              });
          },
        });
      } else if (key === 'branding' && pageConfig?.branding) {
        return updatePageBranding({
          variables: {
            pageId: parseInt(customerPageId ?? `1`),
            input: {
              branding: {
                ...pageConfig?.branding,
              },
            },
          },
        });
      } else if (key === 'seo' && pageConfig?.seo) {
        return updatePageSeo({
          variables: {
            pageId: parseInt(customerPageId ?? `1`),
            input: {
              seo: {
                ...pageConfig?.seo,
              },
            },
          },
        });
      } else if (key === 'sections' && pageConfig?.sections) {
        const { sections } = pageConfig;
        const filteredSections = filterDeletedSections(
          sections,
          deletedSectionIds
        );
        return updatePageSections({
          variables: {
            pageId: parseInt(customerPageId ?? `1`),
            input: {
              sections: {
                ...filteredSections,
              },
            },
          },
        });
      } else if (key === 'order' && pageConfig?.order) {
        const { order } = pageConfig;
        const filteredOrder = filterDeletedOrder(order, deletedSectionIds);
        return updatePageSectionsOrder({
          variables: {
            pageId: parseInt(customerPageId ?? `1`),
            input: {
              order: [...filteredOrder],
            },
          },
        });
      }
    });

    Promise.allSettled(promises)
      .then(() => {
        fireEvent('update_careers', {
          pageId: customerPageId,
          brand: pageConfig?.branding?.brand_name,
        });
        createVersionHistory({
          variables: {
            pageId: parseInt(customerPageId ?? `1`),
            input: {},
          },
        });
        setUpdatedKeys([]);
        refetchCareerDataResponse();
        addToast({
          title: 'Career page successfully saved.',
          description: 'Your career page has been successfully saved.',
          type: ToastTypes.SUCCESS,
        });
      })
      .catch((err) => {
        addToast({
          title: 'Something went wrong',
          description: JSON.stringify(err, null, 2),
          type: ToastTypes.ERROR,
        });
      });
  }, [updatedKeys, pageConfig]);

  const {
    data: careerDataResponse,
    refetch: refetchCareerDataResponse,
    loading: isCareerDataLoading,
  } = useQuery<
    CareerPageAPIResponse,
    {
      pageId: string | number | undefined;
    }
  >(GET_CUSTOMER_CAREER_PAGE, {
    skip: !customerPageId,
    variables: {
      pageId: customerPageId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setPageConfig(data.customerCareerPage);
      setPageInitialConfig(data.customerCareerPage);

      // always delete the deletedkeys sections
      // this is to prevent the deleted sections from showing up
      // during the refetch
      setDeletedSectionIds([]);
    },
  });

  useEffect(() => {
    // if empty response dont proceed
    if (!careerDataResponse?.customerCareerPage) return;

    setPageConfig(careerDataResponse.customerCareerPage);
    setPageInitialConfig(careerDataResponse.customerCareerPage);

    if (careerDataResponse.customerCareerPage.order) {
      setHiddenKeys(getHiddenKeys(careerDataResponse.customerCareerPage.order));
      setHiddenInitialKeys(
        getHiddenKeys(careerDataResponse.customerCareerPage.order)
      );
    }
  }, [careerDataResponse]);

  const isPageDirty = useMemo(() => {
    return (
      !isEqual(pageInitialConfig, pageConfig) ||
      deletedSectionIds.length > 0 ||
      !isEqual(hiddenKeys, hiddenInitialKeys)
    );
  }, [
    pageInitialConfig,
    pageConfig,
    deletedSectionIds,
    hiddenKeys,
    hiddenInitialKeys,
  ]);

  const generateInitialPage = useCallback(() => {
    if (!pageConfig) return;
    const { branding } = pageConfig;
    addSection(SectionType.Hero);
    addSection(SectionType.Feature, { imagePlacement: 'left' });
    addSection(SectionType.Feature, {
      imagePlacement: 'right',
      bgColor: undefined,
    });
    addSection(SectionType.Testimonials);
    addSection(SectionType.Carousel, { bgColor: undefined });
    addSection(SectionType.Vacancies);

    if (branding?.location || branding?.recruiter) {
      addSection(SectionType.Contact, {
        bgColor: undefined,
        ...getContactCards(branding?.location, branding?.recruiter),
      });
    }
  }, [pageConfig]);

  // for backwards support
  const generateFooterSection = useCallback(() => {
    if (!pageConfig) return;
    addSection(SectionType.Footer);
  }, [pageConfig]);

  return (
    <CareerPageContext.Provider
      value={{
        careerPageConfig: pageConfig,
        refetchCareerDataResponse,
        isCareerDataLoading,

        // used for checking which sections are currently being edited
        activeSection,

        // active section block setting key ( used for order )
        activeBlockSection,

        // page block settings key ( used for config )
        activeEditedBlock,

        // for checking deleted sections
        deletedSectionIds,

        // for checking of changed values
        isPageDirty,

        // page settings key
        activePageSettingKey,

        // loading states
        pageStatusLoading,
        pageBrandingLoading,
        pageSeoLoading,
        pageSectionsLoading,
        pageSectionsOrderLoading,
        forceUpdatePageLoading,

        // handlers
        handleUpdatePageStatus,
        handleUpdatePageBranding,
        handleUpdatePageSeo,
        handleUpdatePageSections,
        handleUpdatePageSectionsOrder,
        handleForceUpdatePage,
        handleAddSection,
        handleEditSection,
        handleDeleteSection,
        handlePageSettingKey,
        handleSetActiveEditedBlock,

        // handlers
        handleSave,

        generateInitialPage,
        generateFooterSection,

        versionHistoryModalOpen,
        setVersionHistoryModalOpen,
      }}
    >
      {children}
    </CareerPageContext.Provider>
  );
};
