import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useNavigationContext from './context/nav-context';

/** sympl specific route configuration */
export interface SymplRoute {
  vacancyId?: number;
  setActiveVacancyFromParam: () => void;
}

/**
 * Gets the current sympl-route information configuration.
 * @returns A sympl route object containing the current route info.
 */
function useSymplRoute(): SymplRoute {
  const { search } = useLocation();

  const { setActiveVacancy } = useNavigationContext();

  const [vacancyId, setVacancyId] = useState<number>();
  const [customerId, setCustomerId] = useState<number>();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const c = params.get('c');
    const v = params.get('v');
    const vac = params.get('vac');

    // URL does not contain any params
    if (!c && !v && !vac) return;

    // Reset the URL to re-enable dropdown vacancy selection
    const updatedUrl = window.location.href.split('?')[0];
    window.history.pushState({}, '', updatedUrl);

    // Update the customer id and/or vacancy id if they are present in the URL
    setCustomerId(
      c
        ? parseInt(atob(c))
        : vac
        ? parseInt(atob(vac).split(',')[1])
        : undefined
    );
    setVacancyId(
      v
        ? parseInt(atob(v))
        : vac
        ? parseInt(atob(vac).split(',')[0])
        : undefined
    );

    return () => {
      setCustomerId(undefined);
      setVacancyId(undefined);
    };
  }, [params]);

  /**
   * Custom setter that sets the active vacancy and clears
   * the vacancy id from the URL params if it exists
   * @param config The vacancy setter configuration
   */
  const setActiveVacancyFromParam = () => {
    if (vacancyId && customerId) setActiveVacancy({ vacancyId, customerId });
    setCustomerId(undefined);
    setVacancyId(undefined);
  };

  return {
    vacancyId,
    setActiveVacancyFromParam,
  };
}

export default useSymplRoute;
