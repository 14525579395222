import tw, { styled } from 'twin.macro';
import React, { useEffect, useMemo, useState } from 'react';

import Container from 'components/container/Container';
import ContainerHeader from 'components/container/container-header/ContainerHeader';

import { Invoice, InvoiceStatus } from 'types/invoicing/types';
import { GET_INVOICES } from 'graphql/invoicing/queries';
import {
  CLOSE_INVOICE,
  REGENERATE_INVOICE,
  UPDATE_PO_NUMBER,
} from 'graphql/invoicing/mutations';
import { downloadFile } from 'utils/fileHelpers';
import { Body, Subhead, Title2 } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';
import ToolbarItem from 'components/toolbar-item/ToolbarItem';
import {
  DownloadSimple,
  Eye,
  PencilSimple,
  ArrowsClockwise,
  CheckCircle,
} from '@phosphor-icons/react';
import { useMutation } from 'hooks/sympl-mutation';
import { Modal } from 'components/page/app-page/Modal';
import Input from 'components/form/input/Input';
import Label from 'components/form/Label';
import Button from 'components/button/Button';
import Badge from 'components/badge/Badge';
import { TOOLBOX_BTN_STYLING } from 'components/upload/preview/preview-list/PreviewListItem/ResourceItem';
import FilePreviewer from 'components/file-previewer/FilePreviewer';
import { Meta } from 'types/apiTypes';
import useMetaQuery from 'hooks/metaQuery';
import AutoPagination from 'components/table/auto-table/AutoPagination';
import { useQuery } from 'hooks/sympl-query';
import dayjs from 'dayjs';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import InformationToolTip from 'components/tooltip/InformationToolTip';

const TOOLBOX_ICON_SIZE = 20;

const InvoicingSettings: React.FC = () => {
  const nonSentStatuses = [
    InvoiceStatus.OPEN,
    InvoiceStatus.CLOSED,
    InvoiceStatus.MANUAL_CHECK,
  ];

  const { isAdmin, setHasUnsavedChanges, activeCustomer } =
    useNavigationContext();
  const { addToast } = useToastNotifications();

  const [currentlySelectedInvoice, setCurrentlySelectedInvoice] =
    useState<number>();

  const [poNumber, setPoNumber] = useState('');
  const [isPoNumberModalShown, setIsPoNumberModalShown] = useState(false);
  const [pdfPreviewId, setPdfPreviewId] = useState<number | undefined>();
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const fetchMoreInvoicesHandler = async (currentPage: number) => {
    setActivePage(currentPage);
    try {
      await fetchMore({
        variables: {
          pageSize: 20,
          currentPage,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
    } catch (_) {
      return Promise.reject();
    } finally {
      return Promise.resolve();
    }
  };

  const {
    loading: loadingInvoices,
    data: invoicesData,
    meta,
    refetch: refetchInvoices,
    fetchMore,
  } = useMetaQuery<
    {
      invoices: Invoice[];
      meta: Meta;
    },
    { pageSize: number; currentPage: number; status?: string }
  >(GET_INVOICES, {
    variables: {
      pageSize: 20,
      currentPage: activePage ?? 1,
      status: '',
    },
  });

  const {
    loading: loadingNonSentInvoices,
    data: nonSentInvoicesData,
    refetch: refetchNonSentInvoices,
  } = useQuery<
    { invoices: Invoice[] },
    {
      status?: string;
    }
  >(GET_INVOICES, {
    variables: {
      status: nonSentStatuses.join(','),
    },
  });

  useEffect(() => {
    refetchInvoices();
    refetchNonSentInvoices();
  }, [activeCustomer, refetchInvoices, refetchNonSentInvoices]);

  const paginationMeta = meta.pagination;

  const [updatePoNumber, { loading: isUpdatingPoNumber }] = useMutation<
    undefined,
    {
      invoiceId: number;
      input: {
        po_number: string;
      };
    }
  >(UPDATE_PO_NUMBER);

  const [regenerateInvoice, { loading: isRegeneratingInvoice }] = useMutation<
    undefined,
    { invoiceId: number }
  >(REGENERATE_INVOICE);

  const [closeInvoice, { loading: isClosingInvoice }] = useMutation<
    undefined,
    { invoiceId: number }
  >(CLOSE_INVOICE);

  const invoices = useMemo(
    () => invoicesData?.invoices,
    [invoicesData?.invoices]
  );

  const nonSentInvoices = useMemo(
    () => nonSentInvoicesData?.invoices ?? [],
    [nonSentInvoicesData?.invoices]
  );

  const handleUpdatePoNumber = () => {
    if (!currentlySelectedInvoice) return;

    updatePoNumber({
      variables: {
        invoiceId: currentlySelectedInvoice,
        input: {
          po_number: poNumber,
        },
      },
    })
      .then(() => {
        addToast({
          type: ToastTypes.SUCCESS,
          description: 'The PO number was successfully updated',
        });
      })
      .finally(() => {
        refetchInvoices();
        refetchNonSentInvoices();
        setCurrentlySelectedInvoice(undefined);
        setPoNumber('');
        setIsPoNumberModalShown(false);
      });
  };

  const handleRegenerateInvoice = (invoiceId: number) =>
    regenerateInvoice({
      variables: { invoiceId },
    }).then(() => {
      addToast({
        type: ToastTypes.SUCCESS,
        description: 'Invoice regenerated successfully',
      });
      refetchInvoices();
      refetchNonSentInvoices();
    });

  const handleDownloadInvoice = (invoiceUrl: string) =>
    downloadFile(invoiceUrl);

  const handleCloseInvoice = (invoice: Invoice) => {
    closeInvoice({
      variables: {
        invoiceId: invoice.id,
      },
    }).then(() => {
      addToast({
        type: ToastTypes.SUCCESS,
        description: 'The invoice was closed successfully',
      });
      refetchInvoices();
      refetchNonSentInvoices();
    });
  };

  const sentInvoices =
    invoices?.filter((invoice) => invoice.status === InvoiceStatus.SENT) ?? [];

  useEffect(() => {
    setHasUnsavedChanges(!!poNumber);

    return () => setHasUnsavedChanges(false);
  }, [poNumber, setHasUnsavedChanges]);

  const getFriendlyInvoiceType = (invoice: Invoice) =>
    invoice.type === 'CN' ? 'Credit Note' : 'Invoice';

  return (
    <div>
      <Modal
        show={isPoNumberModalShown}
        onClose={() => setIsPoNumberModalShown(false)}
      >
        <div tw="my-4 space-y-4 w-64">
          <div tw="space-y-1">
            <Label htmlFor={'po-nubmer'}>PO number</Label>
            <Input
              defaultValue={
                invoices?.find(
                  (invoice) => invoice.id === currentlySelectedInvoice
                )?.po_number
              }
              onChange={(e) => setPoNumber(e.target.value)}
            />
          </div>
          <Button
            loading={isUpdatingPoNumber}
            onClick={handleUpdatePoNumber}
            stretch={true}
          >
            Update
          </Button>
        </div>
      </Modal>

      <Container id="invoices">
        <>
          <ContainerHeader>
            <Title2 mb={0}>Invoices</Title2>
            <Subhead isLight mb={3}>
              List of all available invoices
            </Subhead>
          </ContainerHeader>

          <div tw="my-5 flex flex-col">
            {loadingInvoices || loadingNonSentInvoices ? (
              <Body>Loading...</Body>
            ) : invoices && invoices.length > 0 ? (
              <div tw="overflow-hidden border-gray-200">
                {isAdmin && nonSentInvoices.length > 0 && (
                  <table
                    className="isAdmin"
                    tw="w-[calc(100% - 1rem)] mb-12 mt-2 mx-2 divide-y divide-gray-200"
                  >
                    <thead tw="bg-gray-50">
                      <tr>
                        <TableHeading>Status</TableHeading>
                        <TableHeading>Type</TableHeading>
                        <TableHeading>Date</TableHeading>
                        <TableHeading>Number</TableHeading>
                        <TableHeading>PO</TableHeading>
                        <TableHeading>Amount (excl. vat)</TableHeading>
                        <TableHeading>Amount (incl. vat)</TableHeading>
                        <TableHeading>Actions</TableHeading>
                      </tr>
                    </thead>
                    <tbody tw="divide-y divide-gray-200 bg-white">
                      {nonSentInvoices.map((invoice) => (
                        <tr key={invoice.id}>
                          <TableDataCell>
                            <Badge
                              label={InvoiceStatus[invoice.status]}
                              color={'yellow'}
                            />
                          </TableDataCell>
                          <TableDataCell>
                            {getFriendlyInvoiceType(invoice)}
                          </TableDataCell>
                          <TableDataCell>
                            {invoice.date &&
                              new Date(invoice.date).toLocaleDateString()}
                          </TableDataCell>
                          <TableDataCell>{invoice.number}</TableDataCell>
                          <TableDataCell>{invoice.po_number}</TableDataCell>
                          <TableDataCell>
                            {`${invoice.currency} ${invoice.amount_excl}`}
                          </TableDataCell>
                          <TableDataCell>
                            {`${invoice.currency} ${invoice.amount}`}
                          </TableDataCell>
                          <TableDataCell tw="w-px space-x-1">
                            <ul tw="flex w-auto items-center">
                              <ToolbarItem
                                Icon={<PencilSimple size={16} weight="bold" />}
                                iconStyle={TOOLBOX_BTN_STYLING}
                                onClick={() => {
                                  setCurrentlySelectedInvoice(invoice.id);
                                  setIsPoNumberModalShown(true);
                                }}
                                tooltip={{
                                  enabled: true,
                                  text: 'Update PO number',
                                  placement: 'bottom',
                                  arrow: true,
                                }}
                                iconSize={TOOLBOX_ICON_SIZE}
                              />
                              <ToolbarItem
                                Icon={
                                  <ArrowsClockwise size={16} weight="bold" />
                                }
                                iconStyle={TOOLBOX_BTN_STYLING}
                                onClick={() =>
                                  handleRegenerateInvoice(invoice.id)
                                }
                                tooltip={{
                                  enabled: true,
                                  text: 'Regenerate PDF',
                                  placement: 'bottom',
                                  arrow: true,
                                }}
                                iconSize={TOOLBOX_ICON_SIZE}
                                disabled={isRegeneratingInvoice}
                              />
                              <ToolbarItem
                                Icon={<CheckCircle size={16} weight="bold" />}
                                iconStyle={TOOLBOX_BTN_STYLING}
                                onClick={() => handleCloseInvoice(invoice)}
                                tooltip={{
                                  enabled: true,
                                  text: 'Close & send invoice',
                                  placement: 'bottom',
                                  arrow: true,
                                }}
                                iconSize={TOOLBOX_ICON_SIZE}
                                disabled={
                                  isClosingInvoice ||
                                  invoice.status !== InvoiceStatus.MANUAL_CHECK
                                }
                              />

                              {invoice.url && (
                                <>
                                  <FilePreviewer
                                    name={invoice.number}
                                    url={invoice.url!}
                                    type={'pdf'}
                                    isShown={pdfPreviewId === invoice.id}
                                    closeModal={() =>
                                      setPdfPreviewId(undefined)
                                    }
                                    onDocumentLoad={() =>
                                      setIsPdfLoading(false)
                                    }
                                    onDocumentError={() =>
                                      setIsPdfLoading(false)
                                    }
                                  />
                                  <Separation />
                                  <ToolbarItem
                                    Icon={<Eye size={16} weight="bold" />}
                                    iconStyle={TOOLBOX_BTN_STYLING}
                                    onClick={() => {
                                      setPdfPreviewId(invoice.id);
                                      setIsPdfLoading(true);
                                    }}
                                    tooltip={{
                                      enabled: true,
                                      text: 'View',
                                      placement: 'bottom',
                                      arrow: true,
                                    }}
                                    iconSize={TOOLBOX_ICON_SIZE}
                                    disabled={isPdfLoading}
                                  />
                                  <ToolbarItem
                                    Icon={
                                      <DownloadSimple size={16} weight="bold" />
                                    }
                                    iconStyle={TOOLBOX_BTN_STYLING}
                                    onClick={() =>
                                      handleDownloadInvoice(invoice.url!)
                                    }
                                    tooltip={{
                                      enabled: true,
                                      text: 'Download',
                                      placement: 'bottom',
                                      arrow: true,
                                    }}
                                    iconSize={TOOLBOX_ICON_SIZE}
                                    disabled={isPdfLoading}
                                  />
                                </>
                              )}
                            </ul>
                          </TableDataCell>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                <table tw="min-w-full divide-y divide-gray-200">
                  <thead tw="bg-gray-50">
                    <tr>
                      <TableHeading>Type</TableHeading>
                      <TableHeading>Date</TableHeading>
                      <TableHeading>Number</TableHeading>
                      <TableHeading>Amount (excl. vat)</TableHeading>
                      <TableHeading>Amount (incl. vat)</TableHeading>
                      <TableHeading>
                        <div tw="flex flex-row items-center gap-1">
                          <span>Status</span>
                          <InformationToolTip
                            name="paid_status"
                            text="The status might be delayed, contact us if you suspect a paid invoice is not indicated as such."
                          />
                        </div>
                      </TableHeading>
                      <TableHeading>Actions</TableHeading>
                    </tr>
                  </thead>
                  <tbody tw="divide-y divide-gray-200 bg-white">
                    {sentInvoices.map((invoice) => (
                      <tr key={invoice.id}>
                        <TableDataCell>
                          <div tw="flex flex-row gap-1">
                            {getFriendlyInvoiceType(invoice)}
                            {dayjs(invoice.created_at).isAfter(
                              dayjs().subtract(4, 'hour')
                            ) && <Badge label="New" color="blue" />}
                          </div>
                        </TableDataCell>
                        <TableDataCell>
                          {new Date(invoice.date).toLocaleDateString()}
                        </TableDataCell>
                        <TableDataCell>{invoice.number}</TableDataCell>
                        <TableDataCell>
                          {`${invoice.currency} ${invoice.amount_excl}`}
                        </TableDataCell>
                        <TableDataCell>
                          {`${invoice.currency} ${invoice.amount}`}
                        </TableDataCell>
                        <TableDataCell>
                          <Badge
                            label={invoice.is_paid ? 'Paid' : 'Not Paid'}
                            color={invoice.is_paid ? 'green' : 'gray'}
                          />
                        </TableDataCell>
                        <TableDataCell>
                          <ul tw="flex">
                            {invoice.url && (
                              <>
                                <FilePreviewer
                                  name={invoice.number}
                                  url={invoice.url!}
                                  type={'pdf'}
                                  isShown={pdfPreviewId === invoice.id}
                                  closeModal={() => setPdfPreviewId(undefined)}
                                  onDocumentLoad={() => setIsPdfLoading(false)}
                                  onDocumentError={() => setIsPdfLoading(false)}
                                />
                                <ToolbarItem
                                  Icon={<Eye size={16} weight="bold" />}
                                  iconStyle={TOOLBOX_BTN_STYLING}
                                  onClick={() => {
                                    setPdfPreviewId(invoice.id);
                                    setIsPdfLoading(true);
                                  }}
                                  tooltip={{
                                    enabled: true,
                                    text: 'View',
                                    placement: 'bottom',
                                    arrow: true,
                                  }}
                                  iconSize={TOOLBOX_ICON_SIZE}
                                  disabled={isPdfLoading}
                                />
                                <ToolbarItem
                                  Icon={
                                    <DownloadSimple size={16} weight="bold" />
                                  }
                                  iconStyle={TOOLBOX_BTN_STYLING}
                                  onClick={() =>
                                    handleDownloadInvoice(invoice.url!)
                                  }
                                  tooltip={{
                                    enabled: true,
                                    text: 'Download',
                                    placement: 'bottom',
                                    arrow: true,
                                  }}
                                  iconSize={TOOLBOX_ICON_SIZE}
                                  disabled={isPdfLoading}
                                />
                                <ToolbarItem
                                  Icon={
                                    <PencilSimple size={16} weight="bold" />
                                  }
                                  iconStyle={TOOLBOX_BTN_STYLING}
                                  onClick={() => {
                                    setCurrentlySelectedInvoice(invoice.id);
                                    setIsPoNumberModalShown(true);
                                  }}
                                  tooltip={{
                                    enabled: true,
                                    text: 'Update PO number',
                                    placement: 'bottom',
                                    arrow: true,
                                  }}
                                  iconSize={TOOLBOX_ICON_SIZE}
                                />
                                {isAdmin && (
                                  <ToolbarItem
                                    Icon={
                                      <ArrowsClockwise
                                        size={16}
                                        weight="bold"
                                      />
                                    }
                                    iconStyle={TOOLBOX_BTN_STYLING}
                                    onClick={() =>
                                      handleRegenerateInvoice(invoice.id)
                                    }
                                    tooltip={{
                                      enabled: true,
                                      text: 'Regenerate PDF',
                                      placement: 'bottom',
                                      arrow: true,
                                    }}
                                    iconSize={TOOLBOX_ICON_SIZE}
                                    disabled={isRegeneratingInvoice}
                                  />
                                )}
                              </>
                            )}
                          </ul>
                        </TableDataCell>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div tw="flex flex-col items-center">
                <Subhead isCenter>You don't have any invoices yet.</Subhead>
              </div>
            )}
          </div>
        </>
      </Container>
      <AutoPagination
        selectedPage={activePage}
        itemsLoaded={paginationMeta?.count}
        maxItems={paginationMeta?.count}
        totalItems={paginationMeta?.total}
        totalPages={paginationMeta?.total_pages}
        onPageChange={fetchMoreInvoicesHandler}
      />
    </div>
  );
};

const TableHeading = styled.th`
  ${tw`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 last:relative`}
`;

const TableDataCell = styled.td`
  ${tw`whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-700`}
`;

const Separation = styled.span`
  ${tw`bg-gray-300 mx-2 relative w-px h-6 rounded-full`}
`;

export default InvoicingSettings;
