import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IntakeInputTypes, IntakeQuestion } from 'types/intakeTypes';
import { questionRules } from 'utils/formHelpers';
import PillGroup from 'components/form/pill-group/PillGroup';

const IntakePillGroup: React.FC<{
  question: IntakeQuestion;
  defaultValue?: string[];
  disableAdd?: boolean;
}> = ({ question, defaultValue, disableAdd }) => {
  const { control } = useFormContext();

  const [options, setOptions] = useState(question.options as string[]);
  const [selected, setSelected] = useState(
    defaultValue && defaultValue[0] !== '' ? defaultValue : []
  );

  const id = useMemo(() => question.id.toString(), [question.id]);
  const rules = useMemo(() => questionRules(question), [question]);

  const changeHandler = (
    value: {
      key: string;
      selected: boolean;
    }[],
    onChange: (value: string) => void
  ) => {
    let updatedSelected = [...selected];

    if (value?.length > (options?.length ?? 0)) {
      // Option was added
      setOptions(value.map((o) => o.key));
      updatedSelected = value.filter((o) => o.selected).map((o) => o.key);
    } else {
      // Option was toggled
      updatedSelected = value.filter((o) => o.selected).map((o) => o.key);
    }

    setSelected(updatedSelected);
    onChange(JSON.stringify(updatedSelected));
  };

  const maxSelected = useMemo(() => {
    switch (question.type) {
      case IntakeInputTypes.MULTIPLECHOICE:
        return undefined;
      case IntakeInputTypes.SINGLECHOICE:
        return 1;
    }
  }, [question.type]);

  return (
    <Controller
      id={id}
      name={id}
      control={control}
      defaultValue={selected.length > 0 ? selected : undefined}
      rules={rules}
      render={({ onChange }: { onChange: any }) => (
        <PillGroup
          id={id}
          options={options}
          selected={selected}
          onChange={(value) => changeHandler(value, onChange)}
          maxSelected={maxSelected}
          enableNewOptions={!disableAdd}
        />
      )}
    />
  );
};

export default IntakePillGroup;
