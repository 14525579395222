import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { useQuery } from 'hooks/sympl-query';
import { getOperationDefinition } from '@apollo/client/utilities';

import { useMemo } from 'react';
import { Meta } from 'types/apiTypes';

function useMetaQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
) {
  const {
    data: dataWithMeta,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery<TData, TVariables>(query, options);
  const extractedData: any = useMemo(() => {
    return dataWithMeta ? Object.entries(dataWithMeta)?.[0]?.[1] : undefined;
  }, [dataWithMeta]);

  const dataIsArray = useMemo(() => {
    return Array.isArray(extractedData);
  }, [extractedData]);

  /**
   * Gets the type of the query
   *
   */
  const selection: any =
    getOperationDefinition(query)?.selectionSet.selections[0];
  const queryType: string = selection.name.value;

  const meta: Meta = useMemo(
    () => ({
      ...(dataIsArray ? extractedData[0]?.meta : extractedData?.meta),
    }),
    [dataIsArray, extractedData]
  );

  const data: TData | undefined = useMemo(() => {
    if (dataIsArray && extractedData) {
      return {
        [queryType]: extractedData.map((item: any) => {
          const { ...rest } = item;
          return rest;
        }),
      };
    } else if (extractedData) {
      const { ...rest } = extractedData;
      return rest;
    } else {
      return undefined;
    }
  }, [extractedData, dataIsArray, queryType]);

  return { data, loading, error, meta, refetch, fetchMore };
}
export default useMetaQuery;
