import React, { createContext, useState } from 'react';
import { AdChannel, AdDateRange, AD_CHANNELS } from 'types/adTypes';
import { getLocalAppSession, setLocalAppSession } from 'utils/storageHelpers';

export type SocialMediaContextType = {
  activeChannels: AdChannel[];
  activeDateFilter: AdDateRange;
  toggleChannelFilter: (channel: AdChannel) => void;
  setDateRangeFilter: (range: AdDateRange) => void;
};

export const SocialMediaContext = createContext<SocialMediaContextType>(
  undefined!
);

export const SocialMediaProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const session = getLocalAppSession();

  const [activeDateFilter, setDateFilter] = useState<AdDateRange>(
    session?.activeDateFilter ?? 'Last quarter'
  );

  const [activeChannels, setActiveChannels] = useState(AD_CHANNELS);

  const toggleChannelFilter = (channel: AdChannel) => {
    const selectedChannels = !activeChannels.includes(channel)
      ? [...activeChannels, channel]
      : activeChannels.filter((c) => c !== channel);

    setActiveChannels(selectedChannels);
    setLocalAppSession({
      activeChannels: selectedChannels,
    });
  };

  const setDateRangeFilter = (range: AdDateRange) => {
    setDateFilter(range);
    setLocalAppSession({
      activeDateFilter: range,
    });
  };

  return (
    <SocialMediaContext.Provider
      value={{
        activeChannels,
        activeDateFilter,
        toggleChannelFilter,
        setDateRangeFilter,
      }}
    >
      {children}
    </SocialMediaContext.Provider>
  );
};
