import React, { FC } from 'react';
import tw, { styled } from 'twin.macro';

import Tabs from 'components/tabs/Tabs';
import { AppPageProps } from '../app-page/AppPage';
import Toolbar from '../toolbar/Toolbar';

export interface PageHeadingProps {
  title?: string;
  cta?: React.ReactNode;
  clipboardCopy?: React.ReactNode;
  tabs?: React.ReactNode[];
  feedback?: AppPageProps['feedback'];
  activeTabIndex?: number;
  enableFeedback?: boolean;
  loading?: boolean;
  breadcrumbUrl?: string;
  onTabChange?: (currentTabIndex: number) => void;
  isTabsDisabled?: boolean;
}

const PageHeading: FC<PageHeadingProps> = ({
  title,
  cta,
  tabs,
  activeTabIndex = 0,
  feedback,
  loading,
  breadcrumbUrl,
  enableFeedback = false,
  clipboardCopy,
  onTabChange,
  isTabsDisabled,
}) => {
  return (
    <div tw="w-full flex flex-col">
      {title && (
        <Toolbar
          cta={cta}
          title={title}
          feedback={feedback}
          clipboardCopy={clipboardCopy}
          enableFeedback={enableFeedback}
          breadcrumbUrl={breadcrumbUrl}
        />
      )}
      {tabs?.length && (
        <Container>
          {loading ? (
            <div tw="flex flex-col h-full w-full mb-8">
              <div tw="bg-gray-200 h-6 w-2/5 animate-pulse rounded-md" />
              <div tw="mt-2 bg-gray-200 h-4 w-1/5 animate-pulse rounded-md" />
            </div>
          ) : (
            <Tabs
              activeTabIndex={activeTabIndex}
              onChange={(index) => onTabChange?.(index)}
              items={tabs}
              isTabsDisabled={isTabsDisabled}
            />
          )}
        </Container>
      )}
    </div>
  );
};

const Container = styled.div(
  tw`flex flex-col justify-center w-full min-w-[320px] px-3 pt-3 md:px-11 2xl:px-16 bg-white`
);

export default PageHeading;
