import tw, { styled } from 'twin.macro';
import React from 'react';

const InputAddOn = ({
  placeholder = '0.00',
  defaultValue,
  defaultUnit = '€',
  units,
  disabled = false,
  readOnly = false,
  onValueChange,
  onUnitChange,
}: {
  placeholder?: string;
  defaultValue?: number;
  defaultUnit?: string;
  units: string[];
  disabled?: boolean;
  readOnly?: boolean;
  onValueChange?: (value?: number) => void;
  onUnitChange?: (value: string) => void;
}) => (
  <Wrapper disabled={disabled} readOnly={readOnly}>
    <Input
      type="number"
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={(e) =>
        onValueChange?.(
          e.target.value === '' ? undefined : e.target.valueAsNumber
        )
      }
      dropdownVisible={!!units.length}
      disabled={disabled || readOnly}
      readOnly={readOnly}
    />
    {units.length && (
      <div tw="absolute inset-y-0 right-0 flex items-center">
        {units.length > 1 ? (
          <select
            tw="mr-3 mb-0.5 h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-1 text-gray-500 focus:outline-none sm:text-sm disabled:cursor-not-allowed"
            value={defaultUnit}
            onChange={(e) => onUnitChange?.(e.target.value)}
            disabled={units.length === 1 || disabled || readOnly}
          >
            {units.map((unit) => (
              <option key={unit} value={unit}>
                {unit}
              </option>
            ))}
          </select>
        ) : (
          <span tw="mr-6 text-gray-500">{units[0]}</span>
        )}
      </div>
    )}
  </Wrapper>
);
const Wrapper = styled.div<{ disabled: boolean; readOnly: boolean }>`
  ${tw`w-full relative rounded p-1 ring-1 ring-black/5`}
  ${({ disabled }) => disabled && tw`bg-gray-50 cursor-not-allowed`}
  ${({ readOnly }) => readOnly && tw`bg-transparent`}
`;

const Input = styled.input<{ dropdownVisible: boolean }>`
  ${tw`w-full pl-2 py-2 text-sm focus:outline-none disabled:(bg-transparent cursor-not-allowed)`}
  ${({ dropdownVisible }) => (dropdownVisible ? tw`pr-[3.5rem]` : tw`pr-2`)}
`;

export default InputAddOn;
