import { gql } from '@apollo/client';

export const GET_INTAKES = gql`
  query getIntakes($pageSize: Int!, $currentPage: Int!, $completed: String) {
    intakes(
      pageSize: $pageSize
      currentPage: $currentPage
      completed: $completed
    )
      @rest(
        type: "Intake"
        path: "/v4/intakes?sort=-id&page%5Bsize%5D={args.pageSize}&page%5Bnumber%5D={args.currentPage}&filter%5Bcompleted%5D={args.completed}"
      ) {
      id
      name
      hash
      completed
      type
      last_edit
      meta {
        pagination {
          count
          current_page
          links {
            previous
            next
          }
          per_page
          total
          total_pages
        }
      }
    }
  }
`;

export const GET_INTAKE = gql`
  query getIntake($hash: ID!) {
    intake(hash: $hash) @rest(type: "Intake", path: "/v4/intakes/{args.hash}") {
      id
      name
      completed
      sections {
        id
        name
        description
        steps {
          id
          name
          questions {
            id
            annotation
            apiList
            description
            type
            label
            value
            friendlyValue
            name
          }
        }
      }
    }
  }
`;

export const GET_INTAKES_SHORT = gql`
  query getIntakes {
    intakes @rest(type: "Intake", path: "/v4/intakes?sort=-id") {
      id
      name
      type
      customer_id
    }
  }
`;
