import 'twin.macro';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ProgressBar from '../progress-bar/ProgressBar';
import { CaretRight, CreditCard, UsersThree } from '@phosphor-icons/react';
import { numberToDecimalNotation } from 'utils/baseHelpers';
import { Routes } from 'types/routeTypes';
import AutoTable from '../table/auto-table/AutoTable';
import {
  ActiveVacanciesMetricResponse,
  ActiveVacancyMetrics,
} from 'types/dashboard/types';
import useNavigationContext from 'hooks/context/nav-context';
import { GET_ACTIVE_VACANCY_METRICS } from 'graphql/dashboard/active-vacancies-metrics/queries';
import { useQuery } from 'hooks/sympl-query';
import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';

const ActiveVacanciesTable: React.FC = () => {
  const { setActiveVacancy } = useNavigationContext();

  const { loading, data } = useQuery<ActiveVacanciesMetricResponse>(
    GET_ACTIVE_VACANCY_METRICS,
    {
      fetchPolicy: 'network-only',
    }
  );

  const activeVacancies = useMemo(
    () => data?.campaignMetrics?.active_vacancies ?? [],
    [data?.campaignMetrics?.active_vacancies]
  );
  const navigate = useNavigate();

  const COLNAMES = [
    'Campaign name',
    'Spent progress',
    'Spent / Total',
    'Applicants',
    '',
  ];

  const getProgress = useCallback((activeVacancy: ActiveVacancyMetrics) => {
    const progress =
      (activeVacancy.spend_budget / activeVacancy.total_budget) * 100;
    return Math.min(100, progress);
  }, []);

  const generatedRows = useMemo(() => {
    return activeVacancies.map((activeVacancy) => [
      <span tw="cursor-pointer text-base font-medium text-gray-700 hover:text-gray-400 capitalize">
        {activeVacancy.name}
      </span>,
      <ProgressBar progressPercentage={getProgress(activeVacancy)} />,
      <div tw="flex items-baseline">
        <div tw="relative">
          <CreditCard tw="mr-2 text-gray-400" size="16" />
        </div>
        <div tw="flex items-baseline">
          <span tw="text-xs text-gray-600 mr-1">€ </span>
          <span tw="text-base text-gray-700 font-medium">
            {numberToDecimalNotation(activeVacancy.spend_budget)}
          </span>
          <span tw="text-base text-gray-600 mx-1">/</span>
          <span tw="text-xs text-gray-600 mr-1">€ </span>
          <span tw="text-sm text-gray-600">
            {numberToDecimalNotation(activeVacancy.total_budget)}
          </span>
        </div>
      </div>,
      !activeVacancy.has_custom_url ? (
        <div tw="flex items-center">
          <div tw="relative">
            <UsersThree tw="mr-1 text-gray-400" size="16" />
          </div>
          <span tw="text-gray-700 font-medium">
            {numberToDecimalNotation(activeVacancy.applications)}
          </span>
        </div>
      ) : (
        <div tw="flex items-center">External</div>
      ),
      <CaretRight tw="cursor-pointer hover:text-gray-400" />,
    ]);
  }, [activeVacancies, getProgress]);

  const handleVacancyClicked = (rowIndex: number) => {
    setActiveVacancy({ vacancyId: activeVacancies[rowIndex].vac_id });
    navigate(Routes.SOCIAL_MEDIA);
  };

  if (loading) return <SkeletonList rows={3} />;

  return (
    <AutoTable
      columns={COLNAMES}
      rows={generatedRows}
      scrollable={true}
      onRowClicked={(_, rowIndex) => handleVacancyClicked(rowIndex)}
      emptyLabel="No active campaigns at this moment."
    />
  );
};

export default ActiveVacanciesTable;
