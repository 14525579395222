import 'twin.macro';
import React, { useMemo } from 'react';
import { EnvelopeSimple, UserPlus } from '@phosphor-icons/react';

export type IconCardIconKey = 'new-user' | 'mail';

export interface IconCardProps {
  label: string;
  icon: IconCardIconKey;
  sublabel?: string;
  suffix: React.ReactNode;
  onClick?: () => void;
}

const IconCard: React.FC<IconCardProps> = ({
  label,
  sublabel,
  suffix,
  icon = 'mail',
  onClick,
}) => {
  const MAIL_ICON_SIZE = 24;
  const USER_ICON_SIZE = 24;

  const cardIcon = useMemo(() => {
    switch (icon) {
      case 'new-user':
        return (
          <UserPlus
            weight="bold"
            tw="text-gray-400 mr-4"
            size={USER_ICON_SIZE}
          />
        );
      case 'mail':
        return (
          <EnvelopeSimple
            weight="bold"
            tw="text-gray-400 mr-4"
            size={MAIL_ICON_SIZE}
          />
        );
    }
  }, [icon]);

  return (
    <div
      tw="py-1 px-3 cursor-pointer shadow bg-white rounded-lg text-base"
      onClick={onClick}
    >
      <div tw="flex flex-row items-center">
        <div>{cardIcon}</div>
        <div tw="mx-3 truncate flex-1">
          <p tw="text-gray-700 font-medium">{label}</p>
          {sublabel && <p tw="text-gray-400 truncate">{sublabel}</p>}
        </div>
        <div>
          <p tw="text-xs">{suffix}</p>
        </div>
      </div>
    </div>
  );
};

export default IconCard;
