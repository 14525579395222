import { gql } from '@apollo/client';

export const UPDATE_PAGE_STATUS = gql`
  mutation UpdatePageStatus($pageId: ID!) {
    updatePageStatus(input: $input, pageId: $pageId)
      @rest(
        type: "Career Page Status"
        path: "/v4/pages/{args.pageId}/status"
        method: "PUT"
      )
  }
`;

export const UPDATE_PAGE_BRANDING = gql`
  mutation UpdatePageBranding($pageId: ID!) {
    updatePageBranding(input: $input, pageId: $pageId)
      @rest(
        type: "Career Page Status"
        path: "/v4/pages/{args.pageId}/status"
        method: "PUT"
      )
  }
`;

export const UPDATE_PAGE_SEO = gql`
  mutation UpdatePageSeo($pageId: ID!) {
    updatePageSeo(input: $input, pageId: $pageId)
      @rest(
        type: "Career Page Seo"
        path: "/v4/pages/{args.pageId}/seo"
        method: "PUT"
      )
  }
`;

export const UPDATE_PAGE_SECTIONS = gql`
  mutation UpdatePageSections($pageId: ID!) {
    updatePageSections(input: $input, pageId: $pageId)
      @rest(
        type: "Career Page Sections"
        path: "/v4/pages/{args.pageId}/sections"
        method: "PUT"
      )
  }
`;

export const UPDATE_PAGE_SECTIONS_ORDER = gql`
  mutation UpdatePageSectionsOrder($pageId: ID!) {
    updatePageSectionsOrder(input: $input, pageId: $pageId)
      @rest(
        type: "Career Page Sections"
        path: "/v4/pages/{args.pageId}/order"
        method: "PUT"
      )
  }
`;

export const CREATE_VERSION_HISTORY = gql`
  mutation CreateVersionHistory($pageId: ID!) {
    createVersionHistory(input: $input, pageId: $pageId)
      @rest(
        type: "Career Page Version History"
        path: "/v4/pages/{args.pageId}/version-history"
        method: "POST"
      )
  }
`;

export const RESTORE_VERSION_HISTORY = gql`
  mutation RestoreVersionHistory($pageId: ID!, $historyId: ID) {
    createVersionHistory(input: $input, pageId: $pageId, historyId: $historyId)
      @rest(
        type: "Career Page Version History"
        path: "/v4/pages/{args.pageId}/version-history/{args.historyId}"
        method: "POST"
      )
  }
`;

// used to update more than one keys
// like the following keys:
// - status
// - seo
// - sections
// - order
export const FORCE_UPDATE_PAGE = gql`
  mutation ForceUpdatePage($pageId: ID!) {
    forceUpdatePage(input: $input, pageId: $pageId)
      @rest(
        type: "Force Update Career Page"
        path: "/v4/careers/{args.pageId}/force"
        method: "PUT"
      )
  }
`;
