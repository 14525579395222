import { TeamKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';
import { faker } from '@faker-js/faker';

export const defaultTeamSettings: TeamKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig(),
  team: [...Array(Math.round(3 + Math.random() * 4))].map(() => ({
    imageUrl: faker.image.avatar(),
    name: faker.person.firstName(),
    role: faker.person.jobTitle(),
  })),
};
