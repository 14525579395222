import React, { useMemo } from 'react';

import Dropdown from 'components/dropdown/Dropdown';
import { MailTemplate } from 'types/mailTypes';
import { Routes } from 'types/routeTypes';
import { useNavigate } from 'react-router-dom';
import tw, { styled, TwStyle } from 'twin.macro';

export interface TextEditorControlsProps {
  templates?: MailTemplate[];
  loadingTemplates?: boolean;
  insideControls?: boolean;
  currentTemplate?: MailTemplate;
  onTemplateChange?: (template: MailTemplate) => void;
  onTemplateClear?: () => void;
  customStyle?: TwStyle;
}

const TextEditorDropdown: React.FC<TextEditorControlsProps> = ({
  templates = [],
  loadingTemplates,
  currentTemplate,
  insideControls,
  onTemplateChange,
  onTemplateClear,
  customStyle,
}) => {
  const dropdownItems = useMemo(
    () => templates.map(({ name }) => ({ key: name, label: name })),
    [templates]
  );
  const navigate = useNavigate();

  const currentDropItem = currentTemplate?.name;

  return (
    <Wrapper customStyle={customStyle}>
      <Dropdown
        loading={loadingTemplates}
        label={currentDropItem ?? 'Templates'}
        items={dropdownItems}
        value={currentDropItem}
        insideControls={insideControls}
        onClear={() => onTemplateClear?.()}
        onChange={(template) =>
          onTemplateChange?.(
            templates.find(({ name }) => name === template) ?? templates[0]
          )
        }
        stretch={false}
        fullHeight={true}
        minWidth={true}
        onCreate={() => navigate(Routes.TEMPLATE_SETTINGS)}
        createLabel="Create new template"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ customStyle?: TwStyle }>`
  ${tw`max-w-[150px]`}
  ${({ customStyle }) => customStyle}
`;

export default TextEditorDropdown;
