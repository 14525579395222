import SocialIcon from 'components/icons/SocialIcon';
import React from 'react';
import { AdChannel } from 'types/adTypes';

const ChannelIcon: React.FC<{
  channel: AdChannel;
  disabled?: boolean;
  grayScale?: boolean;
  useNaturalColor?: boolean;
}> = ({ channel, disabled, grayScale, useNaturalColor }) => {
  const ICON_SIZE = '20px';

  return (
    <SocialIcon
      company={channel}
      width={ICON_SIZE}
      height={ICON_SIZE}
      color={disabled ? '#e5e7eb' : 'currentColor'}
      grayScale={grayScale}
      useNaturalColor={useNaturalColor}
    />
  );
};

export default ChannelIcon;
