import 'twin.macro';
import React from 'react';
import Button from 'components/button/Button';
import AccountSelector from 'components/navbar/AccountSelector';
import { Body } from 'components/typography/Typography';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'types/routeTypes';

const SubscriptionPopupContent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div tw="flex flex-col text-left p-4">
      <h2 tw="text-xl font-semibold text-gray-700">
        Your subscription has expired.
      </h2>
      <p tw="my-4">Renew your subscription to access this page.</p>
      <Button
        onClick={() => {
          navigate(Routes.SUBSCRIPTION_SETTINGS);
        }}
        stretch
      >
        Reactivate your subscription
      </Button>
      <div tw="mt-6">
        <Body mb={1}>Or switch to another account:</Body>
        <AccountSelector />
      </div>
    </div>
  );
};

export default SubscriptionPopupContent;
