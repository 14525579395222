import tw, { styled } from 'twin.macro';
import { Check, X } from '@phosphor-icons/react';
export const OverviewWrapper = styled.div(
  tw`
    flex flex-col absolute right-0 h-full z-10 bg-gray-50 shadow-xl p-4
  `
);

export const IconWrapper = styled.div(tw`block w-full mb-4`);

export const CloseIcon = styled(X)(
  tw`text-gray-500 text-xl font-bold cursor-pointer ml-auto`
);

export const StepperContentList = styled.ul(
  tw`flex flex-col w-full h-full pt-0 pl-4 pr-4 pb-4 overflow-y-scroll`
);

export const StepperSectionItem = styled.li<{
  completed: boolean;
}>`
  ${tw`flex flex-col w-full items-center m-0 mb-2 last-of-type:m-0 p-4 rounded-lg`}
  ${({ completed }) =>
    completed && tw`bg-green-100 border-[1px] border-green-300`}
`;

export const SectionNameWrapper = styled.div(tw`flex items-center w-full`);

export const SectionName = styled.p<{
  completed: boolean;
}>`
  ${tw`w-full text-lg font-medium truncate`}

  ${({ completed }) => completed && tw`text-green-900`}
`;

export const CheckIcon = styled(Check)(
  tw`text-2xl font-bold mr-2 text-green-500`
);

export const StepperStepList = styled.ul`
  ${tw`
    flex 
    flex-col 
    w-full 
    py-2 
  `}
`;

export const StepperStepListItem = styled.li(tw`flex flex-col w-full m-0`);

export const StepName = styled.p<{
  isAnswered: boolean;
  isUnanswered: boolean;
  isCurrentStep: boolean;
  isStepOptional: boolean;
  isSectionCompleted: boolean;
}>`
  ${tw`
    truncate 
    cursor-pointer 
    p-2
    text-gray-400
  `}
  ${({ isAnswered }) => isAnswered && tw`text-green-700`}

  ${({ isAnswered, isCurrentStep, isSectionCompleted, isStepOptional }) => {
    if (isAnswered && (isSectionCompleted || isCurrentStep)) {
      return tw`text-green-700`;
    }
    if (isAnswered && isStepOptional) {
      return tw`text-gray-400`;
    }
  }}

  ${({ isCurrentStep, isUnanswered }) =>
    isCurrentStep && isUnanswered && tw`text-red-700`}
`;

export const RemainingItems = styled.p(tw`px-4 pt-1 pl-8 text-red-600 text-sm`);

export const ItemCount = styled.span<{ isCurrentStep: boolean }>`
  ${tw`
    w-[2em]
    h-[2em]
    flex
    items-center
    justify-center
    leading-none
    rounded-full
    font-bold 
    text-[10px]
    bg-gray-200
    text-gray-500
    relative
  `}
  ${({ isCurrentStep }) =>
    isCurrentStep &&
    tw`
      text-white
      bg-red-500
      before:content-['']
      before:absolute 
      before:animate-ping 
      before:w-full 
      before:h-full 
      before:rounded-full 
      before:bg-red-500
    `}
`;

export const StepperButton = styled.button`
  ${tw`
    flex 
    flex-col
    flex-nowrap
    text-left 
    flex-nowrap 
    pl-2
    cursor-pointer
    hover:bg-black
    hover:bg-opacity-5
  `}
`;

export const StepperButtonContent = styled.div(tw`
  flex 
  items-center
  justify-start
  flex-nowrap
`);

export const ActiveDot = styled.span<{
  isAnswered: boolean;
  isUnanswered: boolean;
  isCurrentStep: boolean;
  isSectionCompleted: boolean;
}>`
  ${tw`
    relative 
    w-4 
    h-4 
    flex
    shrink-0

    before:content-['']
    before:hidden 
    before:absolute 
    before:animate-ping 
    before:w-full 
    before:h-full 
    before:rounded-full 
    
    after:content-['']
    after:hidden 
    after:relative 
    after:w-4 
    after:h-4 
    after:rounded-full 
  `}
  ${({ isCurrentStep }) =>
    isCurrentStep &&
    tw`
      before:inline-flex 
      after:inline-flex 
      before:bg-indigo-500
      after:bg-indigo-400
    `}
  ${({ isCurrentStep, isSectionCompleted, isAnswered }) =>
    isCurrentStep &&
    (isAnswered || isSectionCompleted) &&
    tw`
      before:bg-green-500
      after:bg-green-400
    `}
  ${({ isUnanswered }) =>
    isUnanswered &&
    tw`
      hidden
    `}
`;
