import 'twin.macro';
import React, { useMemo } from 'react';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { CustomerResource } from 'types/apiTypes';
import ResourcesModal, {
  ResourcesModalProps,
} from 'components/resources-modal/ResourcesModal';

const AdEditorUploadForm = ({
  show,
  mode,
  onClose,
  onSubmit,
}: ResourcesModalProps) => {
  const { currentVariant, previewChannel, updateCurrentVariant } =
    useAdEditorContext();

  const isLogo = useMemo(() => mode === 'logo', [mode]);

  const isCompleted = useMemo(
    () => (isLogo ? !!currentVariant?.logo : !!currentVariant?.path),
    [currentVariant?.logo, currentVariant?.path, isLogo]
  );

  const defaultValue = useMemo(() => {
    if (currentVariant?.logo && mode === 'logo') return [currentVariant.logo];
    else if (
      currentVariant &&
      ['image', 'video'].includes(mode ?? '') &&
      currentVariant?.path
    )
      return [currentVariant.path];
    else return undefined;
  }, [currentVariant, mode]);

  const uploadChangeHandler = (resources: CustomerResource[]) => {
    if (!resources.length) return;

    updateCurrentVariant(isLogo ? 'logo' : 'path', resources[0]);

    onSubmit(resources);
  };

  const uploadDeleteHandler = () =>
    updateCurrentVariant(isLogo ? 'logo' : 'path', null);

  return (
    <ResourcesModal
      show={show}
      defaultValue={defaultValue}
      completed={isCompleted}
      maxSelected={1}
      isLogo={isLogo}
      mode={mode}
      channel={previewChannel}
      creativeType={currentVariant?.creative_type ?? undefined}
      onSubmit={uploadChangeHandler}
      onDelete={uploadDeleteHandler} //!
      onClose={onClose}
      // TODO: brandId={...}
    />
  );
};

export default AdEditorUploadForm;
