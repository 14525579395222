import React, { FC, Suspense, lazy, useEffect } from 'react';
import {
  CardsProps,
  CarouselProps,
  ContactProps,
  FeatureProps,
  FooterProps,
  HeroProps,
  SectionType,
  TeamProps,
  TestimonialProps,
  VacanciesProps,
  VideoProps,
} from '@symplbe/sympl-components';

import { CareerPageResponse } from 'types/careers-page/types';
import useCareerPageContext from 'hooks/context/career-page-context';
import SectionContainer from './section-container/SectionContainer';
import { filterDeletedOrder } from 'utils/careers/section-helpers/sectionHelpers';
import Button from 'components/button/Button';
import { GearSix, Plus } from '@phosphor-icons/react';
import tw from 'twin.macro';
import { Title1 } from 'components/typography/Typography';
import HeroSkeleton from './sections-with-config/hero/HeroSkeleton';
import TeamSkeleton from './sections-with-config/team/TeamSkeleton';
import FeatureSkeleton from './sections-with-config/feature/FeatureSkeleton';
import CarouselSkeleton from './sections-with-config/carousel/CarouselSkeleton';
import VideoSkeleton from './sections-with-config/video/VideoSkeleton';
import VacanciesSkeleton from './sections-with-config/vacancies/VacanciesSkeleton';
import CardsSkeleton from './sections-with-config/cards/CardsSkeleton';
import TestimonialSkeleton from './sections-with-config/testimonial/TestimonialSkeleton';
import ContactSkeleton from './sections-with-config/contact/ContactSkeleton';
import FooterSkeleton from './sections-with-config/footer/FooterSkeleton';
interface SectionsRendererProps {
  pageConfig: CareerPageResponse;
}

const HeroSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/hero/HeroSection'
    )
);

const TeamSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/team/TeamSection'
    )
);

const CardsSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/cards/CardsSection'
    )
);

const FeatureSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/feature/FeatureSection'
    )
);

const CarouselSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/carousel/CarouselSection'
    )
);

const VideoSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/video/VideoSection'
    )
);

const VacanciesSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/vacancies/VacanciesSection'
    )
);

const TestimonialSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/testimonial/TestimonialSection'
    )
);

const ContactSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/contact/ContactSection'
    )
);

const FooterSection = lazy(
  () =>
    import(
      'components/careers/section-renderer/sections-with-config/footer/FooterSection'
    )
);

const SectionsRenderer: FC<SectionsRendererProps> = ({ pageConfig }) => {
  const {
    activeSection,
    deletedSectionIds,
    handleAddSection,
    handleEditSection,
    handlePageSettingKey,
    handleUpdatePageSections,
    generateInitialPage,
    generateFooterSection,
  } = useCareerPageContext();

  const { sections, order } = pageConfig;

  useEffect(() => {
    if (!pageConfig.order.length) {
      generateInitialPage();
      generateFooterSection();
    }

    // if has section configs but has no footer, generate one
    if (pageConfig.order.length > 0 && pageConfig.sections) {
      const findFooterSection = Object.keys(pageConfig.sections).find(
        (sKey) => pageConfig.sections[sKey].type === SectionType.Footer
      );

      const findFooterOrder = pageConfig.order.find(({ sectionId }) =>
        sectionId.includes('footer-')
      );

      // generate one if none
      if (!findFooterSection || !findFooterOrder) generateFooterSection();
    }
  }, [pageConfig]);

  const filteredDeletedOrder = filterDeletedOrder(order, deletedSectionIds);
  const visibleSections = filteredDeletedOrder.filter(
    (order) => !order.isHidden
  );

  const handleAddClick = () => {
    handleAddSection();
    handlePageSettingKey(null);
  };

  const hasSections = sections && visibleSections && visibleSections.length > 0;

  return (
    <>
      {hasSections && (
        <>
          {visibleSections.map(({ sectionId }, index) => {
            const sectionProps = sections[sectionId];
            const reactDomKey = `${index} ${sectionId}`;

            // TEMPORARY FIX: DB data have orders without sections
            if (!sectionProps) {
              return null;
            }

            return (
              <SectionContainer
                key={reactDomKey}
                sectionProps={sectionProps}
                index={index}
              >
                <button
                  hidden={!!activeSection}
                  onClick={() => handleEditSection(sectionId)}
                  tw="absolute z-40 top-4 right-4 h-10 w-10 rounded-full flex justify-center items-center cursor-pointer mb-2 bg-white ring-1 ring-gray-100 text-gray-600 shadow-lg"
                >
                  <GearSix weight="bold" />
                </button>
                {(() => {
                  switch (sectionProps.type) {
                    case SectionType.Hero:
                      return (
                        <Suspense fallback={HeroSkeleton}>
                          <HeroSection
                            key={reactDomKey}
                            sectionProps={sectionProps as HeroProps}
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Team:
                      return (
                        <Suspense fallback={TeamSkeleton}>
                          <TeamSection
                            key={reactDomKey}
                            sectionProps={sectionProps as TeamProps}
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Feature:
                      return (
                        <Suspense fallback={FeatureSkeleton}>
                          <FeatureSection
                            key={reactDomKey}
                            sectionProps={sectionProps as FeatureProps}
                            handleUpdatePageSections={handleUpdatePageSections}
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Carousel:
                      return (
                        <Suspense fallback={CarouselSkeleton}>
                          <CarouselSection
                            key={reactDomKey}
                            sectionProps={sectionProps as CarouselProps}
                            handleUpdatePageSections={handleUpdatePageSections}
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Video:
                      return (
                        <Suspense fallback={VideoSkeleton}>
                          <VideoSection
                            key={reactDomKey}
                            sectionProps={sectionProps as VideoProps}
                            handleUpdatePageSections={handleUpdatePageSections}
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Vacancies:
                      return (
                        <Suspense fallback={VacanciesSkeleton}>
                          <VacanciesSection
                            key={reactDomKey}
                            sectionProps={sectionProps as VacanciesProps}
                            handleUpdatePageSections={handleUpdatePageSections}
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Cards:
                      return (
                        <Suspense fallback={CardsSkeleton}>
                          <CardsSection
                            key={reactDomKey}
                            sectionProps={sectionProps as unknown as CardsProps}
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Testimonials:
                      return (
                        <Suspense fallback={TestimonialSkeleton}>
                          <TestimonialSection
                            key={reactDomKey}
                            sectionProps={
                              sectionProps as unknown as TestimonialProps
                            }
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Contact:
                      return (
                        <Suspense fallback={ContactSkeleton}>
                          <ContactSection
                            key={reactDomKey}
                            sectionProps={
                              sectionProps as unknown as ContactProps
                            }
                            isEditMode
                          />
                        </Suspense>
                      );
                    case SectionType.Footer:
                      return (
                        <Suspense fallback={FooterSkeleton}>
                          <FooterSection
                            key={reactDomKey}
                            sectionProps={
                              sectionProps as unknown as FooterProps
                            }
                            isEditMode
                          />
                        </Suspense>
                      );
                    default:
                      return (
                        <div tw="w-full h-[50vh] min-h-[200px] p-4 flex bg-gray-100 items-center justify-center">
                          <Title1>
                            Section {sectionProps.type} being constructed. Stay
                            tuned!
                          </Title1>
                        </div>
                      );
                  }
                })()}
              </SectionContainer>
            );
          })}
        </>
      )}

      <div tw="p-8 w-full">
        <Button
          stretch
          role="button"
          onClick={handleAddClick}
          customStyle={tw`
            p-8 
            flex 
            flex-col 
            justify-center 
            items-center 
            text-2xl 

            border-dashed
            border-4
            border-green-200
            bg-green-100
            bg-opacity-20
            text-green-900

            hover:bg-green-300
            hover:border-green-300
            hover:bg-opacity-100
            hover:text-white
          `}
        >
          <Plus tw="text-5xl" weight="bold" /> Add Section
        </Button>
      </div>
    </>
  );
};

export default SectionsRenderer;
