import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { Routes } from 'types/routeTypes';
import {
  Briefcase,
  ChatCircleDots,
  FileText,
  FilmScript,
  MapPin,
  Scroll,
} from '@phosphor-icons/react';

import { Link } from 'react-router-dom';
import { Candidate } from 'types/candidates/types';
import SourceIcon from 'components/icon-card/SourceIcon';
import dayjs from 'dayjs';
import { Subhead, Headline, Body } from 'components/typography/Typography';
import { Flipped } from 'react-flip-toolkit';
import ToolTip from 'components/tooltip/ToolTip';

const CandidateCard: React.FC<{
  candidate: Candidate;
}> = ({ candidate }) => {
  const currentyear = new Date().getFullYear();

  return (
    <Flipped flipId={candidate.id}>
      <CandidateCardWrapper tabIndex={-1} ringColor={candidate.color}>
        <Link
          tabIndex={-1}
          to={`${Routes.PROCEDURES}/${candidate.procedureId ?? 0}`}
        >
          <div tw="flex flex-row items-center">
            <div tw="flex-1">
              <div tabIndex={-1} tw="flex items-baseline justify-between">
                <Headline isBold>{candidate.name}</Headline>
                {candidate.procDate && (
                  <div tw="text-gray-400 text-xs font-medium text-right block">
                    {dayjs(candidate.procDate).format(
                      new Date(candidate.procDate).getFullYear() !== currentyear
                        ? 'DD MMM YYYY'
                        : 'DD MMM'
                    )}
                  </div>
                )}
              </div>

              <ul tw="mt-4">
                {candidate.experience && (
                  <>
                    <li tw="flex flex-row items-center my-3">
                      <Body isBlock={false}>
                        <Briefcase tw="w-6 h-6" />
                      </Body>
                      <Subhead>{candidate.experience}</Subhead>
                    </li>
                    <li tw="flex flex-row items-center my-3">
                      <Body isBlock={false}>
                        <Scroll weight="bold" tw="w-6 h-6" />
                      </Body>
                      <Subhead>{candidate.education}</Subhead>
                    </li>
                  </>
                )}

                {candidate.city && (
                  <li tw="flex flex-row items-center my-3">
                    <Body isBlock={false}>
                      <MapPin weight="bold" tw="w-6 h-6 mr-3" />
                    </Body>
                    <Subhead>{candidate.city}</Subhead>
                  </li>
                )}
              </ul>
              <div tw="flex flex-row justify-between items-center mt-2">
                <div tw="flex flex-row items-center">
                  <div tw="block">
                    <SourceIcon source={candidate.source} />
                  </div>
                </div>
                <div tw="flex flex-row gap-1 text-gray-300">
                  {candidate.notes && (
                    <div tw="text-gray-700">
                      <ToolTip
                        content={
                          <div
                            tw="text-white text-sm py-2 px-4 max-w-xl"
                            dangerouslySetInnerHTML={{
                              __html: candidate.notes,
                            }}
                          />
                        }
                        arrow
                      >
                        <FilmScript size="24" weight="bold" />
                      </ToolTip>
                    </div>
                  )}
                  <div css={[candidate.hasCV && tw`text-gray-700`]}>
                    <FileText size="24" weight="bold" />
                  </div>
                  <div css={[candidate.hasCommunications && tw`text-gray-700`]}>
                    <ChatCircleDots size="24" weight="bold" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </CandidateCardWrapper>
    </Flipped>
  );
};

const CandidateCardWrapper = styled.div<{ ringColor?: string }>`
  ${tw`bg-white shadow-md rounded-md p-5 block text-gray-800 cursor-pointer`}
  ${({ ringColor }) =>
    !ringColor || ringColor === '#FFFFFF'
      ? tw`hover:shadow-lg`
      : css`
          box-shadow: 0 0 0 calc(3px) ${ringColor};

          :hover {
            box-shadow: 0 0 0 calc(4px) ${ringColor};
          }
        `}
`;

export default CandidateCard;
