import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import React, { FC, useMemo } from 'react';
import 'twin.macro';
import Title from 'components/title/Title';
import useCareerPageContext from 'hooks/context/career-page-context';
import { Flipper } from 'react-flip-toolkit';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IDraggable } from 'views/candidates/CandidatesOverview';
import { filterDeletedOrder } from 'utils/careers/section-helpers/sectionHelpers';
import SectionItem from './SectionItem';
import * as Styled from './styles';
import { SectionType } from '@symplbe/sympl-components';

interface SectionListSettingsProps {}

const SectionListSettings: FC<SectionListSettingsProps> = () => {
  const {
    careerPageConfig,
    isCareerDataLoading,
    handleEditSection,
    handleUpdatePageSections,
    handleUpdatePageSectionsOrder,
    activeSection,
    deletedSectionIds,
  } = useCareerPageContext();

  const sectionOrder = useMemo(() => {
    const tempOrder = careerPageConfig?.order
      ? [...careerPageConfig?.order]
      : [];

    return filterDeletedOrder(tempOrder, deletedSectionIds);
  }, [careerPageConfig?.order, deletedSectionIds, filterDeletedOrder]);

  const sectionList = useMemo(
    () => careerPageConfig?.sections,
    [careerPageConfig, careerPageConfig?.sections]
  );

  const isLoaded = !isCareerDataLoading;
  const isEmpty = !sectionList || !sectionOrder || sectionOrder.length === 0;

  const onHide = (sectionId: string, isHidden: boolean) => {
    const tempOrder = sectionOrder && [...sectionOrder];
    const index = tempOrder.findIndex((order) => order.sectionId === sectionId);

    tempOrder[index] = { ...tempOrder[index], isHidden };
    handleUpdatePageSectionsOrder(tempOrder);
  };

  const onDragEnd = (result: any) => {
    const { destination, source }: IDraggable = result;

    const tempOrder = sectionOrder && [...sectionOrder];
    const [movedOrder] = tempOrder.splice(source.index, 1);
    tempOrder.splice(destination.index, 0, movedOrder);

    handleUpdatePageSectionsOrder(tempOrder);
  };

  const handleSectionClick = (
    e: React.MouseEvent,
    sectionId: string,
    isHidden: boolean
  ) => {
    const isFakeBtn = (e.target as Element).closest('[class*="fake-btn"]');
    const isBlockItem = (e.target as Element).closest(
      '[class*="section-block-item"]'
    );
    // ensures that hide and drag events dont trigger active sections
    // the purpose of using divs is to prevent unwanted form submissions
    if (!isBlockItem && !isFakeBtn && !isHidden) {
      handleEditSection(sectionId);
    }
  };

  if (!isLoaded) {
    return (
      <div tw="w-full flex flex-col">
        <SkeletonList rows={2} />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div tw="w-full p-4">
        <p tw="italic text-gray-500 text-center">
          You have no sections yet. Please add a section to get started.
        </p>
      </div>
    );
  }

  return (
    <Styled.SectionItemsContainer>
      <Title tw="flex-shrink-0 pb-2">Section List Settings</Title>
      <Styled.SectionList>
        {!isEmpty && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'section-list'}>
              {(provided: any) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Flipper
                    flipKey={sectionOrder.join('')}
                    spring="veryGentle"
                    tw="flex flex-col flex-nowrap gap-1"
                  >
                    {sectionOrder.map(({ sectionId, isHidden }, index) => {
                      const sectionObj = sectionList[sectionId];
                      const isFooter = sectionObj.type === SectionType.Footer;

                      // TEMPORARY FIX: DB data have orders without sections
                      if (isFooter || !sectionObj) {
                        return (
                          <SectionItem
                            index={sectionOrder.length}
                            isHidden={false}
                            activeSection={activeSection}
                            sectionId={sectionObj.sectionId as string}
                            sectionObj={sectionObj}
                            handleSectionClick={handleSectionClick}
                            handleUpdatePageSections={handleUpdatePageSections}
                          />
                        );
                      }

                      return (
                        <Draggable
                          index={index}
                          key={`${sectionId} ${index}`}
                          draggableId={sectionId}
                        >
                          {(provided) => (
                            <SectionItem
                              index={index}
                              isHidden={isHidden}
                              activeSection={activeSection}
                              sectionId={sectionId}
                              sectionObj={sectionObj}
                              provided={provided}
                              handleOnHide={onHide}
                              handleSectionClick={handleSectionClick}
                              handleUpdatePageSections={
                                handleUpdatePageSections
                              }
                            />
                          )}
                        </Draggable>
                      );
                    })}
                  </Flipper>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Styled.SectionList>
    </Styled.SectionItemsContainer>
  );
};

export default SectionListSettings;
